import React, { useEffect, useId, useRef, useState } from "react";
import InputField from "./InputField";
import { Input } from "./Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { getIp } from "sr/utils/api/getIp";
import DropDown from "./DropDown.tsx";
import { indiaState, province } from "sr/i18n/messages/setting";
import { Countries } from "sr/i18n/messages/setting";
import _ from 'lodash';
import { useValidationErrorHandle } from "../functions/validationErrorHandle";

declare const google: any;

export default function AutoLocation(props) {
  const intl = useIntl()
  const [addressline1, setAddressLine1] = useState("");
  const [addressline2, setAddressLine2] = useState("")
  // const [formatedAddress, setFormatedAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [lat, setLat] = useState<any>('');
  const [lng, setLng] = useState<any>('');
  const autoCompleteRef: any = useRef();
  const inputRef = useRef();
  const mapContainerRef = useRef(null);

  const zoom = 15;
  // useEffect(()=> {
  //   const fetchIP = async () => {
  //     const result = await getIp()
  //     setLat(result?.latitude)
  //     setLng(result?.longitude)
  //     setCountryCode(result?.country)
  //   }
    
  //   fetchIP()
  // },[])
  const validationErrorHandle = useValidationErrorHandle();
  useEffect(()=> {
    // if(!props?.address?.lat && !props?.address?.lng) {
    //   getCurrentLocation()
    // }
  },[])
  useEffect(() => {
    setAddressLine1(props?.address?.address_line_1);
    setAddressLine2(props?.address?.address_line_2)
    setCity(props?.address?.city);
    setState(props?.address?.state);
    setZipCode(props?.address?.postal);
    setCountry(props?.address?.country || '');
    setLat(props?.address?.lat);
    setLng(props?.address?.lng);
    
  }, [props?.address]);
  
  let mapObject: any;
  useEffect(() => {
    if (autoCompleteRef && typeof window !== "undefined" && typeof google !== "undefined" && google.maps && mapContainerRef.current) {

        // Initialize Autocomplete
        const options = {
            fields: ["address_components", "geometry", "icon", "name"]
        };
        autoCompleteRef.current = new google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef?.current?.addListener("place_changed", fillInAddress);

        // Initialize the map
        const mapProp = {
            center: { lat: lat, lng: lng },
            zoom: zoom,
        };
        mapObject = new google.maps.Map(document.getElementById("googleMap"), mapProp);
        // Render initial marker
        renderMarker(lat, lng);
    } else {
        console.error("Google Maps JavaScript API is not loaded.");
    }
}, [lat, lng]);


  const fillInAddress = async () => {
    const place = await autoCompleteRef?.current?.getPlace();
    console.log(place);
    if(place) {
      constructAddress(place);
    }
  };

  const constructAddress = (place: any) => {
    let address1 = "";
    let address2 = "";
    let postcode = "";
    let city = "";
    let state = "";
    let country = "";
    let cityShort = '';
    let countryShort = '';
    let stateLong = '';
    // setAddressLine1(place?.formatted_address);
    address1 = place?.name;
    if(!address1) {
      address1 = place?.formatted_address;
    }
    console.log(place?.formatted_address, address1)
    for (const component of place?.address_components) {
      console.log(component);
      const componentTypes = component?.types;
      switch (true) {
        // case _.includes(componentTypes, 'street_number'): {
        //   address1 = `${component?.long_name} ${address1}`;
        //   break;
        // }
        // case _.includes(componentTypes, 'premise'): {
        //   address1 = `${address1} ${component?.long_name}`;
        //   break;
        // }
        // case _.includes(componentTypes, 'neighborhood'): {
        //   address1 = `${address1} ${component?.long_name}`;
        //   break;
        // }
        // case _.includes(componentTypes, 'route'): {
        //   address2 = `${component?.long_name} ${address2}`;
        //   break;
        // }
        // case _.includes(componentTypes, 'sublocality_level_1'):
        // case _.includes(componentTypes, 'sublocality'):
        // case _.includes(componentTypes, 'subpremise'): {
        //   address2 = `${address2} ${component?.long_name}`;
        //   break;
        // }
        case _.includes(componentTypes, 'postal_code'): {
          postcode = `${component?.long_name}${postcode}`;
          break;
        }
        // case _.includes(componentTypes, 'postal_code_suffix'): {
        //   postcode = `${postcode}-${component?.long_name}`;
        //   break;
        // }
        case _.includes(componentTypes, 'locality'):{
          city = component?.long_name;
          cityShort = component?.short_name;
          break;
        }
        case _.includes(componentTypes, 'administrative_area_level_1'): {
          state = component?.short_name;
          stateLong = component?.long_name;
          break;
        }
        case _.includes(componentTypes, 'country'):{
          country = component?.long_name;
          countryShort = component?.short_name;
          break;
        }
      }
    }
    if(!address1) {
      let addressArray = place?.formatted_address?.split(',')?.filter(item => _.trim(item));
      console.log(addressArray)
      if(addressArray?.length > 0) {
        addressArray = addressArray.filter(item => {
          return !_.includes([city, cityShort, state, stateLong, country, countryShort, postcode], _.trim(item))  
        })
        address1 = addressArray?.toString().replaceAll(',', ', ');
      }
    }
    console.log(address1, address2, city, state, postcode, country)
    setAddressLine1(_.trim(address1));
    setAddressLine2(_.trim(address2));
    setCity(city);
    setState(state);
    setZipCode(postcode);
    setCountry(country);
    setLat(place.geometry.location.lat());
    setLng(place.geometry.location.lng());
    renderMarker(place.geometry.location.lat(), place.geometry.location.lng());
  };

  const renderMarker = (latitude: any, longitude: any) => {
    if (typeof window !== "undefined" && typeof google !== "undefined" && google.maps && mapContainerRef.current) {
    const myLatLng = { lat: latitude, lng: longitude };
    let mapProp = {
      center: myLatLng,
      zoom: 15,
    };
    mapObject = new google.maps.Map(
      document.getElementById("googleMap"),
      mapProp
    );

    let marker = new google.maps.Marker({
      position: myLatLng,
      animation: google.maps.Animation.BOUNCE,
    });
    if(mapObject) {
    marker.setMap(mapObject);
    }
  }};

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        renderMarker(position.coords.latitude, position.coords.longitude);
        getAddressFromLatLng(
          position.coords.latitude,
          position.coords.longitude
        );
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  };

  const getAddressFromLatLng = (lat: any, lng: any) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAz5LmnMAyd7t67Yr_NVLPqiO_isMfXIPs`
    )
      .then((responseData) => {
        console.log(responseData);
        
        return responseData.json();
      })
      .then((placeData) => {
        constructAddress(placeData.results[0]);
      })
      .catch((error) => {
        console.log("Got error while fetching address", JSON.stringify(error));
      });
  };

  const address = {
    address_line_1: addressline1,
    address_line_2: addressline2,
    city: city,
    state: state,
    country:country,
    postal: zipCode,
    lat: lat,
    lng: lng
  }
 useEffect(()=> {
  props?.HandleAutoLocation && props?.HandleAutoLocation(address)
 },[addressline1, addressline2, city, state, countryCode, country, zipCode, lat, lng]);

 const handleSearchChange = (event) => {
  const value = event.currentTarget.value
  setAddressLine1(value)
  if(!value) {
    setAddressLine1('');
    setAddressLine2('');
    setCity('');
    setState('');
    setZipCode('');
    setCountry('');    
    setLat('');
    setLng('');
  }
 }

  const isHideLatLang = _.get(props, 'isHideLatLang', false);
  let googleMapStyles: any = {height: "200px", width: "100%"};
  if(!lat && !lng) {
    googleMapStyles = {...googleMapStyles, pointerEvents: 'none'};
  }

  return (
    <div>
      <form>
        <div ref={mapContainerRef} id="googleMap" className="mt-5" style={googleMapStyles} />
        <div className="mt-5">
          <label className="text-normal font-satoshiBold text-sm">{props?.label ? props?.label : <FormattedMessage id="INPUT.ADDRESS1.TITLE" defaultMessage='Address 1'></FormattedMessage>}</label>
          <input
            ref={inputRef}
            value={addressline1}            
            placeholder={props?.placeholder ? props?.placeholder : intl.formatMessage({
              id: 'INPUT.ADDRESS1.PLACEHOLDER',
              defaultMessage: 'Enter Address 1',
            })}          
            className="block w-full md:px-4 sm:px-1 md:py-2 sm:py-1 mt-2 sm:text-sm md:text-md text-gray-700 placeholder-gray-500 bg-white border border-gray-border rounded-2xl
            focus:border-sky-200 focus:ring-sky-200 focus:outline-none focus:ring focus:ring-opacity-40"
            type="text"
            onChange={handleSearchChange}
          ></input>
           {props.errors?.address_line_1 ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(props.errors?.address_line_1)}</p>
        ) : (
          ''
        )}
        </div>
        <button
          type="button"
          onClick={() => {
            getCurrentLocation();
          }}
          className="text-gray-900 mt-2"
        >
          <FontAwesomeIcon icon={faLocationArrow} className='mr-2 text-gray-900' />
          Use my current location
        </button>
{!props?.noOther &&<>
<Input
  label={intl.formatMessage({
    id: 'INPUT.ADDRESS2.TITLE',
    defaultMessage: 'Address 2',
  })}
  placeholder={intl.formatMessage({
    id: 'INPUT.ADDRESS2.PLACEHOLDER',
    defaultMessage: 'Enter Address 2',
  })}
  type="text"
  value={addressline2}
  onChange={(e) => setAddressLine2(e.target.value)}
/>
<DropDown error={props?.errors?.country} label={intl.formatMessage({
    id: 'INPUT.COUNTRY.TITLE',
    defaultMessage: 'Country',
  })}
  value={country}
  onChange={(e) => setCountry(e.target.value)}>
    <option value=''><FormattedMessage id="ECOMMERCE.COMMON.DROPDOWN.DEFAULT.OPTION" defaultMessage="Select an option" /></option>
    {Countries.map((item, index)=> (
      <option key={index} value={item.value}>{item.label}</option>
    ))}
  </DropDown>
{country === 'United States' && 
  <DropDown label={intl.formatMessage({
    id: 'INPUT.PROVINCE.TITLE',
    defaultMessage: 'Enter Province',
  })}
  value={state}
  onChange={(e) => setState(e.target.value)}>
    <option value=''><FormattedMessage id="ECOMMERCE.COMMON.DROPDOWN.DEFAULT.OPTION" defaultMessage="Select an option" /></option>
    {province.map((item, index)=> (
      <option key={index} value={item.value}>{item.label}</option>
    ))}
  </DropDown>
}
{country === 'India' && 
<DropDown
  label={intl.formatMessage({
    id: 'INPUT.STATE.TITLE',
    defaultMessage: 'Enter State',
  })}
  value={state}
  onChange={(e) => setState(e.target.value)}
>
  <option value=''><FormattedMessage id="ECOMMERCE.COMMON.DROPDOWN.DEFAULT.OPTION" defaultMessage="Select an option" /></option>
  {indiaState.map((item, index)=> (
    <option key={index} value={item.value}>{item.label}</option>
  ))}
</DropDown>
}
<Input
  label={intl.formatMessage({
    id: 'INPUT.CITY.TITLE',
    defaultMessage: 'City',
  })}
  placeholder={intl.formatMessage({
    id: 'INPUT.CITY.PLACEHOLDER',
    defaultMessage: 'Enter Locality',
  })}
  type="text"
  value={city}
  onChange={(e) => setCity(e.target.value)}
/>
<Input
  label={intl.formatMessage({
    id: 'INPUT.ZIPCODE',
    defaultMessage: 'Enter Zip Code',
  })}
  placeholder={intl.formatMessage({
    id: 'INPUT.ZIPCODE',
    defaultMessage: 'Enter Zip Code',
  })}
  type="text"
  value={zipCode}
  onChange={(e) => setZipCode(e.target.value)}
/>
{!isHideLatLang && (
  <>
    <Input
      label={intl.formatMessage({
        id: 'INPUT.LATITUDE.TITLE',
        defaultMessage: 'Enter Latitude',
      })}
      placeholder={intl.formatMessage({
        id: 'INPUT.LATITUDE.TITLE',
        defaultMessage: 'Enter Latitude',
      })}
      type="text"
      value={lat}
      onChange={(e) => setLat(e.target.value)}
    />
    <Input
      label={intl.formatMessage({
        id: 'INPUT.LONGITUDE.TITLE',
        defaultMessage: 'Enter Longitude',
      })}
      placeholder={intl.formatMessage({
        id: 'INPUT.LONGITUDE.TITLE',
        defaultMessage: 'Enter Longitude',
      })}
      type="text"
      value={lng}
      onChange={(e) => setLng(e.target.value)}
    />
    </>
)}
</>}

      </form>
    </div>
  );
}
