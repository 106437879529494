import React, {useEffect, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Link, useParams } from 'react-router-dom'
import { TopBar } from '../component/TopBar'
import EmailLink from 'sr/helpers/ui-components/EmailLink'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Profile } from './Profile'
import { ModelWrapper } from '../component/ModelWrapper'
import { getCleaner } from 'sr/utils/api/cleaner/getCleaner'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { getTraining } from 'sr/utils/api/cleaner/getTraining'
import { getMedicalCondition } from 'sr/utils/api/cleaner/getMedicalCondition'
import { getEmployment } from 'sr/utils/api/cleaner/getEmployment'
import { FormattedMessage } from 'react-intl'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { getJobType } from 'sr/utils/api/cleaner/getJobType'
import { useSelector } from 'react-redux'
import { getJob } from 'sr/utils/api/individual/job/getJob'
import Job from 'app/pages/component/types/individual/job'
import { SelectandContinue } from './component/SelectandContinue'
import { getFiles } from 'sr/utils/api/getFiles'
import { getFile } from 'sr/utils/api/getFile'
export const IndividualCleanerProfile = () => {
  const [activeTab, setActiveTab] = useState('About')
  const [model, setModel] = useState(false)
  const [id, setId] = useState<string>()
  const [loader, setLoader] = useState('')
  const [training, setTraining] = useState(null)
  const [medical, setMedical] = useState(null)
  const [employment, setEmployment] = useState(null)
  const [jobType, setJobType] = useState(null)
  const [data, setData] = useState([])
  const [allCleaner, setAllCleaner] = useState([])
  const [jobId, setJobId] = useState<string>()
  const [jobData, setJobData] = useState<Job>()
  const [profileImg, setProfileImg] = useState(null)
const [rate, setRate] = useState()
  const HandleModelByProps = (data:any)=> {
    setModel(data)
  }
  const params = useParams()
  const fetchJobData = async ()=> {
    const queryParams = new URLSearchParams()
    queryParams.append('id', jobId)
    setLoader('cleaner')
      try {
          const res = await getJob(queryParams)
          if(res?.success===true) {
            setJobData(res?.data)
            
          }
          else {
            
          }
      }
      catch(e) {
          
      }
  }
  useEffect(()=> {
if(jobId) {
  fetchJobData()
}
  },[jobId])
  useEffect(()=> {
      const {id, jobid} = params
      setJobId(jobid)
      setId(id)
  },[params])

  const fetchProfileImg = async (cleanerId)=> {
    setLoader('profileImg')
    const params = {
      cleaner_id:cleanerId
    }
const profileImg = await getFiles(params)
if(profileImg?.success===true) {
  const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
  if(filterfile && filterfile[0]?.file?.key) {
    const fileimg = await getFile(filterfile[0]?.file?.key);
    if(fileimg) {
    const newImageUrl = URL.createObjectURL(fileimg);
    setProfileImg(newImageUrl)
    setLoader('')
    }
    else {
      setLoader('')
    }
  }}
  }

  useEffect(()=> {
    const fetchCleaner = async ( id)=> {
      const queryParams = new URLSearchParams()
      queryParams.append('id',id )
      try {
          const res = await getCleaner(queryParams)
          if(res?.success===true) {
            setData(res?.data)
            setLoader('cleaner')
            const jobtyperes = await getJobType(res?.data?.id, undefined)
            if(jobtyperes?.success===true) {
              const allJobType: Set<string> = new Set();
              jobtyperes?.data?.map(item => {
                item?.job_type?.map((item1: string)=>{
                  if(item1===jobData?.title) {
                    setRate(item?.rate)
                  }
                    allJobType.add(item1)
                })
            }
                
            );
              setJobType([...allJobType])
            }
            setLoader('training')
            const trainingres = await getTraining(res?.data?.id, undefined)
            if(trainingres?.success===true)
            setTraining(trainingres.data)
          }
          setLoader('medical')
          const medicalres = await getMedicalCondition(res?.data?.id, undefined)
          if(medicalres?.success===true) {
            setMedical(medicalres.data)
          }
          setLoader('employment')
          const empres = await getEmployment(res?.data?.id, undefined)
          if(empres.success===true) {
            setEmployment(empres?.data)
          }
          fetchProfileImg(res?.data?.id)
          
      }
      catch(e:any) {
setLoader('')
      }
     }
     if(id && jobData) {
      fetchCleaner(id)
     }
  },[id, jobData])

  useEffect(()=> {
    const fetchAllCleaner = async ()=> {
      try {
          const res = await getCleaner(null)
          if(res?.success===true) {
            setAllCleaner(res?.data)
          }
          else {
            
          }
      }
      catch(e:any) {

      }
     }
     fetchAllCleaner()
  },[])

  return (<>
<MainWrapper>
  <ContentWithSpinner loader={loader==='cleaner'} height='h-screen'>
<div className='py-2'>
  <div className='grid grid-cols-12 gap-4'>
    <div className='col-span-3 bg-white rounded-lg'>
    <div className='border-neutral-200 border-b py-4'>
    <h2 className='px-4 text-2xl  text-[#595959]'>4 Cleaner</h2>
    <p className='px-4 text-xs text-[#595959]'>1 of {allCleaner?.length} candidates Cleaner</p>
    </div>
   { allCleaner?.map((data)=> (
    <Link to={`/individual/cleaner-profile-details/${data?.id}`}>
    <div className=' border-neutral-200 border-b py-4'>
    <h2 className='px-4 text-base '>{data?.first_name} {data?.last_name} </h2>
    <p className='px-4 text-xs text-[#595959]'>Bhopal, Madhya Pradesh</p>
    </div>
    </Link>
))}
    </div>
    <div className='col-span-6'>
    <div className='flex'>
    <div className='bg-white rounded-lg p-5 w-[120rem] shadow-boxshadowpage'>
<Profile data={data} jobType={jobType}/>
<div className='flex gap-8 pb-1.5 mt-5'>
                    <span onClick={(e)=> setActiveTab('About')} className={`font-bold text-xl text-[#717b9e] px-3 cursor-pointer	 ${activeTab === 'About' ? 'border-b-4 border-black text-black' : '' }`}>About</span>
                    <span onClick={(e)=> setActiveTab('jobs')} className={`font-bold text-xl text-[#717b9e] px-3 cursor-pointer	 ${activeTab === 'jobs' ? 'border-b-4 border-black text-black' : ''}`}>Completed Jobs</span>
                </div>
                <div className="border-b -mt-1.5"></div>
<div className='mt-8'>
  {activeTab === 'About' &&
    <div>
        <h1 className='text-xl  pb-2'>Training</h1>
       
        <ContentWithSpinner loader={loader==='traning'}>
        {training !='' ? training?.map((item, index)=> (
        <div key={index} className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base  text-black'>{item?.training}</span>
              <div className='text-base'>{DateFormat(item?.date_attended)}
              <p>{item?.description}</p>
            </div>
        </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Training'}}></FormattedMessage></p>
}
</ContentWithSpinner>

        <div>
        <h1 className='text-xl  pb-2'>Medical Condition</h1>
        <ContentWithSpinner loader={loader==='medical'}>
        {
          medical !='' ? medical?.map((item, index)=> (
        <div className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base  text-black'>Condition - {item?.condition}</span>
              <div className='text-base'>Since When - {DateFormat(item?.since_when)}  </div>
              <p>{item?.description}</p>
        </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Medical Condition'}}></FormattedMessage></p>

}
</ContentWithSpinner>
        <div>
        <h1 className='text-xl  pb-2'>Employment</h1>
        <ContentWithSpinner loader={loader==='employment'}>
        {
        employment != '' ?
        employment?.map((item, index)=> (
        <div key={index} className='pb-4 mb-4'>
            <span className='text-base  text-black'>{item?.job_title} </span>
              <div className='text-base'>{item?.company_name}</div>
              <div className='text-base'>{item?.company_location}</div>
              <div className='text-base'>{item?.currently_employed ? DateFormat(item?.working_since_start) : DateFormat(item?.working_since_start) + '-' + DateFormat(item?.working_since_end) }</div>
        
       </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Employment'}}></FormattedMessage></p>

}
</ContentWithSpinner>
       
       </div></div>
 </div>  }
 {activeTab === 'jobs' && 
 [{}, {}, {}].map(()=> (
 <div className='border-b border-neutral-200 pb-4 mt-4'>
 <div className='text-xl  mb-4'> Support existing Node App Backend of mobile Apps</div>
<div className='flex space-x-2 items-center'>
  <div className='flex'>
    {[{}, {}, {}, {}, {}].map(()=> (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#EAB308" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#EAB308]">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>  ))}
</div>
<span className=''>5.00</span>
<span className='text-xs'>Jan 14, 2022 - Aug 25, 2023</span></div>
<p className='italic'>"Very good work I was impressed how he straight fixed the problems in quick turnaround, without the project having a clear documentation"</p>
</div>
 ))}
    </div>
   </div>
   </div>
    </div>
    <div className='col-span-3'>
    <div className='bg-[#FAF9F8] py-10 px-5'>
      <div className='text-center mb-5'>
      <p className=' text-base mb-1'>Rate:</p>
      <span className='text-3xl '>{rate}/hr</span>
      </div>
  <div className='flex w-full justify-center'>
  
<button onClick={()=> setModel(true)} type="submit" className='mt-5  text-[#0078ae] border border-neutral-400 bg-white px-4 py-1 rounded-lg'>Continue</button>
</div>

<hr className='border border-gray-200 my-5' />
      </div>
    </div>
</div></div>
</ContentWithSpinner>
</MainWrapper>
{model && 
    <ModelWrapper showModal={model} background="bg-[#00000099]" opacity="opacity-100" topPosition="top-[10%]" rightPosition="inset-0" HandleModelByProps={HandleModelByProps}  width = "w-[50%] mx-auto" position="fixed" topClass="flex w-full">

    <SelectandContinue item={data} profileImg={profileImg}></SelectandContinue>
    </ModelWrapper>
}





     </> )
}
