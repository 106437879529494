import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getTransaction = async (params) => {
    try {
  return await get<ApiResponse<[]>>(`/wallet/fabric/transaction?${params}`).then((res)=>{
    if(res?.success===true){
        return res
    }
    else {
        return null
    }
  })
}
catch(e){
    HandleApiError(e)
}
};
