import React, {useState, useEffect, useRef} from 'react'
import { RadioInput } from 'sr/helpers/ui-components/RadioInput';
import { InputSearch } from 'sr/helpers/InputSearch';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox';
import { businessTypes } from 'sr/i18n/messages/setting';
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { Checkbox } from 'sr/helpers';
import { ModelWrapper } from 'app/pages/company/component/ModelWrapper';
export const RateRange = (props) => {
  const intl = useIntl()
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [Filterdata, setFilterdata] = useState([]);
    const [minRange, setMinRange] = useState(1)
    const [maxRange, setMaxRange] = useState(100)
    const minRangeRef = useRef<HTMLInputElement>(null);
    const maxRangeRef = useRef<HTMLInputElement>(null);
    const [leftStyle, setleftStyle] = useState("")
    const [rightStyle, setrightStyle] = useState("")

   const [search, setSearch] = useState('')
   useEffect(()=> {
    const filter =  props?.data?.filter((item)=> item?.value?.toLowerCase().includes(search?.toLowerCase()))
    setFilterdata(filter)
     },[search, setSearch])
    const toggleShow = () =>{
        setShow(!show)
    }
    useEffect(()=> {
      const gapRange = 10;
    if(maxRange-minRange<gapRange) {
    if(minRangeRef.current) {
      setMinRange(maxRange-gapRange)
    }
    else {
      setMaxRange(minRange+gapRange)
    }
    }
    else {
    setleftStyle((minRange/100)*100+"%")
    setrightStyle((1-maxRange/100)*100+"%")
    }
    },[minRange, maxRange])
    useEffect(()=> {
props?.HandleRangeData({
  minRange:minRange,
  maxRange:maxRange
})
    },[minRange, maxRange])
    return (
    <>
    <div className='cursor-pointer border-b-[1px]'>
      <div onClick={toggleShow} className='font-bold py-4 flex justify-between'>
      {props.Title} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-gray-500 w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
</svg>
</div>
<div className={`mb-10  overflow-y-auto	${show ? "block": "hidden"}`}>
<div className='mt-3'>
<div className="w-full rounded-full relative bg-gray-200 h-1.5">
        <div className={`bg-[#2557A7] rounded-full absolute left-0 right-0 h-1.5`} style={{left:leftStyle, right:rightStyle}}></div>
     </div>
     <div className="relative  mb-3">
        <input ref={minRangeRef} className="absolute -top-[6px] h-1.5 w-full pointer-events-none range-input"  value={minRange} type="range" min="0" max="100" onChange={(e:any)=> setMinRange(e.target.value)}></input>
        <input ref={maxRangeRef} className="absolute -top-[6px] h-1.5 w-full pointer-events-none range-input" value={maxRange} type="range" min="0" max="100" onChange={(e:any)=> setMaxRange(e.target.value)}></input>
     </div>
     <div className="flex justify-between w-full">
      <span>${minRange}</span>
      <span>${maxRange}</span>
     </div></div>
</div></div>
    </>
  )
}
