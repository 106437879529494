import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "../axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const sendOTP = async (postData:any) => {
    try {
        return await post<ApiResponse<[]>>('/auth/sendotp', postData).then((res)=> {
            if(res.success === true) {
                SuccessMessage("API.SUCCESS.OTP.SEND")
                return res
            }
            else {
                toast.error(res.error.message)
                return null
            }
        })
    }
    catch(e:any) {
        HandleApiError(e);
            return null
    }
};
