import React, {useEffect, useState} from 'react'
import { ChecklistTask } from '../../ChecklistTemplate/ChecklistTemplatedata'
import { Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Button } from 'sr/helpers/ui-components/company/Button'
import { FormattedMessage } from 'react-intl'
import { updateTask } from 'sr/utils/api/individual/tasklist/updateTask'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
export const Task = (props) => {
    const [id, setId] = useState<string>('')
    const [description, setDescription] = useState()
    const [name, setName] = useState()
    const [type, setType] = useState()
    const [loader, setLoader] = useState(false)

    const HandleEdit = async (id) => {
        setLoader(true)
        const payload = {
            id:id,
            description:description,
            name:name,
            type:type
        }
        const res = await updateTask(payload)
        if(res?.success===true) {
                SuccessMessage('API.SUCCESS.INDIVIDUAL.TASK.UPDATE')
                setLoader(true)
                props?.handleAdded('Task')
        }
    }
    useEffect(()=> {
if(id) {
    const editdata = props?.task?.find((item)=> item?.id===id)
    setDescription(editdata?.description)
    setName(editdata?.name)
    setType(editdata?.type)
}
    },[id])
    const handleDelete = async (id)=> {
        setLoader(true)
            const payload = {
                id:id,
                status:'deleted'
            }
            const res = await updateTask(payload)
            if(res?.success===true) {
                SuccessMessage('API.SUCCESS.INDIVIDUAL.TASK.DELETE')
                setLoader(false)
                props?.handleAdded('Task')
            }
    }
  return (
    <>
    {loader && <LoaderFullScreen></LoaderFullScreen>}
    {props?.task?.length > 0 ? (
    <table className="w-full text-left condidate-table">
    <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
        <tr>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.TABLE.COL.TASK" defaultMessage="Task" />
                   </th>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.TABLE.COL.DESCRIPTION" defaultMessage="Description" />
            </th>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.TYPE" defaultMessage="Type" />
            </th>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.ACTIONS" defaultMessage="Action" />
            </th>
        </tr>
    </thead>
    <tbody>
      {props?.task?.map((data, index)=> (
         <tr key={index} className="bg-white border-b  hover:bg-gray-50">
         <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
        {id === data?.id ?  <Input InputDivClass="mt-0"
                onChange={(e)=> setName(e.target.value)}
                value={name}
                    placeholder={''}
                    type={'text'}
                    inputClass = 'border border-zinc-800 rounded-lg h-11'>
                </Input>:   <div className='text-base'>{data.name}</div>}
            
             
         </th>
         <td className="px-6 py-4">
         {id === data?.id ? <textarea onChange={(e:any)=> setDescription(e.target.value)} value={description} id="Description" rows={2} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder="Write your Description here..."></textarea>        
:   data.description }

         </td>
       
 
         <td className="px-6 py-4">
            {id === data?.id ? <DropDown onChange={(e:any)=> setType(e.target.value)} value={type} dropdowndiv='text-gray-700 mt-0' labelMargin="mb-1" className='border border-zinc-800 rounded-lg h-11'>
                    <option value=''>Select Type</option>
                    <option value='Mandatory'>Mandatory</option>
                    <option value='Optional'>Optional</option>
                </DropDown> : data.type}
         
         </td>
         <td className="px-6 py-4">
         {id === data?.id ? 
         <div className='flex justify-center w-auto'>
         <Button onClick={()=> HandleEdit(data?.id)} justify='justify-center' text="Save"></Button>
         <Button onClick={()=> setId('')} justify='justify-center' text="Cancel"></Button>
         </div>
         :
         <div className='flex items-center space-x-4'>
            <div className='border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
              <div onClick={() => {setId(data?.id)}}  className='py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 mt-1">
<path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
</svg>
</div>
<div onClick={()=> handleDelete(data?.id)} className='py-1.5 px-2 cursor-pointer hover:text-[#a9252b] hover:border-2 hover:rounded-r-lg hover:border-[#f76266] hover:bg-[#FEEEEF]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-black">
<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg></div>

            </div></div> }
        </td>
     </tr>
))}
      
    </tbody>
</table>
    ):
    <>
          <p className="text-center">You haven't added any task</p>
        </>
}
</>
  )
}
