import {Children, createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState, useCallback} from 'react'
import * as authHelper from './AuthHelper'
import {AuthModel, UserModel} from './_models'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
type AuthContextProps = {
  // auth: AuthModel | undefined
  // saveAuth: (auth: AuthModel | undefined) => void
  // currentUser: UserModel | undefined
  // //   setter function to set the value in user model
  // setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  persistToken: ()=> void,
  logout: () => void
  isAuthReady:boolean
  isAuthenticate:boolean
  userType:string
}
type WithChildren = {
  children?: ReactNode
}
const initAuthContextPropsState = {
  // auth: authHelper.getAuth(),
  // saveAuth: () => {},
  // currentUser: {id: 6755},
  // setCurrentUser: () => {},
  persistToken: ()=> {},
  logout: () => {},
  isAuthReady:false,
  isAuthenticate:false,
  userType:''
}
// // create auth context
const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)
// console.log(authHelper.getAuth())
// // use auth context
const useAuth = () => {
  return useContext(AuthContext)
}

// // provider auth context
const AuthProvider = ({children}: WithChildren) => {
//   const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
//   const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
    const [isAuthenticate, setIsAuthenticate] = useState<boolean>()
    const [isAuthReady, setIsAuthReady] = useState<boolean>()
    const [userType, setUserType] = useState<string>()
//   const saveAuth = (authParams: AuthModel | undefined) => {
//     // set auth for user
//     setAuth(authParams)
//     if (authParams) {
//       authHelper.setAuth(authParams)
//     } else {
//       authHelper.removeAuth()
//     }
//   }

//   //   logout functionality
//   const logout = () => {
//     saveAuth(undefined)
//     setCurrentUser(undefined)
//   }
const logout = async () => {
  setIsAuthReady(false)
  localStorage.removeItem('auth')
  setIsAuthReady(true)
  setIsAuthenticate(false)
}
const persistToken = ()=> {
  setIsAuthReady(false)
const userdetails =  getUserDetails()
console.log(userdetails);

if(userdetails?.user_id) {
  setIsAuthenticate(true)
  setUserType(userdetails?.userType)
}
else {
  setIsAuthenticate(false)
}
setIsAuthReady(true)
}
useEffect(()=> {
  persistToken()

},[persistToken])

  return (
    // <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
    //   {children}
    // </AuthContext.Provider>

    <AuthContext.Provider value={{isAuthenticate, isAuthReady, logout, persistToken, userType}}>
{children}
    </AuthContext.Provider>
  )
}

// provider auth context
const authInit = () => {}

export {useAuth, AuthProvider}
