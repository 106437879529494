import { parsePhoneNumber } from "libphonenumber-js";
const PhoneNumberFormat = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
  
    // Remove non-digit characters
    const digitsOnly = phoneNumber.replace(/\D/g, '');
  
    // Check if the remaining string is a 10-digit number
    if (/^\d{10}$/.test(digitsOnly)) {
      // Apply the US phone number format
      return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
    } else {
      // Return the original input if it doesn't match the expected format
      return phoneNumber;
    }
  };
  
export default PhoneNumberFormat;