import { isFulfilled } from "@reduxjs/toolkit";
import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const applyWorkOrder = async (postData:any) => {
    try {
  return await post<ApiResponse<[]>>('/workorderapplication',postData).then((res)=> {
        if(res?.success===true){
            SuccessMessage('API.SUCCESS.APPLY.WORKORDER')
                return res
        }
        else {
            return null
        }
  });
}
catch(e) {
    HandleApiError(e)
}
};
