import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext(null);

    let socket;
    export const SocketProvider = ({ children }) => {
      const [socket, setSocket] = useState(null);
      useEffect(() => {
        const newSocket = io(process.env.REACT_APP_SOCKET, {
            transports: ["websocket"], 
        });

        setSocket(newSocket);

        newSocket.on("connect", () => {
          console.log("Connected to socket server");
        });

        return () => {
            newSocket.disconnect();
        };
    }, []);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export const useSocket = () => useContext(SocketContext);
