import React from "react";
import { Spinner } from "./Spinner";

export const ButtonAuth = ({ children, loader=null, onClick=null, index=null, className="w-44" }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className={`${className} rounded-full
                 bg-[#ff39b3] font-bold
                 p-2
                 text-white
                 
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy`}
      disabled={loader} // Disable the button when loader is true
    >
      {loader ? <Spinner className='flex w-full justify-center'/> : children}
    </button>
  );
};
