import React from "react";

export const TimeFormat = (inputDate) => {
    const dateObject = new Date(inputDate);
  const hours = dateObject.getUTCHours();
  const minutes = dateObject.getUTCMinutes();
  const seconds = dateObject.getUTCSeconds();
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  return formattedTime;
};
