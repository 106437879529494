import React, {useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { Checkbox } from 'sr/helpers'
import { Filter } from './component/Filter'
import { Input } from 'sr/helpers'
import { WhiteBtn } from './component/WhiteBtn'
import { MSTableRow } from './ManageSchedule/components/MSTableRow'
import { Link } from 'react-router-dom'
export const ManageSchedule = () => {
    const [selectedCustomer, SetSelectedCustomer] = useState<any[]>([])
    const [activeTab, setActiveTab] = useState('SCHEDULE')
    const [PublishPending, setPublishPending] = useState(false)
    const TabData = [
         {tabname:"SCHEDULE",
         },
         {tabname:"TEAM",
         },
         {tabname:"RECURRING",
         },
         {tabname:"RECURRING REPORT",
         },
      ]
      const HandleActiveTab = (active) => {
        setActiveTab(active)
    }
  return (
    <MainWrapper>
    <div className="py-3 h-screen">
        <div className='mb-2 mt-5'>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'>Manage Schedule</h1>
    </div>
    <div className='flex-1 flex space-x-10 overflow-hidden mt-5 mb-5 border-b border-neutral-500'>
  { TabData.map((data)=> (
    <span onClick={()=> HandleActiveTab(data.tabname)} className={`${activeTab === data.tabname ? "text-[#2557A7] border-b-2 border-[#2557A7]":""} hover:text-[#2557A7] font-satoshiBold text-base inline-flex flex-shrink-0 cursor-pointer pb-1`}>{data.tabname}</span>
    )) }
</div>
<div className='flex items-center space-x-2 mb-3'>
<Filter label="Customer" name="Show All" mainClass="flex-1">
  <Checkbox checked={selectedCustomer.includes('Lucknow, Uttar Pradesh')}  label="Lucknow, Uttar Pradesh" id="Lucknow" name="Lucknow, Uttar Pradesh" value={"Lucknow, Uttar Pradesh"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Delhi, Delhi')} label="Delhi, Delhi" id="Delhi" name="Delhi, Delhi" value={"Delhi, Delhi"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Patna, Bihar')} label="Patna, Bihar" id="Patna" name="Patna, Bihar" value={"Patna, Bihar"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
  </Filter>
  <Filter label="Location" name="Show All" mainClass="flex-1">
  <Checkbox checked={selectedCustomer.includes('Lucknow, Uttar Pradesh')}  label="Lucknow, Uttar Pradesh" id="Lucknow" name="Lucknow, Uttar Pradesh" value={"Lucknow, Uttar Pradesh"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Delhi, Delhi')} label="Delhi, Delhi" id="Delhi" name="Delhi, Delhi" value={"Delhi, Delhi"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Patna, Bihar')} label="Patna, Bihar" id="Patna" name="Patna, Bihar" value={"Patna, Bihar"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
  </Filter>
  <Filter label="Employee" name="Show All" mainClass="flex-1">
  <Checkbox checked={selectedCustomer.includes('Lucknow, Uttar Pradesh')}  label="Lucknow, Uttar Pradesh" id="Lucknow" name="Lucknow, Uttar Pradesh" value={"Lucknow, Uttar Pradesh"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Delhi, Delhi')} label="Delhi, Delhi" id="Delhi" name="Delhi, Delhi" value={"Delhi, Delhi"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Patna, Bihar')} label="Patna, Bihar" id="Patna" name="Patna, Bihar" value={"Patna, Bihar"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
  </Filter>
  <Filter label="Schedule Type" name="Show All" mainClass="flex-1">
  <Checkbox checked={selectedCustomer.includes('Lucknow, Uttar Pradesh')}  label="Lucknow, Uttar Pradesh" id="Lucknow" name="Lucknow, Uttar Pradesh" value={"Lucknow, Uttar Pradesh"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Delhi, Delhi')} label="Delhi, Delhi" id="Delhi" name="Delhi, Delhi" value={"Delhi, Delhi"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Patna, Bihar')} label="Patna, Bihar" id="Patna" name="Patna, Bihar" value={"Patna, Bihar"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
  </Filter>
    </div>
    <div className='flex items-center  justify-between space-x-2 border-b border-neutral-500 pb-3 mb-3'>
    <div className='flex items-center space-x-2 '>
  <Filter label="Payroll" name="Show All">
  <Checkbox checked={selectedCustomer.includes('Lucknow, Uttar Pradesh')}  label="Lucknow, Uttar Pradesh" id="Lucknow" name="Lucknow, Uttar Pradesh" value={"Lucknow, Uttar Pradesh"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Delhi, Delhi')} label="Delhi, Delhi" id="Delhi" name="Delhi, Delhi" value={"Delhi, Delhi"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
<Checkbox checked={selectedCustomer.includes('Patna, Bihar')} label="Patna, Bihar" id="Patna" name="Patna, Bihar" value={"Patna, Bihar"} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
  </Filter>
  <Input label="Start date" inputClass="rounded-lg" labelClass="mt-0 text-black" InputDivClass="mt-0" type="date" placeholder="Enter company joining date"></Input>
 <Input label="End date" inputClass="rounded-lg" labelClass="mt-0 text-black" InputDivClass="mt-0" type="date" placeholder="Enter company joining date"></Input>
 <div className='mb-2'>
    <label className='font-satoshiBold' htmlFor='Publish Pending'>Publish Pending</label>
 <Checkbox divclass="flex justify-center mt-3" label='' id='Publish Pending' checked={PublishPending} value={PublishPending} onChange={() => setPublishPending(!PublishPending)}/>
 </div>
 <WhiteBtn mainClass="mt-5" title="Refresh" svg={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>
}></WhiteBtn>
<WhiteBtn mainClass="mt-5"  title="Clear"></WhiteBtn>
</div>
<div className='flex items-center space-x-2 '>
<WhiteBtn mainClass="mt-5"  title="Publish Schedule"></WhiteBtn>
<Link to="/schedule-work-order">
<WhiteBtn mainClass="mt-5"  title="Add Schedule"></WhiteBtn>
</Link>
</div>
    </div>  
    <div className="overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
                <th scope="col" className="p-4">
                    <div className="flex items-center">
                        <input id="checkbox-all" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>
                        <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
                    </div>
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    Location
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    Employee
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    Schedule Date
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    Start Date
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    End Time
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    Type
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    Flag Rate
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
          {[{},].map((data)=> (
        <MSTableRow />
))}
          
        </tbody>
    </table>
<div className='flex items-center justify-center space-x-4 my-3'>
<div className='bg-white text-[#2557A7] cursor-pointer py-3 w-32 border justify-center border-neutral-200 hover:border-[#2557A7] rounded-lg flex space-x-2 items-center'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>

  <span className='font-satoshiBold'>Previous</span>
 </div> 
 <div className='bg-white text-[#2557A7] cursor-pointer py-3 w-32 border justify-center border-neutral-200 hover:border-[#2557A7] rounded-lg flex space-x-2 items-center'>
  <span className='font-satoshiBold'>Next</span>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

 </div> 
  </div>
</div>
    </div>
    </MainWrapper>
  )
}
