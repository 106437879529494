import React from 'react'

export const CheckBox = ({ value, onChange, checked, label=null }) => {
  return (
    <label className={`${checked ? 'bg-[#767676] text-white group-hover:bg-[#595959] hover:bg-[#595959]': ''} flex items-center border border-gray-500 py-1 px-4 rounded-3xl relative space-x-4 my-1.5 cursor-pointer`}>
    <input
      id="inline-checkbox"
      type="checkbox"
      value={value}
      onChange={onChange}
      checked = {checked}
      className={`${checked ? 'accent-[#767676]': 'appearance-none'} w-4 h-4 border-none bg-transparent rounded`}/>
    
           <span> {label || value}</span>
           {!checked && 
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 absolute left-0"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg> }
            </label>
       
  )
}
