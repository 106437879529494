import { toast } from "react-toastify";
import getCardDetail from "sr/helpers/apiFunctions/strip/getCardDetails";
import { createStripPayment } from "sr/utils/api/strip/createStripPayment";
import { getCardDetails } from "sr/utils/api/strip/getCardDetails";
import { getDefaultCard } from "sr/utils/api/strip/getDefaultCard";
import { getStripCustomer } from "sr/utils/api/strip/getStripCustomer";
import { updateWalletBalance } from "sr/utils/api/wallet/updateWalletBalance";

interface paramsType  {
companyId:string,
amount:number
}
export const AutoPaymentDebit = async (params:paramsType) => {
    try {
    const queryParams = new URLSearchParams()
    queryParams.append('company_id', params?.companyId)
const res = await getDefaultCard(queryParams)
if(res?.success===true){
    const queryParams1 = new URLSearchParams()
    queryParams1?.append('cleanpig_id', params?.companyId)
    const rescustomer = await getStripCustomer(queryParams1)
    if(rescustomer?.success===true){
        const cardresult = await getCardDetails(rescustomer?.data?.stripe_customer_id)
        if(cardresult?.success===true){
    const data = cardresult?.data?.data?.find((item)=> item?.card?.last4===res?.data?.card)
        if(data){
            const payload = {
                      customer:data?.customer,
                      payment_method:data?.id,
                      currency:'USD',
                      amount:Number(params?.amount)*100
                    }
                    const res = await createStripPayment(payload)
                    if(res?.success===true){
                        const payloadwallet = {
                                    to:params?.companyId,
                                    tokenId:'1271231',
                                    amount:Number(params?.amount),
                                    data:'initial top up'
                                  }
                    const walletres = await updateWalletBalance(payloadwallet)
                    if(walletres?.success===true){
                        return true
        }
    }
        else {
            return null
        }
    }
    
    }
    else{
        toast.error('Card Details Not Found')
        return null
    }
    }
    else{
        toast.error('Strip Customer Not Found')
        return null
    }
}
else{
    toast.error('Default Card Not Found')
    return null
}
}
catch(e){
    return null
}
    }