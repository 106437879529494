type Props = {
    onClick?: any
    buttoncss?:any
    className?: any
    color?: any
    icon?: any
    text?: any
    children?: any
    hovertext?:any
    bordersize?:any
    hoverbg?:any
    hoverbordercolor?:any
    justify?:any
    margin?:any
  }
  const Button = ({onClick, className, color, buttoncss, text, hovertext, bordersize, hoverbg, hoverbordercolor, justify, margin}: Props) => {
    return (
      <>
      <div className={`flex w-full ${margin} ${justify}`}>
                        <button
                            onClick={onClick}
                            type='submit'
                            className={`${buttoncss} rounded-lg
                                    font-bold
                        ${hoverbg}
                        ${bordersize}
                        py-2.5 px-6
                        ${hovertext}
                        text-white
                        cursor-pointer
                        `}
                        >{text}
                        </button></div>
      </>
    )
  }
  
  Button.defaultProps = {
    color: 'rose',
    className: 'w-full text-gray-900',
    hovertext:'hover:text-white',
    buttoncss:'bg-[#2557A7]',
    bordersize:'border-2',
    hoverbg:'hover:bg-navy',
    hoverbordercolor:'hover:border-[#C7D2F6]',
    justify:"justify-end",
    margin:"mt-4"
    
  }
  
  export {Button}