import React, {useEffect, useRef, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Checklist } from './components/Tables/Checklist'
import  ChecklistForm from './components/forms/ChecklistForm'
import  TaskForm from './components/forms/TaskForm'
import { Task } from './components/Tables/Task'
import { ModelWrapperWithButton } from '../component/ModelWrapperWithButton'
import { WhiteBtn } from '../jobs/component/WhiteBtn'
import { ChecklistAdd } from './components/ChecklistAdd'
import { FormattedMessage } from 'react-intl'
import { getTasklist } from 'sr/utils/api/individual/tasklist/getTasklist'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { getTasks } from 'sr/utils/api/individual/tasklist/getTasks'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { useNavigate } from 'react-router-dom'
import { Button } from 'sr/helpers/ui-components/company/Button'
import { updateTask } from 'sr/utils/api/individual/tasklist/updateTask'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
import { updateTasklist } from 'sr/utils/api/individual/tasklist/updateTasklist'
export const ChecklistManage = () => {
    const [activeComponent, setActiveComponent] = useState<any>({});
    const [showModel, setShowModel] = useState(false)
    const [checklistAdd, setChecklistAdd] = useState(false)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [userId, setUserId] = useState<string>()
    const [id, setId] = useState<string>('')
    const [task, setTask] = useState()
    const [buttonLoader, setButtonLoader] = useState(false)

    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserId(user_id)
    },[])
    const AllModel = [
        {name:'ChecklistEdit',
        componentName:ChecklistForm },
        {name:'Task',
        componentName:Task }
    ]
    interface TabRef {
      submitForm: () => void;
    }

    const childRef = useRef<TabRef>(null);
    const HandleFormSubmit = () => {
        if (childRef.current && childRef.current.submitForm) {
          childRef.current.submitForm();
        }
      };

    const openPopup = (index: any) => {  
        const activeComp = AllModel.filter(function (e, i) {
          console.log(i == index)
          return i == index
        });      
        setActiveComponent(activeComp[0]);
        setShowModel(true)
      };
      const HandleModel = () => {
        if (childRef.current && childRef.current.submitForm) {
          childRef.current.submitForm();
        }
        setId('')
        setShowModel(false)
        navigate('/individual/tasklist/')
    }
    const HandleChecklistModel = (data) => {
      setChecklistAdd(false)
    }
    const fetchTasklisk = async ()=> {
      setLoader(true)
      const params = new URLSearchParams()
      params.append('individual_id', userId)
      params.append('status!', 'deleted')
      try {
          const res = await getTasklist(params)
          if(res?.success===true) {
            setData(res?.data)
            setLoader(false)
          }
          else {
            setLoader(false)
          }
      }
      catch(e:any) {
setLoader(false)
      }
    }
    useEffect(()=> {
        if(userId) {
          fetchTasklisk()
        }
    },[userId])
    const navigate = useNavigate()
    const fetchTask = async (id)=> {
      setButtonLoader(true)
      try {
      const params = new URLSearchParams()
      params.append('checklist_id', id)
      params.append('status!', 'deleted')
          const res = await getTasks(params)
          if(res?.success===true){
            setTask(res?.data)
            openPopup(1)
            navigate(`/individual/tasklist/${id}`)

            setButtonLoader(false)
          }
          else {
            setButtonLoader(false)
          }
        }
        catch(e:any) {
          setButtonLoader(false)
        }
    }
    useEffect(()=> {
      if(id) {
        fetchTask(id)
      }
    },[id])
    const handleAdded = (data) => {
      if(data==='Task') {
          fetchTask(id)
      }
      if(data===true) {
        setShowModel(false)
        fetchTasklisk()
        navigate('/individual/tasklist/')
      }
    }
const HandleDelete = async (id)=> {
const payload = {
  id:id,
  status:'deleted'
}
const res = await updateTasklist(payload)
if(res?.success===true) {
  SuccessMessage('API.SUCCESS.INDIVIDUAL.TASKLIST.DELETED')
  setData(data?.filter((item)=> item?.id !== id))
}
}

const handleEdit = (id) => {
navigate(`/individual/tasklist/${id}`)
openPopup(0)
}
const onCheckModel = async ()=> {
  setShowModel(false)
}
  return (
    <MainWrapper>
      <ContentWithSpinner loader={loader} height='h-screen'>
        {buttonLoader && <LoaderFullScreen />}
    <div className='py-3 px-10 mx-auto'>
    <div className='mb-2'>
      <div className='flex items-center justify-between'>
      <div>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TITLE" defaultMessage="Manage Task List" /></h1>
    <p><FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TEXT" defaultMessage="Use this page to manage your Task List" /></p>
   </div>
   <WhiteBtn onClick={()=> setChecklistAdd(true)} svg={ <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>} title={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.ADDTASKLIST.BUTTON" defaultMessage="Add Tasklist" />}></WhiteBtn></div></div>
    <Checklist  data={data} setId={setId} HandleDelete={HandleDelete} handleEdit={handleEdit} />       
    <ModelWrapperWithButton showModal={showModel} onCheckModel={onCheckModel} Save={activeComponent?.name==='checklistEdit' ? HandleFormSubmit : HandleModel}>
              {<activeComponent.componentName task={task} ref={childRef} handleAdded={handleAdded} />}
              {activeComponent.componentName ===Task &&
              <>
              <TaskForm dataAddedHandle={handleAdded} ref={childRef}></TaskForm>
              <Button onClick={HandleFormSubmit} text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.SAVE.TASK.BUTTON" defaultMessage="Save Task" />}></Button> 
              </>
              }
             </ModelWrapperWithButton>
       <ChecklistAdd showModal={checklistAdd} HandleChecklistModel={HandleChecklistModel} fetchTasklisk={fetchTasklisk}/>
    </div>
    </ContentWithSpinner>
    </MainWrapper>
  )
}
