import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";


export const getCompany = async (queryParams) => {
  try {
    return await get<ApiResponse<[]>>(`/company/?${queryParams}`).then((res)=> {
        if(res.success===true) {
            return res
        }
        else {
            return null
        }
    })
  }
  catch(e:any){
    console.log(e.response.errror);
    
  }
};
