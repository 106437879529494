import React, { useState, useRef, useEffect } from "react";
import { Checkbox, index } from "sr/helpers";
import { ModelWrapper } from "../../component/ModelWrapper";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Job from "app/pages/component/types/Job";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { jobStatus } from "sr/i18n/messages/setting";
import { updateBasicDetails } from "sr/utils/api/cleaner/company/jobPost/updateBasicDetails";
interface ChildProps {
  jobs: Job;
  fetchJobF:any
}
export const JobsListingCard: React.FC<ChildProps> = ({ jobs, fetchJobF }) => {
  const [showModal, setShowModal] = useState(false);
  const HandleModelByProps = (data) => {
    setShowModal(data);
  };
  const intl = useIntl();
  const HandleJobStatus = async (e, id) => {
    const data = {
      id:id,
      status:e.target.value
    }
    const res = await updateBasicDetails(data)
    if(res.success===true) {
      fetchJobF()
    }
  }
  return (
    <div className='bg-white px-6 py-6 lg:px-3 lg:py-3 xl:py-6 xl:px-6  rounded-[20px] mb-6'>
      <div className=" w-full flex items-center pb-3 xl:space-x-10 lg:space-x-10 md:space-x-5 2xl:space-x-16">
        <div className="flex items-center xl:space-x-6 lg:space-x-3">
          <div>
            <Link to={`/postedjob-details/${jobs?.id}`}>
              <div className="text-base font-satoshiBold text-[#2557A7] cursor-pointer">
                {jobs?.job_title}
              </div>
              <div className="text-base">{jobs?.job_advanced_id?.reporting_address?.address_line_1} {jobs?.job_advanced_id?.reporting_address?.address_line_2}  {jobs?.job_advanced_id?.reporting_address?.city} {jobs?.job_advanced_id?.reporting_address?.state} </div>
              <p className="text-base text-gray-600">
                <FormattedMessage
                  id="RECRUITER.POSTED.JOB.POSTED.TIME.TITLE"
                  defaultMessage="Posted"
                />{" "}
                : {DateFormat(jobs?.createdAt)}
              </p>
            </Link>
          </div>
        </div>
        <div>
          <p>{jobs?.application_status_counts?.active || 0}</p>
          <p>
            <FormattedMessage
              id="RECRUITER.POSTED.JOB.ACTIVE.TITLE"
              defaultMessage="Active"
            />
          </p>
        </div>
        <div className="bg-[#FAF9F8] flex items-center justify-start space-x-20 px-10 py-4">
          <div>
            <p>{jobs?.application_status_counts?.rejected || 0}</p>
            <p>
              <FormattedMessage
                id="RECRUITER.POSTED.JOB.NEW.REJECTED"
                defaultMessage="Rejected"
              />
            </p>
          </div>
          <div>
            <p>{jobs?.application_status_counts?.contacting || 0}</p>
            <p>
              <FormattedMessage
                id="RECRUITER.POSTED.JOB.CONTACTING.TITLE"
                defaultMessage="Contacting"
              />
            </p>
          </div>
          <div>
            <p>{jobs?.application_status_counts?.hired || 0} of {jobs?.job_advanced_id?.hire_count ||1}</p>
            <p>
              <FormattedMessage
                id="RECRUITER.POSTED.JOB.HIRED.TITLE"
                defaultMessage="Hired"
              />
            </p>
          </div>
        </div>
        <div>
          <FormattedMessage
            id="RECRUITER.POSTED.JOB.ADSTATUS.FREE"
            defaultMessage="Free"
          />
        </div>
        <DropDown
        onChange={(e)=> HandleJobStatus(e, jobs?.id)}
        value={jobs?.status}
          dropdowndiv="mt-0"
          label={intl.formatMessage({
            id: "RECRUITER.POSTED.JOB.JOBSTATUS.TITLE",
            defaultMessage: "Job Status",
          })}
          labelMargin="mb-1"
          className="rounded-lg"
        >
          {jobStatus.map((item, index)=> (
          <option key={index}  value={item?.value}> {item?.label}
          </option>
          ))}
        </DropDown>
        <div className="flex items-center space-x-4">
          <div
            onClick={() => setShowModal(!showModal)}
            className="p-2 hover:border hover:border-neutral-200 rounded-lg cursor-pointer relative"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
              role="img"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="w-6 h-6 text-[#2557A7]"
            >
              <path d="M7 12c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm3 0c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2zm7 0c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z"></path>
            </svg>
            {showModal ? (
              <>
                <ModelWrapper
                  rightPosition="right-0"
                  width="w-40"
                  border="border-black"
                  HandleModelByProps={HandleModelByProps}
                  showModel={showModal}
                >
                  <ul className="px-2 pb-2 font-satoshiBold text-base">
                    <Link to={`/job-post/review/${jobs?.id}`}>
                    <li className="py-2">
                      <FormattedMessage
                        id="RECRUITER.POSTED.JOB.ACTION.EDITJOB"
                        defaultMessage="Edit Job"
                      />
                    </li></Link>

                    {/* <li className="py-2">
                      <FormattedMessage
                        id="RECRUITER.POSTED.JOB.ACTION.SPONSOR"
                        defaultMessage="Sponsor your job"
                      />
                    </li>
                    <li className="py-2">
                      <FormattedMessage
                        id="RECRUITER.POSTED.JOB.ACTION.VIEWJOB"
                        defaultMessage="View job details"
                      />
                    </li> */}
                    <li className="py-2">
                      <Link target="_blank" to={`/job-detail/${jobs?.id}`}>
                      <FormattedMessage
                        id="RECRUITER.POSTED.JOB.ACTION.VIEWPUBLICJOB"
                        defaultMessage="View public job details"
                      />
                      </Link>
                    </li>
                  </ul>
                </ModelWrapper>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* <div
        className={`flex items-center rounded-lg space-x-4 px-4 py-4 border bg-[#FDEFE9] border-[#F7C9B3] ml-10 `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          focusable="false"
          role="img"
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="w-5 h-5 text-[#B16300]"
        >
          <path
            fillRule="evenodd"
            d="M12.432 2.248a.5.5 0 00-.864 0l-10.5 19a.5.5 0 00.433.752H22.5a.5.5 0 00.432-.752l-10.5-19zm.569 12.502a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v5.5zm0 4a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className="flex-1">
  <p className="text-base">
    <FormattedMessage
      id='RECRUITER.POSTED.JOB.WARNING'
      defaultMessage='This job has been flagged and must be sponsored to go live on There.'
    />
  </p>
</div>
<div className="bg-white rounded-lg border border-neutral-200 py-2 px-3 text-[#2557A7] font-satoshiBold">
  <FormattedMessage
    id='RECRUITER.POSTED.JOB.SPONSER.BUTTON'
    defaultMessage='Sponsor Now'
  />
</div>

      </div> */}
    </div>
  );
};
