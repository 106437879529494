import React from 'react'
import { FormattedMessage } from 'react-intl'
export const BranchInfoView = ({data}) => {
  return (
    <div className='py-3 px-10'>
         <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'>Branch Name:</span>
      <span>{data?.branch_name}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'>Type:</span>
      <span>{data?.type}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'>Phone No:</span>
      <span>{data?.phone_number}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'>Address 1:</span>
      <span>{data?.address?.address_line_1}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'>Address 2:</span>
      <span>{data?.address?.address_line_2}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.CITY" defaultMessage="City" />:</span>
      <span>{data?.address?.city}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.STATE" defaultMessage="State" />:</span>
      <span>{data?.address?.state}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ZIPCODE" defaultMessage="Zip Code" />:</span>
      <span>{data?.address?.postal}</span>
    </div>
    {/* <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.COUNTRY.TITLE" defaultMessage="Country" />:</span>
      <span>{data?.address?.postal}</span>
    </div> */}
    
    
  </div>
  )
}
