import React, {useEffect, useState} from "react";
import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isEqual,
    isSameDay,
    isSameMonth,
    isToday,
    isValid,
    parse,
    parseISO,
    startOfToday,
  } from 'date-fns'
import { FormattedMessage } from "react-intl";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { fetchWorkOrder } from "sr/helpers/apiFunctions/WorkOrder/fetchWorkOrder";
import { useAuth } from "app/pages/module/auth";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
export const Calendar = () => {
    let today = startOfToday()
    let [selectedDay, setSelectedDay] = useState(today)
    let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
    const [status, setStatus] = useState<any>(['publish'])
    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
    const [cleanerId, setCleanerId] = useState('')
    const [filterData, setFilterData] = useState([])
    const {getData:getWorkOrders, data:data, loader:loader, pageination:pageination}:any = useGet()
        const userType = useAuth()
    useEffect(()=> {
            const {user_id} = getUserDetails()
            setCleanerId(user_id)
    },[])

    useEffect(()=> {
      const fetchWorkOrder = async (cleanerId)=> {
          const queryParams = new URLSearchParams()
          if (cleanerId) queryParams.append('cleaner_id', cleanerId)
           queryParams?.append('page', '1')
           queryParams?.append('limit', '1000')
              queryParams?.append('status', 'publish')

            let obj = {};
            let filter = {}
            if (Object.keys(obj).length>0) {
             filter['$and'] = [obj]
            }
            queryParams?.append('filter', JSON.stringify(filter))
            await getWorkOrders(() => getWorkorder(queryParams));
      }
      if(cleanerId) {
        fetchWorkOrder(cleanerId)
      }
  },[cleanerId])
  
    let days = eachDayOfInterval({
      start: firstDayCurrentMonth,
      end: endOfMonth(firstDayCurrentMonth),
    })
  
    function previousMonth() {
      let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
      setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }
  
    function nextMonth() {
      let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
      setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }
    useEffect(()=> {
        if(data?.length>0 && currentMonth) {
      const filterorder =  data?.filter((item)=> {
        try {
            const date = new Date(item?.one_time_date)
            if (isValid(date)) {

               return ((format(date, 'MMM-yyyy')===currentMonth) && item?.workorder_status==='scheduled')
            }
        }
        catch(e){

        }
        })
        console.log(filterorder);
        
        setFilterData(filterorder)
    }
    },[currentMonth, data])

    const FindMeeting = (day)=> {
        const date = new Date(day);
const currentDate = date.getDate();

const filterorder = filterData?.filter((item)=> {
    const orderDate = new Date(item?.one_time_date);
const ordercurrentDate = orderDate.getDate();
return ordercurrentDate===currentDate
})
return filterorder
    }
  
    return (
      <>
      <AuthNavComponent isLoggedin={true}></AuthNavComponent>
      <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
      <div className='mb-3'>
    <h1 className='text-2xl  border-r border-neutral-200 pr-4'><FormattedMessage id="RECRUITER.WORKORDER.CALENDAR.TITLE" defaultMessage="Work Order Calendar" /></h1>
    <p><FormattedMessage id="RERCRUITER.WORKORDER.CALENDAR.TEXT" defaultMessage="Use this page to view Recurring Work Order" /></p>
   </div>
        <div className="p-4 mx-auto bg-white border border-gray-border rounded-xl">
          <div className="">
            <div className="">
              <div className="flex items-center">
                <h2 className="flex-auto text-gray-900 font-semibold    text-xl">
                  {format(firstDayCurrentMonth, 'MMMM yyyy')}
                </h2>
                <button
                  type="button"
                  onClick={previousMonth}
                  className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Previous month</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>

                </button>
                <button
                  onClick={nextMonth}
                  type="button"
                  className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Next month</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

                </button>
              </div>
              <div className="grid grid-cols-7 mt-2 text-xs leading-6 text-center text-gray-500">
                <div>SUN</div>
                <div>MON</div>
                <div>TUE</div>
                <div>WED</div>
                <div>THU</div>
                <div>FRI</div>
                <div>SAT</div>
              </div>
              <div className="grid grid-cols-7 mt-2 text-sm">
                {days.map((day, dayIdx) => (
                  <div
                    key={day.toString()}
                    className={classNames(
                      dayIdx === 0 && colStartClasses[getDay(day)],
                      ' border-neutral-200 border-[1px] p-5'
                    )}
                  >
                    <div
                  
                      className={
                        classNames(
                        isEqual(day, selectedDay) && '',
                        !isEqual(day, selectedDay) &&
                          isToday(day) &&
                          ' text-black',
                        !isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          isSameMonth(day, firstDayCurrentMonth) &&
                          'text-gray-900',
                        !isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          !isSameMonth(day, firstDayCurrentMonth) &&
                          'text-gray-400',
                        isEqual(day, selectedDay) && isToday(day) && ' text-black',
                        isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          'bg-gray-900',
                        !isEqual(day, selectedDay) && '',
                        (isEqual(day, selectedDay) || isToday(day)) &&
                          'font-semibold',
                        'mx-auto flex items-center justify-end rounded-full'
                      )}
                    >
                      <time dateTime={format(day, 'yyyy-MM-dd')}>
                        {format(day, 'd')}
                      </time>
                    </div>
  {FindMeeting(day)?.map((item)=> (
                    <div className="mx-auto mt-1 bg-[#ff39b3] font-bold text-white rounded-sm ">{item?.title}</div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
       
          </div>
        </div>
      </div>
      </>
  )};

  let colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
  ]  