import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const addJob = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/individual/job', postData).then((res)=> {
        if(res?.success===true){
            return res
        }
        else {
            return null
        }
  })
}
catch(e){
    HandleApiError(e)
}
};
