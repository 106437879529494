import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faLocationDot, faNoteSticky, faStar, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import JobItemCard from '../component/JobItemCard'
import Footer from 'app/pages/module/Footer'
import AuthNavComponent from 'app/pages/module/auth/AuthNav'
import { FormattedMessage } from 'react-intl'
export default function IndividualSavedJobs() {
    const data: any = {
        id: '398598-98w98e9-9090',
        jobTitle: 'Executive Housekeeper',
        location: 'Pune, Maharashtra',
        jobType: 'Regular/Permenent',
        schedule: 'Morning shift',
        description: 'Supervises all housekeeping employees, hires new employees as needed, discharges employees when necessary and take disciplinary actions when policies are not followed. Evaluates employees in order to upgradethem when openings arise. Plans the work for the housekeeping departmentand distributes assignments accordingly. Assigns regular duties and special duties for the housekeeping staff. Schedulesemployees and assigns extra days off according to occupancyforecast. Maintains a time logbook of all employees within the department. Recruit and train new employees. Assigns new employees to work with experienced help. Checks on the work of these employees occasionally and observes the report made by the supervisors. Approves all supply requisitions, such as those for spreads and bathroom rugs. Maintains a lost-and-found department and is responsible for all lost-and-found items. Determines the rightful owner and send correspondences.',
    };
    return (
        <>
            <AuthNavComponent isLoggedin={true}></AuthNavComponent>
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className='lg:flex flex-col lg:w-2/3 block'>
                        <div className='card-1 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <div className='header'>
                            <h1 className='font-medium text-4xl inline'>02</h1>&nbsp;&nbsp;
                                <span className='font-semibold text-sm text-gray-600'>  <FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.SAVEDJOBS.TITLE" defaultMessage="Saved Jobs" />  </span>
                            </div>
                        </div>

                        <div>
                            {
                                [{}, {}].map((datI) => {
                                    return <JobItemCard jobs={data} isSavedJobs={true} link={'/individual/job-detail'}></JobItemCard>
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}
