const AllCoordinate = [
    {
        "stateName": "BIHAR",
        "stateId": "10",
        "districtName": "Araria",
        "districtId": "67",
        "productCategory": "Seeds",
        "productSubCategory": "Fruits and Vegetable Crops",
        "productName": "RADISH SOMANI CROSS 35",
        "value": 39250,
        "earliestSaleDate": "03-08-2022",
        "latestSaleDate": "27-09-2022",
        "districtGeojson": [
                    [
                        77.28436637003713,
                        9.271303803885298
                    ],
                    [
                        77.28459155812011,
                        9.26676579548729
                    ],
                    [
                        77.28269029522251,
                        9.264188029514616
                    ],
                    [
                        77.28245283789614,
                        9.252569098465736
                    ],
                    [
                        77.27928124938919,
                        9.249786208522341
                    ],
        ]
    }
]
export default AllCoordinate