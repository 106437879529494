import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createIndividual = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/individual', postData).then((res)=> {
        if(res?.success===true) {
            SuccessMessage('API.SUCCESS.CLEANER.INDIVUDUAL')
            return res

        }
        else {
            return null
        }
  })
}
catch(e:any){
HandleApiError(e)
}
};
