import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faLocationDot, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { number } from 'yup';
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs';
import { getCompany } from 'sr/utils/api/company/getCompany';
import { getFiles } from 'sr/utils/api/getFiles';
import { getFile } from 'sr/utils/api/getFile';
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
export const OverviewSidebar = ({data}) => {
  const [id, setId] = useState('')
  const [jobs, setJobs] = useState<any[]>([]);
  const [companies, setCompanies] = useState(null)
  const [loader, setLoader] = useState(false)
  const params = useParams()
  useEffect(()=> {
    const {id} = params
    setId(id)
  },[params])
  useEffect(()=> {
    const fetchData = async ()=> {
      const queryParams = new URLSearchParams
                 if (id) queryParams?.append('company_id', id)
          const result: any= await getJobs(queryParams)
          if(result?.success === true){
          setJobs(result?.data);
  }}
  if(id) {
   fetchData()   
  }
  }, [id])

useEffect(()=> {
const getSimilarCompanies = async ()=> {
  setLoader(true)
  try {
  const filter = { "business_type": {"$in": data?.business_type} }
  const queryParams = new URLSearchParams(); 
        if (filter) queryParams.append('filter', JSON.stringify(filter)); 
const result = await getCompany(queryParams)
if(result?.success===true) {
  let company = []
  for(let i = 0; i<=result?.data?.length; i++) {
    const params = {
      company_id:result?.data[i]?.id
    }
const profileImg = await getFiles(params)
if(profileImg?.success===true) {
  const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
  if(filterfile && filterfile[0]?.file?.key) {
    const fileimg = await getFile(filterfile[0]?.file?.key);
    const newImageUrl = URL.createObjectURL(fileimg || '');
    console.log(i);
    const obj = {newImageUrl:newImageUrl, ...result?.data[i] }
   company.push(obj)
}
}}
setCompanies(company)
setLoader(false)
}
  }
  catch(e) {
  setLoader(false)
  }
}
if(data?.business_type) {
getSimilarCompanies()
}
},[data?.business_type])
  return (
    <div className='col-span-1'>
    <div className='bg-white border border-gray-border rounded-xl p-4 mb-4'>
        <p className='text-[0.5rem] font-bold border border-[#f05537] rounded-3xl text-[#f05537] inline-block w-auto px-1 py-0.5'><FormattedMessage id='COMPANY.LEVEL.HIRING.NOW' defaultMessage='Hiring Now'/></p>
    <h1 className='font-bold  text-base p-2'><FormattedMessage id="GENERAL.TOTAL.OPENING.JOBS.TITLE" defaultMessage="{number} Job Openings" values={{number:jobs?.length || 0}}/>  </h1>
  {  jobs?.map((data, index) => (
    <Link to={`/job-detail/${data?.id}`}>
    <div key={index} className='relative'>
    <h1 className='font-bold font-semibold text-sm'>{data?.job_title}</h1>
    <div className='flex px-1'>
    <div className='w-1/2'>
    <FontAwesomeIcon icon={faDollarSign} />
    <span className='pl-2'>{data?.show_pay_by === 'Range' ? `${data?.min_amount}-${data?.max_amount}`: data?.min_amount||data?.max_amount||data?.exact_amount} {data?.rate}</span>                               
                                </div>
                               
                                <div className='w-1/2 flex items-center overflow-hidden'>
                                    <FontAwesomeIcon icon={faLocationDot} className='text-gray-600 text-sm font-medium' />
                                    <span className='pl-2 text-gray-600 text-sm font-medium whitespace-nowrap'>{data?.job_advanced_id?.reporting_address?.address_line_1} {data?.job_advanced_id?.reporting_address?.address_line_2 } {data?.job?.job_advanced_id?.reporting_address?.city}</span>
                                </div>
    </div>
    <div className="h-full absolute flex datas-center  right-0 top-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 text-[#275df5]"><path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path></svg></div>  
                            
    <div className="border-t border-gray-200 my-5"></div>
    </div>
    </Link>
  ))}
  <div className='text-center w-full'>

  {/* <Link to="/" className='font-semibold text-sm border border-[#275df5] rounded-3xl text-[#275df5] inline-block w-auto px-4 py-3 py-0.5'>
  <FormattedMessage id="GENERAL.OPENING.JOB.VIEW.BUTTON" defaultMessage="View all Openings" />  
  </Link> */}
  
  </div>
    </div>
    <div className='bg-white border border-gray-border rounded-xl p-4 mb-4'>
    <ContentWithSpinner height='h-50' loader={loader}>
    <h1 className='font-bold  text-base p-2'> <FormattedMessage id="COMPANIES.SIMILAR.DISPLAY.TITLE" defaultMessage="Companies Similar to {companyName}" values={{companyName:data?.company_name}}/>  </h1>
  {  companies?.map((data) => (<>
    <Link to={`/company-details/${data?.id}`}>
    <div className='flex justify-between items-center cursor-pointer'>
    <div className='w-1/2'>
        <div className='flex gap-2 '>
    <div className='h-12 w-12 rounded border p-1'>
      {data?.newImageUrl ?
      <img src={data?.newImageUrl}></img> :
        <img src={process.env.PUBLIC_URL + "/media/img/cleaning_work.jpg"}></img>
      }
    </div>
    <div>
        <p className='font-bold  text-xs'>{data?.company_name}</p>       
                </div></div>
                
                 </div>
       <div className='w-1/2 text-end'>
       <p className='w-auto font-semibold text-sm border border-[#275df5] rounded-lg text-[#275df5] inline-block w-auto px-4 py-3 py-0.5'>
         <FormattedMessage id='COMPANY.SIMILAR.VIEW.TEXT' defaultMessage='View' />
  </p>
        </div>        
                 </div>
                 </Link>
<div className="border-t border-gray-200 my-5"></div>     
</>))}
 
</ContentWithSpinner>
    </div>
   
</div>
  )
}
