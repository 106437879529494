import React, { useEffect, useState } from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { WhiteBtn } from './component/WhiteBtn'
import { Checkbox, Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import "react-datepicker/dist/react-datepicker.css"
import { ModelWrapperWithButton } from '../component/ModelWrapperWithButton'
import LocationForm  from '../basicdetails/components/LocationForm'
import { Task } from '../basicdetails/components/Tables/Task'
import { AllChecklist } from '../basicdetails/ChecklistTemplate/AllChecklist'
import  TaskForm  from '../basicdetails/components/forms/TaskForm'
import { Button } from 'sr/helpers/ui-components/company/Button'
import ChecklistForm  from '../basicdetails/components/forms/ChecklistForm'
import { ChecklistTemplatedata } from '../basicdetails/ChecklistTemplate/ChecklistTemplatedata'
export const IndividualJobPost = () => {
  const [location, setLocation] = useState('')
  const [tasklist, setTasklist] = useState(-1)
  const [price, setPrice] = useState(0)
  const [addAddress, setaddAddress] = useState(false)
  const [addMore, setAddMore] = useState(false)
  const [task, settask] = useState({})
  const [model, setModel] = useState(false)
  const [taskpopup, setTaskPopup] = useState(false)
  const [dateandtime, setdateandtime] = useState('')
  const [saveTasklist, setSaveTasklist] = useState(false)
 
  const saveTaskHandle = () => {
    setAddMore(false)
    settask({ task: "Clean Trash", Description: "Clean should be clean in a better way and make attention.", Type: "Mandatory" })
  }
  const ModelHandle = (data) => {
    setaddAddress(data)
    setTaskPopup(false)
  }

  useEffect(() => {
    if (location === 'Add New Address') {
      setaddAddress(true)
    }
  }, [location])

  useEffect(() => {
    settask({ task: "Clean Trash", Description: "Clean should be clean in a better way and make attention.", Type: "Mandatory" })
  }, [tasklist])

  return (
    <MainWrapper>
      <div className="py-3 px-10 max-w-5xl">
        <div className='mb-2'>
          <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'>Post a Job</h1>
          <p>Fill out the requiered fields to schedule a service work order.</p>
        </div>
        <>
          <DropDown labelClass='text-base' value={location} label='Address details' dropdowndiv='mt-5 pr-2 pb-2' onChange={(e) => setLocation(e.target.value)} className='rounded-lg' >
            <option value=''>Select Address</option>
            <option>Delhi</option>
            <option>Mumbai</option>
            <option value={'Add New Address'}>Add New Address</option>
          </DropDown>
          <div className='flex w-full items-center'>
            <DropDown labelClass='text-base' value={tasklist} label='Task List' dropdowndiv='mt-5 pr-2 pb-2 flex-1' onChange={(e) => setTasklist(e.target.value)} className='rounded-lg' >
              <option value={-1}>Select Tasklist</option>
              <option value={0}>My tasklist 1</option>
              <option value={1}>My tasklist 2</option>
              <optgroup label="Global Tasklist">
                {ChecklistTemplatedata.map((data, index) => (
                  <option key={index} value={index}>{data.ChecklistName}</option>
                ))}
              </optgroup>
            </DropDown>
            {tasklist > -1 &&
              <WhiteBtn onClick={() => setTaskPopup(true)} mainClass="mt-9 w-24 flex justify-center" title="View Task"></WhiteBtn>
            }
          </div>
          <Input
            label="Suggest a Price"
            onChange={(e) => setPrice(e.target.value)}
            type="number"
            inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            labelClass="mt-2 text-black"
            InputDivClass="mt-5 w-26 pr-2 pb-2"
            placeholder="Enter Price"
            value={price} />
        </>
        <Input
          label="Date and Time"
          onChange={(e) => setdateandtime(e.target.value)}
          type="datetime-local"
          inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          labelClass="mt-2 text-black"
          InputDivClass="mt-5 w-26 pr-2 pb-2"
          value={dateandtime} />
        <div className='w-26 font-satoshiBold mt-5'>
          <p>Work Description</p>
          <textarea rows={3} className="flex-1 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder="Write your note here..."></textarea>
        </div>
        <WhiteBtn mainClass="my-3 w-40 flex justify-center" title="Save Details"></WhiteBtn>
        <ModelWrapperWithButton showModal={addAddress} onCheckModel={ModelHandle}>
          <LocationForm />
        </ModelWrapperWithButton>
        <ModelWrapperWithButton onCheckModel={ModelHandle} showModal={taskpopup}>

          {tasklist > -1 ?
            <>

              <div className="overflow-x-auto mt-10 rounded-[20px] border border-neutral-200">
                {Object.keys(task).length > 0 ?
                  <Task onClick={() => setModel(true)} />
                  : null}
              </div>
            </>
            : null}
          {addMore && <TaskForm />}
          <div className='flex justify-between items-center'>
            <Checkbox onChange={() => setSaveTasklist(!saveTasklist)} checked={saveTasklist} id={'Want to save Tasklist ?'} value={saveTasklist} label={'Want to save Tasklist ?'}></Checkbox>
            <div>
              {tasklist > -1 && addMore && <Button onClick={saveTaskHandle} text="Save Task"></Button>}

              {tasklist > -1 && !addMore && <Button onClick={() => setAddMore(true)} text="Add More Task"></Button>}
            </div></div>
          {saveTasklist &&
            <ChecklistForm />}
        </ModelWrapperWithButton>
      </div>

    </MainWrapper>
  )
}
