type Props = {
  onClick?: any
  id?: any
  title?: any
  className?: any
  color?: any
  icon?: any
  text?: any
  children?: any
  hovertext?:any
  bordersize?:any
  hoverbg?:any
  hoverbordercolor?:any
  loader?:any
}
const Button = ({onClick, id, title, className, color, icon, text, children, hovertext, bordersize, hoverbg, hoverbordercolor, loader}: Props) => {
  return (
    <>
      <button
        onClick={onClick}
        id={id}
        data-title={title}
        className={`${className} 
            border-${color} ${bordersize} ${hovertext} ml-1  font-bold px-3 py-2  mr-1 mb-1 active:bg-${color}-600 ${hoverbg} ${hoverbordercolor} uppercase text-sm shadow hover:shadow-lg
            flex items-center text-center tracking-wide transition-colors duration-200 transform rounded-md`}>
        {icon}
        <div className='flex items-center'>
          {children}
          {text}
        </div>
      </button>
    </>
  )
}

Button.defaultProps = {
  color: 'rose',
  className: 'w-full text-gray-900',
  hovertext:'hover:text-white',
  bordersize:'border-2',
  hoverbg:'hover:bg-[#EEF1FE]',
  hoverbordercolor:'hover:border-[#C7D2F6]'
  
}

export {Button}
