import React from 'react'

export const WhiteBtn = (props) => {
  return (
    <div onClick={props.onClick} className={`${props.mainClass ? props.mainClass : null} bg-white cursor-pointer text-[#0078ae] px-2 py-2 border border-[#d4d2d0]  hover:border-[#0078ae] rounded-lg flex space-x-2 items-center`}>
{props.svg}
 <span className="">{props.title}</span>
 </div>
  )
}
