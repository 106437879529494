import React, { useEffect, useState } from "react";
import { Input, toAbsoluteUrl } from "sr/helpers";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "sr/redux/reducers/individualJob";
import { getAvailability } from "sr/utils/api/cleaner/getAvailability";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { Calendar } from "sr/helpers/ui-components/Calendar";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
export const AvailabilityCalendar = (props:any) => {
  const [startDate, setStartDate] = useState(new Date())
  const [time, setTime] = useState()
  const [availability, setAvailability] = useState([])
  const [loader, setLoader] = useState(true)
  const formData = useSelector((state:any) => state.form); 
    const dispatch = useDispatch()
    const handleUpdateForm = () => {
      const payload =  {
        task_date:startDate,
        start_time:time,
        cleaner_id:props?.item?.id
      }
      dispatch(updateForm(payload));
    };
    const fetchAvailability = async (cleanerId)=> {
      try {
          setLoader(true)
          const queryParams = new URLSearchParams()
          queryParams.append('cleaner_id', cleanerId)
          const res = await getAvailability(queryParams)
          if(res?.success===true) {
            setAvailability(res?.data[0]?.availability)
            setLoader(false)
          }
          else {
                setLoader(false)
          }
      }
      catch(e:any){
            HandleApiError(e)
           setLoader(false)
      }
  }
  useEffect(()=> {
        if(props?.item?.id) {
          fetchAvailability(props?.item?.id)
        }
  },[props?.item?.id])
  return (
  <ContentWithSpinner loader={loader}>
    <div className="flex py-4 justify-end px-4">
    <svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
    </div>
    <h1 className="text-2xl font-bold text-center mb-4"><FormattedMessage id="INDIVIDUAL.CLEANER.CALENDAR.TITLE" defaultMessage="Choose your task date and start time" /></h1>
    <div className="flex space-x-3 items-center px-8">
      <img className="w-10 h-10 rounded-full" src={props?.profileImg ? props?.profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}></img>
      <div className="text-sm font-satoshiBold">{props?.item?.first_name} {props?.item?.last_name} <FormattedMessage id="INDIVIDUAL.CLEANER.CALENDAR.AVAILABILITY.TITLE" defaultMessage="Availability" /></div>
    </div>
  <div className="flex divide-x px-6 items-center">
    <div>
  <Calendar setSelectedDate={setStartDate} availability={availability}/>
  <Input
                    onChange={(e) => setTime(e.target.value)}
                    label={
                      <FormattedMessage
                        id="INDIVIDUAL.CLEANER.CALENDAR.START.TIME"
                        defaultMessage="Start Time"
                      />
                    }
                    inputClass="rounded-lg"
                    labelClass="mt-2 text-black"
                    InputDivClass="mt-0 pr-2 pb-2"
                    type="time"
                    placeholder={
                      <FormattedMessage
                        id="INDIVIDUAL.CLEANER.CALENDAR.START.TIME"
                        defaultMessage="Start Time"
                      />
                    }
                  ></Input>
  <div className="w-96 py-3 px-3"><FormattedMessage id="INDIVIDUAL.CLEANER.CALENDAR.TEXT" defaultMessage="You can chat to adjust task details or change start time after confirming." /></div>
  </div>
  <div className="w-64">
  <div className="flex flex-col px-5">
  <span><FormattedMessage id="INDIVIDUAL.CLEANER.CALENDAR.REQUEST_FOR" defaultMessage="Request for" />:</span>
  <span className="pb-3">{DateFormat(startDate)} {time}</span>
  <Link onClick={()=> handleUpdateForm()} to={'/individual/confirm'} className="text-center font-satoshiBold cursor-pointer text-base bg-[#2557A7] rounded-full text-white py-2 px-6"><FormattedMessage id="INDIVIDUAL.CLEANERS.SELECT_CONTINUE.BUTTON" defaultMessage="Select and Continue" /></Link>
  </div>
  <div className="flex space-x-2 py-7 px-5">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 ">
<path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
</svg>
<div><FormattedMessage id="INDIVIDUAL.CLEANER.CALENDAR.BOOK.TEXT" defaultMessage="Next, confirm your details to get connected with your Tasker." />  </div>
  </div></div>
  </div>
  </ContentWithSpinner>
  )
  
  ;};
