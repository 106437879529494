import React from "react";
import { getWorkOrderModel } from "sr/utils/api/company/workorder/getWorkOrderModel";

export const fetchWorkOrderModel = async (params) => {  
try {
  if (params?.setLoader)  params?.setLoader(true)
    const queryParams = new URLSearchParams()
  if(params?.userType==='company') {
    if (params?.userId) queryParams.append('company_id', params?.userId)
    }
  if(params?.userType==='supervisor'){
    queryParams.append('customer_location_id', params?.customerLocationId)
  }
    const res = await getWorkOrderModel(queryParams)
    if(res?.success===true) {
      params?.setData(res?.data)
      params?.setLoader(false)
      return res
    }
    else {
      params?.setLoader(false)
      return null
    }

}
catch(e){
params?.setLoader(false)
}
};
