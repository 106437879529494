import React, {ReactNode, useEffect, useState } from "react";
import { toAbsoluteUrl } from "sr/helpers";
import { Input } from "sr/helpers";
import { TopBanner } from "./TopBanner";
import { FormattedMessage, useIntl } from "react-intl";
import { employment, jobTypes, Schedule } from './Jsonalldata';
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { RadioInput } from 'sr/helpers/ui-components/company/RadioInput';
import DropDown from 'sr/helpers/ui-components/DropDown.tsx';
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox';
import { Range } from './ShowPayByInput/Range'
import { ExactAmount } from './ShowPayByInput/ExactAmount'
import { MaximumAmount } from './ShowPayByInput/MaximumAmount'
import { StartingAmount } from './ShowPayByInput/StartingAmount'
import { benifitdata } from "./Jsonalldata";
import  TextEditor  from './TextEditor'
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { useFormik } from "formik";
import { Button } from 'sr/helpers/ui-components/company/Button'
import { LevelBar } from "sr/helpers/ui-components/LevelBar";
import { MainWrapper } from "../../component/MainWrapper";
import { BasicDetailsSchema } from "sr/utils/schemas/company/jobPost/BasicDetailsSchema";
import { addBasicDetails } from "sr/utils/api/cleaner/company/jobPost/addBasicDetails";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCompany } from "sr/utils/api/company/getCompany";
import { getJobs } from "sr/utils/api/cleaner/jobs/getJobs";
import { updateBasicDetails } from "sr/utils/api/cleaner/company/jobPost/updateBasicDetails";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { useValidationErrorHandle } from "sr/helpers/functions/validationErrorHandle";
import _ from 'lodash';

export const BasicDetails = () => {
  const navigate = useNavigate();
  const {state: routeState} = useLocation();
  const [jobTitle, setJobTitle] = useState()
  const [jobId, setJobId] = useState("")
  const [specificAddress, setspecificAddress] = useState("");
  const [email, setEmail] = useState();
  const [schedule, setSchedule] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [jobType, setJobType] = useState<any[]>([])
  const [employmentType, setEmploymentType] = useState<any[]>([])
  const [benifit, setBenifit] = useState<any[]>([])
  const [ShowPayBy, setShowPayBy] = useState('Range')
  const [showModal, setShowModal] = useState(false)
  const [resumeRequire, setResumeRequire] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [pay, setPay] = useState(null)
  const [minAmount, setMinAmount] = useState<any>()
  const [maxAmount, setMaxAmount] = useState<any>()
  const [exactAmount, setExactAmount] = useState()
  const [description, setDescription] = useState('')
  const [mobileNo, setMobileNo] = useState()
  const [notification, setNotification] = useState(false)
  const [loader, setLoader] = useState(false)
  const [userDetails, setuserDetails] = useState({
                                        createdAt: "",
                                      id: "",
                                      jwt:"", 
                                      updatedAt: "",
                                      user_id: "",
                                      username: ""
                                        })
  const HandleShowPay = (data)=> {
setMinAmount(data?.min_amount)
setPay(data?.payType)
setMaxAmount(data?.max_amount)
setExactAmount(data?.exact_amount)
  }
  const intl = useIntl()
  // const language = 'English';
  // const country = 'India';
  const params = useParams()
useEffect(()=> {
    const getdetails = getUserDetails();
    if(getdetails){
    setuserDetails(getdetails)
    }
      const {job_id} = params
      if(job_id) {
        setJobId(job_id)
      }
    },[])
    const fetchCompanyDetails = async () => {
      const queryParams = new URLSearchParams(); 
      if (userDetails?.user_id) queryParams.append('id', userDetails?.user_id); 
      const result = await getCompany(queryParams)
      if(result.success === true) {
        setMobileNo(result?.data?.mobile_number)
        setEmail(result?.data?.email)
      }
      else {
          
      }
  }
    useEffect(()=> {
      if(userDetails?.user_id) {
        fetchCompanyDetails()
      }
    },[userDetails?.user_id])

  const initialValues = {
    company_id:userDetails?.user_id,
    job_title:jobTitle,
    employment_type:employmentType,
    job_type:jobType,
    schedule:schedule,
    start_date:startDate,
    show_pay_by:ShowPayBy,
    min_amount : minAmount,
    max_amount: maxAmount,
    exact_amount: exactAmount,
    rate:pay,
    benefits:benifit,
    job_description:description,
    application_call_mobile_number:mobileNo,
    require_resume:resumeRequire,
    notifications:notification,
    email: email,
    status:"open"
  }
  const fetchData = async (jobId:any) => {
    setLoader(true)
    const queryParams = new URLSearchParams()
    queryParams.append('id', jobId?.toString())
    try {
      const result = await getJobs(queryParams )
      if(result?.success===true) {
        console.log(result?.data);
        
      setJobTitle(result?.data?.job_title)
      setJobType(result?.data?.job_type)
      setEmploymentType(result?.data?.employment_type)
      setSchedule(result?.data?.schedule)
      const dateObject = result?.data?.start_date;
      setStartDate(dateObject ? new Date(dateObject) : dateObject)
      setShowPayBy(result?.data?.show_pay_by)
      setMinAmount(result?.data?.min_amount)
      setMaxAmount(result?.data?.max_amount)
      setExactAmount(result?.data?.exact_amount)
      setPay(result?.data?.rate)
      setBenifit(result?.data?.benefits)
      setDescription(result?.data?.job_description)
      setMobileNo(result?.data?.application_call_mobile_number)
      setResumeRequire(result?.data?.require_resume)
      setNotification(result?.data?.notifications)
      setEmail(result?.data?.email)
      initialValues['id']=jobId
      setLoader(false)
      }
    }
    catch(error) {
      console.log(error);
    }
  }
  useEffect(()=> {
    if(jobId){
    fetchData(jobId)
    }
  },[jobId])
  const {errors, submitForm, touched, handleBlur, validateOnChange, validateForm, values} = useFormik({
    initialValues:initialValues,
    enableReinitialize:true,
    validationSchema:BasicDetailsSchema,
    validateOnChange:true,
    validateOnBlur: true,    
    onSubmit:async (values)=> {
      let result
      if(jobId) {
           result = await updateBasicDetails(values)
      }
      else {
       result = await addBasicDetails(values)
      }
       if(result?.success) {
        navigate(`/job-post/Add-Advance-Details/${jobId ? jobId : result?.data?.id}`)
      }
    },
  })
  useEffect(()=> {
    validateForm();
  
  },[values?.max_amount])
  const validationErrorHandle = useValidationErrorHandle()

  useEffect(()=> {
console.log(errors);

  },[errors])

  return (
   
    <MainWrapper>
      <ContentWithSpinner loader={loader} height="h-screen" >
      <LevelBar progress={1} total={3}/>
      <div className='bg-white'>
        <div className='flex items-center space-x-4'>
          <button
            className="p-2 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={() => {
              const linkToRedirect: any = _.get(routeState, 'isFromSidebar', false) ? '/jobs-listing' : -1
              navigate(linkToRedirect);
            }}>
            <span className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <div className='pt-10 container mx-auto max-w-3xl'>
      <TopBanner
        text={
          <FormattedMessage
            id="RECRUITER.POSTJOB.ADD_BASIC_DETAILS.TITLE"
            defaultMessage="Add Basic details"
          />
        }
        img={toAbsoluteUrl("/media/img/jobpost.png")}
      ></TopBanner>
      {/* <div className="py-8 flex items-center space-x-4 border-b border-gray-300">
      <p className="text-base">
      <FormattedMessage
  id="RECRUITER.POSTJOB.LANGUAGE.TITLE"
  defaultMessage="Job Post will be in {language} in {country}"
  values={{ language, country }}
/>

  </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          focusable="false"
          role="img"
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="w-5 h-5 text-[#2557A7]"
        >
          <path d="M20.706 7.042a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83zm-2.898 2.9l-9.705 9.71a.5.5 0 01-.218.128l-4.246 1.2a.5.5 0 01-.618-.616l1.209-4.24a.5.5 0 01.127-.216l9.7-9.716 3.75 3.75z"></path>
        </svg>
      </div> */}
      <div className="py-4">
     
          <Input
          onChange={(e)=> setJobTitle(e.target.value)}
          value={jobTitle}
          error={errors.job_title}
            label={<FormattedMessage id="RECRUITER.POSTJOB.INPUT.JOBTITLE" defaultMessage="Job Title" />}
            placeholder={""}
            type={"text"}
            inputClass="border border-zinc-800 rounded-lg h-11"
          ></Input>
          <div className='pb-4'>
        <p className='pt-8 text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.EMPTYPE.TITLE" defaultMessage="Employment Type" /></p>
<div className="flex flex-wrap space-x-4">
{employment.map((data, index)=> (
<CheckBox
value={data.value}
onChange={(e) => CheckBoxHandle(e, employmentType, setEmploymentType)}
checked = {employmentType?.includes(data.value)} />
))}
   </div>
   {errors.employment_type ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(errors.employment_type as ReactNode)}</p>
        ) : (
          ''
        )}
             <p className='pt-8 text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.TITLE" defaultMessage="Job Type" /></p>
<div className="flex flex-wrap space-x-4">
{jobTypes.map((data, index)=> (
<CheckBox
value={data.value}
onChange={(e) => CheckBoxHandle(e, jobType, setJobType)}
checked = {jobType?.includes(data.value)}
label={data.label}
/>
))}
   </div>
   {errors.job_type ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(errors.job_type as ReactNode)}</p>
        ) : (
          ''
        )}
   <div className='pb-4'>
        <p className='pt-8 text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.TITLE" defaultMessage="Schedule" /></p>
<div className="flex flex-wrap space-x-4">
{Schedule.map((data, index)=> (
<CheckBox
value={data.name.props.defaultMessage}
onChange={(e) => CheckBoxHandle(e, schedule, setSchedule)}
checked = {schedule?.includes(data.name.props.defaultMessage)} />
))}
   </div>
   {errors.schedule ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(errors.schedule as ReactNode)}</p>
        ) : (
          ''
        )}
    </div>
{/* Hiding Job Start date field for Jira https://vikram-bryt.atlassian.net/browse/JMN-151 */}
{/* <div className="relative max-w-xs">
<ReactDatePicker 
error={errors.start_date}
onChange={(date)=> setStartDate(date)}
selected={startDate}
minDate={new Date()}
inputClass="bg-white border border-zinc-800 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-blue-500 focus:border-[#2557A7] focus:shadow-[0_3px_0px_0px_rgb(37,87,167)] block w-full pl-10 p-2 uppercase"
// error={errors.since_when}
label={'Start Date'}></ReactDatePicker>

</div> */}
    
                     
        <DropDown error={errors.show_pay_by} onChange={(e) => setShowPayBy(e.target.value)} value={ShowPayBy} name='Range' label={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SHOWPAYBY.TITLE" defaultMessage="Show Pay By" />} className="h-11 mt-2 border border-zinc-800 rounded-lg">
                    <option value="Range"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.RANGE" defaultMessage="Range" /></option>
                    <option value="Starting amount"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.STARTING_AMOUNT" defaultMessage="Starting amount" /></option>
                    <option value="Maximum amount"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.MAXIMUM_AMOUNT" defaultMessage="Maximum amount" /></option>
                    <option value="Exact amount"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.EXACT_AMOUNT" defaultMessage="Exact amount" /></option>
                </DropDown>
                </div>
                
                {ShowPayBy==="Range" &&
                <Range validateOnChange={validateOnChange} handleBlur={handleBlur} errors={errors} touched={touched} HandleShowPay={HandleShowPay} minAmount={minAmount} maxAmount={maxAmount}  pay={pay}/>}
                    {ShowPayBy==="Starting amount" &&
             <StartingAmount errors={errors} HandleShowPay={HandleShowPay} minAmount={minAmount} pay={pay} />
             }
                    {ShowPayBy==="Maximum amount" &&
               <MaximumAmount errors={errors} HandleShowPay={HandleShowPay} maxAmount={maxAmount} pay={pay} />
               }
                    {ShowPayBy==="Exact amount" &&
               <ExactAmount errors={errors} HandleShowPay={HandleShowPay} exactAmount={exactAmount} pay={pay}/> }
               <div className='pb-4'>
        <p className='pt-8 text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENIFITS" defaultMessage="Benefits" /></p>
<div className="flex flex-wrap space-x-4">
{benifitdata.map((data, index) => (
<CheckBox
value={data.name.props.defaultMessage}
onChange={(e) => CheckBoxHandle(e, benifit, setBenifit)}
checked = {benifit?.includes(data.name.props.defaultMessage)} />
))}
   </div>
   {errors.benefits ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{ validationErrorHandle(errors.benefits as ReactNode)}</p>
        ) : (
          ''
        )}
   <div className='pt-10'>
 <p className='font-satoshiBold text-base'><FormattedMessage id="RECRUITER.POSTJOB.JOBDESCRIPTION.TITLE" defaultMessage="Job Descripion" /></p>
</div>
<div>
<TextEditor setdescription={setDescription} description={description}/>
{errors.job_description ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(errors.job_description as ReactNode)}</p>
        ) : (
          ''
        )}
</div>
            <div className='px-4 pt-4'>
            <Input 
            error={errors.application_call_mobile_number}
            onChange={(e)=> setMobileNo(e.target.value)}
            inputClass = "border border-zinc-800 rounded-lg h-11"
            InputDivClass="mt-0" label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.CALL" defaultMessage="Let applicants call you directly for this job" />     }
            smalllabel = {<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.MOBILENO" defaultMessage="Mobile number" />}
            value={InputNumberFormat(mobileNo)}
            labelClass = "text-base"
            smalllabelclass="font-bold mt-3"
            placeholder="Enter Mobile No"
            >
            </Input>
            
        </div>
        <div className='pt-10'>
                 <p className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.REQUIRERESUME.TITLE" defaultMessage="Require a resume to apply?" /> </p>   
                <RadioInput 
                name="Yes" value={true} checked={resumeRequire === true} 
                onChange= {(e:any)=>setResumeRequire(true) } 
                label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.REQUIRERESUME.YES" defaultMessage="Yes, require it" />}
                inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
                <RadioInput 
                name="No"
                 value={false}
                  checked={resumeRequire === false} 
                   onChange={(e:any)=>setResumeRequire(false) }
                   label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.REQUIRERESUME.NO" defaultMessage="No, don’t ask for it" />}
                   inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
                   </div>
                   <div className='pt-10'>
                 <p className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.TITLE" defaultMessage="Notification" /> </p>
                 <p className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.SENDDAILY.TITLE" defaultMessage="Send daily updates to" /> </p>      
                <RadioInput 
                name="notification1" value={true} checked={notification === true} 
                onChange= {(e)=>setNotification(true) } 
                label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.YES" defaultMessage="Yes" />}
                inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
                <RadioInput 
                name="notification2"
                 value={false}
                  checked={notification === false} 
                   onChange={(e)=>setNotification(false) }
                   label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.NO" defaultMessage="No" />}
                   inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
                 {notification === true &&
                   <Input 
            inputClass = "border border-zinc-800 rounded-lg h-11"
            placeholder="Enter Email Address"
            onChange={(e:any)=> setEmail(e.target.value)}
            value={email}
            labelClass = "text-2xl"
            smalllabelclass="font-bold mt-1 text-lg"
            type="Email"
            >
            </Input> 
            
}</div>
        
         </div>     
     
      </div>
      <Button onClick={submitForm} text={<FormattedMessage id="RECRUITER.POSTJOB.CONTINUE.BUTTON" defaultMessage="Continue" />}></Button>
      </div></div>
      </ContentWithSpinner>
      </MainWrapper>
    
  );
};
