import React from 'react'
import { useIntl } from 'react-intl'

export const ChatBottom = (props) => {
  const intl = useIntl()
  return (
    <div className='flex justify-center items-center px-4 space-x-2 border-t border-neutral-200 pt-2'>
    <textarea onChange={props?.onChange} value={props?.value} id="notes" rows={3} className="block resize-none p-2.5 w-full text-sm text-gray-900  rounded-lg border border-black focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder={intl.formatMessage({
    id: 'RECRUITER.CHATBOX.INPUT.PLACEHOLDER',
    defaultMessage: 'Type your message here...',
  })
}></textarea>
    <div onClick={props?.Clear}>
        <div className='border border-neutral-200 flex items-center justify-center p-2 rounded-lg mb-1 cursor-pointer'>
        <svg className='w-6 h-6' xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="neutral.1000" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.575 18.01a.5.5 0 000 .707l.708.708a.5.5 0 00.707 0l6.01-6.01 6.01 6.01a.5.5 0 00.707 0l.707-.707a.5.5 0 000-.708L13.414 12l6.01-6.01a.5.5 0 000-.707l-.706-.708a.5.5 0 00-.707 0L12 10.586l-6.01-6.01a.5.5 0 00-.708 0l-.707.707a.5.5 0 000 .707l6.01 6.01-6.01 6.01z"></path></svg>
        </div>
    <div onClick={props?.Send} className='bg-[#2557A7] rounded-lg flex items-center justify-center p-2 cursor-pointer'>
    <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6 text-white"><path d="M2.344 4.018a.25.25 0 00-.33.31l1.897 5.895a.5.5 0 00.371.335l7.72 1.44-7.72 1.44a.5.5 0 00-.371.335l-1.898 5.896a.25.25 0 00.33.31l19.494-7.749a.25.25 0 000-.464L2.344 4.018z"></path></svg>
    </div>
    
    </div>
    </div>
  )
}
