import React, {useState} from 'react'
import {SidebarPostItems} from './SidebarItems'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from 'sr/helpers'
export const Sidebar = ({isOpen, HandleOpen}) => {
    const HandleClick = () => {
        HandleOpen();
    }
  return (
<div className={`fixed lg:block top-0 left-0 z-50 h-screen p-4 px-2 overflow-y-auto duration-200 bg-[#2d2d2d] text-white ${isOpen ? 'lg:w-48 w-full block' : 'lg:w-14 hidden'}`}>
<div onClick={HandleClick} className='flex items-center'>
        <button type="button" className=" bg-transparent rounded-lg text-sm p-1.5 inline-flex items-center" >
         {isOpen ?  (
        <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
         ) :
          (
            <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-6 h-6"><path d="M3.5 5.25h13a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zm13 4h-13a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5zm0 4h-13a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5z"></path></svg>
         )   
      }
        </button>
    <h5 className={` ${isOpen ? 'block': 'hidden'} text-base`}>Collapse</h5>
        </div>
  <div className="py-4">
      <ul className="space-y-4 font-medium">

         {SidebarPostItems.map((data)=> (
          <Link to={data.link ? data.link : '#'}>
         <li className={`flex items-center rounded-lg hover:bg-[#424242] my-2`}>
            <span className="inline-flex items-center flex-shrink-0 p-1.5">
{data.icon}
            </span> 
            <div className={`duration-300 overflow-hidden block ${isOpen ? 'block' : 'hidden'}`}>
  <span className="origin-left truncate">{data.text}</span>
</div>

         </li>
         </Link>  
))}
 </ul></div></div>
  )
}
