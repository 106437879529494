import React, {useState} from 'react'
import { Sidebar } from './Sidebar'
import { Header } from './Header'
export const MainWrapper = ({children}) => {
    const [isOpen, setIsOpen] = useState(true)
    const HandleOpen = () => {
        setIsOpen(!isOpen)
    }
  return (
    <>
    <Sidebar isOpen = {isOpen} HandleOpen = {HandleOpen} />
    <Header isOpen = {isOpen} />
    <main className={`${isOpen ? 'pl-52': 'pl-20'} duration-300 bg-[#f3f2f1] px-8`}>
          {children}
        </main>
    </>
    
  )
}
