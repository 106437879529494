import React, {useEffect, useState} from 'react'
import { employment } from '../Jsonalldata'
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { FormattedMessage } from 'react-intl'
export const EmploymentTypeChange = (props) => {
  const [employmentType, setEmploymentType] = useState<any[]>([])
    useEffect(()=> {
      setEmploymentType(props?.data?.employment_type)
    },[props?.data])

    useEffect(()=> {
     const data = {
      employment_type:employmentType
     }
     props.HandleDataProps(data)
    },[employmentType])
  return (
    <>
    <p className='pt-8 text-base '><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.EMPTYPE.TITLE" defaultMessage="Employment Type" /></p>
<div className="flex flex-wrap space-x-4">
{employment.map((data, index)=> (
<CheckBox key={index}
value={data.value}
onChange={(e) => CheckBoxHandle(e, employmentType, setEmploymentType)}
checked = {employmentType?.includes(data.value)} />
))}
   </div>
   </>
  )
}
