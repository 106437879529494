import React, {useState, useEffect} from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { payTypes } from 'sr/i18n/messages/setting'
export const MaximumAmount = (props) => {
    const [pay, setPay] = useState('per hour')
    const [maxAmount, setMaxAmount] = useState()
    const HandleChange = (e) => {
      setPay(e.target.value)
  }
  useEffect(()=> {
    setPay(props?.pay)
    setMaxAmount(props?.maxAmount)
    
  },[])
  const RangeData = {
    max_amount:maxAmount,
    payType:pay ? pay : 'per hour'
  }
  useEffect(()=> {
    props.HandleShowPay(RangeData)
  },[maxAmount, pay, setPay])
  return (
    <div className='flex space-x-4 w-full'>
    <Input 
    onChange={(e)=> setMaxAmount(e.target.value)}
     label = "Maximum amount"
     placeholder="$"
     type={'text'}
       inputClass = 'border border-zinc-800 rounded-lg h-11'
       value={maxAmount}
       InputDivClass = 'w-1/3 mt-10 h-full'
       min={1}
       error={props?.errors?.min_amount}
    />
   <DropDown
    onChange={HandleChange}
    value={pay}
    dropdowndiv='flex-1 mt-10' 
    name="Rate" 
    label={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.TITLE" defaultMessage="Rate" />}
    className="h-11 mt-3 border border-zinc-800 rounded-lg"
    
   >

       {payTypes.map((data)=> (
        <option value={data.value}>{data.label}</option>
           ))}
       </DropDown>
       </div>
  )
}
