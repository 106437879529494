import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faSearch } from '@fortawesome/free-solid-svg-icons'
export const InputSearch = (props) => {
  return (

<div className={`${props.className && props.className } w-full rounded-3xl flex space-x-2 items-center border ${props.borderColor && props.borderColor}`}>
{props.noicon ? null : (
<FontAwesomeIcon icon={faMagnifyingGlass} className='text-[#717b9e] text-lg font-satoshiBold pl-3' />
) }
<input onChange={props?.onChange} value={props?.value}  type='search' className={`${props.height && props.height} px-1 ${props.inputdiv} py-1 text-gray-900 focus:outline-none`} placeholder={props?.placeholder} onClick={props?.onClick}>
                                                </input>
                                                </div>
  )
}
InputSearch.defaultProps = {
  className:'px-2 my-2',
  inputdiv:'w-40'
}