import React, { useEffect } from "react";
import { updateMessage } from "sr/utils/api/cleaner/updateMessage";

export const DisplayMessageReceiver = ({item}) => {
  
     const updateReadMessage = async (id)=>{
            console.log('wwww');
            
            try {
            const payload = {
              id:id,
              readed:true
            }
              const res = await updateMessage(payload)
              if(res?.success===true){
    
              }
            }catch(e){
    
            }
           }
           useEffect(()=>{
            if(item?._id && item?.readed===false){
                updateReadMessage(item?._id)
            }
           },[item?._id, item?.readed])
  return (<div className='text-[#595959]'>
    {item?.message}
     </div>);
};
