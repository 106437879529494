import React, { useEffect, useState, useRef } from 'react'
import { SliderCard } from './component/Slider/SliderCard'
import AuthNavComponent from '../module/auth/AuthNav'
import { Filter } from './component/Sidebar/Filter'
import { CompanyCard } from './component/CompanyCard'
import { SliderContainer } from './component/Slider/SliderContainer'
import { datas } from './component/JobsCategory'
import Footer from '../module/Footer'
import { FormattedMessage } from 'react-intl'
import { Pageination } from 'sr/helpers/ui-components/Pageination'
import { getCompany } from 'sr/utils/api/company/getCompany'
import ContentWithLoader from 'sr/layout/common/ContentLoader'
import { CompanyCardLoader } from './component/CompanyCardLoader'
const Companies = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalPage, settotalPage] = useState<number>(10);
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [filters, setFilters] = useState<any>({})

    const HandleFilterData = (data)=> {
        setFilters(data)
        console.log(data);
        
}
    const handlePageChange = (value:any) => {
        if(value > totalPage || value < 1 ) {
            return 
        }
        console.log(value);
        
    setPage(value)
    }
    useEffect(()=> {
       const fetchCompanies = async () => {
            setLoader(true)
            const queryParams = new URLSearchParams(); 
            if (page) queryParams.append('page', page.toString())
                if (filters?.business_type) {
                    let businessType = filters.business_type;
                    if (businessType?.length ===1 ) {
                      businessType += ',';
                    }
            
        if (businessType?.length>0) queryParams.append('business_type', businessType);
                }
        const result = await getCompany(queryParams)
        if(result?.success===true) {
        setData(result?.data)
        const total = Math.ceil(result?.pagination?.total/limit)
        settotalPage(total)
        setLoader(false)
        }
       }
       fetchCompanies()
    },[page, filters?.business_type])
    return (
        <>
            <AuthNavComponent isLoggedin={true} />
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className="font-bold  text-xl mb-5"> <FormattedMessage id="COMPANIES.TOP.TITLE" defaultMessage="Top Companies hiring now" />  </div>
                   <SliderContainer data = {datas} bg = 'bg-[#e7e7f1]' />

                    <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-4 pt-4 gap-0">
                        <div className="col-span-1">
                            <Filter HandleFilterData={HandleFilterData}/>

                        </div>
                        <div className="col-span-3">
                            <div className='pb-2'>
                            <FormattedMessage id="COMPANIES.SHOWING.TITLE" defaultMessage="Showing {number} companies" values={{number:data.length}}/> 
                            </div>
                            
                                <ContentWithLoader loading={loader} content={<CompanyCardLoader/>}>
                                <div className='grid lg:grid-cols-2  grid-cols-1 gap-4'>
                                {
                          data?.map((data1) => {
                             return <CompanyCard data = {data1} />
                            })
}   
</div>                    </ContentWithLoader>
                
                            <Pageination page = {page} totalpage= {totalPage} limit = {limit} onPageChange = {handlePageChange}/>
                        </div>
                    </div>


                </div>
            </main>
            <Footer />
        </>
    )
}
export default Companies;