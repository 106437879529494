import React from 'react';

export const RadioInput = ({ name, value, checked, onChange, label, inputcss="" }) => {
    
  return (
    <label className={`${inputcss} px-4 flex items-center py-1.5 relative`}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="appearance-none w-4 h-4 border-2 border-black rounded-full checked:bg-blue-500 checked:border-transparent"
      />
      <span className="ml-2 text-base text-gray-500 font-satoshiBold font-bold">{label}</span>
    </label>
  );
};