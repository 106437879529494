import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getCardDetails = async (customerId) => {
    try  {
  return await get<ApiResponse<[]>>(`/stripepayments/cards?stripe_customer_id=${customerId}`).then((res)=> {
    if(res?.success===true) {
        return res
    }
    else {
        return null
    }
  })
}
catch(e) {
    HandleApiError(e)
}
};
