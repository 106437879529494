import React, {useState, useEffect} from 'react'
import AuthNavComponent from '../../module/auth/AuthNav'
import Footer from '../../module/Footer'
import { Filter } from '../../company-display/component/Sidebar/Filter';
import { Pageination } from 'sr/helpers/ui-components/Pageination';
// import { DemoCompaniesData, getLenght} from 'sr/DemoCompanies';
import JobItemCard from './components/JobItemCard';
import { FormattedMessage } from 'react-intl';
import { HandleApiError } from 'sr/helpers/functions/HandleApiError';
import { getJob } from 'sr/utils/api/individual/job/getJob';
import { JobLoaderCard } from '../component/JobLoaderCard';
import ContentWithLoader from 'sr/layout/common/ContentLoader';
export const IndividualJobs = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [jobs, setJobs] = useState<any[] | undefined>(undefined);
    const [totalPage, settotalPage] = useState<number>(1);
   const [loader, setLoader] = useState(true)
   const [data, setData] = useState([])
    const handlePageChange = (value) => {
        if(value > totalPage || value < 1 ) {
            return 
        }
    setPage(value)
    }

    const fetchJobs = async ()=> {
        try {
        setLoader(true)
        const queryParams = new URLSearchParams();  
      if (page) queryParams.append('page', page.toString());
      if (limit) queryParams.append('limit', limit.toString());
            const res = await getJob(queryParams)
            if(res?.success===true) {
                    setData(res?.data)
                    settotalPage(Math.ceil(res?.pagination?.total/limit))
                    setLoader(false)
            }
            else {
                setLoader(false)
            }
        }
        catch(e:any) {
            setLoader(false)
            HandleApiError(e)
        }
    }
useEffect(()=> {
    fetchJobs()
},[limit, page])
  return (
    <div>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
        <div className='font-bold  text-xl mb-5 lg:text-left text-center'>
        {data?.length} <FormattedMessage id='CLEANER.INDIVIDUAL.JOBS.TITLE' defaultMessage='Jobs based on Your Desired Preferences' />
        </div>
        <div className='grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-0 pt-4'>
            <div className='col-span-1'>
            {/* <Filter /> */}
            </div>
            
            <ContentWithLoader loading={loader} content={<JobLoaderCard></JobLoaderCard>}>
                <div className="col-span-3">
                {
                    data?.map((datI, index) => {
                        return <JobItemCard key={index} jobs={datI}></JobItemCard>
                    })
                }
                <Pageination page = {page} totalpage= {totalPage} limit = {limit} onPageChange = {handlePageChange}/>
                </div>
                </ContentWithLoader>
        </div>
    </div>
   <Footer /> 
</div>
  )
}
