import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createDefaultCard = async (postData:any) => {
    try {
  return await post<ApiResponse<[]>>('/default-card/', postData).then((res)=>{
    if(res?.success===true){
                return res
    }
    else {
        return null
    }
  })
}
catch(e){
    HandleApiError(e)
}
};
