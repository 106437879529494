import React from "react";

export const EmpoymentDuration = (employmentData) => {
    let totalYears = 0;
    let totalMonths = 0;
    let totalDays = 0;

    employmentData.forEach(employment => {
        const start = new Date(employment?.working_since_start);
        const end = employment?.working_since_end ? new Date(employment?.working_since_end) : new Date()
        
        // Calculate the duration in milliseconds
        const durationMilliseconds = end.getTime() - start.getTime();
        
        // Calculate the duration in years, months, and days for this employment
        const years = Math.floor(durationMilliseconds / (1000 * 60 * 60 * 24 * 365));
        const months = Math.floor((durationMilliseconds % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        const days = Math.floor((durationMilliseconds % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
        
        // Add this employment's duration to the total
        totalYears += years;
        totalMonths += months;
        totalDays += days;
      });
      totalYears += Math.floor(totalMonths / 12);
      totalMonths = totalMonths % 12;
      totalMonths += Math.floor(totalDays / 30);
      totalDays = totalDays % 30;
      return {
        totalYears:totalYears,
        totalMonths:totalMonths,
        totalDays:totalDays
      };
        
};
