import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MainWrapper } from '../component/MainWrapper'
import { WarningRed } from 'sr/helpers/ui-components/company/WarningRed'
import EmailLink from 'sr/helpers/ui-components/EmailLink'
import { ApplicantQualification } from './component/Candidate-details/ApplicantQualification'
import { toAbsoluteUrl } from 'sr/helpers'
import { TopBar } from '../component/TopBar'
import { CandidateLeftSidebar } from './component/Candidate-details/CandidateLeftSidebar'
import { CondidateRightSidebar } from './component/Candidate-details/CondidateRightSidebar'
import { ChatPopup } from './component/Candidate-details/ChatPopup'
import { FormattedMessage } from 'react-intl'
import { getApplication } from 'sr/utils/api/cleaner/getApplication'
import CleanerType from 'app/pages/cleaner/component/type/CleanerType'
import { getTraining } from 'sr/utils/api/cleaner/getTraining'
import { getMedicalCondition } from 'sr/utils/api/cleaner/getMedicalCondition'
import { getEmployment } from 'sr/utils/api/cleaner/getEmployment'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { useNavigate } from 'react-router-dom'
import { getFiles } from 'sr/utils/api/getFiles'
import { getFile } from 'sr/utils/api/getFile'
import { Document, pdfjs } from 'react-pdf'
import { ApplicationStatus } from 'sr/i18n/messages/setting'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { DownloadResume } from 'sr/helpers/functions/ApiFunction.tsx/DownloadResume'
export const CandidateView = () => {
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const params = useParams()
  const navigate = useNavigate()
  const [id, setId] = useState<string>()
  const [application, setApplicant] = useState(null)
  const [allApplicant, setAllApplicant] = useState(null)
  const [candidate, setCandidate] = useState(null)
  const [cleaner, setCleaner] = useState<CleanerType>(null)
  const [training, setTraining] = useState(null)
  const [medical, setMedical] = useState(null)
  const [employment, setEmployment] = useState(null)
  const [candidates, setCandidates] = useState(null)
  const [loader, setLoader] = useState(false)
  const [companyId, setCompanyId] = useState()
  const [totalCandidate, setTotalCandidate] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [resume, setResume] = useState(null)
  const [resumeKey, setResumeKey] = useState(null)
  const [backStatus, setBackStatus] = useState()
  useEffect(()=> {
    const userData =  getUserDetails()
    setCompanyId(userData?.user_id)
  }, [])


  useEffect(()=> {
  
    const {id} = params
    setId(id)
  }, [params])
  useEffect(()=> {
    const fetchAllCandidate = async (job_id)=> {
      const queryParams = new URLSearchParams();  
      queryParams.append('job_id', job_id?.toString());
 const result = await getApplication(queryParams)
 if(result?.success===true) {
  let allid=[]
  for(let i =0; i<result?.data?.length; i++) {
      allid.push(result?.data[i].id)
  }
  setAllApplicant(allid)
  setCandidates(result?.data)
  setTotalCandidate(result?.pagination?.statusCounts[candidate?.status])
 }
    }
    if(candidate?.job_id?.id) {
      fetchAllCandidate(candidate?.job_id?.id)
    }
},[candidate?.job_id?.id ])

const fetchCandidate = async (id) => {
  setLoader(true)
  try {
const queryParams = new URLSearchParams();  
       queryParams.append('id', id.toString());
  const result = await getApplication(queryParams)
  if(result?.success===true) {
    setCandidate(result?.data)
    setBackStatus(result?.data?.status)
        const trainingres = await getTraining(result?.data?.cleaner_id?._id, undefined)
        if(trainingres?.success===true)
        setTraining(trainingres.data)
      }
      const medicalres = await getMedicalCondition(result?.data?.cleaner_id?._id, undefined)
      if(medicalres?.success===true) {
        setMedical(medicalres.data)
      }
      const empres = await getEmployment(result?.data?.cleaner_id?._id, undefined)
      if(empres.success===true) {
        setEmployment(empres?.data)
        setLoader(false)
      }
      const data = {
        cleaner_id:result?.data?.cleaner_id?._id
      }
      const files = await getFiles(data)
      if(files?.success===true && files?.data?.length>0) {
        const files = await getFiles(data);

if (files?.success === true && files?.data?.length > 0) {
const filterfile = files?.data?.filter((item)=> item?.file?.fieldname==='resume')
if(filterfile) {
setResumeKey(filterfile[0]?.file?.key)
const getfile = await getFile(filterfile[0]?.file?.key)

if (getfile) {
  const pdfBlob = new Blob([getfile], { type: 'application/pdf' });
  // console.log(pdfBlob);
  
  // Create a URL for the Blob
  const newPdfUrl = URL.createObjectURL(pdfBlob);
  console.log(newPdfUrl);

  // Set the URL to your state or variable (e.g., setResume)
  setResume(newPdfUrl);

    // window.location.href = newPdfUrl;
    // const reader = new FileReader();
    // reader.onload = (event) => {
    //     console.log('Reader onload callback executed.');

    //     const arrayBuffer = event.target.result;
    //     const pdfBlob = new Blob([arrayBuffer], { type: 'application/pdf' });
    //     console.log(pdfBlob);

    //     const newPdfUrl = URL.createObjectURL(pdfBlob);
    //     window.location.href = newPdfUrl;

    //     console.log(newPdfUrl);
    //     setResume(newPdfUrl);
    //     console.log(newPdfUrl);
    // };
    // reader.readAsArrayBuffer(pdfBlob);
}}
}

      }

  }
  catch(e:any) {

  }
}
  useEffect(()=> {
   
    if(id) {
    fetchCandidate(id)
    }
  }, [id])

  useEffect(()=> {
if(id && allApplicant) {
  const index =  allApplicant?.indexOf(id)
        setCurrentPage(index)
}
  },[allApplicant, id])

const HandlePageChange = async (index)=> {
    if(index>allApplicant?.length-1 || index < 0){
      return
    }
    const id = allApplicant[index]
    navigate(`/candidate-details/${id}`)
}

  const address_line_1 = candidate?.job_id?.job_advanced_id?.reporting_address?.address_line_1;
  const address_line_2 = candidate?.job_id?.job_advanced_id?.reporting_address?.address_line_2;
  const reportingCity = candidate?.job_id?.job_advanced_id?.reporting_address?.city;
  const candidateAddressLine1 = candidate?.cleaner_id?.address?.address_line_1;
  const candidateAddressLine2 = cleaner?.address?.address_line_2
  const candidateCity = cleaner?.address?.city;
  return (
    <MainWrapper >
    <div className='py-3'>
        <TopBar title = {<FormattedMessage
          id='RECRUITER.CANDIDATES.CANDIDATES.TITLE'
          defaultMessage='Candidates'
        />} btntitle = {<FormattedMessage
          id='RECRUITER.CANDIDATES.ALLCANDIDATE.BUTTON'
          defaultMessage='All Candidates'
        />} btntitlelink = '/candidates' className='px-10'></TopBar>
    <div className='pl-10'>
    <div className='flex items-center justify-between'>
<div className='flex items-center space-x-4'>
<Link to={`/candidates/${backStatus}`}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#2557A7] w-5 h-5 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
</Link>
<div>
    <h2 className='font-satoshiBold'>{candidate?.job_id?.job_title}{address_line_1 || address_line_2 || reportingCity ? ` - ` : ''}{address_line_1} {address_line_2} {reportingCity}</h2>
    <Link to={`/candidates/${backStatus}`}>
      <p className='text-base font-satoshiBold text-[#2557A7]'> <FormattedMessage id="RECRUITER.CANDIDATES.BACKBUTTON" defaultMessage="Back to" /> {getLabelByValue(backStatus, ApplicationStatus)}  </p>
      </Link>
</div>

</div>
<nav className=' flex flex-wrap justify-center items-center lg:space-x-8'>
    <p className='font-bold text-sm text-[#717b9e]'><FormattedMessage id="RECRUITER.CANDIDATES.PAGEINATION.TITLE" defaultMessage="Candidate {activeNumber} of {totalNumber}" values={{activeNumber:currentPage+1, totalNumber:allApplicant?.length}} /></p>
  <ul className="items-center inline-flex lg:space-x-2 text-base h-10 justify-between w-full lg:w-fit">
    <li onClick={()=> HandlePageChange(currentPage-1)}>
      <Link to="#" className="flex items-center justify-center px-4 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-[20px] hover:bg-gray-100 hover:text-gray-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg> <FormattedMessage id="PAGEINATION.PREVIOUS.BUTTON" defaultMessage="Previous" />  </Link>
    </li>

    <li onClick={()=> HandlePageChange(currentPage+1)}>
      <Link to="#" className="flex items-center justify-center px-4 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-[20px] hover:bg-gray-100 hover:text-gray-700"><FormattedMessage id="PAGEINATION.NEXT.BUTTON" defaultMessage="Next" /> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>
</Link>
    </li>
  </ul>
</nav>
    </div>
    <div className='grid grid-cols-12 gap-7 mt-10'>
   <div className='col-span-3 bg-white rounded-[18px] h-fit'>
    <CandidateLeftSidebar candidates = {candidates} total={totalCandidate} currentPage = {currentPage} allApplicant = {allApplicant}/>
  <div>

 <div>
</div>
</div>
   </div>
   {<ContentWithSpinner loader={loader}>
   <div className='col-span-9'>
    <div className='flex'>
    <div className='bg-white rounded-lg p-5 w-[120rem] shadow-boxshadowpage'>
   {/* <WarningRed heading =  {<FormattedMessage id="RECRUITER.JOBPOST.WARNING.TITLE" defaultMessage="Job post has missing information" /> }  paragraph = {<FormattedMessage id="RECRUITER.JOBPOST.WARNING.TEXT" defaultMessage="To improve this post, add information that's important to people applying." />  }></WarningRed> */}
{/* <div className='flex space-x-3'>
  <div className='bg-[#2557A7] hover:bg-[#1a478f] text-white py-3 rounded-lg font-satoshiBold text-xl px-3 cursor-pointer'>
<div className='flex space-x-2 items-center'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
<span><FormattedMessage id='RECRUITER.CANDIDATE.INTERVIEW.SET.BUTTON' defaultMessage='set up interview' /></span>
</div>
  </div>
  <div className='text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-3 shadow-lg rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200'>
<div className='flex space-x-2 items-center'>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2557A7" className="w-6 h-6">
  <path fillRule="evenodd" d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z" clipRule="evenodd" />
</svg>

<span><FormattedMessage id='RECRUITER.CANDIDATES.TABLE.INTEREST.MESSAGE' defaultMessage='Message'  /></span>
</div>
  </div>
  <div className='text-[#2557A7] py-3 shadow-lg rounded-lg font-satoshiBold hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] text-base px-3 cursor-pointer border border-neutral-200'>
<div className='flex space-x-2 items-center'>
<svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="#2557A7" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.364 9.89A18.007 18.007 0 013 3.5a.49.49 0 01.493-.5h4.502c.276 0 .5.224.51.5.048 1.204.27 2.394.659 3.534a.513.513 0 01-.12.528l-2.438 2.439a16.007 16.007 0 007.391 7.392l2.438-2.437a.513.513 0 01.528-.12c1.14.39 2.332.612 3.537.66.276.011.5.234.5.51v4.5a.49.49 0 01-.5.494A18.01 18.01 0 014.364 9.89z"></path></svg>
<span><FormattedMessage id='RECRUITER.CANDIDATE.CALL.BUTTON' defaultMessage='Call'  /></span>
</div>
  </div>
</div> */}
<div className='flex space-x-5 mb-5'>
       <div className='w-40 h-40'>
        <div className='transform lg:transform-none -translate-x-1/2'>
        <img alt="Profile" className="rounded" src="/media/icons/displayProfilePlaceholder.png" />
    </div></div>
<div className='py-5'>
  <h1 className='text-3xl font-satoshiBold text-[#595959]'>{candidate?.cleaner_id?.first_name} {candidate?.cleaner_id?.last_name}</h1>
  <p><EmailLink email={candidate?.cleaner_id?.email} subject="Hello" body="Hi, how are you?"></EmailLink>{candidateAddressLine1 || candidateAddressLine2 || candidateCity ? ` - ` : ''}{candidateAddressLine1} {candidateAddressLine2} {candidateCity}</p>
<p>Applied to {candidate?.job_id?.job_title}{address_line_1 || address_line_2 || reportingCity ? ` - ` : ''}{address_line_1} {address_line_2} {reportingCity}
</p>
</div>
</div>
<div>
        <h1 className='text-xl font-satoshiBold pb-2'>Training</h1>
        {training !='' ? training?.map((item, index)=> (
        <div key={index} className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base font-satoshiBold text-black'>{item?.training}</span>
              <div className='text-base'>{DateFormat(item?.date_attended)}
              <p>{item?.description}</p>
            </div>
        </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Training'}}></FormattedMessage></p>
}
        <div>
        <h1 className='text-xl font-satoshiBold pb-2'>Medical Condition</h1>
        {
          medical !='' ? medical?.map((item, index)=> (
        <div className='pb-4 mb-4 border-b border-neutral-200'>
            <span className='text-base font-satoshiBold text-black'>Condition - {item?.condition}</span>
              <div className='text-base'>Since When - {DateFormat(item?.since_when)}  </div>
              <p>{item?.description}</p>
        </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Medical Condition'}}></FormattedMessage></p>

}
        <div>
        <h1 className='text-xl font-satoshiBold pb-2'>Employment</h1>
        {
        employment != '' ?
        employment?.map((item, index)=> (
        <div key={index} className='pb-4 mb-4'>
            <span className='text-base font-satoshiBold text-black'>{item?.job_title} </span>
              <div className='text-base'>{item?.company_name}</div>
              <div className='text-base'>{item?.company_location}</div>
              <div className='text-base'>{item?.currently_employed ? DateFormat(item?.working_since_start) : DateFormat(item?.working_since_start) + '-' + DateFormat(item?.working_since_end) }</div>
        
       </div>
))
:
<p className='pb-4 mb-4 border-b border-neutral-200'><FormattedMessage id='No {title} Data Added.' defaultMessage='No {title} Data Added.' values={{title:'Employment'}}></FormattedMessage></p>

}
       
       </div></div>
 </div> 
<div className='border-b border-neutral-200 py-5 flex justify-between items-center'>
<span className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.CANDIDATE.CV.TITLE" defaultMessage="CV" /> </span>
<div onClick={()=> DownloadResume('bbnett.pdf', resumeKey)} className='text-[#2557A7] font-satoshiBold cursor-pointer border border-neutral-200 px-3 py-1.5 text-base rounded-lg'>
<FormattedMessage id="RECRUITER.CANDIDATE.CV.BUTTON" defaultMessage="Download PDF" /> 
</div>
</div>
{/* { resume &&
        <iframe src={resume} style={{ width: '100%', height: '500px' }} title="Resume Viewer" />

} */}
{resume &&
 <Document
  file={{ url: resume }}
  onLoadSuccess={() => console.log('PDF loaded successfully')}
  onLoadError={(error) => console.error('Error loading PDF:', error)}
>
</Document>
}

   </div>
   <div className='w-full'>
<CondidateRightSidebar status = {candidate?.status} candidate={candidate} setBackStatus={setBackStatus}/>
   </div>
   
   </div>
   
   </div>
   </ContentWithSpinner>
   }
   </div>
    </div>
   <ChatPopup candidate={candidate}/>
    </div>
    </MainWrapper>
  )
}
