import React from "react";
import ContentLoader from "react-content-loader";
export const StringLoader = () => {
  return <div className="mb-2">
     <ContentLoader 
    speed={2}
    width={120}
    height={20}
    viewBox="0 0 80 20"
    backgroundColor="#fcfcfc"
    foregroundColor="#e3e3e3"
  >
    <rect x="-358" y="-62" rx="3" ry="3" width="67" height="31" /> 
    <rect x="-235" y="-34" rx="3" ry="3" width="140" height="11" /> 
    <rect x="-300" y="-15" rx="3" ry="3" width="53" height="11" /> 
    <rect x="-381" y="-11" rx="3" ry="3" width="72" height="11" /> 
    <rect x="-407" y="17" rx="3" ry="3" width="100" height="11" /> 
    <rect x="-331" y="24" rx="3" ry="3" width="37" height="11" /> 
    <rect x="-462" y="41" rx="3" ry="3" width="140" height="11" /> 
    <rect x="-353" y="11" rx="3" ry="3" width="173" height="11" /> 
    <rect x="-724" y="11" rx="0" ry="0" width="210" height="14" /> 
    <rect x="-791" y="39" rx="0" ry="0" width="208" height="13" /> 
    <rect x="-800" y="-39" rx="0" ry="0" width="203" height="13" /> 
    <rect x="-372" y="-18" rx="0" ry="0" width="63" height="50" /> 
    <rect x="35" y="60" rx="0" ry="0" width="96" height="20" /> 
    <rect x="35" y="16" rx="0" ry="0" width="3" height="0" /> 
    <rect x="6" y="0" rx="0" ry="0" width="120" height="20" />
  </ContentLoader>
  </div>;
};
