import React, { useState } from "react";
import { MainWrapper } from "../../component/MainWrapper";
import { toAbsoluteUrl } from "sr/helpers";
import { TaskDetails } from "../component/Data";
import { Link } from "react-router-dom";
import { Current } from "./component/Current";
import { Completed } from "./component/Completed";
import { FormattedMessage } from "react-intl";
export const ConfirmAndBook = () => {
  const [activeTab, setactiveTab] = useState("Current");
  return (
    <MainWrapper>
      <div className="py-12 lg:px-14 mx-auto max-w-[52rem] h-screen">
        <div className="flex space-x-4">
          <div
            onClick={() => setactiveTab("Current")}
            className={`text-[#595959] font-satoshiBold text-xl px-3 cursor-pointer ${
              activeTab === "Current"
                ? "border-b-4 border-black text-black"
                : ""
            }`}
          >
<FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.TOGGLE.CURRENT" defaultMessage="Current" />         
 </div>
          <div
            onClick={() => setactiveTab("Completed")}
            className={`text-[#595959] font-satoshiBold text-xl px-3 cursor-pointer	 ${
              activeTab === "Completed"
                ? "border-b-4 border-black text-black"
                : ""
            }`}
          >
            <FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.TOGGLE.COMPLETED" defaultMessage="Completed" />
          </div>
        </div>
    {activeTab === 'Current' && 
    <Current/>
    }
    {activeTab === 'Completed' &&
    <Completed />}
    </div>
    </MainWrapper>
  );
};
