import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateApplication = async (putData:any) => {
    try {
  return await put<ApiResponse<[]>>('/application/', putData ).then((res)=> {
    if(res.success===true) {
        return res
    }
    else {
        return null
    }

  })
}catch(e:any){
    HandleApiError(e)
}
};
