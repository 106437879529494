import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Input, toAbsoluteUrl } from "sr/helpers";
import { ModelWrapper } from "../../component/ModelWrapper";
import { SelectandContinue } from "./SelectandContinue";
import { FormattedMessage } from "react-intl";
import { getFiles } from "sr/utils/api/getFiles";
import { getFile } from "sr/utils/api/getFile";
import { useSelector } from "react-redux";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { toast } from "react-toastify";
export const CleanerProfileCard = ({item}) => {
  const [profileImg, setProfileImg] = useState(null)
  const [model, setModel] = useState(false)
  const [jobId, setJobId] = useState<string>()
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const HandleModelByProps = (data:any)=> {

    setModel(data)
  }
  const param = useParams()
  useEffect(()=> {
    const fetchProfileImg = async (cleanerId)=> {
      const params = {
        cleaner_id:cleanerId
      }
  const profileImg = await getFiles(params)
  if(profileImg?.success===true) {
    const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
    if(filterfile && filterfile[0]?.file?.key) {
      const fileimg = await getFile(filterfile[0]?.file?.key);
      if(fileimg) {
      const newImageUrl = URL.createObjectURL(fileimg);
      setProfileImg(newImageUrl)
      }
      
    }}
    }
    if(item?._id)
    fetchProfileImg(item?._id)
  },[item?._id])

  useEffect(()=>{
      const {id, to, from} = param
      setJobId(id)
      setTo(to)
      setFrom(from)
  },[param])
  
  return (<>
    <div className="bg-white border border-neutral-300 rounded-lg mb-3">
    <div className="lg:flex block lg:space-x-5 p-5">
      <div className="lg:w-[26%] w-full text-center">
        <div className="mx-auto mb-3 flex justify-center lg:block">
          <img
            alt="Profile"
            className="rounded-full w-44 h-44"
            src={` ${profileImg ? profileImg: "/media/icons/displayProfilePlaceholder.png"}`}
          />
        </div>

        <Link to={`/individual/cleaner-profile-details/${item?._id}/${jobId}/${from}/${to}`}>
        <div className="mb-3 text-base  text-[#0078ae] cursor-pointer">
        <FormattedMessage id="INDIVIDUAL.CLEANERS.VIEW.PROFILE" defaultMessage="View Profile and Reviews" />
        </div></Link>
        <div onClick={()=> setModel(true)}
        className="mb-3 text-center font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy rounded-full">
          <FormattedMessage id="INDIVIDUAL.CLEANERS.SELECT_CONTINUE.BUTTON" defaultMessage="Select and Continue" />
        </div>

        <p className="text-center lg:block hidden mt-3">
        <FormattedMessage id="INDIVIDUAL.CLEANERS.SUGGEST.INFORMATION" defaultMessage="You can chat with your Tasker, adjust task details, or change the task time after booking." />

        </p>
      </div>
      <div className="lg:ml-3 flex-1 mt-3 lg:mt-0">
        <div className="flex justify-between lg:pb-5 pb-2">
          <p className="text-xl ">{item?.first_name} {item?.last_name}</p>
          <p className="text-xl ">${item?.rate}/hr</p>
        </div>
        <div className="items-center flex divide-x divide-solid divide-slate-400 gap-1">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#EAB308"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4 text-[#EAB308]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              ></path>
            </svg>
            <div className="text-xs text-gray-500  font-bold">
              3.8
            </div>
          </div>
          <div className="text-xs	 text-gray-500  font-bold pl-1">
            (101   <FormattedMessage id="CLEANER.JOBS.REVIEWS" defaultMessage="Reviews" />)
          </div>
        </div>
        {/* <div className=" text-base text-[#0078ae]">1 Cleaner Help Tasks</div>
        <div className="text-base">6 Home Improvement tasks overall</div> */}
        <div className="flex flex-col lg:p-6 p-4  bg-gray-50 border border-gray-border rounded-xl lg:mt-10 mt-4">
            <div className=" text-base"><FormattedMessage id="INDIVIDUAL.CLEANERS.QUESTION" defaultMessage="How I can help:" /></div>
            <div>
            <p>{item?.additional_information}</p>
            </div>
           <Link className="mt-2 text-sm font-bold  text-[#275df5] cursor-pointer" to={''}><FormattedMessage id="INDIVIDUAL.CLEANERS.READMORE.BUTTON" defaultMessage="Read More" /></Link>
        </div>
      </div>
    </div></div>
    {model && 
    <ModelWrapper showModal={model} background="bg-[#00000099]" opacity="opacity-100" topPosition="top-[10%]" rightPosition="inset-0" HandleModelByProps={HandleModelByProps}  width = "w-[50%] mx-auto" position="fixed" topClass="flex w-full">

    <SelectandContinue setModel={setModel} item={item} profileImg={profileImg}></SelectandContinue>
    </ModelWrapper>
}
    </> );
};
