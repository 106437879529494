import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getAdditionInfo = async (company_id:any, id:any) => {
  try {
    const queryParams = new URLSearchParams();  
    if (company_id) queryParams.append('company_id', company_id.toString());
    if (id) queryParams.append('id', id.toString());
        return await get<ApiResponse<[]>>(`/company/addlinfo?${queryParams}`).then((res)=> {
            if(res.success===true) {
                return res
            }
            else {
                return null
            }
        })
  }
  catch(e:any) {
    console.log(e.response.error);
    
  }
};
