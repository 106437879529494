import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateContractor = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/contractor', putData).then((res)=> {
        if(res.success===true) {
            return res
        }
        else {
                HandleApiError(res.error.msg)
        }
    }) }
    catch(e) {
         HandleApiError(e)   
    }

};
