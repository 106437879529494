import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
export const createEmployment = async (postData:any) => {
    try {
        return await post<ApiResponse<[]>>(`/cleaner/employment`, postData).then((res)=> {
            if(res.success===true) {
                return res
            }
            else {
                toast.error(res.error) 
                return null
            }
        })
    }
    catch(e:any) {
        HandleApiError(e);
            return null
    }};
