import { DateFormat } from "sr/helpers/functions/DateFormat";

export const TableRow = ({item}) => {
  return (
    <tr className="bg-white border-b  hover:bg-gray-50">
      
        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
     {DateFormat(item?.createdAt)}
        </th>
        <td className="px-6 py-4">
<div className="text-base cursor-pointer">
   Completed
</div>
        </td>
        <td className="px-6 py-4">
           {item?.type ==='transfer' && 'Credited'} {item?.type ==='mint' && 'Added in Wallet'}
        </td>


        {/* <td className="px-6 py-4">
        FRM45622
        </td> */}
        <td className="px-6 py-4">
{ item?.type ==='transfer' && `+$${ Number(item?.amount)?.toFixed(2)}`}{ item?.type ==='mint' && `-$${Number(item?.amount)?.toFixed(2)}`}
  </td>
    </tr>
     );
};
