import React from 'react'
import { toAbsoluteUrl } from 'sr/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
export const FormDetails = () => {
  return (
  <div className='my-4 lg:my-0 lg:flex hidden flex-col lg:w-1/3 w-full p-6 items-center bg-white border border-gray-border rounded-xl h-fit '>
                        <img src={toAbsoluteUrl('/media/img/cleaning_illu.png')}
                            className='bg-gray-border rounded-full'
                            style={{ maxHeight: '190px' }}></img>
                        <h3 className='mt-5 text-lg '>
                            <FormattedMessage 
                            id='AUTH.LOGIN.FORMDETAILS.TITLE'
                            defaultMessage='On login, you can'
                            />
                        </h3>
                        <div className='mt-5'>
                            <h5 className=''>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-green-600' />
                                <FormattedMessage 
                            id='AUTH.LOGIN.FORMDETAILS.BUILD.TEXT'
                            defaultMessage='Build your profile and let recruiters find you'
                            />
                                </h5>
                            <h5 className=' mt-5'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-green-600' />
                                <FormattedMessage 
                            id='AUTH.LOGIN.FORMDETAILS.GETJOB.TEXT'
                            defaultMessage='Get job posting delivered right to your email'
                            />
                                </h5>
                            <h5 className=' mt-5'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-green-600' />
                                <FormattedMessage 
                            id='AUTH.LOGIN.FORMDETAILS.FINDJOB.TEXT'
                            defaultMessage='Find a job and grow your career'
                            />
                                </h5>
                        </div>
                    </div>
  )
}
