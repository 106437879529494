import React, {useEffect, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { InputSearch } from 'sr/helpers/InputSearch'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { JobsListingCard } from './component/JobsListingCard'
import { TopBar } from '../component/TopBar'
import { FormattedMessage, useIntl } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs'
import { Empty } from '../candidates/component/Empty'
import ContentWithLoader from 'sr/layout/common/ContentLoader'
import { JobsLoadingCard } from './JobsLoadingCard'
import Job from 'app/pages/component/types/Job'
import { Pagination } from 'app/pages/component/Pagination'
export const JobsListing = () => {
  const intl = useIntl()
    const [searchShow, setSearchShow] = useState(false)
    const [jobs, setJobs] = useState<Job[]>([]);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [id, setId] = useState(null)
    const [loader, setLoader] = useState(false)
    const [totalPage, settotalPage] = useState<number>(1);
    const [totalJobs, setTotalJobs] = useState(0)
    const [filterjobs, setFilterJobs] = useState<Job[]>([]);
    const [openPauseCount, setOpenPauseCount] = useState(0)
    const [closeCount, setCloseCount] = useState(0)
    const [tab, setTab] = useState('Open and Pause')
    const [search, setSearch] = useState<string>()
    const [searchLocation, setSearhLocation] = useState<string>()
    const [sortBy, setSortBy] = useState('')

    useEffect(()=> {
      const getdetails = getUserDetails()
      setId(getdetails.user_id)
    },[id])
    const fetchJobs = async ()=> {
      setLoader(true)
      const queryParams = new URLSearchParams();  
      if (id) queryParams.append('company_id', id.toString());
      if (page) queryParams.append('page', page.toString());
      if (limit) queryParams.append('limit', limit.toString())
      if (sortBy) queryParams.append('sort', sortBy)
      const result = await getJobs(queryParams)
      if(result?.success===true) {
      setJobs(result?.data)
      setTotalJobs(result?.pagination.page * result?.pagination.total)
      settotalPage(Math.ceil(result?.pagination.total/limit))
      const obj = result?.pagination?.statusCounts;
      let openandpausecount = 0;
      let closedcount = 0;
          for (let key in  obj) {
              if(key === 'open' || key==='pause' || key==='active') {
                openandpausecount = openandpausecount+obj[key]
              }
              if(key==='closed') {
                  closedcount = closedcount+obj[key]
              }
          }
          setOpenPauseCount(openandpausecount)
          setCloseCount(closedcount)
      setLoader(false)
      }
    }
    useEffect(()=> {
      
      if(id) {
      fetchJobs()
      }
    },[id, page, limit, sortBy])

    useEffect(()=> {
        if(tab === 'Closed') {
          const closedjob = jobs?.filter((item) => item.status==='closed')
          setFilterJobs(closedjob)
        }
        else {
          const openpaused = jobs?.filter((item) => item.status==='active' || item.status==='open' ||item.status==='pause' )
          setFilterJobs(openpaused)
        }
    },[tab, jobs, search])
  useEffect(()=> {
    if(tab === 'Closed') {
  const res =   jobs?.filter((item)=> item.job_title?.toLowerCase().includes(search?.toLowerCase()) && item.status==='closed' )
  setFilterJobs(res)
    }
    else {
      const res = jobs?.filter((item)=> item.job_title?.toLowerCase().includes(search?.toLowerCase()) && (item.status==='active' || item.status==='open' ||item.status==='pause' ))
  setFilterJobs(res)
    }
  },[search])

  useEffect(()=> {
    if(tab === 'Closed') {
  const res =   jobs?.filter((item)=>  (item?.job_advanced_id?.reporting_address?.city.toLowerCase().includes(searchLocation?.toLowerCase()) ||item?.job_advanced_id?.reporting_address?.address_line_1.toLowerCase().includes(searchLocation?.toLowerCase()) ||
  item?.job_advanced_id?.reporting_address?.address_line_2.toLowerCase().includes(searchLocation?.toLowerCase()) && item.status==='closed' ))
  setFilterJobs(res)
    }
    else {
      const res = jobs?.filter((item)=> (item?.job_advanced_id?.reporting_address?.city.toLowerCase().includes(searchLocation?.toLowerCase()) ||item?.job_advanced_id?.reporting_address?.address_line_1.toLowerCase().includes(searchLocation?.toLowerCase()) ||
      item?.job_advanced_id?.reporting_address?.address_line_2.toLowerCase().includes(searchLocation?.toLowerCase())) && (item.status==='active' || item.status==='open' ||item.status==='pause' ))
  setFilterJobs(res)
    }
  },[searchLocation])
  return (
    <MainWrapper>
        <div className='py-3'>
 <TopBar title = {<FormattedMessage id="RECRUITER.POSTED.JOB.JOB.TITLE" defaultMessage="Jobs" /> } />
    <div className='flex items-center mb-5'>
<div onClick={()=> setTab('Open and Pause')} className={`rounded-l-lg font-bold px-5 py-2.5  cursor-pointer ${tab === 'Open and Pause' ? 'bg-[#2557A7] text-white' :'bg-white text-[#2557A7]' }`}>
<FormattedMessage id="RECRUITER.POSTED.JOB.STATUS" defaultMessage="Open and Paused {number}" values={{number:openPauseCount}}/>  
</div>
<div onClick={()=> setTab('Closed')} className={`rounded-r-lg font-satoshiBold px-5 py-2.5  cursor-pointer border border-neutral-200 ${tab === 'Closed' ? 'bg-[#2557A7] text-white' :'bg-white text-[#2557A7]' }`}>
<FormattedMessage id="RECRUITER.POSTED.JOB.CLOSED" defaultMessage="Closed {number}" values={{number:closeCount}}/>  
</div>
    </div>
    <div className='flex items-center justify-between'>
      <div className='flex justify-start items-center space-x-4'>
        <div>
      <p className='text-base font-satoshiBold'> <FormattedMessage id="RECRUITER.POSTED.JOB.TITLES" defaultMessage="Search Job Titles" />  </p>

<InputSearch onChange={(e)=> setSearch(e.target.value)}  value={search} noicon = {true} className="border border-black rounded-lg my-0" 
placeholder={intl.formatMessage({
    id: 'INPUT.SEARCH.JOB.TITLES.PLACEHOLDER',
    defaultMessage: 'Search Candidates',
  })}
 height = 'h-8' borderColor='border-[#275df5]' inputdiv="rounded-lg w-60">
                                      </InputSearch>
                                    
</div>

<div>
      <p className='text-base font-satoshiBold'> <FormattedMessage id="INPUT.SEARCH.LOCATION.TITLE" defaultMessage="Search Location" />  </p>
      <InputSearch onChange={(e:any)=> setSearhLocation(e.target.value)} noicon = {true} inputdiv="rounded-lg w-60" className="border border-black rounded-lg my-0" placeholder={intl.formatMessage({ id:"INPUT.SEARCH.LOCATION.TITLE", defaultMessage:"Search Location" })} height = 'h-8' borderColor='border-[#275df5]'>
                                      </InputSearch>
    </div>
    
    </div>
    <div className='flex justify-start items-center space-x-4 my-4'>
      <DropDown value={sortBy} onChange={(e)=> setSortBy(e.target.value)} dropdowndiv="mt-0" label={intl.formatMessage({
    id: 'RECRUITER.POSTED.JOB.FILTER.SORTBY.TITLE',
    defaultMessage: 'Sort By:',
  })} className="rounded-lg" labelMargin="pb-0.5">
        <option> <FormattedMessage id="RECRUITER.POSTED.JOB.FILTER.SORTBY.TYPE" defaultMessage="Job Title" />  </option>
        <option value={'job_title'}> A - Z </option>
        <option value={'-job_title'}> Z - A </option>
      </DropDown>
  
    </div>
    </div>
    <ContentWithLoader loading={loader} content={<JobsLoadingCard></JobsLoadingCard>}>
    {filterjobs?.length > 0 ? (

    filterjobs?.map((data)=> (<>
   <JobsListingCard jobs={data} fetchJobF={fetchJobs}/>
   </>
   
   ))):
   
     <Empty text="You do not have any job opening"></Empty>
   }
  { filterjobs?.length >0 &&
  <Pagination page={page} totalPage={totalPage} setPage={setPage} />
}
   </ContentWithLoader>
    </div>

    </MainWrapper>
  )
}
