import React, {useRef, useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { SliderContainer } from '../Slider/SliderContainer';
import { data } from '../JobsData';
import { OverviewSidebar } from './Sidebar/OverviewSidebar';
import { FormattedMessage, useIntl } from 'react-intl';
import CompanyInfo from 'app/pages/component/types/CompanyInfo';
import CompanyType from '../type/CompanyType';
import { DateFormat } from 'sr/helpers/functions/DateFormat';
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
import Jobs from 'app/pages/component/types/Job';
interface OverviewProps {
data:CompanyType,
addInfo:CompanyInfo,
loader:boolean
jobs:Jobs[]
}

export const Overview: React.FC<OverviewProps>= ({data, addInfo, loader, jobs}) => {
  const intl = useIntl()
    const [activeTab, setActiveTab] = useState(0)
  return (
    <ContentWithSpinner loader={loader} height='h-52'>
    <div className='grid lg:grid-cols-3 grid-cols-1 gap-4 pt-4'>
    <div className='col-span-2'>
        <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
            <h1 className='font-bold text-base pb-2'> <FormattedMessage id="GENERAL.TEXT.ABOUT" defaultMessage="About" /> {data?.company_name}</h1>
            <p className='text-sm '>{addInfo?.about_company}</p>
        </div>
{/* <SliderContainer data={data} text={<FormattedMessage id='COMPANY.JOBS.DEPARTMENT.TITLE' defaultMessage='Department hiring at {companyName}' values={{companyName:data?.company_name}}/>}
  bg='bg-gradient-to-b from-[#fff] to-[#fff3dd]'/> */}
                    <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
            <h1 className='font-bold text-base pb-2'><FormattedMessage id="COMPANY.MORE.INFORMATION.TITLE" defaultMessage="More Information" /></h1>
            <div className='flex w-full flex-wrap'>
            {/* <p className='text-sx w-1/2'> <span className='font-bold mr-2'><FormattedMessage id="COMPANY.INFORMATION.TEXT.TYPE" defaultMessage="Type" />:</span>Private </p> */}
            <p className='text-sx w-1/2 mb-2'> <span className='font-bold mr-2'><FormattedMessage id="COMPANY.INFORMATION.TEXT.FOUNDED" defaultMessage="Founded" />:</span>{DateFormat(addInfo?.date_of_incorporation)}</p>
            <p className='text-sx w-1/2'> <span className='font-bold mr-2'><FormattedMessage id="COMPANY.INFORMATION.TEXT.TOTAL.EMPLOYEE" defaultMessage="Total Employees" />:</span>{addInfo?.no_of_employees}</p>
            <p className='text-sx w-1/2'> <span className='font-bold mr-2'><FormattedMessage id="COMPANY.INFORMATION.TEXT.TOTAL.CLIENTS" defaultMessage="Total Clients" />:</span>{addInfo?.no_of_clients}</p>
            <p className='text-sx w-1/2'> <span className='font-bold mr-2'><FormattedMessage id="COMPANY.INFORMATION.TEXT.TOTAL.BRANCHES" defaultMessage="Total Branches" />:</span>{addInfo?.number_of_branches}</p>
            </div>
        </div>
        <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
            <h1 className='font-bold text-base pb-2'><FormattedMessage id="INTERESTED.JOBS.TITLE" defaultMessage="Jobs you might be interested in" /></h1>
            <div className='w-full flex-wrap'>
            <div className='flex gap-8 pb-1.5'>
                    <span onClick={(e)=> setActiveTab(0)} className={`font-bold text-sm text-[#717b9e] px-3 cursor-pointer ${activeTab == 0 ? 'border-b-[3px] border-black text-black' : '' }`}><FormattedMessage id="COMPANY.JOBS.SIMILAR.TEXT" defaultMessage="Companies Similar to {companyName}" values={{companyName:'Digit Insurance'}} />  </span>
                    <span onClick={(e)=> setActiveTab(1)} className={`font-bold text-sm text-[#717b9e] px-3 cursor-pointer ${activeTab == 1 ? 'border-b-[3px] border-black text-black' : ''}`}>Digit Insurance jobs across locations</span>
                </div>
                <div className='border-b -mt-1.5'></div>
                {activeTab == 0 ? (
                <ul className='flex py-4 list-disc list-inside	flex-wrap py-1'>
                    <li className='text-xs w-1/3 font-semibold	text-[#717b9e] 	'>You Broadband Jobs</li>
                    <li className='text-xs w-1/3 font-semibold	text-[#717b9e] 	'> Gojek Jobs</li>
                    <li className='text-xs w-1/3 font-semibold	text-[#717b9e] 	'> Simpl Jobs</li>
                    </ul>) : null
}
{activeTab == 1 ? (
                <ul className='flex py-4 list-disc list-inside	flex-wrap'>
             {       [{}, {}, {}, {}, {}, {}].map((data) => (
                    <li className='py-1 text-xs w-1/3 font-semibold	text-[#717b9e] 	'>Digit Insurance jobs in Bangalore Bengaluru</li>
             ))}
                    </ul>) : null
}

            </div>
        </div>
    </div>
  <OverviewSidebar data={data}/>
</div>
</ContentWithSpinner>
  )
}
