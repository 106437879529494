import React, {useEffect, useRef, useState} from 'react'
import { Input, TextArea, index } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { customerType } from 'sr/i18n/messages/setting'
import { InputNumberFormat } from 'sr/helpers/functions/InputNumberFormat'
export const GeneralForm = ({errors, getData, allData }) => {
    const intl = useIntl()
    const [customerName, setCustomerName] = useState<string>('')
    const [phoneNo, setPhoneNo] = useState()
    const [email, setEmail] = useState()
    const [type, setType] = useState()
    const [remark, setRemark] = useState()
   
    useEffect(()=> {
        setCustomerName(allData?.customerName)
        setPhoneNo(allData?.phoneNo)
        setEmail(allData?.email)
        setType(allData?.type)
        setRemark(allData?.remarks)
    },[allData])
    const data = {
        customerName:customerName,
        phoneNo:phoneNo,
        email:email,
        type:type,
        remark:remark
    }
    useEffect(()=> {
        getData(data)
    },[customerName, phoneNo, email,type, remark])
  return (
    <>
    <Input onChange={(e:any)=> setCustomerName(e.target.value)} label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.CUSTOMERNAME.TITLE" defaultMessage="Customer Name" />}
    type={'text'}
    error={errors.name}
    value={customerName}
    placeholder={intl.formatMessage({
        id: "RECRUITER.CUSTOMER.INPUT.CUSTOMERNAME.PLACEHOLDER",
        defaultMessage: "Enter Customer Name",
      })}
    inputClass = 'border border-zinc-800 rounded-lg h-11'>
</Input>
<Input
onChange={(e:any)=> setPhoneNo(e.target.value)}
error={errors.mobile_number}
value={InputNumberFormat(phoneNo)}
 label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.PHONENO.TITLE" defaultMessage="Phone Number" />}
      placeholder={intl.formatMessage({
        id: "RECRUITER.CUSTOMER.INPUT.PHONENO.PLACEHOLDER",
        defaultMessage: "Enter Phone Number",
      })}
    type={'tel'}
    inputClass = 'border border-zinc-800 rounded-lg h-11'>
</Input>
<Input 
onChange={(e:any)=> setEmail(e.target.value)}
error={errors.email}
value={email}
label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.EMAIL.TITLE" defaultMessage="Email" />}
placeholder={intl.formatMessage({
    id: "RECRUITER.CUSTOMER.INPUT.EMAIL.PLACEHOLDER",
    defaultMessage: "Enter your Email Address",
  })}
    type={'email'}
    inputClass = 'border border-zinc-800 rounded-lg h-11'>
</Input>
<DropDown onChange={(e:any)=> setType(e.target.value)} value={type} error={errors.type} dropdowndiv='text-gray-700 mt-10' labelMargin="mb-1" label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TYPE.TITLE" defaultMessage="Type" />} className='border border-zinc-800 rounded-lg h-11'>
    <option value=''><FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TYPE.SELECTTYPE" defaultMessage="Select Type" /></option>
    {customerType.map((item, index)=> (
    <option key={index} value={item.value}>{item.label}</option>
    ))}
</DropDown>
<div className='mt-10'>
<TextArea 
labelClass={'text-normal font-satoshiBold text-sm'} 
rows={4} 
label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TEXTAREA.TITLE.REMARKS" defaultMessage="Remarks" />} 
onChange={(e:any)=> setRemark(e.target.value)} value={remark}
placeholder={intl.formatMessage({ id:'RECRUITER.CUSTOMER.INPUT.TEXTAREA.PLACEHOLDER', defaultMessage:"Write your note here..." })}
className={'block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0'} 
error={errors.remarks}>
</TextArea>
</div>
</>  )
}
