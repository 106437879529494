import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder"
import convertToISOFormat from "../../functions/convertToISOFormat"

export const fetchWorkOrder = async (userType, userId, page, limit, startDate, endDate, status,location, customerLocationId, customer, setLoader, setData, settotalPage, setStatusCount)=> {
    try {
      setLoader(true)
      const queryParams = new URLSearchParams()
      if(userType==='company') {
      if (userId) queryParams.append('company_id', userId)
      }
    if(userType==='supervisor'){
      queryParams.append('customer_location_id', customerLocationId)
    }
      if (page) queryParams.append('page', page.toString())
      if (limit) queryParams?.append('limit', limit.toString())    
      if (startDate && endDate) queryParams?.append('one_time_date>', convertToISOFormat(startDate))  
      if (startDate && endDate) queryParams?.append('one_time_date<', convertToISOFormat(endDate))  
      if (location) queryParams?.append('location', location)  
        let obj = {};
      if(status.length>0) {
        queryParams.append('status', status.toString())
  }
        else {
          
          queryParams.append('status!', 'deleted');
          
        }   
        if(customer) {
          obj['customer_id'] = {"$in":customer}
        }
        
        let filter = {}
        if (Object.keys(obj).length>0) {
         filter['$and'] = [obj]
        }

      queryParams?.append('filter', JSON.stringify(filter))
        const res = await getWorkorder(queryParams)
      if(res?.success) {
          setData(res?.data)
          settotalPage && settotalPage(Math.ceil(res?.pagination?.total/limit))
          setLoader(false)
          console.log(res);
          
          if(setStatusCount) setStatusCount(res?.pagination?.counts)
      }
      else {
        setLoader(false)
      }
    }
    catch(e) {
        console.log(e);
        
        setLoader(false)
    }
}