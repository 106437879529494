import React from 'react'

export const DeviceManagement = () => {
  return (
    <>
    <div className='mb-3'>You are currently signed into your Indeed account on these devices.</div>
    
<div className="relative overflow-x-auto">
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-sm text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                Device
                </th>
                <th scope="col" className="px-6 py-3">
                    Date logged in
                </th>
                <th scope="col" className="px-6 py-3">
                    IP address
                </th>
                
            </tr>
        </thead>
        <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Chrome Windows
                </th>
                <td className="px-6 py-4">
                July 25, 2023
                </td>
                <td className="px-6 py-4">
                47.9.111.183
                Lucknow, 36
                </td>
                <td className="px-6 py-4">
                <div className="text-[#0078ae]  inline-flex hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
                Sign out</div>
    
                </td>
            </tr>
        </tbody>
    </table>
</div>

    </>
  )
}
