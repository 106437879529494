import React, {useState, useEffect} from 'react'
import { RadioInput } from 'sr/helpers/ui-components/company/RadioInput'
import { Input } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { ReactDatePicker } from 'sr/helpers/ui-components/ReactDatePicker'
export const ScheduleCalendarBottom = ({endsHandle, ends=null}) => {
    const [RecurrenceEnd, setRecurrenceEnd] = useState('Never')
  const [RecurrenceEndDate, setRecurrenceEndDate] = useState(new Date())
  const [RecurrenceAfter, setRecurrenceAfter] = useState()

  const endData =  {
    never: RecurrenceEnd==='Never',
    on: RecurrenceEndDate,
    after: RecurrenceAfter
}

useEffect(()=>{
setRecurrenceEndDate(null)
setRecurrenceAfter(null)
},[RecurrenceEnd])

useEffect(()=> {
  if(ends?.never===true) {
    setRecurrenceEnd('Never')
  }
  if(ends?.on) {
    setRecurrenceEnd('On')
  }
if(ends?.after) {
  setRecurrenceEnd('After')
}
setRecurrenceEndDate(ends?.on)
setRecurrenceAfter(ends?.after)
},[ends?.never, ends?.on, ends?.after])



  useEffect(()=> {
    endsHandle(endData)
  },[RecurrenceEnd,RecurrenceEndDate,RecurrenceAfter ])

  return (
    <div>
    <p className='pt-2 font-satoshiBold'>{<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.ENDS.TITLE" defaultMessage="Ends" />}</p>
    <RadioInput name={"Never"} value="Never" label={<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.NEVER" defaultMessage="NEVER" />} checked={RecurrenceEnd === 'Never'} onChange={(e) => setRecurrenceEnd(e.target.value)}></RadioInput>
      <div className='flex items-center space-x-6'>
      <RadioInput name="On" value="On" label={<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.ON" defaultMessage="On" />} checked={RecurrenceEnd === 'On'} onChange={(e) => setRecurrenceEnd(e.target.value)}></RadioInput>
      <ReactDatePicker onChange={(date)=> setRecurrenceEndDate(date)} type="date" placeholder="Enter date" selected={RecurrenceEndDate} inputClass={`rounded-lg ${RecurrenceEnd === 'On' ? '': '!bg-[#dddddd]'}`} labelClass="mt-2 text-black" InputDivClass="mt-0 w-26 pr-2 pb-2"></ReactDatePicker>
        </div>  
        <div className='flex items-center space-x-6'>
      <RadioInput name="After" value="After" label={<FormattedMessage id="RECRUITER.SCHEDULEWORKORDER.RADIOINPUT.After" defaultMessage="After" />} checked={RecurrenceEnd === 'After'} onChange={(e) => setRecurrenceEnd(e.target.value)}></RadioInput>
      <div className='relative'>
      <Input onChange={(e:any)=> setRecurrenceAfter(e.target.value)} value={RecurrenceAfter} disable={RecurrenceEnd === 'After' ? false:true} inputClass={` ${RecurrenceEnd === 'After' ? '': '!bg-[#dddddd]'} rounded-lg`} labelClass="mt-2 text-black" InputDivClass={`mt-0 w-26 pr-2 pb-2`} type="number"></Input>
        <p className='absolute right-12 top-[0.35rem] text-base text-gray-500 font-satoshiBold font-bold'>occurences</p>
  </div></div>
        </div>    
  )
}
