const SidebarData = [
    { icon: <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
        , 
        smalltext:'Contact information and password',
        text: 'Account Settings'
    },
    { icon: <svg className="w-5 h-5" aria-hidden="true" height="14" role="presentation" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg" ><path d="M14.5 0.5H1.5C0.583333 0.5 0 1.08333 0 2V12C0 12.9167 0.583333 13.5 1.5 13.5H14.5C15.4167 13.5 16 12.9166 16 11.9999V2C16 1.08333 15.4167 0.5 14.5 0.5ZM2 4.5H10V7.5H2V4.5ZM2 9.5H10V11.5H2C2 11.5 2 11.4583 2 11V9.5ZM14 11.5H12V4.5L14 4.49992V11C14 11.4583 14 11.5 14 11.5Z" fill="currentColor"></path></svg>
        , 
        smalltext:'Company name and details',
        text: 'Job Preferences'
    },
    
    {
        icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
      </svg>,   
      smalltext: 'Manage your active devices and settings',   
        text: 'Device Management'
        
    }
]
export {SidebarData}