import { toast } from "react-toastify"
import { HandleApiError } from "sr/helpers/functions/HandleApiError"
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage"
import ApiResponse from "sr/models/ApiResponse"
import { post } from "sr/utils/axios"

export const registerCompany = async (postData:any) => {
try {
return await post<ApiResponse<[]>>('/company/', postData).then((res)=> {
if(res.success === true) {
    SuccessMessage("API.SUCCESS.COMPANY.REGISTER")
    return res.data
}
else {
    
    toast.error(res.error.message)
    return null
}
})
}
catch(e:any) {
    HandleApiError(e)
}
}