import { faBookBookmark, faBookmark, faDollarSign, faLocationDot, faMagnifyingGlass, faMagnifyingGlassLocation, faMagnifyingGlassPlus, faNoteSticky, faStar, faSuitcase, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { DaysConverter } from 'sr/helpers/functions/DaysConverter'
import { HTMLStringRender } from 'sr/helpers/ui-components/HTMLStringRender'
import Job from 'app/pages/component/types/Job'
interface ChildProps {
  jobs: Job;
  link:string,
  isSavedJobs?: boolean | null;
}

const JobItemCard:React.FC<ChildProps> =({link, jobs, isSavedJobs=null })=> {
    const [postedDays, setpostedDays] = useState(null)
    useEffect(()=> {
        setpostedDays(DaysConverter([jobs?.createdAt]))
    },[jobs])
    return (
        <Link to={link ? link : `/job-detail/${jobs?.id}`}>
            <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
                <div>
                    <div className='font-bold font-satoshiBold text-lg'>
                        {jobs?.job_title}
                    </div>
                    <div className='lg:flex block items-center'>
                        {/* <span className='text-sm font-satoshiBold font-bold'>{jobs?.companyname}</span> */}
                        {/* {
                            isSavedJobs &&
                            <div className='items-center justify-items-start lg:justify-items-end grid grid-cols-2 divide-x divide-solid divide-slate-400'>
                                <div>
                                    <span className='px-2'>
                                        <FontAwesomeIcon icon={faStar} className='text-yellow-500 text-sm' />
                                        <span className='text-sm text-gray-500 font-satoshiBold font-bold'>3.5</span>
                                    </span>
                                </div>
                                <div className='text-sm text-gray-500 font-satoshiBold font-bold pl-[10px]'>
                                    729 <FormattedMessage id='CLEANER.JOBS.REVIEWS' defaultMessage='Reviews'></FormattedMessage>
                                </div>
                            </div>
                        } */}
                    </div>
                </div>

                <div className="mt-3 grid grid-cols-4 divide-x">
                    <div className='lg:px-0 px-4'>
                        <FontAwesomeIcon icon={faDollarSign} />
                        <span className='pl-2'>{jobs?.show_pay_by === 'Range' ? `${jobs?.min_amount}-${jobs?.max_amount}`: jobs?.min_amount||jobs?.max_amount||jobs?.exact_amount} {jobs?.rate}</span>
                    </div>
                    {jobs?.job_advanced_id?.reporting_address &&
                    <div className='lg:px-0 px-4 text-center'>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span className='pl-2'>{jobs?.job_advanced_id?.reporting_address?.address_line_1} {jobs?.job_advanced_id?.reporting_address?.address_line_2} {jobs?.job_advanced_id?.reporting_address?.city} {jobs?.job_advanced_id?.reporting_address?.state}</span>
                    </div>
}
                 
                    <div className='lg:px-0 px-4 flex justify-center'>
                    <div className='pl-2 text-gray-600 text-sm font-normal'>
                        Opening:
                        </div>
                        <span className='pl-2 font-satoshiBold'>{jobs?.job_advanced_id?.hire_count || 1}</span>
                    </div>
                    {jobs?.application_ids &&
                    <div className='lg:px-0 px-4 flex justify-center'>
                    <div className='pl-2 text-gray-600 text-sm font-normal '>
                        Applicant:
                        </div>
                        <span className='pl-2 font-satoshiBold'>{jobs?.application_ids?.length || 0}</span>
                    </div>
}
                </div>

                <div className='flex mt-2 items-center space-x-2'>
                    <FontAwesomeIcon icon={faNoteSticky} />
                    <HTMLStringRender htmlContent={jobs?.job_description?.slice(0,100)}></HTMLStringRender>{jobs?.job_description?.length>100 && '...' }
                </div>

                <div className='mt-3 flex items-center justify-between'>
                    <div className='text-xs font-bold font-satoshiBold text-gray-500'>
                        <FormattedMessage 
                           id='CLEANER.JOBS.TIME'
                           defaultMessage='{DayNumber} Days Ago'
                           values={{DayNumber:postedDays}}
                        />
                    </div>
                    <div>
                      
                            <Link to={`/job-detail/${jobs?._id}`} className='bg-pink hover:bg-navy text-white px-4 py-2 w-28 rounded-3xl'>
                                <FormattedMessage 
                                id='CLEANER.JOBS.CARD.BUTTON'
                                defaultMessage='Details'
                                />
                                </Link>
                
                    </div>
                    
                </div>
                <div className='flex justify-end mt-5 '>
                    {
                            isSavedJobs &&
                            <button className='w-20 rounded-3xl flex justify-center items-center'>
                                <FontAwesomeIcon icon={faBookmark} className='mr-2'></FontAwesomeIcon>
                                <span className='font-bold font-satoshiBold text-sm'> <FormattedMessage id="CLEANER.SAVEDJOBS.SAVED.TEXT" defaultMessage="Saved" />  </span>
                            </button>
                        }
                    </div>
            </div>
        </Link>
    )
}

export default JobItemCard;