import * as Yup from 'yup'
export const authSchema = Yup.object({
    username: Yup.string()
    .required('INPUT.VALIDATION.USERNAME.LOGIN')
    .min(3, 'INPUT.VALIDATION.USERNAME.MIN.CHARACTER')
    .max(20, 'INPUT.VALIDATION.USERNAME.MAX.CHARACTER')
    .matches(
      /^[a-zA-Z0-9_]+$/, 
      'INPUT.VALIDATION.USERNAME.MATCHES.CHARACTER'
    ),
    password:Yup.string().required('INPUT.VALIDATION.PASSWORD.LOGIN').min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER'),
})