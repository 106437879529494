import { updateCustomer } from "sr/utils/api/company/customer/updateCustomer"

export const DeleteCustomer = async (id, loadCustomerData)=> {
    const data = {
      id:id,
      status:'draft'}
  const res = await updateCustomer(data, 'API.SUCCESS.MANAGECUSTOMER.DELETE')
  if(res.success===true) {
    loadCustomerData()
  }
  }  