import React, { useEffect, useState } from 'react'
export const ModelWrapper = ({children, ...props}) => {
    const [showeffect, setShowEffect] = useState(false)
    const HandleModel = (data) =>{  
      setTimeout(() => {
        setShowEffect(false);
      }, 200);
      setTimeout(() => {
        props?.HandleModelByProps && props?.HandleModelByProps(data)
      },500);
  }
  useEffect(() => {
    if (props?.showModal || props?.showModel) {
      console.log(props?.showModal);
      setTimeout(() => {
        setShowEffect(true);
      }, 200);
    } 
  }, [props.showModal]);
  return (
    <>
    <div className={`justify-center items-center ${showeffect ? 'modeleffect':'popup'} transitioncss overflow-y-auto ${props.position} ${props.topPosition} ${props.rightPosition}  z-50 outline-none focus:outline-none`}>
    <div className={`relative  my-6 ${props.width} `}>
      {/*content*/}
      <div className={`${props.border} ${props.rounded} bg-white shadow-sm shadow-slate-300  relative flex flex-col w-full outline-none focus:outline-none`}>
       {children}
      </div>
    </div>
  </div>
  
  <span onClick={props?.noCancel ? null : ()=>HandleModel(false)} className={`fixed w-full h-full top-0 left-0 delay-100 visible ${props.background} ${props.opacity}`}></span>
        </>)
}
ModelWrapper.defaultProps =  {
width:'w-80',
border:'border-0',
topPosition:'top-5',
rounded:'rounded-[15px]',
position:'absolute',
opacity:'',
background:'',
rightPosition:''
}
