import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { remove } from "sr/utils/axios";

export const deleteRecWorkOrder = async (id) => {
  try {
    return await remove<ApiResponse<[]>>(`/workorder/model?id=${id}`).then(
      (res) => {
        if (res?.success === true) {
          return res;
        } else {
          return null;
        }
      }
    );
  } catch (e: any) {
    HandleApiError(e);
  }
};
