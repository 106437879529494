import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "sr/helpers";
import { FormattedMessage } from "react-intl";
export const Completed = () => {
  return (
<>
<div className="bg-white border border-neutral-300 rounded-lg my-3">
<div className="py-3 text-xl border-b border-neutral-300 px-3 ">Home Cleaning</div>
<div className="p-3">
<div className="flex justify-between">
        <div className="flex space-x-3 items-center">
          <img
            className="w-10 h-10 rounded-full"
            src={toAbsoluteUrl("/media/img/news-2.jpg")}
          ></img>
          <div className="text-lg ">Alex M.</div>
        </div>
      <div className="">
<div className="text-lg ">$53.98</div>
<p className="text-[#0078ae]  cursor-pointer"><FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.COMPLETED.RECEIPT" defaultMessage="View Receipt" /></p>
      </div>
      </div>
      <ul className="py-2">
        <li className="mb-1.5">
          <div className="flex space-x-2 items-center font-semibold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              ></path>
            </svg>
            <div>Friday, Nov 24 2023</div>
          </div>
        </li>
        <li className="mb-1">
          <div className="flex space-x-2 items-center font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

            <div>15:00</div>
          </div>
        </li>
      </ul>
      <div className="flex justify-between items-center space-x-4 mb-3">
      <div className="text-white flex-1 text-center cursor-pointer  bg-[#ff39b3] font-bold py-2 px-6 rounded-lg"><FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.COMPLETED.REBOOK" defaultMessage="Rebook" /> Jagdish M.</div>
<div className=" text-base cursor-pointer pl-10 text-[#0078ae]"><FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.COMPLETED.CHATHISTORY" defaultMessage="Chat History" /></div>
      </div>
</div>

</div>
<div className="bg-white border border-neutral-300 rounded-xl py-5">
 <div className="px-5">  
<img src={'/media/img/Ikea_logo.svg.png'} className="w-20 pb-5"></img>
<div className=" text-lg text-[#0078ae] pb-5">Full Apartment Cleaning</div>
<div className="text-lg "><FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.COMPLETED.TITLE" defaultMessage="Completed" /> Sat, Aug 07 at 11:00 PST</div>
<p className="text-base pb-5">14887 Hardie Ave, White Rock, BC V4B 2H6 Canada</p>
<div className="flex items-center space-x-1">
    <Link className="text-[#0078ae] font-bold" to={''}><FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.COMPLETED.VIEW.ORDER" defaultMessage="View order details" /></Link>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-[#0078ae] ">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
</svg>


</div>
</div>
</div>
</>
  
  );};
