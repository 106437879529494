import { FormattedMessage } from "react-intl";
const IndiaState =[
    {
    "key": "AN",
    "name": "Andaman and Nicobar Islands"
    },
    {
    "key": "AP",
    "name": "Andhra Pradesh"
    },
    {
    "key": "AR",
    "name": "Arunachal Pradesh"
    },
    {
    "key": "AS",
    "name": "Assam"
    },
    {
    "key": "BR",
    "name": "Bihar"
    },
    {
    "key": "CG",
    "name": "Chandigarh"
    },
    {
    "key": "CH",
    "name": "Chhattisgarh"
    },
    {
    "key": "DH",
    "name": "Dadra and Nagar Haveli"
    },
    {
    "key": "DD",
    "name": "Daman and Diu"
    },
    {
    "key": "DL",
    "name": "Delhi"
    },
    {
    "key": "GA",
    "name": "Goa"
    },
    {
    "key": "GJ",
    "name": "Gujarat"
    },
    {
    "key": "HR",
    "name": "Haryana"
    },
    {
    "key": "HP",
    "name": "Himachal Pradesh"
    },
    {
    "key": "JK",
    "name": "Jammu and Kashmir"
    },
    {
    "key": "JH",
    "name": "Jharkhand"
    },
    {
    "key": "KA",
    "name": "Karnataka"
    },
    {
    "key": "KL",
    "name": "Kerala"
    },
    {
    "key": "LD",
    "name": "Lakshadweep"
    },
    {
    "key": "MP",
    "name": "Madhya Pradesh"
    },
    {
    "key": "MH",
    "name": "Maharashtra"
    },
    {
    "key": "MN",
    "name": "Manipur"
    },
    {
    "key": "ML",
    "name": "Meghalaya"
    },
    {
    "key": "MZ",
    "name": "Mizoram"
    },
    {
    "key": "NL",
    "name": "Nagaland"
    },
    {
    "key": "OR",
    "name": "Odisha"
    },
    {
    "key": "PY",
    "name": "Puducherry"
    },
    {
    "key": "PB",
    "name": "Punjab"
    },
    {
    "key": "RJ",
    "name": "Rajasthan"
    },
    {
    "key": "SK",
    "name": "Sikkim"
    },
    {
    "key": "TN",
    "name": "Tamil Nadu"
    },
    {
    "key": "TS",
    "name": "Telangana"
    },
    {
    "key": "TR",
    "name": "Tripura"
    },
    {
    "key": "UK",
    "name": "Uttar Pradesh"
    },
    {
    "key": "UP",
    "name": "Uttarakhand"
    },
    {
    "key": "WB",
    "name": "West Bengal"
    }
    ]

    const employment = [
      {value:'Regular/Permanent', label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.REGULAR" defaultMessage="Regular/Permanent" /> },
      {value:'Fresher',  label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.FRESHER" defaultMessage="Fresher" /> },
      { value:'Part Time', label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.PARTTIME" defaultMessage="Part Time" /> },
      { value:'Internship', label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.INTERNSHIP" defaultMessage="Internship" /> },
      { value:'Contractual/Temporary', label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.CONTRACTUAL" defaultMessage="Contractual/Temporary" /> },
      { value:'Freelance', label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.FREELANCE" defaultMessage="Freelance" /> },
    ];
    
    const jobTypes = [
      { value: "ApartmentCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.APARTMENT" defaultMessage="Apartment Cleaner" /> },
    { value: "OfficeCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.OFFICE" defaultMessage="Office Cleaner" /> },
    { value: "BathroomCleaners", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.BATHROOM" defaultMessage="Bathroom Cleaners" /> },
    { value: "FloorCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.FLOOR" defaultMessage="Floor Cleaner" /> },
    { value: "AllCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.ALL" defaultMessage="All Cleaner" /> },
    { value: "FlatCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.FLAT" defaultMessage="Flat Cleaner" /> },
    { value: "KitchenCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.KITCHEN" defaultMessage="Kitchen Cleaner" /> },
    { value: "CarpetShampooing", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.CARPET_SHAMPOOING" defaultMessage="Carpet shampooing" /> },
    { value: "HouseCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.HOUSE" defaultMessage="House Cleaner" /> },
    { value: "SofaCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.SOFA" defaultMessage="Sofa Cleaner" /> },
    { value: "CarpetCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.CARPET" defaultMessage="Carpet Cleaner" /> },
    { value: "TilesCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.TILES" defaultMessage="Tiles Cleaner" /> },
    { value: "MarblePolishing", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.MARBLE" defaultMessage="Marble Polishing" /> },
    { value: "MattressCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.MATTRESS" defaultMessage="Mattress Cleaner" /> },
    { value: "CurtainsCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.CURTAINS" defaultMessage="Curtains Cleaner" /> },
    { value: "BlindsCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.BLINDS" defaultMessage="Blinds Cleaner" /> },
    { value: "FacadeCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.FACADE" defaultMessage="Facade Cleaner" /> },
    { value: "InteriorDesigning", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.INTERIOR_DESIGN" defaultMessage="Interior Designing" /> },
    { value: "Painting", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.PAINTING" defaultMessage="Painting" /> },
    { value: "PestControl", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.PEST_CONTROL" defaultMessage="Pest Control" /> },
    { value: "HotelCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.HOTEL" defaultMessage="Hotel Cleaner" /> },
    { value: "ACCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.AC" defaultMessage="AC Cleaner" /> },
    { value: "CommercialCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.COMMERCIAL" defaultMessage="Commercial Cleaner" /> },
    { value: "BirdNetting", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.BIRD_NETTING" defaultMessage="Bird Netting" /> },
    { value: "SwimmingPoolCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.SWIMMING_POOL" defaultMessage="Swimming Pool Cleaner" /> },
    { value: "SchoolCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.SCHOOL" defaultMessage="School Cleaner" /> },
    { value: "CarCleaner", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.CAR" defaultMessage="Car Cleaner" /> }
    ];
    

    const Schedule = [
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.MORNINGSHIFT" defaultMessage="Morning shift" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.ROTATIONALSHIFT" defaultMessage="Rotational shift" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.NIGHTSHIFT" defaultMessage="Night shift" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.MONDAYTOFRIDAY" defaultMessage="Monday to Friday" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.EVENINGSHIFT" defaultMessage="Evening shift" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.WEEKENDAVAILABILITY" defaultMessage="Weekend availability" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.FIXEDSHIFT" defaultMessage="Fixed shift" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.WEEKENDONLY" defaultMessage="Weekend only" /> },
    ];
    

    const supplementalpaydata = [
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.PERFORMANCE_BONUS" defaultMessage="Performance bonus" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.YEARLY_BONUS" defaultMessage="Yearly bonus" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.COMMISSION_PAY" defaultMessage="Commission pay" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.OVERTIME_PAY" defaultMessage="Overtime pay" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.QUARTERLY_BONUS" defaultMessage="Quarterly bonus" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.SHIFT_ALLOWANCE" defaultMessage="Shift allowance" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.JOINING_BONUS" defaultMessage="Joining bonus" /> },
      { "name": <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY.OTHER" defaultMessage="Other" /> },
      ];
      
      const benifitdata = [
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.HEALTH_INSURANCE" defaultMessage="Health insurance" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.PROVIDENT_FUND" defaultMessage="Provident Fund" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.CELL_PHONE_REIMBURSEMENT" defaultMessage="Cell phone reimbursement" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.PAID_SICK_TIME" defaultMessage="Paid sick time" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.WORK_FROM_HOME" defaultMessage="Work from home" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.PAID_TIME_OFF" defaultMessage="Paid time off" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.FOOD_PROVIDED" defaultMessage="Food provided" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.LIFE_INSURANCE" defaultMessage="Life insurance" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.INTERNET_REIMBURSEMENT" defaultMessage="Internet reimbursement" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.COMMUTER_ASSISTANCE" defaultMessage="Commuter assistance" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.LEAVE_ENCASHMENT" defaultMessage="Leave encashment" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.FLEXIBLE_SCHEDULE" defaultMessage="Flexible schedule" /> },
        { name: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENEFITS.OTHER" defaultMessage="Other" /> },
      ];
      const educationleveldata = [
        { name: "Secondary (10th Pass)" },
        { name: "Higher Secondary (12th Pass)" },
        { name: "Diploma" },
        { name: "Bachelor's" },
        { name: "Master's" },
        { name: "Doctorate" }
      ];
       
export {IndiaState, employment, Schedule, supplementalpaydata, benifitdata, educationleveldata, jobTypes};