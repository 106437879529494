import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getIndividual = async (queryParams) => {
    try {
  return await get<ApiResponse<[]>>(`/individual?${queryParams}`).then((res)=> {
                if(res?.success===true){
            return res
        }
        else {
            return null
        }
  })}
  catch(e){
    HandleApiError(e)
  }
}