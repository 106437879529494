import React from "react";
import { Button, Input } from "sr/helpers";
import { FormattedMessage } from "react-intl";
export const Balance = () => {
  return (
  <div className="py-3">
  <div className="pb-5">
    <p><span className="font-satoshiBold"><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TITLE" defaultMessage="Available Account Balance" />:</span>$0</p>
    <p>*<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TEXT" defaultMessage="Account balances are automatically applied when a task is Completed" />
</p>
    </div>
    <Input inputClass="rounded" InputDivClass="mt-2" placeholder="" label={<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.INPUT.CODE" defaultMessage="Enter a redemption code" />}></Input>
    <div className='flex my-5'>
   <div className="text-center rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy" ><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.APPLY.BUTTON" defaultMessage="Apply" /></div>
   </div>
    </div>

  );
};
