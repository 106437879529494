import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from 'sr/helpers'
import { ModelItems, ModelBottomItem } from './SidebarItems'
import { FormattedMessage } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getCompany } from 'sr/utils/api/company/getCompany'
import { useAuth } from 'app/pages/module/auth'
import { getSupervisor } from 'sr/utils/api/company/workorder/getSupervisor'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSupervisor } from 'sr/redux/reducers/supervisor'
import { AppDispatch } from 'sr/redux/store'
import { useSocket } from 'app/pages/component/SocketContext'
import { formatISO } from 'date-fns'
import { getMessage } from 'sr/utils/api/cleaner/getMessage'
import { CHAT_TYPE } from 'sr/i18n/messages/setting'
export const Header = (props) => {
  const {logout} = useAuth()
    const [model, setModel] = useState(false)
    const [userId, serUserId] = useState()
  const [showeffect, setShowEffect] = useState(false)
    const [data, setData] = useState(null)
    const [allmessage, setAllMessage] = useState([])
    const supervisorData = useSelector((state:any)=> state?.supervisor?.data)
    const dispatch = useDispatch<AppDispatch>();
        const audioFile = toAbsoluteUrl('/media/notification/notification-18-270129.mp3')


    const {userType} = useAuth()
useEffect(()=> {
  const getdetails = getUserDetails()
  serUserId(getdetails.user_id)
},[])

const fetchData = async () => {
  try {
    const queryParams = new URLSearchParams(); 
    if (userId) queryParams.append('id', userId); 
  const result = await getCompany(queryParams)
  if(result.success === true) {
      setData(result.data)
  }
}
catch(error) {
}
}
 const fetchChat = async ()=> {
                       const queryParams = new URLSearchParams()
                       queryParams?.append('recipient_id', userId)

                   try {
                   const res = await getMessage(queryParams)
                   if(res?.success===true) {
                    
                   setAllMessage(res?.data)     
                   }
                   else {
                   
                   }
                   }
                   catch(e) {
                   }
                      }
useEffect(()=> {
if(userId && userType) {
  if(userType==='company'){
  fetchData()
  }
  else {
    if(!supervisorData){
      dispatch(fetchSupervisor({id:userId}))
    }
  }
}
},[userId])
useEffect(()=> {
  if(supervisorData){
setData(supervisorData)
  }
},[supervisorData])
useEffect(()=>{
  if(userId){
  fetchChat()
  }
},[userId])
/* Open/End Model Popup With Effects */
const HandleModel = ()=> {
  if(!model){
    setModel(true)
    setTimeout(() => {
      setShowEffect(true)
    }, 200);
  }
  else {
    setTimeout(() => {
      setShowEffect(false)
    }, 200);
    setTimeout(() => {
      setModel(false)
    }, 500);
  }
}
      const socket = useSocket()
useEffect(() => { 
      if (!userId) return; 
      if(socket){
        socket.emit('register', userId);
    }
            socket.on('sendmessage', ({ from, message }) => {
              console.log(message);
              if(message){
                const audio = new Audio(audioFile)
                audio.play().catch((error) => console.error('Audio playback failed:', error)); // Play the audio
              const currentDateTime = formatISO(new Date());
                const newmessage = {createdAt:currentDateTime, ...message}
              setAllMessage((preview)=> [...preview, newmessage])    
              }
            });
            
          }, [userId]);
  return (
    <nav className={`${props.isOpen ? 'pl-48': 'pl-16'} duration-300 pr-4 border border-b-text-gray-500`}>
<div className="w-full flex bg-white justify-between py-1.5">
  <div className=''>
   <img width = {120}  src={toAbsoluteUrl('/media/logos/logo2.png')} alt='logo' />
  </div>
  <div className='lg:flex block space-x-4'>
   <Link className='flex items-center space-x-2' to="#">
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-5 h-5"><path fill-rule="evenodd" d="M10 2a8 8 0 100 16 8 8 0 000-16zm0 5.497a1.25 1.25 0 00-1.152.763c-.107.254-.322.487-.598.487h-.5c-.276 0-.505-.226-.455-.498a2.752 2.752 0 015.455.498c0 .779-.369 1.326-.765 1.714-.19.186-.399.349-.608.511-.162.126-.324.251-.477.387a.538.538 0 01-.357.142H9.75c-.276 0-.507-.231-.412-.49.137-.374.42-.687.736-.94.088-.072.192-.144.3-.22.405-.281.876-.609.876-1.104A1.25 1.25 0 0010 7.497zM9.25 13.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1z" clipRule="evenodd"></path></svg>
   <span><FormattedMessage id="RECRUITER.TOPBAR.MENU.HELP" defaultMessage="Help" />  </span>
   </Link>
   <Link className='flex items-center space-x-2' to="#">
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" height="24" className="w-5 h-5"><path d="M10 2a1.5 1.5 0 00-1.5 1.5v.229A5.002 5.002 0 005 8.5V12l-1.854 1.854a.5.5 0 00-.146.353v.293a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-.293a.5.5 0 00-.146-.353L15 12V8.5a5.002 5.002 0 00-3.5-4.771V3.5A1.5 1.5 0 0010 2zm.002 16a2.5 2.5 0 01-2.45-2h4.9a2.5 2.5 0 01-2.45 2z"></path></svg>   
   <span><FormattedMessage id="RECRUITER.TOPBAR.MENU.NOTIFICATION" defaultMessage="Notification" /></span>
   </Link>

   <Link className='flex items-center space-x-2 border-r border-text-gray-500 pr-4 relative' to="/messages">
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-5 h-5"><path fill-rule="evenodd" d="M2.5 4a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-15zm13.111 2.26L10 10 4.389 6.26A.25.25 0 004 6.466v.9a.25.25 0 00.111.207L10 11.5l5.889-3.926A.25.25 0 0016 7.366v-.899a.25.25 0 00-.389-.208z" clipRule="evenodd"></path></svg>
   <span><FormattedMessage id="RECRUITER.TOPBAR.MENU.MESSAGES" defaultMessage="Message" /></span>
   <div className='rounded-full bg-pink w-4 h-4 flex justify-center items-center absolute top-[0.25rem] -left-[0.4rem]'>
  <span className='text-[0.8rem] font-satoshiBold text-white'>{allmessage?.length}</span>
</div>
   </Link>
   <div className='flex flex-1 items-center space-x-2 border-r border-text-gray-500 pr-4' >
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5"><path fill-rule="evenodd" d="M3.5 3a.5.5 0 00-.5.5v17a.5.5 0 00.5.5H6v-6h5v6h9.5a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H14V3.5a.5.5 0 00-.5-.5h-10zM5 9h7v1H5V9zm7-3H5v1h7V6z" clipRule="evenodd"></path></svg>
   <div className='block'>
      <p className='-mb-1'>{data?.username}</p>
      <p className='text-[#767676] text-sm'><FormattedMessage id="RECRUITER.TOPBAR.MENU.OWNER.TITLE" defaultMessage="owner" />:{data?.email}</p>
   </div>
      </div>
      <Link  className='flex items-center space-x-2 relative' to="#">
      <svg onClick={HandleModel} xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>   
      <span onClick={HandleModel}>   {data?.email}</span>
      <span className={`transition-transform transform duration-1000 ${model ? 'rotate-180':''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg></span>
{model &&
<div className={`${showeffect ? 'modeleffect':'popup' }  w-[22rem] transitioncss z-50 absolute top-14 right-2 bg-white rounded-lg border border-slate-400	`}>
<div className='p-4'>
    {/* <h1 className='font-satoshiBold text-lg'>
        {data?.username}
    </h1>
    <ul>
        {ModelItems.map((data)=> (
        <li className='flex items-center space-x-2 py-3'>
        {data.icon}
        <span>{data.text}</span>
        </li>
        )) }
    </ul>
    <hr className='pt-2 border-slate-300'/> */}
    <h1 className='font-satoshiBold text-lg	 py-2'>
        {data?.email}
    </h1>
    <ul>
      {userType==='company' &&<>
        {ModelBottomItem.map((data)=> (
          <Link to={data.link ? data.link : '#'}>
        <li className='flex items-center space-x-2 py-3'>
        {data.icon}
        <span>{data.text}</span>
        </li>
        </Link> )) }
        </>}
        <li onClick={logout} className='flex items-center space-x-2 py-3'>
      
        <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
        className="w-5 h-5"
      >
        <path d="M4 3a2 2 0 00-2 2v9.998a2 2 0 002 2h5.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-6v-11h6a.5.5 0 00.5-.5V3.5a.5.5 0 00-.5-.5H4z"></path>
        <path d="M12.274 13.795a.5.5 0 000 .707l.354.353a.5.5 0 00.707 0l4.52-4.52a.503.503 0 00-.004-.71l-4.477-4.48a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l3.041 3.043H7.517a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h7.803l-3.046 3.046z"></path>
      </svg>
        <span><FormattedMessage
    id="RECRUITER.TOPBAR.MENU.SIGN.OUT"
    defaultMessage="Sign Out"
  /></span>
        </li>
        
    </ul>

</div>
</div>}
   </Link>
  </div>
</div></nav>
  )
}
