import React, {useState, useEffect} from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox } from 'sr/helpers';
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { InputSearch } from 'sr/helpers/InputSearch'
export const Model = (props) => {
    const [Filterdata, setFilterdata] = useState([]);
    const [showModal, setShowModal] = useState('')

    useEffect(() => {
        setShowModal(props.showModal);
      }, [props.showModal]);
      useEffect(()=> {
           if(props?.filterData[props?.fieldName]) {
          setFilterdata(props?.filterData[props?.fieldName])
           }
      },[props?.filterData])

    useEffect(()=>{
      if(Filterdata?.length>0){
          const updatedData = {
            ...props?.filterData,  // Spread the existing data
            [props?.fieldName]: Filterdata  // Add/overwrite the new field data
        };
        props?.setFilterdata(updatedData);
      }
    
    },[Filterdata])
    const HandleClearData = async (fieldName)=> {
        setFilterdata([])
        const { [fieldName]: removed, ...updatedFilterData } = props?.filterData; // Destructure and remove the field
        props?.setFilterdata(updatedFilterData); // Update the state with the new object
    }

  return (
    <>
    {showModal ? (
        <>
          <div className="justify-center items-center absolute top-0 inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-10 w-80">
              {/*content*/}
              <div className="border-0 bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300  relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                {/*body*/}
                <div className=' w-full'>
                <InputSearch placeholder={`Search ${props?.showModal}`} height = 'h-10' inputdiv='' borderColor='border-[#275df5]'>
                                              </InputSearch>
                                              </div>
                {/*footer*/}
                <div className="rounded-b">
                {
props?.data?.map((datI, index) =>  {                                    
 return <Checkbox
        id={datI?.value}
        name="filter"
        value={datI?.value}
        checked={Filterdata?.includes(datI?.value)}
        onChange={(e)=> CheckBoxHandle(e, Filterdata, setFilterdata)}
        label={datI?.label?.includes('RECRUITER.POSTJOB') ? <FormattedMessage id={datI?.label}></FormattedMessage> : datI?.label}
      />
})}
                </div>
                <div className='flex items-center  justify-between'>
                <div onClick={()=> HandleClearData(props?.fieldName)} className='text-sm pl-5 font-bold  text-[#275df5] cursor-pointer'>Clear</div>
                <Button onClick={()=> props?.fetchJobs()} text={'Apply'} className={'bg-[#ff39b3] font-bold text-white capitalize rounded-full'} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
              </div>
              </div>
             
            </div>
          </div>
        </>
      ) : null} 
       </>
  )
}
