
import {useRef } from "react";
import { FormattedMessage } from "react-intl";
import { MainWrapper } from "../../component/MainWrapper";
import Form from "./components/Form";
import { Warning } from "sr/helpers/ui-components/company/Warning";

export const AddSupervisor = () => {
  const childRef:any = useRef()
  const HandleFormSubmit = ()=> {
    if (childRef.current && childRef.current.submitForm) {
      childRef.current.submitForm()
    }
  }
  return (

  <MainWrapper>
   <div className="py-3 h-screen">
        <div className='mb-2 mt-5'>
        <h1 className='text-2xl  border-r border-neutral-200 pr-4'><FormattedMessage id="RECRUITER.CONTRACTOR.CREATE.SUPERVISOR" defaultMessage="Create Supervisor" /></h1>
    {/* <p><FormattedMessage id="RECRUITER.CONTRACTOR.CREATE.SUPERVISOR.TEXT" defaultMessage="Use this page to create new Supervisor" /></p> */}

            </div>
            <div className="mx-auto bg-white max-w-2xl px-10 border rounded-lg">
               <Form ref={childRef}></Form>
               <div className="flex justify-center my-10">
<div className="flex justify-center">
      <button onClick={HandleFormSubmit} className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy'>
      <FormattedMessage id="RECRUITER.CONTRACTOR.CREATE.SUPERVISOR" defaultMessage="Create Supervisor" />
</button>
</div>
</div>
            </div>
            </div>

   </MainWrapper>

);
};
