import React, {useState, useEffect} from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input } from 'sr/helpers'
import { payTypes } from 'sr/i18n/messages/setting'
import { FormattedMessage } from 'react-intl'
export const ExactAmount = (props) => {
  const [pay, setPay] = useState('per hour')
  const [exactAmount, setExactAmount] = useState()
  const HandleChange = (e) => {
    setPay(e.target.value)
}

useEffect(()=> {
setPay(props?.pay)
setExactAmount(props?.exactAmount)
console.log(props?.pay);
},[])

const RangeData = {
  exact_amount:exactAmount,
  payType:pay ? pay : 'per hour'
}

useEffect(()=> {
  props.HandleShowPay(RangeData)
},[exactAmount, pay])
  return (
    <div className='flex space-x-4 w-full'>
                 <Input 
                 onChange={(e:any)=> setExactAmount(e.target.value)}
                  label = "amount"
                  placeholder="$"
                  type={'text'}
                    inputClass = 'border border-zinc-800 rounded-lg h-11'
                    InputDivClass = 'w-1/3 mt-10 h-full'
                    value={exactAmount}
                    min={1}
                    error={props?.errors?.exact_amount}
                 />
                 <DropDown
    onChange={HandleChange}
    value={pay}
    dropdowndiv='flex-1 mt-10' 
    name="Rate" 
    label={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.TITLE" defaultMessage="Rate" />}
    className="h-11 mt-3 border border-zinc-800 rounded-lg">
       {payTypes.map((data, index)=> (
        <option key={index} value={data.value}>{data.label}</option>
           ))}
       </DropDown>
                    </div>
  )
}
