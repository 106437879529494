import React, { useEffect, useState } from "react";
import { setPreview } from "../function/setPreview";
import { uploadFile } from "sr/utils/api/uploadFile";
import { updatetaskManage } from "sr/utils/api/company/workorder/updatetaskManage";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { ModelWrapperWithButton } from "app/pages/company/component/ModelWrapperWithButton";
import { useIntl } from "react-intl";
import { createtaskManage } from "sr/utils/api/company/workorder/createtaskManage";
import { toast } from "react-toastify";

export const ReviewCard = ({item, managetasks, workorderId, fetchManageTask, contractor}) => {
    const [previewImages, setPreviewImages] = useState<any>([])
    const [previewVideo, setPreviewVideo] = useState<any>([])
    const [loader, setLoader] = useState(false)
    const [uploadedVideoKey, setUploadVideoKey] = useState<any>([])
    const [uploadedImgKey, setUploadImgKey] = useState<any>([])
    const [showModel, setShowModel] = useState(false)
    const [remarks, setRemarks] = useState('')
    const intl = useIntl()
    const getRemark = (taskId)=> {
        return managetasks?.find((item)=>item?.task_id?._id === taskId)?.contractor_remarks
  }
  const checkCompletedTask = (taskId)=> {
    return managetasks?.some((item) => item?.task_id?._id === taskId)
}
  useEffect(()=> {
    
    if(managetasks?.length>0 && item){
       const images = managetasks?.find((data)=> (data?.task_id?._id===item?.id))?.images
       const videos = managetasks?.find((data)=> (data?.task_id?._id===item?.id))?.videos
       if (images) {
    setPreview({type:'images', files:images, setPreviewImages:setPreviewImages, setLoader:setLoader})
       }
       if (videos) {
        setPreview({type:'videos', files:videos, setPreviewVideo:setPreviewVideo,  setLoader:setLoader})
       }    
}
},[managetasks, item])
const handleImage = async (e, id)=> {
    const allowedExtensions = ["image/jpeg", "image/jpg", "image/png"];
    setLoader(true)
  const  alluploadKey = []
    const files = Array.from(e.target.files  as FileList);
    for (const item of files) {
        if(!allowedExtensions.includes(item?.type)) {
            toast.error('Please upload valid Image File')
            return 
          }
        const formData = new FormData()
        formData.append('task_img', item)
        const res = await uploadFile(formData);
        if(res?.success===true) {
        alluploadKey.push(res?.data?.files[0]?.key)
        }

    }   
    let imageAddRes;
   const  uploadedImgKey = previewImages?.map((item)=> item?.key)
    const payload = {
contractor_status:'completed',
workorder_id:workorderId,
contractor_id:contractor[0]?.id,
supervisor_status: "pending",
status:'in progress',
task_id:item?.id,
images:[...uploadedImgKey, ...alluploadKey],
    }
            if(id) {
                imageAddRes = await updatetaskManage({id:id, ...payload}) 
            } 
            else {
                imageAddRes = await createtaskManage(payload)
            }   
    if(imageAddRes?.success===true) {
await fetchManageTask()
setLoader(false)
    }        
}
const handleVideo = async (e, id)=> {
    const allowedExtensions = ["video/mp4", "video/avi", "video/mov", "video/mpeg"];
    setLoader(true)
    const  alluploadKey = []
    const files = Array.from(e.target.files  as FileList);
    for (const item of files) {
        if(!allowedExtensions.includes(item?.type)) {
            toast.error('Please upload valid Video File')
            return 
          }
        const formData = new FormData()
        formData.append('task_video', item)
        const res = await uploadFile(formData);
        if(res?.success===true) {
            alluploadKey.push(res?.data?.files[0]?.key)
        }
    }   
    let videoAddRes;
   const  uploadedVidKey = previewVideo?.map((item)=> item?.key)
    const payload = {
contractor_status:'completed',
workorder_id:workorderId,
contractor_id:contractor[0]?.id,
supervisor_status: "pending",
status:'in progress',
task_id:item?.id,
videos:[...uploadedVidKey, ...alluploadKey],
    }
            if(id) {
                videoAddRes = await updatetaskManage({id:id, ...payload}) 
            } 
            else {
                videoAddRes = await createtaskManage(payload)
            }   
    if(videoAddRes?.success===true) {
await fetchManageTask()
setLoader(false)
    }        
}
const removeImage = async (key, id)=> {
    
    setLoader(true)
const filterimg = previewImages?.filter((item)=> item?.key != key)
const uploadimg = filterimg?.map((item)=> item?.key)
const payLoad =  {
    id:id,
    images:uploadimg,
}
try {
    const res = await updatetaskManage(payLoad)
    if(res?.success===true) {
            await fetchManageTask()
            setPreviewImages(filterimg)
            setLoader(false)
    }
}
catch(e){
setLoader(false)
}
}
const onCheckModel = ()=> {
    setShowModel(false)
}
const handleRemark = async (id)=> {
    setLoader(true)
    const payLoad =  {
        contractor_remarks:remarks,
        contractor_status:'completed',
        workorder_id:workorderId,
        contractor_id:contractor[0]?.id,
        supervisor_status: "pending",
        status:'in progress',
        task_id:item?.id
    }
    try {
        let res;
        if(id){
        res = await updatetaskManage({id:id, ...payLoad})
        }
        else {
            res = await createtaskManage(payLoad)
        }
        if(res?.success===true) {
                await fetchManageTask()
                setLoader(false)
                setShowModel(false)
        }
    
    }
    catch(e){

    }
}
  return (<>
    {loader && <LoaderFullScreen />}
    <div className='py-2 border-neutral-200 border-b'>
    <p className='text-base'><span className="font-satoshiBold">Name: </span>{item?.name}</p>
    <p className='text-base'><span className="font-satoshiBold">Description: </span>{item?.description}</p>
    <p className='text-base'><span className="font-satoshiBold">Type: </span>{item?.type}</p>
    <div className="flex items-start">
    <p className='text-base'><span className="font-satoshiBold">Remark: </span>{getRemark(item?.id) || 'Not Available'}</p>
    <svg onClick={()=> setShowModel(true)}  xmlns="http://www.w3.org/1000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5 text-[#2557A7] cursor-pointer"><path d="M20.706 7.042a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83zm-2.898 2.9l-9.705 9.71a.5.5 0 01-.218.128l-4.246 1.2a.5.5 0 01-.618-.616l1.209-4.24a.5.5 0 01.127-.216l9.7-9.716 3.75 3.75z"></path></svg>
    </div>
    <p className='text-base'><span className="font-satoshiBold">Status: </span>{checkCompletedTask(item?.id) ? 'Completed' : 'Not Completed'}</p>
    <div className="mb-2"><div className="text-base font-satoshiBold">Images:</div>
    <div className="flex flex-wrap w-full">
   {previewImages?.map((preview, index)=> (
    <div key={index} className="relative">
 <img className="w-48 mr-2 mb-2" src={preview?.blob}></img>
 <svg onClick={()=> removeImage(preview?.key, managetasks?.find((items)=> items?.task_id?._id===item?.id)?.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 absolute right-0 top-0 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>
</div>))}

 <label htmlFor={item?.id} className="cursor-pointer w-48">
 <div className="flex flex-col items-center justify-center py-10">
             <svg className="w-4 h-4 mb-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
             </svg>
             <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Upload Image</p>
 
         </div>
         
   <input multiple 
        accept="image/*"   onChange={(e)=> handleImage(e, managetasks?.find((items)=> items?.task_id?._id===item?.id)?.id)}   id={item?.id} type="file" className="hidden"></input>
 </label>
 </div>
    </div>
    <div><div className="text-base font-satoshiBold w-full">Videos:</div>
    <div className="flex flex-wrap">
   {previewVideo?.map((preview, index)=> ( 
    <div key={index} className="relative">
         <video className="h-96  mr-2 mb-2" controls>
         <source src={preview?.blob} type="video/mp4" />
       </video>
       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 absolute right-0 top-0 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>
 </div>
))}
<label htmlFor={`${item?.id}video`} className="cursor-pointer">
 <div className="flex flex-col items-center justify-center py-10 w-48">
             <svg className="w-4 h-4 mb-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
             </svg>
             <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Upload Video</p>
 
         </div>
   <input className="hidden" onChange={(e)=> handleVideo(e, managetasks?.find((items)=> items?.task_id?._id===item?.id)?.id)} id={`${item?.id}video`} type="file"></input>
 </label>
 </div>
    </div>
    </div>
    <ModelWrapperWithButton Save={()=> handleRemark(managetasks?.find((items)=> items?.task_id?._id===item?.id)?.id)} showModal={showModel} onCheckModel={onCheckModel}>
    <textarea onChange={(e)=> setRemarks(e.target.value)}  id="Description" rows={1} className="block p-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder={intl.formatMessage({id:'MYJOB.INPUT.REMARKS.PLACEHOLDER', defaultMessage:'Remarks'})}></textarea>
    </ModelWrapperWithButton>
    </> );
};
