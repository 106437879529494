import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createCustomer = async (postData:any) => {
    try {
  return await post<ApiResponse<[]>>('/customer/', postData).then((res)=> {
    if(res.success===true) {
        SuccessMessage("API.SUCCESS.CUSTOMER.CUSTOMER.ADD")
        return res
    }
    else {
        return null
    }
  })
}
catch(e:any) {
    HandleApiError(e)
}
};
