import React from 'react'

const Warning = (props: any) => {
  return (
    <div className={`${props.mainclassName} flex items-center rounded-lg space-x-4 px-4 border border-[#C7D2F6] py-4`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
         fill="currentColor" className={`${props.svgclassName} w-6 h-6`}><path fillRule="evenodd" 
         d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" 
         clipRule="evenodd"></path></svg>
         <div>
         <div className='font-normal text-xl font-satoshiBold'>
{props.heading}
        </div>
        <span className='font-normal text-base'>
{props.text}
        </span>
        </div>


    </div>
  )
}
Warning.defaultProps = {
  mainclassName:"bg-[#EEF1FE]",
  svgclassName:"text-[#3F73D3]"
}
export {Warning}
