import React, {useEffect, useRef, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Location } from './components/Tables/Location'
import { LocationDetailsView } from './components/LocationDetailsView'
import { ModelWrapperWithButton } from '../component/ModelWrapperWithButton'
import  LocationForm  from './components/LocationForm'
import { WhiteBtn } from '../jobs/component/WhiteBtn'
import { FormattedMessage } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getAddress } from 'sr/utils/api/individual/address/getAddress'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { updateAddress } from 'sr/utils/api/individual/address/updateAddress'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
export const LocationManage = () => {
const [showModel, setShowModel] = useState(false)
const [activeComponent, setActiveComponent] = useState<any>({});
const [id, setId] = useState<string>('')
const [userId, setUserId] = useState<string>()
const childRef = useRef(null)
const [loader, setLoader] = useState('fetchData')
const [data, setData] = useState([])
      const AllModel = [
        {name:LocationDetailsView, 
        label:"LocationDetailsView"},
       {name:LocationForm,
        label:"LocationForm"
       }
      ]
      const handleButtonClick = () => {
        if (childRef.current && childRef.current.submitForm) {
          console.log('wwww');
          
          childRef.current.submitForm()
          console.log('www2');
          
        }
      };
      const openPopup = (index: any) => {  
        const activeComp = AllModel.filter(function (e, i) {
          return i == index
        });      
        setActiveComponent(activeComp[0]);
        setShowModel(true)
      };
      const HandleModel = (data) => {
        setShowModel(data)
    }
    const fetchLocation = async (userId)=> {
      setLoader('fetchData')
    try {
      const params = new URLSearchParams()
     if (userId) params.append('individual_id', userId)
      params.append('status!', 'deleted')
    const res = await getAddress(params)
     if(res?.success===true) {
      setData(res?.data)
      setLoader('')
     }
     else {
    setLoader('')
     }
    }
    catch(e:any) {
      setLoader('')
    }
    }
    const dataAddedHandle = (data)=> {
        if(data===true) {
          setShowModel(false)
          fetchLocation(userId)
        }
    }
    useEffect(()=> {
      const {user_id} = getUserDetails()
      setUserId(user_id)
    },[])
    useEffect(()=> {

if(userId) {
  fetchLocation(userId)
}
    },[userId])
    const handleDelete = async (id)=> {
      try {
      setLoader('apiprocess')
          const payLoad = {id:id, status:'deleted'}
          const res = await updateAddress(payLoad)
          if(res?.success===true) {
            SuccessMessage('API.SUCCESS.INDIVIDUAL.LOCATION.DELETED')
            setData(data?.filter((item)=> item?.id!== id))
            setLoader('')
          }
          else {
            setLoader('')
          }

    }
    catch(e:any) {
      setLoader('')
    }
  }


  return (
    <MainWrapper>
      <ContentWithSpinner loader={loader==='fetchData'} height='h-screen'>
        {loader==='apiprocess' && <LoaderFullScreen />}
    <div className='py-3 px-10 mx-auto'>
        <div className='mb-2'>
          <div className='flex justify-between items-center'>
          <div>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TITLE" defaultMessage="Manage Addresses" /></h1>
    <p><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TEXT" defaultMessage="Use this page to manage your Addresses" /></p>
    </div>
   
 <WhiteBtn onClick={() => openPopup(1)} svg={ <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>} title={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.BUTTON.TITLE" defaultMessage="Add Address" />}></WhiteBtn>
    </div>
    
   </div>
    <Location handleDelete={handleDelete} data={data} Viewicon={()=> openPopup(0)} onClick = {(() => openPopup(1))} setId={setId}/>
     <ModelWrapperWithButton Save={handleButtonClick} nofooter = {activeComponent.name == LocationDetailsView ? true : false}  showModal={showModel} onCheckModel={HandleModel}>
             {<activeComponent.name ref={childRef} dataAddedHandle={dataAddedHandle} id={id}/>}
            </ModelWrapperWithButton>
        </div>
        </ContentWithSpinner>
        </MainWrapper>
  )
}
