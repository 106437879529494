import React from "react";
import { Spinner } from "sr/helpers";

export const LoaderFullScreen = () => {
  return (<div>
    <div role="status" className="bg-white opacity-25 fixed -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 min-h-screen w-full z-50 flex justify-center items-center">
<Spinner size={`w-10 h-10`}/>
    </div>

  </div>);
};
