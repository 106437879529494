import React, {useState, useEffect} from 'react'
import AuthNavComponent from '../module/auth/AuthNav'
import JobItemCard from './component/JobItemCard'
import { Filter } from './component/Sidebar/Filter';
import { Pageination } from 'sr/helpers/ui-components/Pageination';
import Footer from '../module/Footer'
import { FormattedMessage } from 'react-intl';
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs';
import jobList from 'sr/utils/model/jobList';
import ContentWithLoader from 'sr/layout/common/ContentLoader';
import { JobLoaderCard } from './component/JobLoaderCard';
import Job from '../component/types/Job';
export default function JobList() {
    const jobcount = 13
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [jobs, setJobs] = useState<Job[]>([]);
    const [totalPage, settotalPage] = useState<number>(0);
    const [loader, setLoader] = useState(false)
    const [filters, setFilters] = useState<any>({})
    const HandleFilterData = (data)=> {
            setFilters(data)
            console.log(data);
            
    }
    const fetchData = async ()=> {
        setLoader(true)
        const queryParams = new URLSearchParams();  
      if (page) queryParams.append('page', page.toString());
      if (limit) queryParams.append('limit', limit.toString());
      if (filters?.business_type) {
        let businessType = filters.business_type;
        if (businessType?.length ===1 ) {
          businessType += ',';
        }

        if (businessType?.length>0) queryParams.append('business_type', businessType);
      }
      let filter = [];
      const first = {"status": {"$in": ["open"]}}
      filter.push(first)
      Object.keys(filters)?.forEach(item => {
        if(item==='business_type') {
            delete filters[item]; 
        }
        if (item && filters[item] && (filters[item]?.length > 0 || Object.keys(filters[item]).length > 0)) {
        let condition1 = {};
        let condition2 = {};
        if(item==='createdAt') {
            Object.keys(filters[item]).forEach(key => {
                if (key && filters[item][key]) { 
                    if(key==='from')    { 
        condition1[item] = { "$gte": filters[item][key] };
                    }
              
                if(key==='to'){ 
        condition2[item] = { "$lte": filters[item][key] };
                
                }  }
            })
        }
        else {
            if(item !== 'payType') {
                condition1[item] = { "$in": filters[item] }; 
            }
        }

            filter.push(condition1)
            filter.push(condition2)
            
            
    }});  
        let newfilter;
    if(filter?.length>0) {
        if(filter?.length===1)  {
     newfilter = filter[0]
        }
        else {
            newfilter = {"$and":filter}
        }
    }
    if (newfilter) queryParams.append('filter', JSON.stringify(newfilter))
    if(Object.keys(filters).includes('payType')) {
        queryParams.append('rate', filters?.payType)
    }
      try {
            const result: any= await getJobs(queryParams);
            if(result?.success===true){
            setJobs(result?.data);
            settotalPage(Math.ceil(result?.pagination.total/limit))
            setLoader(false)
            
            }
        }
        catch(error) {
            console.log(error);
            setLoader(false)
        }
    }
    useEffect(() => {
        fetchData()
    }, [page, limit, filters])

    const handlePageChange = (value) => {
        if(value > totalPage || value < 1 ) {
            return 
        }
    setPage(value)
    }
    return (
        <div>
            <AuthNavComponent isLoggedin={true}></AuthNavComponent>
            <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                {/* <div className='font-bold font-satoshiBold text-xl mb-5 lg:text-left text-center'>
           14  <FormattedMessage
                id='CLEANER.JOBS.TITLE'
                defaultMessage='Jobs based on Your Desired Preferences'
             />
                </div> */}
                <div className='grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-0 pt-4'>
                    <div className='col-span-1'>
                    <Filter HandleFilterData={HandleFilterData}/>
                    </div>
                    <ContentWithLoader loading={loader} content={<JobLoaderCard></JobLoaderCard>}>
                <div className="col-span-3">
                {
                    jobs?.map((datI, index) => {
                        return <JobItemCard key={index} jobs={datI} link={`/job-detail/${datI.id}`}></JobItemCard>
                    })
                }
                <Pageination page = {page} totalpage= {totalPage} limit = {limit} onPageChange = {handlePageChange}/>
                </div>
                </ContentWithLoader>
                </div>
            </div>
           <Footer /> 
        </div>
    )
}
