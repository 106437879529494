import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomerHttpHandle from 'sr/redux/services/customer'
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import 'tailwindcss/tailwind.css'

export default function CreateCustomer() {

    const validationSchema = Yup.object().shape({
        customerName: Yup.string().max(70, 'Must be 70 characters or less').required('Required'),
        contactPerson: Yup.string().matches(/^[0-9]{10}$/, 'Must be a valid 10-digit phone number').required('Required'),
        addressLine1: Yup.string().max(300, 'Must be 300 characters or less').required('Required'),
        addressLine2: Yup.string().max(300, 'Must be 300 characters or less'),
        city: Yup.string().max(70, 'Must be 70 characters or less').required('Required'),
        province: Yup.string().max(70, 'Must be 70 characters or less').required('Required'),
    });

    const initialValues = {
        customerName: '',
        contactPerson: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        province: '',
    };
    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const res = await CustomerHttpHandle.customerRegistration(values);
            console.log("Customer Created Successfully", res.data);
            toast.success(`Customer ${res.data.name} is created sucessfully`);
            setSubmitting(false);
            resetForm();
        } catch (error) {
            toast.error('An error occurred while creating customer. Please try again later.');
            setSubmitting(false);
        }
    };

    return (
        <div className="flex justify-center items-center">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form className="w-full max-w-lg">
                        <div className='mt-10'>
                            <h1 className="text-base font-bold leading-7 text-gray-900">Customer Registration</h1>
                            <p className="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="customerName">
                                    Customer Name
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="customerName"
                                    type="text"
                                    name="customerName"
                                    placeholder="Enter customer name"
                                />
                                <ErrorMessage name="customerName" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
                                    htmlFor="contactPerson"
                                >
                                    Contact Person
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="contactPerson"
                                    type="tel"
                                    name="contactPerson"
                                    placeholder="Enter contact person phone number"
                                />
                                <ErrorMessage name="contactPerson" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="addressLine1">
                                    Address Line 1
                                </label>
                                <Field
                                    as="textarea"
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="addressLine1"
                                    name="addressLine1"
                                    placeholder="Enter address line 1"
                                />
                                <ErrorMessage name="addressLine1" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="addressLine2">
                                    Address Line 2
                                </label>
                                <Field
                                    as="textarea"
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="addressLine2"
                                    name="addressLine2"
                                    placeholder="Enter address line 2 (optional)"
                                />
                                <ErrorMessage name="addressLine2" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="city">
                                    City
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="city"
                                    type="text"
                                    name="city"
                                    placeholder="Enter city name"
                                />
                                <ErrorMessage name="city" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="province">
                                    Province
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="province"
                                    type="text"
                                    name="province"
                                    placeholder="Enter province name"
                                />
                                <ErrorMessage name="province" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Processing...' : 'Register'}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

