import React, { useEffect, useState, useRef } from 'react'
export const SliderCard = (props) => {
    return (
                <li
                    className={`my-5 order-1 px-4 lg:basis-w-mobile basis-w-call matches bg-white rounded-[20px] shadow-sm shadow-slate-300 ${props.slidercardstyle && props.slidercardstyle}`}
                >
                    <div className="flex  items-center flex-col py-4">
                        <span className={`${props.textstyle}`}>
                            {props.data && props.data.category}
                        </span>
                        <span className="w-full text-sm lg:text-base flex items-center text-[#275df5] font-bold">{props.data && props.data.companycount} <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                </svg></span>
                    </div>
                </li>
    )
}
