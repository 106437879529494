import React from "react";

export const InputNumberFormat = (input) => {
    // Remove all non-numeric characters
    const cleaned = ('' + input).replace(/\D/g, '');

    // Format the cleaned phone number
    let formattedPhoneNumber = '';
    if (cleaned.length > 0) {
      formattedPhoneNumber = '(' + cleaned.substring(0, 3);
      if (cleaned.length > 3) {
        formattedPhoneNumber += ') ' + cleaned.substring(3, 6);
      }
      if (cleaned.length > 6) {
        formattedPhoneNumber += '-' + cleaned.substring(6);
      }
    }

    return formattedPhoneNumber;

};
