import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const updateReference = async (putData:any) => {
    try {
        return await put<ApiResponse<[]>>('/cleaner/reference', putData ).then((res)=> {
            if(res.success=== true) {
                SuccessMessage("API.SUCCESS.REFERENCE.UPDATE")
                return res
            }
            else {
                toast.error(res.error.message)
                  return null
            }
        })
        }
        catch (e:any) {
            if (e.response) {
                HandleApiError(e);
            return null
                
              }}

};
