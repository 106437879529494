// export const LocalApi = 'http://localhost:2000/api'

// production env urls
export const httpAccess =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_ACCESS_LOCAL
    : process.env.REACT_APP_BASE_URL_ACCESS_PROD
    console.log(process.env.REACT_APP_BASE_URL_ACCESS_LOCAL);
export const httpServer =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_SERVER_LOCAL
    : process.env.REACT_APP_BASE_URL_SERVER_PROD
export const httpAnalyse =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_ANALYSE_LOCAL
    : process.env.REACT_APP_BASE_URL_ANALYSE_PROD

// export const jmmAccess = "http://localhost:4000/v1"
// export const jmmServer = "https://sev.jmm.ai/jmm/api/jmm-server/v1"
// export const jmmAnalyse = "https://any.jmm.ai/jmm/api/jmm-analyse/v1"

// AUDIT TOOL URL
// export const auditUrl = `https://localhost:44372/Home/Index`;

// local json server
// export const localJSON = `http://localhost:3001`;
