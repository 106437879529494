import React, {useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Sidebar } from './component/Sidebar'
import { Account } from './component/Account'
import { Notification } from './component/Notifications'
import { Balance } from './component/Balance'
import { Transaction } from './component/Transaction'
import { FormattedMessage } from 'react-intl'
export const AccountSetting = () => {
    const [Tab, setTab] = useState('Account Settings')
    const [title, setTitle] = useState(<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.ACCOUNT.TITLE" defaultMessage="Account Settings" />)
    const HandleTab = (data) => {
    setTab(data.props.defaultMessage)
    setTitle(data)
    }
  return (
    <MainWrapper>
       <div className='py-2 flex space-x-2'>
        <Sidebar activeTab={Tab} HandleTabs = {HandleTab}/>
        <div className='flex-1 bg-white p-10 rounded-lg'>
        <h1 className='font-satoshiBold text-xl mb-3 pb-3 border-b border-neutral-200'>{title}</h1>
        {Tab === 'Account Settings' &&
<Account />
}
{Tab === 'Notification' &&
<Notification />
}
{Tab === 'Account Balance' &&
<Balance />
}
{
  Tab === 'Transaction History' && 
  <Transaction />
}

</div>

        </div>
    </MainWrapper>
  )
}
