import { getStripCustomer } from "sr/utils/api/strip/getStripCustomer"
import getCardDetail from "./getCardDetails"
import { HandleApiError } from "sr/helpers/functions/HandleApiError"

interface ParamsType {
    customerId:string,
    setStripId:(params:any)=> void
    setIsProcessing:(params:any)=> void
    setCardDetails:(params:any) => void
  }
const getStripCustomerId = async (params:ParamsType)=> {
    params?.setIsProcessing(true)
    try {
    const queryParams = new URLSearchParams()
    queryParams?.append('cleanpig_id', params?.customerId)
    const response = await getStripCustomer(queryParams) 
    if(response?.data){
      params?.setStripId(response?.data?.stripe_customer_id)
      getCardDetail({setIsProcessing:params?.setIsProcessing, setCardDetails:params?.setCardDetails, customerId:response?.data?.stripe_customer_id})
    }
    else {
      params?.setIsProcessing(false)
    }
  }
  catch(e) {
    params?.setIsProcessing(false)
    HandleApiError(e)
  }
  }
export default getStripCustomerId;  