import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateTasklist = async (putData) => {
    try {
        return await put<ApiResponse<[]>>('/customer/tasklist', putData).then((res)=> {
          if(res.success===true) {
              SuccessMessage('API.SUCCESS.CUSTOMER.TASKLIST.UPDATE')
              return res
          }
          else {
              return null
          }
        });
      }
      catch(e:any) {
          HandleApiError(e)
          }
};
