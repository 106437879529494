import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { remove } from "sr/utils/axios";

export const deleteCard = async (queryParams) => {
    try {
  return await remove<ApiResponse<[]>>(`stripepayments/cards?${queryParams}`).then((res)=>{
    if(res?.success===true){
                return res
    }
    else {
                return null
    }
  })
}
catch(e){
HandleApiError(e)
}
};
