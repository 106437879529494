import React, {useState} from 'react'
import { ModelWrapper } from 'app/pages/company/component/ModelWrapper'
import { Link } from 'react-router-dom'
import { ActionBtn } from '../../component/ActionBtn'
export const MSTableRow = () => {
    const [editModel, setEditModel] = useState(false)  
    const [selectedCustomer, SetSelectedCustomer] = useState('')
    const [ScheduleType, setScheduleType] = useState('')
    
    const HandleModelByProps = (data) => {
        setEditModel(data)
       }
       const tabs = [
        {tabName:"General"},
        {tabName:"Advanced"}, 
        {tabName:"Area"}
       ]
  return (
    <>
    
        <tr className="bg-white border-b  hover:bg-gray-50">
            <td className="w-4 p-4">
                <div className="flex items-center">
                    <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
                    <label htmlFor="checkbox-table-1" className="sr-only">checkbox</label>
                </div>
            </td>
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
            
                <div className='text-base cursor-pointer'>West Pole</div>
            </th>
            <td className="px-6 py-4">
                Vishal Rathaur
            </td>
            <td className="px-6 py-4">
                WO-2023-245050
            </td>
            <td className="px-6 py-4">
            INV-005016
            
            </td>
            <td className="px-6 py-4">
            New
            
            </td>
            <td className="px-6 py-4">
            08/21/2023
            
            </td>
            <td className="px-6 py-4">
            $500
            
            </td>
            <td className="px-6 py-4">
           
            <ActionBtn/>
           </td>

        </tr>
  </>)
}
