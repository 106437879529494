import { useAuth } from "app/pages/module/auth";
import { useFormik } from "formik";
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input } from "sr/helpers";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { AppDispatch } from "sr/redux/store";
import { createContractor } from "sr/utils/api/company/workorder/createContractor";
import { createSupervisor } from "sr/utils/api/company/workorder/createSupervisor";
import { updateContractor } from "sr/utils/api/company/workorder/updateContractor";
import ContractorSchema from "sr/utils/schemas/company/contractor/contractor";
import supervisorSchema from "sr/utils/schemas/company/supervisor/supervisorSchema";

export const Form:ForwardRefRenderFunction<any, any> = ({type='', id, dataAddedHandle=null}, ref) => {
    const [email, setEmail] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phoneNo, setPhoneNo]= useState()
    const [userId, setUserId] = useState()
    const [loader, setLoader] = useState(false)
    const [status, setStatus] = useState('')
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
    useEffect(()=> {
      const userData = getUserDetails()
      setUserId(userData?.user_id)
    },[]) 
    const {userType} = useAuth()
    const dispatch = useDispatch<AppDispatch>()
    //If supervisordata is not available 
    useEffect(()=> {
      if(userId && userType==='supervisor') {
        if(!supervisorData){
          dispatch(fetchSupervisor({id:userId}))
        }
      }
},[userId, userType, dispatch])
    const intl = useIntl()
    const initialValues = {
      email:email, 
      mobile_number:phoneNo,
      first_name:firstName,
      last_name:lastName,
      company_id:userType==='supervisor' ? supervisorData?.company_id?._id : userId,
      status:status ? status :'pending'
  }

  const navigate = useNavigate()
  const {errors, submitForm} = useFormik({
    initialValues:initialValues,
    enableReinitialize:true,
    validationSchema:ContractorSchema,
    onSubmit:async (values)=> {
      setLoader(true)
      let res;
      if(type==='popup' && id){
        res = await updateContractor({id:id, ...values})
      }
      else {
         res = await createContractor(userType==='supervisor' ? {supervisor_id:supervisorData?.id, ...values}: values)
      }
        if(res?.success===true) {
          if(type==='popup' && id){
            toast.success('Contractor Details Updated Successfully')
          }
          else {
            toast.success('Contractor Created Successfully')
          }
            setLoader(false)
            if(type==='popup' && id){
              dataAddedHandle(true)
            }
            else {
            navigate('/contractor')
            }
        }
        else {

        }
    }
})
  useEffect(()=> {
const loadContractor = async ()=> {
const data = await fetchContractor({id:id, setLoader:setLoader})
if(data){
  console.log(data?.first_name);
  setPhoneNo(data?.mobile_number)
  setFirstName(data?.first_name)
  setLastName(data?.last_name)
  setEmail(data?.email)
  setStatus(data?.status)
}
}
if(type='popup' && id){
  loadContractor()
}
  },[id])
  React.useImperativeHandle(ref, () => ({
    submitForm,
  }));
  return (<>
    {loader && <LoaderFullScreen />}
    <form>
    <Input
  onChange={(e)=> setFirstName(e.target.value)}
  value={firstName}
    label={intl.formatMessage({
      id: "AUTH.INPUT.FIRSTNAME",
      defaultMessage: "First Name",
    })}
    placeholder={'Contractor first name'}
    type={"text"}
    name="first_name"
    error={errors.first_name}
  ></Input>
  <Input
  onChange={(e)=> setLastName(e.target.value)}
    label={intl.formatMessage({
      id: "AUTH.INPUT.LASTNAME",
      defaultMessage: "Last Name",
    })}
    placeholder={"Contractor last name"}
    type={"text"}
    error={errors.last_name}
    value={lastName}
  ></Input>
  <Input

  onChange={(e)=> setEmail(e.target.value)}
    label={intl.formatMessage({
      id: "AUTH.INPUT.EMAIL.ADDRESS",
      defaultMessage: "Email Address",
    })}
    placeholder={'Contractor email address'}
    type={"email"}
    error={errors.email}
    value={email}
  ></Input>
        <Input
  onChange={(e)=> setPhoneNo(e.target.value)}
    label={intl.formatMessage({
      id: "AUTH.INPUT.MOBILENO",
      defaultMessage: "Mobile Number",
    })}
    placeholder={'Contractor Mobile Number'}
    type={"tel"}
    error={errors.mobile_number}
    value={InputNumberFormat(phoneNo)}
  ></Input>

  </form>  
  </>
);
};
export default forwardRef(Form);