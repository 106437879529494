import { ModelWrapperWithButton } from "app/pages/company/component/ModelWrapperWithButton";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Input } from "sr/helpers";
import { createtaskManage } from "sr/utils/api/company/workorder/createtaskManage";
import { getFile } from "sr/utils/api/getFile";
import { uploadFile } from "sr/utils/api/uploadFile";
import { setPreview } from "../function/setPreview";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { toast } from "react-toastify";
export const TaskDetailsCard = ({item, managetasks, workorderId, contractor, fetchManageTask}) => {
    const [loader, setLoader] = useState(false)
    const [uploadedImgKey, setUploadImgKey] = useState<any>([])
    const [previewImages, setPreviewImages] = useState<any>([])
    const [uploadedVideoKey, setUploadVideoKey] = useState<any>([])
    const [previewVideo, setPreviewVideo] = useState<any>([])
    const [imagePopup, setImagePopup] = useState(false)
    const [videoPopup, setVideoPopup] = useState(false)
    
    const intl = useIntl()
    const [remarks, setRemarks] = useState<string>()
    const checkCompletedTask = (taskId)=> {
        return managetasks?.some((item) => item?.task_id?._id === taskId)
    }
    const getRemark = (taskId)=> {
          return managetasks?.find((item)=>item?.task_id?._id === taskId)?.contractor_remarks
    }

    const handleImage = async (e)=> {
        const files = Array.from(e.target.files  as FileList);
        const allowedExtensions = ["image/jpeg", "image/jpg", "image/png"];
        
        for (const item of files) {
          if(!allowedExtensions.includes(item?.type)) {
            toast.error('Please upload valid Image File')
            return 
          }
            const formData = new FormData()
            formData.append('task_img', item)
            setPreviewImages([{blob:URL.createObjectURL(item), key:item}, ...previewImages])
            const res = await uploadFile(formData);
            if(res?.success===true) {
                setUploadImgKey([res?.data?.files[0]?.key, ...uploadedImgKey])
            }
        }        
    }
    const handleVideo = async (e)=> {
      const allowedExtensions = ["video/mp4", "video/avi", "video/mov", "video/mpeg"];
        const files = Array.from(e.target.files  as FileList);
        for (const item of files) {
          if(!allowedExtensions.includes(item?.type)) {
            toast.error('Please upload valid Video File')
            return 
          }
            const formData = new FormData()
            formData.append('task_video', item)
            setPreviewVideo([{blob:URL.createObjectURL(item), key:item}, ...previewVideo])
            const res = await uploadFile(formData);
            if(res?.success===true) {
                setUploadVideoKey([res?.data?.files[0]?.key, ...uploadedVideoKey])
            }
        }   
}
const onCheckModel = ()=> {
    setImagePopup(false)
    setVideoPopup(false)
  }
const handleTaskManage = async (id)=> {
    setLoader(true)
    const payLoad =  {
      contractor_id:contractor[0]?.id,
      workorder_id:workorderId,
      task_id:id,
      images:uploadedImgKey,
      videos:uploadedVideoKey,
      contractor_status:'completed',
      supervisor_status: "pending",
      contractor_remarks:remarks,
      status:'in progress'

    }
        try {
             const res = await createtaskManage(payLoad)
             if(res?.success===true) {
await fetchManageTask()
setLoader(false)
             }
        }
        catch(e) {

        }
  }
  
useEffect(()=> {
    
    if(managetasks?.length>0 && item){
       const images = managetasks?.find((data)=> (data?.task_id?._id===item?.id))?.images
       const videos = managetasks?.find((data)=> (data?.task_id?._id===item?.id))?.videos
       if (images) {
    setPreview({type:'images', files:images, setPreviewImages:setPreviewImages, setLoader:setLoader})
       }
       if (videos) {
        setPreview({type:'videos', files:videos, setPreviewVideo:setPreviewVideo, setLoader:setLoader})
       }    
}
},[managetasks, item])

const removeImage = (key)=> {
const uploadres = uploadedImgKey?.filter((item)=> item != key)
setUploadImgKey(uploadres)
const previewfilter = previewImages?.filter((item)=> item?.key !=key)
setPreviewImages(previewfilter)
}

const removeVideo = (key) => {
  const uploadres = uploadedVideoKey?.filter((item)=> item?.key != key)
  setUploadVideoKey(uploadres)
  const previewfilter = previewVideo?.filter((item)=> item?.key !=key)
  setPreviewVideo(previewfilter)
}
  return (<>
     {loader && <LoaderFullScreen />}
    <div className="flex justify-between items-center py-3 border-b border-neutral-200 px-10">
    <div className="w-[30%]">
    <h2 className="">{item?.name}</h2>
    <div>{item?.description}</div>
    <div><span className="">Type:</span> {item?.type}</div>
    </div>
 <div className="w-[25%] ">
     <p className=""><FormattedMessage
     id="MYJOB.INPUT.REMARKS.TITLE"
     defaultMessage= "Remarks"
     /></p>
      {checkCompletedTask(item?.id) ?
      <div className="break-words"> {getRemark(item?.id)} </div> :
     <textarea onChange={(e)=> setRemarks(e.target.value )}  id="Description" rows={1} className="block p-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#0078ae] focus:shadow-inputshadow outline-0" placeholder={intl.formatMessage({id:'MYJOB.INPUT.REMARKS.PLACEHOLDER', defaultMessage:'Remarks'})}></textarea>
 
      }
 </div>
 <div className="w-[12%]">
    {!checkCompletedTask(item?.id) &&
 <label htmlFor={item?.id} className="cursor-pointer">
 <div className="flex flex-col items-center justify-center pt-5">
             <svg className="w-4 h-4 mb-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
             </svg>
             <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Upload Image</p>
 
         </div>
         
   <input multiple 
        accept="image/*"   onChange={(e)=> handleImage(e)}   id={item?.id} type="file" className="hidden"></input>
 </label>
}
 {previewImages?.length>0 &&
 <div className="flex justify-center">
 <Button onClick={()=> setImagePopup(true)} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}>View Image</Button>
 </div>
 }
 </div>
 <div className="w-[12%]">
 {!checkCompletedTask(item?.id) &&
 <label htmlFor={`${item?.id}video`} className="cursor-pointer">
 <div className="flex flex-col items-center justify-center pt-5">
             <svg className="w-4 h-4 mb-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
             </svg>
             <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Upload Video</p>
 
         </div>
   <input className="hidden" onChange={(e)=> handleVideo(e)} id={`${item?.id}video`} type="file"></input>
 </label>
}
 {previewVideo?.length>0 &&
 <div className="flex justify-center">
 <Button onClick={()=> setVideoPopup(true)} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}>View Video</Button>
 </div>
 }
 </div>
 <div className="w-[20%] flex justify-end">
   {checkCompletedTask(item?.id) ?
   <Button text={<FormattedMessage id="MYJOB.COMPLETED.BUTTON" defaultMessage="Completed" />} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
 :
 <Button loader={loader} onClick={()=> handleTaskManage(item?.id)} text={<FormattedMessage id="MYJOB.MARK_AS_COMPLETED.BUTTON" defaultMessage="Mark As Completed" />} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
 }
 </div>
    </div>
 <ModelWrapperWithButton showModal={imagePopup} onCheckModel={onCheckModel} nofooter={true}>
 <div className="flex justify-center flex-wrap">
 {previewImages?.map((preview, index) => (
  <div key={index} className="relative">
    <img
      className="w-48 mr-2 mb-2 cursor-pointer"
      src={preview?.blob}
      alt={`Preview ${index}`}
    />
    {!checkCompletedTask(item?.id) &&
    <svg
      onClick={() => removeImage(preview?.key)}  // Move the onClick to the svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5 absolute right-0 top-0 cursor-pointer"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
}
  </div>
))}

 </div>
 </ModelWrapperWithButton>
 <ModelWrapperWithButton showModal={videoPopup} onCheckModel={onCheckModel} nofooter={true}>
 <div className="flex justify-center flex-wrap">
   {previewVideo?.map((preview, index)=> ( 
    <div className="relative">
         <video className="h-96 mr-2 mb-2" controls>
         <source src={preview?.blob} type="video/mp4" />
       </video>
       {!checkCompletedTask(item?.id) &&
       <svg onClick={()=>removeVideo(preview?.key)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 absolute right-0 top-0 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>
}
       </div>
 
))}
 </div>
 </ModelWrapperWithButton>   
 </>);};
