import { toast } from "react-toastify"
import { post } from "sr/utils/axios"
import ApiResponse from "sr/models/ApiResponse"
import { HandleApiError } from "sr/helpers/functions/HandleApiError"
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage"
export const cleanerRegister = async (postData:any) => {
    try {
        return await post<ApiResponse<[]>>(`/cleaner/`, postData).then((res)=> {
            if(res.success === true) {
                SuccessMessage('API.SUCCESS.CLEANER.REGISTER')
                return res.data
            }
            else {
                console.log(res.error);
                toast.error(res.error) 
                return null
            }
        })
    }
 catch (e:any) {
    HandleApiError(e);
    return null
        
      }
}
