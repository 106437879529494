import React, {useEffect, useState} from 'react'
import AutoLocation from 'sr/helpers/ui-components/AutoLocation'
export const ReportWorkLocation = (props) => {
  const [address, setAddress] = useState({});
  const [currentaddress, setCurrentAddress] = useState<any>();
  
  useEffect(()=> {
    const updatedAddress = props?.data?.job_advanced_id?.reporting_address
    setCurrentAddress(updatedAddress)
  },[props?.data])

  const HandleAutoLocation = (data)=> {
       setAddress(data)
  }
  useEffect(()=> {
    const data = {
      reporting_address: address,
    }
   props?.HandleDataProps(data)
    
  },[address])


  return ( 
    <div className="pt-10">
          
             <AutoLocation HandleAutoLocation = {HandleAutoLocation} address={currentaddress}/>
 </div>
  )
}
