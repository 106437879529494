import React, { useEffect, useState } from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input } from 'sr/helpers'
export const JobTitleChange = (props) => {
  const [jobTitle, setJobTitle] = useState()
  const data = {
    job_title:jobTitle
  }
  useEffect(()=> {
setJobTitle(props?.data?.job_title)
  },[props?.data])
  useEffect(()=> {
    props.HandleDataProps(data)
  },[jobTitle])
  return (
    <div className='py-4'>
    <Input onChange={(e:any)=> setJobTitle(e.target.value) } value={jobTitle} InputDivClass="mt-0" inputClass = "border border-zinc-800 rounded-lg h-11" label="Job Title"></Input>
</div>
  )
}
