// validationErrorHandle.js
import { useIntl } from "react-intl";

export const useValidationErrorHandle = () => {
    const intl = useIntl();
    
    const validationErrorHandle = (errorId) => {
        return intl.formatMessage({
            id: errorId,
            defaultMessage: " "
        });
    };

    return validationErrorHandle;
};
