import { useFormik } from 'formik';
import React, {useState, useEffect, ForwardRefRenderFunction, forwardRef} from 'react'
import { useIntl } from 'react-intl'
import { Input } from 'sr/helpers'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails';
import { changePassword } from 'sr/utils/api/changePassword';
import { getCompany } from 'sr/utils/api/company/getCompany';
import * as Yup from 'yup'
export interface CleanerComponentProps {
HandleData:(arg: any) => void | null;
HandleModel:(arg: any) => void | null;
}
const PasswordChange: ForwardRefRenderFunction<any, CleanerComponentProps> = ({HandleData, HandleModel}, ref) => {
  const intl = useIntl()
  const [password, setPassword] = useState()
  const [currentPassword, setCurrentPassword] = useState()
  const [userId, setUserId] = useState()
  const [companyId, setCompanyId] = useState()
  useEffect(()=> {
const getdetails = getUserDetails()
setCompanyId(getdetails.user_id)
},[])
  useEffect(()=> {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(); 
        if (companyId) queryParams.append('id', companyId); 
      const result = await getCompany(queryParams)
      if(result?.success === true) {
          setUserId(result?.data?.user_id)
      }
  }
  catch(error) {
  }
  }
  if(companyId) {
    fetchData()
  }
  },[companyId])
  const PasswordChangeSchema = Yup.object({
    password:Yup.string().required('INPUT.VALIDATION.PASSWORD').min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER'),
    old_password:Yup.string().required('INPUT.VALIDATION.PASSWORD').min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER')
  })
  const initialValue = {
    user_id:userId,
    password:password,
    old_password:currentPassword
  }
  const {errors, submitForm} = useFormik({
    initialValues:initialValue,
    enableReinitialize:true,
    validationSchema:PasswordChangeSchema,
    onSubmit: async (value)=> {
          const res = await changePassword(value)
          if(res?.success===true) {
            HandleModel(false)
          }
    }
  })
  
  React.useImperativeHandle(ref, () => ({
    submitForm
  }));


  return (
    <>
<Input
error={errors?.password}
onChange={(e)=> {setPassword(e.target.value)}}
  type="password"
  InputDivClass="mt-0"
  inputClass="border border-zinc-800 rounded-lg h-11"
  label={intl.formatMessage({
    id: 'RECRUITER.ACCOUNT.SETTINGS.INPUT.NEWPASS',
    defaultMessage: 'New password',
  })}
  
/>

<Input
error={errors?.old_password}
onChange={(e)=> {setCurrentPassword(e.target.value)}}
  type="password"
  InputDivClass="mt-5"
  inputClass="border border-zinc-800 rounded-lg h-11"
  label={intl.formatMessage({
    id: 'RECRUITER.ACCOUNT.SETTINGS.INPUT.CURRENTPASS',
    defaultMessage: 'Current password',
  })}
  
/>

    </>
  )
}

export default forwardRef(PasswordChange)