import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const assignContractorLocation = async (postData) => {
    try {
  return await put<ApiResponse<[]>>('/contractor/addlocations', postData).then((res)=> {
    if(res?.success===true) {
      SuccessMessage('API.SUCCESS.CONTRACTOR.LOCATION.ASSIGNED')
        return res
    }
    else {
        return null
    }
  })
}
catch(e:any) {
    HandleApiError(e)
}
};
