import React, { ForwardRefRenderFunction, forwardRef, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Input } from 'sr/helpers'
import PhoneNumberFormat from 'sr/helpers/functions/PhoneNumberFormat';
import * as Yup from 'yup'
export interface CleanerComponentProps {
  HandleData:(arg: any) => void | null;
  }
  const ChangePhone: ForwardRefRenderFunction<any, CleanerComponentProps> = ({HandleData}, ref) => {
  const [phoneNo, setPhoneNo] = useState()
  const [errors, setErrors] = useState<any>({});
  const intl = useIntl()
  useEffect(()=> {
HandleData({
  mobile_number:phoneNo
})
  },[phoneNo])

  interface data{
    mobile_number:string
  }
  const MobileNo = Yup.object({
    mobile_number:Yup.string().required("INPUT.VALIDATION.MOBILENUMBER")
    })


  const validateData = async () =>  {
    const data:data = {
      mobile_number:phoneNo
    }
    let isValid: boolean;
    try {
      await MobileNo.validate(data, { abortEarly: false });
      setErrors({}); // Reset errors if validation is successful
      HandleData(data);
      isValid = true;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      isValid = false;
    }
    return isValid;
  };

  React.useImperativeHandle(ref, () => ({
    validateData
  }));
  return (
    <>
<Input
onChange={(e:any)=> setPhoneNo(e.target.value)}
value={PhoneNumberFormat(phoneNo)}
error={errors?.mobile_number}
  type="text"
  InputDivClass="mt-0"
  inputClass="border border-zinc-800 rounded-lg h-11"
  label={intl.formatMessage({
    id: 'RECRUITER.ACCOUNT.SETTINGS.NEW_PHONENO.TITLE',
    defaultMessage: 'New Phone Number',
  })}
/>
    </>
  )
}

export default forwardRef(ChangePhone)
