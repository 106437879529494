import { useFormik } from "formik";
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input } from "sr/helpers";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { createSupervisor } from "sr/utils/api/company/workorder/createSupervisor";
import { updateSupervisor } from "sr/utils/api/company/workorder/updateSupervisor";
import supervisorSchema from "sr/utils/schemas/company/supervisor/supervisorSchema";

export const Form:ForwardRefRenderFunction<any, any> = ({type='', id, dataAddedHandle=null}, ref) => {
    const [loader, setLoader] = useState(false)
    const [companyId, setCompanyId] = useState()
    const [email, setEmail] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phoneNo, setPhoneNo]= useState()
    const initialValues = {
        email:email, 
        mobile_number:phoneNo,
        first_name:firstName,
        last_name:lastName,
        company_id:companyId,
        status:'pending'
    }
    const navigate = useNavigate()
  const intl = useIntl()
  useEffect(()=> {
    const userData = getUserDetails()
    setCompanyId(userData?.user_id)
  },[]) 

  useEffect(()=> {
    const fetchSupervisor = async (id)=>{
        try {
        setLoader(true)
      const queryParams = new URLSearchParams()
      if (id) queryParams.append('id', id)
       const res = await updateSupervisor(queryParams)
       if(res?.success===true) {
        setFirstName(res?.data?.first_name)
        setLastName(res?.data?.last_name)
        setEmail(res?.data?.email)
        setPhoneNo(res?.data?.mobile_number)
        setLoader(false)
       }
      }catch(e) {
        setLoader(false)
      }
      
      }
      if(type==='popup' && id){
        fetchSupervisor(id)
      }
  },[type, id])
    const {errors, submitForm} = useFormik({
        initialValues:initialValues,
        enableReinitialize:true,
        validationSchema:supervisorSchema,
        onSubmit:async (values)=> {
          setLoader(true)
          let res;
                if(type==='popup' && id){
            res = await updateSupervisor({id:id, ...values})
            }
            else {
                res = await createSupervisor(values)
            }
            if(res?.success===true) {
                toast.success('Supervisor Created')
                setLoader(false)
                if(type==='popup' && id){
                            dataAddedHandle(true)
                }
                else {
                navigate('/supervisor')
                }
              
            }
            else {
      setLoader(false)
            }
        }
      })
      React.useImperativeHandle(ref, () => ({
        submitForm,
      }));
  return (<>
        {loader && <LoaderFullScreen />}
        <form>
        <Input
      onChange={(e)=> setFirstName(e.target.value)}
      value={firstName}
        label={intl.formatMessage({
          id: "AUTH.INPUT.FIRSTNAME",
          defaultMessage: "First Name",
        })}
        placeholder={intl.formatMessage({
          id: "AUTH.INPUT.FIRSTNAME.PLACEHOLDER",
          defaultMessage: "Enter your first name",
        })}
        type={"text"}
        name="first_name"
        error={errors.first_name}
      ></Input>
      <Input
      onChange={(e)=> setLastName(e.target.value)}
        label={intl.formatMessage({
          id: "AUTH.INPUT.LASTNAME",
          defaultMessage: "Last Name",
        })}
        placeholder={"Enter your last name"}
        type={"text"}
        error={errors.last_name}
        value={lastName}
      ></Input>
      <Input
    
      onChange={(e)=> setEmail(e.target.value)}
        label={intl.formatMessage({
          id: "AUTH.INPUT.EMAIL.ADDRESS",
          defaultMessage: "Email Address",
        })}
        placeholder={intl.formatMessage({
          id: "AUTH.INPUT.EMAIL.PLACEHOLDER",
          defaultMessage: "Enter your email id",
        })}
        type={"email"}
        error={errors.email}
        value={email}
      ></Input>
            <Input
      onChange={(e)=> setPhoneNo(e.target.value)}
        label={intl.formatMessage({
          id: "AUTH.INPUT.MOBILENO",
          defaultMessage: "Mobile Number",
        })}
        placeholder={intl.formatMessage({
          id: "AUTH.INPUT.LASTMOBILENONAME.PLACEHOLDER",
          defaultMessage: "Enter your Mobile Number",
        })}
        type={"tel"}
        error={errors.mobile_number}
        value={InputNumberFormat(phoneNo)}
      ></Input>
    
      </form>  
      
      </> )
};
export default forwardRef(Form);