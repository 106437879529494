import React, { useEffect, useState } from "react";
import { Input } from "sr/helpers";
import { FormattedMessage } from "react-intl";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import getStripCustomerId from "sr/helpers/apiFunctions/strip/getStripCustomerId";
import { getCardIcon } from "sr/helpers/functions/getCardIcon";
import { RadioInput } from "sr/helpers/ui-components/RadioInput";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { fetchBalance } from "sr/helpers/apiFunctions/fetchBalance";
import { createStripPayment } from "sr/utils/api/strip/createStripPayment";
import { toast } from "react-toastify";
import { updateWalletBalance } from "sr/utils/api/wallet/updateWalletBalance";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
export const Balance = (props) => {
  const [userId, setUserId] = useState()
  const [cardDetails, setCardDetails] = useState<any>([])
  const [isProcessing, setIsProcessing] = useState(false);
  const [stripId, setStripId] = useState()
  const [paymentDetails, setPaymentDetails] = useState(null)
const [balance, setBalance] = useState(null)
const [loader, setLoader] = useState(true)
const [amount, setAmount] = useState(null)
const [error, setError] = useState('')
  useEffect(()=> {
      const {user_id} = getUserDetails()
      setUserId(user_id)
  },[])
  useEffect(()=> {
    if(userId) {
    getStripCustomerId({setCardDetails:setCardDetails, setIsProcessing:setIsProcessing, setStripId:setStripId, customerId:userId})
    }
  },[userId])
  useEffect(()=>{
  
        if(userId){
          fetchBalance({userId:userId, setData:setBalance, setLoader:setLoader})
        }
    },[userId])
    const handlePayment = async ()=>{
      if(amount<1){
        setError('INPUT.VALIDATION.AMOUNT.ADD.ERROR')
        return
      }
      try {
      setLoader(true)
        const payload = {
          customer:paymentDetails?.customer,
          payment_method:paymentDetails?.id,
          currency:'USD',
          amount:Number(amount)*100
        }
        const res = await createStripPayment(payload)
        if(res?.success===true){
          const payloadwallet = {
            to:userId,
            tokenId:'1271231',
            amount:Number(amount),
            last4:paymentDetails?.card?.last4,
            brand:paymentDetails?.card?.brand,
            data:'initial top up',
            async:true
          }
          const walletres = await updateWalletBalance(payloadwallet)
          if(walletres?.success===true){
            setLoader(false)
            setAmount(null)
            if (props?.type==='popup'){
               props?.setShowAddModal(false)
              props?.loadBalance()
            }
            fetchBalance({userId:userId, setData:setBalance, setLoader:setIsProcessing})
          }
          toast.success('Amount Added Successfully')
        }
    }
    catch(e){

    }
  }
    useEffect(()=>{
        setPaymentDetails(cardDetails && cardDetails[0])
    },[cardDetails])
    useEffect(()=>{
        if(amount>1){
          setError('')
        }
    },[amount])
  return (
    <ContentWithSpinner loader={isProcessing} height="h-screen">
      {loader && <LoaderFullScreen></LoaderFullScreen>}
  <div className="py-3">
<div className="border border-gray-border rounded-xl flex w-full bg-white text-black pt-10 pb-10">
  <div className="mx-auto">
  <div className="mb-5">
    <div className="text-2xl  mb-3"><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TOTAL.BALANCE.TITLE" defaultMessage="Total Balance" /></div>
    <div className="text-2xl text-center ">${Number(balance?.balance)?.toFixed(2)}</div>
    </div>
    <div>
    <div className="text-2xl  mb-3"><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TITLE" defaultMessage="Available Account Balance" /></div>
    <div className="text-2xl text-center ">${Number(balance?.available_balance)?.toFixed(2)}</div>
    </div>
  <div className='my-5 justify-center '>
    {cardDetails?.map((item)=> (
      <RadioInput icon={getCardIcon(item?.card?.brand)} name={''} onChange={()=> setPaymentDetails(item)} checked={item?.id===paymentDetails?.id} label={`**** **** **** ${item?.card?.last4}`} value={item?.id}></RadioInput>
           ))
         }
   </div>
   {paymentDetails &&<>
   <Input 
   prefix="$" 
   error={error}
   label={'Enter Amount'}
   InputDivClass="mt-0 w-26 pr-2 pb-2"  
  inputClass="border border-zinc-800 rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
   value={amount} onChange={(e)=>setAmount(e.target.value)} type="number"></Input>
   <div onClick={handlePayment} className="text-center rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy" >Add Money</div>
  </> }
   </div>

    </div>
</div>
</ContentWithSpinner>
  );
};
