import * as Yup from 'yup'


export const AdvanceDetailsSchema = Yup.object({
      reporting_address:  Yup.object().shape({
        address_line_1:Yup.string().required('INPUT.VALIDATION.AUTOLOCATION.ADDRESSLINE1'),
        country:Yup.string().required('INPUT.VALIDATION.AUTOLOCATION.COUNTRY'),
      }),
    hire_count: Yup.number(),
    recruitment_timeline:Yup.date(),   
    supplimental_pay: Yup.array().min(1, 'INPUT.VALIDATION.JOBPOST.SUPPLIMENTAL.PAY'),
    application_deadline: Yup.date()
})