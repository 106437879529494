import React, {useState} from 'react'
import { ModelWrapper } from '../ModelWrapper'
import { ChatBottom } from './ChatBottom'
import { FormattedMessage } from 'react-intl'
export const ChatBox = () => {
    const [modelShow, setModelshow] = useState(false)
    const HandleModelByProps = (data) => {
        setModelshow(data)
       }
  return (
    <div>
    <div className='flex justify-between items-center py-3 px-3'>
        <div>
        <h1 className='text-2xl font-satoshiBold text-[#595959]'>Deepak Kumar</h1>
        <p className='font-satoshi'>Applied to Web Developer in Lucknow, Uttar Pradesh</p>
        </div>
        <div className='flex items-center justify-center'>
        <div className='mr-8 flex space-x-4 items-center justify-center rounded-lg font-satoshiBold px-5 py-2  text-[#2557A7] cursor-pointer bg-white border border-neutral-200'>
        <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="#2557A7" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.364 9.89A18.007 18.007 0 013 3.5a.49.49 0 01.493-.5h4.502c.276 0 .5.224.51.5.048 1.204.27 2.394.659 3.534a.513.513 0 01-.12.528l-2.438 2.439a16.007 16.007 0 007.391 7.392l2.438-2.437a.513.513 0 01.528-.12c1.14.39 2.332.612 3.537.66.276.011.5.234.5.51v4.5a.49.49 0 01-.5.494A18.01 18.01 0 014.364 9.89z"></path></svg>
 <label>         
 <FormattedMessage id="RECRUITER.MESSAGES.VIEWPHONE.DETAILS" defaultMessage="View phone details" />
</label>  
</div>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-[#2557A7] mr-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
<div onClick={()=> setModelshow(!modelShow)} className='mr-8 p-2 hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] hover:rounded-r-lg rounded-lg cursor-pointer relative'>
                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-label="More actions" className="w-6 h-6 text-[#2557A7]"><path d="M12 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
                {modelShow &&
              <ModelWrapper rightPosition = 'right-0' width = 'w-52' border = 'border border-black'  HandleModelByProps = {HandleModelByProps}>
              <ul className='px-3 pb-2 font-satoshiBold text-base font-bold text-[#2557A7]'>
              <li className='py-3'><FormattedMessage id="RECRUITER.MESSAGES.VIEWCANDIDATEDETAILS.DETAILS" defaultMessage="View candidate details" /></li>
              <li className='py-3'><FormattedMessage id="RECRUITER.MESSAGES.MESSAGING.POLICY.TITLE" defaultMessage="Messaging policy and terms" /></li>
              </ul>
              </ModelWrapper>
                  }
               </div>
        </div>
    </div>
<hr className="border-2 border-neutral-200 my-4" />
<div className="flex flex-col h-[60vh] justify-between">
<div className='flex-1 h-[60vh] overflow-auto mb-3'>
<div className='text-center mb-10'>
Applied 25 July
</div>
<div className="flex items-center justify-center mb-4">
  <div className="border-t flex-1 mr-4"></div>
  <span className="text-black font-satoshiBold">Today</span>
  <div className="border-t flex-1 ml-4"></div>
</div>
<div>
 {
  [{}, {}, {}, {}].map(()=> (
<div className='flex justify-start items-center space-x-2 px-2 pb-6'>
    <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
        D
    </div>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='font-satoshiBold text-base'> Deepak Kumar </div>
    <div className='text-sm'>
    3:19 AM
    </div>
    </div>
    <div className='text-[#595959]'>
    Hi, I recently submitted my application. Please let me know if you need anything else from me at this point. Thank you!
    </div></div>
</div>
))}
<div className='flex justify-start items-center space-x-2 px-2'>
<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='font-satoshiBold text-base'> You </div>
    <div className='text-sm'>
    3:19 AM
    </div>
    </div>
    <div className='text-[#595959]'>
    Hi, I recently submitted my application. Please let me know if you need anything else from me at this point. Thank you!
    </div></div>
</div>

</div>
</div>
<ChatBottom />

</div></div>
  )
}
