import React, {useState, useEffect} from 'react'
import AuthNavComponent from '../module/auth/AuthNav'
import JobItemCard from './component/JobItemCard'
import { Filter } from './component/Sidebar/Filter';
import { Pageination } from 'sr/helpers/ui-components/Pageination';
import Footer from '../module/Footer'
import { FormattedMessage } from 'react-intl';
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs';
import jobList from 'sr/utils/model/jobList';
import ContentWithLoader from 'sr/layout/common/ContentLoader';
import { JobLoaderCard } from './component/JobLoaderCard';
import Job from '../component/types/Job';
import { useParams } from 'react-router-dom';

export const SearchJob = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [jobs, setJobs] = useState<Job[]>([]);
    const [totalPage, settotalPage] = useState<number>(0);
    const [loader, setLoader] = useState(false)
    const [search, setSearch] = useState<string>('')

    const params = useParams()
    useEffect(()=> {
                const {query} = params
                setSearch(query)
    },[params])

    const fetchData = async ()=> {
        setLoader(true)
        const queryParams = new URLSearchParams();  
    //   if (page) queryParams.append('page', page.toString());
    //   if (limit) queryParams.append('limit', limit.toString());
      queryParams.append('sort', 'job_title')
      queryParams.append('status', 'open')
      if (search) queryParams.append('job_title', `/.*${search}./i`.toString())
      try {
            const result: any= await getJobs(queryParams);
            if(result?.success===true){
            setJobs(result?.data);
            settotalPage(Math.ceil(result?.pagination.total/limit))
            setLoader(false)
            
            }
        }
        catch(error) {
            console.log(error);
            setLoader(false)
        }
    }
    useEffect(() => {
        if(search) {
        fetchData()
        }
    }, [page, limit, search])

    const handlePageChange = (value) => {
        if(value > totalPage || value < 1 ) {
            return 
        }
    setPage(value)
    }
  return ( <div>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
        {/* <div className='font-bold  text-xl mb-5 lg:text-left text-center'>
   14  <FormattedMessage
        id='CLEANER.JOBS.TITLE'
        defaultMessage='Jobs based on Your Desired Preferences'
     />
        </div> */}
        <div className='grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-0 pt-4'>
            {/* <div className='col-span-1'>
            <Filter HandleFilterData={HandleFilterData}/>
            </div> */}
            <ContentWithLoader loading={loader} content={<JobLoaderCard></JobLoaderCard>}>
        <div className="col-span-3">
        {
            jobs?.map((datI, index) => {
                return <JobItemCard key={index} jobs={datI} link={`/job-detail/${datI.id}`}></JobItemCard>
            })
        }
        <Pageination page = {page} totalpage= {totalPage} limit = {limit} onPageChange = {handlePageChange}/>
        </div>
        </ContentWithLoader>
        </div>
    </div>
   <Footer /> 
</div>);
};
