import React, { useState, useRef, useEffect } from "react";
import { Checkbox } from "sr/helpers";
import { Button } from "sr/helpers/ui-components/company/Button";
import { ModelWrapperWithButton } from "app/pages/company/component/ModelWrapperWithButton";
import TaskForm from "../forms/TaskForm";
import { AllChecklist } from "../../ChecklistTemplate/AllChecklist";
import ChecklistForm from "../forms/ChecklistForm";
import { Checklist } from "../Tables/Checklist";
import { Task } from "../Tables/Task";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCustomer } from "sr/utils/api/company/customer/getCustomer";
import { getChecklist } from "sr/utils/api/company/customer/getChecklist";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { AddChecklist } from "./component/AddChecklist";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { updateChecklist } from "sr/utils/api/company/customer/updateChecklist";
import { createTasklists } from "sr/utils/api/company/customer/createTasklists";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { Warning } from "sr/helpers/ui-components/company/Warning";
export const ChecklistTab = () => {
  const [companyId, setCompanyId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [isEditCheckList, setIsEditCheckList] = useState(false);
  const [checklistdetails, setchecklistDetails] = useState([]);
  const [addChecklistPopup, setAddChecklistPopup] = useState(false);
  const [TaskPopup, setTaskPopup] = useState(false);
  const [checklistId, setchecklistId] = useState("");
  const [taskadded, setTaskAdded] = useState(0);
  const [editChecklistPopup, setEditChecklistPopup] = useState(false);
  const [loader, setLoader] = useState('');
  const [copyChecklistid, setCopyChecklistId] = useState(-1);
  const [addMore, setAddMore] = useState(false);
  const [GlobalTemplate, setGlobalTemplate] = useState(false);
  const [copyTasks, setCopyTasks] = useState([]);
  const [userTasks, setUserTasks] = useState<any>()
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const userDetails = getUserDetails();
    setCompanyId(userDetails?.user_id);
  }, []);
  useEffect(() => {
    const { id, checklistId } = params;
    setCustomerId(id);
    setchecklistId(checklistId);
  }, [params]);

  const fetchChecklist = async () => {
    try {
      setLoader('fetch');
      const queryParams = new URLSearchParams();
      if (customerId) queryParams.append("customer_id", customerId);
    
      const result = await getChecklist(queryParams);
      if (result.success === true) {
        setLoader('');
        setchecklistDetails(result?.data);
      } else {
        setLoader('');
      }
    } catch (e) {
      setLoader('');
    }
  };
  useEffect(() => {
    if (customerId) {
      fetchChecklist();
    }
  }, [customerId]);
  const onCheckModel = (data) => {
    setTaskPopup(false);
    setAddChecklistPopup(false);
    setTaskPopup(false);
    setEditChecklistPopup(false);
    setIsEditCheckList(false);
    navigate(`${location.pathname.match(/^\/[^\/]*/)[0]}/${customerId}`);
  };
  interface TabRef {
    submitForm: () => void;
  }

  const childRef = useRef<TabRef>(null);
  const HandleFormSubmit = () => {
    if (childRef.current && childRef.current.submitForm) {
      childRef.current.submitForm();
    }
  };
  const dataAddedHandle = (data) => {
    if (data === "Checklist") {
      console.log(data);

      fetchChecklist();
    }
    if (data === "Task") {
      setTaskAdded(taskadded + 1);
      setAddMore(false);
    }
  };
  const HandleAddChecklist = () => {
    setTaskPopup(false)
    setAddChecklistPopup(false);
    navigate(`${location.pathname.match(/^\/[^\/]*/)[0]}/${customerId}`);
    fetchChecklist();
  };
  const HandleChecklistTask = (data) => {
    navigate(`${location.pathname}/${data}`);
    setTaskPopup(true);
  };
  const HandleEditChecklist = (data) => {
    navigate(`${location.pathname}/${data}`);
    setAddChecklistPopup(true);
    setIsEditCheckList(true);
    // setEditChecklistPopup(true)
  };
  const HandleEditChecklistSave = () => {
    if (childRef.current && childRef.current.submitForm) {
      childRef.current.submitForm();
    }
    navigate(`${location.pathname.match(/^\/[^\/]*/)[0]}/${customerId}`);
    setEditChecklistPopup(false);
  };
  const saveTaskHandle = () => {
    setAddMore(false);
  };

  const HandleDeleteChecklist = async (data) => {
    const formData = {
      id: data,
      status: "draft",
    };
    try {
      const res = await updateChecklist(
        formData,
        "API.SUCCESS.CUSTOMER.CHECKLIST.DELETE"
      );
      if (res?.success === true) {
        const filteredchecklist = checklistdetails?.filter(
          (item) => item.id !== data
        );
        setchecklistDetails(filteredchecklist);
      }
    } catch (e) {}
  };
  const ChecklistHandle = async (tasks) => {
    setLoader('submit');
    let formData = [];
    for (let i = 0; i < tasks?.length; i++) {
      const data = {
        company_id: companyId,
        customer_id: customerId,
        checklist_id: checklistId,
        name: tasks[i]?.name,
        type: tasks[i]?.type,
        description: tasks[i]?.description,
        status: "active",
      };
      formData.push(data);
    }
    console.log(formData);

    setCopyTasks(tasks);
    try {
      const res = await createTasklists(formData);
      if (res.success === true) {
        const cPayload = {
          id:checklistId,
          status:'active'
        }
        const cres = await updateChecklist(cPayload)
        setTaskAdded(taskadded + 1);
        
        setLoader('');
      } else {
        setLoader('');
      }
    } catch (e) {
      setLoader('');
    }
  };
  const getTasks = (data)=> {
    setUserTasks(data)
  }
  return (
    <>
    {loader==='submit' && <LoaderFullScreen />}
      <div className="py-4">
        <ModelWrapperWithButton
          showModal={addChecklistPopup}
          Save={HandleAddChecklist}
          onCheckModel={onCheckModel}
        >
          <AddChecklist isEditCheckList={isEditCheckList}></AddChecklist>
        </ModelWrapperWithButton>
        {/* View Task, Update Task, Add Task */}
        <ModelWrapperWithButton
          showModal={TaskPopup}
          Save={HandleAddChecklist}
          onCheckModel={onCheckModel}
        >  {userTasks?.length===0 &&<>
               <Checkbox
                    id="Global"
                    label={
                      <FormattedMessage
                        id="RECRUITER.CUSTOMER.CHECKLIST.GLOBAL.TITLE"
                        defaultMessage="Import from global Checklist"
                      />
                    }
                    onChange={() => setGlobalTemplate(!GlobalTemplate)}
                    value={GlobalTemplate}
                  ></Checkbox>
                   {GlobalTemplate && (copyTasks.length === 0) ? (
        <AllChecklist ChecklistHandle={ChecklistHandle} />
      ) : null}</>}

          <Task taskadded={taskadded} getTasks={getTasks}></Task>
          {addMore && (
            <TaskForm dataAddedHandle={dataAddedHandle} ref={childRef} />
          )}
          {addMore && (
            <Button
              onClick={HandleFormSubmit}
              text={
                <FormattedMessage
                  id="RECRUITER.CUSTOMER.CHECKLIST.SAVE.TASK.BUTTON"
                  defaultMessage="Save Task"
                />
              }
            ></Button>
          )}
          {!addMore && (
            <Button
              onClick={() => setAddMore(true)}
              text={
                <FormattedMessage
                  id="RECRUITER.CUSTOMER.CHECKLIST.TASK.BUTTON"
                  defaultMessage="Add More Task"
                />
              }
            ></Button>
          )}
        </ModelWrapperWithButton>
        <ModelWrapperWithButton
          showModal={editChecklistPopup}
          Save={HandleEditChecklistSave}
          onCheckModel={onCheckModel}
        >
          <ChecklistForm
            ref={childRef}
            dataAddedHandle={dataAddedHandle}
          ></ChecklistForm>
        </ModelWrapperWithButton>
        <Warning text={'Without tasks checklist will save in draft'} ></Warning>
        <Button
          onClick={() => setAddChecklistPopup(true)}
          text={
            <FormattedMessage
              id="RECRUITER.CUSTOMER.ADDCHECKLIST.BUTTON"
              defaultMessage="Add Checklist"
            />
          }
        ></Button>
        <ContentWithSpinner loader={loader==='fetch'}>
          {Object.keys(checklistdetails).length > 0 ? (
            <>
              <Checklist
                HandleDeleteChecklist={HandleDeleteChecklist}
                Viewicon={true}
                HandleChecklistTask={HandleChecklistTask}
                HandleEditChecklist={HandleEditChecklist}
                data={checklistdetails}
              />
            </>
          ) : null}
        </ContentWithSpinner>
      </div>
      {checklistdetails?.length > 0 && (
        <Link to={"/manage-customer"}>
          <Button
            text={
              <FormattedMessage
                id="RECRUITER.CUSTOMER.SAVE.BUTTON"
                defaultMessage="Save"
              />
            }
          ></Button>
        </Link>
      )}
    </>
  );
};
