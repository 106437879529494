import React, { useState } from 'react'
import { toAbsoluteUrl } from 'sr/helpers'
import { Button } from 'sr/helpers'
import { Input } from 'sr/helpers'
import { FormDetails } from 'sr/helpers/ui-components/FormDetails'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AuthNavComponent from './AuthNav'
import { FormattedMessage, useIntl } from 'react-intl'
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { forgetPassword } from 'sr/utils/api/forgetPassword'

export const ForgetPassword = () => {
    const [username, setUsername] = useState()
    const [loader, setLoader] = useState(false)
    const [isEmailSend, setIsEmailSend] = useState(false)
    const intl = useIntl()
    const initialValues = {
      username:username
    }
    const usernamevalidate = Yup.object().shape({
      username:Yup.string().required("INPUT.VALIDATION.USERNAME.LOGIN")
  })
    const {errors, handleSubmit} = useFormik({
      initialValues:initialValues,
      enableReinitialize:true,
      validationSchema:usernamevalidate,
      onSubmit: async (values)=> {
        try {
          setLoader(true)
          const res = await forgetPassword(values)
          if(res.success===true) {
           
              setLoader(false)
              setIsEmailSend(true)
          }
          else {
            setLoader(false)
          }
        }
        catch(e:any) {
setLoader(false)
        }
      }
    })
  return (
    <>
    <AuthNavComponent isLoginPage={true}></AuthNavComponent>
    <main>
    <div className='lg:container mx-auto lg:max-w-6xl lg:mt-8 mt-8 px-4 lg:px-0'>
        <div className='lg:flex block'>
      <FormDetails />
        <div className='lg:flex-1 lg:flex flex-col h-fit lg:mx-12 block p-6 bg-white border border-gray-border rounded-xl'>
        {isEmailSend ?
        <div className='w-[15rem] py-20 text-center mx-auto'>
        <FormattedMessage id='AUTH.FORGET.PASSWORD.EMAIL.SEND.TEXT'></FormattedMessage>
      </div> 
      :
      (<>
            <h1 className=' text-xl'>
            <FormattedMessage
                                id="AUTH.GENERAL.FORGOT_BUTTON"
                                defaultMessage="Forgot Password"
                                />
            </h1>
            <div className='max-w-lg'>
          
              
            <form className='mt-5'>
             
              
            <Input
            onChange={(e)=> setUsername(e.target.value)}
            value={username}
           error={errors.username}
             label={intl.formatMessage({
                                    id:"AUTH.INPUT.USERNAME",
                                    defaultMessage:"Username"
                                }) } type="email" placeholder={intl.formatMessage({
                                    id:"AUTH.LOGIN.INPUT.USERNAME.PLACEHOLDER",
                                    defaultMessage:"Enter your Username"
                                }) }></Input>
                            </form>
                           <div className='flex justify-star mt-4'>
                                    <ButtonAuth onClick={handleSubmit}  loader={loader}><FormattedMessage
                                id="AUTH.GENERAL.FORGOT_BUTTON"
                                defaultMessage="Forgot Password"
                                /></ButtonAuth>
                             </div>
                           
        </div>
        </> )}
      </div>
    </div></div></main></>
  )
}
