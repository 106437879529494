import { CompanyRoutes } from "app/routing/component/CompanyRoutes";

export const FilterRoutes = (supervisorData)=> {
const routes = CompanyRoutes.filter((route) => {
    const access = supervisorData?.access;

    // Check if the route has a matching permission in any of the module
    return Object.entries(access || {}).some(([moduleKey, permissions]) => {
        // Check if the current route's permission exists and is true
        return permissions[route.permission];
    });
});
return routes;
}