import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateApplyWorkOrder = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/workorderapplication', putData).then((res)=> {
        if(res?.success===true) {
            SuccessMessage('API.SUCCESS.APPLY.WORKORDER.UPDATE')
            return res
        }
        else {
            return null
        }
  })
}
catch(e) {
    HandleApiError(e)
}
};
