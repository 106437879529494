import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getNotes =  async (params) => {
  return await get<ApiResponse<[]>>(`/application/notes?${params}`).then((res)=> {
    try {
    if(res?.success===true) {
        return res
    }
    else {
        return null
    }
} catch(e:any) {
    HandleApiError(e)
}
  })
};
