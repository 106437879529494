import React, {useState, ForwardRefRenderFunction, forwardRef, useEffect} from 'react'
import { useIntl } from 'react-intl'
import { Input } from 'sr/helpers'
import * as Yup from 'yup'

interface data {
  email: string;
  password: string;
}

export const EmailChange: ForwardRefRenderFunction<any, any> = (props, ref) => {
  const intl = useIntl()
  const [email, setEmail] = useState()
  const [currentPassword, setCurrentPassword] = useState()
  const [errors, setErrors] = useState<any>({});
  const EmailChangeSchema = Yup.object({
    email: Yup.string().email().required('INPUT.VALIDATION.EMAIL'),
    password: Yup.string().required('INPUT.VALIDATION.PASSWORD').min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER')
  })

  useEffect(()=> {
    props?.HandleData({
      email:email,
      password:currentPassword
    })
  },[email, currentPassword]);

  const validateData = async () =>  {
    const data:data = {
      email:email,
      password:currentPassword,
    }
    let isValid: boolean;
    try {
      await EmailChangeSchema.validate(data, { abortEarly: false });
      setErrors({}); // Reset errors if validation is successful
      props?.HandleData(data);
      isValid = true;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      isValid = false;
    }
    return isValid;
  };

  React.useImperativeHandle(ref, () => ({
    validateData
  }));
useEffect(()=> {
console.log(errors.email);

},[errors])
  return (
    <>
   <Input
   error={errors.email}
   onChange={(e:any)=> setEmail(e.target.value)}
   value={email}
  InputDivClass="mt-0"
  inputClass="border border-zinc-800 rounded-lg h-11"
  label={intl.formatMessage({
    id: 'RECRUITER.ACCOUNT.SETTINGS.CHANGE_EMAIL.INPUT.NEWMAIL',
    defaultMessage: 'New email address',
  })}
/>

<Input
onChange={(e:any)=> setCurrentPassword(e.target.value)}
error={errors.password}
value={currentPassword}
  type="password"
  InputDivClass="mt-5"
  inputClass="border border-zinc-800 rounded-lg h-11"
  label={intl.formatMessage({
    id: 'RECRUITER.ACCOUNT.SETTINGS.INPUT.CURRENTPASS',
    defaultMessage: 'Current password',
  })}
/>

    </>)
}
export default forwardRef(EmailChange);