import React, {useEffect, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Sidebar } from './Components/Sidebar'
import { AccountSettings } from './Components/AccountSettings'
import { DeviceManagement } from './Components/DeviceManagement'
import { CompanySettings } from './Components/CompanySettings'
import { FormattedMessage } from 'react-intl'
import CompanyAdditionalInfo from '../CompanyAdditionalInfo'
import CompanyBranchInfo from '../CompanyBranchInfo'
import { BranchInfoSettings } from './Components/BranchInfoSettings'
export const Settings = () => {
    const [Tab, setTab] = useState('Account Settings')
    const [title, setTitle] = useState(<FormattedMessage
      id="RECRUITER.ACCOUNT.SETTINGS.MENU.ACCOUNT.TITLE"
      defaultMessage="Account Settings"
    />)
    const HandleTab = (data) => {
      setTitle(data)
    setTab(data.props.defaultMessage)
    }
    
  return (
    <MainWrapper>
       <div className='py-2 flex space-x-2'>
        <Sidebar activeTab={Tab} HandleTabs = {HandleTab}/>
        <div className='flex-1 bg-white p-10 rounded-lg'>
        <h1 className='font-satoshiBold text-xl mb-3 pb-3 border-b border-neutral-200'>{title}</h1>
        {Tab === 'Account Settings' &&
<AccountSettings />
}
{/* {
    Tab === 'Device Management' && 
    <DeviceManagement />
} */}
{
    Tab === 'Company Settings' && 
    <CompanySettings />
}
{
    Tab === 'Additional Info' && 
    <CompanyAdditionalInfo type='setting'/>
}
{
    Tab === 'Branch' && 
    <BranchInfoSettings />
}
</div>

        </div>
    </MainWrapper>
  )
}
