import React, {useState, useEffect, useRef} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Link, useParams } from 'react-router-dom'
import { InputSearch } from 'sr/helpers/InputSearch'
import { Empty } from './component/Empty'
import { ModelWrapper } from '../component/ModelWrapper'
import { TableRow } from './component/TableRow'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Checkbox } from 'sr/helpers'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { TopBar } from '../component/TopBar'
import { FormattedMessage, useIntl } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getApplication } from 'sr/utils/api/cleaner/getApplication'
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { updateApplication } from 'sr/utils/api/cleaner/updateApplication'
import { toast } from 'react-toastify'
import { CandidateSortType, TabData, usaCities } from 'sr/i18n/messages/setting'
import { Pagination } from 'app/pages/component/Pagination'
import { ApplicationTab } from 'app/pages/component/ApplicationTab'
import { SortCandidate } from './component/SortCandidate'

export const Candidates = () => {
  const intl = useIntl()
    const [showModal, setShowModal] = useState(false)
    const [activeTab, setActiveTab] = useState('active')
    const [filter, setFilter] = useState(false)
    const [locationModel, setLocationModel] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalPage, settotalPage] = useState<number>(1);
    const [selectedlocation, SetSelectedLocation] = useState<any[]>([])
    const [id, setId] = useState(null)
    const [loader, setLoader] = useState(true)
    const [candidates, setCandidates] = useState<any[]>([])
    const [filterData, setFilterData] = useState<any[]>([])
    const [tabCount, setTabCount] = useState<any>({})
    const [allJobs, setAllJobs] = useState(null)
    const [filterJobs, setFilterJobs] = useState(null)
    const [jobId, setJobId] = useState('')
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState<string>('')
   const HandleModelByProps = (data) => {
    setShowModal(data)
    setLocationModel(false)
   }
  
   const params = useParams()

   useEffect(()=> {
    const getdetails = getUserDetails()
    setId(getdetails?.user_id)
    console.log(params);
    
    const {status, job_id} = params
      if(job_id) {
        setJobId(job_id)
      }
    if(status) {
      console.log(status);
      setActiveTab(status)
    }
  },[])


    const fetchCandidates = async ()=> {
        try {
          setLoader(true)
          const candidate = []
            const queryParams = new URLSearchParams();  
            if(jobId) {
           queryParams.append('job_id', jobId.toString());
            }
            else {
              queryParams.append('company_id', id.toString());
            }
      if (page) queryParams.append('page', page.toString());
      if (limit) queryParams.append('limit', limit.toString());
            const result = await getApplication(queryParams)
            if(result.success===true) {
                  setTabCount(result?.pagination?.statusCounts)
              settotalPage(Math.ceil(result?.pagination.total/limit))
                 setCandidates(result?.data)
                 const queryParams = new URLSearchParams
                 if (id) queryParams?.append('company_id', id)
                const jobresults = await getJobs(queryParams)
                if(jobresults.success===true) {
                      setAllJobs(jobresults?.data);
                }
              }
            setLoader(false)
        }
        catch(e) {

        }
    }
  useEffect(()=> { 
    if(id) {
      fetchCandidates()
    }
  },[id, page, limit, jobId])

   
    const HandleActiveTab = (active) => {
        setActiveTab(active)
    }
    
    useEffect(()=> {
      const data = candidates?.filter((item)=> item?.status?.toLowerCase() === activeTab?.toLowerCase())
setFilterData(data)

    },[activeTab, candidates])

useEffect(()=> {
  const filterdatas = allJobs?.filter((item)=> item?.job_title?.toLowerCase().includes(search?.toLowerCase()))
  setFilterJobs(filterdatas)
},[search, allJobs])
const HandleAction = async (status, id) => {
  const data = {
    id: id,    
    status:status
}
  const result = await updateApplication(data)
  if(result?.success===true) {
    fetchCandidates()
  }
}
  return (
   <MainWrapper>
    <div className='py-3'>
      <ContentWithSpinner loader={loader} height='h-screen'>
      <TopBar title = { <FormattedMessage id="RECRUITER.CANDIDATES.CANDIDATES.TITLE" defaultMessage="Candidates" />  }/>
    <div  className="flex space-x-2 pb-3 items-center justify-start border-b border-neutral-200 relative">
        <span onClick={()=> setShowModal(!showModal)} className='font-satoshiBold text-base cursor-pointer'>
        {jobId ?
filterJobs?.map((data)=> (
  data?.id === jobId && 
              data?.job_title 
))
:
          <FormattedMessage id="RECRUITER.CANDIDATES.FILTER.TITLE" defaultMessage="All Job" />
}
          </span>
          <span onClick={()=> setShowModal(!showModal)} className={`transition-transform transform duration-1000 ${showModal ? 'rotate-180':''}`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg>
</span>
{showModal ? (
        <>
        <ModelWrapper HandleModelByProps = {HandleModelByProps} showModal = {showModal}>
          <div className=' w-full px-4 py-4'>
            <span className='text-base font-satoshiBold'>
              <FormattedMessage id="RECRUITER.CANDIDATES.FILTER.TITLE" defaultMessage="All Job" /></span>
        <InputSearch onChange={(e)=> setSearch(e.target.value) } inputdiv="w-full" className="border border-zinc-800 rounded-lg mt-2" 
        placeholder={intl.formatMessage({
          id: 'RECRUITER.CANDIDATES.FILTER.INPUT.SEARCH.PLACEHOLDER',
          defaultMessage: 'Search',
        })}
      
       height = 'h-10' borderColor='border-[#275df5]'>
                                      </InputSearch>
                                      </div>
                                      <div className='h-5 bg-[#f3f2f1]'></div>
        {/*footer*/}
        <div className="rounded-b px-4 h-80 overflow-scroll">
       <div>
<ul>
<li onClick={()=> setJobId('')}  className='text-[#2557A7] py-4 border-b border-neutral-200 cursor-pointer'>
<FormattedMessage id="RECRUITER.CANDIDATES.FILTER.TITLE" defaultMessage="All Job" />
</li>
{
filterJobs?.map((data)=> (
<li onClick={()=> setJobId(data?.id)} className='text-[#2557A7] font-semibold py-2 border-b border-neutral-200 cursor-pointer'>
<div className='text-base'>{data?.job_title}</div>
<div className='text-sm'>{data?.job_type[0]}</div>
</li> )) }
</ul>

       </div>
        </div>
        </ModelWrapper>
        </>
      ) : null}

</div>
<div className='flex items-center justify-between mt-8 space-x-4'>
<div onClick={()=> setFilter(!filter)} className='bg-white hover:text-[#2557A7] cursor-pointer  p-3 border border-neutral-200 hover:border-[#2557A7] rounded-lg flex space-x-2 items-center px-4'>
  <span className=' font-satoshiBold'> <FormattedMessage id="RECRUITER.CANDIDATES.FILTERS.TITLE" defaultMessage="Filters" />  </span>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg>
 </div> 

<ApplicationTab HandleActiveTab={HandleActiveTab} activeTab={activeTab} tabCount={tabCount}></ApplicationTab>
</div>
{filter && 
<div className='flex items-center space-x-3 my-4'>
  <SortCandidate setSort={setSort} sort={sort}/>
  <div className='relative'>
 <div className='bg-white hover:text-[#2557A7] cursor-pointer  p-2 border border-[#d4d2d0] hover:border-[#2557A7] rounded-lg flex space-x-2 items-center px-4'>
 <span onClick={() => setLocationModel(!locationModel)}>
  <span className='font-satoshiBold'>
    <FormattedMessage
      id='RECRUITER.CANDIDATES.FILTERS.LOCATION.TITLE'
      defaultMessage='Location:'
    />:
  </span> <FormattedMessage
    id='RECRUITER.CANDIDATES.FILTERS.LOCATION.GENERAL.TYPE'
    defaultMessage='Any'
  />
</span>

  <svg onClick={() => setLocationModel(!locationModel)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg>
{locationModel &&
<ModelWrapper HandleModelByProps = {HandleModelByProps} showModel = {locationModel} width = 'w-56'>
  <div className='p-3'>
    {usaCities?.map((data, index)=> (
<Checkbox checked={selectedlocation.includes(data?.value)}  label={data?.label} id={data?.value} name={data?.value} value={data?.value} onChange={(e)=> CheckBoxHandle(e, selectedlocation, SetSelectedLocation)} />
))}
</div>
</ModelWrapper>
}</div>
 </div> 
</div> }
{candidates.length > 0 ? (
<div className="relative overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
                {/* <th scope="col" className="p-4">
                    <div className="flex items-center">
                        <input id="checkbox-all" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>
                        <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
                    </div>
                </th> */}
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  <FormattedMessage
    id='RECRUITER.CANDIDATES.TABLE.COL.CANDIDATE'
    defaultMessage='Candidate'
  />
</th>
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  <FormattedMessage
    id='RECRUITER.CANDIDATES.TABLE.COL.ADDRESS'
    defaultMessage='Screen Questions'
  />
</th>
{/* <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  <FormattedMessage
    id='RECRUITER.CANDIDATES.TABLE.COL.ACTIVITY'
    defaultMessage='Activity'
  />
</th> */}
<th scope="col" className="px-6 py-4 text-base font-satoshiBold">
  <FormattedMessage
    id='RECRUITER.CANDIDATES.TABLE.COL.INTEREST'
    defaultMessage='Interest'
  />
</th>

            </tr>
        </thead>
        <tbody>
          {filterData?.map((data)=> (
        <TableRow data={data} activeTab={activeTab} shortlistonClick={()=> HandleAction('shortlist', data?.id)} rejectonClick={()=> HandleAction('rejected', data?.id)}  maybeonClick = {()=> HandleAction('awaiting-reviews', data?.id)}/>
))}
          
        </tbody>
    </table>
    <Pagination page={page} totalPage={totalPage} setPage={setPage} />
</div>
 ):  (
<Empty tabname = {activeTab} />
 )
 
 }
</ContentWithSpinner>
    </div>
    
    </MainWrapper>
  )
}
