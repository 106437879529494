import React, {useState} from 'react'
import { Sidebar } from './Components/Sidebar'
import { AccountSettings } from './Components/AccountSettings'
import { DeviceManagement } from './Components/DeviceManagement'
import { JobPreferences } from './Components/JobPreferences'
import AuthNavComponent from '../module/auth/AuthNav'
import Footer from '../module/Footer'
export const UserSetting = () => {
    const [Tab, setTab] = useState('Account Settings')
    const HandleTab = (data) => {
    setTab(data)
    }
  return (
    <>
<AuthNavComponent></AuthNavComponent>
<main className="lg:container mx-auto lg:max-w-6xl lg:mt-8 mt-8 px-4 lg:px-0">
       <div className='py-2 flex space-x-2'>
        <Sidebar activeTab={Tab} HandleTabs = {HandleTab}/>
        <div className='flex-1 bg-white p-10 rounded-lg'>
        <h1 className=' text-xl mb-3 pb-3 border-b border-neutral-200'>{Tab}</h1>
        {Tab === 'Account Settings' &&
<AccountSettings />
}
{
    Tab === 'Device Management' && 
    <DeviceManagement />
}
{
    Tab === 'Job Preferences' && 
    <JobPreferences />
}

</div>

        </div>
        </main>
       <Footer></Footer>
        </>
  )
}
