import React from 'react'

export const ChecklistTemplatedata = [
    {
      "ChecklistName": "Daily Cleaning Office -1",
      "Tasks": 11,
      "Type": "Office",
      "Sub Type": "Daily Cleaning"
    },
    {
      "ChecklistName": "Daily Cleaning Office -2",
      "Tasks": 5,
      "Type": "Office",
      "Sub Type": "Daily Cleaning"
    },
   
    {
      "ChecklistName": "Weekly Cleaning Office -1",
      "Tasks": 5,
      "Type": "Office",
      "Sub Type": "Weekly Cleaning"
    },
    {
      "ChecklistName": "Weekly Cleaning Office -2",
      "Tasks": 6,
      "Type": "Office",
      "Sub Type": "Weekly Cleaning"
    },
    {
      "ChecklistName": "Hospital Cleaning Office -1",
      "Tasks": 8,
      "Type": "Hospital",
      "Sub Type": "Daily Cleaning"
    },
    {
      "ChecklistName": "Hospital Cleaning Office -2",
      "Tasks": 5,
      "Type": "Hospital",
      "Sub Type": "Daily Cleaning"
    },
    {
      "ChecklistName": "Hospital Cleaning Office -1",
      "Tasks": 5,
      "Type": "Hospital",
      "Sub Type": "Weekly Cleaning"
    },
    {
      "ChecklistName": "Hospital Cleaning Office -2",
      "Tasks": 4,
      "Type": "Hospital",
      "Sub Type": "Weekly Cleaning"
    }
  ]
  
export const ChecklistTask =[
    {
      "Task": "Clean Trash",
      "Description": "Clean should be clean in a better way and make attention.",
      "Type": "Mandatory"
    },
    {
      "Task": "Mop Floor",
      "Description": "Clean should be clean in a better way and make attention.",
      "Type": "Mandatory"
    },
    {
      "Task": "Vacuum Carpet",
      "Description": "Clean should be clean in a better way and make attention.",
      "Type": "Mandatory"
    },
    {
      "Task": "Change Toilet Roll",
      "Description": "Clean should be clean in a better way and make attention.",
      "Type": "Mandatory"
    },
    {
      "Task": "Refill soap",
      "Description": "Clean should be clean in a better way and make attention.",
      "Type": "Optional"
    },
    {
      "Task": "Wipe windows",
      "Description": "Clean should be clean in a better way and make attention.",
      "Type": "Mandatory"
    },
    {
      "Task": "Dust work stations",
      "Description": "Clean should be clean in a better way and make attention.",
      "Type": "Mandatory"
    }
  ]
  