import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface CounterState {
  count: number
}

// Define the initial state using that type
const initialState: CounterState = {
  count: 0,
}

export const CounterSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,

  reducers: {
    increment: (state) => {
      console.log('state', state.count)
      state.count += 1
    },
    decrement: (state) => {
      state.count -= 1
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.count += action.payload
    },
  },
})

export const {increment, decrement, incrementByAmount} = CounterSlice.actions

export default CounterSlice.reducer
