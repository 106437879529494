import React, {useEffect, useState} from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { payTypes } from 'sr/i18n/messages/setting'
export const Range = (props) => {
  const [pay, setPay] = useState('per hour')
  const [minAmount, setMinAmount] = useState()
  const [maxAmount, setMaxAmount] = useState()
  const HandleChange = (e) => {
    setPay(e.target.value)
}
useEffect(()=> {
setMaxAmount(props?.maxAmount)
setMinAmount(props?.minAmount)
setPay(props?.pay)
},[])
const RangeData = {
  min_amount:minAmount,
  max_amount:maxAmount,
  payType:pay ? pay : 'per hour'
}
useEffect(()=> {
  props.HandleShowPay(RangeData)
},[maxAmount, maxAmount, pay])

  return (
    <div className='flex space-x-4 w-full'>
    <Input 
    onChange={(e)=> setMinAmount(e.target.value)}
    value={minAmount}
     label = {<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.MINIMUM.TITLE" defaultMessage="Minimum" />}
     placeholder="$"
     type={'text'}
       inputClass = 'border border-zinc-800 rounded-lg h-11'
       InputDivClass = "w-1/3 mt-10 h-full"
       min={1}
       error={props?.errors?.min_amount}
    />
     <Input 
     onChange={(e)=> setMaxAmount(e.target.value)}
     handleBlur={props?.handleBlur}
     value={maxAmount}
     id='max_amount'
     name='max_amount'
     label = {<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.MAXIMUM.TITLE" defaultMessage="Maximum" />}
     placeholder="$"
     type={'text'}
       inputClass = 'border border-zinc-800 rounded-lg h-11'
       InputDivClass = "w-1/3 mt-10 h-full"
       min={1}
       error={props?.errors?.max_amount}
    />
    <DropDown
    onChange={HandleChange}
    value={pay}
    dropdowndiv='flex-1 mt-10' 
    name="Rate" 
    label={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.TITLE" defaultMessage="Rate" />}
    className="h-11 mt-3 border border-zinc-800 rounded-lg"
    
   >
       {payTypes.map((data)=> (
<option value={data.value}>{data.label}</option>
           ))}
       </DropDown>
       </div> 
  )
}
