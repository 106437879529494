import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TableRow } from "../../component/TableRow";
import { Empty } from "app/pages/company/candidates/component/Empty";
import { useParams } from "react-router-dom";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";

export const WorkOrders = () => {
    const [contractorId, setContractorId] = useState<string>('')
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState<[]>([])
const params = useParams()
    useEffect(()=> {
        const {id} = params
        setContractorId(id)
},[params])

    const loadWorkOrder = async ()=> {
        try {
        const queryParams = new URLSearchParams()
        queryParams.append('contractor_id', contractorId)
            const res = await getWorkorder(queryParams)
            if(res?.success===true) {
                    setLoader(false)
                    setData(res?.data)
            }
        }
        catch(e) {
                setLoader(false)
        }
    }
    useEffect(()=> {
            if(contractorId) {
                loadWorkOrder()
            }
    },[contractorId])
  return (
  <div className="py-3">
     <ContentWithSpinner loader={loader} height="h-screen">
     <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
            <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.WORKORDER.TITLE" defaultMessage="Title" />
                </th>
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.CUSTOMER.NAME.TITLE" defaultMessage="Customer Name" />
                </th>
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.LOCATION" defaultMessage="Location" />
                </th>

                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.SCHEDULE.DATE" defaultMessage="Schedule Date" />
                </th>
              
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.COMPLETION.DATE" defaultMessage="Completion Date" />
                </th>

                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.RATE.DATE" defaultMessage="Amount" />
                </th>
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.STATUS" defaultMessage="Status" />
                </th>
                {/* {((type ==='recurring') || (type==='one time' && (active==='scheduled'|| active==='in progress'))) &&
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME" defaultMessage="Action" />
                </th>
} */}
            </tr>
        </thead>
        <tbody>
          {data?.length>0 ?
          
          data?.map((item, index)=> (
        <TableRow 
        active={null}
        key={index} 
        type={'one time'}
         item={item} 
         setEditId={null}
         setShowDelete={null}
         setDeleteId={null}
           isCompletionDate={true}
           editPermission={null}
            rejectPermission ={null}
           />
))
:
<tr>
<td colSpan={7} className="text-center">
  <Empty text="No records to display" className='py-14'/>
</td>
</tr>
}
          
        </tbody>
    </table>
    </ContentWithSpinner>
    </div>
);
};
