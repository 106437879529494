import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createChecklist = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/customer/checklist', postData).then((res)=> {
    if(res.success===true){
        SuccessMessage("API.SUCCESS.CUSTOMER.CHECKLIST.ADD")
        return res
    }
    else {
        return null
    }
  })
}
catch(e){
    HandleApiError(e)
}
};
