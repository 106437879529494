import * as Yup from 'yup'

const workorderSchema = Yup.object({
    title:Yup.string().required('INPUT.VALIDATION.WORKORDER.ADD.TITLE'),
    customer_id:Yup.string().required('INPUT.VALIDATION.WORKORDER.SELECT.CUSTOMER'),
    customer_location_id:Yup.string().required('INPUT.VALIDATION.WORKORDER.SELECT.LOCATION'),
    checklist_id:Yup.string().required('INPUT.VALIDATION.WORKORDER.SELECT.CHECKLIST'),
    cleaner_id:Yup.string(),
    type:Yup.string().required('INPUT.VALIDATION.WORKORDER.SELECT.TYPE'),
    time_for_work_completion:Yup.string().required('INPUT.VALIDATION.WORKORDER.TIMEFORWORKCOMPLETION'),
    pay_type:Yup.string().required('INPUT.VALIDATION.WORKORDER.SELECT.PAY'),
    pay_type_rate:Yup.string().required('INPUT.VALIDATION.WORKORDER.PAY.TYPE.RATE'),
    entry_time:Yup.string().required('INPUT.VALIDATION.WORKORDER.ENTRYTIME').nullable(),
    exit_time:Yup.string(),
    description:Yup.string(),
    one_time_date:Yup.string(),
    enter_date:Yup.string(),
    recurring:Yup.boolean().required('INPUT.VALIDATION.WORKORDER.SELECT.TYPE'),
    repeat_every:Yup.string(),
    recurrence:Yup.boolean(),
    repeat_on:Yup.array().nullable(),
    ends: Yup.object({}).nullable()
})
export default workorderSchema;