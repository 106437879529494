import React, { useEffect, useState } from "react";
import { Input } from "sr/helpers";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { FormattedMessage } from "react-intl";
import { DateFormat } from "sr/helpers/functions/DateFormat";
export const ApplicationDeadline = (props) => {
  const [applicationDeadline, setApplicationDeadline] = useState(new Date())
    useEffect(()=> {
      const formatteddate = DateFormat(props?.data?.job_advanced_id?.application_deadline)
      if(formatteddate) {
        const newDate = new Date(formatteddate)
        setApplicationDeadline(newDate)
      }
    },[props?.data])
    useEffect(()=> {
        const data = {
          application_deadline:applicationDeadline
        }
        props?.HandleDataProps(data)
    },[applicationDeadline])

    const startDate = props?.data?.start_date;
  return (
    <div className='pt-10'>
        <ReactDatePicker 
InputDivClass="mt-2"
onChange={(date)=> setApplicationDeadline(date)}
selected={applicationDeadline}
minDate={startDate ? new Date(startDate): new Date()}
inputClass="bg-white border border-zinc-800 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-blue-500 focus:border-[#2557A7] focus:shadow-[0_3px_0px_0px_rgb(37,87,167)] block w-full pl-10 p-2 uppercase"
label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.APPLICATION.DEADLINE" defaultMessage="Accepting applications until" />}></ReactDatePicker>
                </div>
    );
};
