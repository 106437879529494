import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateMessage = async (putData) => {
    try {
return await put<ApiResponse<[]>>(`/chatmessage`, putData).then((res)=> {
if(res.success===true) {
    return res
}
else {
    return null
}
})
    }
    catch(e:any) {
        HandleApiError(e)
    }
}
