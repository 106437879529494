import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const addReportJob = async (postData:any) => {
return await post<ApiResponse<[]>>('/job/report', postData).then((res)=> {
    try {
    if(res.success) {
        SuccessMessage('API.SUCCESS.JOB.REPORTED')
        return res
    }
    else {
        return null
    }
}
catch(e:any) {
    HandleApiError(e)
}
})
};
