import { put } from "sr/utils/axios"
import ApiResponse from "sr/models/ApiResponse"
import {toast} from 'react-toastify'
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage"

export const updateAddInfo = async (putData:any) => {
try {
   return await put<ApiResponse<[]>>('/company/addlinfo', putData).then((res)=> {
    if(res.success=== true) {
        SuccessMessage("API.SUCCESS.COMPANY.ADD.INFO")
        return res
    }
    else {
        
        toast.error(res.error.message)
        return null
    }
   })
}
catch(e:any) {
    toast.error(e.message)
}
}