import React from 'react'

export const WhiteBtn = (props) => {
  return (
    <div onClick={props.onClick} className={`${props.mainClass ? props.mainClass : null} bg-white cursor-pointer text-[#2557A7] px-2 py-2 border border-[#d4d2d0]  hover:border-[#2557A7] rounded-lg flex space-x-2 items-center`}>
{props.svg}
 <span className="font-satoshiBold">{props.title}</span>
 </div>
  )
}
