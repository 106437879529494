import React, { useEffect, useState } from "react";
import { Filter } from "../company/work-order/component/Filter";
import { FormattedMessage } from "react-intl";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { Button } from "sr/helpers/ui-components/Button";

export const TransactionDateFilter = (props) => {
    const [selectedMonth, setSelectedMonth] = useState<string>('')
            const [from, setFrom] = useState(new Date())
            const [to, setTo] = useState(new Date())
      const currentYear = new Date().getFullYear();
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    useEffect(()=>{
        const getMonthShortName = (date) => {
            return date.toLocaleString('en-US', { month: 'short' });
          };
          const date = new Date(); 
          setSelectedMonth(getMonthShortName(date)); 
    },[])
    useEffect(() => {
        if (selectedMonth) {
          const utcDateFrom = new Date(Date.UTC(currentYear, months?.indexOf(selectedMonth), 1));
          const utcDateTo = new Date(Date.UTC(currentYear, months?.indexOf(selectedMonth), 30));
    
          setFrom(utcDateFrom);
          setTo(utcDateTo);
        }
      }, [selectedMonth]);
      const handleApply = ()=>{
        if(from){
          props?.setFrom(from)
        }
        if(to){
          props?.setTo(to)
        }
      }
  return (
    <Filter width="w-96" name={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.DATE.RANGE" defaultMessage='Date Range' /> } label={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.DATE.RANGE" defaultMessage='Date Range' /> }>
    <div className="px-3">
        <div className=" text-base ">Date Range</div>
    <DropDown value={selectedMonth} onChange={(e)=> setSelectedMonth(e.target.value)} dropdowndiv={'mt-5'} label = {<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.MONTH.LABEL"></FormattedMessage>}>
    {months.map((month, index) => (
        <option value={month} key={index}>{month} {currentYear}</option>
    ))}
    </DropDown>
    <div className="flex justify-between space-x-5">
        <ReactDatePicker selected={from}  onChange={(value)=> setFrom(value) } InputDivClass={'mt-5'} label={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.FROM"></FormattedMessage>}></ReactDatePicker>
        <ReactDatePicker selected={to}  onChange={(value)=> setTo(value) }  InputDivClass={'mt-5'} label={<FormattedMessage id="CLEANER.WALLET.BILLING.HISTORY.TO"></FormattedMessage>}></ReactDatePicker>
    </div>
    </div>
    <div className="bg-gray-200 border-b my-2"></div>
    <div className="flex justify-end my-3">
    <Button onClick={handleApply} text={<FormattedMessage id="CLEANER.WALLET.DATE.RANGE.FILTER.APPLY" defaultMessage="Apply" />} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
       </div>
    </Filter>
);
};
