import React, {useEffect, useState} from 'react'
import { RadioInput } from 'sr/helpers/ui-components/RadioInput';
import { InputSearch } from 'sr/helpers/InputSearch';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkOrderPayType } from 'sr/i18n/messages/setting';
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { usaCities } from 'sr/i18n/messages/setting';
import { jobTypes } from 'app/pages/company/jobpost/component/Jsonalldata';
import { employment } from 'app/pages/company/jobpost/component/Jsonalldata';
import { payTypes } from 'sr/i18n/messages/setting';
import { HideShow } from '../Sidebar/HideShow';
import { TextHideShow } from '../Sidebar/TextHideShow';
import { RateRange } from './RateRange';
export const Filter = ({HandleFilterData}) => {
  const [payType, setPayType] = useState([])
  const [location, setLocation] = useState([])
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [rateRange, setRateRange] = useState()
    useEffect(()=> {
  const filter = {
    pay_type:payType,
    location:location,
    createdAt:{
      from:from,
      to:to
    },
    rateRange:rateRange
  }
  HandleFilterData(filter)
    },[location, payType, from, to, rateRange])

    const HandleRangeData = (props)=> {
          setRateRange(props)
    }

  return (
    <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
    <div className='font-bold py-4 border-b-[1px]	'>
      <FormattedMessage 
      id='CLEANER.JOBS.FILTER.TITLE'
      defaultMessage='All Filter'
      />
    </div>
   <HideShow viewmore={false} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.WORKORDER.FILTER.PAYTYPE', defaultMessage:'Pay Type'})} data={WorkOrderPayType} newData={payType}  onChange={(e:any) => CheckBoxHandle(e, payType, setPayType )} />
   <HideShow viewmore={true} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.LOCATION', defaultMessage:'Location'})} searchPlaceholder={intl.formatMessage({id:'CLEANER.JOBS.FILTER.LOCATION.SEARCH.PLACEHOLDER', defaultMessage:'Search Location'})} data={usaCities} newData={location} onChange={(e:any)=> CheckBoxHandle(e, location, setLocation)}/>
   <RateRange viewmore={false} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.WORKORDER.FILTER.RATERANGE', defaultMessage:'Rate Range'})} HandleRangeData={HandleRangeData}/>
   <TextHideShow fromValue={from} toValue={to} From={(date)=> setFrom(date)} To={(date)=> setTo(date)}  Title = {intl.formatMessage({id:'CLEANER.WORKORDER.FILTER.POSTING', defaultMessage:'Work Order Posting Date'})} />
 
</div>
  )
}
