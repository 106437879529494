import React, {useState} from 'react'
import { MainWrapper } from './component/MainWrapper'
import { TopBar } from './component/TopBar'
import { Noselected } from './component/Messages/Noselected'
import { ModelWrapper } from './component/ModelWrapper'
import { Checkbox } from 'sr/helpers'
import { ChatBox } from './component/Messages/ChatBox'
import { FormattedMessage } from 'react-intl'
export const Messages = () => {
    const [modelShow, setModelshow] = useState(false)
    const [selectchat, setSelectChat] = useState(false)
    const HandleModelByProps = (data) => {
        setModelshow(data)
       }
  return (
   <MainWrapper>
    <div className='py-3'>
    <TopBar title = {<FormattedMessage id='RECRUITER.MESSAGES.TITLE' defaultMessage='Messages' />}></TopBar>
    <div className={`flex border-y border-neutral-200 bg-white h-full ${selectchat ? '':'items-center'}`}>
        <div className='py-3 border-r-2 border-neutral-300 h-[calc(100vh-170px)] overflow-x-auto'>
        <div className='flex items-center mb-3 px-4'>
<div className='rounded-l-lg font-bold bg-[#2557A7] px-3 py-1  text-white cursor-pointer'>
<FormattedMessage id="RECRUITER.MESSAGES.TOGGLE.BUTTON.INBOX" defaultMessage="Inbox" />
</div>
<div className='rounded-r-lg font-satoshiBold px-24 py-1  text-[#2557A7] cursor-pointer bg-white border border-neutral-200'>
<FormattedMessage id="RECRUITER.MESSAGES.TOGGLE.BUTTON.UNREAD" defaultMessage="Unread" />(15)
</div>
    </div>
    <div className='flex items-center space-x-3 relative px-4'>
        <div onClick={()=> setModelshow(!modelShow)} className='flex-1 flex items-center py-2 px-2 justify-between border rounded-lg border-black hover:shadow-inputshadow hover:border-[#2557A7]'>
        <span><b><FormattedMessage id="RECRUITER.MESSAGES.FILTER.TITLE" defaultMessage="Filter by job" />:</b> <FormattedMessage id="RECRUITER.MESSAGES.FILTER.COMMON.TYPE" defaultMessage="All Jobs" /></span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg></div>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#2557A7]">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

    
    {modelShow && 
    <ModelWrapper HandleModelByProps={HandleModelByProps} topPosition='top-5' border='border border-black'>
    <div className=' w-full px-4 py-4'>
<Checkbox id='Web' label='Web Developer - Lucknow, Uttar Pradesh' name='Web' value='Web Developer - Lucknow, Uttar Pradesh'/>
    </div>
    </ModelWrapper>
}
        </div>
        <hr className='border-2 border-neutral-200 mt-4'></hr>
        {[{}, {}, {}, {},{}, {}, {}, {},{}, {}, {}, {},{}, {}, {}, {}].map((data)=> (
        <div onClick={()=> setSelectChat(true)} className='bg-[#faf9f8] p-4 border-b border-neutral-200'>
<div className='flex items-center justify-between space-x-2'>
<span className='text-gray-600 font-sm'>
Applied to Web Developer in Lucknow, U...
</span>
<span className='text-gray-600 font-sm'>
Jul 24
</span>
</div>
<p className='font-satoshiBold'>Saniya Khan</p>
<span className='text-gray-600 font-sm'>
Hello, I feel like I'd be a great fit for... 
</span>
        </div> ))}
        </div>
<div className='flex-1'>
    {selectchat ? <ChatBox />:
    (
        (<Noselected />)
    )
    }
</div>
    </div></div>
   </MainWrapper>
  )
}
