import React, {useEffect, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Filter } from './component/Filter'
import { Checkbox } from 'sr/helpers'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { Input } from 'sr/helpers'
import { WhiteBtn } from './component/WhiteBtn'
import { TableRow } from './component/TableRow'
import { Link } from 'react-router-dom'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getJob } from 'sr/utils/api/individual/job/getJob'
import { HandleApiError } from 'sr/helpers/functions/HandleApiError'
import { IndiJobStatus, individualJobStatus } from 'sr/i18n/messages/setting'
export const Jobs = () => {
    const [location, setLocation] = useState('')
    const [status, setStatus] = useState([])
     const [userId, setUserId] = useState()
     const [limit, setLimit] = useState(10)
     const [page, setPage] = useState(1)
     const [data, setData] = useState([])
     const [loader, setLoader] = useState(true)

  useEffect(()=> {
      const {user_id} = getUserDetails()
      setUserId(user_id)
  },[])
useEffect(()=> {
const fetchJob = async () => {
  try {
  const params = new URLSearchParams()
  if (userId) params.append('individual_id', userId)
  if (limit) params.append('limit', limit.toString())
  if (page) params.append('page', page.toString()) 
  const res = await getJob(params)
if(res?.success===true) {
  setData(res?.data)
  setLoader(false)
}
else {
  setLoader(false)
}
}
catch(e) {
  setLoader(false)
  HandleApiError(e)
}
}
if(userId) {
  fetchJob()
}
},[userId])

  return (
   <MainWrapper>
    <div className="py-3">
        <div className='mb-2'>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="INDIVIDUAL.JOBS.TITLE" defaultMessage="Jobs" /></h1>
    <p><FormattedMessage id="INDIVIDUAL.JOBS.TEXT" defaultMessage="Use this page to manage your Jobs" /></p>
   </div>
   <div className='flex space-x-2 items-center'>
  <DropDown labelClass='text-base' value={location} label={<FormattedMessage id="INDIVIDUAL.JOBS.FILTER.DROPDOWN.ADDRESS" defaultMessage="Address" />} dropdowndiv='mt-1' onChange={(e) => setLocation(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="INDIVIDUAL.JOBS.FILTER.DROPDOWN.SELECT.ADDRESS" defaultMessage="Select Address" /></option>
    <option>Delhi</option>
    <option>Mumbai </option>
  </DropDown>
  <Filter label={<FormattedMessage id="INDIVIDUAL.JOBS.FILTER.DROPDOWN.STATUS" />} name={<FormattedMessage id="INDIVIDUAL.JOBS.FILTER.DROPDOWN.FILTERSTATUS" defaultMessage="Filter Status" />}>
{IndiJobStatus?.map((item)=> (
<Checkbox checked={status.includes(item?.value)} label={item?.label} id={item?.value} name={item?.value} value={item?.value} onChange={(e)=> CheckBoxHandle(e, status, setStatus)} />
 ))}
 </Filter>
  <Filter label={<FormattedMessage id="INDIVIDUAL.JOBS.FILTER.DROPDOWN.JOB.STATUS" />} name={<FormattedMessage id="INDIVIDUAL.JOBS.FILTER.DROPDOWN.FILTERSTATUS" defaultMessage="Filter Status" />}>
{individualJobStatus?.map((item)=> (
<Checkbox checked={status.includes(item?.value)} label={item?.label} id={item?.value} name={item?.value} value={item?.value} onChange={(e)=> CheckBoxHandle(e, status, setStatus)} />
 ))}
 </Filter>
 <Input label={<FormattedMessage id="INDIVIDUAL.JOBS.FILTER.DROPDOWN.DATE_AND_TIME" defaultMessage="Date And Time" />} inputClass="rounded-lg" labelClass="mt-0 text-black" InputDivClass="mt-0" type="datetime-local" placeholder="Date and Time"></Input>
 <WhiteBtn mainClass="mt-5" title={<FormattedMessage id="INDIVIDUAL.JOBS.FILTER.REFRESH" defaultMessage="Refresh" />} svg={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>
}></WhiteBtn>
 </div>
 <div className='flex justify-end items-center my-2'>
  <Link to='/individual/job-post-new'>
 <WhiteBtn svg={ <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>} title={<FormattedMessage id="INDIVIDUAL.JOBS.POST_A_JOB.BUTTON" defaultMessage="Post a Job" />}></WhiteBtn></Link>
 </div>
 <div className="overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.JOBS.TABLE.COL.ADDRESS" defaultMessage="Address" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.JOBS.TABLE.COL.ADDRESS" defaultMessage="Address" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.JOBS.TABLE.COL.SUGGEST_A_PRICE" defaultMessage="Suggest A Price" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.JOBS.TABLE.COL.DATEANDTIME" defaultMessage="Date And Time" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.JOBS.TABLE.COL.STATUS" defaultMessage="Status" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="INDIVIDUAL.JOBS.TABLE.COL.ACTIONS" defaultMessage="Actions" />
                </th>
            </tr>
        </thead>
        <tbody>
          {data?.map((item)=> (
        <TableRow item={item}/>
))}
          
        </tbody>
    </table>
<div className='flex items-center justify-center space-x-4 my-3'>
<div className='bg-white text-[#2557A7] cursor-pointer py-3 w-32 border justify-center border-neutral-200 hover:border-[#2557A7] rounded-lg flex space-x-2 items-center'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>

  <span className='font-satoshiBold'><FormattedMessage id="PAGEINATION.PREVIOUS.BUTTON" defaultMessage="Previous" /></span>
 </div> 
 <div className='bg-white text-[#2557A7] cursor-pointer py-3 w-32 border justify-center border-neutral-200 hover:border-[#2557A7] rounded-lg flex space-x-2 items-center'>
  <span className='font-satoshiBold'><FormattedMessage id="PAGEINATION.NEXT.BUTTON" defaultMessage="Next" /></span>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>

 </div> 
  </div>
</div>
    </div>
   
   </MainWrapper>
  )
}
