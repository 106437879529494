import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApprovalStatus, PaymentStatus, WorkOrderStatus } from "sr/i18n/messages/setting";

export const StatusTab = ({data, setFilterdata, statusCount, className='', setActive = null}) => {
    const [Checkbtn, setCheckbtn] = useState("left");
    const refs = useRef(null);
    const [activeTab, setActiveTab] = useState('')
    const [activeType, setActiveType] = useState('')
    const isFirstRender = useRef(true);
    const [isDragging, setisDragging] = useState(false);
    const [prevTouchX, setprevTouchX] = useState();
    const navigate = useNavigate()
const location = useLocation()

    useEffect(() => {
      // Get query parameters from the current URL
      const queryParams = new URLSearchParams(location.search);
      const currentTab = queryParams.get('tab');
      const currentType = queryParams.get('type');
      if (currentTab && currentType) {
        setActiveTab(currentTab);
      if (setActive)  setActive(currentTab)
        setActiveType(currentType)
      }
      else {
        setActiveTab('scheduled')
     if (setActive) setActive('scheduled')
        setActiveType('workorder')
      }
    }, [location.search]);
  
    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return; // Skip the effect on the initial render
      }
  
      if (refs.current) {
        if (Checkbtn === "left") {
          refs.current.scrollLeft = Math.max(refs.current.scrollLeft - 500, 0);
        } else {
          refs.current.scrollLeft += 350;
        }
        console.log(`scrollLeft: ${refs.current.scrollLeft}`);
      }
    }, [Checkbtn]);
    
  useEffect(()=> { 
if(activeTab && activeType) {
    navigate({
      pathname: location.pathname,
      search: `?tab=${activeTab}&type=${activeType}`
    });
  }

  },[activeTab, activeType])

    const HandleActiveTab = (active, type)=> {
      if(type==='workorder'){
        setFilterdata(data?.filter((item)=> item?.workorder_status===active)) 
      }
      if (type==='approval'){
        setFilterdata(data?.filter((item)=> item?.approval_status===(active))) 
      }
      if (type==='payment'){
        setFilterdata(data?.filter((item)=> item?.payment_status===active)) 
      }
        setActiveTab(active)
    if (setActive)    setActive(active)
        setActiveType(type)
       }
       useEffect(()=> {
        if(activeType==='workorder'){
          setFilterdata(data?.filter((item)=> item?.workorder_status===activeTab)) 
        }
        if (activeType==='approval'){
          setFilterdata(data?.filter((item)=> item?.approval_status===(activeTab))) 
        }
        if (activeType==='payment'){
          setFilterdata(data?.filter((item)=> item?.payment_status===activeTab)) 
        }
       },[data, activeTab, activeType])
       const handleMouseMove = (ev) => {
        if (!isDragging) return;
        ev.preventDefault();
        
        // const x = ev.pageX -  refs.current.offsetLeft;
        // const walk = x - startX;
        // refs.current.scrollLeft = scrollLeft - walk;
        refs.current.scrollLeft -= ev.movementX;
        
        
    };
    useEffect(() => {
        /* eslint-disable no-restricted-globals */
        if (screen.width < 768) {
            refs.current.scrollLeft = 90;
        }
        /* eslint-enable no-restricted-globals */
    }, []);
    const handleTouchMove = (ev) => { 
        const touchX = ev.changedTouches[0].clientX;
        if (prevTouchX) {
            const movementX = touchX - prevTouchX;
            refs.current.scrollLeft -= movementX;
        }
        setprevTouchX(touchX);
    };
       const getStatusCount = (value, type)=> {
        if (type==='workorder'){
       return (statusCount && statusCount?.workorderStatusCounts?.[value]) ?? 0
        }
        if (type==='approval'){
          return (statusCount && statusCount?.approvalStatusCounts?.[value]) ?? 0
        }
        if(type==='payment'){
          return (statusCount && statusCount?.paymentStatusCounts?.[value]) ?? 0
        }
      }

  return (
    <div className={`flex space-x-8 ${className}`}>
    <svg onClick={() => setCheckbtn("left")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
     <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
   </svg>
   <div onMouseMove={(ev) => handleMouseMove(ev)}
                    onMouseDown={() => setisDragging(true)}
                    onMouseUp={() => setisDragging(false)}
                    onMouseLeave={() => setisDragging(false)}
                    onTouchMove={(ev) => handleTouchMove(ev)}
                    onTouchEnd={() => setisDragging(false)}
                    onTouchStart={() => setisDragging(true)}  ref={refs} className='flex-1  flex space-x-10 overflow-hidden justify-start'>
     { [...WorkOrderStatus, ...ApprovalStatus, ...PaymentStatus].map((data)=> (
       <span  onClick={()=> HandleActiveTab(data.value, data?.type)} className={`${activeTab  === data.value && activeType=== data.type ? "text-[#0078ae] border-b-2 border-[#0078ae] font-bold":""} hover:text-[#0078ae]  text-base inline-flex flex-shrink-0 cursor-pointer pb-1`}>{data?.label} ({getStatusCount(data.value, data?.type)})</span>
       )) }
   </div>
   <svg onClick={() => setCheckbtn("right")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
     <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
   </svg>
   
    </div>
    );
};
