export const DateFormat = (inputDate:any) => {
    if(inputDate) {
    const dateObject = new Date(inputDate);
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = dateObject.getUTCFullYear();
    const formattedDate = `${month}/${day}/${ year}`;
    return formattedDate;
    }
    else {
        return null
    }
};
