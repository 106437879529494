import React, {useEffect, useState} from 'react'
import { ModelWrapper } from '../../component/ModelWrapper'
import { Link } from 'react-router-dom'
import { Interested } from './Interested'
import { FormattedMessage } from 'react-intl'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { createContractor } from 'sr/utils/api/company/workorder/createContractor'
import { toast } from 'react-toastify'
import { Spinner } from 'sr/helpers'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { useGet } from '../../Hooks/Api/useGet'
import { getContractor } from 'sr/utils/api/company/workorder/getContractor'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { ModelWrapperWithButton } from '../../component/ModelWrapperWithButton'
import { HirePopup } from './HirePopup'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
export const TableRow = ({data, setActiveTab, activeTab, shortlistonClick, rejectonClick, maybeonClick, fetchCandidate}) => {
    const [showModal, setShowModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const {getData:FetchContractor, loader:contractorLoader, data:contractors} = useGet()
    const [showHired, setShowHired] = useState(false)
    const HandleModelByProps = (data) => {
        setShowModal(data)
       }
       const [companyId, setCompanyId] = useState()

    useEffect(()=> {
        const userData = getUserDetails()
        setCompanyId(userData?.user_id)
      },[]) 

      const getContractorall = async (company_id)=> {
        const queryParams = new URLSearchParams()
        queryParams.append('status!','deleted' ) 
        if (company_id) queryParams?.append('company_id', company_id )
          await FetchContractor(()=> getContractor(queryParams))
      }
      useEffect(()=> {
        if (companyId) {
          getContractorall(companyId)
        }
      },[companyId])
       const HandleConvert = async ()=> {
        setShowHired(true)
       }
       const CheckConverted = (cleaner_id)=> {
        const data = contractors?.some((item)=> item?.cleaner_id?._id === cleaner_id)
        return data;
       }
       const onCheckModel = ()=> {
        setShowHired(false)
       }
  return (
    <>
    {loader && <LoaderFullScreen />}
        <tr className="bg-white border-b  hover:bg-gray-50">
            {/* <td className="w-4 p-4">
                <div className="flex items-center">
                    <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
                    <label htmlFor="checkbox-table-1" className="sr-only">checkbox</label>
                </div>
            </td> */}
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap !normal-case">
              <Link to={`/candidate-details/${data?.id}`}>
                <div className='text-base  text-[#0078ae] cursor-pointer'>{data?.cleaner_id?.first_name} {data?.cleaner_id?.last_name}</div>
                <div className='text-base'>Applied: {DateFormat(data?.createdAt)}</div>
                <p className='text-base text-gray-600'>applied to {data?.job_id?.job_title} </p>
                </Link>
            </th>
            <td className="px-6 py-4">
       {data?.cleaner_id?.address?.address_line_1} {data?.cleaner_id?.address?.address_line_2} {data?.cleaner_id?.address?.city} {data?.cleaner_id?.address?.country} {data?.cleaner_id?.address?.postal}
            </td>
            <td className="px-6 py-4">
              <div className='flex space-x-3'>
               
            <Interested status={data?.status} modelonClick = {()=> setShowModal(!showModal)} shortlistonClick = {shortlistonClick} rejectonClick={rejectonClick} maybeonClick={maybeonClick}>
                
                
                {showModal ? (
    <>
<ModelWrapper rightPosition = '-right-20' width = 'w-52' border = 'border border-black'  HandleModelByProps = {HandleModelByProps} showModel = {showModal}>
<ul className='px-3 pb-2  text-base font-bold text-[#595959]'>
{/* <li className='py-2'>
  <FormattedMessage
    id='RECRUITER.CANDIDATES.TABLE.INTEREST.MESSAGE'
    defaultMessage='Message'
  />
</li> */}
<Link to={`/candidate-details/${data?.id}`}>
<li className='py-2'>
  <FormattedMessage
    id='RECRUITER.CANDIDATES.TABLE.INTEREST.VIEWPHONE.DETAILS'
    defaultMessage='View details'
  />
</li>
</Link>
{/* <li className='py-2'>
  <FormattedMessage
    id='RECRUITER.CANDIDATES.TABLE.INTEREST.DELETE.CANDIDATES'
    defaultMessage='Delete candidate'
  />
</li> */}

</ul>
</ModelWrapper>
</>): null }
</Interested >
{
CheckConverted(data?.cleaner_id?._id) ?
  <div className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-3  text-white cursor-pointer hover:bg-navy'>Converted</div>
  :
<button disabled={loader || contractorLoader} onClick={()=> HandleConvert()} className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-3  text-white cursor-pointer hover:bg-navy'>
{loader || contractorLoader  ? <Spinner className='flex w-full justify-center'/> :  <FormattedMessage id='RECRUITER.CANDIDATES.TABLE.CONVERT.TO.CONTRACTOR'></FormattedMessage>  }
</button>
}
</div>
            
            </td>
        </tr>
   {showHired &&    
<HirePopup data={data} getContractorall={getContractorall} setActiveTab={setActiveTab} fetchCandidate={fetchCandidate} showHired={showHired} onCheckModel={onCheckModel}></HirePopup>
}

  </>
)
}
