import React from "react";
import ContentLoader from "react-content-loader";
export const ProfileImgCard = () => {
  return (
    <ContentLoader 
    speed={2}
    width={64}
    height={64}
    viewBox="0 0 64 64"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="1" y="2" rx="0" ry="0" width="64" height="64" />
  </ContentLoader>
  );
};
