import { FormattedMessage } from "react-intl"
const SidebarPostItems = [
    {link:'/individual/location',
    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
  </svg>
  , 
text:<FormattedMessage id="INDIVIDUAL.SIDEBAR.MENU.ADDRESSES" defaultMessage="Addresses" />
},
{link:'/individual/tasklist',
    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
  </svg>, 
text:<FormattedMessage id="INDIVIDUAL.SIDEBAR.MENU.TASKLIST" defaultMessage="Task List" />
},
{link:'/individual/jobs',
    icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
</svg>, 
text:<FormattedMessage id="INDIVIDUAL.SIDEBAR.MENU.JOBS" defaultMessage="Jobs" />
},
]

const ModelItems = [
    { icon:<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>, text: 'Users' },
    { icon: <svg className="w-5 h-5" aria-hidden="true" height="24" role="presentation" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23 8.5V20.5C23 20.8 22.8 21 22.5 21H4.5C4.2 21 4 20.8 4 20.5V19H20.5C20.8 19 21 18.8 21 18.5V8H22.5C22.8 8 23 8.3 23 8.5Z" fill="currentColor"></path><path d="M15 4H1.4C1.2 4 1 4.1 1 4.4V16.7C1 16.9 1.2 17.1 1.4 17.1H18.6C18.8 17.1 19 16.9 19 16.7V4.4C19 4.2 18.8 4 18.6 4H15ZM2.7 8.8C4.3 8.5 5.6 7.2 5.9 5.6H14.2C14.5 7.2 15.8 8.5 17.4 8.8V12.2C15.8 12.5 14.5 13.8 14.2 15.4H5.8C5.5 13.8 4.2 12.5 2.6 12.2L2.7 8.8ZM17.4 8C16.2 7.7 15.3 6.8 15.1 5.6H17C17.2 5.6 17.4 5.8 17.4 6V8ZM5 5.6C4.7 6.7 3.8 7.6 2.7 7.9V6C2.7 5.8 2.9 5.6 3.1 5.6H5ZM2.7 15V13.1C3.8 13.4 4.7 14.3 5 15.4H3.1C2.8 15.4 2.7 15.2 2.7 15ZM15 15.4C15.3 14.3 16.2 13.4 17.3 13.1V15C17.3 15.2 17.1 15.4 16.9 15.4H15Z" fill="currentColor"></path><path d="M10 7.19995C8.20001 7.19995 6.70001 8.69995 6.70001 10.5C6.70001 12.3 8.20001 13.8 10 13.8C11.8 13.8 13.3 12.3 13.3 10.5C13.3 8.69995 11.8 7.19995 10 7.19995ZM10 12.9C8.70001 12.9 7.60001 11.8 7.60001 10.5C7.60001 9.19995 8.70001 8.09995 10 8.09995C11.3 8.09995 12.4 9.19995 12.4 10.5C12.4 11.8 11.3 12.9 10 12.9Z" fill="currentColor"></path></svg>,
     text: <FormattedMessage id="INDIVIDUAL.TOPBAR.MENU.BILLING.AND.INVOICES" defaultMessage="Billing and Invoices" /> },
{icon: <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-6 h-6"><path fill-rule="evenodd" d="M9 9c1.934 0 3.5-1.566 3.5-3.5S10.934 2 9 2a3.499 3.499 0 00-3.5 3.5C5.5 7.434 7.066 9 9 9zm0 1.75c-2.336 0-7 1.172-7 3.5v1.25a.5.5 0 00.5.5h6.92a4.685 4.685 0 01-.9-2.77c0-.909.257-1.757.703-2.477A7.418 7.418 0 009 10.75zm6.952 4.136c.292-.483.46-1.05.46-1.656a3.2 3.2 0 00-3.196-3.202 3.2 3.2 0 00-3.196 3.202 3.2 3.2 0 004.878 2.723l1.897 1.9a.5.5 0 00.706 0l.354-.353a.501.501 0 000-.708l-1.903-1.906zm-1.046-1.656a1.69 1.69 0 11-3.38 0 1.69 1.69 0 013.38 0z" clip-rule="evenodd"></path></svg>
, text:'Subscription'
},
{icon: <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-6 h-6"><path fill-rule="evenodd" d="M9 9c1.934 0 3.5-1.566 3.5-3.5S10.934 2 9 2a3.499 3.499 0 00-3.5 3.5C5.5 7.434 7.066 9 9 9zm0 1.75c-2.336 0-7 1.172-7 3.5v1.25a.5.5 0 00.5.5h6.92a4.685 4.685 0 01-.9-2.77c0-.909.257-1.757.703-2.477A7.418 7.418 0 009 10.75zm6.952 4.136c.292-.483.46-1.05.46-1.656a3.2 3.2 0 00-3.196-3.202 3.2 3.2 0 00-3.196 3.202 3.2 3.2 0 004.878 2.723l1.897 1.9a.5.5 0 00.706 0l.354-.353a.501.501 0 000-.708l-1.903-1.906zm-1.046-1.656a1.69 1.69 0 11-3.38 0 1.69 1.69 0 013.38 0z" clip-rule="evenodd"></path></svg>
, text:'Contact Us'
},
]

const ModelBottomItem = [
    {
        icon:<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-5 h-5"><path fillRule="evenodd" d="M8.269 2.448A.5.5 0 018.766 2h2.468a.5.5 0 01.497.448l.194 1.836a6.395 6.395 0 012.322 1.275l1.785-.765a.528.528 0 01.654.208L17.9 6.998a.473.473 0 01-.137.642l-1.59 1.086a5.735 5.735 0 010 2.548l1.59 1.086a.473.473 0 01.137.642l-1.213 1.996a.528.528 0 01-.654.208l-1.785-.765a6.396 6.396 0 01-2.322 1.275l-.194 1.836a.5.5 0 01-.497.448H8.766a.5.5 0 01-.497-.448l-.194-1.836a6.397 6.397 0 01-2.322-1.275l-1.785.765a.528.528 0 01-.655-.208L2.1 13.002a.473.473 0 01.138-.642l1.589-1.085a5.734 5.734 0 010-2.55L2.238 7.64a.473.473 0 01-.137-.642l1.213-1.996a.528.528 0 01.654-.208l1.785.765a6.395 6.395 0 012.322-1.275l.194-1.836zm1.73 11.043c2.03 0 3.675-1.563 3.675-3.49 0-1.929-1.645-3.492-3.674-3.492-2.03 0-3.675 1.563-3.675 3.491 0 1.928 1.645 3.49 3.675 3.49z" clipRule="evenodd"></path></svg>
        , text: <FormattedMessage id="INDIVIDUAL.TOPBAR.MENU.ACCOUNT.SETTING" defaultMessage="Account Settings" />, link:"/individual/Account"
    },
    { icon: <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-5 h-5"><path d="M4 3a2 2 0 00-2 2v9.998a2 2 0 002 2h5.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-6v-11h6a.5.5 0 00.5-.5V3.5a.5.5 0 00-.5-.5H4z"></path><path d="M12.274 13.795a.5.5 0 000 .707l.354.353a.5.5 0 00.707 0l4.52-4.52a.503.503 0 00-.004-.71l-4.477-4.48a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l3.041 3.043H7.517a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h7.803l-3.046 3.046z"></path></svg>,
     text: <FormattedMessage id="INDIVIDUAL.TOPBAR.MENU.SIGN.OUT" defaultMessage="Sign Out" /> },
]
const IndividualSettingData = [
    { icon: <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
        , 
        smalltext:<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.ACCOUNT.TEXT" defaultMessage="Contact information and password" />,
        text: <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.ACCOUNT.TITLE" defaultMessage="Account Settings" />
    },
    { icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
  </svg>
  
        , 
        smalltext:<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TABLE.COL.FOC" defaultMessage="Form of Communication" />,
        text: <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TITLE" defaultMessage="Notification" />
    },
    { icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>  
  
        , 
        smalltext:<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.BALANCE.TEXT" defaultMessage="Account Balance Detail" />,
        text: <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.BALANCE.TITLE" defaultMessage="Account Balance" />
    },
    { icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 17h8m0 0l-3-3m3 3l-3 3m-4-7v-4m0 0l-3 3m3-3l3-3M3 7v10a4 4 0 004 4h14a2 2 0 002-2V9a2 2 0 00-2-2H7a4 4 0 00-4 4"/>
  </svg>, 
        smalltext:<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.TEXT" defaultMessage="Check and Download Transaction History" />,
        text: <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.TITLE" defaultMessage="Transaction History" />
    },
]

export { ModelItems, ModelBottomItem, SidebarPostItems, IndividualSettingData};