import axios from 'axios'
import {httpAccess} from './HttpUrls'

const AccessAxios = axios.create({
  baseURL: httpAccess,
  headers: {
    Authorization: 'Basic am1tX2FkbWluOjMyMyExY3hzc3M=',
    'Content-Type': 'application/json',
  },
})
export default AccessAxios
