import { TopBanner } from 'app/pages/company/jobpost/component/TopBanner'
import React, {useEffect, useState} from 'react'
import { Input } from 'sr/helpers'
import AutoLocation from 'sr/helpers/ui-components/AutoLocation'
import { toAbsoluteUrl } from 'sr/helpers'
import { TableRow } from 'app/pages/company/work-order/component/TableRow'
import { ActionBtn } from 'app/pages/company/work-order/component/ActionBtn'
import { ModelWrapperWithButton } from 'app/pages/company/component/ModelWrapperWithButton'
import { Button } from 'sr/helpers/ui-components/company/Button'
import { Location } from '../Tables/Location'
import { LocationDetailsView } from '../LocationDetailsView'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { createCustomerLocation } from 'sr/utils/api/company/customer/createCustomerLocation'
import { getCustomerLocation } from 'sr/utils/api/company/customer/getCustomerLocation'
import { useParams } from 'react-router-dom'
import CustomerLocationSchema from 'sr/utils/schemas/company/customer/CustomerLocationSchema'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { updateCustomerLocation } from 'sr/utils/api/company/customer/updateCustomerLocation'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
export const LocationTab = (props) => {
  const intl = useIntl()
  const [savedata, setsavedata] = useState(0)
  const [model, setModel] = useState(false)
  const [addlocation, setaddlocation] = useState(false)
  const [LocationView, setLocationView] = useState(false)
  const [address, setAddress] = useState()
  const [data, setData] = useState()
  const [remark, setRemark] = useState()
  const [customerId, setCustomerId] = useState(null)
  const [customerLocations, setCustomerLocations] = useState([])
  const [locationId, setLocationId] = useState(null)
  const [loader, setLoader]= useState('')
  const [companyId, setCompanyId] = useState()
  const params = useParams()
  useEffect(()=> {
    const {id} = params
    setCustomerId(id)
  },[])
  useEffect(()=> {
      const {user_id} = getUserDetails()
      setCompanyId(user_id)
  },[])
  const initialValues={
    customer_id:customerId,
      company_id:companyId,
    address:address,
    remarks: remark,
  }
  const initialValuesPut={
    id:locationId,
    address:address,
    company_id:companyId,
    remarks:remark
  }
  const onCheckModel = (data) => {
    setModel(data)
    setaddlocation(false)
    setLocationView(false)
    setLocationId(null);
    setData(null);
    setRemark(null);
  }
  const FetchCustomerLocations = async ()=> {
    setLoader('location')
    try {
    const queryParams = new URLSearchParams();  
    if (customerId) queryParams.append('customer_id', customerId.toString());
    const result = await getCustomerLocation(queryParams)
    if(result?.success===true) {
      const filteredLocations = result?.data?.filter(location => location.status !== 'draft');
      setCustomerLocations(filteredLocations);      
      setLoader('');
    }
    else {
      setLoader('')
    }
  }
  catch(e:any) {
    setLoader('')
  }
  }
  useEffect(()=> {
    if(customerId) {
    FetchCustomerLocations()
    }
  },[customerId])
  const {errors, handleSubmit} = useFormik({
    initialValues:locationId ? initialValuesPut : initialValues,
    enableReinitialize:true,
    validationSchema:CustomerLocationSchema,
    onSubmit: async (values)=> {
      setLoader('submit')
try {
  let result;
  if(locationId) {
  result = await updateCustomerLocation(values)
  }
  else {
    result = await createCustomerLocation(values)
  }
  if(result.success===true) {
    FetchCustomerLocations()
    setsavedata(1)
    setaddlocation(false)
    setLocationId(null);
    setData(null);
    setRemark(null);
    setLoader('')
  }
}
catch(e) {
setLoader('')
}
    }
  })
  const Handlesave = () => {
    setsavedata(1)
    setaddlocation(false)
  }
  const HandleAutoLocation = (data)=> {
setAddress(data)
  }
  const HandleViewLocation = (id) => {
    setLocationId(id)
    setLocationView(true)
  }
  const HandleEditLocation = async (id) => {
    setLocationId(id)
    setaddlocation(true)
      try {
      const queryParams = new URLSearchParams();  
      if (id) queryParams.append('id', id.toString());
      const result = await getCustomerLocation(queryParams)
      if(result?.success===true) {
        setData(result?.data?.address);
        setRemark(result?.data?.remarks);
      }
      else {
        
      }
    } 
    catch(e) {
      
    }
  }

  const handleDeleteLocation = async (id) => {
    if(id) {
      const params = {id, status: 'draft'};
      const result = await updateCustomerLocation(params)      
      if(result?.success===true) {
        FetchCustomerLocations();
      }
    }
  }

  return (
    <>
    {loader === 'submit' && <LoaderFullScreen />}
    <div className='py-4'>
<ModelWrapperWithButton showModal={addlocation} onCheckModel={onCheckModel} Save={handleSubmit}>
<form>
                <AutoLocation HandleAutoLocation={HandleAutoLocation} address={data}></AutoLocation>   
                <div className='mt-10'>
                <label htmlFor="Remarks" className=" mb-2 text-sm font-satoshiBold flex items-center">
                  <FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TEXTAREA.TITLE.REMARKS" defaultMessage="Remarks" /></label>
<textarea onChange={(e:any)=> setRemark(e.target.value)} value={remark} id="Remarks" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder={ intl.formatMessage({ id:"RECRUITER.CUSTOMER.INPUT.TEXTAREA.PLACEHOLDER", defaultMessage:"Write your note here..." })}></textarea>        
</div>  </form>
</ModelWrapperWithButton>
<Button onClick={() => setaddlocation(true)} text={<FormattedMessage id="RECRUITER.CUSTOMER.ADDLOCATION.BUTTON" defaultMessage="Add Location" />}></Button>
  <ContentWithSpinner loader={loader==='location'}>
   {customerLocations.length>0 &&                    
<Location HandleViewLocation={HandleViewLocation} HandleEditLocation={HandleEditLocation} handleDeleteLocation={handleDeleteLocation} onClick={() => setModel(true)} data={customerLocations}/>
}
</ContentWithSpinner>

    </div>
  
    <ModelWrapperWithButton nofooter = {true} showModal = {LocationView} onCheckModel={onCheckModel}> 
  <LocationDetailsView id={locationId}/>
    </ModelWrapperWithButton>
                     {customerLocations?.length>0 &&
                   <Button onClick={() => props?.TabHandle('Checklist')} text={<FormattedMessage id="RECRUITER.CUSTOMER.SAVE.BUTTON" defaultMessage="Save" />}></Button>
                     }
    </>
  )
}
