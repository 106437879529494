import React, { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react'
import AutoLocation from 'sr/helpers/ui-components/AutoLocation'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input, TextArea } from 'sr/helpers'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { AddressSchema } from 'sr/utils/schemas/individual/Address'
import { createIndividual } from 'sr/utils/api/individual/createIndividual'
import { createAddress } from 'sr/utils/api/individual/address/createAddress'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { updateAddress } from 'sr/utils/api/individual/address/updateAddress'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getAddress } from 'sr/utils/api/individual/address/getAddress'
 const LocationForm: ForwardRefRenderFunction<any, any> = ({dataAddedHandle=null, id}, ref) => {
    const [addresstype, setAddressType] = useState('')
    const [address, setAddress] = useState()
    const [noOfRooms, setNoOfRooms] = useState()
    const [noOfBath, setNoOfBath] = useState()
    const [totalArea, setTotalArea] = useState()
    const [remark, setRemark] = useState('')
    const [loader, setLoader] = useState(false)
    const [userId, setUserId] = useState<string>()
    const [data, setData] = useState()
    const intl = useIntl()
const HandleAutoLocation = (data)=> {
    setAddress(data)
}
useEffect(()=> {
const {user_id} = getUserDetails()
setUserId(user_id)
},[])

const InitialValues = {
    address_type:addresstype,
    individual_id:userId,
    address:address,
    no_of_rooms:noOfRooms,
    no_of_bath:noOfBath,
    total_area:totalArea,
    remark:remark,
}

const {submitForm, errors} = useFormik({
    initialValues:InitialValues,
    enableReinitialize:true,
    validationSchema:AddressSchema,
    onSubmit:async (values)=> {
        setLoader(true)
        console.log(values);
        
        try {
            console.log(values);
            
            let res
            if(id) {
                res = await updateAddress({id:id, ...values})
            }
            else {
         res = await createAddress(values)
            }
        if(res?.success===true) {
            dataAddedHandle(true)
            if(id){
                SuccessMessage('API.SUCCESS.ADDRESS.UPDATE')
            }else {
            SuccessMessage('API.SUCCESS.ADDRESS.ADD')
            }
        }
        else {
setLoader(false)
        }

        }
        catch(e:any) {
            console.log(e);
            
setLoader(false)
        }
            
    }
})
React.useImperativeHandle(ref, () => ({
    submitForm,
  }));
  useEffect(()=> {
    const fetchLocation = async (id)=> {
        setLoader(true)
      try {
        const params = new URLSearchParams()
       if (id) params.append('id', id)
      const res = await getAddress(params)
       if(res?.success===true) {
        setRemark(res?.data?.remark)
        setNoOfBath(res?.data?.no_of_bath)
        setNoOfRooms(res?.data?.no_of_rooms)
        setData(res?.data?.address)
        setAddressType(res?.data?.address_type)
        setTotalArea(res?.data?.total_area)
        setRemark(res?.data?.remark)
        setLoader(false)
       }
       else {
      setLoader(false)
       }
      }
      catch(e:any) {
        setLoader(false)
      }
      }
      if(id) {
        fetchLocation(id)
      }
  },[id])
  useEffect(()=> {
console.log(address);

  },[address])
  return (

    <form>
        {loader &&<LoaderFullScreen></LoaderFullScreen> }
                <AutoLocation  HandleAutoLocation={HandleAutoLocation} address={data}></AutoLocation>
              
<DropDown error={errors.address_type} onChange={(e)=> setAddressType(e.target.value)} dropdowndiv=' mt-10' labelMargin="mb-1" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.DROPDOWN.TYPE" defaultMessage="Address Type" />} value={addresstype} className=' '>
                    <option value=''>{<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.DROPDOWN.SELECT" defaultMessage="Select Type" />}</option>
                    <option value='Residencial'>Residencial</option>
                    <option value='Commercial'>Commercial</option>
                </DropDown>
                <Input error={errors.no_of_rooms}  value={noOfRooms} onChange={(e)=> setNoOfRooms(e.target.value)} InputDivClass="mt-10" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFROOMS" defaultMessage="No of Rooms" />}
                    placeholder={intl.formatMessage({id:"INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFROOMS", defaultMessage:"No of Rooms" }) }
                    type={'text'}
                    inputClass = ''>
                </Input>  
                <Input error={errors.no_of_bath} value={noOfBath} onChange={(e)=> setNoOfBath(e.target.value)} InputDivClass="mt-10" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS" defaultMessage="No of Bathrooms" />}
                    placeholder={intl.formatMessage({id:"INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS", defaultMessage:"No of Bathrooms" })}
                    type={'text'}
                    inputClass = ''>
                </Input>  
                <Input error={errors.total_area} value={totalArea} onChange={(e)=> setTotalArea(e.target.value)} InputDivClass="mt-10" label={<FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.TOTALAREA" defaultMessage="Total Area" />}
                    placeholder={intl.formatMessage({id:"INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS.PLACEHOLDER", defaultMessage:"Square Feet" })}
                    type={'text'}
                    inputClass = ''>
                </Input>   
                <div className='mt-10'>
                <label  htmlFor="Remarks" className=" mb-2 text-sm font-satoshiBold flex items-center"><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.REMARKS" defaultMessage="Remarks" /></label>
<TextArea 
value={remark}
divClassName=""
placeholder={intl.formatMessage({id:'INDIVIDUAL.MANAGE.ADDRESSES.INPUT.REMARKS.PLACEHOLDER', defaultMessage:'Write your note here...'})} 
className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" 
onChange={(e)=> setRemark(e.target.value)}  
id="Remarks" rows={4}></TextArea>
</div>              
                </form>
  )
}
export default forwardRef(LocationForm)