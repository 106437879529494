import AccessAxios from "libraries/http/AccessHtpp";

class JobHttpHandle {
    cleanerId = '64329b2741b330bcd22e7cb9';
    /**
     * Create new job
     * @param {data} any 
     * @returns {Promise}
     */
    async jobCreation(data: any) {
        return AccessAxios.post('/job', {
            company_id: data.company_id,
            title: data.title,
            description: data.description,
            schehdule_date: data.schehdule_date,
            reccurance: data.reccurance,
            class: data.class,
            location: data.location,
            start_time: data.start_time,
            end_time: data.end_time,
            duration: data.duration,
            pay_type: data.pay_type,
            rate: data.rate,
            radius: data.radius,
            instructions: data.instructions,
            // "media": ["/path/to/media"],
            // "pictures": ["/path/to/pictures"],
            vacancies: data.vacancies,
            status: "ACTIVE"
        });
    }

    /**
     * GET the job list from server
     * @returns {Promise}
     */
    async jobList() {
        return AccessAxios.get('/job');
    }

    /**
     * Apply for job
     * @param {data} any 
     * @returns {Promise}
     */
    async applyJob(data: any) {
        return AccessAxios.post('/application', {
            job_id: data.id,
            cleaner_id: this.cleanerId,
            status: "ACTIVE"
        });
    }
}

export default new JobHttpHandle()