import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";
export const createFavoriteJob = async (postData:any) => {
    try {
  return await post<ApiResponse<[]>>('/cleaner/favoritejob', postData).then((res)=> {
    if(res.success===true) {
        SuccessMessage("API.SUCCESS.JOB.SAVE")
        return res
    }
    else {
        return null
    }
  })
}
catch(e:any) {
   HandleApiError(e) 
}
};
