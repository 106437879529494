import React, { useState, forwardRef, ForwardRefRenderFunction, useEffect } from 'react';import { Input } from 'sr/helpers'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import AutoLocation from 'sr/helpers/ui-components/AutoLocation'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import BranchInfoSchema from 'sr/utils/schemas/company/BranchInfoSchema'
import { createBranchInfo } from 'sr/utils/api/company/createBranchInfo'
import { getBranchInfo } from 'sr/utils/api/company/getBranchInfo'
import { updateBranchInfo } from 'sr/utils/api/cleaner/updateBranchInfo'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth';
import { InputNumberFormat } from 'sr/helpers/functions/InputNumberFormat';
import { scrollToError } from 'sr/helpers/functions/scrollToError';
interface CompanyBranchInfoProps {
    Steppar?: (arg: number) => void | null;
    typec?: string;
    method?:string;
    dataAddedHandle?: (arg: any) => void | null;
    id?: string;
}
const CompanyBranchInfo:ForwardRefRenderFunction<any, CompanyBranchInfoProps> = ({Steppar=null, typec='', id='', method='', dataAddedHandle=null},
ref
) => {
    const navigate = useNavigate()
    const [type, setType] = useState(typec ==='setting' ? '' : 'HO')
    const [address, setAddress] = useState({})
    const [addressp, setAddressP] = useState({})
    const [AddMoreClicked, setAddMoreClicked] = useState(false)
    const [CompanyId, setCompanyId] = useState()
    const [branchName, setBranchName] = useState<string>('')
    const [phoneNo, setPhoneNo] = useState(null)
    const [loader, SetLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const HandleAutoLocation =(address)=> {
        setAddress(address)
    }
    useEffect(()=> 
    {
console.log(typec);
console.log(method);

    },[typec, method])
    useEffect(()=> {
        const getdetails = getUserDetails()
        setCompanyId(getdetails?.user_id)
      },[])
    const initialValues = {
        company_id:CompanyId,
        branch_name:branchName,
        type:type,
        phone_number: phoneNo,
        address:address
    }
    const initialValuesPut = {
        company_id:CompanyId,
        branch_name:branchName,
        type:type,
        phone_number: phoneNo,
        address:address,
        id:id
    }
    useEffect(()=> {
        const fetchData = async ()=> {
            SetLoader(true)
                const result = await getBranchInfo(undefined,id)
                if(result.success===true) {
                    SetLoader(false)
                    setBranchName(result?.data?.branch_name)
                    setType(result?.data?.type)
                    setPhoneNo(result?.data?.phone_number)
                    setAddress(result?.data?.address)
                    setAddressP(result?.data?.address)
                }
                else {
                    SetLoader(false)
                }
        }
        if(id) {
        fetchData()
        }
    },[id])
    const {handleSubmit, handleChange,errors,values, submitForm} = useFormik({
        enableReinitialize:true,
        initialValues:typec ==='setting' &&  method==='put' ? initialValuesPut:initialValues,
        validationSchema:BranchInfoSchema,
          onSubmit: async (values, helpers) =>{
            try {
            setButtonLoader(true)
            console.log(values);
            let result;
            if(typec ==='setting' &&  method==='put') {
             result = await updateBranchInfo(values)
             if(result.success === true) {
                setButtonLoader(false)
                dataAddedHandle(true)
             }
            }
            else {
                const params = {
                    ...values,
                    isDefaultBranch: typec ==='setting' ? false : true,
                }
            result = await createBranchInfo(params)
            }
           
            if(result.success===true) {
                setButtonLoader(false)
                
                if(typec ==='setting'){
                    dataAddedHandle(true)
                }
                if(AddMoreClicked){
                    setBranchName('')
                    setPhoneNo('')
                    setType(null)
                    setAddMoreClicked(false)
                }
                else {
                    if(typec ==='setting') {

                    }
                    else {
                    navigate('/jobs-listing')
                    }

                }
            }
          }
          catch(e) {
            setButtonLoader(false)
          }
}})
    React.useImperativeHandle(ref, () => ({
        submitForm,
      }));

      useEffect(() => {
              scrollToError(errors)
             }, [errors]);
    return (
        
        <div>
            <ContentWithSpinner loader={loader}>
            <form className='mt-5' onSubmit={handleSubmit}>
                <Input
                onChange={(e:any)=>setBranchName(e.target.value)}
                    label={"Branch Name"}
                    placeholder={"Enter branch name"}
                    type={"text"} 
                    value={branchName}
                    error={errors.branch_name}
                    >
                </Input>
                <DropDown error={errors.type} name='selectjob' label="Select Type" value={type} onChange={(e:any)=> setType(e.target.value)}>
                    <option selected>Choose type</option>
                    <option value="SL">Service Location</option>
                    <option value="HO">Head Office</option>
                    <option value="RHO">Regional Head Office</option>
                </DropDown>
                <Input
                error={errors.phone_number}
                onChange={(e:any)=> setPhoneNo(e.target.value)}
                value={InputNumberFormat(phoneNo)}
                
                    label={"Phone Number"}
                    placeholder={"Enter phone number"}
                    type={"text"}>
                </Input>
            <AutoLocation isHideLatLang={true} HandleAutoLocation={HandleAutoLocation} address={addressp}></AutoLocation>
                {typec==='setting' ? '':
                <div className='mt-5 flex space-x-5'>
                   
                                        <ButtonAuth loader={buttonLoader}
                                       
                                        >Submit
                                        </ButtonAuth>

                                       
                                        <ButtonAuth loader={buttonLoader}
                                        onClick={()=> setAddMoreClicked(true)}
                                       
                                        >Add More
                                        </ButtonAuth>
                                    </div>
}
            </form>
            </ContentWithSpinner>
        </div>
    )
}
export default forwardRef(CompanyBranchInfo)