import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import { getSupervisor } from "sr/utils/api/company/workorder/getSupervisor";

export const fetchSupervisor = createAsyncThunk('fetchSupervisor', async (params:any)=> {
    const queryParams = new URLSearchParams();
    if (params?.id) queryParams.append('id', params?.id)
    if (params?.companyId) queryParams.append('company_id', params?.companyId);
    queryParams.append('status!', 'deleted');
    
    const response = await getSupervisor(queryParams);
    if (response?.success === true) {
      return response.data;
    } 
})
interface SupervisorState {
    data: any; // Replace 'any' with your actual data type
    isLoading: boolean;
    isError: boolean;
  }
  const initialState:SupervisorState =  {
    isLoading: false,
    data: null,
    isError: false
}
  
const SupervisorSlice = createSlice({
    name: 'Supervisor',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSupervisor.pending, (state, action) => {
            state.isLoading = true;
        })
            builder.addCase(fetchSupervisor.fulfilled, (state, action) => {
                state.data = action.payload;
            })
            builder.addCase(fetchSupervisor.rejected, (state, action) => {
                console.log('Error', action.payload);
                state.isError = true;

            });

    },
    reducers: undefined
})
export default SupervisorSlice.reducer;
