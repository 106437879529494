import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import {  post } from "sr/utils/axios";

export const createTask = async (postData:any) => {
    try {
  return await post<ApiResponse<[]>>('/individual/tasklist', postData).then((res)=> {
        if(res?.success===true) {
                    return res
        }
        else {
                return null
        }
  })
}catch(e:any) {
    HandleApiError(e)
}
};
