import { useEffect, useState } from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import CompanyBasicInfo from "./CompanyBasicInfo";
import CompanyAdditionalInfo from "./CompanyAdditionalInfo";
import CompanyIndustryInfo from "./CompanyIndustryInfo";
import CompanyBranchInfo from "./CompanyBranchInfo";
import { CompanySocialLinks } from "./CompanySocialLinks";
import { FormattedMessage } from "react-intl";

export default function CompanyProfile() {
    const navigate = useNavigate();
    // const [activeTab, setActiveTab] = useState("PersonalInfo");
    // const [refrences, setRefrences] = useState([{ name: "", expand: true }]);
    // const [empHty, setEmpHty] = useState([{ name: "", expand: true }]);
    // const [training, setTraining] = useState([{ name: "", expand: true }]);
    // const [medicalCdn, setMedicalCdn] = useState([{ name: "", expand: true }]);
    const [latLng, setLatLng] = useState("");


    const [stepperIndex, setStepperIndex] = useState(0);



    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                console.log(position.coords.latitude, position.coords.longitude);
                setLatLng(position.coords.latitude + "," + position.coords.longitude);
            },
            function (error) {
                console.error("Error Code = " + error.code + " - " + error.message);
            }
        );
    }, []);

    // const toggleReferenceSection = (index: number) => {
    //     let referenceList = refrences;
    //     referenceList[index].expand = !referenceList[index].expand;
    //     setRefrences([...referenceList]);
    // };

    // const toggleEmpHstySection = (index: number) => {
    //     let empHtyList = empHty;
    //     empHtyList[index].expand = !empHtyList[index].expand;
    //     setEmpHty([...empHtyList]);
    // };

    // const toggleTrainingSection = (index: number) => {
    //     let trainingList = training;
    //     trainingList[index].expand = !trainingList[index].expand;
    //     setTraining([...trainingList]);
    // };

    // const toggleMedicalConditionSection = (index: number) => {
    //     let medicalCndList = medicalCdn;
    //     medicalCndList[index].expand = !medicalCndList[index].expand;
    //     setMedicalCdn([...medicalCndList]);
    // };
    const handleSteppar = (data) => {
        setStepperIndex(data)
    }
    return (
        <>
            <AuthNavComponent></AuthNavComponent>
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:my-8 my-8 px-4 lg:px-0 lg:flex block'>
                    <div className='mb-4 lg:m-0 lg:flex block flex-col  lg:w-80 w-full p-6 items-center bg-white border border-gray-border rounded-xl h-fit'>
                        <ol className="relative text-gray-500 border-l border-gray-800">
                            <li onClick={()=>setStepperIndex(0)} className="mb-10 ml-6">
                                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 1 ? 'bg-green-500' : 'bg-gray-500'}`}>
                                    <FontAwesomeIcon icon={stepperIndex > 0 ? faCheck : faCircle} className='text-white text-xs' />
                                </span>
                                <h3 className="font-medium leading-tight">Additional Info</h3>
                             
                            </li>
                            
                            
                            <li onClick={()=>setStepperIndex(1)} className="ml-6">
                                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 2 ? 'bg-green-500' : 'bg-gray-500'}`}>
                                    <FontAwesomeIcon icon={stepperIndex > 1 ? faCheck : faCircle} className='text-white text-xs' />
                                </span>
                                <h3 className="font-medium leading-tight">Branches</h3>
                             
                            </li>
                        </ol>
                    </div>
                    <div className='lg:flex-1 lg:flex lg:flex-col block h-fit lg:mx-12 mx-auto p-6 bg-white border border-gray-border rounded-xl'>

                        {
                            stepperIndex === 0 &&
                            <div>
                                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="Additional Info" /></h1>
                                <h3 className='font-satoshi text-md'><FormattedMessage id="RECRUITER.REGISTER.ACCOUNT.ADDITIONALINFO.TEXT" defaultMessage="Additional information helps job seekers know about your company details"/></h3>
                                <div className='max-w-lg'>
                                    <CompanyAdditionalInfo Steppar = {handleSteppar}></CompanyAdditionalInfo>

                                </div>
                            </div>
                        }

                        
                        {
                            stepperIndex === 1 &&
                            <div>
                                <h1 className='font-satoshiBold text-xl'>Add your branch</h1>
                                <h3 className='font-satoshi text-md'>Branch details helps job seekers understand your company location</h3>
                                <div className='max-w-lg'>
                                    <CompanyBranchInfo Steppar = {handleSteppar}></CompanyBranchInfo>
                                    
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>
        </>
    );
}
