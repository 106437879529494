import {combineReducers} from '@reduxjs/toolkit'
import authSlice from './auth'
import counterSlice from './counter'
import { reducer as  registerCompanyReducer} from './registerCompany';
import { reducer as registerCleanerReducer } from './registerCleaner';
import supervisorReducer from './supervisor'
import formReducer from './individualJob'; 
const rootReducer = combineReducers({
  counter: counterSlice,
  auth: authSlice,
  registerCompany:registerCompanyReducer,
  registerCleaner:registerCleanerReducer,
  supervisor:supervisorReducer,
  form:formReducer
})
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer
