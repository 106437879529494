import React, { Children } from 'react'
import { FormattedMessage } from 'react-intl'

export const Interested = ({children, ...props}) => {
  return (
  <>
    <div className='flex items-center space-x-4'>
      {props?.status !=='hired' &&
                <div className='border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
                  {props?.status !== 'shortlist' &&
                  <div onClick={props.shortlistonClick} className='group py-1.5 px-2 cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-black">
<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
<span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
<FormattedMessage
          id='RECRUITER.CANDIDATES.FILTERS.SHORTLIST'
          defaultMessage='shortlist'
        /></span>
</div>
}
{props?.status !=='awaiting-reviews' &&
<div onClick={props.maybeonClick} className='group py-1.5 px-2 cursor-pointer relative hover:text-[#595959] hover:border-2 hover:border-[#949494] hover:bg-[#f3f2f1]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-black">
<path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
</svg>
<span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
<FormattedMessage
          id='RECRUITER.CANDIDATES.FILTERS.AWAITINGREVIEW'
          defaultMessage='awaiting-reviews'
        /></span>
</div>
}
{props?.status !=='rejected' &&
<div onClick={props.rejectonClick} className='group py-1.5 px-2 cursor-pointer relative hover:text-[#a9252b] hover:border-2 hover:rounded-r-lg hover:border-[#f76266] hover:bg-[#FEEEEF]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-black">
<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
<span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
<FormattedMessage
          id='RECRUITER.CANDIDATES.FILTERS.REJECTED'
          defaultMessage='rejected'
        /></span>
</div>
}

                </div>
}
                <div onClick={props.modelonClick} className='p-2 hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] hover:rounded-r-lg rounded-lg cursor-pointer relative'>
                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-label="More actions" className="w-6 h-6"><path d="M12 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
               {children}
               </div></div>
                </> )
}
