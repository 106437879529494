import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { HTMLStringRender } from "sr/helpers/ui-components/HTMLStringRender";

export const ViewJobDetails = ({data}) => {
  return (
 <main>
             <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                 <div className='lg:flex block'>
                 <div className='flex flex-col w-full'>
                     <div className='card-1 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                         <div className='header'>
                             <h1 className='font-bold  text-lg'>{data?.title}</h1>
                             <h2 className='text-sm font-medium text-gray-800'>{data?.individual_id?.first_name} {data?.individual_id?.last_name}</h2>
                         </div>
                             <div className='row-2 flex'>
                                 <div className='pr-8'>
                                     <FontAwesomeIcon icon={faLocationDot} className='text-gray-600 text-sm font-medium' />
                                     <span className='pl-2 text-gray-600 text-sm font-medium'>{data?.streetAddress} {data?.unitorapt }</span>
                                 </div>
                             </div>
                             <div className='border-t border-gray-200 my-5'>
                             </div>
                             <div className='job-description mt-6'>
                             <h1 className='font-bold  text-base'>
                             <FormattedMessage 
                                 id='JOBDETAILS.JOBDESCRIPTION'
                                 defaultMessage='Job Description'
                                 />
                             </h1>
                             <div><HTMLStringRender htmlContent={data?.description}></HTMLStringRender></div>
                         </div>   
                            <div className='mt-6'>
                                                         <div><span className='font-bold  text-sm'> <FormattedMessage id="JOB.STARTDATE.TITLE" defaultMessage="Start Date" />:</span>
                                                             <span className='text-sm text-gray-700 pl-1'>{DateFormat(data?.schedule_date)}</span>
                                                         </div>
                                                       
                       
                         
                 
                                                     
                           
                                                     </div>  
                                                     <div className='mt-6'>
                                                         <div><span className='font-bold  text-sm'> <FormattedMessage id="INDIVIDUAL.JOBS.TABLE.COL.STARTTIME" defaultMessage="Start Time" />:</span>
                                                             <span className='text-sm text-gray-700 pl-1'>{data?.start_time}</span>
                                                         </div>
                                                       
                       
                         
                 
                                                     
                           
                                                     </div>  
 </div></div></div></div></main>
);
};
