import React, { useState, forwardRef, ForwardRefRenderFunction, useEffect } from "react";
import { Input } from 'sr/helpers'
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { jobtypeSchema } from 'sr/utils/schemas/cleaner/jobtypeSchema';
import { createJobtype } from 'sr/utils/api/cleaner/createJobtype';
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { CleanerComponentProps } from './CleanerComponentProps';
import { getJobType } from "sr/utils/api/cleaner/getJobType";
import { updateJobType } from "sr/utils/api/cleaner/updateJobType";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { ButtonAuth } from "sr/helpers/ui-components/ButtonAuth";
import { ReactSelect } from "sr/helpers/ui-components/ReactSelect";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { getJobTypeCategory } from "sr/utils/api/getJobTypeCategory";
const JobType: ForwardRefRenderFunction<any, CleanerComponentProps> = (
  { HandleStepper = null, page = '', type = '', dataAddedHandle=null, id='' },
  ref
) => {
  const [AddMoreClicked, setAddMoreClicked] = useState(false)
  const [loader, setLoader] = useState(false)
    const intl = useIntl()
    const [buttonLoader, setButtonLoader] = useState(false)
    const [userData, setUserData] = useState(null)
    const [jobType, setJobType] = useState<[]>()
    const [rate, setRate] = useState<string>()
    const [information, setInformation] = useState<string>()
    useEffect(()=> {
      const getdetails = getUserDetails()
      setUserData(getdetails)
    },[])
    const initialValues = {
      cleaner_id:userData?.user_id,
        job_type:jobType,
        rate:rate,
        additional_information:information,
    }
    const initialValuesPut = {
      cleaner_id:userData?.user_id,
        job_type:jobType,
        rate:rate,
        additional_information:information,
        id:id
    }
    useEffect(()=> {
      const fetchData = async () => {
        setLoader(true)
        try {
          const result = await getJobType(undefined,id)
          if(result.success===true){

            setJobType(result?.data?.job_type)
            console.log(result?.data?.job_type);
            
            setRate(result?.data?.rate)
            setInformation(result?.data?.additional_information)
            setLoader(false)
          }
        }
        catch(e:any){
          setLoader(false)
          
        }
      }
      if(page === 'popup' && type==='put'){
      fetchData()
      }
      
    },[id, page, type])
    const {handleSubmit, errors, values, submitForm } = useFormik({
      initialValues:page === 'popup' && type==='put' ? initialValuesPut:initialValues,
      enableReinitialize:true,
      onSubmit:async (values, helpers)=> {
        try {
        setButtonLoader(true)
        let result;
        if(page === 'popup' && type==='put'){
        result = await updateJobType(values)
        }
        else {
          result = await createJobtype(values)
        }
        if(result?.success===true) {
          setButtonLoader(false)
          if(page === 'popup' ){
            dataAddedHandle(true)
            }
          if(AddMoreClicked) {
            console.log('addmore');
            
            setJobType([])
            setRate('')
            setInformation('')
            setAddMoreClicked(false)
          }
          else {
            if (HandleStepper !== null) {
              HandleStepper(4);
            }
          
          }
        }
        else {
          setLoader(false)
          setButtonLoader(false)
        }
      }
      catch(e){
        setLoader(false)
        setButtonLoader(false)
      }
      },
      validationSchema:jobtypeSchema
    })
    React.useImperativeHandle(ref, () => ({
      submitForm,
    }));
    const [jobTypes, setJobTypes] = useState([])
            useEffect(()=>{
              const fetchJobType = async ()=>{
        try {
        const res = await getJobTypeCategory()
        if(res?.success===true){
          setJobTypes(res?.data)
        }
        }
        catch(e:any){
        
        }
              }
              fetchJobType()
            },[])
            const options = jobTypes?.map((item) => ({
              value: item?.value, // Assuming `value` exists
              label: <FormattedMessage id={item?.label} />,
            }));

    return (
        <ContentWithSpinner loader={loader}>
          {buttonLoader && <LoaderFullScreen />}
            <form className='mt-5' onSubmit={handleSubmit}>
                <ReactSelect label={intl.formatMessage({
                    id:'CLEANER.PROFILE.JOBTYPE.TITLE',
                    defaultMessage:'Job Type'
                })} 
                type="text"
                onChange={(selectedOptions) => setJobType(selectedOptions?.value)}

                placeholder={intl.formatMessage({
                    id:'CLEANER.PROFILE.JOBTYPE.INPUT.PLACEHOLDER',
                    defaultMessage:'Enter Job Type'
                })}
                error={errors.job_type}
                value={jobType}
                options={options}
               />
               

<Input
onChange={(e:any)=> setRate(e.target.value)}
error={errors.rate}
value={values.rate}
  name="rate"
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.JOBTYPE.RATE',
    defaultMessage: 'Hourly Rate',
  })}
  type="number"
  placeholder="$"
/>

                <Input
                value={information}
                error={errors.additional_information}
                label={intl.formatMessage({
                    id:'CLEANER.PROFILE.JOBTYPE.ADDITIONALINFORMATION',
                    defaultMessage:'Additional Information'
                }) }
                type="text" placeholder={intl.formatMessage({
                    id:'CLEANER.PROFILE.JOBTYPE.ADDITIONALINFORMATION.PLACEHOLDER',
                    defaultMessage:'Enter additional information'
                }) }
                name="additional_information"
                onChange={(e:any)=> setInformation(e.target.value)}
                ></Input>
            <div className='mt-5 flex space-x-5'>
  {page === 'popup' && (type === 'add'|| type==='put') ? '':(<>
    
                    <ButtonAuth loader={buttonLoader}
                      ><FormattedMessage id="REGISTER.BUTTON.SAVEANDCONTINUE" defaultMessage="Save and Continue" />
                    </ButtonAuth>
                   
                    <ButtonAuth onClick={()=> setAddMoreClicked(true)} loader={buttonLoader}
                                >Add More</ButtonAuth>
                
                <div className="rounded-full text-center
                 bg-[#ff39b3] font-bold
                 p-2
                 text-white
                 w-44
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy" onClick={()=> HandleStepper(4)}>Skip</div>

  </>)
  }
                  </div>
            </form>
            </ContentWithSpinner>
    )
}
export default forwardRef(JobType);