import React from 'react'
export const TopBanner = (props) => {
  return (
    <div className='flex bg-[#FAF9F8] justify-between items-center'>
            <h1 className='text-2xl	font-bold px-8'>{props.text}</h1>
            <div className='h-48'>
            <img className='h-full w-full' src={props.img}></img>
            </div>
        </div>
  )
}
