import React, {forwardRef, ForwardRefRenderFunction, useEffect, useState} from 'react'
import { Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { useFormik } from 'formik'
import { tasklistSchema } from 'sr/utils/schemas/individual/tasklist/tasklistSchema'
import { createTasklist } from 'sr/utils/api/individual/tasklist/createTasklist'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getTasklist } from 'sr/utils/api/individual/tasklist/getTasklist'
import { updateTasklist } from 'sr/utils/api/individual/tasklist/updateTasklist'

const ChecklistForm: ForwardRefRenderFunction<any, any> = ({handleAdded=null}, ref) => {
  const intl = useIntl()
    const [checklistType, setChecklistType] = useState('')
    const [subtype, setsubtype] = useState('')
    const [tasklist, setTasklist] = useState('')
    const [userId, setUserId] = useState<string>()
    const [loader, setLoader] = useState(false)
    const [checklistId, setchecklistId] = useState<string>()
    const params = useParams()
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserId(user_id)
    },[])
    useEffect(()=> {
      const {id} = params
      setchecklistId(id)
},[params])
const fetchTasklisk = async (checklistId)=> {
  setLoader(true)
  const params = new URLSearchParams()
  params.append('id', checklistId)
  try {
      const res = await getTasklist(params)
      if(res?.success===true) {
        setChecklistType(res?.data?.type)
        setTasklist(res?.data?.name)
        setsubtype(res?.data?.sub_type)
        setLoader(false)
      }
      else {
        setLoader(false)
      }
  }
  catch(e:any) {
setLoader(false)
  }
}
useEffect(()=> {
if(checklistId) {
  fetchTasklisk(checklistId)
}
},[checklistId])
const navigate = useNavigate()
const location = useLocation()
    const InitialValues = {
      type:checklistType,
      sub_type:subtype,
      name:tasklist,
      individual_id:userId
    }
    const {errors, submitForm} = useFormik({
          initialValues:checklistId ? {id:checklistId, ...InitialValues}: InitialValues,
          validationSchema:tasklistSchema,
          enableReinitialize:true,
          onSubmit:async (values)=> {
                setLoader(true)
                let res 
                if(checklistId){
                  res = await updateTasklist(values)
                }
                else {
                res = await createTasklist(values)
                }
                if(res?.success===true) {
                      handleAdded(true)
                      if(checklistId) {
                        SuccessMessage('API.SUCCESS.INDIVIDUAL.TASKLIST.UPDATE')
                      }
                      if(!checklistId){
                        navigate(`${location.pathname}/${res?.data?.id}`) 
                        }
                }
          }
    })
    React.useImperativeHandle(ref, () => ({
      submitForm,
    }));
  return (
    <>
    {loader && <LoaderFullScreen />}
    <Input error={errors.name} onChange={(e)=> setTasklist(e.target.value)} value={tasklist} label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLIST.TITLE" defaultMessage="Tasklist Name" />}
                        placeholder={intl.formatMessage({id:'INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLIST.PLACEHOLDER', defaultMessage:'Enter Tasklist Name'})}
                        type={'text'}
                        inputClass = 'border border-zinc-800 rounded-lg h-11'>
                    </Input>
    <DropDown error={errors.type} dropdowndiv='text-gray-700 mt-10 mb-10' labelMargin="mb-1" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLISTTYPE.TITLE" defaultMessage="Tasklist Type" />} value={checklistType} onChange={(e) => setChecklistType(e.target.value)} className='border border-zinc-800 rounded-lg h-11'>
                        <option value=''><FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.TASKLISTTTYPE.SELECT" defaultMessage="Select Tasklist Type" /></option>
                        <option value='Daily'>Daily</option>
                        <option value='Weekly'>Weekly</option>
                    </DropDown>
                  
                    <DropDown error={errors.sub_type} dropdowndiv='text-gray-700 mt-10 mb-10' labelMargin="mb-1" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.SUBTYPES.TITLE" defaultMessage="Sub Types" />} value={subtype} onChange={(e) => setsubtype(e.target.value)} className='border border-zinc-800 rounded-lg h-11'>
                        <option value=''>{<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.INPUT.SUBTYPES.PLACEHOLDER" defaultMessage="Select Sub Types" />}</option>
                        <option value='Hospital'>Hospital</option>
                        <option value='Office'>Office</option>
                    </DropDown>
                    
                    </>
  )
}
export default forwardRef(ChecklistForm)