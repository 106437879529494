import React, { useEffect, useRef, useState } from "react";
import { TabData } from "sr/i18n/messages/setting";
interface propsType {
    HandleActiveTab: (value: string) => void;
    activeTab: string;
    tabCount?: Record<string, number>; 
}
export const ApplicationTab = (props:propsType) => {
  const [Checkbtn, setCheckbtn] = useState("");
  const isFirstRender = useRef(true);
  const [isDragging, setisDragging] = useState(false);
  const [prevTouchX, setprevTouchX] = useState();
  const refs = useRef(null);
  useEffect(() => {
   if (isFirstRender.current) {
       isFirstRender.current = false;
       return; // Skip the effect on the initial render
   }

   refs.current.scrollLeft += Checkbtn === "left" ? -350 : 350;
}, [Checkbtn]);
  return (
    <div className='flex-1 flex space-x-8'>
    <svg onClick={() => setCheckbtn("left")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
     <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
   </svg>
   <div  ref={refs} className='flex-1 flex space-x-10 overflow-hidden'>
     { TabData?.map((data, index)=> (
       <span key={index} onClick={()=> props?.HandleActiveTab(data.value)} className={`${props?.activeTab === data.value ? "text-[#2557A7] border-b-2 border-[#2557A7]":""} hover:text-[#2557A7] font-satoshiBold text-base inline-flex flex-shrink-0 cursor-pointer pb-1`}>{data.tabname} ({(props?.tabCount && props?.tabCount[data.value]) ||0})</span>
       )) }
   </div>
   <svg onClick={() => setCheckbtn("right")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
     <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
   </svg>
   
    </div>
  );
};
