import { differenceInYears } from 'date-fns';
import * as Yup from 'yup'

const cleanerRegisterSchema = Yup.object({
    username: Yup.string()
    .required('INPUT.VALIDATION.USERNAME')
    .min(3, 'INPUT.VALIDATION.USERNAME.MIN.CHARACTER')
    .max(20, 'INPUT.VALIDATION.USERNAME.MAX.CHARACTER')
    .matches(
      /^[a-zA-Z0-9_]+$/, 
      'INPUT.VALIDATION.USERNAME.MATCHES.CHARACTER'
    ),
    mobile_number:Yup.string().required("INPUT.VALIDATION.MOBILENUMBER"),
    password:Yup.string().required('INPUT.VALIDATION.PASSWORD').min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER'),
    first_name:Yup.string().required('INPUT.VALIDATION.FIRSTNAME'),
    last_name:Yup.string().required('INPUT.VALIDATION.LASTNAME'),
    email:Yup.string().email().required('INPUT.VALIDATION.EMAIL'),
    date_of_birth: Yup.string().required('INPUT.VALIDATION.DATEOFBIRTH').test('DOB', 'INPUT.VALIDATION.DATEOFBIRTH.AGE', value => {
      return differenceInYears(new Date(), new Date(value)) >= 18;
    })


})
export default cleanerRegisterSchema;