import React, {useState,useEffect, ForwardRefRenderFunction, forwardRef} from 'react'
import { Checkbox } from "sr/helpers";
import { intentType } from "sr/i18n/messages/setting";
import { FormattedMessage } from "react-intl";
import CheckBoxHandle from "sr/helpers/functions/CheckBoxHandle";
import * as Yup from 'yup'

interface data {
  intent: Array<string>;
}
const ChangeIntent: ForwardRefRenderFunction<any, any> = (props, ref) => {
  const [intent, setIntent] = useState([])
  const [errors, setErrors] = useState<any>({});

  useEffect(()=> {
    const defaultSelected = props?.data?.intent;
    setIntent(defaultSelected);
  },[props?.data]);

  useEffect(()=> {
    props?.HandleData({
            intent: intent
          })
    if(Object.keys(errors).length !== 0 ){
      validateData();
    }
  },[intent]);

  const CompanyNameChange = Yup.object({
    intent: Yup.array().min(1, 'Please select business type'),
  });

  const validateData = async () =>  {
    const data:data = {intent: intent}
    let isValid: boolean;
    try {
      await CompanyNameChange.validate(data, { abortEarly: false });
      setErrors({}); // Reset errors if validation is successful
      props?.HandleData(data);
      isValid= true;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      isValid= false;
    }
    return isValid;
  };

  React.useImperativeHandle(ref, () => ({
    validateData
  }));

  return (<>
  <div className="mt-10 mb-1 text-gray-700 text-sm "><FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.INTENT" defaultMessage="Select Intent"/>
  </div>
  <div className="flex justify-start space-x-3">
    {intentType.map((data, index)=> (
  <Checkbox labelClass='font-normal' key={index} checked={intent.includes(data.value)}  label={data.label} id={data.value} name={data.value} value={data.value} onChange={(e)=> CheckBoxHandle(e, intent, setIntent)} />
  ))}  
  </div>
    {errors.intent && (
      <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{errors.intent as React.ReactNode}</p>
    )}
  </>);
};

export default forwardRef(ChangeIntent);