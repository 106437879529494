import React from 'react'
import { Input } from 'sr/helpers'
export const PasswordChange = () => {
  return (
    <>
    <Input type='password' InputDivClass="mt-0" inputClass = "border border-zinc-800 rounded-lg h-11" label="Current password" value=""></Input>
    <Input type='password' InputDivClass="mt-5" inputClass = "border border-zinc-800 rounded-lg h-11" label="New password" value=""></Input>
    </>
  )
}
