import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const addBasicDetails = async (postData:any) => {
  try {
    return await post<ApiResponse<[]>>('/job/', postData).then((res)=> {
        if(res.success === true){
            return res
        }
        else {
            return null
        }
    })
  }
  catch(e:any) {
    HandleApiError(e);
            return null
  }
};
