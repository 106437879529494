import React, { useEffect, useState } from "react";
import { Checkbox } from "sr/helpers";
import { SecurityCard } from "./SecurityCard";

export const SecuritySetting = ({item, permission, setPermission}) => {
    const [showSecurity, setShowSecurity] = useState(false)
  return (<>
    <div onClick={(e)=> setShowSecurity(!showSecurity)} className={`flex cursor-pointer justify-between animate-fade duration-1000 items-center mb-3 py-3 border-b border-neutral-200 mx-10`}>
    <div>
        <h2 className='font-satoshiBold'>{item?.title}</h2>
        <div>{item?.description}</div>
    </div>
</div>
<div className={`animate-fade duration-1000 overflow-hidden ${showSecurity ? 'transition-maxHeight max-h-[90rem] ease-ease-vss':'max-h-0 overflow-hidden '}`}>
{item?.permission?.map((data, index)=> (
<SecurityCard setPermission={setPermission} permission={permission} key={index} data={data} parentTitle={item?.title}/>
))}
</div>
</>);
};
