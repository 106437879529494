import React from 'react';
import { Link } from 'react-router-dom';

const EmailLink = ({ email, subject, body }) => {
  const composeEmail = () => {
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Link className='text-[#2256a9]' to="" onClick={composeEmail}>
      {email}
    </Link>
  );
};

export default EmailLink;
