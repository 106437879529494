import { Empty } from "app/pages/company/candidates/component/Empty";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TableRow } from "./history/TableRow";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { Button } from "sr/helpers/ui-components/Button";
import { Filter } from "app/pages/company/work-order/component/Filter";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getTransaction } from "sr/utils/api/wallet/getTransaction";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { TransactionDateFilter } from "app/pages/component/TransactionDateFilter";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { Checkbox } from "sr/helpers/ui-components/CheckBox";
import CheckBoxHandle from "sr/helpers/functions/CheckBoxHandle";
import convertToISOFormat from "sr/helpers/functions/convertToISOFormat";
import { Pagination } from "app/pages/component/Pagination";

export const History = () => {
    const [cleanerId, setCleanerId] = useState()
        const [data, setData] = useState(null)
        const [loader, setLoader] = useState(true)
        const [from, setFrom] = useState(null)
        const [to, setTo] = useState(null)
        const [status, setStatus] = useState(['credited', 'debited'])
        const [page, setPage] = useState(1)
        const [limit, setLimit] = useState(10)
        const [totalPage, settotalPage] = useState<number>(1);
            
        
    useEffect(()=>{
        const {user_id} = getUserDetails()
        setCleanerId(user_id)
    },[])
  
  

    const intl = useIntl()
    const fetchTransaction = async ()=> {
        setLoader(true)
            const queryParams = new URLSearchParams()
            const filterarr = []
        if (status?.includes('credited')){
            filterarr?.push({"to":cleanerId})   
        } 
        if (status?.includes('debited')){
            filterarr?.push({"from":cleanerId})
        }
            const filter = {"$or":[{"from":cleanerId},{"to":cleanerId}]}
            queryParams.append('filter', JSON.stringify(filter))
            if (from && to) queryParams?.append('createdAt>', convertToISOFormat(from))  
                if (from && to) queryParams?.append('createdAt<', convertToISOFormat(to))  
    
                    const res = await getTransaction(queryParams)
                    if(res?.success===true) {
                        setLoader(false)
                        setData(res?.data)
                        settotalPage(Math.ceil(res?.pagination.total / limit));
                    }
                    else {
                        setLoader(true)
                    }
        }
            useEffect(()=> {
                    if(cleanerId) {
        fetchTransaction()
                    }
            },[cleanerId,page, status])
  return (

      <ContentWithSpinner loader={loader} >
  
    <div className="flex space-x-3 justify-normal mb-5">
   <TransactionDateFilter setFrom={setFrom} setTo={setTo}></TransactionDateFilter>
   <Filter name={<FormattedMessage id="MYJOB.FILTER.DROPDOWN.STATUS" defaultMessage='Status' /> } label={<FormattedMessage id="MYJOB.FILTER.DROPDOWN.STATUS" defaultMessage='Status' /> }>
      <Checkbox id='credited' name='credited' onChange={(e)=> CheckBoxHandle(e, status, setStatus)} checked={status?.includes('credited')} value='credited' label={'Credited'}></Checkbox>
      <Checkbox id='debited' name='debited' onChange={(e)=> CheckBoxHandle(e, status, setStatus)} checked={status?.includes('debited')} value='debited' label={'Debited'}></Checkbox>

   </Filter>
    </div>
    <div className="overflow-x-auto mt-4 rounded-[20px] border border-neutral-200">
 <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
            <th scope="col" className="px-6 py-4 text-base ">
  Date
</th>
              
            <th scope="col" className="px-6 py-4 text-base ">
 Activity
</th>
<th scope="col" className="px-6 py-4 text-base ">
  Description
</th>

<th scope="col" className="px-6 py-4 text-base ">
 Amount
</th>

            </tr>
        </thead>
        <tbody>
            {data?.map((item)=> (

        <TableRow item={item}/>
  )) }
          
        </tbody>
    </table>
           <Pagination page={page} totalPage={totalPage} setPage={setPage} />
      
    </div>
    </ContentWithSpinner>
);
};
