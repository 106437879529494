import { Input } from 'sr/helpers';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { ReactDatePicker } from 'sr/helpers/ui-components/ReactDatePicker';
import { useFormik } from 'formik';
import { trainingSchema } from 'sr/utils/schemas/cleaner/trainingSchema';
import React, { useState, forwardRef, ForwardRefRenderFunction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'sr/redux/reducers';
import { createTraning } from 'sr/utils/api/cleaner/createTraning';
import { getUserDetails } from 'sr/helpers/functions/getUserDetails';
import { CleanerComponentProps } from './CleanerComponentProps';
import { getTraining } from 'sr/utils/api/cleaner/getTraining';
import { DateFormat } from 'sr/helpers/functions/DateFormat';
import { parse } from 'date-fns';  
import { updateTraining } from 'sr/utils/api/cleaner/updateTraining';
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth';
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen';
const CleanerTraining: ForwardRefRenderFunction<any, CleanerComponentProps> = (
  { HandleStepper = null, page = '', type = '', dataAddedHandle=null, id='' },
  ref
) => {
  const registeredData = useSelector((state: RootState) => state.registerCleaner);
  const [AddMoreClicked,setAddMoreClicked] = useState(false)
  const [dateAttended, setDateAttended] = useState(new Date())
  const [trainigName, settrainingName] = useState("")
  const [description, setDescription] = useState("")
  const [loader, setLoader] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
    const intl = useIntl()
    const [userData, setUserData] = useState(null)
  useEffect(()=> {
    const getdetails = getUserDetails()
    setUserData(getdetails)
  },[])
  const initialValues = {
    cleaner_id:userData?.user_id,
      training:trainigName,
      date_attended:dateAttended,
      description:description
    }
    const initialValuesPut = {
      cleaner_id:userData?.user_id,
        training:trainigName,
        date_attended:dateAttended,
        description:description,
        id:id
      }

    useEffect(()=> {
      const fetchData = async () => {
        setLoader(true)
        try {
          const trainingData = await getTraining(undefined,id)
          if(trainingData.success===true){
          settrainingName(trainingData?.data?.training)
          setDateAttended(trainingData?.data?.date_attended);
          setDescription(trainingData?.data?.description)
            setLoader(false)
          }
        }
        catch(e:any){
          console.log(e);
          setLoader(false)
          
        }
      }
      if(page === 'popup' && type==='put'){
      fetchData()
      }
    },[id])
    const {handleSubmit, errors, submitForm, setFieldValue} = useFormik({
      initialValues:page === 'popup' && type==='put' ? initialValuesPut:initialValues,
      enableReinitialize:true,
      onSubmit: async (values, helpers)=> {
        setButtonLoader(true)
let result
          if(page === 'popup' && type==='put'){
            setFieldValue('id', id)
            result = await updateTraining(values)
          }
          else {
          result = await createTraning(values)
          }
if(result) {
  setButtonLoader(false)
  if(page === 'popup' ){
    dataAddedHandle(true)
    }
  if(AddMoreClicked) {
    helpers.resetForm()
    setDateAttended(null)
    settrainingName("")
    setDescription("")
    setAddMoreClicked(false)
  }
  else {
    if (HandleStepper !== null) {
      HandleStepper(5);
    }
  
  }
}
      },
      validationSchema:trainingSchema
    })
    React.useImperativeHandle(ref, () => ({
      submitForm,
    }));
    return (
      <ContentWithSpinner loader={loader}>
        {buttonLoader && <LoaderFullScreen />}
            <form className='mt-5' onSubmit={handleSubmit}>
            <Input
    value={trainigName}        
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.TRAINING.INPUT.TRAINING.TITLE',
    defaultMessage: 'Training Name',
  })}
  type="text"
  onChange={(e)=> settrainingName(e.target.value)}
  name="training"
  placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.TRAINING.INPUT.TRAINING.PLACEHOLDER',
    defaultMessage: 'Enter training name',
  })}
  error={errors.training}
/>

{/* <Input
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.TRAINING.DATE.ATTENDED',
    defaultMessage: 'Date Attended',
  })}
  type="date"
/> */}
<ReactDatePicker 
onChange={(date)=> setDateAttended(date)}
selected={dateAttended}
error={errors.date_attended}
label={intl.formatMessage({
    id: 'CLEANER.PROFILE.TRAINING.DATE.ATTENDED',
    defaultMessage: 'Date Attended',
    
  })}></ReactDatePicker>

<Input
value={description}
onChange = {(e)=> setDescription(e.target.value)}
error={errors.description}
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.TRAINING.INPUT.DESCRIPTION.TITLE',
    defaultMessage: 'Description',
  })}
  type="text"
  placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.TRAINING.INPUT.DECRIPTION.PLACEHOLDER',
    defaultMessage: 'Enter Description',
  })}
/>
<div className='mt-5 flex space-x-5'>
  {page === 'popup' && type === 'add'|| type==='put' ? '':(<>
    
                    <ButtonAuth loader={buttonLoader}
                      ><FormattedMessage id="REGISTER.BUTTON.SAVEANDCONTINUE" defaultMessage="Save and Continue" />
                    </ButtonAuth>
                   
                    <ButtonAuth loader={buttonLoader} onClick={()=> setAddMoreClicked(true)} 
                                >Add More</ButtonAuth>
                
                    <div className='rounded-full text-center
                 bg-[#ff39b3] font-bold
                 p-2
                 text-white
                 w-44
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy'
                                onClick={()=> HandleStepper(5)}>Skip</div>
  </>)
  }
                  </div>
            </form>
       </ContentWithSpinner>
    )
}
export default forwardRef(CleanerTraining);