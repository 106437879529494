import { ReactNode } from "react";
interface LoaderProps {
    loading: boolean;
    children: ReactNode;
    content:ReactNode
  }
  
const ContentWithLoader = ({ loading, children, content }) => {
    return loading ? (
        <>{content}</>
    ) : (
      <>{children}</>
    );
  };
  export default ContentWithLoader;
  