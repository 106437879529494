import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Input } from "sr/helpers";
import { WhiteBtn } from "../../work-order/component/WhiteBtn";
export const BillingSummary = () => {
    const [transactionTab, setTransactionTab] = useState("All")
  return (
    <div className="grid grid-cols-12 gap-8">
    <div className="col-span-9">
    <div className="bg-white rounded-lg p-5 mb-5">
    <div className="font-satoshiBold text-lg">Payments Due</div>
    <p className="text-xs">Your payment method will be charged monthly and when the amount due reaches the charge threshold below.</p>
    <p className="py-3">No payments due</p>
    <div className="flex space-x-4">
    <div className="">
    <div className="font-satoshiBold">Payment method<FontAwesomeIcon icon={faCircleExclamation} className='pl-1 pr-3' /></div>
    <div className="flex space-x-4 py-3 items-center">
    <p>None</p>
    <Link className="text-[#2557A7] font-satoshiBold" to={'#'}>Add</Link>
    </div>
    </div>
    <div className="">
    <div className="font-satoshiBold">Currency<FontAwesomeIcon icon={faCircleExclamation} className='pl-1 pr-3' /></div>
    <div className="flex space-x-4 py-3">
        ($) Dollar
    </div>
    </div>
    <div className="">
    <div className="font-satoshiBold">Charge threshold<FontAwesomeIcon icon={faCircleExclamation} className='pl-1 pr-3' /></div>
    <div className="flex space-x-4 py-3">
    <div>INR 4,000</div>
    </div>
    </div>
    </div>
    </div>
    <div className="bg-white rounded-lg p-5 mb-5">
        <div className="flex justify-between items-center">
    <div className="font-satoshiBold text-lg">Transaction history</div>
    <div className="flex space-x-2 items-center">
    <Input inputClass="rounded-lg" InputDivClass="mt-0" type="date"></Input>
    <p>to</p>
    <Input inputClass="rounded-lg" InputDivClass="mt-0" type="date"></Input>
    <WhiteBtn title="Update" mainClass="px-3 py-1.5"></WhiteBtn>
    </div>
    </div>
    <div className='flex items-center my-5'>
<div onClick={()=> setTransactionTab("All")} className={`rounded-l-lg font-bold px-5 py-1.5 border border-neutral-200  cursor-pointer ${transactionTab==="All" ? 'bg-[#2557A7] text-white':'text-[#2557A7] bg-white'}`}>
All
</div>
<div onClick={()=> setTransactionTab("Payments")} className={`font-satoshiBold px-5 py-1.5 cursor-pointer  border border-neutral-200 ${transactionTab==="Payments" ? 'bg-[#2557A7] text-white':'text-[#2557A7] bg-white'}`}>
Payments
</div>
<div onClick={()=> setTransactionTab("Invoices")} className={`rounded-r-lg font-satoshiBold px-5 py-1.5 cursor-pointer border border-neutral-200 ${transactionTab==="Invoices" ? 'bg-[#2557A7] text-white':'text-[#2557A7] bg-white'} `}>
Invoices
</div>
    </div>
    <p className="py-3 text-xs">No transactions</p>
    </div>
    </div>
    <div className="col-span-3 rounded-lg">
        <div className="bg-white p-4 rounded-lg">
        <div className="font-satoshiBold text-lg">Payments Due</div>
    <p className="text-xs py-3">No payments to date.</p>
        </div>
    </div>
  </div>
  );
};
