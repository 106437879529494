import React, {useState} from "react";
import { ModelWrapper } from "../component/ModelWrapper";
import { MainWrapper } from "../component/MainWrapper";
import { BillingSummary } from "./component/BillingSummary";
import { BillingInformation } from "./component/BillingInformation";

export const BillingAndInvoices = () => {
    const [activeTab, setActiveTab] = useState("Billing summary")
  return (
    <MainWrapper>
  <div className="bg-white my-1 border-b border-neutral-600 flex items-center space-x-3">
    <div onClick={()=> setActiveTab("Billing summary")} className={`cursor-pointer text-black p-2.5 ${activeTab === "Billing summary" ? "border-b-2 border-neutral-600":""}`}>Billing summary</div>
    <div onClick={()=> setActiveTab("Billing information")} className={`cursor-pointer text-black p-2.5 ${activeTab === "Billing information" ? "border-b-2 border-neutral-600":""}`}>Billing information</div>
  </div>
  <div className="my-3">
    {activeTab === 'Billing summary' && 
    <BillingSummary />
    }
    {
        activeTab === 'Billing information' &&
        <BillingInformation />
     }
  </div>
  </MainWrapper>
  );
};
