import React, {useEffect, useState} from 'react'
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox'
import { benifitdata } from '../Jsonalldata'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { FormattedMessage } from 'react-intl'
export const BenifitsChange = (props) => {
    const [benifit, setBenifit] = useState<any[]>([])
    useEffect(()=> {
      setBenifit(props?.data?.benefits)
    },[props?.data])

    useEffect(()=> {
      const data = {
        benefits:benifit
      }
        props?.HandleDataProps(data)
    },[benifit])
  return (
    <div className='pb-4'>
    <p className='pt-8 text-base '><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.BENIFITS" defaultMessage="Benefits" /></p>
<div className="flex flex-wrap space-x-4">
{benifitdata?.map((data, index) => (
<CheckBox
value={data.name.props.defaultMessage}
onChange={(e) => CheckBoxHandle(e, benifit, setBenifit)}
checked = {benifit.includes(data.name.props.defaultMessage)} />
))}
</div>

     </div>     
  )
}
