import React from "react";
import { get } from "../axios";
import ApiResponse from "sr/models/ApiResponse";

export const getJobTypeCategory = async () => {
    try {
  return await get<ApiResponse<[]>>('/individual/job/category').then((res)=>{
    if(res?.success===true){
        return res
    }
    else {
        return null
    }
  })
}
catch(e){
    
}
};
