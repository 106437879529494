import React from "react";
import { Input } from "sr/helpers";
import { WhiteBtn } from "../../work-order/component/WhiteBtn";

export const BillingContactForm = (props) => {
  return (
  <div>
    <div className="text-lg ">Billing contacts</div>
    <p className="text-xs">Edits made only impact billing and will not be applied to other areas of your Indeed account. All fields marked with * are required.</p>
    <form className="w-full flex flex-wrap mt-3">
    <Input type="text" label="Name" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5"></Input>
    <Input type="number" label="Phone" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5"></Input>
    <Input type="text" label="Company" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5"></Input>
    <Input type="text" label="Fax" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5"></Input>
    <Input type="text" label="Billing email" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5"></Input>

    </form>
    <div className="flex space-x-5 items-center">
    <WhiteBtn mainClass="px-3 py-1.5" title="Update"></WhiteBtn>
    <div onClick={props.onClick} className="text-[#0078ae]  cursor-pointer">Cancel</div>
    </div>
    </div>
    );
};
