import React, {useState, useEffect} from "react";
import { Button } from "sr/helpers";

export const Rating = ({setReviewData}) => {
   const [internalRating, setInternalRating] = useState(0);
   const [internalFeedback, setInternalFeedBack] = useState<string>('')
   const [externalRating, setExternalRating] = useState(0);
   const [externalFeedback, setExternalFeedBack] = useState<string>('')
   const review = {
    internalRating:internalRating,
    internalFeedback:internalFeedback,
    externalRating:externalRating,
    externalFeedback:externalFeedback
  }
   useEffect(()=> {
    setReviewData(review)
   },[internalRating, internalRating,externalRating, externalFeedback])
    
  return (
    <div className="py-3 lg:container mx-auto lg:max-w-3xl lg:mt-5 mt-10 px-4 lg:px-0">
    <div className="bg-white text-center py-10 border border-neutral-200 rounded-[10px]">
    <p className=" pb-3">How's your overrall experience with worker?</p>
    <div>
      <div className=" pb-3">Internal Feedback</div>
    <div className=" flex gap-3 justify-center item-center pb-5">
      { [{}, {}, {}, {}].map((item, index)=> (
        <svg key={index} onClick={()=> setInternalRating(index+1)} xmlns="http://www.w3.org/2000/svg" fill={index + 1 <= internalRating ? 'currentColor' : 'none'} viewBox="0 0 24 24" strokeWidth={1.5} stroke={index + 1 <= internalRating ? 'none' : 'currentColor'}  className={`w-6 h-6 ${index+1 <= internalRating ? 'svg-inline--fa fa-star text-yellow-500':""}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>
    ))}
    </div>
    <div className="max-w-2xl mx-auto pb-5">
    <textarea onChange={(e)=> setInternalFeedBack(e.target.value)} id="Description" rows={2} className="p-2.5 text-sm w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#0078ae] focus:shadow-inputshadow outline-0" placeholder="Additional feedback, if any?"></textarea>
    </div>
    </div>
    <div>
    <div className=" pb-3">External Feedback</div>
    <div className=" flex gap-3 justify-center item-center pb-5">
      { [{}, {}, {}, {}].map((item, index)=> (
        <svg key={index} onClick={()=> setExternalRating(index+1)} xmlns="http://www.w3.org/2000/svg" fill={index + 1 <= externalRating ? 'currentColor' : 'none'} viewBox="0 0 24 24" strokeWidth={1.5} stroke={index + 1 <= externalRating ? 'none' : 'currentColor'}  className={`w-6 h-6 ${index+1 <= externalRating ? 'svg-inline--fa fa-star text-yellow-500':""}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>
    ))}
    </div>
    <div className="max-w-2xl mx-auto pb-5">
    <textarea onChange={(e)=> setExternalFeedBack(e.target.value)} id="Description" rows={2} className="p-2.5 text-sm w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#0078ae] focus:shadow-inputshadow outline-0" placeholder="Additional feedback, if any?"></textarea>
    </div>
    </div>
    <div className="flex justify-center">
    <Button text={'Submit'} className={'bg-[#ff39b3] font-bold text-white capitalize px-14 py-2.5'} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
    </div>
    </div>
    
       </div>
  );
};
