import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getChecklist = async (params) => {
    try {
        return await get<ApiResponse<[]>>(`/customer/checklist?${params}`).then((res)=> {
          if(res.success===true) {
              return res
          }
          else {
              return null
          }
        })
      }
      catch(e:any) {
          HandleApiError(e)
      }
};
