import React, { useEffect } from 'react'
import { useValidationErrorHandle } from '../functions/validationErrorHandle'
function Input(props: any) {
  const validationErrorHandle = useValidationErrorHandle();
  return (
    <>
      <div className={props.InputDivClass}>
        {props.label ? (
          <label className={` ${props.labelClass} text-normal font-satoshiBold`}>
            {props.label}
          </label>
        ) : (
          ''
        )
        }
      {props.smalllabel ? (
        <p className={props.smalllabelclass}>{props.smalllabel}</p>
      ): ''}
        <div className={`relative ${props.topinputdiv} flex h-full `}>
          {props?.prefix && (
            <div className="absolute ml-2 top-1/2 transform -translate-y-1/2">{props?.prefix}</div>
          )}
          <input
            type={props.type}
            name={props.name}
            id={props.id}
            placeholder={props.placeholder}
            required={props.required}
            value={props.value}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            minLength={props.minLength}
            onChange={props.onChange}
            onBlur={props?.handleBlur}
            readOnly={props.readOnly}
       
            autoComplete="new-password"
            className={`${props.inputClass} block w-full md:px-4 sm:px-1 md:py-2 sm:py-1 sm:text-sm md:text-md text-gray-700 placeholder-gray-500 bg-white border border-gray-border rounded-2xl
                        focus:border-sky-200 focus:ring-sky-200 focus:outline-none focus:ring focus:ring-opacity-40`} 
                        disabled={props.disable}/>
        </div>
        {props.error ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(props?.error)}</p>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

Input.defaultProps = {
  InputDivClass:'mt-10',
  labelClass: 'mt-2 text-gray-700 text-sm',
}

export {Input}
