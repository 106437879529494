import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const updateAdvanceDetails = async (putData:any) => {
    try {
        return await put<ApiResponse<[]>>('/job/advanced', putData ).then((res)=> {
            if(res?.success=== true) {
                SuccessMessage("API.SUCCESS.JOBADVANCE.DETAILS.UPDATE")
                return res
            }
            else {
                toast.error(res?.error?.message)
                  return null
            }
        })
        }
        catch (e:any) {
            HandleApiError(e);
            return null   
        }

};
