import React, { useEffect, useState } from 'react'
import AuthNavComponent from '../module/auth/AuthNav';
import { Link, useParams } from 'react-router-dom';
import { Profile } from './component/CompanyDetails/Profile';
import { Overview } from './component/CompanyDetails/Overview';
import { JobsTab } from './component/CompanyDetails/JobsTab';
import Footer from '../module/Footer';
import { getCompany } from 'sr/utils/api/company/getCompany';
import { FormattedMessage } from 'react-intl';
import { getAdditionInfo } from 'sr/utils/api/company/getAdditionInfo';
import CompanyInfo from '../component/types/CompanyInfo';
import CompanyType from './component/type/CompanyType';
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs';
import Jobs from '../component/types/Job';
import { getFiles } from 'sr/utils/api/getFiles';
import { getFile } from 'sr/utils/api/getFile';
const CompanyDetails = () => {
    const [activeTab, setActiveTab] = useState('overview')
    const [data, setData] = useState<CompanyType>()
    const [companyAddInfo, setCompanyAddInfo] = useState<CompanyInfo>(null)
    const [loader, setLoader] = useState(false)
    const [jobs, setJobs] = useState<Jobs[]>(null)
    const [logo, setLogo] = useState(null)
    const params = useParams()
    const fetchCompany = async (id) => {
        setLoader(true)
        try {
            const queryParam = new URLSearchParams(); 
        if (id) queryParam.append('id', id.toString()); 
        const result = await getCompany(queryParam)
        if(result?.success===true) {
        setData(result.data)
        }
        const resultAdd = await getAdditionInfo(id, undefined)
        if(resultAdd?.success===true) {
            setCompanyAddInfo(resultAdd?.data)
        }
        const queryParams = new URLSearchParams
         if (id) queryParams?.append('company_id', id)
        const Jobsresult = await getJobs(queryParams)
        if(Jobsresult?.success===true) {
          setLoader(false)
        setJobs(Jobsresult?.data)
       }
       const params = {
        company_id:id
      }
  const profileImg = await getFiles(params)
  if(profileImg?.success===true) {
    const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
    if(filterfile && filterfile[0]?.file?.key) {
      const fileimg = await getFile(filterfile[0]?.file?.key);
      if(fileimg) {
      const newImageUrl = URL.createObjectURL(fileimg);
      setLogo(newImageUrl)
       setLoader(false)
    } }}}
catch(e) {
    setLoader(false)
}
}


    useEffect(()=> {
        const {id} = params
           if(id) {
            fetchCompany(id)
           }
    },[params])
    return (
        <>
            <AuthNavComponent isLoggedin={true} />
            <main>
                <div className='w-full bg-[rgba(239,237,255,.8)] h-24 relative'>
                    {/* <div className='text-xs absolute bottom-5 right-24 text-gray-500'>
                        Your Company? <Link className='text-[#275df5] font-bold' to="/">Claim Now</Link>
                    </div> */}
                </div>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-4 mt-10 px-4 lg:px-0'>
                <Profile data={data} loader={loader} logo={logo}/>
                <div className='flex gap-8 pb-1.5'>
                    <span onClick={(e)=> setActiveTab('overview')} className={`font-bold text-xl text-[#717b9e] px-3 cursor-pointer	 ${activeTab == 'overview' ? 'border-b-4 border-black text-black' : '' }`}><FormattedMessage id="GENERAL.TOGGLE.BUTTON.TITLE.OVERVIEW" defaultMessage="Overview" />  </span>
                    <span onClick={(e)=> setActiveTab('jobs')} className={`font-bold text-xl text-[#717b9e] px-3 cursor-pointer	 ${activeTab == 'jobs' ? 'border-b-4 border-black text-black' : ''}`}><FormattedMessage id="GENERAL.TOGGLE.BUTTON.TITLE.JOBS" defaultMessage="Jobs" /></span>
                </div>
                <div className='border-b -mt-1.5'></div>
             {activeTab == 'overview' ? (
              <Overview data={data} addInfo={companyAddInfo} loader={loader} jobs={jobs}/>
             ): null}
             {activeTab == 'jobs' ? (
              <JobsTab data={data} loader={loader} jobs={jobs}/>
             ): null}
            </div>
        </main >
        <Footer />
        </>
  )
}
export default CompanyDetails;