import React from "react";
import { createChecklist } from "sr/utils/api/company/customer/createChecklist";
import { addTasks } from "./addTasks";

export const checklistHandle = async (checklist, workorder, tasklist, managetasks) => {
    let checklistId;
    if (checklist==='Not Completed Task') {
        const payload = {
            name:workorder?.checklist_id?.name,
            type:workorder?.checklist_id?.type,
            subtype:workorder?.checklist_id?.subtype,
            company_id:workorder?.checklist_id?.company_id,
            customer_id:workorder?.checklist_id?.customer_id
}

const res = await createChecklist(payload)
if(res?.success===true){
        const taskId = (managetasks?.filter((item)=> item?.supervisor_status==='rejected'))
        const tasks = taskId?.map((item)=> item?.task_id)
          
    if(tasks?.length>0) {
        const result = await addTasks(tasks, res?.data?.id)
                if(result){
                    checklistId = res?.data?.id
                }
    }
    else {
        return null
    }
}
}
else {
    checklistId = workorder?.checklist_id?._id
}
return checklistId
}
