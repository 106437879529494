import React from 'react'

export const WhiteBtn = (props) => {
  return (
    <div onClick={props.onClick} className={`${props.mainClass ? props.mainClass : null} bg-[#ff39b3] font-bold  cursor-pointer text-white px-2 py-3  hover:border-[#0078ae] rounded-lg flex space-x-2 items-center`}>
{props.svg}
 <span className="">{props.title}</span>
 </div>
  )
}
