export const AddressDisplay = (address)=> {
    
if(address?.address_line_1?.length>20){
    if(address?.city){
        const shortAddress = address?.address_line_1?.slice(0,20)
        const lastspaceIndex =shortAddress?.lastIndexOf(' ')
        const addressreal = shortAddress?.slice(0, lastspaceIndex) 
        return `${addressreal} ${address?.city}`
    }
    else {
        const shortAddress = address?.address_line_1?.slice(0,30)
        const lastspaceIndex =shortAddress?.lastIndexOf(' ')
        const addressreal = shortAddress?.slice(0, lastspaceIndex) 
        return `${addressreal}`
    }
}
else {
    if(address?.address_line_1?.length>10){
        if(address?.address_line_2?.length>10){
        return `${address?.address_line_2} ${address?.address_line_2} ${address?.city}`
        }
        else {
            return `${address?.address_line_1} ${address?.city}`
        }
    }
    else {
        return `${address?.address_line_1} ${address?.address_line_2} ${address?.city}`
    }
}
}