import React, {useEffect, useState, useRef} from 'react'
import { SliderCard } from './SliderCard';
export const SliderContainer = (props) => {
    const isFirstRender = useRef(true);
    const [isDragging, setisDragging] = useState(false);
    const [prevTouchX, setprevTouchX] = useState();
    const [Checkbtn, setCheckbtn] = useState("");

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // Skip the effect on the initial render
        }

        refs.current.scrollLeft += Checkbtn === "left" ? -350 : 350;
    }, [Checkbtn]);
    // const [startX, setStartX] = useState(null);
    // const [scrollLeft, setScrollLeft] = useState(0);
    const refs = useRef(null);
    const handleMouseMove = (ev) => {
        if (!isDragging) return;
        ev.preventDefault();
        // const x = ev.pageX -  refs.current.offsetLeft;
        // const walk = x - startX;
        // refs.current.scrollLeft = scrollLeft - walk;
        refs.current.scrollLeft -= ev.movementX;
    };
    useEffect(() => {
        /* eslint-disable no-restricted-globals */
        if (screen.width < 768) {
            refs.current.scrollLeft = 90;
        }
        /* eslint-enable no-restricted-globals */
    }, []);
    const handleTouchMove = (ev) => {
        const touchX = ev.changedTouches[0].clientX;
        if (prevTouchX) {
            const movementX = touchX - prevTouchX;
            refs.current.scrollLeft -= movementX;
        }
        setprevTouchX(touchX);
    };
  return (
    <div className={`mb-2 dark:border-gray-50/[0.06] relative py-4 rounded-2xl ${props.bg && props.bg}`}>
    <p className='px-4 font-bold'>{props.text && props.text}</p>
                <div
                    onClick={() => setCheckbtn("left")}
                    className="h-full absolute flex items-center left-5 top-0"
                >

<div className='w-5 h-5 bg-white border border-gray-border rounded-full p-4'>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 -m-[8px]"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>
                    </div>
                </div>
                <ul
                    onMouseMove={(ev) => handleMouseMove(ev)}
                    onMouseDown={() => setisDragging(true)}
                    onMouseUp={() => setisDragging(false)}
                    onMouseLeave={() => setisDragging(false)}
                    onTouchMove={(ev) => handleTouchMove(ev)}
                    onTouchEnd={() => setisDragging(false)}
                    onTouchStart={() => setisDragging(true)}
                    className=" space-x-3 whitespace-nowrap overflow-hidden flex max-w-[750px] lg:max-w-6xl mx-auto justify-start items-center"
                    ref={refs}
                >

                    <div className="w-5" />
                    {
                       props.data.map((datam) => {
                            return <SliderCard data={datam} textstyle='text-lg lg:text-sm font-bold' slidercardstyle='w-64'/>
                        })
                    }
                    <div className="w-5" />

                </ul>
                <div
                    onClick={() => setCheckbtn("right")}
                    className="h-full absolute flex items-center  right-5 top-0"
                >
                    <div className='w-5 h-5 bg-white border border-gray-border rounded-full p-4'>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className='w-4 h-4 -m-[8px]'
                        
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                    </svg>
                    </div>
                </div>
            
            </div>
  )
}
