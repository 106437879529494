import React, { useState } from "react";
import AuthNavComponent from "../../module/auth/AuthNav";
import { Input } from "sr/helpers";
import { Link, useNavigate } from "react-router-dom";
import { FormDetails } from "sr/helpers/ui-components/FormDetails";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { sendOTP } from "sr/utils/api/sendOTP";
import { createIndividual } from "sr/utils/api/individual/createIndividual";
import { login } from "sr/utils/api/login";
import { useAuth } from "app/pages/module/auth";
import individualRegisterSchema from "sr/utils/schemas/individual/individualRegister";
import { ButtonAuth } from "sr/helpers/ui-components/ButtonAuth";
import { useFormik } from "formik";
// import cleanerRegister from "sr/utils/schemas/cleaner/cleanerRegister";

export const RegisterAsIndividual = () => {
  const [loader, setLoader] = useState(false)
  const {persistToken} = useAuth()
  const intl = useIntl();
  interface FormValues {
    username: string;
    mobile_number: string;
    password: string;
    first_name: string;
    last_name: string;
    email: string;
  }
  const navigate = useNavigate()
  
  const initialValues:FormValues = {
    username:"",
    mobile_number:"",
    password:"",
    first_name:"",
    last_name:"",
    email:"",
  }
  const {errors, handleChange, handleSubmit, setFieldValue, values} = useFormik({
    initialValues:initialValues,
    enableReinitialize:true,
    validateOnChange:false,
  onSubmit:async (values) => {
    setLoader(true)
    console.log(values);
    try {
    const result = await createIndividual(values)
    setLoader(false)
    if(result) {
      const otpresult = await sendOTP({username:result?.data?.username}
      )
      const loginresult = await login({username:values.username, password:values.password})
      if(loginresult.success===true) {
      const data = {
        userType:loginresult?.data?.user.type,
        user_id:loginresult?.data?.auth.user_id,
        jwt:loginresult?.data?.auth.jwt
      }
      localStorage.setItem('auth', JSON.stringify(data))
      persistToken()
        navigate('/verify-otp')
    } }
    else {
      setLoader(false)
    }
    }
    catch(error) {
      setLoader(false)
    }
  },
  validationSchema:individualRegisterSchema
})
  return (
    <>
      <AuthNavComponent></AuthNavComponent>
      <main>
        <div className="lg:container mx-auto lg:max-w-6xl lg:my-8 my-8 px-4 lg:px-0 lg:flex block">
          <FormDetails />
          <div className="lg:flex-1 lg:flex flex-col h-fit lg:mx-12 mx-auto lg:p-6 p-4 bg-white border border-gray-border rounded-xl">
            <h1 className="font-satoshiBold text-xl">
              <FormattedMessage
                id="ECOMMERCE.COMMON.CREATE_AN_ACCOUNT.TEXT"
                defaultMessage="Create an Account"
              />
            </h1>
            <h3 className="font-satoshi text-md">
              <FormattedMessage
                id="INDIVIDUAL.REGISTER.ACCOUNT.REGISTERAS.TEXT"
                defaultMessage="As Individual"
              />
            </h3>
            <div className="max-w-lg">
              <form onSubmit={handleSubmit}>
              <Input
              onChange={handleChange}
                    name="username"
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME",
                      defaultMessage: "Username",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME.PLACEHOLDER",
                      defaultMessage: "Enter your username",
                    })}
                    type={"text"}
                    error={errors.username}
                  ></Input>
                  <Input
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD",
                    defaultMessage: "Password",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
                    defaultMessage: "Enter your password",
                  })}
                  type={"password"}
                  name="password"
                  onChange={handleChange}
                  error={errors.password}
                ></Input>
                  <Input
                onChange={handleChange}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.MOBILENO",
                    defaultMessage: "Mobile Number",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.LASTMOBILENONAME.PLACEHOLDER",
                    defaultMessage: "Enter your Mobile Number",
                  })}
                  type={"tel"}
                  name="mobile_number"
                  error={errors.mobile_number}
                  value={InputNumberFormat(values.mobile_number)}
                ></Input>
                  <Input
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.ADDRESS",
                    defaultMessage: "Email Address",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.PLACEHOLDER",
                    defaultMessage: "Enter your email id",
                  })}
                  type={"email"}
                  name="email"
                  error={errors.email}
                  onChange={handleChange}

                ></Input>
                <Input
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME",
                    defaultMessage: "First Name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME.PLACEHOLDER",
                    defaultMessage: "Enter your first name",
                  })}
                  type={"text"}
                  onChange={handleChange}
                  name='first_name'
                  error={errors.first_name}
                ></Input>
                <Input
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.LASTNAME",
                    defaultMessage: "Last Name",
                  })}
                  onChange={handleChange}
                  error={errors.last_name}
                  name="last_name"
                  placeholder={"Enter your last name"}
                  type={"text"}
                ></Input>
              
              
                
           <ButtonAuth loader={loader}
                    onClick={() => {
                      localStorage.setItem("RegisterAs", "Individual");
                    }}>
                    <FormattedMessage
                      id="AUTH.GENERAL.CREATE.ACCOUNT.BUTTON"
                      defaultMessage="Create Account"
                    />
                  </ButtonAuth>
              </form>
              <div className="mt-10 flex justify-center">
                <FormattedMessage
                  id="AUTH.ALREADY.HAVE.ACCOUNT"
                  defaultMessage="Already have an account?"
                />
                &nbsp;&nbsp;
                <Link to="/auth" replace>
                  <a className="cursor-pointer hover:text-pink underline">
                    {" "}
                    <FormattedMessage
                      id="AUTH.SIGN_IN_HERE"
                      defaultMessage="Sign in here"
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
