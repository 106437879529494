import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
export const ModelWrapperWithButton = ({children, ...props}) => {
  const [showeffect, setShowEffect] = useState(false)
    const HandleModelClose = () => {
        setTimeout(() => {
          setShowEffect(false);
        }, 200);
        setTimeout(() => {
          props.onCheckModel(false)
        },400);
    }
    useEffect(() => {
      if (props.showModal) {
        setTimeout(() => {
          setShowEffect(true);
        }, 200);
      } else {
        setShowEffect(false);
      }
    }, [props.showModal]);

  return (
    <>
    {props.showModal ? (
        <>
          <div className={`justify-center transitioncss ${showeffect ? 'modeleffect':'popup'} items-center flex ${props?.showModal && 'overflow-x-hidden overflow-y-auto' } fixed inset-0 z-40 outline-none focus:outline-none`}
          >
            <div className="relative  my-6 max-w-8xl w-4/5 h-4/5">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props.headertitle} 
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={HandleModelClose}
                  >
                    <span className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                 {children}
                </div>
                {/*footer*/}
                {props.nofooter === true ? '' :
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={HandleModelClose}
                  >
                    <FormattedMessage id="POPUP.CLOSE.BUTTON" defaultMessage="CLOSE" />
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={props.Save}
                  >
                    {props?.savetext ? props?.savetext :
                    <FormattedMessage id="POPUP.SAVECHANGES.BUTTON" defaultMessage="SAVE CHANGES" /> 
}
                  </button>
                </div> }
              </div>
            </div>
          </div>
          {showeffect &&
          <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
}
        </>
      ) : null}
      </>
  )
}
