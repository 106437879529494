import { useFormik } from "formik";
import React, { useState, forwardRef, ForwardRefRenderFunction, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Checkbox, Input } from "sr/helpers";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { RootState } from "sr/redux/reducers";
import { createEmployment } from "sr/utils/api/cleaner/createEmployment";
import { employment } from "sr/utils/schemas/cleaner/employment";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { CleanerComponentProps } from './CleanerComponentProps'
import { getEmployment } from "sr/utils/api/cleaner/getEmployment";
import { updateEmployment } from "sr/utils/api/cleaner/updateEmployment";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { ButtonAuth } from "sr/helpers/ui-components/ButtonAuth";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";

const CleanerEmployment: ForwardRefRenderFunction<any, CleanerComponentProps> = (
  { HandleStepper = null, page = '', type = '',dataAddedHandle=null, id='' },
  ref
) => {
  const [currentlyEmployed, setCurrentlyEmployed] = useState(false);
  const [AddMoreClicked, setAddMoreClicked] = useState(false)
  const [jobTitle, setjobTitle] = useState('')
  const [companyName, setcompanyName] = useState('')
  const [companyLocation, setcompanyLocation] = useState('')
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [jobType, setJobType] = useState('')
  const [loader, setLoader] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
  const intl = useIntl();
  const [userData, setUserData] = useState(null)
  useEffect(()=> {
    const getdetails = getUserDetails()
    setUserData(getdetails)
  },[])
  const initialValue = {
    cleaner_id:userData?.user_id,
   job_title:jobTitle,
  company_name:companyName,
  company_location:companyLocation,
  working_since_start:startDate,
  working_since_end:endDate,
  currently_employed:currentlyEmployed
  }
  const initialValuePut = {
    cleaner_id:userData?.user_id,
   job_title:jobTitle,
  company_name:companyName,
  company_location:companyLocation,
  working_since_start:startDate,
  working_since_end:currentlyEmployed ? '': endDate,
  currently_employed:currentlyEmployed,
  id:id
  }

  const  {handleSubmit, errors, submitForm} = useFormik({
      initialValues:page === 'popup' && type==='put' ? initialValuePut:initialValue,
      enableReinitialize:true,
      validationSchema:employment,
      onSubmit:async (values, helpers)=> {
        setButtonLoader(true)
        console.log(values);
        let result
          if(page === 'popup' && type==='put'){
            result = await updateEmployment(values)
          }
          else {
          result = await createEmployment(values)
          }
        if(result) {
          setButtonLoader(false)
          if(page === 'popup' ){
            dataAddedHandle(true)
            }
          if(AddMoreClicked) {
              helpers.resetForm()
              setcompanyLocation('')
              setcompanyName('')
              setjobTitle('')
              setstartDate(new Date())
              setEndDate(new Date())
              setJobType('')
              setAddMoreClicked(false)
          }
          else {
            if (HandleStepper !== null) {
              HandleStepper(3);
            }
          
          }
        }
      }
      
  })
  useEffect(()=> {
    const fetchData = async () => {
      setLoader(true)
      try {
        const referenceData = await getEmployment(undefined,id)
        if(referenceData.success===true){
          setcompanyLocation(referenceData?.data?.company_location)
          setcompanyName(referenceData?.data?.company_name)
          setjobTitle(referenceData?.data?.job_title)
          setstartDate(referenceData?.data?.working_since_start)
          setEndDate(referenceData?.data?.working_since_end)
          setJobType(referenceData?.data?.job_type)
          setCurrentlyEmployed(referenceData?.data?.currently_employed)
          setLoader(false)
        }
      }
      catch(e:any){
        console.log(e);
        setLoader(false)
      }
    }
    if(page === 'popup' && type==='put'){
    fetchData()
    }
    console.log(id);
    
  },[id])
  React.useImperativeHandle(ref, () => ({
    submitForm,
  }));
  return (
    <ContentWithSpinner loader={loader}>
      {buttonLoader && <LoaderFullScreen />}
      <form className="mt-5" onSubmit={handleSubmit}>
        <div>
          <label className={`text-normal font-satoshiBold text-sm`}>
            {intl.formatMessage({
              id: "CLEANER.PROFILE.EMPLOYMENT.STATUS.TITLE",
              defaultMessage: "Are you currently employed?",
            })}
          </label>
          <div className="flex mt-2">
            <div
              className={`cursor-pointer border p-1 rounded-2xl w-12 text-center mr-3 ${
                currentlyEmployed
                  ? "bg-gray-200 font-bold font-satoshiBold border-gray-600"
                  : "font-Satoshi border-gray-300"
              }`}
              onClick={() => setCurrentlyEmployed(true)}
            >
              Yes
            </div>
            <div
              className={`cursor-pointer border p-1 rounded-2xl w-12 text-center mr-3 ${
                !currentlyEmployed
                  ? "bg-gray-200 font-bold font-satoshiBold border-gray-600"
                  : "font-Satoshi border-gray-300"
              }`}
              onClick={() => setCurrentlyEmployed(false)}
            >
              No
            </div>
          </div>
        </div>
        <Input
          label={intl.formatMessage({
            id: "CLEANER.PROFILE.EMPLOYMENT.INPUT.JOBTITLE",
            defaultMessage: "Job Title",
          })}
          type="text"
          value={jobTitle}
          onChange = {(e)=> setjobTitle(e.target.value)}
          placeholder={intl.formatMessage({
            id: "CLEANER.PROFILE.EMPLOYMENT.INPUT.PLACEHOLDER",
            defaultMessage: "Enter job title",
          })}
          // error={errors.job_title}
        />

        <Input
          label={intl.formatMessage({
            id: "CLEANER.PROFILE.EMPLOYMENT.INPUT.COMPANYNAME",
            defaultMessage: "Company Name",
          })}
          type="text"
          value={companyName}
          onChange={(e)=> setcompanyName(e.target.value)}
          placeholder={intl.formatMessage({
            id: "CLEANER.PROFILE.EMPLOYMENT.INPUT.COMPANYNAME.PLACEHOLDER",
            defaultMessage: "Enter company name",
          })}
          error={errors.company_name}
        />

        <Input
          label={intl.formatMessage({
            id: "CLEANER.PROFILE.EMPLOYMENT.INPUT.COMPANYLOCATION",
            defaultMessage: "Company Location",
          })}
          type="text"
          value={companyLocation}
          onChange={(e)=> setcompanyLocation(e.target.value)}
          placeholder={intl.formatMessage({
            id: "CLEANER.PROFILE.EMPLOYMENT.INPUT.COMPANYLOCATION.PLACEHOLDER",
            defaultMessage: "Enter Company Location",
          })}
          error = {errors.company_location}
        />

        <div className="mt-10">
          <label className={`text-normal font-satoshiBold text-sm`}>
            {intl.formatMessage({
              id: "CLEANER.PROFILE.EMPLOYMENT.INPUT.WORKINGSINCE",
              defaultMessage: "Working Since",
            })}
          </label>
          <div className="flex mt-2">

            <div className="-mt-10">
              <ReactDatePicker
                selected={startDate}
                onChange={(date)=> setstartDate(date)}
              error={errors.working_since_start}
              ></ReactDatePicker>
            </div>
            {currentlyEmployed === false  && <>
            <div className="flex items-center mx-3 font-bold font-satoshiBold">
              <label>To</label>
            </div>
            <div className="-mt-10">
              <ReactDatePicker
                selected={endDate}
                onChange={(date)=> setEndDate(date)}
                error={errors.working_since_end}
              ></ReactDatePicker>
            </div>
            </>}
          </div>
        </div>
        {/* <DropDown onChange={(e)=> setJobType(e.target.value)} value={jobType} name="selectjob" label="Select Job Type" error={errors.job_type}>
          <option selected>
            <FormattedMessage
              id="CLEANER.PROFILE.EMPLOYMENT.SELECT.JOBTYPE.TITLE"
              defaultMessage="Select Job Type"
            />
          </option>
          <option value={"FT"}><FormattedMessage id="CLEANER.JOB.TYPE.FULLTIME" defaultMessage="Full Time" /></option>
          <option value={"PT"}><FormattedMessage id="CLEANER.JOB.TYPE.PARTTIME" defaultMessage="Part Time" /></option>
          <option value={"WK"}><FormattedMessage id="CLEANER.JOB.TYPE.WEEKEND" defaultMessage="Weekend" /></option>
          <option value={"AD"}><FormattedMessage id="CLEANER.JOB.TYPE.ADHOC" defaultMessage="Adhoc/Ondemand" /></option>
          <option value={"VR"}><FormattedMessage id="CLEANER.JOB.TYPE.MQA" defaultMessage="Monthly/Quarterly/Annual" /></option>
          
        </DropDown> */}
        <div className='mt-5 flex space-x-5'>
  {page === 'popup' && type === 'add'||type==='put' ? '':(<>
    
                    <ButtonAuth loader={buttonLoader}
                      ><FormattedMessage id="REGISTER.BUTTON.SAVEANDCONTINUE" defaultMessage="Save and Continue" />
                    </ButtonAuth>
                   
                    <ButtonAuth onClick={()=> setAddMoreClicked(true)}
                                    
                             loader={buttonLoader}   >Add More</ButtonAuth>
                
                    <div className="rounded-full text-center
                 bg-pink
                 p-2
                 text-white
                 w-44
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy"
                                onClick={()=> HandleStepper(3)}>Skip</div>
  </>)
  }
                  </div>
      </form>
   </ContentWithSpinner>
  );
}
export default forwardRef(CleanerEmployment);