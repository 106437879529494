const datas = [
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
{ 
    id: 1,
    category: "Manufacturing",
    companycount: "187 Companies"
}, 
]
export {datas}