import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import { Input } from "sr/helpers";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { updateForm } from "sr/redux/reducers/individualJob";
import { FormattedMessage, useIntl } from "react-intl";
import { jobTypes } from "app/pages/company/jobpost/component/Jsonalldata";
export const JobPostNew = () => {
    const dispatch = useDispatch();
    const handleUpdateForm = (name: any) => {
        const payload =  {
            title:name
        }
        dispatch(updateForm(payload));
      };
      
    const intl = useIntl()
  return (
  <MainWrapper>
       <div className="py-3 lg:px-10 lg:max-w-4xl h-screen">
        <div className="text-center lg:py-10 py-5">
            <p className="text-2xl font-satoshiBold"><FormattedMessage id="INDIVIDUAL.BOOKTASK.TITLE" defaultMessage="Book your Task" /></p>
        </div>
        <Input inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0" placeholder={intl.formatMessage({id:'INDIVIDUAL.BOOKTASK.INPUT.PLACEHOLDER', defaultMessage:'Describe one task'})}></Input>
        <div className="py-3 flex flex-wrap space-x-3">
            {jobTypes.map((item, index)=> (
            <Link key={index} onClick={()=> handleUpdateForm(item?.value)} to={'/individual/job-post-details'} className="hover:bg-[#595959] hover:text-white border border-gray-500 py-1 px-4 rounded-lg relative space-x-4 my-1.5 cursor-pointer">{item.label}</Link>
            ))}
            </div>
       </div>
  </MainWrapper>
  );
};
