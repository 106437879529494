import React, { useEffect, useState } from "react";
import { SidebarPostItems, SidebarPreItems } from "./SidebarItems";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "sr/helpers";
import { FormattedMessage } from "react-intl";
import { useAuth } from "app/pages/module/auth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { CompanyRoutes } from "app/routing/component/CompanyRoutes";
import { FilterRoutes } from "sr/helpers/functions/FilterRoutes";
export const Sidebar = ({ isOpen, HandleOpen }) => {
  const [preRecruit, setPreRecruit] = useState(true);
  const [postRecruit, setPostRecruit] = useState(true);
  const supervisorData = useSelector((state:any)=> state?.supervisor?.data)
  const dispatch = useDispatch<AppDispatch>()
  const [filteredRoutes, setFilteredRoutes] = useState([])
  const [userId, serUserId] = useState()
  const {userType} = useAuth()
  useEffect(()=> {
    const getdetails = getUserDetails()
    serUserId(getdetails.user_id)
  },[])
  useEffect(()=> {
    if(supervisorData) {
       setFilteredRoutes(FilterRoutes(supervisorData))
    
    }
        },[supervisorData])
useEffect(()=> {
if(userId && userType){
  if (userType==='supervisor'){
    dispatch(fetchSupervisor({id:userId}))
  }
}
},[dispatch, userId, userType])
  const HandleClick = () => {
    HandleOpen();
  };
  const roles = { SUPERVISOR: "supervisor", COMPANY: "company" };
  const SidebarPreItemsPerm = {
    [roles.COMPANY]: [
      "/job-post/add-basic-details",
      "/jobs-listing",
      "/candidates",
      "/supervisor/work-order",
      "/work-order",
      "/work-order-model",
      "/contractor",
      "/supervisor",
      "/manage-customer",
    ],
    [roles.SUPERVISOR]:filteredRoutes?.filter((item)=> item?.permission?.includes('view'))?.map((item)=> item?.path)
  };

  let allowedLinks;

  if (userType === roles.SUPERVISOR) {
    allowedLinks = SidebarPreItemsPerm[roles.SUPERVISOR];
  } else if (userType === roles.COMPANY) {
    allowedLinks = SidebarPreItemsPerm[roles.COMPANY];
  } else {
    allowedLinks = [];
  }

  return (
    <div
      className={`fixed top-0 left-0 z-40 h-screen p-4 px-2 overflow-y-auto duration-200 bg-[#2d2d2d] text-white ${
        isOpen ? "w-48" : "w-14"
      }`}
    >
      <div onClick={HandleClick} className="flex items-center">
        <button
          type="button"
          className=" bg-transparent rounded-lg text-sm p-1.5 inline-flex items-center"
        >
          {isOpen ? (
            <svg
              aria-hidden="true"
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
              role="img"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
              className="w-6 h-6"
            >
              <path d="M3.5 5.25h13a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zm13 4h-13a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5zm0 4h-13a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5z"></path>
            </svg>
          )}
        </button>
        <h5 className={` ${isOpen ? "block" : "hidden"} text-base`}>
          {" "}
          <FormattedMessage
            id="RECRUITER.SIDEBAR.MENU.TITLE"
            defaultMessage="Collapse"
          />{" "}
        </h5>
      </div>
      <div className="py-4">
        <ul className="space-y-4 font-medium">
          {userType === "company" && (
            <li
              className={`flex items-center cursor-pointer rounded-lg hover:bg-[#424242] my-2`}
            >
              <span
                onClick={() => setPreRecruit(!preRecruit)}
                className="inline-flex items-center flex-shrink-0 p-1.5"
              >

              </span>
              <div
                onClick={() => setPreRecruit(!preRecruit)}
                className={`duration-300 overflow-hidden block ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                <span className="origin-left truncate">
                  <FormattedMessage
                    id="RECRUITER.SIDEBAR.MENU.PREREQRUITEMENT"
                    defaultMessage="Pre Recruitement"
                  />
                </span>
              </div>
            </li>
          )}
          <div className={`${preRecruit ? "block ml-2" : "hidden"}`}>
            {SidebarPreItems?.filter((link) =>
              allowedLinks?.includes(link?.link)
            ).map((data) => (
              <Link
                state={{ isFromSidebar: true }}
                to={data.link ? data.link : "#"}
              >
                <li
                  className={`flex items-center rounded-lg hover:bg-[#424242] my-2`}
                >
                  <span className="inline-flex items-center flex-shrink-0 p-1.5">
                    {data.icon}
                  </span>
                  <div
                    className={`duration-300 overflow-hidden block ${
                      isOpen ? "block" : "hidden"
                    }`}
                  >
                    <span className="origin-left truncate">{data.text}</span>
                  </div>
                </li>
              </Link>
            ))}
          </div>
          {userType === "company" && (
            <li
              className={`flex items-center cursor-pointer rounded-lg hover:bg-[#424242] my-2`}
            >
              <span className="inline-flex items-center flex-shrink-0 p-1.5">
           
              </span>
              <div
                onClick={() => setPostRecruit(!postRecruit)}
                className={`duration-300 overflow-hidden block ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                <span className="origin-left truncate">
                  <FormattedMessage
                    id="RECRUITER.SIDEBAR.MENU.POSTREQRUITEMENT"
                    defaultMessage="Post Recruitement"
                  />
                </span>
              </div>
            </li>
          )}
         
            <div className={`${postRecruit ? "block ml-2" : "hidden"}`}>
              {SidebarPostItems?.filter((link) =>
                allowedLinks?.includes(link?.link)
              ).map((data) => (
                <Link to={data.link ? data.link : "#"}>
                  <li
                    className={`flex items-center rounded-lg hover:bg-[#424242] my-2`}
                  >
                    <span className="inline-flex items-center flex-shrink-0 p-1.5">
                      {data.icon}
                    </span>
                    <div
                      className={`duration-300 overflow-hidden block ${
                        isOpen ? "block" : "hidden"
                      }`}
                    >
                      <span className="origin-left truncate">{data.text}</span>
                    </div>
                  </li>
                </Link>
              ))}
            </div>
        </ul>
      </div>
    </div>
  );
};
