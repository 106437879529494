import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { getAddress } from 'sr/utils/api/individual/address/getAddress'
export const LocationDetailsView = ({id}) => {
  const [data, setData] = useState<any>()
  const [loader, setLoader] = useState(false)
  
  useEffect(()=> {
    const fetchLocation = async (id)=> {
        setLoader(true)
      try {
        const params = new URLSearchParams()
       if (id) params.append('id', id)
      const res = await getAddress(params)
       if(res?.success===true) {
        setData(res?.data)
        setLoader(false)
       }
       else {
      setLoader(false)
       }
      }
      catch(e:any) {
        setLoader(false)
      }
      }
      if(id) {
        fetchLocation(id)
      }
  },[id])
  return (
    <ContentWithSpinner loader={loader} height='h-28'>
  
    <div className='py-3 px-10'>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ADDRESSLINE1" defaultMessage="Address 1" />:</span>
      <span>{data?.address?.address_line_1}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ADDRESSLINE2" defaultMessage="Address 2" />:</span>
      <span>{data?.address?.address_line_2}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.CITY" defaultMessage="City" />:</span>
      <span>{data?.address?.city}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.STATE" defaultMessage="State" />:</span>
      <span>{data?.address?.state}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ZIPCODE" defaultMessage="Zip Code" />:</span>
      <span>{data?.address?.postal_code}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.COUNTRY.TITLE" defaultMessage="Country" />:</span>
      <span>{data?.address?.country}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.LATITUDE.TITLE" defaultMessage="Latitude" />:</span>
      <span>{data?.address?.latitude}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.LONGITUDE.TITLE" defaultMessage="Longitude" />:</span>
      <span>{data?.address?.longitude}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.ADDRESSTYPE.TITLE" defaultMessage="Address Type" />:</span>
      <span>{data?.address_type}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.NOOFROOMS" defaultMessage="No of Rooms" />:</span>
      <span>{data?.no_of_rooms}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.INPUT.NOOFBATHROOMS" defaultMessage="No of Bathrooms" />:</span>
      <span>{data?.no_of_bath}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className='font-satoshiBold text-base w-36'><FormattedMessage id="INDIVIDUAL.MANAGE.ADDRESSES.TABLE.COL.TOTALAREA" defaultMessage="Total Area" /></span>
      <span>{data?.total_area}</span>
    </div>
    
  </div>
</ContentWithSpinner>

  )
}
