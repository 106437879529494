import { error } from "console";
import React from "react";
import { toast } from "react-toastify";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
export const createMedicalCondition = async (postData:any) => {
  try {
    return await post<ApiResponse<[]>>('/cleaner/medical', postData ).then((res)=> {
        if(res.success === true) {
            toast.success("Data added successful ")
            return res
        }
        else {
            return null
        }
    })
  }
  catch(e:any){
    HandleApiError(e);
            return null
    
  }
};
