import React, { useEffect, useRef, useState } from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import Footer from "../module/Footer";
import { Button, Input } from "sr/helpers";
import { Rating } from "./Rating";
import { FormattedMessage, useIntl } from "react-intl";
import { MainWrapper } from "../company/component/MainWrapper";
import { useGet } from "../company/Hooks/Api/useGet";
import { getTaskManage } from "sr/utils/api/company/workorder/getTaskManage";
import { useParams } from "react-router-dom";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { getTask } from "sr/utils/api/company/customer/getTask";
import getLabelByValue from "sr/helpers/functions/getLabelByValue";
import { TaskStatus } from "sr/i18n/messages/setting";
import { createtaskManage } from "sr/utils/api/company/workorder/createtaskManage";
import { updatetaskManage } from "sr/utils/api/company/workorder/updatetaskManage";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { DateFormatForApi } from "sr/helpers/functions/DateFormatForApi";
import { getManageTaskSubmit } from "sr/utils/api/company/workorder/getManageTaskSubmit";
import { toast } from "react-toastify";
import { manageTaskSubmit } from "sr/utils/api/company/workorder/manageTaskSubmit";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { updateManageTaskSubmit } from "sr/utils/api/company/workorder/updateManageTaskSubmit";
import { updateWorkorder } from "sr/utils/api/company/workorder/updateWorkorder";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { ModelWrapperWithButton } from "../company/component/ModelWrapperWithButton";
import { Empty } from "../company/candidates/component/Empty";
import { TaskTableHeader } from "./component/pendingDetails/TaskTableHeader";
import { TaskManageButton } from "./component/pendingDetails/TaskManageButton";
import { ApproveWorkHours } from "./component/pendingDetails/ApproveWorkHours";
import { CopyWorkOrder } from "./component/pendingDetails/CopyWorkOrder";
import { WorkOrderPopup } from "../company/work-order/component/WordOrderPopup";
import { RadioInput } from "sr/helpers/ui-components/RadioInput";
import RejectWorkOrder from "./component/pendingDetails/RejectWorkOrder";
import { CheckMandatorTask } from "./component/pendingDetails/function/CheckMandatoryTask";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { useAuth } from "../module/auth";
import { createfeedBack } from "sr/utils/api/company/workorder/createfeedBack";
import { setPreview } from "../jobs/Myjobs/function/setPreview";
interface ReviewDataType {
  internalRating: number;       // Assuming internalRating is a numeric value (e.g., 1-5 stars)
  internalFeedback: string;     // Assuming internalFeedback is a string (e.g., comments or review text)
  externalRating: number;       // Assuming externalRating is a numeric value (e.g., 1-5 stars)
  externalFeedback: string;     // Assuming externalFeedback is a string (e.g., comments or review text)
}
export const PendingDetails = () => {
  const childRef:any = useRef()
  useEffect(()=> {
console.log(childRef);

  },[childRef])
const intl = useIntl()
const [taskTrackId, setTaskTrackId] = useState<string>()
const [userId, setUserId] = useState('')
const [cworkHours, setCWorkHours] = useState()  // C - Contractor
const [aworkHours, setAWorkHours] = useState()   // A - Approved
const [loader, setLoader] = useState(false)
const [showModal, setShowModal] = useState('')
const [previewImages, setPreviewImages] = useState<any>([])
const {getData:getWorkOrders, data:workorder, loader:loaderWorkOrder, pageination:pageination}:any = useGet()
  const {data:checklistData, loader:checklistLoader, getData:getChecklists} = useGet()
  const [workorderId, setWorkOrderId] = useState<any>()
  const {data:managetasks, getData:getManageTaskList, loader:taskmanageLoader} = useGet()
  const [reviewData, setReviewData] = useState<ReviewDataType>()
  const [previewVideo, setPreviewVideo] = useState<any>([])
const params = useParams()
const {userType} = useAuth()
const {data:submittask, getData:getTaskSubmitted, loader:trackloader} = useGet()
  useEffect(()=> {
    const {id} = params
      setWorkOrderId(id)
  },[params])
    const stepperIndex = 2;
    useEffect(()=> {
      const fetchWorkOrder = async (workorderId)=> {
          const queryParams = new URLSearchParams()
          if (workorderId) queryParams.append('id', workorderId)
            await getWorkOrders(() => getWorkorder(queryParams));
      }
      if(workorderId) {
        fetchWorkOrder(workorderId)
      }
  },[workorderId])
  useEffect(() => {
    const { user_id } = getUserDetails();
    if(userType==='supervisor'){
      setUserId(user_id)
    }
  }, []);
  useEffect(()=> {
    const fetchChecklist = async (id)=> {
      const queryParams = new URLSearchParams()
      if (id) queryParams.append('checklist_id', id)
          await getChecklists(()=> getTask(queryParams))
}
      if(workorder?.checklist_id?._id) {
        fetchChecklist(workorder?.checklist_id?._id)
      }
  },[workorder?.checklist_id?._id])

  const fetchManageTask = async ()=> {
    const queryParams = new URLSearchParams()
    if (workorderId) queryParams?.append('workorder_id', workorderId)
    await getManageTaskList(()=> getTaskManage(queryParams))
    return true;
}
const fetchTaskTrack = async ()=> {
  const queryParams = new URLSearchParams()
    if (workorderId) queryParams?.append('workorder_id', workorderId)
      await getTaskSubmitted(()=> getManageTaskSubmit(queryParams)) 
  
return true
}
useEffect(()=> {
if(workorderId) {
fetchTaskTrack()
fetchManageTask()
}
},[workorderId])

useEffect(()=> {
  if(submittask?.length>0) {
    setTaskTrackId(submittask[0]?.id)
    setAWorkHours(submittask[0]?.work_completion_time)
    setCWorkHours(submittask[0]?.work_completion_time)
  }
  
    },[submittask])
let remarks = {}
const checkSupervisorStatus = (taskId)=> {
  return managetasks?.find((item) => item?.task_id?._id === taskId)?.supervisor_status
}
const getRemark = (taskId)=> {
  return managetasks?.find((item)=>item?.task_id?._id === taskId)?.supervisor_remarks
}
const HandleRemark = (id, remark)=> {
  remarks[id] = remark
      }
const handleTaskManage = async (id, type)=> {
  setLoader(true)
  const payLoad =  {
    id:id,
    supervisor_status: type,
    supervisor_remarks:remarks[id] || '',
    status:'in progress'
  }
      try {
           const res = await updatetaskManage(payLoad)
           if(res?.success===true) {
           
await fetchManageTask()
setLoader(false)
           }
      }
      catch(e) {
setLoader(false)
      }
}

const onCheckModel = (data)=>{
  setShowModal('')
  setPreviewImages([])
}
const HandleTaskSubmit = async ()=> {
  setLoader(true)
  const res = await updateManageTaskSubmit({
    workorder_id:workorderId,
    id:taskTrackId,
    supervisor_status:'completed',
    approve_time:aworkHours,
    status:"completed"
  })
if(res?.success===true){
  await fetchTaskTrack()
const payLoad = {
id:workorderId,
approval_status:'completed'
}
const res = await updateWorkorder(payLoad)
if(res?.success===true) {
  const feedbackpayload = {
    workorder_id:workorder?.id,
    supervisor_id:userId,
    contractor_id:workorder?.contractor_id?._id,
    internal_supervisor_rating:reviewData?.internalRating,
    internal_supervisor_feedback:reviewData?.internalFeedback,
    external_supervisor_feedback:reviewData?.externalFeedback,
    external_supervisor_rating:reviewData?.externalFeedback
  }
  const feedbackres = await createfeedBack(feedbackpayload)
  setShowModal('')
  setLoader(false)
}
}
}
const HandleFormSubmit = ()=> {
  if (childRef.current && childRef.current.HandleWorkOrderCopy) {
    childRef.current.HandleWorkOrderCopy()
  }
}
const dataAddedHandle = (data)=> {
  if(data===true){
setShowModal('')
  }
}
const CheckContractorComplete = (id)=> {
  return managetasks?.some((tasks)=> tasks?.task_id?._id === id && tasks?.contractor_status==='completed')
}
const HandleImageView = (taskId)=> {
  setLoader(true)
  const images = managetasks?.find((data)=> (data?.task_id?._id===taskId))?.images
  if (images) {
    setPreview({type:'images', files:images, setPreviewImages:setPreviewImages, previewImages:previewImages, setLoader:setLoader})
}
}
const HandleVideoView = (taskId) => {
setLoader(true)
    const videos = managetasks?.find((data)=> (data?.task_id?._id===taskId))?.videos
    if (videos) {
      setPreview({type:'videos', files:videos, setPreviewVideo:setPreviewVideo, previewVideo:previewVideo, setLoader:setLoader})
  }
}

  return (<>
  <MainWrapper>
  {loader && <LoaderFullScreen />}
  <ContentWithSpinner loader={checklistLoader} height="h-screen">
  {/* <Empty text={'The contractor has not started the work order yet'}/> */}
    <div className="py-3 lg:container mx-auto lg:max-w-6xl px-4 lg:px-0">
        <div className='mb-5'>
    <h1 className='text-2xl '><FormattedMessage id="SUPERVISOR.PENDING.JOB.MANAGE_JOB.TITLE" defaultMessage="Manage Pending Job Task" /></h1>
   </div>
   
   <div className="bg-white">
   {submittask?.length>0 && submittask[0]?.status==='completed' &&<>
        <div className="text-center py-10 "> Work Order completed successfully </div>
</>}
   <TaskTableHeader />
   { checklistData?.map((item, index)=> (
  
   <div className="flex justify-between items-center py-3 border-b border-neutral-200 px-10">
   <div className="w-[30%]">
   <h2 className=""><span className=""><FormattedMessage id="SUPERVISOR.PENDING.JOB.TASK.TITLE" defaultMessage="Task" />:</span> {item?.name}</h2>
   <div>{item?.description}</div>
   <div><span className="">Type:</span> {item?.type}</div>
   </div>
   <div className="w-[11.5%] flex justify-center">
    <div className="break-words">{managetasks?.find((tasks)=> tasks?.task_id?._id === item?.id)?.contractor_remarks||<FormattedMessage id="SUPERVISOR.PENDING.JOB.REMARKS.NOT AVAILABLE" defaultMessage='Not Available'/>}</div>
    </div>
   
    <div className="w-[11.5%] flex justify-center">
    <div>{getLabelByValue(managetasks?.find((tasks)=> tasks?.task_id?._id === item?.id)?.contractor_status, TaskStatus) || 'Not Completed'}</div>
    </div>
<div className="w-[11.5%] flex justify-center">
{(checkSupervisorStatus(item?.id) ==='approved') || (checkSupervisorStatus(item?.id) ==='rejected') ?
  <div> {getRemark(item?.id) || 'N/A'} </div> :
<textarea onChange={(e)=> HandleRemark(managetasks?.find((tasks)=> tasks?.task_id?._id === item?.id)?.id, e.target.value )}  id="Description" rows={1} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#0078ae] focus:shadow-inputshadow outline-0" placeholder={intl.formatMessage({id:'MYJOB.INPUT.REMARKS.PLACEHOLDER', defaultMessage:'Remarks'})}></textarea>
}
</div>

<div className="w-[11.5%] flex justify-center">
{managetasks?.find((tasks)=> tasks?.task_id?._id === item?.id)?.images?.length>0 ?
    <svg onClick={()=> HandleImageView(item?.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>
:
<p>N/A</p>
}

    </div>
   
    <div className="w-[11.5%] flex justify-center">
    {managetasks?.find((tasks)=> tasks?.task_id?._id === item?.id)?.videos?.length>0 ?
    <svg onClick={()=> HandleVideoView(item?.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>
:
<p>N/A</p>
}
    </div>
<div className="w-[11.5%] flex justify-center">
<TaskManageButton approveOnClick={()=>handleTaskManage(managetasks?.find((tasks)=> tasks?.task_id?._id === item?.id)?.id, 'approved')} rejectOnClick={()=>handleTaskManage(managetasks?.find((tasks)=> tasks?.task_id?._id === item?.id)?.id, 'rejected')} CheckContractorComplete = {CheckContractorComplete(item?.id)} checkSupervisorStatus = { checkSupervisorStatus(item?.id)} type={'task'}  />
</div>
   </div>
   ))} 
   {submittask?.length>0 && submittask[0]?.status==='rejected' && <>
   <div className="text-center pt-10 pb-5"> You have rejected the work order </div>
</>
}
   {
   submittask?.length>0 && submittask[0]?.status!=='completed' && submittask?.length>0 && submittask[0]?.status!=='rejected'   &&    
   <TaskManageButton approveOnClick={()=> CheckMandatorTask({setLoader:setLoader, checklistData:checklistData, managetasks:managetasks, setShowModal:setShowModal, type:'approve'})} rejectOnClick={()=> CheckMandatorTask({setLoader:setLoader, checklistData:checklistData, managetasks:managetasks, setShowModal:setShowModal, type:'reject'})} type={'workorder'}/>
}
   </div>
   </div>
  
   </ContentWithSpinner>
</MainWrapper>
       <ModelWrapperWithButton savetext={'Submit'} showModal={showModal==='hours'} onCheckModel={onCheckModel} Save={HandleTaskSubmit}>
       <ApproveWorkHours cworkHours={cworkHours} aworkHours={aworkHours} setAWorkHours={setAWorkHours}></ApproveWorkHours>
       <Rating setReviewData = {setReviewData}/>
       </ModelWrapperWithButton>
       <ModelWrapperWithButton headertitle={'Reject and Create New'} Save={HandleFormSubmit} showModal={showModal==='reject'} onCheckModel={onCheckModel} > 
             <RejectWorkOrder setShowModal = {setShowModal}  workorderId ={workorderId} managetasks={managetasks} tasklist = {checklistData} taskTrackId = {taskTrackId} dataAddedHandle={dataAddedHandle} submittask={submittask} ref={childRef}></RejectWorkOrder>
             
       </ModelWrapperWithButton>
       <ModelWrapperWithButton showModal={previewImages?.length} onCheckModel={onCheckModel}>
       <div className="flex justify-center">
   {previewImages?.map((preview, index)=> (
 <img className="w-48" src={preview}></img>
))}
 </div>
       </ModelWrapperWithButton>
       <ModelWrapperWithButton showModal={previewVideo?.length} onCheckModel={onCheckModel} nofooter={true}>
       <div className="flex justify-center">
   {previewVideo?.map((preview)=> ( 
         <video className="h-96 w-96" controls>
         <source src={preview} type="video/mp4" />
       </video>
 
))}
 </div>
       </ModelWrapperWithButton>
        </>);
};
