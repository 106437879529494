import React, { useEffect, useState } from "react";
import { Checkbox } from "sr/helpers";
import { toSnakeCase } from "sr/helpers/functions/toSnakeCase";

export const SecurityCard = ({data, parentTitle, setPermission, permission}) => {
    
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        const parentKey = toSnakeCase(parentTitle);
        setPermission((prevPermissions) => {
            
             // Ensure that prevPermissions is initialized as an empty object if it is undefined
        const currentPermissions = prevPermissions || {};

        // Ensure that the parentKey property is an object if it is undefined
        const currentParentPermissions = currentPermissions[parentKey] || {};
        if (currentParentPermissions[name]) {
            // Remove the existing permission
            //Destructuring Object With Name Variable Property
            const { [name]: _, ...remainingPermissions } = currentParentPermissions;
            return {
                ...currentPermissions,
                [parentKey]: remainingPermissions,
            };
        }
        return {
            // Add New Value 
            ...currentPermissions,
            [parentKey]: {
                ...currentParentPermissions,
                [name]: value
            }

    }});
    };
    
    
  return (
    <div className='flex justify-between items-center mb-3 py-3 border-b border-neutral-200 mx-10 '>
    <div>
        <h2 className=''>{data?.title}</h2>
    </div>
    <div  className="flex item-center space-x-2">
   
    <Checkbox onChange={(e)=> handleChange(e)} id={toSnakeCase(data?.title)} checked={permission && permission?.[toSnakeCase(parentTitle)]?.[toSnakeCase(data?.title)]===true} name={toSnakeCase(data?.title)} label={'YES'}></Checkbox>
</div> 
</div>
    );
};
