export const scrollToError = (errors) => {
    if (Object.keys(errors).length > 0) {
        const firstErrorMessage = document.querySelector(
          "p.text-red-400, p.text-red-500"
        ) as HTMLElement | null;


    
        if (firstErrorMessage) {
          // Get the parent div (assuming the div wraps the input and error message)
          if(firstErrorMessage?.innerText !== 'Please Enter Job Description'){
          const parentDiv = firstErrorMessage.closest("div");
    
          if (parentDiv) {
            parentDiv.scrollIntoView({ behavior: "smooth", block: "center" });
          }
          else {
            return
          }
        }
        else {
            return
        }
        }
        else {
            return
        }
      }
      else {
        return
      }
};
