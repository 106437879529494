function formatNumber(num) {
    if(num<10) {
        const formatnum =  num.toString().padStart(2, '0');
            return formatnum
    }
    else {
        return num
    }
  }
  
export default formatNumber;