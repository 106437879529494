import React, {useEffect, useState} from 'react'
import { toAbsoluteUrl } from '../../../../sr/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faL, faMagnifyingGlass, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SidebarMenuLinks } from './SidebarMenuLinks'
import { FormattedMessage, useIntl } from 'react-intl';
import { Sidebar } from 'app/pages/cleaner/component/Sidebar'
import { useAuth } from './core/Auth'
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getFiles } from 'sr/utils/api/getFiles'
import { getFile } from 'sr/utils/api/getFile'
import { getCleaner } from 'sr/utils/api/cleaner/getCleaner'
import CleanerType from 'app/pages/cleaner/component/type/CleanerType'
import { io } from 'socket.io-client'
import { formatISO } from 'date-fns'
import { getIndividual } from 'sr/utils/api/individual/getIndividual'
import { getMessage } from 'sr/utils/api/cleaner/getMessage'
import { DaysConverter } from 'sr/helpers/functions/DaysConverter'
import { useSocket } from 'app/pages/component/SocketContext'
import { CHAT_TYPE } from 'sr/i18n/messages/setting'
export default function AuthNavComponent(props) {
    const intl = useIntl()
    const [isOpened, setIsOpened] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const {isAuthenticate, userType} = useAuth()
    const [search, setSearch] = useState()
    const [show, setshow] = useState(false)
    const [profileImg, setProfileImg] = useState<string>('')
    const [cleanerId, setCleanerId] = useState<string>()
    const [cleanerData, setCleanerData] = useState<CleanerType>(null)
    const [allIndividual, setAllIndividual] = useState([])
    
    const audioFile = toAbsoluteUrl('/media/notification/notification-18-270129.mp3')
    console.log(process.env.REACT_APP_SOCKET)
      const [allmessage, setAllMessage] = useState([])
      const socket = useSocket()
  const ToggleHandle = () => {
        setIsOpened(!isOpened);
    }
    const navigate = useNavigate()
    const SidebarHandle = ()=> { 
            setSidebarOpen(false)
    }
    useEffect(()=> {
if(isAuthenticate===false) {
  setSidebarOpen(false)
}
    },[isAuthenticate])
    const HandleSubmit = (e)=> {
      e.preventDefault()
     if(e.target[0].value){
      navigate(`/search/${e.target[0].value}`)
     }
     
    }
    useEffect(()=> {
      const getdetails = getUserDetails()
setCleanerId(getdetails?.user_id)
    },[])
    const fetchChat = async ()=> {
                       const queryParams = new URLSearchParams()
                       queryParams?.append('recipient_id', cleanerId)
                       queryParams.append('sender_id_type', 'individual')

                   try {
                   const res = await getMessage(queryParams)
                   if(res?.success===true) {
                    let messages = []
                    for (let i =0; i<res?.data?.length; i++){
                      if(Object.values(CHAT_TYPE)?.includes(res?.data[i]?.type)){
                            messages.push(res?.data[i])
                      }

                    }
                   setAllMessage(messages)     
                   }
                   else {
                   
                   }
                   }
                   catch(e) {
                   }
                      }
    const fetchProfileImg = async (cleanerId)=> {
      const params = {
        cleaner_id:cleanerId
      }
  const profileImg = await getFiles(params)
  if(profileImg?.success===true) {
    const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
    if(filterfile && filterfile[0]?.file?.key) {
      const fileimg = await getFile(filterfile[0]?.file?.key);
      
      if(fileimg) {
      // const rawImageData = new Uint8Array(fileimg as ArrayBuffer);
      // const blob = new Blob([rawImageData], { type: 'image/png' });
      const newImageUrl = URL.createObjectURL(fileimg);
      setProfileImg(newImageUrl)
      }
      
    }}
    }
    // const fetchCleaner = async (cleanerId) => {
    //   const cleanerData = await getCleaner(undefined, cleanerId);
      
    //   if (cleanerData?.success) {
    //     setCleanerData(cleanerData?.data);

    //   }
    // };
    
    useEffect(()=> {
if(cleanerId) {
  fetchProfileImg(cleanerId)
  fetchChat()
}

    },[cleanerId])
    useEffect(() => { 
      if (!cleanerId) return; 
      if(socket){
        socket.emit('register', cleanerId);
    }
            socket.on('sendmessage', ({ from, message }) => {
              console.log(message);
              if(message){
                const audio = new Audio(audioFile)
                audio.play().catch((error) => console.error('Audio playback failed:', error)); // Play the audio
              const currentDateTime = formatISO(new Date());
                const newmessage = {createdAt:currentDateTime, ...message}
              setAllMessage((preview)=> [...preview, newmessage])    
              }
            });
            
          }, [cleanerId]);

          const getIndividualName = (id)=> {
              const data =  allIndividual?.find((item)=> item?.id=== id)
              return `${data?.first_name} ${data?.last_name}`
          }

          const fetchAllIndividual = async ()=> {
            try {
                  const queryParams = new URLSearchParams()
                  queryParams.append('limit', '1000')
                  const res = await getIndividual(queryParams)
                  if(res?.success===true) {
                        setAllIndividual(res?.data)
                  }
                  else {

                  }
            }
            catch(e){

            }
          }
          useEffect(()=> {
              fetchAllIndividual()
          },[])

    return (<>
        <nav className={` ${isOpened? 'z-50	sticky top-0 inset-x-0 ': 'static'} bg-navy text-white flex items-center p-4`}>
            <Link to="/" className='z-50'>
            <img width={150} src={toAbsoluteUrl('/media/logos/logo2.png')} alt=''
                className='bg-white rounded-lg' />
                </Link>
            {
                props.isLoginPage &&
                (
                    <ul className='flex-1 flex justify-end'>
                        <Link to='/create-company' replace>
                            <li className='cursor-pointer hover:text-pink font-mono'> <FormattedMessage
        id="AUTH.GENERAL.REGISTER.CLEANING.COMPANY"
        defaultMessage="Register as Cleaning Company"
      /></li>
                        </Link>
                    </ul>
                )
            }

            {
                props.isLoggedin && ( <>
                <div
            className={`z-20 opacity-100 lg:flex lg:justify-end lg:grow fixed inset-0 bg-white lg:bg-transparent shadow-xl transition duration-300 lg:w-20 w-[80%] lg:static lg:shadow-none lg:translate-x-0 ${
              isOpened ? "translate-x-0 delay-100	dark:bg-gray-800 border-gray-100":'-translate-x-[100%]'
            }`}
          >
            <hr className="border-gray-200 my-2 mt-24 lg:hidden" />
            <div className='flex lg:hidden items-center space-x-4 px-4'>
            <div className="radius-50 ">
                <img alt="Profile" className="rounded-full w-14 h-14" src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}/>
                </div>
            <div className='text-black'>
            <div className="font-bold text-base">{cleanerData?.first_name} {cleanerData?.last_name}</div>
                </div> 
            </div>
            <hr className="border-gray-200 my-2 lg:hidden" />
            <SidebarMenuLinks />
                    <ul className='lg:flex-1 lg:flex lg:justify-center lg:items-center lg:text-white text-black lg:m-0 hidden'>
                       <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'> <Link  to='/cleaner-jobs' replace className='mr-10 hover:text-pink'>
                          <FormattedMessage 
                          id='NAVBAR.COMPANY.JOBS.TITLE'
                          defaultMessage='Jobs'
                         />
                            </Link>
                            <div className="group-hover:block dropdown-menu absolute hidden h-auto z-10">
                            {isAuthenticate && userType === 'cleaner' &&
        <ul className="top-0 w-64 bg-white shadow px-6 py-8 rounded-lg">
        <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/applied-jobs'>
            <FormattedMessage 
                          id='NAVBAR.APPLIED.JOBS.TITLE'
                          defaultMessage='Applied Jobs'
                         />
                         
                         </Link></li>
            <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/saved-jobs'>
            <FormattedMessage 
                          id='NAVBAR.SAVED.JOBS.TITLE'
                          defaultMessage='Saved Jobs'
                         />
                </Link></li>
        </ul>
}
        </div>
                            </li>
                            {/* <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'>
                                 <Link  to='/individual-jobs' replace className='mr-10 hover:text-pink'>
                                 <FormattedMessage 
                          id='NAVBAR.INDIVIDUAL.JOBS.TITLE'
                          defaultMessage='Individual Jobs'
                         />
                            </Link>
                            <div className="group-hover:block dropdown-menu absolute hidden h-auto z-10">

        <ul className="top-0 w-64 bg-white shadow px-6 py-8 rounded-lg">
        <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/individual/applied-jobs'>
        <FormattedMessage 
                          id='NAVBAR.APPLIED.JOBS.TITLE'
                          defaultMessage='Applied Jobs'
                         />
            </Link></li>
            <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/individual/saved-jobs'>
            <FormattedMessage 
                          id='NAVBAR.SAVED.JOBS.TITLE'
                          defaultMessage='Saved Jobs'
                         />
                </Link></li>

        </ul>
        </div>
                            </li> */}
                            
                            <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'>
                                 <Link  to='/cleaner/work-order' replace className='mr-10 hover:text-pink'>
                                 <FormattedMessage 
                          id='NAVBAR.WORK.ORDER.TITLE'
                          defaultMessage='Work Order'
                         />
                             </Link>
                             {isAuthenticate && userType === 'cleaner' &&
                             <div className="group-hover:block dropdown-menu absolute hidden h-auto z-10">

<ul className="top-0 w-64 bg-white shadow px-6 py-8 rounded-lg">
    {/* <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/pending-jobs'>
    <FormattedMessage 
                  id='NAVBAR.PENDING.JOBS.TITLE'
                  defaultMessage='Pending Workorder'
                 />
        </Link></li> */}
        <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/cleaner/applied-work-order'> <FormattedMessage 
                  id='NAVBAR.APPLIED.WORK.ORDER.TITLE'
                  defaultMessage='Applied Work Order'
                 /></Link></li>
                                             <li className='py-1'>
                                 <Link  to='/my-jobs' className='block text-base cursor-pointer text-black'>
                                 <FormattedMessage 
                          id='NAVBAR.MY.JOBS.TITLE'
                          defaultMessage='My Jobs'
                         />
                             </Link>
                       
                            </li>
                            <li className='py-1'>
                                 <Link  to='/saved-work-order' className='block text-base cursor-pointer text-black'>
                                 <FormattedMessage 
                          id='NAVBAR.SAVED.WORK.ORDER.TITLE'
                          defaultMessage='Saved Work Order'
                         />
                             </Link>
                       
                            </li>
</ul>
</div>
}
                            </li>
                        
                            <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'> <Link  to='/companies' replace className='mr-10 hover:text-pink'>
                            <FormattedMessage 
                          id='NAVBAR.COMPANIES.TITLE'
                          defaultMessage='Companies'
                         />  </Link>
                       
                            </li>
 {isAuthenticate && userType === 'cleaner' &&
                            <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'> 
                            <Link  to='/cleaner/message' replace className='mr-10 hover:text-pink'>
                            Message  </Link>
                        
                            </li>
}
                    </ul> </div>
                    <div className='lg:block hidden'>
                      <form onSubmit={(e:any)=> HandleSubmit(e)} className='relative flex'>
                                                <input onClick={()=> setshow(true)} type='search' className='w-60 h-10 rounded-3xl p-5 text-gray-900'
                                                    placeholder={intl.formatMessage({
                                                        id:"INPUT.SEARCH.JOBS.PLACEHOLDER",
                                                        defaultMessage:"Search jobs"
                                                    }) }>
                                                </input>
                              <button className='h-8  w-8 rounded-full bg-pink flex justify-center items-center absolute top-1 right-2'>              
                              <FontAwesomeIcon icon={faMagnifyingGlass} className='text-white text-lg font-bold font-satoshiBold' />
                              </button>   
                              </form>
                                            </div>
                    
                                            <div className='profile-container flex-1'>
                                                <div className='flex gap-4 items-center justify-end'>
                                               {isAuthenticate && userType === 'cleaner' ?
                                               <>
                                               <div className='relative cursor-pointer group w-full flex justify-end' >
                                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
</svg>
<div className='rounded-full bg-pink w-4 h-4 flex justify-center items-center absolute -top-2 right-0'>
  <span className='text-[0.8rem] font-satoshiBold'>{allmessage?.length}</span>
</div>

<div className='group-hover:block  dropdown-menu absolute hidden top-5'>
  <div className='bg-white shadow py-3 rounded-lg text-black'>
  {allmessage.sort((a, b)=> {
  const adate = new Date(a?.createdAt)
  const bdate = new Date(b?.createdAt)

  const differenceInMilliseconds =  bdate.getTime() - adate.getTime()
  return differenceInMilliseconds
  })?.map((item, index)=> (
<Link to={`/cleaner/individual/chat/${item?.individual_job_id}`} key={index} className='flex justify-start space-x-3 py-2 px-6 border-gray-200 border-b hover:bg-gray-50'>
  <div className=''>
  <img className='h-16 w-16 rounded-full' src={toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}>
  </img>
  </div>
  <div>
   
    <div className='flex justify-between w-80 '>
      <span className='font-satoshiBold'>{getIndividualName(item?.sender_id)}</span>
      <span className=''>{DaysConverter(item?.createdAt)} Days Ago</span>
      
      </div>
    <p>{item?.message?.slice(0,100)}</p>
  </div>
</Link>
  ))}
</div>
</div>

                                               </div>
                                                    <div onClick={()=> setSidebarOpen(true)} className='p-[2px] bg-white rounded-full float-right cursor-pointer'>
                                                        <img
                                                            className='lg:w-14 lg:h-14 w-10 h-10 rounded-full'
                                                            src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}></img>
                                                    </div>
                                                    </>
                                                   :<>
                                                   <Link to={'/auth'} className="bg-transparent hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded">
  Login
</Link>
<Link to={'/create-cleaner'} className="bg-pink hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border rounded">
  Register
</Link>
</>
                                                }
                                             
          
                                                <div onClick={ToggleHandle} className="lg:hidden z-20">
              <div
                className={`w-6 h-0.5 rounded bg-white transation duration-300 ${
                  isOpened ? "translate-y-2 rotate-45" : ""
                } `}
              ></div>
              <div
                className={`w-6 h-0.5 mt-1.5 rounded bg-white transation duration-300 ${
                  isOpened ? "opacity-0" : ""
                } `}
              ></div>
              <div
                className={`w-6 h-0.5 mt-1.5 rounded bg-white transation duration-300 ${
                  isOpened ? "-translate-y-2 -rotate-45" : ""
                } `}
              ></div>
            </div>
          </div>                                            </div>
                    </>
                                               
                )
            }

<span onClick={ToggleHandle} className={`fixed w-full h-full top-0 left-0 delay-100 visible opacity-100 bg-[#00000099] ${isOpened ? 'block':'hidden'}`}></span>
        </nav>
        <Sidebar sidebarOpen={sidebarOpen} SidebarHandle={SidebarHandle}></Sidebar>
        </>
    )
}
