import React, { useEffect, useState } from "react";
import { MainWrapper } from "../component/MainWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { TopBar } from "../component/TopBar";
import { Link, useParams } from "react-router-dom";
import EmailLink from "sr/helpers/ui-components/EmailLink";
import { useGet } from "../Hooks/Api/useGet";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { getCleaner } from "sr/utils/api/cleaner/getCleaner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarker, faPhone } from "@fortawesome/free-solid-svg-icons";
import PhoneNumberFormat from "sr/helpers/functions/PhoneNumberFormat";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { DateFormatForApi } from "sr/helpers/functions/DateFormatForApi";
import { getManageTaskSubmit } from "sr/utils/api/company/workorder/getManageTaskSubmit";
import { getTaskManage } from "sr/utils/api/company/workorder/getTaskManage";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { Empty } from "../candidates/component/Empty";
import { ModelWrapperWithButton } from "../component/ModelWrapperWithButton";
import { setPreview } from "app/pages/jobs/Myjobs/function/setPreview";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";

export const WorkOrderProgress = () => {
  const [isTaskSubmitted, setIsTaskSubmitted] = useState(false)
  const [id, setId] = useState<string>()
  const params = useParams()
  const [previewImages, setPreviewImages] = useState<any>([])
  const [loader, setLoader] = useState(false)
  const [previewVideo, setPreviewVideo] = useState<any>([])
  const intl = useIntl()
  const {getData:getWorkOrders, data:workorder, loader:loaders, pageination:pageination}:any = useGet()
  const {data:contractor, getData:getContractorDetails, loader:contractorLoader} = useGet()  
  const {data:cleanerData, getData:getCleanerData, loader:cleanerLoader} = useGet()
  const {data:tasks, getData:getTasks, loader:taskLoader} = useGet()
  const {data:managetasks, getData:getManageTaskList, loader:taskmanageLoader} = useGet()
  const {data:submittask, getData:getTaskSubmitted, loader:trackloader} = useGet()

  useEffect(()=> {
        const {id} = params
        setId(id)
    },[params])
    useEffect(()=> {
      if(submittask?.length>0) {
        setIsTaskSubmitted(true)
      }
      else {
        setIsTaskSubmitted(false)
      }
      console.log(submittask);
      
      
        },[submittask])
    useEffect(()=> {
      const fetchWorkOrder = async (id)=> {
          const queryParams = new URLSearchParams()
          if (id) queryParams.append('id', id)
          await getWorkOrders(() => getWorkorder(queryParams));
      }
      if(id) {
        fetchWorkOrder(id)
        fetchManageTask(id)
      }
  },[id])
  const onCheckModel = (data)=>{
    setPreviewImages([])
  }
  
  const fetchManageTask = async (workorderId)=> {
    const queryParams = new URLSearchParams()
    if (workorderId) queryParams?.append('workorder_id', workorderId)
    await getTaskSubmitted(()=> getManageTaskSubmit(queryParams)) 
    await getManageTaskList(()=> getTaskManage(queryParams))  
    }
  // useEffect(()=> {
  //   const fetchCleaner = async (cleanerId) => {
  // await getCleanerData(()=> getCleaner(undefined, cleanerId))
  //   }
  //   if(contractor?.cleaner_id) {
  //     fetchCleaner(contractor?.cleaner_id)
  //   }
  // },[contractor?.cleaner_id])

  useEffect(()=> {
      const fetchTasks = async (checklistId)=> {
        const queryParams = new URLSearchParams()
        queryParams.append('checklist_id', checklistId)

          await getTasks(()=> getTask(queryParams))
      }
      if(workorder?.checklist_id?._id) {
        fetchTasks(workorder?.checklist_id?._id)
      }
  },[workorder?.checklist_id?._id])
  const HandleImageView = (taskId)=> {
    setLoader(true)
    const images = managetasks?.find((data)=> (data?.task_id?._id===taskId))?.images
    if (images) {
      setPreview({type:'images', files:images, setPreviewImages:setPreviewImages, previewImages:previewImages, setLoader:setLoader})
  }
  }
  const HandleVideoView = (taskId) => {
  setLoader(true)
      const videos = managetasks?.find((data)=> (data?.task_id?._id===taskId))?.videos
      if (videos) {
        setPreview({type:'videos', files:videos, setPreviewVideo:setPreviewVideo, previewVideo:previewVideo, setLoader:setLoader})
    }
  }

const calculateAmount = (payType, rate, hours)=> {
        if(payType==='Fixed Rate') {
          return rate
        }
        if (payType==='Hourly Rate') {
          return rate*hours
        }
}

  return (
    <MainWrapper>
        {loader && <LoaderFullScreen />}
     <div className="py-3">
    <TopBar title = {<FormattedMessage
          id='RECRUITER.WORKORDER.DETAILS.TITLE'
          defaultMessage='Work Order'
        />} 
        btntitle = {<FormattedMessage
          id='RECRUITER.WORKORDER.ALL.TITLE'
          defaultMessage='All Work Order'
        />}
         btntitlelink = '/work-order' postButton={<Link  to="/schedule-work-order" className='rounded-lg font-bold bg-[#2557A7] px-5 py-3  text-white cursor-pointer hover:bg-navy' ><FormattedMessage id="RECRUITER.WORKORDER.ADD.WORK.ORDER" defaultMessage="Add Work Order" />
         </Link>}  className='px-10'></TopBar>
         <ContentWithSpinner loader={taskmanageLoader} height="h-screen">
         <div className='px-10'>
         <div className='flex'>
    <div className='bg-white rounded-lg p-5 w-[120rem] shadow-boxshadowpage'>
    <div className='flex space-x-5 mb-5'>
       <div className='w-40 h-40'>
        <div className='transform lg:transform-none -translate-x-1/2'>
        <img alt="Profile" className="rounded" src="/media/icons/displayProfilePlaceholder.png" />
    </div></div>
<div className='pb-5 pt-2'>
  <h1 className='text-3xl font-satoshiBold text-[#595959]'>{workorder?.contractor_id?.first_name} {workorder?.contractor_id?.last_name}</h1>
  <div className="mt-3 font-700 text-[16px]">
                      <FontAwesomeIcon icon={faEnvelope} className='pr-1' /> <EmailLink email={workorder?.contractor_id?.email} subject="Hello" body="Hi, how are you?"></EmailLink>
                    </div>
                    <div className="mt-2 text-[14px]">
                      <FontAwesomeIcon icon={faPhone} className='pr-3' />
                      {PhoneNumberFormat(workorder?.contractor_id?.mobile_number)}
                    </div>
                    <div className="flex justify-start space-x-3 items-center">
                    <div className="mt-2 text-[14px]">
                      <FontAwesomeIcon icon={faMapMarker} className='pl-1 pr-3 cursor-pointer' />
                      
                      {workorder?.contractor_id?.address?.address_line_1} {workorder?.contractor_id?.address?.address_line_2} {workorder?.contractor_id?.address?.city}  {workorder?.contractor_id?.address?.state}
                    </div>
                    </div>
</div>
</div></div>
      </div>
</div>
{isTaskSubmitted ?  

<div className="pt-3 w-full px-10">
  <div className=" bg-white rounded-lg">
<div className="items-start border-b border-neutral-200">
  <div className="flex w-full divide-x-2">
  <p className="font-satoshiBold text-xl border-b-2 text-center py-3 w-[30%]">Task Details</p>
  <div className="w-[40%]">
  <p className="font-satoshiBold text-xl border-b-2 text-center py-3">Contractor</p>
  <div className="flex">
  <p className="text-base font-satoshiBold border-b-2 py-3 text-center w-[25%]">Status</p> 
  <p className="text-base font-satoshiBold border-b-2 py-3 text-center w-[25%]">Remark</p> 
  <p className="text-base font-satoshiBold border-b-2 py-3 text-center w-[25%]">Image</p> 
  <p className="text-base font-satoshiBold border-b-2 py-3 text-center w-[25%]">Video</p> 
  </div>
  </div>
  <div className="w-[30%]">
  <p className="font-satoshiBold text-xl border-b-2 text-center py-3">Supervisor</p>
  <div className="flex">
  <p className="text-base font-satoshiBold border-b-2 py-3 text-center w-[50%]">Status</p> 
  <p className="text-base font-satoshiBold border-b-2 py-3 text-center w-[50%]">Remark</p> 
  </div>
  </div>
  </div>
  {tasks?.map((data, index)=> (
  <div className="flex divide-x-2 border-b-2">
  <div className="w-[30%]">
   <div className="pl-3">
   <h2 className=""><span className="font-satoshiBold"><FormattedMessage id="SUPERVISOR.PENDING.JOB.TASK.TITLE" defaultMessage="Task" />:</span> {data?.name}</h2>
   <div>{data?.description}</div>
   <div><span className="font-satoshiBold">Type:</span> {data?.type}</div>   </div>
   </div>
   <div className="w-[40%]">
  
      <div className="flex text-center">
            <div className="w-[25%]">
         
             <p>{managetasks?.find((item)=>item?.task_id?._id === data?.id)?.contractor_status|| 'Not Completed'} 
             </p>
            </div>
            <div className="w-[25%]">
             <p>{managetasks?.find((item)=>item?.task_id?._id === data?.id)?.contractor_remarks || 'Not Available'}</p>
            </div>
            <div className="w-[25%] flex justify-center">
{managetasks?.find((tasks)=> tasks?.task_id?._id === data?.id)?.images?.length>0 ?
    <svg onClick={()=> HandleImageView(data?.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>
:
<p>Empty</p>
}

    </div>
    <div className="w-[25%] flex justify-center">
    {managetasks?.find((tasks)=> tasks?.task_id?._id === data?.id)?.videos?.length>0 ?
    <svg onClick={()=> HandleVideoView(data?.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>
:
<p>Empty</p>
}
    </div>
      </div>
   </div>
   <div className="w-[30%]">
  
   <div className="flex text-center">
            <div className="w-[50%]">
           
             <p>{managetasks?.find((item)=>item?.task_id?._id === data?.id)?.supervisor_status || 'Pending'}</p>
            </div>
            <div className="w-[50%]">
           
             <p>{managetasks?.find((item)=>item?.task_id?._id === data?.id)?.supervisor_remarks|| 'Not Available'}</p>
            </div>
      </div>
   </div>
   </div>
   ))}
   {(submittask && submittask[0]?.contractor_status==='completed') && (submittask && submittask[0]?.supervisor_status==='completed') &&
   <div className="py-5">
   <div className="text-center">
    <p>Work Order completed with approved {submittask && submittask[0]?.approve_time} hours successfully</p>
   </div>
   <div className="text-center">
   <div>{workorder?.pay_type} : $  {workorder?.pay_type_rate}</div>
   <div><span>Total Amount</span> : $ {calculateAmount(workorder?.pay_type, workorder?.pay_type_rate, submittask[0]?.approve_time)}</div>
   </div>
   <div className="flex justify-center mt-3">
   <button className='rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy'>
   Make Payment
 </button>
 </div>
 </div>
}
   </div>
   </div>

   </div>
:
<Empty text={'Tasklist is not updatd from both side Contractor/Supervisor'}/>}
   </ContentWithSpinner>
   </div>
   <ModelWrapperWithButton showModal={previewImages?.length} onCheckModel={onCheckModel}>
       <div className="flex justify-center">
   {previewImages?.map((preview, index)=> (
 <img className="w-48" src={preview}></img>
))}
 </div>
       </ModelWrapperWithButton>
       <ModelWrapperWithButton showModal={previewVideo?.length} onCheckModel={onCheckModel} nofooter={true}>
       <div className="flex justify-center">
   {previewVideo?.map((preview)=> ( 
         <video className="h-96 w-96" controls>
         <source src={preview} type="video/mp4" />
       </video>
 
))}
 </div>
       </ModelWrapperWithButton>
        </MainWrapper>
  );
};
