import React, {useState, useEffect} from 'react'
import { InputSearch } from 'sr/helpers/InputSearch';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from 'sr/helpers';
import { ModelWrapper } from 'app/pages/company/component/ModelWrapper';
import { RateRange } from '../workOrder/RateRange';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
export const HideShow = (props) => {
  const intl = useIntl()
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [Filterdata, setFilterdata] = useState([]);
   const [search, setSearch] = useState('')
   useEffect(()=> {
    const filter =  props?.data?.filter((item)=> item?.value?.toLowerCase().includes(search?.toLowerCase()))
    setFilterdata(filter)
     },[search, setSearch])
    const toggleShow = () =>{
        setShow(!show)
    }
    const HandleSubmit = (e:any)=>{
      e.preventDefault()
      props?.setAddress(e.target[0].value)
    }
    
    return (
    <>
    <div className='cursor-pointer border-b-[1px]'>
      <div onClick={toggleShow} className='font-bold py-4 flex justify-between'>
      {props.Title} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-gray-500 w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
</svg>
</div>
<div className={`mb-10  overflow-y-auto	${show ? "block": "hidden"}`}>
    {props.SearchShow ? (
<InputSearch onClick={() => setShowModal(true) } placeholder={props?.searchPlaceholder}>
                                                </InputSearch>
    ) : null }
 {
  props?.data?.map((datI, index) => {
    return index < 4 ? (
      <Checkbox
        key={index}
        id={datI?.value}
        value={datI?.value}
        checked={props?.newData?.includes(datI?.value)}
        onChange={props?.onChange}
        label={typeof(datI?.label)==='string' ? <FormattedMessage id={datI?.label}></FormattedMessage> : datI?.label}
      />
    ) : null;
  })
} 
{props?.name==='rate' && 
<RateRange nohideShow={true} HandleRangeData={props?.HandleRangeData} viewmore={false} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.WORKORDER.FILTER.RATERANGE', defaultMessage:'Rate Range'})}/>

}
{props?.viewmore &&
<p onClick={() => setShowModal(true) } className='text-sm pl-5 mt-3 font-bold  text-[#275df5] cursor-pointer'>
 View More
</p> 
}
{props?.name==='customlocation' && 
 <form onSubmit={(e:any)=> HandleSubmit(e)} className='relative flex border rounded-3xl border-neutral-200 mt-5'>
                                                <input type='search' className='block w-full md:px-4 sm:px-1 md:py-2 sm:py-1 sm:text-sm md:text-md text-gray-700 placeholder-gray-500 bg-white border border-gray-border rounded-2xl
                        focus:border-sky-200 focus:ring-sky-200 focus:outline-none focus:ring focus:ring-opacity-40'
                                                    placeholder={'Enter Location'}>
                                                </input>
                              <button className='h-8  w-8 rounded-full bg-[#ff39b3] font-bold flex justify-center items-center absolute top-1 right-2'>              
                              <FontAwesomeIcon icon={faMagnifyingGlass} className='text-white text-lg font-bold ' />
                              </button>   
                              </form>
}
    </div>
    
    </div>
    {showModal &&
 <ModelWrapper showModal={showModal} ModelWrapper
 rounded="rounded-[10px]"
 background="bg-[#00000099]"
 position="fixed"
 opacity="opacity-100"
 topPosition="top-[10%]"
 rightPosition="inset-0"
 width="w-[50%] mx-auto">
          <div className="cursor-pointer justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative  my-6 max-w-3xl w-4/5 h-4/5">
              {/*content*/}
              <div className="border-0 bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300  relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Location 
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  
                  >
                    <span onClick={()=> setShowModal(false)} className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='ml-4 max-w-[15rem]'>
                <InputSearch placeholder={props?.searchPlaceholder} onChange={(e:any)=> setSearch(e.target.value)} value={search}>
                                              </InputSearch>
                                              </div>
                {/*footer*/}
                <div className="grid grid-cols-3 gap-4 p-6 rounded-b">
                {
Filterdata?.map((datI, index) =>  {                                    
 return <Checkbox
 key={index}
 id={datI?.value}
        value={datI?.value}
        checked={props?.newData?.includes(datI?.value)}
        onChange={props?.onChange}
        label={typeof(datI?.label)==='string' ? <FormattedMessage id={datI?.label}></FormattedMessage> : datI?.label}
      />
})}
                </div>
              </div>
            </div>
          </div>
 </ModelWrapper>
}
    </>
  )
}
