export const  validateFile = (file) => {
    if (!file) {
      return 'Please upload file';
    }
    const allowedExtensions = ['doc', 'docx', 'rtf', 'pdf'];
  
    const extension = file.name.split('.').pop().toLowerCase();
  
    if (!allowedExtensions.includes(extension)) {
      return 'This format not allowed'; 
    }
  
    const maxSizeInBytes = 2 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      return 'File should of 2 MB'; 
    }
  
    return true;
  }
  