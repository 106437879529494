import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getApplication = async (params) => {
  try {
    return await get<ApiResponse<[]>>(`/application/?${params}`).then((res) => {
        if(res.success===true) {
            return res
        }
        else {
            return null
        }
    })
}
catch(e:any) {
    HandleApiError(e)
}

};
