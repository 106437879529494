import React from "react";
import { FormattedMessage } from "react-intl";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { CandidateSortType } from "sr/i18n/messages/setting";

export const SortCandidate = ({setSort, sort}) => {
  return (<DropDown  value={sort} onChange={(e)=> setSort(e.target.value)} className = "md:py-3 rounded-lg hover:text-[#0078ae] hover:border-[#0078ae]" dropdowndiv="mt-0">
    <option selected> <FormattedMessage id="RECRUITER.CANDIDATES.FILTERS.SORT.SELECT.TITLE" defaultMessage="Select sort" /></option>
    {CandidateSortType.map((item, index)=> (
    
    <option value={item.value}>{item.label} </option>
 
 ))}

  </DropDown>);
};
