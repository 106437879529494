import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useValidationErrorHandle } from "../functions/validationErrorHandle";

export const ReactDatePicker = (props) => {
  const {isDefaultSelected = true} = props;
  const validationErrorHandle = useValidationErrorHandle()
    const [startDate, setStartDate] = useState(isDefaultSelected ? new Date() : null);
    useEffect(() => {
      if (props.selected) {
          const dateInIndiaTimeZone = new Date(props.selected); // Date in India timezone
          const dateInUSATimeZone = new Date(dateInIndiaTimeZone.toLocaleString('en-US', { timeZone: 'America/New_York' })); // Convert to USA timezone
          setStartDate(new Date(dateInUSATimeZone));
      }
  }, [props.selected]);


    return (
        <div className={props.InputDivClass}>
        {props.label ? (
          <label className={` ${props.labelClass} text-normal font-satoshiBold`}>
            {props.label}
          </label>
        ) : (
          ''
        )
        }
        <DatePicker 
      placeholderText="mm / dd / yyyy"
 name={props.name} className={`${props.inputClass} block w-full md:px-4 sm:px-1 md:py-2 sm:py-1 sm:text-sm md:text-md text-gray-700 placeholder-gray-500 bg-white border border-gray-border rounded-2xl
                        focus:border-sky-200 focus:ring-sky-200 focus:outline-none focus:ring focus:ring-opacity-40`} 
                        selected={startDate}
                        dateFormat="MM/dd/yyyy"
                        timeZone="America/New_York"
                         onChange={props.onChange}
                         minDate={props?.minDate ? new Date(props?.minDate) : null} />
         {props.error ? (
            <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(props.error)}</p>
          ) : (
            ''
          )}
        </div>
       
      );
    
};
ReactDatePicker.defaultProps = {
    InputDivClass:'mt-10',
    labelClass: 'mt-2 text-gray-700 text-sm',
  }