import React, {useState} from 'react'
import { ModelWrapper } from '../../component/ModelWrapper'
export const Filter = ({children, ...props}) => {
    const [showModal, setShowModal] = useState(false)
    const HandleModelByProps = (data) => {
        setShowModal(data)
       }
  return (
    <div className={`${props.mainClass ? props.mainClass : null} relative`}>
    <label className='block '>{props.label}</label>
 <div onClick={()=> setShowModal(!showModal)} className='bg-white hover:text-[#0078ae] cursor-pointer py-1.5 border border-[#d4d2d0] hover:border-[#0078ae] rounded-lg flex justify-between space-x-2 items-center px-4'>
  <span>{props.name}</span>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg></div>
{showModal &&
<ModelWrapper HandleModelByProps = {HandleModelByProps} showModel = {showModal} width = 'w-56' topPosition='top-12'>
  <div className='px-3 py-1.5 text-black'>
    {children}
</div>
</ModelWrapper>
}
</div>
  )
}
