import React from "react";
import { RadioInput } from "sr/helpers/ui-components/RadioInput";
import { CheckBox } from "sr/helpers/ui-components/company/CheckBox";
import { FormattedMessage } from "react-intl";
export const Notification = () => {
  return   (  
    <div className="flex justify-between items-center mb-3 pb-3 border-b">
   <table className="condidate-table">
    <thead className="font-satoshiBold border-b border-neutral-200 py-3 capitalize">
      <tr>
        <th className="text-left py-3 text-sm font-satoshiBold">
        <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TABLE.COL.FOC" defaultMessage="Form of Communication" />
        </th>
        <th className="px-6 py-3 text-sm font-satoshiBold">
        <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TABLE.COL.EMAIL" defaultMessage="Email" />
        </th>
        <th className="px-6 py-3 text-sm font-satoshiBold">
        <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TABLE.COL.SMS" defaultMessage="SMS" />
        </th>
        <th className="px-6 py-3 text-sm font-satoshiBold">
        <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TABLE.COL.PUSH_N" defaultMessage="Push Notification" />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td className="py-3"><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TABLE.ROW.TASK" defaultMessage="Task Updates" /></td>

      <td>
      <div className="flex items-center justify-center">
        <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
        </div>
        </td>
        <td>
      <div className="flex items-center justify-center">
        <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
        </div>
        </td>
        <td>
      <div className="flex items-center justify-center">
        <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
        </div>
        </td>
      </tr>
      <tr>
      <td><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.NOTIFICATION.TABLE.ROW.PROMOTION" defaultMessage="Promotion Emails and Notifications" /></td>

      <td>
      <div className="flex items-center justify-center">
        <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
        </div>
        </td>
        <td>
      <div className="flex items-center justify-center">
        <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
        </div>
        </td>
        <td>
      <div className="flex items-center justify-center">
        <input id="checkbox-table-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "/>
        </div>
        </td>
      </tr>
    </tbody>
   </table>
  </div>
  )
};
