import accessAxios from 'libraries/http/AccessHtpp'
// import { loginUserQuery } from "../graphql/auth"

type LoginUserType = {
  email: string
  password: string
}

class AuthHttpHandle {
  // empty
  // login user apis
  async userLogin(data: any) {
    return accessAxios.post('/', {
      variables: {
        email: data.email,
        password: data.password,
      },
    })
  }
}

export default new AuthHttpHandle()
