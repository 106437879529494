import { useEffect, useState } from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import InputField from "sr/helpers/ui-components/InputField";
import { Input, TextArea, toAbsoluteUrl } from "sr/helpers/index";
import { Link, useNavigate } from "react-router-dom";
import AutoLocation from "sr/helpers/ui-components/AutoLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import CleanerPersonalInfo from "./CleanerPersonalInfo";
// import CleanerDocuments from "./CleanerDocuments";
import CleanerReference from "./CleanerReference";
import CleanerEmployment from "./CleanerEmployment";
import CleanerTraining from "./CleanerTraining";
import CleanerMedicalCondition from "./CleanerMedicalCondition";
import CleanerPreferences from "./CleanerPreferences";
import { FormattedMessage } from "react-intl";
import JobType from "./JobType";
export default function CleanerProfile() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("PersonalInfo");
  const [refrences, setRefrences] = useState([{ name: "", expand: true }]);
  const [empHty, setEmpHty] = useState([{ name: "", expand: true }]);
  const [training, setTraining] = useState([{ name: "", expand: true }]);
  const [medicalCdn, setMedicalCdn] = useState([{ name: "", expand: true }]);
  const [latLng, setLatLng] = useState("");
  const [stepperIndex, setStepperIndex] = useState(0);
  
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log(position.coords.latitude, position.coords.longitude);
        setLatLng(position.coords.latitude + "," + position.coords.longitude);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }, []);

  // const toggleReferenceSection = (index: number) => {
  //   let referenceList = refrences;
  //   referenceList[index].expand = !referenceList[index].expand;
  //   setRefrences([...referenceList]);
  // };

  // const toggleEmpHstySection = (index: number) => {
  //   let empHtyList = empHty;
  //   empHtyList[index].expand = !empHtyList[index].expand;
  //   setEmpHty([...empHtyList]);
  // };

  // const toggleTrainingSection = (index: number) => {
  //   let trainingList = training;
  //   trainingList[index].expand = !trainingList[index].expand;
  //   setTraining([...trainingList]);
  // };

  // const toggleMedicalConditionSection = (index: number) => {
  //   let medicalCndList = medicalCdn;
  //   medicalCndList[index].expand = !medicalCndList[index].expand;
  //   setMedicalCdn([...medicalCndList]);
  // };

  const HandleStepper = (data) => {
    setStepperIndex(data)
  }
  return (
    <>
      <AuthNavComponent></AuthNavComponent>
      <main>
      <div className='lg:container lg:flex mx-auto lg:max-w-6xl lg:my-8 my-8 px-4 lg:px-0 block'>
          <div className='lg:flex lg:flex-col lg:w-80 w-full block p-6 items-center bg-white border border-gray-border rounded-xl h-fit mb-4'>
            <ol className="relative text-gray-500 border-l border-gray-800">
              <li onClick={()=> setStepperIndex(0)} className="mb-10 ml-6 cursor-pointer">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 0 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 0 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.REGISTER.PERSONALINFO.TITLE"   defaultMessage='Personal Info'/></h3>
        
              </li>
              {/* <li className="mb-10 ml-6">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 1 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 1 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.REGISTER.DOCUMENTS.TITLE" defaultMessage="Documents" /></h3>
               
              </li> */}
              <li onClick={()=> setStepperIndex(1)} className="mb-10 ml-6 cursor-pointer">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 2 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 1 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.PROFILE.REFERENCE.TITLE" defaultMessage="Reference" /></h3>
                
              </li>
              <li onClick={()=> setStepperIndex(2)} className="mb-10 ml-6 cursor-pointer">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 3 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 2 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.REGISTER.EMPLOYMENT.TITLE" defaultMessage="Employment" /></h3>
               
              </li>
              <li onClick={()=> setStepperIndex(3)} className="mb-10 ml-6 cursor-pointer">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 4 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 3 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.REGISTER.JOBTYPE.TITLE" defaultMessage="Job Type" /></h3>
               
              </li>
              <li onClick={()=> setStepperIndex(4)} className="mb-10 ml-6 cursor-pointer">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 5 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 4 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.REGISTER.TRAINING.TITLE" defaultMessage="Training" /></h3>
                
              </li>
              <li onClick={()=> setStepperIndex(5)} className="mb-10 ml-6 cursor-pointer">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 6 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 5 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.REGISTER.MEDICALCONDITION.TITLE" defaultMessage="Medical Condition" /></h3>
                
              </li>
              <li onClick={()=> setStepperIndex(6)} className="ml-6 cursor-pointer">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 7 ? 'bg-green-500' : 'bg-gray-500'}`}>
                  <FontAwesomeIcon icon={stepperIndex > 6 ? faCheck : faCircle} className='text-white text-xs' />
                </span>
                <h3 className="font-medium leading-tight"><FormattedMessage id="CLEANER.REGISTER.PREFERENCES.TITLE" defaultMessage="Preferences" /></h3>
               
              </li>
            </ol>
          </div>
          <div className='lg:flex-1 lg:flex lg:flex-col block h-fit lg:mx-12 mx-auto p-6 bg-white border border-gray-border rounded-xl'>
            {
              stepperIndex === 0 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.REGISTER.PERSONALINFO.TAB.TITLE" defaultMessage="Personal Information" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.REGISTER.PERSONALINFO.TAB.DESCRIPTION" defaultMessage="Personal information helps recuiters know about yourself" /></h3>
                <div className='max-w-lg'>
                  <CleanerPersonalInfo HandleStepper={HandleStepper}></CleanerPersonalInfo>
                </div>
              </div>
            }

            {/* {
              stepperIndex === 1 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.PROFILE.DOCUMENT.TITLE" defaultMessage="Documents" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.PROFILE.DOCUMENT.TEXT" defaultMessage="Documents helps recuiters know about your identity" /> </h3>
                <div className='max-w-lg'>
                  <CleanerDocuments HandleStepper={HandleStepper}></CleanerDocuments>
             
                </div>
              </div>
            } */}

            {
              stepperIndex === 1 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.PROFILE.REFERENCE.TITLE" defaultMessage="Reference" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.PROFILE.REFERENCE.TEXT" defaultMessage="Reference information helps recuiters know about your referral person" />
</h3>
                <div className='max-w-lg'>
                  <CleanerReference HandleStepper={HandleStepper}></CleanerReference>
                 
                </div>
              </div>
            }

            {
              stepperIndex === 2 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.PROFILE.EMLOYMENT.TITLE" defaultMessage="Employment" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.PROFILE.EMLOYMENT.TEXT" defaultMessage="Employment details helps recuiters understand your background" />
</h3>
                <div className='max-w-lg'>
                  <CleanerEmployment HandleStepper={HandleStepper}></CleanerEmployment>
                 
                </div>
              </div>
            }

            {
              stepperIndex === 3 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.REGISTER.JOBTYPE.TAB.TITLE" defaultMessage="Add your Job Type" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.PROFILE.JOBTYPE.TEXT" defaultMessage="Job Type details helps recuiters understand your Job Type" /></h3>
                <div className='max-w-lg'>
                  <JobType HandleStepper={HandleStepper}></JobType>

                </div>
              </div>
            }

            {
              stepperIndex === 4 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.REGISTER.TRAINING.TAB.TITLE" defaultMessage="Add your recent training" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.PROFILE.TRAINING.TEXT" defaultMessage="Training details helps recuiters understand your recent training" />
</h3>
                <div className='max-w-lg'>
                  <CleanerTraining HandleStepper={HandleStepper}></CleanerTraining>
               
                </div>
              </div>
            }

            {
              stepperIndex === 5 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.REGISTER.MEDICAL.TAB.TITLE" defaultMessage="Add your recent medical condition" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.REGISTER.MEDICAL.TAB.TITLE" defaultMessage="Add your recent medical condition" /></h3>
                <div className='max-w-lg'>
                  <CleanerMedicalCondition HandleStepper={HandleStepper}></CleanerMedicalCondition>
                  
                </div>
              </div>
            }

            {
              stepperIndex === 6 &&
              <div>
                <h1 className='font-satoshiBold text-xl'><FormattedMessage id="CLEANER.REGISTER.PREFERENCES.TAB.TITLE" defaultMessage="Add your preferences" /></h1>
                <h3 className='font-satoshi text-md'><FormattedMessage id="CLEANER.PROFILE.PREFERENCES.TEXT" defaultMessage="preferences details helps recuiters understand your preferences" /></h3>
                <div className='max-w-lg'>
                  <CleanerPreferences HandleStepper={HandleStepper}></CleanerPreferences>
                 
                </div>
              </div>
            }
          </div>
        </div>
      </main>
    </>
  );
}
