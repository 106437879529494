function convertToISOFormat(dateStr) {
    // Create a Date object from the input date string
    const date = new Date(dateStr);
  
    // Convert to ISO string and ensure it includes milliseconds
    const isoString = date.toISOString();
  
    return isoString;
  }

  export default convertToISOFormat