import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MainWrapper } from "../../component/MainWrapper";
import Form from "./components/Form";
import { Warning } from "sr/helpers/ui-components/company/Warning";

export const AddContractor = () => {
  const childRef:any = useRef()
  const HandleFormSubmit = ()=> {
    if (childRef.current && childRef.current.submitForm) {
      childRef.current.submitForm()
    }
  }
  
  return (
   <MainWrapper>
   <div className="py-3 h-screen">
    <Warning text="In this page, you can create a new Contractor. You can also convert a shortlisted Candidate into a Contractor from the Candidate page."/>
        <div className='mb-2 mt-5'>
        <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="RECRUITER.CONTRACTOR.CREATE.CONTRACTOR" defaultMessage="Create Contractor" /></h1>
    <p><FormattedMessage id="RECRUITER.CONTRACTOR.CREATE.CONTRACT.TEXT" defaultMessage="Use this page to create new Contractor" /></p>

            </div>
            <div className="mx-auto bg-white max-w-2xl px-10 border rounded-lg">
            <Form ref={childRef} />
            <div className="flex justify-center my-10">
            <button onClick={HandleFormSubmit} className='rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy'>
      <FormattedMessage id="RECRUITER.CONTRACTOR.CREATE.CONTRACTOR" defaultMessage="Create Contractor" />
</button>  
</div>
            </div>
            </div>
   </MainWrapper>);
};
