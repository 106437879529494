import React, {useState, useRef, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { InputSearch } from 'sr/helpers/InputSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faSearch } from '@fortawesome/free-solid-svg-icons'
import { initMap } from 'sr/initMap'
import AllCoordinate from './AllCoordinate'
import 'mapbox-gl/dist/mapbox-gl.css'
 const MapDisplay = () => {
    const [Filterdata, setFilterdata] = useState('');
    const [searchData, setSearchData] = useState('')
    const mapRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (mapRef.current) {
        initMap(
              mapRef.current,
              [77.694626, 8.741222],
              "BIHAR",
              "10",
              "Araria"
          )
      }
      
  }, []);

    const data = ['State', 'Cluster', 'District', 'Retailer', 'SHD', 'Farmer']
    const HandleFilterChange = (event) => {
        setFilterdata(event.target.value);
      };
      console.log(Filterdata)
const checkboxdata = [
    {name:"Total Market Size"},
    {name:"Total Quantity"},
    {name:"Speed of Sales"}
]

  return (
    <main>
    <div className='bg-[#e0f2fe]'>
        <div className='flex lg:container mx-auto lg:max-w-7xl px-4 lg:px-0 space-x-8 py-1'>
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
</svg>
        </div>
      <div className='flex space-x-8'>
        {[{}, {}, {}, {}].map((data)=> (
       <Link className='font-medium active:text-[#3b82f6] active:border-b-2 active:border-[#3b82f6]' to="#">Insight-1 </Link>
        ))}
      </div></div>
    </div>
    <div className='grid grid-cols-12 gap-4 lg:max-w-7xl mx-auto'>
<div className='col-span-2'>
<div className='w-full'>
<div className={`w-full rounded-lg border px-2 my-2 border-slate-400`}>
<FontAwesomeIcon icon={faMagnifyingGlass} className='text-[#717b9e] text-lg font-satoshiBold' />
<input onChange={(e) => setSearchData(e.target.value)} value={searchData}  type='search' className={`w-40 h-8  px-1 py-1 text-gray-900 focus:outline-none`} placeholder="Enter Data">
                                                </input>
                                              </div></div>
</div>
<div className='col-span-6 bg-[#e0f2fe] my-1 flex space-x-2 items-center justify-center	rounded-lg'>
    {data.map((alldata)=> (
<span className='bg-white px-6 rounded-lg py-0.5'>{alldata}</span>
)) }
</div>
<div className='col-span-4 my-1 flex space-x-4 items-center justify-center'>
    <div className='flex space-x-2'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
<span>22May, 2023 - 29May, 2023</span></div>
<label className="relative inline-flex items-center cursor-pointer">
  <input type="checkbox" value="" className="sr-only peer"/>
  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
  <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Map</span>
</label>
<div className='flex items-center space-x-2 group  relative'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
</svg>
<span>Filter</span>
<div className="group-hover:block dropdown-menu absolute hidden h-auto z-10 right-0 top-6">
<ul className="w-64 bg-white shadow px-6 py-8 rounded-lg">
{checkboxdata.map((data)=> (
    <li className="py-1">
    <label className="flex items-center py-1.5 relative">
      <input
        type="radio"
        name={data.name}
        value={data.name}
        checked = {Filterdata === data.name}
        onChange={HandleFilterChange}
        className="appearance-none w-4 h-4 border-2 border-black rounded checked:bg-blue-500 checked:border-transparent"/>
      <span className="ml-2 text-sm	 text-gray-500 font-satoshiBold font-bold">{data.name}</span>
      <svg className="w-4 h-4 absolute left-0 top-1/2 transform -translate-y-1/2 pointer-events-none text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
    </label>
    </li>
    )) }
</ul>
</div>
</div>
</div>
</div>

<div className='h-screen'>
<div ref={mapRef} style={{ height: "100%" }} className='map' /></div>

    </main>
  )
}
export default MapDisplay
