import AccessAxios from "libraries/http/AccessHtpp";

class CustomerHttpHandle {

    /**
     * Create new customer
     * @param {data} any 
     * @returns {Promise}
     */
    async customerRegistration(data: any) {
      return AccessAxios.post('/customer', {
        name : data.customerName,
        contact_person: data.contactPerson,
        address_1 : data.addressLine1,
        address_2 : data.addressLine2,
        city : data.city,
        province: data.province, 
        status:"ACTIVE"
      });
    }
}

export default new CustomerHttpHandle()