import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createWorkorder = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/workorder/', postData).then((res)=> {
    if(res?.success===true){
        SuccessMessage('API.SUCCESS.WORKORDER.ADD')
        return res
    }
    else {
            HandleApiError(res.error.msg)
    }
  })
}catch(e) {
    HandleApiError(e)
}
};
