import React, {useState} from 'react'
import { InputSearch } from 'sr/helpers/InputSearch'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
export const TopBar = (props) => {
    const [searchShow, setSearchShow] = useState(false)
    const intl = useIntl()
  return (
    <div className={`flex justify-between items-center pb-4 ${props.className}`}>
    <div className='flex items-center space-x-4'>
        <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'>{props.title}</h1>
    {props.btntitle &&    
    <Link  to={props.btntitlelink} className='text-xs rounded-lg font-bold bg-[#595959] px-5 py-3  text-white cursor-pointer hover:bg-navy' >{props.btntitle}
                                    </Link>
}
        </div> 
    <div className='flex space-x-4 justify-center items-center'>
      {searchShow ? (
    <div className='bg-white text-[#2557A7] px-3 border border-black rounded-lg cursor-pointer flex items-center'>
<InputSearch className="border border-transparent rounded-lg" placeholder={intl.formatMessage({
    id: 'INPUT.SEARCH.JOB.TITLES.PLACEHOLDER',
    defaultMessage: 'Search Candidates',
  })} height = 'h-8' borderColor='border-[#275df5]'>
                                  </InputSearch>
                                  <button type='submit' className='text-[#2557A7] rounded-lg m-1 px-3 py-2 font-satoshiBold border border-neutral-200'>Search</button>
                                  <svg onClick={() => setSearchShow(!searchShow)} className='w-10 h-10' xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="neutral.1000" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.575 18.01a.5.5 0 000 .707l.708.708a.5.5 0 00.707 0l6.01-6.01 6.01 6.01a.5.5 0 00.707 0l.707-.707a.5.5 0 000-.708L13.414 12l6.01-6.01a.5.5 0 000-.707l-.706-.708a.5.5 0 00-.707 0L12 10.586l-6.01-6.01a.5.5 0 00-.708 0l-.707.707a.5.5 0 000 .707l6.01 6.01-6.01 6.01z"></path></svg>
</div> ) : null }
{/* <div onClick={() => setSearchShow(!searchShow)} className='bg-white text-[#2557A7] p-3 border border-neutral-200 rounded-lg cursor-pointer'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>
</div> */}
{props?.postButton ?
props?.postButton :
<Link  to="/job-post/add-basic-details" className='rounded-lg font-bold bg-[#2557A7] px-5 py-3  text-white cursor-pointer hover:bg-navy' ><FormattedMessage id="RECRUITER.POST.A.JOB.BUTTON.TITLE" defaultMessage="Post a Job" />
                                </Link>

}
    </div>
</div>
  )
}
