import * as Yup from 'yup'

export const BasicDetailsSchema = Yup.object({ 
    job_title:Yup.string().required("INPUT.VALIDATION.JOBPOST.JOB.TITLE"),
    employment_type:Yup.array().min(1, 'INPUT.VALIDATION.JOBPOST.EMPLOYMENT.TYPE'),
    job_type:Yup.array().min(1, 'INPUT.VALIDATION.JOBPOST.JOBTYPE'),
    schedule:Yup.array().min(1, 'INPUT.VALIDATION.JOBPOST.SCHEDULE'),
    start_date:Yup.date().required('INPUT.VALIDATION.JOBPOST.STARTDATE'),
    show_pay_by:Yup.string().required('INPUT.VALIDATION.JOBPOST.SHOWPAYBY'),    
    min_amount : Yup.number().min(0).positive("INPUT.VALIDATION.MINAMOUNT.POSITIVE"),
    max_amount : Yup.number().min(0).positive("INPUT.VALIDATION.MAXAMOUNT.POSITIVE").nullable().notRequired()
    .test('is-greater', 'INPUT.VALIDATION.MAXAMOUNT.MAXAMOUNT', function(value) {
        const { min_amount } = this.parent;
        if(min_amount === null || min_amount === undefined){
          return true
        }
        if (value === null || value === undefined) {
          return true;
        }
        return value > min_amount;
      }),
    exact_amount : Yup.number().min(0).positive("INPUT.VALIDATION.EXACTAMOUNT.POSITIVE"),
    rate:Yup.string(),
    benefits:Yup.array().min(1, 'INPUT.VALIDATION.JOBPOST.BENIFIT'),
    job_description:Yup.string().required('INPUT.VALIDATION.JOBPOST.DESCRIPTION'),
    application_call_mobile_number:Yup.string().required("INPUT.VALIDATION.JOBPOST.APPLICATION.CALL"),
    require_resume:Yup.bool().required("INPUT.VALIDATION.JOBPOST.REQUIRE.RESUME"),
    notifications:Yup.bool().required("INPUT.VALIDATION.JOBPOST.NOTIFICATION"),    
})