import React, { useEffect, useState } from "react";
import { ModelWrapperWithButton } from "../component/ModelWrapperWithButton";
import AutoLocation from "sr/helpers/ui-components/AutoLocation";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { createCustomerLocation } from "sr/utils/api/company/customer/createCustomerLocation";

export const AddLocation = (props) => {
    const [remark, setRemark] = useState()
      const [address, setAddress] = useState()
            const [companyId, setCompanyId] = useState()
            const intl = useIntl()
            const [loader, setLoader] = useState(false)
useEffect(()=> {
const {user_id} = getUserDetails()
setCompanyId(user_id)
      },[])
      const initialValues={
        customer_id:props?.customerId,
          company_id:companyId,
        address:address,
        remarks: remark,
      }
      const HandleAutoLocation = (data)=> {
        console.log(data);
        
        setAddress(data)
          }
          const {errors, handleSubmit} = useFormik({
            initialValues:initialValues,
            enableReinitialize:true,
            onSubmit:async (values)=>{
                setLoader(true)
                    try {
                                const res = await createCustomerLocation(values)
                                if(res?.success===true){
                                    props?.fetchLocation(props?.customerId)
                                    props?.setLocation(res?.data?.id)
                                    setLoader(false)
                                    props?.onCheckModel()
                                }
                    }
                    catch(e){

                    }
            }
          })
            return (
    <ModelWrapperWithButton Save={handleSubmit} onCheckModel={props?.onCheckModel} showModal={props?.showAddModel}>
    <form>
                    <AutoLocation HandleAutoLocation={HandleAutoLocation}></AutoLocation>   
                    <div className='mt-10'>
                    <label htmlFor="Remarks" className=" mb-2 text-sm  flex items-center">
                      <FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TEXTAREA.TITLE.REMARKS" defaultMessage="Remarks" /></label>
    <textarea onChange={(e:any)=> setRemark(e.target.value)} value={remark} id="Remarks" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#0078ae] focus:shadow-inputshadow outline-0" placeholder={ intl.formatMessage({ id:"RECRUITER.CUSTOMER.INPUT.TEXTAREA.PLACEHOLDER", defaultMessage:"Write your note here..." })}></textarea>        
    </div>  </form>
    </ModelWrapperWithButton>
);
};
