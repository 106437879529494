import React from 'react'
import Footer from 'app/pages/module/Footer'
import AuthNavComponent from 'app/pages/module/auth/AuthNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle, faCircleCheck, faStar } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
export const AppliedIndividualJobs = () => {
    const stepperIndex = 2;
    const count = 5;
    const totalApplied = 8;
  return (
    <>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <main>
        <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
            <div className='card-1 p-6 bg-white border border-gray-border rounded-xl'>
                <div className='statistics flex items-center'>
                    <div className='font-bold  text-5xl mr-3'>
                        01
                    </div>
                    <div>
                        <span><FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.TOTAL" defaultMessage="Total" />    </span>
                        <br />
                        <span><FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLIES" defaultMessage="applies" /> </span>
                    </div>
                    <div className='border border-r border-black h-10 mx-4'></div>
                    <div className='font-bold  text-5xl mr-3'>
                        00
                    </div>
                    <div>
                        <span><FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLICATION" defaultMessage="Application" /> </span>
                        <br />
                        <span><FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.UPDATES" defaultMessage="updates" /> </span>
                    </div>
                </div>
            </div>
        </div>
        <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
        <div className='lg:flex block bg-white border border-gray-border rounded-xl'>

            <div className='flex flex-col lg:w-2/5 w-full left-panel border-r'>
                <div className='card-1 p-6 mb-5'>
                    <div className='statistics flex items-center tab-container mb-5'>
                        <div className='font-semibold text-sm text-gray-400 border border-gray-300 py-1 px-2 rounded-3xl mr-3'>
                        <FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.RECRUITER.ACTION.STATUS" defaultMessage="Recruiter Actions {count}" values={{count}} />
                        </div>

                        <div className='font-semibold text-sm text-gray-900 border border-gray-300 py-1 px-2 rounded-3xl'>
                        <FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.TOTAL.APPLIED.STATUS" defaultMessage="Applied on JM {totalApplied}" values={{totalApplied}} />
                        </div>
                    </div>

                    <div className='cursor-pointer'>
                        <h1 className='text-base font-bold  mb-1'>Front End Developer</h1>
                        <h3 className='text-sm font-bold  mb-3'>Staffing Root</h3>

                        <div className='flex justify-between items-center'>
                            <div className='text-xs font-semibold text-gray-800 border border-gray-300 py-1 px-2 rounded-3xl'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-green-500'></FontAwesomeIcon>
                                <FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLICATION.TIME.STATUS" defaultMessage="Application sent {countnumber} week ago" values={{countnumber:'5'}}/>  
                            </div>
                            <div className='text-xs text-gray-500'>
                            <FormattedMessage id="CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLICATION.RECRUITERACTIVE.STATUS" defaultMessage="Recruiter last active {countnumber} ago" values={{countnumber:'5'}} />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex flex-col w-2/3 h-fit p-6 right-panel'>
                <div className='header'>
                    <h1 className='font-bold  text-xl'>Sr. Front End Developer - React</h1>
                    <div className='flex items-center mb-7'>
                        <span className='text-base text-gray-500 font-semibold  mr-1'>ICE Data Services</span>
                        <span className=''>
                            <FontAwesomeIcon icon={faStar} className='text-yellow-500 text-sm px-1' />
                            <span className='text-sm text-gray-700'>3.5</span>
                        </span>
                        <div className='border border-r m-2 border-gray-300 h-3'></div>
                        <span className='text-sm text-gray-700'>59 <FormattedMessage id='CLEANER.JOBS.REVIEWS'  defaultMessage={'Reviews'}/></span>
                    </div>

                    <div className='border-b'></div>

                    <div className='mt-7'>
                        <strong className='text-lg font-bold '> <FormattedMessage id='CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLICATION.STATUS'  defaultMessage={'Application Status'} /></strong>
                        <ol className="relative text-gray-500 border-l border-gray-800 mt-2">
                            <li className="mb-10 ml-6">
                                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 0 ? 'bg-green-500' : 'bg-gray-500'}`}>
                                    <FontAwesomeIcon icon={stepperIndex > 0 ? faCheck : faCircle} className='text-white text-xs' />
                                </span>
                                <h3 className="font-bold  leading-tight text-gray-800"><FormattedMessage id='CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLIED'  defaultMessage={'Applied'} /></h3>
                                <p className="text-sm">29 May 23</p>
                            </li>
                            <li className="mb-10 ml-6">
                                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 1 ? 'bg-green-500' : 'bg-gray-500'}`}>
                                    <FontAwesomeIcon icon={stepperIndex > 1 ? faCheck : faCircle} className='text-white text-xs' />
                                </span>
                                <h3 className="font-bold  leading-tight text-gray-800"><FormattedMessage id='CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLICATION.SENT'  defaultMessage={'Application Sent'} /></h3>
                                <p className="text-sm">29 May 23</p>
                            </li>
                            <li className="mb-10 ml-6">
                                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 2 ? 'bg-green-500' : 'bg-gray-500'}`}>
                                    <FontAwesomeIcon icon={stepperIndex > 2 ? faCheck : faCircle} className='text-white text-xs' />
                                </span>
                                <h3 className="font-bold  leading-tight text-gray-800"><FormattedMessage id='CLEANER.INDIVIDUAL.JOBS.APPLIEDJOBS.APPLICATION.RECRUITER.ACTION'  defaultMessage={'Awaiting Recruiter Action'} /></h3>
                                {/* <p className="text-sm">Step details here</p> */}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div></div>
    </main>
   <Footer />
</>
  )
}
