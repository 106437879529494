import React, { useEffect, useState } from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage } from 'react-intl'
export const HireNumberChange = (props) => {
  const [hireCount, setHireCount] = useState()

  useEffect(()=> {
      setHireCount(props?.data?.job_advanced_id?.hire_count)
  }, [props?.data])

  useEffect(()=> {
    const data = {
      hire_count:hireCount
    }
    props?.HandleDataProps(data)
  },[hireCount])
  return (
    <DropDown onChange={(e)=> setHireCount(e.target.value)} value={hireCount} name='selectjob' label={<FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.NUMBEROFPEOPLE.TITLE" defaultMessage="Number of people you wish to hire for this job?" />
  } className="h-11 mt-2 border border-zinc-800 rounded-lg">
                      <option selected><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.NUMBEROFPEOPLE.OPTIONS" defaultMessage="Select an option" /></option>
                      {Array.from({ length: 10 }, (_, index) => (
                      <option key={index + 1} value={index + 1}>{index + 1}</option>
                      ))}
                  </DropDown>
              
  )
}
