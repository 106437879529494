import React, { ReactNode, useEffect, useState } from 'react'
import { Input, Spinner, TextArea } from 'sr/helpers'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import additionalInfo from 'sr/utils/schemas/company/additionalInfo';
import { ReactDatePicker } from 'sr/helpers/ui-components/ReactDatePicker';
import { createAdditionInfo } from 'sr/utils/api/company/createAdditionInfo';
import { getUserDetails } from 'sr/helpers/functions/getUserDetails';
import { getAdditionInfo } from 'sr/utils/api/company/getAdditionInfo';
import { parse } from 'date-fns';
import { DateFormat } from 'sr/helpers/functions/DateFormat';
import { updateAddInfo } from 'sr/utils/api/company/updateAddInfo';
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth';
import { uploadFile } from 'sr/utils/api/uploadFile';
import { getFile } from 'sr/utils/api/getFile';
import { toast } from 'react-toastify';
import { getFiles } from 'sr/utils/api/getFiles';
import { scrollToError } from 'sr/helpers/functions/scrollToError';
export default function CompanyAdditionalInfo({Steppar=null, type=''}) {
    
    const [dateIncorporation, setDateIncorporation] = useState(null);
    const [fbLink, setFbLink] = useState()
    const [website, setWebsite] = useState()
    const [twitterLink, setTwitterLink] = useState()
    const [logo, setLogo] = useState(null)
    const [logoimg, setLogoImg] = useState(null)
    const [noEmployee, setNoEmployee] = useState()
    const [noBranch, setNoBranch] = useState()
    const [annualRevenue, setAnnualRevenue] = useState()
    const [noClient, setNoClient] = useState()
    const [about, setAbout] = useState()
    const [CompanyId, setCompanyId] = useState()
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const intl = useIntl()
    const HandleImage = async (e:any)=> {
        const file: any = e.target.files[0]
    const formData = new FormData()
    formData.append('company_id', CompanyId);
    formData.append('profile_img', file);
    const result = await uploadFile(formData);
    if(result?.success===true) {
        setLogoImg(URL.createObjectURL(e.target.files[0]))
        setLogo(e.target.files[0])
    }
      }
      useEffect(()=> {
        const getdetails = getUserDetails()
        setCompanyId(getdetails?.user_id)
      },[])
    const initialValues = {
        company_id:CompanyId,
        company_website:website,
        social_link_fb:fbLink,
        social_link_twitter:twitterLink,
        company_logo_path:"Untitledh.png",
          no_of_employees: noBranch && parseInt(noEmployee, 10), 
          date_format: "yyyy/MM/dd",
          date_of_incorporation:dateIncorporation && dateIncorporation,
          number_of_branches:noBranch && parseInt(noBranch, 10),
          annual_revenue: annualRevenue && parseInt(annualRevenue, 10),
          no_of_clients: noClient && parseInt(noClient, 10),
          about_company: about,
    }

    useEffect(()=> {
        const fetchData = async () => {
            setLoader(true)
            try {
            const result = await getAdditionInfo(CompanyId, undefined)
            if(result?.success===true && result.data) {
                setLoader(false)
              setWebsite(result?.data?.company_website)
                 setFbLink(result?.data?.social_link_fb)
                 setTwitterLink(result?.data?.social_link_twitter)
                 setNoEmployee(result?.data?.no_of_employees)
                setDateIncorporation(result?.data?.date_of_incorporation)
                 setNoBranch(result?.data?.number_of_branches)
                 setAnnualRevenue(result?.data?.annual_revenue)
                 setNoClient(result?.data?.no_of_clients)
                 setAbout(result?.data?.about_company)
                 const params = {
                    company_id:CompanyId
                  }
              const profileImg = await getFiles(params)
              if(profileImg?.success===true) {
                const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
                if(filterfile && filterfile[0]?.file?.key) {
                  const fileimg = await getFile(filterfile[0]?.file?.key);
                  if(fileimg) {
                  const newImageUrl = URL.createObjectURL(fileimg);
                  setLogoImg(newImageUrl)
                  }}}
            }
            
            else {
                setLoader(false)
            }
        }
        catch(e) {
setLoader(false)
        }
        }
        if(CompanyId) {
            fetchData()
        }
    },[CompanyId])
useEffect(()=> {
console.log(noBranch);

},[noBranch])
    const {handleSubmit, errors} = useFormik({
        initialValues:initialValues,
        enableReinitialize:true,
        validationSchema: additionalInfo,
        onSubmit:async (values)=> {
            setButtonLoader(true)
            if(type==='setting' ) {
                const result = await updateAddInfo(values)
                if(result.success) {
                    setButtonLoader(false)
                }
            }
            else {
            const result = await createAdditionInfo(values)
            if(result.success === true) {
                setButtonLoader(true)
                Steppar(1)
            }}
        }

        }
    )
    useEffect(() => {
            scrollToError(errors)
           }, [errors]);
    return (
      
        <div>
             <ContentWithSpinner loader={loader} height='h-screen'>
            <form className='mt-5' onSubmit={handleSubmit}>
                <Input
                    onChange={(e)=> setWebsite(e.target.value)}
                    value={website}
                    label={<FormattedMessage id="RECRUITER.REGISTER.INPUT.WEBSITE" defaultMessage="Company Website" />}
                    placeholder={intl.formatMessage({id:"RECRUITER.REGISTER.INPUT.WEBSITE.PLACEHOLDER", defaultMessage:"Enter Company Website"})}
                    type={"text"}
                    error={errors.company_website}
                ></Input>
                <Input
                onChange={(e)=> setFbLink(e.target.value)}
                value={fbLink}
                    label={"Facebook Profile"}
                    placeholder={"Enter company facebook profile"}
                    type={"text"}
                    error={errors.social_link_fb}
                ></Input>

                <Input
                    label={"LinkedIn Profile"}
                    placeholder={"Enter company LinkedIn profile"}
                    type={"text"}
                    onChange={(e)=> setTwitterLink(e.target.value)}
                value={twitterLink}
                error={errors.social_link_twitter}
                ></Input>
                <div className="mt-5">
                    <label htmlFor="ImgInput">Company Logo
                    <div className="relative mt-5 w-14 h-14 overflow-hidden bg-gray-light rounded-full dark:bg-gray-light ring-2 ring-pink dark:ring-pink">
                       {logoimg ? 
                       <img alt="Profile " className="rounded-full w-14 h-14"  src={logoimg}></img>
                       : 
                        <svg
                            className="absolute w-16 h-16 text-gray-400 -left-1"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>}
                        {errors.company_logo_path ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{errors.company_logo_path as ReactNode}</p>
        ) : (
          ''
        )}
                    </div></label>
                    <input onChange={HandleImage} className="hidden" type="file" id="ImgInput" name="img" accept="image/*"></input>
                </div>
                <Input
                    label={"Number of Employees"}
                    placeholder={"Enter number of employees in company"}
                    type={"number"}
                    onChange={(e)=> setNoEmployee(e.target.value)}
                value={noEmployee}
                error={errors.no_of_employees}
                ></Input>
                <ReactDatePicker 
                    onChange={(date)=> setDateIncorporation(date)}
                    selected={dateIncorporation}
                    error={errors.date_of_incorporation}
                    label={'Date of Incorporation'}>
                </ReactDatePicker>

                <Input
                    label={"Number of Branches"}
                    placeholder={"Enter number of company branches"}
                    type={"number"}
                    onChange={(e)=> setNoBranch(e.target.value)}
                value={noBranch}
                error={errors.number_of_branches}
                ></Input>

                <Input
                    label={"Annual Revenue"}
                    placeholder={"Enter company annual revenue"}
                    type={"number"}
                    onChange={(e)=> setAnnualRevenue(e.target.value)}
                value={annualRevenue}
                error={errors.annual_revenue}
                ></Input>

                <Input
                    label={"Number of Clients"}
                    placeholder={"Enter number of clients of company"}
                    type={"number"}
                    onChange={(e)=> setNoClient(e.target.value)}
                value={noClient}
                error={errors.no_of_clients}
                ></Input>

                <TextArea
                    label={"About Company"}
                    placeholder={"Enter info about company"}
                    type={"number"}
                    onChange={(e)=> setAbout(e.target.value)}
                value={about}
                error={errors.about_company}
                ></TextArea>
                                                 <div className='mt-5'>
                                        <ButtonAuth loader={buttonLoader}
                                           >{type==='setting' ? 'Save' : 'Save and continue' }
                                        </ButtonAuth>
                                    </div>
            </form>
            </ContentWithSpinner>
        </div>
    )
}
