import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner';
import { getCustomerLocation } from 'sr/utils/api/company/customer/getCustomerLocation';
export const LocationDetailsView = ({id}) => {
  const [data, setData]= useState(null)
  const [loader, setLoader] = useState(false)
  const FetchCustomerLocation = async ()=> {
    try {
    setLoader(true)
    const queryParams = new URLSearchParams();  
    if (id) queryParams.append('id', id.toString());
    const result = await getCustomerLocation(queryParams)
    if(result?.success===true) {
      setData(result?.data)
      setLoader(false)
    }
    else {
      setLoader(false)
    }
  } 
  catch(e) {
    setLoader(false)
  }
  }
  useEffect(()=> {
    if(id) {
    FetchCustomerLocation()
    }
  },[id])

  return (
    <ContentWithSpinner loader={loader} height='h-28'>
    <div className='py-3 px-10'>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className=' text-base w-36'>   <FormattedMessage id="INPUT.ADDRESS1.TITLE" defaultMessage="Address 1" />:</span>
      <span>{data?.address?.address_line_1}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200 '>
      <span className=' text-base w-36'>   <FormattedMessage id="INPUT.ADDRESS2.TITLE" defaultMessage="Address 2" />:</span>
      <span>{data?.address?.address_line_2}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className=' text-base w-36'><FormattedMessage id="RECRUITER.CUSTOMER.TAB.LOCATION.TITLE" defaultMessage="City" />:</span>
      <span>{data?.address?.city}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className=' text-base w-36'><FormattedMessage id="RECRUITER.CUSTOMER.TAB.STATE.TITLE" defaultMessage="State" />:</span>
      <span>{data?.address?.state}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className=' text-base w-36'><FormattedMessage id="RECRUITER.CUSTOMER.TAB.ZIPCODE.TITLE" defaultMessage="Zip Code" />:</span>
      <span>{data?.address?.postal}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className=' text-base w-36'><FormattedMessage id="RECRUITER.CUSTOMER.TAB.COUNTRY.TITLE" defaultMessage="Country" />:</span>
      <span>{data?.address?.country}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className=' text-base w-36'><FormattedMessage id="RECRUITER.CUSTOMER.TAB.LATITUDE.TITLE" defaultMessage="Latitude" />:</span>
      <span>{data?.address?.lat}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className=' text-base w-36'><FormattedMessage id="RECRUITER.CUSTOMER.TAB.LONGITUDE.TITLE" defaultMessage="Longitude" />:</span>
      <span>{data?.address?.lng}</span>
    </div>
    <div className='flex space-x-3  mb-4 pb-4 border-b border-neutral-200'>
      <span className=' text-base w-36'><FormattedMessage id= "RECRUITER.CUSTOMER.INPUT.TEXTAREA.TITLE.REMARKS" defaultMessage='Remarks' /></span>
      <span>{data?.remarks}</span>
    </div>
  </div>
  </ContentWithSpinner>
  )
}
