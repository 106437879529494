import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useValidationErrorHandle } from "../functions/validationErrorHandle";

export const ReactDatePicker = (props) => {
  const {isDefaultSelected = true} = props;
  const validationErrorHandle = useValidationErrorHandle()
    const [startDate, setStartDate] = useState(isDefaultSelected ? new Date() : null);
    useEffect(() => {
      if (props?.selected) {
        console.log(props?.selected);
        console.log(typeof(props?.selected)==='string');
        
        
        if(typeof(props?.selected)==='string') {
          const formatted = new Date(props.selected)
          setStartDate(formatted)
        }
        else {
        setStartDate(props.selected);
        }
      }
    }, [props.selected]);
    return (
        <div className={props.InputDivClass}>
        {props.label ? (
          <label className={` ${props.labelClass} text-normal `}>
            {props.label}
          </label>
        ) : (
          ''
        )
        }
        <DatePicker 
        portalId="root"
      placeholderText="mm / dd / yyyy"
 name={props.name} className={`${props.inputClass} block w-full md:px-4 sm:px-1 md:py-2 sm:py-1 sm:text-sm md:text-md text-gray-700 placeholder-gray-500 bg-white border border-gray-border rounded-2xl
                        focus:border-sky-200 focus:ring-sky-200 focus:outline-none focus:ring focus:ring-opacity-40`} 
                        selected={startDate}
                        dateFormat="MM/dd/yyyy"
                        onChange={props?.onChange}                   
                        minDate={props?.minDate ? new Date(Date.UTC(new Date(props.minDate).getFullYear(), new Date(props.minDate).getMonth(), new Date(props.minDate).getDate())) : null}
                         
                         />
         {props.error ? (
            <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(props.error)}</p>
          ) : (
            ''
          )}
        </div>
       
      );
    
};
ReactDatePicker.defaultProps = {
    InputDivClass:'mt-10',
    labelClass: 'mt-2 text-gray-700 text-sm',
  }