import React, {useEffect, useState} from 'react'
import { ChecklistTemplatedata } from './ChecklistTemplatedata'
import { ChecklistTask } from './ChecklistTemplatedata'
import { FormattedMessage } from 'react-intl'
import { getChecklist } from 'sr/utils/api/company/customer/getChecklist'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { getTask } from 'sr/utils/api/company/customer/getTask'
export const AllChecklist = (props) => {
    const [showTask, setShowTask] = useState(null)
    const [checklist, setChecklist] = useState(null)
    const [loader, setLoader] = useState(false)
    const [task, setTask] = useState(null)
    const [taskLoader, setTaskLoader] = useState(false)
    const fetchTasks = async (id) => {
        try {
            setTaskLoader(true)
            const queryParams = new URLSearchParams()
            if(id) queryParams?.append('checklist_id',id )
            const res = await getTask(queryParams)
        if(res.success===true){
                setTask(res.data)
                setTaskLoader(false)
                return res
        }
        else {
                    setTaskLoader(false)
        }
        }
        catch(e){
                    setTaskLoader(false)
        }
    }
    const HandleTask = async (id) => {
        if(showTask === id) {
            setShowTask(null)
        }
        else {
            setShowTask(id)
        }
        fetchTasks(id)
    }
    useEffect(()=> {
            const fetchChecklist = async ()=> {
                const queryParams = new URLSearchParams()
                queryParams.append('filter', JSON.stringify({"customer_id":{"$exists": false}}))
                    try {
                        setLoader(true)
                            const res = await getChecklist(queryParams)
                            if(res?.success===true) {
                                setChecklist(res?.data)
                                setLoader(false)
                            }
                            else {
                                setLoader(false)
                            }
                    }
                    catch(e) {
                            setLoader(false)
                    }
            }
            fetchChecklist()
    },[])
    const HandleCopyTask = async (id) => {
       const res = await fetchTasks(id)
            if(res?.success===true) {
                console.log(res?.data);
                
                props?.ChecklistHandle(res?.data)
            }
    }
  return (
    <ContentWithSpinner loader={loader}>
    <div className="overflow-x-auto mt-4 rounded-[20px]">
                <table className="w-full text-left condidate-table">
                    <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
                        <tr>
                            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                            <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TABLE.COL.NAME" defaultMessage="Checklist Name" />
                            </th>
                            {/* <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                            <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TABLE.COL.TASKS" defaultMessage="Tasks" />
                            </th> */}
                            {/* <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                            <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TABLE.COL.TYPE" defaultMessage="Type" />
                            </th> */}
                            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                            <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TABLE.COL.SUBTYPE" defaultMessage="Sub Type" />
                            </th>
                            
                            <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                            <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TABLE.COL.ACTIONS" defaultMessage="Action" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      {checklist?.map((data, index)=> (
                        <>
                         <tr className="bg-white border-b  hover:bg-gray-50" >
                         <td scope="row" className="px-6 py-4 text-base">
                        
                         {data?.name}
                             
                         </td>
                         {/* <td className="px-6 py-4">
                           {data.Tasks}
                         </td> */}
                       
                 
                         <td className="px-6 py-4">
                         {data?.type}
                         </td>
                         <td className="px-6 py-4">
                         {data?.subtype}
                         </td>
                         <td className="px-6 py-4">
                        
                         <div className='flex items-center space-x-4'>
                            <div onClick={()=>HandleTask(data?.id) } className='cursor-pointer px-6 py-2 border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
                            <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TABLE.COL.ACTIONS.VIEW" defaultMessage="View" /> </div>
                             <div onClick={() => HandleCopyTask(data?.id)}  className='cursor-pointer px-6 py-2 border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
                             <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TABLE.COL.ACTIONS.COPY" defaultMessage="Copy" /> </div>
                             </div>
                        
                        </td>
                     </tr>
                     {showTask === data?.id&&
                        <tr className="bg-white border-b  hover:bg-gray-50" >
                            <td  {...props} colSpan={5}>
                            <ContentWithSpinner loader={taskLoader}>
                                {task?.length>0 ? 
                     <div className='flex w-full rounded-[20px] border border-neutral-200'>
                      <table className="w-full text-left condidate-table">
                      <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
                          <tr>
                              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                              <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TABLE.COL.TASK" defaultMessage="Task" />
                              </th>
                              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                              <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TABLE.COL.DESCRIPTION" defaultMessage="Description" />
                              </th>
                              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                              <FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TABLE.COL.TYPE" defaultMessage="Type" />
                              </th>
                             
                          </tr>
                      </thead>
                      <tbody>
                        {task?.map((data, index)=> (
                           <tr className="bg-white border-b  hover:bg-gray-50">
                           <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                          
                               <div className='text-base'>{data.name}</div>
                               
                           </th>
                           <td className="px-6 py-4">
                               {data.description}
                           </td>
                         
                   
                           <td className="px-6 py-4">
                           {data.type}
                           </td>
                          
                       </tr>
                  ))}
                        
                      </tbody>
                  </table> 
                  </div>
                  :
                  <p className='text-center'>No Task Added</p>
                        }
                  </ContentWithSpinner>
                  </td> </tr>}
                 </>

            ))}

                      
                    </tbody>
                </table></div>
                </ContentWithSpinner>
  )
}
