import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const createTraning = async (postData:any) => {
  try {
    return await post<ApiResponse<[]>>(`/cleaner/training`, postData).then((res)=> {
        if(res.success === true) {
            SuccessMessage('API.SUCCESS.TRAINING.ADD')
            return res
        }
        else {
            return null
        }
    })
  }
  catch(e:any) {
    HandleApiError(e);
            return null
  }
};
