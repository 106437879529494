import React, {useState} from 'react'
import { ModelWrapper } from 'app/pages/company/component/ModelWrapper'
import { ChatBottom } from './ChatBottom'
import { FormattedMessage } from 'react-intl'
export const ChatBox = () => {
    const [modelShow, setModelshow] = useState(false)
    const HandleModelByProps = (data) => {
        setModelshow(data)
       }
  return (
    <div>
    <div className='flex justify-between items-center py-3 px-3'>
        <div>
        <h1 className='text-2xl font-satoshiBold text-[#595959]'>Deepak Kumar</h1>
        <p className='font-satoshi'>Applied to Web Developer in Lucknow, Uttar Pradesh</p>
        </div>
    </div>
<hr className="border-2 border-neutral-200" />
<div className="flex flex-col h-[60vh] justify-between">
<div className='flex-1 h-[60vh] overflow-auto mb-3'>
<div className='text-center mb-10 mt-3'>
Applied 25 July
</div>
<div className="flex items-center justify-center mb-4">
  <div className="border-t flex-1 mr-4"></div>
  <span className="text-black font-satoshiBold">Today</span>
  <div className="border-t flex-1 ml-4"></div>
</div>
<div>
 {
  [{}, {}, {}, {}].map(()=> (
<div className='flex justify-start items-center space-x-2 px-2 pb-6'>
    <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
        D
    </div>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='font-satoshiBold text-base'> Deepak Kumar </div>
    <div className='text-sm'>
    3:19 AM
    </div>
    </div>
    <div className='text-[#595959]'>
    Hi, I recently submitted my application. Please let me know if you need anything else from me at this point. Thank you!
    </div></div>
</div>
))}
<div className='flex justify-start items-center space-x-2 px-2'>
<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='font-satoshiBold text-base'> You </div>
    <div className='text-sm'>
    3:19 AM
    </div>
    </div>
    <div className='text-[#595959]'>
    Hi, I recently submitted my application. Please let me know if you need anything else from me at this point. Thank you!
    </div></div>
</div>

</div>
</div>
<ChatBottom />

</div></div>
  )
}
