import React from 'react'
import {IndividualSettingData } from '../../component/SidebarItems'
import { FormattedMessage } from 'react-intl'
export const Sidebar = ({activeTab, HandleTabs}) => {
  console.log(activeTab)
  return (
    <div className='h-screen bg-white rounded-lg py-4'>
        <h1 className='px-3 font-satoshiBold text-xl'><FormattedMessage id="INDIVIDUAL.SETTINGS" defaultMessage="Settings" /></h1>
        <ul>
        {IndividualSettingData.map((data)=> (
          
         <li onClick={() => HandleTabs(data.text)} className={`cursor-pointer flex items-center rounded-lg hover:rounded hover:bg-[#EEF1FE] my-2 px-3 py-2 ${activeTab === data.text.props.defaultMessage  ? 'bg-[#EEF1FE] border border-[#2557A7]' : '' }`}>
            <span className="p-1.5">
{data.icon}
            </span> 
        <div>
            <div className={`block font-satoshiBold text-base`}>
  <span>{data.text}</span>
</div>
<div className={`block text-sm`}>
  <span>{data.smalltext}</span>
</div>
</div>

         </li>
))}
        </ul>
    </div>
  )
}
