import React, {useState} from 'react'
import { ChecklistTemplatedata } from './ChecklistTemplatedata'
import { ChecklistTask } from './ChecklistTemplatedata'
import { FormattedMessage } from 'react-intl'
export const AllChecklist = (props) => {
    const [showTask, setShowTask] = useState(null)
    const HandleTask = (index) => {
        if(showTask === index) {
            setShowTask(null)
        }
        else {
            setShowTask(index)
        }
    }
  return (
    <div className="overflow-x-auto mt-4 rounded-[20px]">
                <table className="w-full text-left condidate-table">
                    <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
                        <tr>
                            <th scope="col" className="px-6 py-4 text-base ">
                            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.NAME" defaultMessage="Tasklist Name" />                            </th>
                            <th scope="col" className="px-6 py-4 text-base ">
                            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.TASKS" defaultMessage="Tasks" />
                            </th>
                            <th scope="col" className="px-6 py-4 text-base ">
                            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.TYPE" defaultMessage="Tasklist Type" />
                            </th>
                            <th scope="col" className="px-6 py-4 text-base ">
                            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.SUBTYPE" defaultMessage="Sub Type" />
                            </th>
                            
                            <th scope="col" className="px-6 py-4 text-base ">
                            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.ACTIONS" defaultMessage="Action" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      {ChecklistTemplatedata.map((data, index)=> (
                        <>
                         <tr className="bg-white border-b  hover:bg-gray-50" >
                         <td scope="row" className="px-6 py-4 text-base">
                        
                         {data.ChecklistName}
                             
                         </td>
                         <td className="px-6 py-4">
                           {data.Tasks}
                         </td>
                       
                 
                         <td className="px-6 py-4">
                         {data['Sub Type']}
                         </td>
                         <td className="px-6 py-4">
                         {data.Type}
                         </td>
                         <td className="px-6 py-4">
                        
                         <div className='flex items-center space-x-4'>
                            <div onClick={()=>HandleTask(index) } className='cursor-pointer px-6 py-2 border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
                            <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.ACTIONS.VIEW" defaultMessage="View" /> </div>
                             <div onClick={() => props.ChecklistHandle(index)}  className='cursor-pointer px-6 py-2 border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
                             <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TABLE.COL.ACTIONS.COPY" defaultMessage="Copy" /> </div>
                             </div>
                        </td>
                     </tr>
                     {showTask === index&&
                        <tr className="bg-white border-b  hover:bg-gray-50" >
                            <td  {...props} colSpan={5}>
                     <div className='flex w-full'>
                      <table className="w-full text-left condidate-table">
                      <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
                          <tr>
                              <th scope="col" className="px-6 py-4 text-base ">
                              <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.TABLE.COL.TASK" defaultMessage="Task" />
                              </th>
                              <th scope="col" className="px-6 py-4 text-base ">
                              <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.TABLE.COL.DESCRIPTION" defaultMessage="Description" />
                              </th>
                              <th scope="col" className="px-6 py-4 text-base ">
                              <FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.TABLE.COL.TYPE" defaultMessage="Type" />                              </th>
                             
                          </tr>
                      </thead>
                      <tbody>
                        {ChecklistTask.map((data, index)=> (
                           <tr className="bg-white border-b  hover:bg-gray-50">
                           <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                          
                               <div className='text-base'>{data.Task}</div>
                               
                           </th>
                           <td className="px-6 py-4">
                               {data.Description}
                           </td>
                         
                   
                           <td className="px-6 py-4">
                           {data.Type}
                           </td>
                          
                       </tr>
                  ))}
                        
                      </tbody>
                  </table> 
                  </div></td> </tr>}
                 </>

            ))}

                      
                    </tbody>
                </table></div>
  )
}
