import React, { useEffect, useState } from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { WhiteBtn } from '../work-order/component/WhiteBtn'
import { Link, useNavigate } from 'react-router-dom'
import { MSTableRow } from '../work-order/ManageSchedule/components/MSTableRow'
import { Filter } from '../work-order/component/Filter'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { Checkbox, Spinner } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getCustomer } from 'sr/utils/api/company/customer/getCustomer'
import ContentWithLoader from 'sr/layout/common/ContentLoader'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { updateCustomer } from 'sr/utils/api/company/customer/updateCustomer'
import { customerType } from 'sr/i18n/messages/setting'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { useAuth } from 'app/pages/module/auth'
import { useGet } from '../Hooks/Api/useGet'
import { getSupervisor } from 'sr/utils/api/company/workorder/getSupervisor'
import { getCustomerLocation } from 'sr/utils/api/company/customer/getCustomerLocation'
import { fetchCustomerData } from 'sr/helpers/apiFunctions/fetchCustomerData'
import { Pagination } from 'app/pages/component/Pagination'
import { DeleteCustomer } from 'sr/helpers/apiFunctions/deleteCustomer'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'sr/redux/store'
import { fetchSupervisor } from 'sr/redux/reducers/supervisor'
import { fetchCustomerLocations } from 'sr/helpers/apiFunctions/fetchCustomerLocations'
export const ManageCustomer = () => {
    const navigate = useNavigate();
    const [selectedCustomer, SetSelectedCustomer] = useState([])
    const [userId, setuserId] = useState()
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState<any>(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalPage, settotalPage] = useState<number>(1);
    const {userType} = useAuth()
    const [customerLocationId, setCustomerLocationId] = useState<string>()
    const dispatch = useDispatch<AppDispatch>();
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)

    useEffect(()=> {
      const userData = getUserDetails()
      setuserId(userData?.user_id)
    },[]) 
    useEffect(()=> {
      if(userId && userType==='supervisor') {
        if(!supervisorData){
          dispatch(fetchSupervisor({id:userId}))
        }
      }
},[userId, userType, dispatch])
const fetchAllLocation = async (companyId)=> {
  const data= await fetchCustomerLocations({userType:userType, companyId:companyId})
  setCustomerLocationId(data?.map((item)=> item?.id)?.join())
}
  
useEffect(()=> {
  if(supervisorData){
  const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
  if(id?.length>0){
  setCustomerLocationId(id?.join());
  }
  else {
  fetchAllLocation(supervisorData?.company_id?._id)
}
}
  
 },[supervisorData])
     const loadCustomerData = async ()=> {
      await fetchCustomerData({
        userId:userId, userType:userType, page:page, limit:limit, selectedCustomer:selectedCustomer, customerLocationId:customerLocationId,
        setLoader:setLoader, setData:setData, setTotalPage:settotalPage}
     );
    }
  useEffect(()=> {
   
      if(userId && userType) {
        if(userType==='supervisor'){
          if(customerLocationId){
      loadCustomerData()
          }}
          else {
            loadCustomerData()
          }
    }
    }, [userId,page, limit,selectedCustomer, customerLocationId ])
    const processedData = typeof data === 'object' && !Array.isArray(data) ? [data] : data;

  return (
    <MainWrapper>
    <div className='py-3'>
      <ContentWithSpinner loader={loader} height='h-screen'>
    <div className='mb-2 mt-5'>
    <h1 className='text-2xl font-satoshiBold border-r border-neutral-200 pr-4'><FormattedMessage id="RECRUITER.MANAGECUSTOMER.TITLE" defaultMessage="Manage Customer" /></h1>
    </div>
    <div className='flex justify-between'>
        <div className='flex w-1/6 space-x-4'>
  <Filter 
  label={<FormattedMessage id="RECRUITER.MANAGECUSTOMER.FILTER.TYPE.TITLE" defaultMessage="Type" />} 

  name={<FormattedMessage id="RECRUITER.MANAGECUSTOMER.FILTER.GENERAL.TYPE" defaultMessage="Show All" />} 
  mainClass="flex-1">
  {customerType?.map((item, index)=> (
  <Checkbox key={index} checked={selectedCustomer.includes(item?.value)}  label={item?.label} id={item?.value} name={item?.value} value={item?.value} onChange={(e)=> CheckBoxHandle(e, selectedCustomer, SetSelectedCustomer)} />
  ))}
  </Filter></div>
  {((userType==='company') || (userType==='supervisor' && supervisorData?.access?.customer_management?.add_customer)) &&
  <div className='flex'>
    <Link to="/add-customer">
  <WhiteBtn mainClass="mt-5"  title={<FormattedMessage id="RECRUITER.MANAGECUSTOMER.ADDCUSTOMER.BUTTON." defaultMessage="Add Customer" />}></WhiteBtn>
  </Link>

  </div>
}
    </div>
    <div className="overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.MANAGECUSTOMER.TABLE.COL.CUSTOMER" defaultMessage="Customer" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.MANAGECUSTOMER.TABLE.COL.PHONENO" defaultMessage="Phone No" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.MANAGECUSTOMER.TABLE.COL.EMAIL" defaultMessage="Email" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.MANAGECUSTOMER.TABLE.COL.TYPE" defaultMessage="Type" />
                </th>
                <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
                <FormattedMessage id="RECRUITER.MANAGECUSTOMER.TABLE.COL.ACTION" defaultMessage="Action" />
                </th>
            </tr>
        </thead>
        <tbody>
       { processedData?.map((item, index)=> (
         <tr className="bg-white border-b  hover:bg-gray-50">
         <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
         <Link to={`/customer-details/${item?.id}`}>
             <div className='text-base cursor-pointer'>{item?.name}</div> 
             </Link>
         </th>
         <td className="px-6 py-4">
           {item?.mobile_number}
         </td>
         <td className="px-6 py-4">
             {item?.email}
         </td>
         <td className="px-6 py-4">
        {item?.type}
         </td>
        <td className="px-6 py-4">

          <div className='flex items-center space-x-4'>
            <div className='border border-neutral-200 rounded-lg inline-flex  divide-x w-auto'>
              <div onClick={()=> navigate(`/customer-details/${item?.id}`)}   className='py-1.5 px-2 group cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 mt-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                </svg>
                <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
                  <FormattedMessage
                    id='ECOMMERCE.COMMON.ICON.TOOLTIP.EDIT'
                    defaultMessage='Edit'
                  />
                </span>
              </div>
              {((userType==='company') || (userType==='supervisor' && supervisorData?.access?.customer_management?.delete_customer)) &&
              <div onClick={() => DeleteCustomer(item?.id, loadCustomerData)} className='py-1.5 px-2 group cursor-pointer relative hover:text-[#a9252b] hover:border-2 hover:rounded-r-lg hover:border-[#f76266] hover:bg-[#FEEEEF]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 mt-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                </svg>
                <span className="absolute z-10 top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100"> 
                  <FormattedMessage
                    id='RECRUITER.MANAGECUSTOMER.TABLE.COL.ACTION.DELETE'
                    defaultMessage='Delete Customer'
                  />
                </span>
              </div>
}
            </div>
          </div>
        </td>
     </tr>
))}
          
        </tbody>
    </table>
   <Pagination page={page} totalPage={totalPage} setPage={setPage} />
</div>
</ContentWithSpinner>
    </div>
    </MainWrapper>
  )
}
