// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.ProseMirror {
  height: 230px !important;
}

.ProseMirror-focused {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/company/jobpost/component/styles.scss"],"names":[],"mappings":"AAAA,wBAAA;AAEI;EACE,kBAAA;AAAN;AAII;;EAEE,eAAA;AAFN;AAKI;;;;;;EAME,gBAAA;AAHN;AAMI;EACE,uCAAA;EACA,cAAA;AAJN;AAOI;EACE,mBAAA;EACA,WAAA;EACA,uCAAA;EACA,qBAAA;EACA,qBAAA;AALN;AAOM;EACE,cAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;AALR;AASI;EACE,eAAA;EACA,YAAA;AAPN;AAUI;EACE,kBAAA;EACA,4CAAA;AARN;AAWI;EACE,YAAA;EACA,2CAAA;EACA,cAAA;AATN;;AAYE;EACE,wBAAA;AATJ;;AAWA;EACI,aAAA;AARJ","sourcesContent":["/* Basic editor styles */\n.ProseMirror {\n    > * + * {\n      margin-top: 0.75em;\n      \n    }\n  \n    ul,\n    ol {\n      padding: 0 1rem;\n    }\n  \n    h1,\n    h2,\n    h3,\n    h4,\n    h5,\n    h6 {\n      line-height: 1.1;\n    }\n  \n    code {\n      background-color: rgba(#616161, 0.1);\n      color: #616161;\n    }\n  \n    pre {\n      background: #0D0D0D;\n      color: #FFF;\n      font-family: 'JetBrainsMono', monospace;\n      padding: 0.75rem 1rem;\n      border-radius: 0.5rem;\n  \n      code {\n        color: inherit;\n        padding: 0;\n        background: none;\n        font-size: 0.8rem;\n      }\n    }\n  \n    img {\n      max-width: 100%;\n      height: auto;\n    }\n  \n    blockquote {\n      padding-left: 1rem;\n      border-left: 2px solid rgba(#0D0D0D, 0.1);\n    }\n  \n    hr {\n      border: none;\n      border-top: 2px solid rgba(#0D0D0D, 0.1);\n      margin: 2rem 0;\n    }\n  }\n  .ProseMirror{\n    height: 230px !important;\n}\n.ProseMirror-focused {\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
