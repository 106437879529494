import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createTasklists = async (postData) => {
  return await post<ApiResponse<[]>>('/customer/tasklists', postData).then((res)=> {
    try {
    if(res.success===true) {
        SuccessMessage('API.SUCCESS.CUSTOMER.TASKLIST.COPY')
        return res
    }
    else {
        return null
    }
}
catch(e:any) {
    HandleApiError(e)
}})
};
