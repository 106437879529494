import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateManageTaskSubmit = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/tasktrack', putData).then((res)=> {
    if(res?.success===true) {
        SuccessMessage('API.SUCCESS.WORKORDER.TASK.SUBMIT')
        return res
    }
    else {
        return null
    }
  })
}
catch(e) {
    HandleApiError(e)
}
};
