import { useEffect, useState } from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import { Input} from "sr/helpers";
import { Link, useParams } from "react-router-dom";
import { FormDetails } from "sr/helpers/ui-components/FormDetails";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import cleanerRegisterSchema from "sr/utils/schemas/cleaner/cleanerRegister";
import { cleanerRegister } from "sr/utils/api/cleaner/cleanerRegister";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { sendOTP } from "sr/utils/api/sendOTP";
import { ButtonAuth } from "sr/helpers/ui-components/ButtonAuth";
import { login } from "sr/utils/api/login";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { useAuth } from "../module/auth";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { updateContractor } from "sr/utils/api/company/workorder/updateContractor";
export default function RegisterCleaner() {
  const intl = useIntl();
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const {persistToken} = useAuth()
  const [id, setId] = useState<string>()
  const params = useParams()
  useEffect(()=> {
      const  {id} = params
      setId(id)
  },[params])
  useEffect(()=> {
    const loadContractor = async ()=> {
      const data = await fetchContractor({id:id, setLoader:setLoader })
      if(data){
        setFieldValue('mobile_number', data?.mobile_number);
        setFieldValue('first_name', data?.first_name);
        setFieldValue('last_name', data?.last_name);
        setFieldValue('email', data?.email)
      }
      }
      if(id){
        loadContractor()
      }
  },[id])
  interface FormValues {
    username: string;
    mobile_number: string;
    password: string;
    first_name: string;
    last_name: string;
    email: string;
    date_of_birth: string;
  }
  
  const initialValues:FormValues = {
    username:"",
    mobile_number:"",
    password:"",
    first_name:"",
    last_name:"",
    email:"",
    date_of_birth:""
  }
  const {errors, handleChange, handleSubmit, setFieldValue, values} = useFormik({
    initialValues:initialValues,
    enableReinitialize:true,
    validateOnChange:false,
  onSubmit:async (values) => {
    setLoader(true)
    try {
    const result = await cleanerRegister(values)
    setLoader(false)
    if(result) {
      if(id){
        const contractorres = await updateContractor({id:id,cleaner_id:result?.id, status:'active'})
      }
      const otpresult = await sendOTP({username:result?.username})
      const loginresult = await login({username:values.username, password:values.password})
      if(loginresult.success===true) {
      const data = {
        userType:loginresult?.data?.user.type,
        user_id:loginresult?.data?.auth.user_id,
        jwt:loginresult?.data?.auth.jwt
      }
      localStorage.setItem('auth', JSON.stringify(data))
      persistToken()
        navigate('/verify-otp')
    } }
    else {
      setLoader(false)
    }
    }
    catch(error) {
      setLoader(false)
    }
  },
  validationSchema:cleanerRegisterSchema
})
useEffect(()=> {
console.log(errors);

},[errors])

  return (
    <>
      <AuthNavComponent></AuthNavComponent>
      <main>
        {loader && <LoaderFullScreen />}
        <div className="lg:container mx-auto lg:max-w-6xl lg:my-8 my-8 px-4 lg:px-0 lg:flex block">
          <FormDetails />
          <div className="lg:flex-1 lg:flex flex-col h-fit lg:mx-12 mx-auto lg:p-6 p-4 bg-white border border-gray-border rounded-xl">
            <h1 className="font-satoshiBold text-xl">
              <FormattedMessage
                id="ECOMMERCE.COMMON.CREATE_AN_ACCOUNT.TEXT"
                defaultMessage="Create an Account"
              />
            </h1>
            <div className="max-w-lg">
              <form onSubmit={handleSubmit}>
              <Input
              onChange={handleChange}
                    name="username"
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME",
                      defaultMessage: "Username",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME.PLACEHOLDER",
                      defaultMessage: "Enter your username",
                    })}
                    type={"text"}
                    error={errors.username}
                    value={values.username}
                  ></Input>
               
                <Input
                onChange={handleChange}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD",
                    defaultMessage: "Password",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
                    defaultMessage: "Enter your password",
                  })}
                  type={"password"}
                  name="password"
                  error={errors.password}
                ></Input>
                 <Input
                onChange={handleChange}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.MOBILENO",
                    defaultMessage: "Mobile Number",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.LASTMOBILENONAME.PLACEHOLDER",
                    defaultMessage: "Enter your Mobile Number",
                  })}
                  type={"tel"}
                  name="mobile_number"
                  error={errors.mobile_number}
                  value={InputNumberFormat(values.mobile_number)}
                ></Input>
                    <Input
                onChange={handleChange}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.ADDRESS",
                    defaultMessage: "Email Address",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.PLACEHOLDER",
                    defaultMessage: "Enter your email id",
                  })}
                  type={"email"}
                  name="email"
                  value={values.email}
                  error={errors.email}
                ></Input>
                <Input
                onChange={handleChange}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME",
                    defaultMessage: "First Name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME.PLACEHOLDER",
                    defaultMessage: "Enter your first name",
                  })}
                  type={"text"}
                  name="first_name"
                  error={errors.first_name}
                  value={values.first_name}
                ></Input>
                <Input
                onChange={handleChange}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.LASTNAME",
                    defaultMessage: "Last Name",
                  })}
                  placeholder={"Enter your last name"}
                  type={"text"}
                  name="last_name"
                  error={errors.last_name}
                  value={values.last_name}
                ></Input>
               
                 <ReactDatePicker
          onChange={(date)=> setFieldValue('date_of_birth', date)}
          selected={values.date_of_birth}
          label={intl.formatMessage({
            id: "AUTH.INPUT.DATEOFBIRTH",
            defaultMessage: "Date of Birth",
          })}
          error={errors.date_of_birth}
        ></ReactDatePicker>
               

                {/* <Link to="/verify-otp" replace> */}
                  <ButtonAuth loader={loader}
                    onClick={() => {
                      localStorage.setItem("RegisterAs", "Cleaner");
                    }}>
                    <FormattedMessage
                      id="AUTH.GENERAL.CREATE.ACCOUNT.BUTTON"
                      defaultMessage="Create Account"
                    />
                  </ButtonAuth>
                {/* </Link> */}
              </form>
              <div className="mt-10 flex justify-center">
                <FormattedMessage
                  id="AUTH.ALREADY.HAVE.ACCOUNT"
                  defaultMessage="Already have an account?"
                />
              
                <Link to="/auth" className="cursor-pointer hover:text-pink underline" replace>
               
                    {" "}
                    <FormattedMessage
                      id="AUTH.SIGN_IN_HERE"
                      defaultMessage="Sign in here"
                    />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
