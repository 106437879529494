import { getCardDetails } from "sr/utils/api/strip/getCardDetails"
interface ParamsType {
    customerId?:string,
    setCardDetails?:(params:any)=> void
    setIsProcessing?:(params:any)=> void
  }
const getCardDetail = async (params:ParamsType)=> {
    try {
        const res = await getCardDetails(params?.customerId)
        if(res?.success===true) {
            params?.setCardDetails(res?.data?.data)
              params?.setIsProcessing(false)
              
        }
      }
      catch(e) {
        params?.setIsProcessing(false)
      }
    }
export default getCardDetail;    