import { put } from "sr/utils/axios";
import {toast} from 'react-toastify'
import ApiResponse from "sr/models/ApiResponse";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const updateEmployment = async (putData:any) => {
try {
return await put<ApiResponse<[]>>('/cleaner/employment', putData ).then((res)=> {
    if(res.success=== true) {
        SuccessMessage('API.SUCCESS.EMPLOYMENT.UPDATE')
        return res
    }
    else {
        toast.error(res.error.message)
          return null
    }
})
}
catch(e:any) {
    HandleApiError(e);
    return null
}
}