import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleCheck, faCircleXmark, faDollarSign, faLocationDot, faStar, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom'
import Footer from 'app/pages/module/Footer'
import { InterestedJobs } from '../component/Sidebar/InterestedJobs'
import AuthNavComponent from 'app/pages/module/auth/AuthNav'
import { FormattedMessage } from 'react-intl'
import Job from 'app/pages/component/types/individual/job'
import { getJob } from 'sr/utils/api/individual/job/getJob'
import { HandleApiError } from 'sr/helpers/functions/HandleApiError'
import { HTMLStringRender } from 'sr/helpers/ui-components/HTMLStringRender'
import { DaysConverter } from 'sr/helpers/functions/DaysConverter'
export default function IndividualJobDetail() {
    const [id, setId] = useState<string>()
    const [data, setData] = useState<Job>()
    const params = useParams()
    const [loader, setLoader] = useState(true)
    const [postedDays, setpostedDays] = useState(null)
    useEffect(()=> {
            const {id} = params
                setId(id)
        },[params])
        useEffect(()=> {
            const fetchJob = async (id)=> {
                try {
                setLoader(true)
                const queryParams = new URLSearchParams();  
              queryParams.append('id', id.toString());
                    const res = await getJob(queryParams)
                    if(res?.success===true) {
                            setData(res?.data)
                            setpostedDays(DaysConverter(res?.data?.createdAt))
                            setLoader(false)
                    }
                    else {
                        setLoader(false)
                    }
                }
                catch(e:any) {
                    setLoader(false)
                    HandleApiError(e)
                }
            }
            if(id) {
                fetchJob(id)
            }
        },[id])
    const DayNumber = 5;
    return (
        <>
        <AuthNavComponent isLoggedin={true}></AuthNavComponent>
        <main>
            <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                <div className='lg:flex block'>
                <div className='flex flex-col lg:w-2/3 w-full'>
                    <div className='card-1 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                        <div className='header'>
                            <h1 className='font-bold  text-lg'>{data?.title}</h1>
                            <h2 className='text-sm font-medium text-gray-800'>{data?.individual_id?.first_name} {data?.individual_id?.last_name}</h2>
                        </div>

                        <div className='job-attr mt-5'>
                            <div className='row-1 flex'>
                                <div className='pr-8'>
                                    <FontAwesomeIcon icon={faSuitcase} className='text-gray-600 text-sm font-medium' />
                                    <span className='pl-2 text-gray-600 text-sm font-medium'>5 - 10 <FormattedMessage id='CLEANER.JOBS.EXP.YEAR' defaultMessage='years'/></span>
                                </div>
                                <div className='pr-8'>
                                    <FontAwesomeIcon icon={faDollarSign} className='text-gray-600 text-sm font-medium' />
                                    <span className='pl-2 text-gray-600 text-sm font-medium'>2300</span>
                                </div>
                            </div>
                            <div className='row-2 flex'>
                                <div className='pr-8'>
                                    <FontAwesomeIcon icon={faLocationDot} className='text-gray-600 text-sm font-medium' />
                                    <span className='pl-2 text-gray-600 text-sm font-medium'>{(data?.streetAddress || data?.unitorapt)|| (data?.location_id?.address_line_1 ||data?.location_id?.address_line_2 || data?.location_id?.city)}</span>
                                </div>
                            </div>
                            <div className='border-t border-gray-200 my-5'>
                            </div>
                            <div className='lg:flex block justify-between items-center'>
                                <div className='row-1 flex'>
                                    <div className='pr-8'>
                                        <span className='lg:pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='ECOMMERCE.COMMON.TEXT.POSTED' defaultMessage={'Posted'}/>:</span>
                                        <span className='lg:pl-2 text-gray-900 text-sm font-semibold'>  <FormattedMessage 
                       id='CLEANER.JOBS.TIME'
                       defaultMessage='{postedDays} Days Ago'
                       values={{postedDays}}
                    /></span>
                                    </div>
                                    <div className='pr-8'>
                                        <span className='pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='CLEANER.JOBS.JOBAPPLICANTS' defaultMessage='Job Applicants' />:</span>
                                        <span className='pl-2 text-gray-900 text-sm font-semibold'>78</span>
                                    </div>
                                </div>

                                <div className='mt-8 lg:m-0'>
                                    <button className='border border-pink hover:bg-red-50 p-2 w-24 rounded-3xl mr-3'><Link to="/saved-jobs"><FormattedMessage id='JOBS.SAVEBUTTON' defaultMessage='Save' /></Link></button>
                                    <button className='bg-[#ff39b3] font-bold text-white hover:bg-navy p-2 w-24 rounded-3xl'><FormattedMessage id='JOBS.APPLYBUTTON' defaultMessage='Apply' /></button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='card-2 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                        <div className='flex flex-col p-6 bg-gray-50 border border-gray-border rounded-xl'>
                            <h1 className='font-bold  text-base'>
                                <FormattedMessage 
                                id='JOBDETAILS.HIGHLIGHT.TITLE'
                                defaultMessage='Job Highlights'
                                />
                            </h1>
                        </div>

                        <div className='job-description mt-6'>
                            <h1 className='font-bold  text-base'>
                            <FormattedMessage 
                                id='JOBDETAILS.JOBDESCRIPTION'
                                defaultMessage='Job Description'
                                />
                            </h1>
                            <div><HTMLStringRender htmlContent={data?.description}></HTMLStringRender></div>
                        </div>

                        <div className='mt-6'>
                            <div><span className='font-bold  text-sm'>                                
                            <FormattedMessage 
                                id='JOB.ROLE.TITLE'
                                defaultMessage='Role'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'><FormattedMessage 
                                id='JOB.ROLE.OTHER'
                                defaultMessage='Other'
                                /></span>
                            </div>
                            <div><span className='font-bold  text-sm'> <FormattedMessage 
                                id='JOB.INDUSTRY.TYPE'
                                defaultMessage='Industry Type'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'>IT Services & Consulting</span>
                            </div>
                            <div>
                                <span className='font-bold  text-sm'>
                                    <FormattedMessage 
                                id='JOB.DEPARTMENT.TITLE'
                                defaultMessage='Department'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'>Other</span>
                            </div>
                            <div><span className='font-bold  text-sm'> 
                            <FormattedMessage 
                                id='JOB.EMPLOYMENT.TYPE.TITLE'
                                defaultMessage='Employment Type'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'><FormattedMessage 
                                id='JOB.EMPLOYMENT.TYPE.FULLTIME'
                                defaultMessage='Full Time'
                                />, <FormattedMessage 
                                id='JOB.EMPLOYMENT.TYPE.PERMANENT'
                                defaultMessage='Parmanent'
                                /></span>
                            </div>
                            <div><span className='font-bold  text-sm'>
                                <FormattedMessage 
                                id='JOB.ROLE.CATEGORY.TITLE'
                                defaultMessage='Role Category'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'>Other</span>
                            </div>

                            <h3 className='mt-5 font-bold  text-sm'><FormattedMessage 
                                id='JOB.EDUCATION.TITLE'
                                defaultMessage='Education'
                                /></h3>
                            <div><span className='font-bold  text-sm'><FormattedMessage 
                                id='JOB.EDUCATION.UG.TITLE'
                                defaultMessage='UG'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'>Any</span>
                            </div>
                            <div><span className='font-bold  text-sm'><FormattedMessage 
                                id='JOB.EDUCATION.PG.TITLE'
                                defaultMessage='PG'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'>Any</span>
                            </div>
                            <div><span className='font-bold  text-sm'><FormattedMessage 
                                id='JOB.EDUCATION.DOCTORATE.TITLE'
                                defaultMessage='Doctorate'
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'>
                                    <FormattedMessage 
                                id='JOB.EDUCATION.DOCTORATE.NOT.REQUIRED'
                                defaultMessage='Doctorate Not Required'
                                /></span>
                            </div>

                            <h3 className='mt-5 font-bold  text-sm'> <FormattedMessage 
                                id='JOB.KEY.SKILLS'
                                defaultMessage='Key Skills'
                                /></h3>
                            <div className='flex mt-2'>
                                <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>Azure</div>
                                <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>GCP</div>
                                <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>AWS</div>
                                <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>ES5</div>
                            </div>
                            <div className='w-full flex justify-between border-t-2 items-center mt-4'>
                                <div className='flex space-x-2 my-4'>
                                <div className="rounded-lg py-0.5 px-2 transition-ease-out duration-25 bg-[#3b5998] hover-[#2d4373] border-[#3b5998] 
                                 hover:bg-[#2d4373] hover:border-[#2d4373] active:bg-[#2d4373] active:border-[#2d4373]">
                                    <div aria-hidden="true" className="inline-block fill-white stroke-none">
                                    <svg className="h-4 w-4 m-0 align-middle inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z">
                                            </path></svg></div></div>
                                <div className="rounded-lg py-0.5 px-2 transition-ease-out duration-25 bg-[#55acee] hover:bg-[#2795e9] hover:border-[#2795e9] active:bg-[#2795e9] active:border-[#2795e9]">
                                    <div aria-hidden="true" className="inline-block fill-white stroke-none"><svg className="h-4 w-4 m-0 align-middle inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"></path>
                                        </svg></div></div>
                                        <div className="rounded-lg transition-ease-out duration-25 bg-[#0288D1] hover:bg-[#2795e9] hover:border-[#2795e9] active:bg-[#2795e9] active:border-[#2795e9]">
                                    <div aria-hidden="true" className="inline-block fill-white stroke-none">
<svg className='h-8 w-8 m-0 align-middle inline-block' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48">
<path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"/>
<path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"/></svg></div></div>
                            </div>     
                            <p className='text-sm font-bold  text-[#275df5] cursor-pointer'>
                                <FormattedMessage 
                                id='JOB.REPORT.TITLE'
                                defaultMessage='Report this Job'
                                />
                            </p>
                                                           </div>

                        </div>
                    </div>

                    <div className='card-2 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                        <h1 className='font-bold  text-base'>
                            <FormattedMessage
                            id='JOB.ABOUT.COMPANY.TITLE'
                            defaultMessage={'About Company'}
                            />
                        </h1>
                        <div>Leading Client</div>

                        <h3 className='mt-5 font-bold  text-sm'>
                        <FormattedMessage
                            id='JOB.COMPANY.INFO.TITLE'
                            defaultMessage={'Company Info'}
                            />
                            </h3>
                        <div><span className='font-bold  text-sm'><FormattedMessage
                            id='JOB.COMPANY.ADDRESS.TITLE'
                            defaultMessage={'Address'}
                            />:</span>
                            <span className='text-sm text-gray-700 pl-1'>dfret street, area fgh, high street</span>
                        </div>
                    </div>
                </div>

              <InterestedJobs />
              </div>
            </div>
        </main>
        <Footer />
    </>
    )
}
