import { put } from "sr/utils/axios"
import ApiResponse from "sr/models/ApiResponse"
import {toast} from 'react-toastify'
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage"
import { HandleApiError } from "sr/helpers/functions/HandleApiError"

export const updateCompany = async (putData:any) => {
try {
   return await put<ApiResponse<[]>>('/company/', putData).then((res)=> {
    if(res?.success=== true) {
        SuccessMessage("API.SUCCESS.COMPANY.UPDATE")
        return res
    }
    else {
HandleApiError(res.error.msg)
        return null
    }
   })
}
catch(e:any) {
    HandleApiError(e)
}
}