import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getTaskManage = async (params) => {
    try {
  return await get<ApiResponse<[]>>(`/taskmgmt?${params}`).then((res)=> {
    if(res?.success===true){
        return res
    }
    else {
        return null
    }
  })
}
catch(e) {
    HandleApiError(e)
}
};
