import * as Yup from 'yup'
const additionalInfo = Yup.object({
    company_website:Yup.string().required("INPUT.VALIDATION.COMPANY.WEBSITE.REQUIRED"),
    social_link_fb:Yup.string(),
    social_link_twitter:Yup.string(),
    company_logo_path:Yup.mixed().test('isImage', 'Invalid file type. Only images are allowed.', (value) => {
        if (value && value instanceof File) {
          return value.type.startsWith('image/');
        }
        return true; // Return true for non-file values or if value is undefined
      }),
      no_of_employees: Yup.number(), 
      date_format: Yup.string(),
      date_of_incorporation:Yup.date().nullable(),
      number_of_branches: Yup.number(),
      annual_revenue: Yup.number(),
      no_of_clients: Yup.number(),
      about_company: Yup.string(),
    
})
export default additionalInfo;