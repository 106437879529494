import React, {useEffect, useState} from 'react'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox';
import { FormattedMessage } from 'react-intl';
import { Schedule } from '../Jsonalldata';
export const ScheduleChange = (props) => {
    const [schedule, setSchedule] = useState<any[]>([]);
    useEffect(()=> {
      setSchedule(props?.data?.schedule)
    },[props?.data])

    useEffect(()=>{
      const data = {
        schedule:schedule
      }
      props?.HandleDataProps(data)
    },[schedule])
  return (
    <div className='pb-4'>
        <p className='pt-8 text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.SCHEDULE.TITLE" defaultMessage="Schedule" /></p>
<div className="flex flex-wrap space-x-4">
{Schedule.map((data, index)=> (
<CheckBox
value={data.name.props.defaultMessage}
onChange={(e) => CheckBoxHandle(e, schedule, setSchedule)}
checked = {schedule.includes(data.name.props.defaultMessage)} />
))}
   </div> </div>
  )
}
