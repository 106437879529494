import React, {useState} from "react";
import { Input } from "sr/helpers";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { WhiteBtn } from "../../work-order/component/WhiteBtn";
export const BillingInfoForm = (props) => {
    const [country, setcountry] = useState("United States")
  return (
    <div>
    <div className="text-lg font-satoshiBold px-5 pt-5">Billing information</div>
    <p className="text-xs px-5">Prices do not include taxes, which will be additionally charged where appropriate. All fields marked with * are required.</p>
    <form className="w-full flex flex-wrap mt-3">
        <DropDown value={country} onChange={(e:any)=> setcountry(e.target.value)} dropdowndiv="mt-0 w-1/2 pr-8 pb-5 pl-5" className="border border-zinc-800 rounded-lg h-11" label={'Country'}>
            <option value={'United States'}>United States</option>
            <option value={'Canada'}>Canada</option>
        </DropDown>
        <DropDown dropdowndiv="mt-0 w-1/2 pr-8 pb-5 pl-5" className="border border-zinc-800 rounded-lg h-11" label={'Currency'}>
            {country === 'United States' &&
            <option>USD $ (US Dollar)</option>
  }
         {country === 'Canada' &&
            <option>AUD $ (Australian Dollar)</option>
}
        </DropDown>
        {country=== 'Canada' &&
        <>
        <Input type="text" label="GST / HST Number" labelClass="text-base mt-2" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5 pl-5"></Input>
    <Input type="number" label="Quebec Sales Tax (QST) Numbers" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5 pl-5"></Input>
    </>}
    <div className="border-b-4 border-[#f3f2f1] w-full"></div>
    <div className="p-5 font-satoshiBold text-lg w-full">Billing address</div>
    <Input type="text" label="Address line 1" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5 pl-5"></Input>
    <Input type="number" label="Address line 2" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5 pl-5"></Input>
    <Input type="text" label="City" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5 pl-5"></Input>
    <DropDown dropdowndiv="mt-0 w-1/2 pr-8 pb-5 pl-5" className="border border-zinc-800 rounded-lg h-11" label={'Province'}>
          <option></option>
          <option>Alberta</option>
        </DropDown>
        <Input type="text" label="Postcode" labelClass="text-base" inputClass="border border-zinc-800 rounded-lg h-11" InputDivClass="mt-0 w-1/2 pr-8 pb-5 pl-5"></Input>
            </form>
            <div className="flex space-x-5 items-center px-5 pb-5">
    <WhiteBtn mainClass="px-3 py-1.5" title="Update"></WhiteBtn>
    <div onClick={props.onClick} className="text-[#2557A7] font-satoshiBold cursor-pointer">Cancel</div>
    </div>

   </div>
  );
};
