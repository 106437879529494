import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const removeContractorLocation = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/contractor/removelocations', putData).then((res)=> {
    if(res?.success===true){
      SuccessMessage('API.SUCCESS.CONTRACTOR.REMOVE.LOCATION')
        return res
    }
    else {
        return null
    }
  })
}
catch(e){
    HandleApiError(e)
}
};
