import React, { useState, forwardRef, ForwardRefRenderFunction, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage } from 'react-intl'
import { useFormik } from 'formik'
import { PreferencesSchema } from 'sr/utils/schemas/cleaner/PreferencesSchema'
import { useSelector } from 'react-redux'
import { RootState } from 'sr/redux/reducers'
import { createPreferences } from 'sr/utils/api/cleaner/createPreferences'
import { Link, useNavigate } from 'react-router-dom'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { CleanerComponentProps } from './CleanerComponentProps'
import { getPreferences } from 'sr/utils/api/cleaner/getPreferences'
import { updatePreferences } from 'sr/utils/api/cleaner/updatePreferences'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth'
import { employment } from '../company/jobpost/component/Jsonalldata'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'

const CleanerPreferences: ForwardRefRenderFunction<any, CleanerComponentProps> = (
  { HandleStepper = null, page = '', type = '',dataAddedHandle=null, id='' },
  ref
) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const [AddMoreClicked, setAddMoreClicked] = useState(false)
    const [userData, setUserData] = useState(null)
    const [preference, setPreference] = useState(null)
    const [loader, setLoader] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    useEffect(()=> {
      const getdetails = getUserDetails()
      setUserData(getdetails)
    },[])
    const initialValues = {
      cleaner_id:userData?.user_id,
      radius:'' ,
      shift_type :'' ,
      min_hours:'' ,
      max_hours :'' ,
    }
    const initialValuesPut = {
      cleaner_id:userData?.user_id,
      radius:preference?.radius,
      shift_type :preference?.shift_type,
      min_hours:preference?.min_hours,
      max_hours :preference?.max_hours,
      id:id
    }
    useEffect(()=> {
      const fetchData = async () => {
        setLoader(true)
        try {
          const preference = await getPreferences(undefined, id)
          console.log(preference);
          
          if(preference.success===true){
            setPreference(preference?.data)
            initialValues[id] = id
            setLoader(false)
          }
        }
        catch(e:any){
          console.log(e);
          
        }
      }
      if(page === 'popup' && type==='put'){
      fetchData()
      }   
    },[id])

    const {errors, handleChange, handleSubmit, values, submitForm} = useFormik({
      initialValues:page === 'popup' && type==='put'? initialValuesPut : initialValues,
      enableReinitialize:true,
      validationSchema: PreferencesSchema,
      onSubmit: async (values, helpers)=> {
        setButtonLoader(true)
        let result
  if(page === 'popup' && type==='put'){
    result = await updatePreferences(values)
  }
  else {
  result = await createPreferences(values)
  }
        if(result.success===true) {
          setButtonLoader(false)
          if(page === 'popup'){
            dataAddedHandle(true)
            }
          if(AddMoreClicked) {
              helpers.resetForm()
              setAddMoreClicked(false)
          }
          else {
            navigate('/cleaner-profile-page')
          }
        }
      },
    })
    React.useImperativeHandle(ref, () => ({
      submitForm,
    }));
    return (
      <ContentWithSpinner loader={loader}>
        {buttonLoader && <LoaderFullScreen />}
            <form className='mt-5' onSubmit={handleSubmit}>
                <Input value={values.radius} error={errors.radius} onChange={handleChange} label={intl.formatMessage({
    id: 'INPUT.RADIUS.TITLE',
    defaultMessage: 'Radius (in Miles)',
  })}
 type="number"
 name="radius"
 ></Input>
                <DropDown error={errors.shift_type} value={values.shift_type}
 onChange={handleChange} name='shift_type' label={intl.formatMessage({
    id: 'CLEANER.PROFILE.PREFERENCES.SHIFTTYPE',
    defaultMessage: 'Select Shift Type',
  })}>
     <option selected> <FormattedMessage id="CLEANER.PROFILE.PREFERENCES.CHOOSE.SHIFTTYPE" defaultMessage="Choose Shift Type" />  </option>
    { employment.map((data, index)=> (
                   
                   <option value={data.value}> {data.label}  </option>
                    )) }     </DropDown>
                <Input value={values.min_hours} error={errors.min_hours} onChange={handleChange} name="min_hours" label={intl.formatMessage({
    id: 'CLEANER.PROFILE.PREFERENCES.MINWORKHOURS',
    defaultMessage: 'Min hours Work(per week)',
  })}
 type="number" 
 placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.PREFERENCES.MINWORKHOURS.PLACEHOLDER',
    defaultMessage: 'Enter minimum hours work',
  })}


 ></Input>
                <Input value={values.max_hours} error={errors.max_hours} onChange={handleChange} name="max_hours" label={intl.formatMessage({
    id: 'CLEANER.PROFILE.PREFERENCES.MAXWORKHOURS',
    defaultMessage: 'Max hours Work(per week)',
  })}
 type="number" 
 placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.PREFERENCES.MAXWORKHOURS.PLACEHOLDER',
    defaultMessage: 'Enter maximum hours work',
  })}


 ></Input>
  <div className='mt-5 flex space-x-5'>
  {page === 'popup' && type === 'add'|| type==='put' ? '':(<>
    
                    <ButtonAuth loader={buttonLoader}
                      ><FormattedMessage id="REGISTER.BUTTON.SAVEANDCONTINUE" defaultMessage="Save and Continue" />
                    </ButtonAuth>
                  
                   <Link className='rounded-full text-center
                 bg-pink
                 p-2
                 text-white
                 w-44
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy' to={'/cleaner-profile-page'}>
                  Skip  </Link>
                               
  </>)
  }
                  </div>
            </form>
    </ContentWithSpinner>
    )
}
export default forwardRef(CleanerPreferences);