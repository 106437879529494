import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { ActionBtn } from './ActionBtn'
import { JobPostEdit } from './JobPostEdit'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { individualJobStatus } from 'sr/i18n/messages/setting'
export const TableRow = ({item}) => {
    const [editModel, setEditModel] = useState(false)
    const HandleModelByProps = (data) => {
        setEditModel(data)
       }
  return (
    <>
      
        <tr className="bg-white border-b  hover:bg-gray-50">
            
            <td className="w-4 p-4">
             {item?.title}
            </td>
          
            <td className="px-6 py-4">
                {`${item?.streetAddress} ${item?.unitorapt}`}
            </td>
           
            <td className="px-6 py-4">
            50$
            </td>
    
            <td className="px-6 py-4">
            {item?.schedule_date}
            </td>
            <td className="px-6 py-4">
            {item?.start_time}
            </td>
            <td className="px-6 py-4">
           
            {getLabelByValue(item?.work_status, individualJobStatus)}
            </td>
            <td className="px-6 py-4">
           
            <ActionBtn editonClick={()=> setEditModel(!editModel)}/>
           </td>   
        </tr>
        {editModel ?
    <JobPostEdit editModel={editModel} HandleModelByProps={HandleModelByProps}/>: null}
  </>)
}
