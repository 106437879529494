import React, { useEffect, useState } from "react";
import { MainWrapper } from "../../component/MainWrapper";
import { TopBar } from "../../component/TopBar";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useGet } from "../../Hooks/Api/useGet";
import { getSupervisor } from "sr/utils/api/company/workorder/getSupervisor";
import { AssignSupervisorLocation } from "sr/utils/api/company/workorder/AssignSupervisorLocation";
import { ReactSelect } from "sr/helpers/ui-components/ReactSelect";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { removeSupervisorLocation } from "sr/utils/api/company/workorder/removeSupervisorLocation";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { SecuritySetting } from "./components/SecuritySetting";
import { securityPermission } from "sr/i18n/messages/setting";
import { updateSupervisor } from "sr/utils/api/company/workorder/updateSupervisor";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { useAuth } from "app/pages/module/auth";

export const SupervisorDetails = () => {
  const [id, setId] = useState<string>()
  const [companyId, setCompanyId] = useState()
  const [customerLocations, setCustomerLocations] = useState([])
  const [data, setData] = useState<any>([])
  const [loader, setLoader] = useState('')
   const params = useParams()
   const [showSecurity, setShowSecurity] = useState<boolean>(false)
   const [permission, setPermission] = useState<{}>({})
    const {userType} = useAuth()
  useEffect(()=>{
const {id} = params
setId(id)
  },[])

  useEffect(()=> {
const {user_id} = getUserDetails()
setCompanyId(user_id)
  },[])

  const fetchSupervisor = async (id)=>{
    const queryParams = new URLSearchParams()
    if (id) queryParams.append('id', id)
        const res = await getSupervisor(queryParams)
      if(res?.success===true) {
        setData(res?.data)
        setPermission(res?.data?.access)
      }
    }
    const FetchCustomerLocations = async ()=> {
        setLoader('fetch')
      const queryParams = new URLSearchParams()
      queryParams?.append('company_id', companyId)
          const res = await getCustomerLocation(queryParams)
          if(res?.success===true) {
            setCustomerLocations(res?.data)
            setLoader('')
          }
          else {
            setLoader('')
          }
    }
    useEffect(()=> {
        if(id) {
          fetchSupervisor(id)
        }
    },[id])
    useEffect(()=> {
          if(companyId) {
            FetchCustomerLocations()
          }
    },[companyId])

  const AssignLocation = async (customerLocationid)=> {
    setLoader('submit')
    const payLoad = {
      supervisor_id:id,
      customer_location_ids:[customerLocationid]
    }
    const res = await AssignSupervisorLocation(payLoad)
    if(res?.success===true) {
      setLoader('')
            FetchCustomerLocations()
            fetchSupervisor(id)
    }
    
  }
  const checkAssigned = (customerLocationid)=> {
      return  data?.customer_location_ids?.some((item) => item?._id===customerLocationid)
  }
  const handleRemoveLocation = async (customerLocationid)=> {
    setLoader('submit')
        const payLoad = {
          supervisor_id:id,
          customer_location_ids:customerLocationid
        }
        const res = await removeSupervisorLocation(payLoad)
        if(res.success===true) {
          setLoader('')
          FetchCustomerLocations()
          fetchSupervisor(id)

        }
  }
  const HandlePermission = async ()=> {
    const payload = {
    id:id,
    access:permission
    }
    const res = await updateSupervisor(payload)
    if(res?.success===true) {

    }
  }
  useEffect(()=> {
   HandlePermission()
  },[permission])

  return (
    <MainWrapper >
      {loader==='submit' && <LoaderFullScreen />}
    <div className='py-3'>
    <TopBar title = {<FormattedMessage
          id='RECRUITER.SCHEDULEWORKORDER.INPUT.SUPERVISOR.TITLE'
          defaultMessage='Supervisor'
        />} 
        btntitle = {<FormattedMessage
          id='RECRUITER.SUPERVISOR.ALL.SUPERVISOR'
          defaultMessage='All Supervisor'
        />} btntitlelink = '/supervisor' postButton={<Link  to="/add-supervisor" className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-3  text-white cursor-pointer hover:bg-navy' ><FormattedMessage id="RECRUITER.WORK.ORDER.SUPERVISOR.ADD.BTN" defaultMessage="Add Supervisor" />
                                </Link>}  className='px-10'></TopBar>
                                <div className='pl-10'>
                                <div className='flex items-center space-x-4 mb-5'>
<Link to={`/supervisor`}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#0078ae] w-5 h-5 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
</Link>
<div>
    <h2 className=''>{data?.first_name} {data?.last_name}</h2>
    <Link to={`/supervisor`}>
      <p className='text-base  text-[#0078ae]'> <FormattedMessage id="RECRUITER.SUPERVISOR.BACKBUTTON" defaultMessage="Back" /></p>
      </Link>
</div>

</div> 
<ContentWithSpinner loader={loader==='fetch'}>

<table className="w-full text-left condidate-table my-">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
          <tr>
              <th scope="col" className="px-6 py-4 text-base ">
              <FormattedMessage id="INPUT.ADDRESS1.TITLE" defaultMessage="Address 1" />
              </th>
              <th scope="col" className="px-6 py-4 text-base ">
              <FormattedMessage id="INPUT.ADDRESS2.TITLE" defaultMessage="Address 2" />
              </th>
              <th scope="col" className="px-6 py-4 text-base ">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.LOCATION.TITLE" defaultMessage="City" />
              </th>
              <th scope="col" className="px-6 py-4 text-base ">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.STATE.TITLE" defaultMessage="State" />
              </th>
              <th scope="col" className="px-6 py-4 text-base ">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.ZIPCODE.TITLE" defaultMessage="Zip Code" />
              </th>
              <th scope="col" className="px-6 py-4 text-base ">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.COUNTRY.TITLE" defaultMessage="Country" />
              </th>
              <th scope="col" className="px-6 py-4 text-base ">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.COUNTRY.ACTION.TITLE" defaultMessage="Actions" />
              </th>
          </tr>
        </thead>
        <tbody>
          {customerLocations?.map((item, index)=> {
            return (
              <tr key={index} className="bg-white border-b  hover:bg-gray-50">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900">                
                    <div className='text-base'>{item?.address?.address_line_1}</div>                    
                </th>
                <td className="px-6 py-4">{item?.address?.address_line_2}</td>
                <td className="px-6 py-4">{item?.address?.city}</td>
                <td className="px-6 py-4">{item?.address?.state}</td>
                <td className="px-6 py-4">{item?.address?.postal}</td>
                <td className="px-6 py-4">{item?.address?.country}</td>
                <td className="px-6 py-4">

               
{checkAssigned(item?.id) ? 
<div className="flex space-x-3">
  <button className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy'>
  Assigned
</button>
<button onClick={()=> handleRemoveLocation(item?.id)} className='rounded-lg font-bold text-white px-5 py-2  bg-red-500  border-2 cursor-pointer hover:text-white hover:border-white '>
UnAssign
</button>
</div>
:
   <button onClick={()=> AssignLocation(item?.id)} className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy'>
  Assign Location
</button>
          }
                
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
</ContentWithSpinner>

<div className="bg-white rounded-lg my-3">
  <div onClick={(e)=> setShowSecurity(!showSecurity)} className={`flex justify-between items-center mx-10 cursor-pointer border-b border-neutral-200`}>
<div className={`text-xl  py-3`}>User Settings and Rights</div>
<span className={`transition-transform transform duration-1000 ${showSecurity ? 'rotate-180':''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg></span>

</div>
<div className={`animate-fade duration-1000 overflow-hidden ${showSecurity ? 'transition-maxHeight max-h-[90rem] ease-ease-vss':'max-h-0 overflow-hidden '}`}>
{
securityPermission.map((item, index)=> (
<SecuritySetting item={item} permission={permission} setPermission={setPermission}/>
))
}
</div>
</div>
    </div>
                                  </div>

        </MainWrapper>
    );
};
