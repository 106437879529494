import React, {useState, useEffect} from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input } from 'sr/helpers'
import { payTypes } from 'sr/i18n/messages/setting'
import { FormattedMessage } from 'react-intl'
export const ExactAmount = (props) => {
  const [pay, setPay] = useState('per hour')
  const [exactAmount, setExactAmount] = useState()
  const HandleChange = (e) => {
    setPay(e.target.value)
}

useEffect(()=> {
setPay(props?.pay)
setExactAmount(props?.exactAmount)
console.log(props?.pay);
},[])

const RangeData = {
  exact_amount:exactAmount,
  payType:pay ? pay : 'per hour'
}

useEffect(()=> {
  props.HandleShowPay(RangeData)
},[exactAmount, pay])
  return (
    <div className='flex space-x-4 w-full mt-10 items-center'>
        <div className='relative flex w-1/3'>
      <div className='absolute top-[34px] inset-0 z-50 w-6 h-6'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33" />
</svg>


      </div>
                 <Input 
                 onChange={(e:any)=> setExactAmount(e.target.value)}
                  label = "Exact"
                  placeholder="Enter Exact Amount"
                  type={'text'}
                    inputClass = 'border border-zinc-800 rounded-lg h-11'
                    InputDivClass = 'h-full w-full'
                    labelClass={'mt-2 text-sm text-normal '}
                    value={exactAmount}
                    min={1}
                    error={props?.errors?.exact_amount}
                 />
                 </div>
                 <DropDown
    onChange={HandleChange}
    value={pay}
    dropdowndiv='flex-1' 
    name="Rate" 
    label={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.TITLE" defaultMessage="Rate" />}
    className="h-11 border border-zinc-800 rounded-lg">
       {payTypes.map((data, index)=> (
        <option key={index} value={data.value}>{data.label}</option>
           ))}
       </DropDown>
                    </div>
  )
}
