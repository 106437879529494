import React from "react";
import { Spinner } from "./Spinner";

export const ButtonAuth = ({ children, loader=null, onClick=null, index=null }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className="rounded-full
                 bg-pink
                 p-2
                 text-white
                 w-44
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy"
      disabled={loader} // Disable the button when loader is true
    >
      {loader ? <Spinner className='flex w-full justify-center'/> : children}
    </button>
  );
};
