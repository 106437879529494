import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from 'sr/helpers'
import { Button } from 'sr/helpers'
import { Input } from 'sr/helpers'
import { FormDetails } from 'sr/helpers/ui-components/FormDetails'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import AuthNavComponent from './auth/AuthNav'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { changePassword } from 'sr/utils/api/changePassword'
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth'
import { resetPassword } from 'sr/utils/api/resetPassword'
export const ResetPassword = () => {
  const navigate = useNavigate();
  const [Password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [loader, setLoader] = useState(false)
    const intl = useIntl()
    const param = useParams()
    const [session, setSession] = useState('')
    const [searchParams] = useSearchParams();

    useEffect(()=> {
      const session = searchParams.get("session");
      setSession(session)
    },[param])
    const initialValues = {
      password:Password,
      confirmPassword:confirmPassword
    }
    const passwordValidate = Yup.object().shape({
      password:Yup.string().required("INPUT.VALIDATION.PASSWORD").min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'INPUT.VALIDATION.CONFIRMPASSWORD.MATCH')
  .required('INPUT.VALIDATION.CONFIRMPASSWORD')
  })
      const {errors, handleSubmit} = useFormik({
        initialValues:initialValues,
        enableReinitialize:true,
        validationSchema:passwordValidate,
        onSubmit: async (values)=> {
          try {
            const queryParams = new URLSearchParams()
            queryParams.append('session', session)
            setLoader(true)
            const res = await resetPassword(queryParams, values)
            if(res.success===true) {
                setLoader(false)
                navigate(`/auth`);
            }
            else {
              setLoader(false)
            }
          }
          catch(e:any) {
  setLoader(false)
          }
        }
      })
  return (
    <>
    <AuthNavComponent isLoginPage={true}></AuthNavComponent>
    <main>
    <div className='lg:container mx-auto lg:max-w-6xl lg:mt-8 mt-8 px-4 lg:px-0'>
        <div className='lg:flex block'>
      <FormDetails />
        <div className='lg:flex-1 lg:flex flex-col h-fit lg:mx-12 block p-6 bg-white border border-gray-border rounded-xl'>
            <h1 className=' text-xl'>Reset Password</h1>
            <div className='max-w-lg'>
            <form className='mt-5'>
            <Input
                onChange={(e)=> setPassword(e.target.value)}
                value={Password}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD",
                    defaultMessage: "Password",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
                    defaultMessage: "Enter your password",
                  })}
                  type={"password"}
                  name="password"
                  error={errors.password}
                ></Input>
                                <Input error={errors.confirmPassword} label={intl.formatMessage({id:"AUTH.INPUT.CONFIRM_PASSWORD", defaultMessage:'Confirm Password'})}
                                    placeholder={intl.formatMessage({id:"AUTH.INPUT.CONFIRM_PASSWORD", defaultMessage:'Confirm Password'})}
                                   onChange={(e)=> setConfirmPassword(e.target.value)}
                                   value={confirmPassword}
                                   type={'password'}>
                                </Input>
                            
                            </form>
                            <ButtonAuth onClick={handleSubmit}  loader={loader}>Reset Password</ButtonAuth>
        </div>
      </div>
    </div></div></main></>
  )
}
