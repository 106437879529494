import React from "react";
import { FormattedMessage } from "react-intl";
export const Transaction = () => {
  return (
    <div className='py-3'>
        <div className="flex justify-center space-x-2 text-[#0078ae]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
</svg>
<p className="font-semibold"><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.DOWNLOAD.BUTTON" defaultMessage="Download Transaction History" />
</p>
        </div>
        {[{}, {}].map(()=> (
        <div className="max-w-xl mx-auto mt-10">
            <div className="flex justify-between pb-5">
                <div>
                    <p className=" text-base">Shelf Mounting</p>
                    <p><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.TASKER.TITLE" defaultMessage="Tasker" />:Jagadish M.</p>
                </div>
                <div>Aug 8, 2021</div>
            </div>
            <div>
            <div className="flex justify-between">
                <p>$55.29/hr x 1hr</p>
                <p>$55.28</p>
            </div>
            <div className="flex justify-between  text-[#0078ae] ">
                <p><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.TRUST_SUPPORT_FEE" defaultMessage="Trust & Support Fee" /></p>
                <p>$55.28</p>
            </div>
           <div className="ml-4">
           <div className="flex justify-between">
                <p><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.TASKER_AMOUNT" defaultMessage="Tasker Amount" /></p>
                <p>$47.00</p>
            </div>
            <div className="flex justify-between">
                <p><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.JMM_SERVICE_FEE" defaultMessage="JMM Service Fee" /></p>
                <p>$7.00</p>
            </div>
           </div>
           <div className="flex justify-between">
                <p><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.PROMO_CODE" defaultMessage="Promo Code" /></p>
                <p>$10.28</p>
            </div>
        </div>
        <div className="py-3 border-y border-neutral-300 my-3">
        <div className="flex justify-between ">
                <p className=""><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.TOTAL" defaultMessage="Total" /></p>
                <p>$55.28</p>
            </div>
            <div className="flex justify-between">
                <p className=""><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.TRANSACTION.HISTORY.CHARGED_TO_CARD" defaultMessage="Charged to Card" />:</p>
                <p>$55.28</p>
            </div>
        </div>
        </div>
))}
    </div>
  );
};
