import React from 'react'

export const ReviewDataEdit = (props) => {
  return (
        <div className='py-2'>
<div className='py-1 flex items-center space-x-4'>
<p className='text-base font-satoshiBold'>{props && props?.title}</p>
<svg onClick={props.onClick} xmlns="http://www.w3.org/1000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5 text-[#2557A7] cursor-pointer"><path d="M20.706 7.042a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83zm-2.898 2.9l-9.705 9.71a.5.5 0 01-.218.128l-4.246 1.2a.5.5 0 01-.618-.616l1.209-4.24a.5.5 0 01.127-.216l9.7-9.716 3.75 3.75z"></path></svg>
</div>
<p>{props && props?.value}</p>
{props && 
<ol className='list-disc list-inside'>
 {
    props?.multivalue?.map((data)=> (
        <li>{data}</li>
        ))
}
</ol>
}
</div> 
  )
}
