import React from 'react'
import { CompanySettingData } from '../../component/SidebarItems'
import { FormattedMessage } from 'react-intl'
export const Sidebar = ({activeTab, HandleTabs}) => {
  return (
    <div className='h-screen bg-white rounded-lg py-4'>
        <h1 className='px-3  text-xl'> <FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS" defaultMessage="Settings" />  </h1>
        <ul>
        {CompanySettingData.map((data)=> (
          
         <li onClick={() => HandleTabs(data.text)} className={`cursor-pointer flex items-center rounded-lg hover:rounded hover:bg-[#EEF1FE] my-2 px-3 py-2 ${activeTab === data.text  ? 'bg-[#EEF1FE] border border-[#0078ae]' : '' }`}>
            <span className="p-1.5">
{data.icon}
            </span> 
        <div>
            <div className={`block  text-base`}>
  <span>{data.text}</span>
</div>
<div className={`block text-sm`}>
  <span>{data.smalltext}</span>
</div>
</div>

         </li>
))}
        </ul>
    </div>
  )
}
