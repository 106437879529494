import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the shape of the form's state
interface Payload {
  title: string;
  streetAddress: string;
  unitorapt: string;
  description: string;
  location_id: string;
  tasklist_id: string;
  task_date:string,
  from:string,
  to:string,
  cleaner_id:string,
}

// Initial state
const initialState: Payload = {
  title: '',
  streetAddress: '',
  unitorapt: '',
  description: '',
  location_id: '',
  tasklist_id: '',
  task_date:'',
  from:'',
  to:'',
  cleaner_id:'',
};

// Create a slice of the state
const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    // Action to update the form state
    updateForm(state, action: PayloadAction<Partial<Payload>>) {
      return {
        ...state,
        ...action.payload, // Merging new data with existing state
      };
    },
  },
});

// Export the action so it can be dispatched
export const { updateForm } = formSlice.actions;

// Export the reducer to include in the root reducer
export default formSlice.reducer;
