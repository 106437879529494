import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { remove } from "sr/utils/axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const deleteBranchInfo = async (deleteData:any) => {
  try {
    return await remove<ApiResponse<[]>>('/company/branch', deleteData).then((res)=> {
        if(res.success=== true) {
            SuccessMessage("API.SUCCESS.COMPANY.BRANCH.INFO.DELETE")
            return res
        }
        else {
            HandleApiError(res.error.message)
              return null
        }
    })
  }
  catch(e:any) {
    HandleApiError(e);
    
  }
};
