import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { DaysConverter } from "sr/helpers/functions/DaysConverter";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";

export const InterestedWorkOrder = ({datas}) => {
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [jobId, setJobId] = useState('')
    const param = useParams()
    const {getData:getWorkOrders, data:data, loader:loader, pageination:pageination}:any = useGet()
    useEffect(()=> {
            const {id} = param
            setJobId(id)
    },[param])
    const fetchData = async ()=> {
        const queryParams = new URLSearchParams()
        if (page) queryParams.append('limit', limit.toString())
        if (limit) queryParams.append('page', page.toString())
            if (datas?.customer_location_id?.address?.city) queryParams.append('location', datas?.customer_location_id?.address?.city) 
        await getWorkOrders(() => getWorkorder(queryParams));
    }
      useEffect(()=> {
        if(datas?.customer_location_id?.address?.city){
              fetchData()
        }
      },[page, limit])
  return (
       <div className={`lg:flex block flex-col h-fit lg:ml-5 m-0 p-6 bg-white border border-gray-border rounded-xl`}>

<h1 className='font-bold  text-base'>
<FormattedMessage 
id='INTERESTED.WORKORDER.TITLE'
defaultMessage='Work order you might be interested in'
/>

</h1>
<ContentWithSpinner loader={loader} height='h-20'>
{
    data?.length>0  ?
    data?.map((item) => {
        const postedDays = DaysConverter(item?.createdAt);
        return (
            <>
             <Link to={`/cleaner/work-order-details/${item?.id}`}>
                <div className='recommended-job-list-item'>
                <h3 className='mt-5 font-bold  text-sm'>{item?.title}</h3>
                    <h3 className='mt-1 font-bold  text-xd'>{item?.customer_id?.name}</h3>
                    <h4 className='font-medium text-xs text-gray-600'>{item?.company_id?.company_name}</h4>

                    <div className='mt-2 flex justify-between items-center'>
                    <div className='lg:px-0 px-4 text-start '>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span className='pl-2'>{item?.customer_location_id?.address?.address_line_1?.slice(0, 10) || item?.customer_location_id?.address?.address_line_2?.slice(0, 10)} {item?.customer_location_id?.address?.city} </span>
                    </div>
                        <div className='text-xs text-gray-400'>
                            <FormattedMessage
                            id='ECOMMERCE.COMMON.TEXT.POSTED'
                            defaultMessage='Posted'
                            /> <FormattedMessage
                            id='CLEANER.JOBS.TIME'
                            defaultMessage='{postedDays} Days Ago'
                            values={{postedDays}}
                            />
                        </div>
                    </div>
                </div>
                </Link>
                <div className='border-t border-gray-200 my-5'>
                </div>
            </>
        )
    })
    :
    <p className=' text-lg text-center mt-3'>No Jobs Found</p>
}
</ContentWithSpinner>

</div>
);
};
InterestedWorkOrder.defaultProps = {
    width: 'lg:w-1/3 w-full',
  }
