import { HandleApiError } from "sr/helpers/functions/HandleApiError"
import { createStripIntent } from "sr/utils/api/strip/createStripIntent"
import addCreditCard from "./addCreditCard"

interface ParamsType {
customerId:string
currency:string
cardnumberElement:any
    stripe:any
    elements:any
    email:string
    cardcvv:any,
    cardExpiry:any,
    setCardDetails:(params:any)=> void
    setIsProcessing:(params:any)=> void
    stripId:string
    setShowModel?:(params:any)=> void
    onCheckModel?:any
}
const CreateIntent = async (params:ParamsType)=> {
  params?.setIsProcessing(true)
  if(!params?.cardnumberElement){
    console.log('card element not available')
  }
  if(params?.cardnumberElement){
    console.log('card element is available');    
  }
    try {
      const payload = {
        currency: params?.currency,
        customer:params?.stripId
      }
        const res = await createStripIntent(payload)
        if(res?.success===true) {
          addCreditCard({clientSecret:res?.data?.client_secret, 
            setCardDetails:params?.setCardDetails, 
            email:params?.email, 
            setShowModel:params?.setShowModel,
            setIsProcessing:params?.setIsProcessing, 
            stripId:params?.stripId, stripe:params?.stripe,
             elements:params?.elements, customerId: params?.customerId, 
             cardnumberElement:params?.cardnumberElement, 
             cardcvv:params?.cardcvv, 
             cardExpiry:params?.cardExpiry,
             onCheckModel:params?.onCheckModel
            
            })
        }
    }
    catch(e) {
      params.setIsProcessing(false)
HandleApiError(e)
    }
  }
export default CreateIntent;  