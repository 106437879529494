import { useFormik } from "formik";
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useState } from "react";
import { Days } from "../company/work-order/component/Days";
import { Input } from "sr/helpers";
import { FormattedMessage } from "react-intl";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { createAvailability } from "sr/utils/api/cleaner/createAvailability";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { getAvailability } from "sr/utils/api/cleaner/getAvailability";

const Availability: ForwardRefRenderFunction<any, any> = ({HandleStepper = null, page = '', type = '', dataAddedHandle=null, id='' }, ref)=>{
    const [Availability, setAvailability] = useState([])
    const [userData, setUserData] = useState(null)
    const [loader, setLoader] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
    useEffect(()=> {
        const getdetails = getUserDetails()
        setUserData(getdetails)
      },[])
      useEffect(()=> {
   const fetchAvailability = async ()=> {
    const params = new URLSearchParams()
    params.append('cleaner_id', id)
    try {
        const res = await getAvailability(params)
        if(res?.success===true) {
          setAvailability(res?.data[0]?.availability)
        }
        else {
          return null
        }
    }
    catch(e:any) {

    }
   }
if(id) {
  fetchAvailability()
}
      },[id])
    const initialValues = {
            cleaner_id:userData?.user_id,
            availability:Availability
    }
    const handleAvailability = (params)=> {
            switch (params?.type){
                case 'day':
                    if(Availability?.some((item)=> item?.day===params?.day)){
                        setAvailability(Availability?.filter((item)=> item?.day !==params?.day))
                    }
                    else {
                        setAvailability((Availability)=> [...Availability, {day:params?.day}])
                    }
                    break;
                case 'startTime':
                    setAvailability((allObj)=> 
                        allObj?.map(dayObj=> 
                            dayObj.day === params?.day 
                            ? { ...dayObj, start_time: params?.startTime } : dayObj
                        )
                    )
                    break;
                case 'endTime':
                    setAvailability((allObj)=> 
                        allObj?.map(dayObj=> 
                            dayObj.day === params?.day 
                            ? { ...dayObj, end_time: params?.endTime } : dayObj
                        )
                    )
            } 
    }
    const {errors, submitForm} = useFormik({
        initialValues:initialValues,
        enableReinitialize:true,
        onSubmit:async (values)=> {
            setButtonLoader(true)
            try {
                const res = await createAvailability(values)
                if(res?.success) {
                        setButtonLoader(false)
                        if(page === 'popup' ){
                        dataAddedHandle(true)
                        }
                }
                else {
                        setButtonLoader(false)
                }
            }catch(e:any) {
                    setButtonLoader(false)
            }
        }
    })
    const checkIncluded = (fullName)=> {
       return Availability?.some((item)=> item?.day===fullName)
    }
    React.useImperativeHandle(ref, () => ({
        submitForm,
      }));
  return (
    <ContentWithSpinner loader={loader}>
        {buttonLoader && <LoaderFullScreen />}
    <div>
        {Days.map((item)=> (
        <div className="flex space-x-3 w-full mb-8 items-center">
           <span onClick={()=> handleAvailability({day:item?.fullName, type:'day'})}
                                    className={`${
                                      checkIncluded(item?.fullName)
                                        ? "bg-[#2557A7] text-white"
                                        : "bg-white border border-neutral-200"
                                    } cursor-pointer rounded-full h-10 w-10 flex justify-center items-center`}
                                  >
                                    {item?.short}
                                  </span>
            <Input
            onChange={(e)=> handleAvailability({day:item?.fullName, startTime:e.target.value, type:'startTime'})}
                    label={
                      <FormattedMessage
                        id="CLEANER.PROFILE.AVAILABILITY.START.TIME"
                        defaultMessage="Start Time"
                      />
                    }
                    value={Availability?.find((data)=> data?.day===item?.fullName)?.start_time}
                    inputClass="rounded-lg"
                    labelClass="mt-2 text-black"
                    InputDivClass="mt-0 pr-2 pb-2 w-[50%]"
                    type="time"
                    placeholder={
                      <FormattedMessage
                        id="CLEANER.PROFILE.AVAILABILITY.START.TIME"
                        defaultMessage="Start Time"
                      />
                    }
                  ></Input>
                  <Input
                   onChange={(e)=> handleAvailability({day:item?.fullName, endTime:e.target.value, type:'endTime'})}
                    label={
                      <FormattedMessage
                        id="CLEANER.PROFILE.AVAILABILITY.END.TIME"
                        defaultMessage="End Time"
                      />
                    }
                    value={Availability?.find((data)=> data?.day===item?.fullName)?.end_time}
                    inputClass="rounded-lg"
                    labelClass="mt-2 text-black"
                    InputDivClass="mt-0 pr-2 pb-2 w-[50%]"
                    type="time"
                    placeholder={
                      <FormattedMessage
                        id="CLEANER.PROFILE.AVAILABILITY.END.TIME"
                        defaultMessage="End Time"
                      />
                    }
                  ></Input>
            </div>
        ))}
        
    </div>
    </ContentWithSpinner>
    );};
export default forwardRef(Availability);