import React from "react";
import { post } from "sr/utils/axios";
import { toast } from "react-toastify";
import ApiResponse from "sr/models/ApiResponse";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const createBranchInfo = async  (postData:any) => {
    try {
        return await post<ApiResponse<[]>>('/company/branch', postData ).then((res)=> {
            if(res.success === true) {
                SuccessMessage("API.SUCCESS.COMPANY.BRANCH.INFO.UPDATE")
                return res
            }
            else {
                toast.error(res.error.message)
                  return null
            }
        })
        }
        catch(e:any) {
        toast.error(e.message)
        }
};
