import React, {useEffect, useState} from 'react'
import { Input} from 'sr/helpers';
import { RadioInput } from 'sr/helpers/ui-components/company/RadioInput';
import { FormattedMessage } from 'react-intl';
export const Notification = (props) => {
  const [notification, setNotification] = useState(false)
  const [email, setEmail] = useState('');

  useEffect(()=> {
    const notifications = props?.data?.notifications;
    const email = props?.data?.email;
      setNotification(notifications);
      setEmail(email);
  },[props?.data]);

  useEffect(()=> {
    const defaultEmail = props?.data?.email;
    const data = {
      notifications:notification,
      email: notification ? email : defaultEmail,
    }
    props?.HandleDataProps(data)
  },[notification, email]);

  return (
    <div className='pt-10'>
    <p className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.TITLE" defaultMessage="Notification" /> </p>
    <p className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.SENDDAILY.TITLE" defaultMessage="Send daily updates to" /> </p>      
   <RadioInput 
   name="notification1" value={true} checked={notification === true} 
   onChange= {(e)=>setNotification(true) } 
   label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.YES" defaultMessage="Yes" />}
   inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
    <RadioInput
      name="notification2"
      value={false}
      checked={notification === false} 
      onChange={(e)=>setNotification(false) }
      label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.NOTIFICATION.NO" defaultMessage="No" />}
      inputcss='border border-zinc-800 h-11 rounded-lg my-2'
    />
    {notification === true &&
      <Input 
        inputClass = "border border-zinc-800 rounded-lg h-11"
        labelClass = "text-2xl"
        smalllabelclass="font-bold mt-1 text-lg"
        type="Email"
        placeholder="Enter Email Address"
        onChange={(e:any)=> setEmail(e.target.value)}
        value={email}
      />
    }
    </div>
  )
}
