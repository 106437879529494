import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const registerSupervisor = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/supervisor/register', putData).then((res)=> {
    if(res.success===true) {
      SuccessMessage('API.SUCCESS.REGISTER.SUPERVISOR.SUCCESS')
        return res
    }
    else {
        return null
    }
  })
}
catch(e){
    HandleApiError(e)
}
};
