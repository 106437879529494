import React, {useState} from 'react'
import { CompanyNameChange } from './models/CompanyNameChange';
import { ModelWrapperWithButton } from 'app/pages/company/component/ModelWrapperWithButton';
export const JobPreferences = () => {
    const [showModal, setShowModal] = useState(false)
    const [activeComponent, setActiveComponent] = useState<any>({});
    const HandleModel = (data) => {
        setShowModal(data)
}
const AllModels = [
    {
      label: 'CompanyNameChange',
      componentName: CompanyNameChange,
      headertitle:'Change your company name'
    }
    ]

const openModel = (index: any) => {  
    const activeComp = AllModels.filter(function (e, i) {
      return i == index
    });      
    console.log(activeComp[0])
    setActiveComponent(activeComp[0]);

    setShowModal(true)
  };
  return (
    <>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
    <div>
        <h2 className=''>Preferred Work Location</h2>
        <div>Lucknow</div>
    </div>
    <div onClick={()=> openModel(0)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
            Change Location</div>
</div>
<ModelWrapperWithButton showModal = {showModal} onCheckModel = {HandleModel} headertitle = {activeComponent.headertitle}>
    {<activeComponent.componentName />}
    </ModelWrapperWithButton>
    </>)
}
