import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Checkbox, Input } from "sr/helpers";
import Footer from "app/pages/module/Footer";
import CheckBoxHandle from "sr/helpers/functions/CheckBoxHandle";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import { JobLoaderCard } from "../component/JobLoaderCard";
import JobItemCard from "../component/JobItemCard";
import { Pageination } from "sr/helpers/ui-components/Pageination";
import { WorkOrderCard } from "./compoent/WorkOrderCard";
import { Filter } from "../component/workOrder/Filter";
import { debounce } from "sr/helpers/functions/Debounce";

export const WorkOrderList = () => {
  const [Filterdata, setFilterdata] = useState<any>({})
    const intl= useIntl()
    const [tab, setTab] = useState('Current Jobs')
    const [location, setLocation] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalPage, settotalPage] = useState<number>(1)
    const isFirstRender = useRef(true);
    const {getData:getWorkOrders, data:data, loader:loader, pageination:pageination}:any = useGet()
    const fetchData = async ()=> {
      const queryParams = new URLSearchParams()
      if (page) queryParams.append('limit', limit.toString())
      if (limit) queryParams.append('page', page.toString())
      if (Filterdata?.pay_type?.length>0) queryParams.append('pay_type', Filterdata?.pay_type) 
      if (Filterdata?.location) queryParams.append('location', Filterdata?.location)
        let filter = []
          const first = {"status": {"$in": "publish"}}
          const second = {"workorder_status": {"$in": "scheduled"}}
          const three = {"type": {"$in": "open"}}
          filter.push(first)
          filter.push(second)
          filter.push(three)
          Object.keys(Filterdata)?.forEach((item)=> {
            if (item && Filterdata[item] && (Filterdata[item]?.length > 0 || Object.keys(Filterdata[item]).length > 0)) {
              let condition1 = {};
              let condition2 = {};
              if(item==='createdAt') {
                  Object.keys(Filterdata[item]).forEach(key => {
                    
                      if (key && Filterdata[item][key]) { 
                          if(key==='from')    { 
              condition1[item] = { "$gte": Filterdata[item][key] };
                          }
                    
                      if(key==='to'){ 
              condition2[item] = { "$lte": Filterdata[item][key] };
                      
                      }  }
                  })
              }
                  console.log(condition1);
                  
                  filter.push(condition1)
                  filter.push(condition2)
                  
                  
          } 
          })
          let newfilter;
    if(filter?.length>0) {
        if(filter?.length===1)  {
     newfilter = filter[0]
        }
        else {
            newfilter = {"$and":filter}
        }}
        queryParams?.append('filter', JSON.stringify(newfilter))
        let query = '';
        if(Filterdata?.rateRange?.minRange && Filterdata?.rateRange?.maxRange){
        query = `pay_type_rate>${Filterdata?.rateRange?.minRange}&pay_type_rate<${Filterdata?.rateRange?.maxRange}`
        }
      await getWorkOrders(() => getWorkorder(`${queryParams}&${query.toString()}`));
  }
  // const debouncedFetchData =     debounce(fetchData, 500)
  //   useEffect(()=> {
  //     if(Filterdata?.rateRange?.maxRange || Filterdata?.rateRange?.minRange) {
  //         debouncedFetchData()
  //     }
  //   },[page, limit,Filterdata])

  //Currently i am using events for tracking sliding after user sliding complete and release mouse then we calling

  useEffect(()=>{
fetchData()
  },[page, limit, Filterdata])

    
    useEffect(()=> {
      settotalPage(Math.ceil(pageination?.total/limit))
    },[pageination])


    const handlePageChange = (value)=> {
        if(value>totalPage || value<1){
          return
        }
        setPage(value)
    }
    const HandleFilterData = (data)=> {
      setFilterdata(data)
      console.log(data);
      
    }


  return (
    <>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>

    <div className="py-3 lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0">
    <div className='grid lg:grid-cols-4 grid-cols-1 lg:gap-4 gap-0 pt-4'>
                    <div className='col-span-1'>
                      <Filter HandleFilterData={HandleFilterData}/>

                      </div>
                      <ContentWithLoader loading={loader} content={<JobLoaderCard></JobLoaderCard>}>
                <div className="col-span-3">
                {
                    data?.map((datI, index) => {
                        return <WorkOrderCard key={index} data={datI}></WorkOrderCard>
                    })
                }
                <Pageination page = {page} totalpage= {totalPage} limit = {limit} onPageChange = {handlePageChange}/>
                </div>
                </ContentWithLoader>
                      </div>   

 </div>
    <Footer /> 
    </>
  );
};
