import { useValidationErrorHandle } from "../functions/validationErrorHandle";
function TextArea(props: any) {
  
const validationErrorHandle = useValidationErrorHandle()
  return (
    <div className={props?.divClassName}>
      <label htmlFor={props.name} className={`${props?.labelClass} text-normal  text-sm`}>
        {props.label}
      </label>
      <textarea
        value={props.value}
        id={props.name}
        rows={props.rows == null ? '2' : props.rows}
        onChange={props.onChange}
        className={`${props.className} `}
        placeholder={props?.placeholder}
      ></textarea>
      {props?.error ? <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(props?.error)}</p> : ''}
    </div>
  )
}
TextArea.defaultProps = {
  className:'block w-full md:px-4 sm:px-1 md:py-2 sm:py-1 mt-2 sm:text-sm md:text-md text-gray-700 placeholder-gray-500 bg-white border border-gray-300 rounded-2xl focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40',
  divClassName:"mt-10"
}
export {TextArea};