import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const createJobtype = async (postData:any) => {
 try {
    return await post<ApiResponse<[]>>(`/cleaner/jobtype`, postData).then((res)=> {
        if(res.success === true) {
            SuccessMessage('API.SUCCESS.JOBTYPE.ADD')
            return res
        }
        else {
            toast.error(res.error) 
            return null
        }
    })
 }
 catch(e:any) {
    HandleApiError(e);
            return null
 }
};
