import React, { useEffect, useState } from "react";
import { Sidebar } from "./Components/Sidebar";
import { MainWrapper } from "../component/MainWrapper";
import { FormattedMessage } from "react-intl";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { TimeFormat } from "sr/helpers/functions/TimeFormat";
import { capitalizeFirstLetter } from "sr/helpers/functions/firstletterCapital";
import { ModelWrapperWithButton } from "../component/ModelWrapperWithButton";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { PaymentTypes } from "sr/i18n/messages/setting";
import { Input } from "sr/helpers";
import { ChargesCalculator } from "sr/helpers/functions/ChargesCalculator";
import { transferBalance } from "sr/utils/api/wallet/transferBalance";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import getLabelByValue from "sr/helpers/functions/getLabelByValue";
import { sendPaymentSchema } from "sr/utils/schemas/company/sendPaymentSchema";

export const HistoryDetails = ({setDetails, details, setdataAdded}) => {
    const [companyId, setCompanyId] = useState<string>('')
    const [modelOpen, setModelOpen] = useState(false)
    const [paymentType, setPaymentType] = useState<string>('')
    const [amount, setAmount] = useState(null)
    const [sendAmount, setSendAmount] = useState(0)
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState(null)
    const handlePay = async ()=>{
        setLoader(true)
        try {
            const payload = {
                to:details?.workorder_id?.contractor_id?.cleaner_id, 
                workorder_id:details?.workorder_id?._id, 
                from:companyId, 
                tokenId:'1271231',
                 amount:amount ? amount : 0, 
                 data:'',
                  description:paymentType}
                 const error =  await ValidateData(payload)
                 if(Object.keys(error || {})?.length>0){
                    console.log(
                     Object.keys(error)?.length>0
                    )
                    return
                 }
      const restransfer = await transferBalance(payload)
    if(restransfer?.success===true){
        SuccessMessage('API.SUCCESS.PAYMENT.SEND')
        setModelOpen(false)
        setDetails('')
        setdataAdded(true)
        setLoader(false)
    }
    else {
        setLoader(false)
    }
}
catch(e){
setLoader(false)
}
    }
    useEffect(()=>{
            const {user_id} = getUserDetails()
            setCompanyId(user_id)
    },[companyId])
    useEffect(()=>{
        setSendAmount(amount- ChargesCalculator(amount, 10))
    },[amount])
    useEffect(()=>{
            if(paymentType){
                if(errors?.hasOwnProperty('description')){
                errors['description'] = ''
                }

            }
            if(amount){
                if(errors?.hasOwnProperty('amount')){
                errors['amount'] = ''
                }
            }
    },[amount, paymentType])
    const ValidateData = async (payload)=> {
    try {
        await sendPaymentSchema.validate(payload, { abortEarly: false });
        setErrors({}); // Reset errors if validation is successful
    } catch (validationErrors) {
        const newErrors = {};
        validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
        return newErrors
    }
}
  return (<>
         <div className='py-2 flex space-x-2'>
    <div className="w-full">
    <div onClick={()=> setDetails('')} className='flex items-center space-x-4 cursor-pointer'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#0078ae] w-5 h-5 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
<div>
    <h2 className=''></h2>
      <p className='text-base  text-[#0078ae]'> <FormattedMessage id="RECRUITER.SUPERVISOR.BACKBUTTON" defaultMessage="Back to" /></p>
    
</div>

</div>
        <div className="bg-white  rounded-[20px] shadow-sm shadow-slate-300 border-gray-200 border mx-auto w-[50%]">
            <div className="flex py-3 px-3 justify-between items-center border-b border-gray-200">
                <div className="flex space-x-3 items-center">
                    <div className="flex w-12 h-12 rounded-full items-center justify-center bg-red-50">
                    {details?.type ==='transfer' ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
</svg>
:
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
</svg>
}


                    </div>
                    <div>
                    {details?.type ==='transfer' ?<>
                        <p className="text-sm font-medium text-gray-800">Paid to</p>
                        <p className="">{details?.workorder_id?.contractor_id?.first_name} {details?.workorder_id?.contractor_id?.last_name}</p>
                 </>   :
                    <p className="text-sm font-medium text-gray-800">Credited</p>
                    }
                    </div>
                </div>
                <div className="text-sm font-medium text-gray-800">
                    ${details?.amount?.toFixed(2)}
                </div>
            </div>
            <div className="flex px-3 py-3 justify-between items-center border-b border-gray-200">
            <p className="">Date and Time</p>
            <p>{DateFormat(details?.createdAt)} {TimeFormat(details?.createdAt)}</p>
                </div>
                <div className="flex px-3 py-3 justify-between items-center border-b border-gray-200">
            <p className="">Transaction Type</p>
            <p>  {details?.type ==='transfer' && 'Debit'} {details?.type ==='mint' && 'Credit'}
            </p>
                </div>
                {details?.type ==='transfer' ?<>
                <div className="flex px-3 py-3 justify-between items-center border-b border-gray-200">
            <p className="">Payment Type</p>
            <p>{details?.description?.includes('.') ? <FormattedMessage id={details?.description} ></FormattedMessage> : getLabelByValue(details?.description, PaymentTypes)}</p>
                </div>
    
       <div className="flex px-3 py-3 justify-between items-center border-b border-gray-200">
            <p className="">Workorder (Title)</p>
            <p>{details?.workorder_id?.title}</p>
                </div>
                <div onClick={()=> setModelOpen(true)} className="flex justify-center py-4">
                <div className="text-center rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy" >Send Payment</div>
                </div>
                </>
                :
                <>
                 <div className="flex px-3 py-3 justify-between items-center border-b border-gray-200">
            <p className="">Card Type</p>
            <p>{capitalizeFirstLetter(details?.brand)}</p>
                </div>
    
       <div className="flex px-3 py-3 justify-between items-center border-b border-gray-200">
            <p className="">Card Details</p>
            <p>XXXX XXXX XXXX {details?.last4}</p>
                </div>
                </>
}

                </div>
       </div>
          </div>
          {modelOpen &&
          <ModelWrapperWithButton showModal={modelOpen} onCheckModel={()=> setModelOpen(false)} Save={handlePay} savetext = {'Send Payment'}>
<div className="max-w-96 mx-auto">
    <div className="flex space-x-3 pt-2 pb-4">
<div className="flex w-12 h-12 rounded-full items-center justify-center bg-red-50">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
</svg></div>
<div>
<p className="text-sm font-medium text-gray-800">Paid to</p>
                        <p className="">{details?.workorder_id?.contractor_id?.first_name} {details?.workorder_id?.contractor_id?.last_name}</p>
                        </div>       </div>
      <DropDown  
                     labelClass="text-base"
                     value={paymentType}
                     error={errors?.description}
                     label={'Payment Type'}
                     dropdowndiv="mt-1 pr-2 pb-2 mb-3"
                     onChange={(e) => setPaymentType(e.target.value)}
                     className="rounded-lg"
                   >
                     <option value="">
                       <FormattedMessage
                         id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION"
                         defaultMessage="Select Location"
                       />
                     </option>
                     {PaymentTypes?.map((item, index) => (
                       <option key={index} value={item?.value}>{item.label}</option>
                     ))}
                   </DropDown>
                   <Input 
                      prefix="$" 
                    //   error={error}
                      label={'Enter Amount'}
                      InputDivClass="mt-0 w-26 pr-2 pb-2" 
                      labelClass=" text-base" 
                     inputClass="border rounded-lg  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={amount} error={errors?.amount} onChange={(e)=>setAmount(e.target.value)} type="number"></Input>
{amount>0 && 
<div className="py-4">
    <div className="flex justify-between">
        <p>Total Amount</p><p>${Number(amount)?.toFixed(2)}</p></div>
        <div className="flex justify-between  text-[#0078ae] ">
            <p>Support Fee</p><p>${ChargesCalculator(amount, 10)?.toFixed(2)}</p></div>
            <div className="ml-4"><div className="flex justify-between">
                <p>{details?.workorder_id?.contractor_id?.first_name} {details?.workorder_id?.contractor_id?.last_name} Receive</p><p>${Number(sendAmount)?.toFixed(2)}</p></div>
         </div>
</div>
}
</div>
          </ModelWrapperWithButton>
          }
          </>);
};
