import React, {useEffect, useRef, useState} from 'react'
import { ModelWrapper } from 'app/pages/company/component/ModelWrapper'
import { ChatBottom } from './ChatBottom'
import { FormattedMessage } from 'react-intl'
import Application from 'app/pages/component/types/cleaner/application'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { getMessage } from 'sr/utils/api/cleaner/getMessage'
import Message from 'app/pages/component/types/message/message'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import getTimeFromDate from 'sr/helpers/functions/getTimeFromDate'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { io } from 'socket.io-client'
import { formatISO } from 'date-fns'
import { scrollToBottom } from 'sr/helpers/functions/scrollToBottom'
import { useSocket } from 'app/pages/component/SocketContext'
import { uploadFile } from 'sr/utils/api/uploadFile'
import { ChatImg } from 'app/pages/individual/jobs/component/ChatImg'
import { DisplayMessageReceiver } from 'app/pages/component/DisplayMessageReceiver'
interface PropsType {
  data:Application
}
export const ChatBox = (props:PropsType) => {
    const [message, setMessage] = useState<string>('');
    const [allmessage, setAllMessage] = useState<any>([])
    const [cleanerId, setCleanerId] = useState<string>()
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalPage, setTotalPage] = useState(0)
    const messagesEndRef = useRef(null);
        const [imageId, setImageId] = useState('')
          const [imageUrl, setImageUrl] = useState('')
    
    useEffect(()=> {
      const {user_id} = getUserDetails()
      setCleanerId(user_id)
    },[])
    const socket = useSocket()
       useEffect(() => { 
        if(socket){
          
          // Register the user (replace 'userId123' with the actual user ID)
          socket.emit('register', cleanerId);
          socket.off('sendmessage');

        }
        socket.on('sendmessage', ({ from, message }) => {
          console.log(message);
          console.log(props?.data?.job_id?._id);
          
          if(message?.job_id===props?.data?.job_id?._id){
          const currentDateTime = formatISO(new Date());
          const newmessage = {createdAt:currentDateTime, ...message}
          setAllMessage((preview)=> [...preview, newmessage])    
          scrollToBottom(messagesEndRef)
          }
        });
        return () => {
          socket.off('sendmessage');
        };
      
        
      }, [cleanerId, props]);

      const sendMessage = () => {
        const payload = {
            message: message,
            recipient_id: props?.data?.job_id?.company_id?.id,
            sender_id:cleanerId,
            job_id:props?.data?.job_id?._id,
            file_id:imageId,
            type:'chat',
            sender_id_type:'cleaner',
        recipient_id_type:'company',
        };
        console.log(payload);
    
        socket.emit('sendmessage', payload); // Match the event name in the backend
        setMessage(''); // Clear the input
        const currentDateTime = formatISO(new Date());
        const newmessage = {createdAt:currentDateTime, ...payload}
        setAllMessage((preview)=> [...preview, newmessage])
        setImageId('')
        setImageUrl('')
    };

       const fetchChat = async (job_id)=> {
        setLoader(true)
        const queryParams = new URLSearchParams()
        queryParams?.append('job_id', job_id)
        queryParams.append('page', page?.toString())
        queryParams.append('limit', limit?.toString())
    try {
    const res = await getMessage(queryParams)
    if(res?.success===true) {
    setAllMessage((item) => [...(item || []), ...(res?.data || [])]); 
    setTotalPage(Math.ceil(res?.pagination?.total/limit))
    setLoader(false)
    }
    else {
    setLoader(false)
    }
    }
    catch(e) {
    setLoader(false)
    }
       }
useEffect(()=> {
if(props?.data?.job_id?._id) {
  fetchChat(props?.data?.job_id?._id)
}
},[props?.data?.job_id?._id, page])
 const handleImageClick = ()=> {
        setImageId('')
        setImageUrl('')
      }
      const handleImage = async (e)=> {
        setLoader(true)
      const formData = new FormData()
      formData.append('cleaner_id', cleanerId);
      formData.append('chat_img', e.target.files[0]);
      const result = await uploadFile(formData);
          if(result?.success===true) {
            setImageId(result?.data?.files[0].key)
            setImageUrl(URL.createObjectURL(e.target.files[0]))
            setLoader(false)
          }
          else {
            setLoader(false)
          }
      
      
      }
const handleScroll = () => {
 console.log(messagesEndRef.current?.scrollTop);
 if (messagesEndRef.current?.scrollTop === 0 && totalPage>page) {
  setPage((prev) => prev + 1);
}

};
useEffect(() => {
  if (page === 1) {
    scrollToBottom(messagesEndRef);
  }
}, [allmessage]); // Scroll after messages update


  return (
    <>
      {loader && <LoaderFullScreen></LoaderFullScreen>}
    <div>
    <div className='flex justify-between items-center py-3 px-3'>
        <div>
        <h1 className='text-2xl  text-[#595959]'>{props?.data?.job_id?.company_id?.company_name}</h1>
        <p className=''>Applied to {props?.data?.job_id?.job_title} in {props?.data?.job_id?.job_advanced_id?.reporting_address?.city}</p>
        </div>
    </div>
<hr className="border-2 border-neutral-200" />
<div className="flex flex-col h-[60vh] justify-between">
<div onScroll={handleScroll}
 ref={messagesEndRef} className='flex-1 h-[60vh] overflow-auto mb-3'>
<div className='text-center mb-10 mt-3'>
{DateFormat(props?.data?.createdAt)}
</div>
<div className="flex items-center justify-center mb-4">
  <div className="border-t flex-1 mr-4"></div>
  <span className="text-black ">Today</span>
  <div className="border-t flex-1 ml-4"></div>
</div>
<div>
 {allmessage?.sort((a, b)=> {
  const adate = new Date(a?.createdAt)
  const bdate = new Date(b?.createdAt)

  const differenceInMilliseconds = adate.getTime() - bdate.getTime();
return differenceInMilliseconds
 }).map((item)=> (
   <div>
    {(item?.sender_id?.id || item?.sender_id)  === cleanerId ?(
      <div className='flex justify-end items-center space-x-2 px-2'>
<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className=' text-base'> You </div>
    <div className='text-sm'>
     {getTimeFromDate(item?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
  {item?.message}
    </div>
    {item?.file_id &&
                    <ChatImg item={item}></ChatImg>
               }
    </div>
</div>
    ):(
      <div className='flex justify-start items-center space-x-2 px-2 pb-6'>
    <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
    {props?.data?.job_id?.company_id?.company_name?.slice(0,1)?.toUpperCase()} 
    </div>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className='text-sm'>
   {getTimeFromDate(item?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
    <DisplayMessageReceiver item={item}></DisplayMessageReceiver>
    </div>
    {item?.file_id &&
                    <ChatImg item={item}></ChatImg>
               }
    <div />
    </div>
</div>
)}
</div>
 ))} 
  
  </div>
</div>
<ChatBottom imageUrl={imageUrl} uploadChange={handleImage} handleImageClick={handleImageClick} Send={sendMessage} Clear={()=> setMessage('')} onChange={(e)=> setMessage(e.target.value)} value={message} />

</div></div>
</>
  )
}
