import React, { useEffect, useState } from 'react'
import TextEditor from '../TextEditor'
import { FormattedMessage } from 'react-intl'
export const JobDescription = (props) => {
  const [description, setDescription] = useState("")
  useEffect(()=> {
      setDescription(props?.data?.job_description)
  },[props?.data])
  useEffect(()=> {
    const data = {
      job_description:description
    }
      props?.HandleDataProps(data)
  },[description])

  return (
    <>
    <p className='font-satoshiBold text-base'><FormattedMessage id="RECRUITER.POSTJOB.JOBDESCRIPTION.TITLE" defaultMessage="Job Descripion" /></p>
   {description && <TextEditor setdescription={setDescription} description={description}/> }
   </>
  )
}
