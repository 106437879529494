import React, {useState} from 'react'
import { ModelWrapper } from '../../component/ModelWrapper'
import { WhiteBtn } from './WhiteBtn'
import { Input } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
export const JobPostEdit = (props) => {
    const [tasklist, settasklist] = useState(0)
    const [address, setaddress] = useState('')
  const [dateandtime, setdateandtime] = useState('')
    const [price, setPrice] = useState(0)
  return ( <>
        <>
        <ModelWrapper rounded="rounded-[10px]" background="bg-[#00000099]" position="fixed" opacity="opacity-100" topPosition="top-[10%]" rightPosition="inset-0" width = "w-[50%] mx-auto" HandleModelByProps={props.HandleModelByProps}>
           
           <div className='flex justify-end space-x-2 py-3 px-3 mb-3 border-b border-solid border-slate-200'>
           <WhiteBtn title="Draft"></WhiteBtn>
           <WhiteBtn title="Publish"></WhiteBtn>
           <WhiteBtn onClick={()=> props.HandleModelByProps(false)} title="Cancel"></WhiteBtn>
           </div>
      <div className='px-5'>
<DropDown value={address} label='Location' dropdowndiv='mt-3' onChange={(e) => setaddress(e.target.value)} className='rounded-lg' >
<option value=''>Select Address</option>
<option>Lucknow</option>
<option>Delhi </option>
</DropDown>
<DropDown value={tasklist} label='Checklist' dropdowndiv='mt-3' onChange={(e) => (e.target.value)} className='rounded-lg' >
<option value=''>Select Tasklist</option>
<option>My Hospital Tasklist</option>
</DropDown>
<Input
            label="Suggest a Price"
            onChange={(e) => setPrice(e.target.value)}
            type="number"
            inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            labelClass="mt-2 text-black"
            InputDivClass="mt-5 w-26 pr-2 pb-2"
            placeholder="Enter Price"
            value={price} />
        <Input
          label="Date and Time"
          onChange={(e) => setdateandtime(e.target.value)}
          type="datetime-local"
          inputClass="rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          labelClass="mt-2 text-black"
          InputDivClass="mt-5 w-26 pr-2 pb-2"
          value={dateandtime} />
<div className='flex justify-between space-x-6 items-center  pb-3 mb-3 mt-3'>
           <textarea rows={3} className="flex-1 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder="Write your note here..."></textarea>
         
           </div>   </div>  
       </ModelWrapper>
              </>
    </>
  )
}
