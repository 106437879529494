import { getContractor } from "sr/utils/api/company/workorder/getContractor"
interface paramsType {
  setLoader?:(value:boolean) => void
  userType?: string
  userId?:string
  customerLocationId?:any
  page?:number
  limit?:number
  status?:string
  setData?:(array:[])=> void
    id?:string
    setStepperIndex?:(value:number) => void
    supervisor_id?:string
}
export const fetchContractor = async (params:paramsType)=> {
    if (params?.setLoader) params?.setLoader(true);
    try {
    const param = new URLSearchParams()
    if (params?.status) param.append('status', params?.status)
    if(params?.userType==='company'){
    if (params?.userId) param.append('company_id', params?.userId) 
    }
    if (params?.id) param.append('id', params?.id)   
if (params?.userType==='supervisor'){
  let filter = [];
  if (params?.supervisor_id) {
    filter?.push({supervisor_id:params?.supervisor_id})
  }
  if (params?.customerLocationId) {
    filter?.push({customer_location_ids:{"$in":params?.customerLocationId?.split(",")}})
  }
param?.append('filter', JSON.stringify({"$or":filter}))
}

      param.append('status!','deleted' ) 
      if (params?.page) param.append('page', params?.page?.toString())
      if (params?.limit) param.append('limit', params?.limit?.toString())  
      const res = await getContractor(param)
        if(res?.success===true) {
          if (params?.setData) params?.setData(res?.data)
            if (params?.setLoader) params?.setLoader(false);
          if(res?.data?.length>0) {
            params?.setStepperIndex(4)
          }
          return res?.data;
        }
        else {
            if (params?.setLoader) params?.setLoader(false);
        }
    }
    catch(e) {
        if (params?.setLoader) params?.setLoader(false);
    }
}