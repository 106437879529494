import Axios, {AxiosRequestHeaders} from 'axios'
// import {ACCESS_TOKEN_KEY} from 'sr/constants/common'
import {getCookieValue} from '../helper'
import { PASSWORD, USERNAME } from 'sr/constants/common'

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_DEV
})

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(
  async (config) => {
    const copy = {...config}
    const basicAuthCredentials =
      typeof window !== 'undefined'
        ? btoa(`${USERNAME}:${PASSWORD}`)
        : Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');


    // if (typeof localStorage !== 'undefined') {
    //   const accessToken = getCookieValue(ACCESS_TOKEN_KEY)
    //   if (accessToken && copy.headers) {
    //     ;(copy.headers as AxiosRequestHeaders).AccessToken = accessToken
    //   }
    // }

    ;(copy.headers as AxiosRequestHeaders).Authorization = `Basic ${basicAuthCredentials}`

    return config
  },
  async (error) => {
    console.log(error);
    
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  async (response) => {
    if (response.data.error == null) {
      return response
    }
    return Promise.reject(response)
  },
  async (error) => {
    if (error.response && error.response.status >= 400) {
      console.error("Bad Request Error:");
      console.error("Response data:", error.response.data);
      console.error("Response headers:", error.response.headers);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }

)

export default axios
