import { faDollarSign, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { Empty } from "app/pages/company/candidates/component/Empty";
import { ModelWrapperWithButton } from "app/pages/company/component/ModelWrapperWithButton";
import { StringLoader } from "app/pages/component/Loader/StringLoader";
import { useAuth } from "app/pages/module/auth";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Checkbox } from "sr/helpers";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { DaysConverter } from "sr/helpers/functions/DaysConverter";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { HTMLStringRender } from "sr/helpers/ui-components/HTMLStringRender";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { createfavoriteWorkOrder } from "sr/utils/api/cleaner/workorder/createfavoriteWorkOrder";
import { getfavoriteWorkOrder } from "sr/utils/api/cleaner/workorder/getfavoriteWorkOrder";
import { getChecklist } from "sr/utils/api/company/customer/getChecklist";
import { getCustomer } from "sr/utils/api/company/customer/getCustomer";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { getAdditionInfo } from "sr/utils/api/company/getAdditionInfo";
import { getCompany } from "sr/utils/api/company/getCompany";
import { applyWorkOrder } from "sr/utils/api/company/workorder/applyWorkOrder";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { getapplyWorkOrder } from "sr/utils/api/company/workorder/getapplyWorkOrder";
import { getFiles } from "sr/utils/api/getFiles";
import { uploadFile } from "sr/utils/api/uploadFile";
import { InterestedWorkOrder } from "./compoent/InterestedWorkOrder";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
export const WorkOrderDetails = () => {
    const param = useParams()
    const [id, setId] = useState<string>()
    const [address, setAddress] = useState<any>({})
    const [postedDays, setpostedDays] = useState<any>()
    const [isSaved, setIsSaved] = useState(false)
    const [isApplied, setIsApplied] = useState()
    const [cleanerId, setCleanerId] = useState(null)
    const [resume, setResume] = useState(null)
    const [newResume, setNewResume] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const {isAuthenticate, userType, logout} = useAuth()
    const [message, setMessage] = useState('')
    const [buttonLoader, setButtonLoader] = useState(false)
    const navigate = useNavigate()

    const [applyWithOld, setapplyWithOld] = useState(false)
        const {data:data, loader:loader, getData:getWorkOrderDetail}= useGet()
        const {data:checklistData, loader:checklistLoader, getData:getChecklists} = useGet()
        const {data:companyAdditional, loader:companyLoader, getData:getcompanyInfo} = useGet()
        const {data:workOrderapplied, loader:applyLoader, getData:getapplicationworkorder} = useGet()
        const {data:favoriteOrder, loader:favoriteLoader, getData:getFavoriteOrder} = useGet()

        useEffect(()=> {
            const fetchWorkOrder = async () => {
                const queryParams = new URLSearchParams()
                if (cleanerId) queryParams.append('cleaner_id', cleanerId)
                     getFavoriteOrder(()=> getfavoriteWorkOrder(queryParams))   
            }
                    if(cleanerId) {
                        fetchWorkOrder()
                    }
        },[cleanerId])

        useEffect(()=> {
            if(userType ==='cleaner') {
                const getdetails = getUserDetails()
                setCleanerId(getdetails?.user_id)
            }
        },[])

        const getWorkOrderapplied = async (cleanerId)=> {
            const params = new URLSearchParams()
            if (cleanerId) params.append('cleaner_id', cleanerId)
                await getapplicationworkorder(()=> getapplyWorkOrder(params))
        }

        useEffect(()=> {
                    setIsApplied(workOrderapplied?.some((item)=> item?.workorder_id?._id===id))

        },[workOrderapplied])
        // const fetchCustomer = async (customer_id)=> {
        //     const queryParams = new URLSearchParams()
        //     if (customer_id) queryParams.append('id', customer_id)
        //         await getCustomerData(() => getCustomer(queryParams));
        // }
    useEffect(()=> {
            const {id} = param
            setId(id)
    },[param])

    useEffect(()=> {
            const fetchDetails = async (id)=> {
                        const queryParams = new URLSearchParams()
                        if (id) queryParams.append('id', id)
                            await getWorkOrderDetail(()=> getWorkorder(queryParams))
            }
            if(id) {
                fetchDetails(id)
            }
    },[id])
    const onCheckModel = () => {
        setShowModal(false)
    }

//     const FetchCustomerLocation = async (id)=> {
//         try {
      
//         const queryParams = new URLSearchParams();  
//         if (id) queryParams.append('id', id.toString());
//         const result = await getCustomerLocation(queryParams)
//         if(result?.success===true) {
//             console.log(result?.data?.address);
            
// setAddress(result?.data?.address)
//         }
//         else {
       
//         }
//       } 
//       catch(e) {
      
//       }
//       }

      const fetchAddInfo = async (company_id) => {
        const queryParams = new URLSearchParams()
        queryParams.append('id', company_id)
            await getcompanyInfo(( ) =>  getAdditionInfo(company_id, undefined))


        }

    useEffect(()=> {
        const fetchChecklist = async (id)=> {
            const queryParams = new URLSearchParams()
            if (id) queryParams.append('checklist_id', id)
                await getChecklists(()=> getTask(queryParams))
}
if(data?.checklist_id) {
    fetchChecklist(data?.checklist_id?._id)
    // FetchCustomerLocation(data?.customer_location_id)
    fetchAddInfo(data?.company_id?._id)
    // fetchCustomer(data?.customer_id)

}     
    },[data?.checklist_id, data?.customer_location_id])

    useEffect(()=> {
        if(cleanerId) {
        getWorkOrderapplied(cleanerId)
        }
    },[cleanerId])
    useEffect(()=> {
        const days = DaysConverter(data?.createdAt)
        setpostedDays(days)
    },[data?.createdAt])

    const HandleApply = async () => {
        setButtonLoader(true)
        if((isAuthenticate || !isAuthenticate) && userType !=='cleaner') {
            logout()
             navigate('/auth')
             return
        }
        const params = {
            cleaner_id:cleanerId
        }
         const resumeData = await getFiles(params)
        if(resumeData.success===true) {
            const filterfile = resumeData?.data?.filter((item)=> item?.file?.fieldname==='resume')
            if(filterfile?.length>0) {
                setResume(filterfile && filterfile[0]?.file?.originalname)
                setapplyWithOld(true)
                setButtonLoader(false)
            }
            // const resume = await getFile(filterfile[0].file.key)
            // if(resume) {
            //     console.log(resume);
            // // const rawPdfData: Uint8Array = resume as Uint8Array;
            // // const pdfBlob = new Blob([rawPdfData], { type: 'application/pdf' });
            // // const pdfFile = new File([pdfBlob], 'resume.pdf', { type: 'application/pdf' });
            //     // setResume(pdfFile)
            //      }
    }
    setButtonLoader(false)
    setShowModal(true)
    }
    const HandleResume = async (e: any) => {
        setButtonLoader(true)
        try {
        const filedata = new FormData();
        filedata.append('cleaner_id', cleanerId)
        filedata.append('resume', e.target.files[0]);
        console.log(filedata);
        const fileres = await uploadFile(filedata)
       if(fileres?.success===true) {
        setNewResume(fileres && fileres?.data?.files[0].originalname)
        setButtonLoader(false)
       }
        }
        catch (error) {
          console.error('Error uploading resume:', error);
          setButtonLoader(false)
        }
      };
    const ApplyJob = async () => {
        setButtonLoader(true)
                if(!resume && !newResume) {
                    setMessage('Please add Resume')
                    setButtonLoader(false)
                    return 
        }
        const data = {
            workorder_id:id,
            status:"active",
            cleaner_id:cleanerId
        }
    const result = await applyWorkOrder(data)
    if(result.success===true) {
        getWorkOrderapplied(cleanerId)
        setButtonLoader(false)
        setShowModal(false)
    }  
    } 
    const HandleSave = async (id)=> {
        setButtonLoader(true)
        if((isAuthenticate || !isAuthenticate) && userType !=='cleaner') {
            logout()
             navigate('/auth')
             return
        }
            const payLoad = {
                cleaner_id:cleanerId,
                workorder_id:id
            }
            const res = await createfavoriteWorkOrder(payLoad)
            if(res?.success===true){
                
            setIsSaved(true)
            setButtonLoader(false)
            }
    }
    useEffect(()=> {
        if (favoriteOrder && favoriteOrder.workorder_ids && data) {
        setIsSaved(favoriteOrder?.workorder_ids.some((item)=> item?._id=== data?.id))
        }
},[favoriteOrder?.workorder_ids, data])

  return (


    <>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>

    <div className="py-3 lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0">
        {buttonLoader && <LoaderFullScreen />}
    <ContentWithSpinner loader={loader} height="h-screen">
    <div className='lg:flex block'>
                    <div className='flex flex-col lg:w-2/3 w-full'>
                    <div className='card-1 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <div className='header'>
                            <h1 className='font-bold  text-lg'>{data?.title}</h1>
                                <h1 className='font-bold  text-lg'>{data?.customer_id?.name}</h1>
                                <ContentWithLoader loading={false} content={<StringLoader />}>
                                <h2 className='text-sm font-medium text-gray-800'>{data?.company_id?.company_name}</h2>
                                </ContentWithLoader>

                                {/* <h3 className='text-xs font-normal text-gray-500'>Hiring for Leading Client</h3> */}
                            </div>

                            <div className='job-attr mt-5'>
                                
                                <div className='row-2 flex'>
                                <div className='pr-8'>
                                        <FontAwesomeIcon icon={faDollarSign} className='text-gray-600 text-sm font-medium' />
                                        <span className='pl-2 text-gray-600 text-sm font-medium'> {data?.pay_type_rate} {data?.pay_type}</span>
                                    </div>
                                    {data?.customer_location_id?.address &&
                                    <div className='pr-8'>
                                        <FontAwesomeIcon icon={faLocationDot} className='text-gray-600 text-sm font-medium' />
                                        <span className='pl-2 text-gray-600 text-sm font-medium'>{data?.customer_location_id?.address?.address_line_1} {data?.customer_location_id?.address?.address_line_2} {data?.customer_location_id?.address?.city} {data?.customer_location_id?.address?.state}</span>
                                    </div>
}
                                </div>
                                <div className='border-t border-gray-200 my-5'>
                                </div>
                                <div className='lg:flex block justify-between items-center'>
                                    <div className='row-1 flex'>
                                        <div className='pr-8'>
                                            <span className='lg:pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='ECOMMERCE.COMMON.TEXT.POSTED' defaultMessage={'Posted'}/>:</span>
                                            <span className='lg:pl-2 text-gray-900 text-sm font-semibold'>  <FormattedMessage 
                           id='CLEANER.JOBS.TIME'
                           defaultMessage='{postedDays} Days Ago'
                           values={{postedDays}}
                        /></span>
                                        </div>
                                        {/* <div className='pr-8'>
                                            <span className='pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='JOBDETAILS.OPENINGS' defaultMessage='Openings' />:</span>
                                            <span className='pl-2 text-gray-900 text-sm font-semibold'>{job?.job_advanced_id?.hire_count || 1 }</span>
                                        </div> */}
                                        {/* <div className='pr-8'>
                                            <span className='pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='CLEANER.JOBS.JOBAPPLICANTS' defaultMessage='Job Applicants' />:</span>
                                            <span className='pl-2 text-gray-900 text-sm font-semibold'>{job?.application_ids?.length || 0}</span>
                                        </div> */}
                                    </div>

                                    <div className='mt-8 lg:m-0'>
                                        {  isApplied ? 
                                        <button className='bg-[#ff39b3] font-bold text-white hover:bg-navy p-2 w-24 rounded-3xl mr-3'><FormattedMessage id='APPLIEDJOBS.APPLIED' defaultMessage='Applied' /></button>
:
<button onClick={HandleApply} className='bg-[#ff39b3] font-bold text-white hover:bg-navy p-2 w-24 rounded-3xl mr-3'><FormattedMessage id='JOBS.APPLYBUTTON' defaultMessage='Apply' /></button>

}
                                          
                                         {/* <button onClick={()=> HandleSave(data?.id)} className='border border-pink hover:bg-red-50 p-2 w-24 rounded-3xl'><div> {isSaved ? <FormattedMessage id='JOBS.SAVEDBUTTON' defaultMessage='Saved'></FormattedMessage>: <FormattedMessage id='JOBS.SAVEBUTTON' defaultMessage='Save' />  }</div></button> */}
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='card-2 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            {/* <div className='flex flex-col p-6 bg-gray-50 border border-gray-border rounded-xl'>
                                <h1 className='font-bold  text-base'>
                                    <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.TITLE'
                                    defaultMessage='Job Highlights'
                                    />
                                </h1>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.BENIFITS'
                                    defaultMessage='Benefits'
                                    />:</h3>
                                    <div className='flex flex-wrap'>
                                    {job?.benefits?.toString().replaceAll(',', ', ')}                                      
                                 </div>
                                    
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.JOBTYPE'
                                    defaultMessage='Job Type'
                                    />: </h3>
                                       <div className='flex flex-wrap '>
                                    {job?.job_type?.toString().replaceAll(',', ', ')}
                                    </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.EMPLOYMENTTYPE'
                                    defaultMessage='Employment Type'
                                    />: </h3>
                                       <div className='flex flex-wrap '>
                                    {job?.employment_type?.toString().replaceAll(',', ', ')}
                                    </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.SCHEDULE'
                                    defaultMessage='Schedule'
                                    />: </h3>
                                    <div className='flex  flex-wrap '>
                                    {job?.schedule?.toString().replaceAll(',', ', ')}
                                 </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.SUPPLEMENTALPAY'
                                    defaultMessage='Supplemental Pay'
                                    />: </h3>
                                    <div className='flex flex-wrap '>
                                    {job?.job_advanced_id?.supplemental_pay?.toString().replaceAll(',', ', ')}
                                 </div>
                                </div>
                            </div> */}
                            <div className='job-description mt-6'>
                                <h1 className='font-bold  text-base'>
                                <FormattedMessage 
                                    id='JOBDETAILS.JOBDESCRIPTION'
                                    defaultMessage='Job Description'
                                    />
                                </h1>
                                <div><HTMLStringRender htmlContent={data?.description}></HTMLStringRender></div>
                            </div>

                            <div>
                                <div className="mt-6">
            <span className='font-bold  text-sm'> <FormattedMessage id="PENDING.JOBS.JOB.LOCATION.TITLE" defaultMessage="Location" />  :</span>
                                    <span className='text-sm text-gray-700 pl-1'>{address?.address_line_1} {address?.address_line_2} {address?.city}</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.ENTRYTIME" defaultMessage="Entry Time" />  :</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.entry_time}</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.EXITTIME" defaultMessage="Exit Time" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.exit_time}</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.PAYTYPE" defaultMessage="GENERAL.TEXT.TITLE.PAYTYPE" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{data?.pay_type} (${data?.pay_type_rate})</span>
                                </div></div>
                            <div className='mt-6 mb-6 border border-neutral-200 rounded-[20px]'>
                                <table className="w-full text-left condidate-table">
    <thead className="border-collapse border-b border-neutral-200 capitalize">
        <tr>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="PENDING.JOBS.TABLE.COL.NAME.TASK" defaultMessage="Task" />  
            </th>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="PENDING.JOBS.TABLE.COL.NAME.DESCRIPTION" defaultMessage="Description" /> 
            </th>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="PENDING.JOBS.TEXT.TYPE" defaultMessage="Type" /> 
            </th>
        </tr></thead>
        <tbody>
        {checklistData?.map((data, index)=> (
                           <tr className="bg-white border-b  hover:bg-gray-50">
                           <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                          
                               <div className='text-base'>{data.name}</div>
                               
                           </th>
                           <td className="px-6 py-4">
                               {data.description}
                           </td>
                         
                   
                           <td className="px-6 py-4">
                           {data.type}
                           </td>
                          
                       </tr>
                  ))}
        </tbody>
        </table></div>
                        </div>
                        <div className='card-2 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <h1 className='font-bold  text-base'>
                                <FormattedMessage
                                id='JOB.ABOUT.COMPANY.TITLE'
                                defaultMessage={'About Company'}
                                />
                            </h1>
                            <ContentWithLoader loading={companyLoader} content={<StringLoader />}>
                            <div>{companyAdditional?.about_company}</div>
                            </ContentWithLoader>

                            <h3 className='mt-5 font-bold  text-sm'>
                            <FormattedMessage
                                id='JOB.COMPANY.INFO.TITLE'
                                defaultMessage={'Company Info'}
                                />
                                </h3>
                            <div>
                                {/* <span className='font-bold  text-sm'><FormattedMessage
                                id='JOB.COMPANY.ADDRESS.TITLE'
                                defaultMessage={'Address'}
                                />:</span> */}
                                {/* <span className='text-sm text-gray-700 pl-1'>{job?.job_advanced_id?.reporting_address?.address_line_1} {job?.job_advanced_id?.reporting_address?.address_line_2} {job?.job_advanced_id?.reporting_address?.city} {job?.job_advanced_id?.reporting_address?.state}</span> */}
                            </div>
                            <div className='flex space-x-2 my-4'>
                                        {companyAdditional?.social_link_fb &&
<Link className="rounded-lg py-0.5 px-2 transition-ease-out duration-25 bg-[#3b5998] hover-[#2d4373] border-[#3b5998] 
                                     hover:bg-[#2d4373] hover:border-[#2d4373] active:bg-[#2d4373] active:border-[#2d4373]" to={companyAdditional?.social_link_fb}>

                                        <div aria-hidden="true" className="inline-block fill-white stroke-none">
                                        <svg className="h-4 w-4 m-0 align-middle inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z">
                                                </path></svg></div>
                                                </Link>}
                                             {companyAdditional?.social_link_twitter &&
                                            <Link className="rounded-lg transition-ease-out duration-25 bg-[#0288D1] hover:bg-[#2795e9] hover:border-[#2795e9] active:bg-[#2795e9] active:border-[#2795e9]" to={companyAdditional?.social_link_twitter}>
                                           
                                        <div aria-hidden="true" className="inline-block fill-white stroke-none">
<svg className='h-8 w-8 m-0 align-middle inline-block' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48">
    <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"/>
    <path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"/></svg>
    
    </div></Link> }
                                </div>   
                        </div>

                
                    </div>
                     <InterestedWorkOrder datas={data} />
                  </div>
         
                  </ContentWithSpinner>
               
                  <ModelWrapperWithButton showModal={showModal} onCheckModel = {onCheckModel} savetext={'Continue'} Save={ApplyJob}>
                {message && <p>{message}</p>}
               {resume && <div>
                    <p className=''>Continue With</p>
                    <div className='flex space-x-3'>
                            <Checkbox label={resume} checked={applyWithOld===true} value={applyWithOld} onChange={(e:any)=> setapplyWithOld(!applyWithOld)} id={'resumeshow'}></Checkbox>
                    </div>
                  </div>
                  }
{!applyWithOld && <>
                <div className=' mt-5'>Upload new CV</div>
                <div className="flex items-center justify-center w-full mt-2">
    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">Supported Formats: doc, docx, rtf, pdf, upto 2 MB
</p>
        </div>
        <input onChange={HandleResume} id="dropzone-file" type="file" className="hidden" />
    </label>
</div> 
{    newResume &&  
<div className="flex items-center justify-between  w-full my-4 px-5">
  <p className="">{newResume} </p>
</div>
}
</>}
               
            </ModelWrapperWithButton>
            </div>

        </>

);
};
