import React, {useState} from 'react'
import { ReactDatePicker } from 'sr/helpers/ui-components/ReactDatePicker'

export const TextHideShow = (props) => {
    const [show, setShow] = useState(false)
    const toggleShow = () =>{
        setShow(!show)
    }
  return (
    <div className='cursor-pointer border-b-[1px]'>
    <div onClick={toggleShow} className='font-bold py-4 flex justify-between'>
    {props.Title} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-gray-500 w-5 h-5">
<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
</svg>
</div>
<div className={`mb-10 max-h-44 max-h-32 overflow-y-auto 	${show ? "block": "hidden"}`}>
<ReactDatePicker onChange={props?.From} selected={props?.fromValue} label="From" InputDivClass="mt-2">

</ReactDatePicker>
<ReactDatePicker onChange={props?.To} selected={props?.toValue} label="To" InputDivClass="mt-2">

</ReactDatePicker>

  </div>
  </div>
  )
}
