import React from "react";
import { toast } from "react-toastify";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "../axios";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const login = async (postData:any) => {
    try {
        return await post<ApiResponse<[]>>('/auth/', postData).then((res)=> {  
        if(res.success===true) {
            return res
        }
        else {
            toast.error(res.error.message)
            return null
        }
        })
        }
        catch(e:any) {
            HandleApiError(e);
            return null
        }
            }
