import React, {useState} from 'react'
import { Input } from 'sr/helpers';
import AutoLocation from 'sr/helpers/ui-components/AutoLocation';
import { FormattedMessage, useIntl } from 'react-intl';
export const LocationEdit = () => {
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const intl = useIntl()
  return (
    <div>
    <form>
     <AutoLocation></AutoLocation>
     <div className='mt-10'>
                <label htmlFor="Remarks" className=" mb-2 text-sm font-satoshiBold flex items-center">
                  <FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TEXTAREA.TITLE.REMARKS" defaultMessage="Remarks" /></label>
<textarea id="Remarks" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" placeholder={ intl.formatMessage({ id:"RECRUITER.CUSTOMER.INPUT.TEXTAREA.PLACEHOLDER", defaultMessage:"Write your note here..." })}></textarea>        
</div> 
    </form>
  </div>
  )
}
