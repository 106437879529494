import React, {useEffect, useRef, useState} from 'react'
import { Checkbox } from 'sr/helpers'
import { Button } from 'sr/helpers/ui-components/company/Button'
import { ModelWrapperWithButton } from 'app/pages/company/component/ModelWrapperWithButton'
import  ChecklistForm  from './forms/ChecklistForm'
import TaskForm  from './forms/TaskForm'
import { AllChecklist } from '../ChecklistTemplate/AllChecklist'
import { Task } from './Tables/Task'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getTasks } from 'sr/utils/api/individual/tasklist/getTasks'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
export const ChecklistAdd = ({showModal, HandleChecklistModel, fetchTasklisk}) => {
    const [GlobalTemplate, setGlobalTemplate] = useState(false)
    const [model, setModel] = useState(false)
    const [copyChecklistid, setCopyChecklistId] = useState(-1)
    const [addMore, setAddMore] = useState(false)
    const [isChecklistAdded, setIsChecklistAdded] = useState(false)
    const [task, settask] = useState([])
    const [tasklistId, setTasklistId] = useState<string>()
    const [loader, setLoader] = useState(false)
    interface TabRef {
        submitForm: () => void;
      }
      const params = useParams()
      useEffect(()=> {
          const {id} = params
          setTasklistId(id)
      },[params])
    const childRef = useRef<TabRef>(null);
    const HandleFormSubmit = () => {
        setAddMore(false)
        if (childRef.current && childRef.current.submitForm) {
          childRef.current.submitForm();
        }
      };
    const ChecklistHandle = (index) => {
        setCopyChecklistId(index)
    }
    const HandleAddChecklist = () => {
        // setchecklistDetails({"checklistname":"my dailychecklist"})    
        if (childRef.current && childRef.current.submitForm) {
            childRef.current.submitForm();
          }
        // navigate(`${location.pathname}/${checklistId}`);
      };
      const handleAdded = (data)=> {
        if(data===true) {
          // fetchTasklisk()
        setIsChecklistAdded(true)
        }
        if(data==='Task'){
          fetchTask(tasklistId)
        } 

      }
      const fetchTask = async (tasklistId)=> {
        try {
        setLoader(true)
        const params = new URLSearchParams()
        params.append('checklist_id', tasklistId)
        params.append('status!', 'deleted')
            const res = await getTasks(params)
            if(res?.success===true){
              settask(res?.data)
              setLoader(false)
            }
            else {
              setLoader(false)
            }
          }
          catch(e:any) {
            setLoader(false)
          }
      }
      useEffect(()=> {
          if(tasklistId) {
            fetchTask(tasklistId)
          }
      },[tasklistId])

      useEffect(()=> {
console.log(task);

      },[task])
      const HandleModel = ()=> {
        HandleChecklistModel(false)
      }
  return (
   <ModelWrapperWithButton  showModal={showModal} onCheckModel={HandleChecklistModel} Save={HandleModel}>
    { !isChecklistAdded &&
    <>
    <ChecklistForm ref={childRef} handleAdded={handleAdded}/>
    <Button text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.SAVE_BUTTON" defaultMessage="Save" />} onClick={HandleAddChecklist}></Button>
    </>
    
    }
       { isChecklistAdded &&
       <>
       {/* <div className='mt-5'> 
       
    <Checkbox id="Global" label={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.GLOBAL.TITLE" defaultMessage="Import from global Checklist" />} onChange={()=> setGlobalTemplate(!GlobalTemplate)} value={GlobalTemplate}></Checkbox>
    </div> */}
    {GlobalTemplate ? null :
    <>
    <TaskForm  ref={childRef} dataAddedHandle={handleAdded} />
    <Button  text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.SAVE_BUTTON" defaultMessage="Save" />} onClick={HandleFormSubmit}></Button>
    </>
    }
    </>
     }
                    {GlobalTemplate ? copyChecklistid === -1 &&
                    <AllChecklist  ChecklistHandle={ChecklistHandle}/>
                    :null}
                  
                                            
        <>    
    
    <div className="overflow-x-auto mt-10 rounded-[20px] border border-neutral-200">
        {task?.length > 0 ?
        <ContentWithSpinner loader={loader}>
      <Task onClick={() => setModel(true)} task={task} handleAdded={handleAdded}/>
      </ContentWithSpinner>
        :null}
        </div>
         </>  
    {addMore && <TaskForm dataAddedHandle={handleAdded}/> }
    
              {copyChecklistid > -1 && addMore && <Button onClick={HandleFormSubmit} text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.SAVE.TASK.BUTTON" defaultMessage="Save Task" />}></Button> }
              
            {copyChecklistid > -1 && !addMore && <Button onClick={() => setAddMore(true)} text={<FormattedMessage id="INDIVIDUAL.MANAGE.TASKLIST.TASK.BUTTON" defaultMessage="Add More Task" />}></Button> }
        
            </ModelWrapperWithButton>  )
}
