import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const contractorHire = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/contractor/hiredetails',postData ).then((res)=> {
        if(res?.success===true) {
                return res
        }
        else {
            return null
        }
  })
}
catch(e){
    HandleApiError(e)
}
};
