import { useGet } from "app/pages/company/Hooks/Api/useGet";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { getTaskManage } from "sr/utils/api/company/workorder/getTaskManage";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { ReviewCard } from "./component/ReviewCard";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { manageTaskSubmit } from "sr/utils/api/company/workorder/manageTaskSubmit";
import { updateWorkorder } from "sr/utils/api/company/workorder/updateWorkorder";
import { Button, Input } from "sr/helpers";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";

export const ReviewWorkOrder = () => {
    const [workorderId, setWorkOrderId] = useState<any>()
    const {data:managetasks, getData:getManageTaskList, loader:taskmanageLoader} = useGet()
    const params  = useParams()
    const [workHours, setWorkHours] = useState(0)
    const {data:checklistData, loader:checklistLoader, getData:getChecklists} = useGet()
    const {getData:getWorkOrders, data:workorder, loader:loaderWorkOrder, pageination:pageination}:any = useGet()
    const {data:contractor, getData:getContractorDetails, loader:contractorLoader} = useGet()
    const [cleanerId, setCleanerId] = useState()
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const fetchWorkOrder = async (workorderId)=> {
        const queryParams = new URLSearchParams()
        if (workorderId) queryParams.append('id', workorderId)
          await getWorkOrders(() => getWorkorder(queryParams));
    }
    useEffect(()=> {
      const {user_id} = getUserDetails()
      setCleanerId(user_id)
  },[])
    useEffect(()=> {
        const {id} = params
          setWorkOrderId(id)
      },[params])
    const fetchManageTask = async ()=> {
      setLoader(true)
        const queryParams = new URLSearchParams()
        if (workorderId) queryParams?.append('workorder_id', workorderId)
        await getManageTaskList(()=> getTaskManage(queryParams))
      setLoader(false)
        return true;
      }
      useEffect(()=> {
if(workorderId) {
    fetchManageTask()
}
      },[workorderId])
      useEffect(()=> {
        const fetchChecklist = async (id)=> {
          const queryParams = new URLSearchParams()
          if (id) queryParams.append('checklist_id', id)
              await getChecklists(()=> getTask(queryParams))
    }
          if(workorder?.checklist_id?._id) {
            fetchChecklist(workorder?.checklist_id?._id)
          }
          setWorkHours(workorder?.time_for_work_completion)
      },[workorder?.checklist_id?._id])
      useEffect(()=> {
     
        if(workorderId) {
          fetchWorkOrder(workorderId)
        }
    },[workorderId])

    useEffect(()=> {
      const FetchContractor = async (cleanerId)=> {
        const useParams = new URLSearchParams()
        if (cleanerId) useParams?.append('cleaner_id', cleanerId)
      await getContractorDetails(()=> getContractor(useParams))
  }
  if (cleanerId) {
    FetchContractor(cleanerId)
  }
    },[cleanerId])
   const HandleTaskSubmit = async ()=> {
    setLoader(true)
    const res = await manageTaskSubmit({
      workorder_id:workorderId,
      contractor_status:'completed',
      supervisor_status:'pending',
      work_completion_time:workHours,
      status:"in progress"
    })
if(res?.success===true){
if(workorder?.workorder_status==='in progress'){
const payload = {
  id:workorder?.id,
  workorder_status:'completed',
  completion_date:Date.now(),
  approval_status:'pending'
}
const updateres = await updateWorkorder(payload) 
if(updateres?.success===true) {
  setLoader(false)
navigate(`/my-jobs?tab=completed&type=workorder`)
}
            }
}
   }
  
  return (
  <div>
   <AuthNavComponent isLoggedin={true}></AuthNavComponent>
   <ContentWithSpinner loader={checklistLoader} height="h-screen">
   <main>
    <div className="py-3 lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0">
    <div className='mb-5'>
    <h1 className='text-2xl font-satoshiBold'>
      <FormattedMessage 
      id="WORKORDER.REVIEW.WORK.ORDER"
      defaultMessage="Review Work Order"
      />
    </h1>
   </div>
   <div className="bg-white rounded-[20px] shadow-sm shadow-slate-300 px-10 py-3">
  {checklistData?.map((item)=> (
   <ReviewCard fetchManageTask = {fetchManageTask} item={item} managetasks={managetasks} workorderId={workorderId} contractor={contractor}/>
))}
<Input
                  label={
                    <FormattedMessage
                      id="MYJOB.INPUT.WORK.COMPLETION.TIME"
                      defaultMessage="Work Completion Time"
                    />
                  }
                  onChange={(e) => setWorkHours(e.target.value)}
                  type="number"
                  inputClass="rounded-lg"
                  labelClass="mt-2 text-black"
                  InputDivClass="mt-5 w-80  pr-2 pb-2 mx-auto"
                  placeholder="Number of hours"
                  value={workHours}
                />

<div className="flex justify-center my-3">
<Button onClick={()=> HandleTaskSubmit()} text={<FormattedMessage id="MYJOB.SUBMIT.BUTTON" defaultMessage="Submit" />} className={'bg-[#2557A7] text-white capitalize '} hoverbg={'bg-[#2557A7]'}></Button>
   </div>

    </div>
    </div>
   </main>
   </ContentWithSpinner>
    </div>);
};
