import React, {useState, ForwardRefRenderFunction, forwardRef, useEffect} from 'react'
import { Input } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'

interface data {
  company_name: string;
  password: string;
}

const CompanyNameChange: ForwardRefRenderFunction<any, any> = (props, ref) => {
  const [companyName, setcompanyName] = useState()
  const [currentPassword, setCurrentPassword] = useState()
  const [errors, setErrors] = useState<any>({});
  const CompanyNameChange = Yup.object({
    company_name:Yup.string().required('Please enter company name'),
    password:Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters')
  });

    useEffect(()=> {
    props?.HandleData({
      company_name: companyName,
      password: currentPassword
    })
    if(Object.keys(errors).length !== 0 ){
      validateData();
    }
  },[companyName, currentPassword]);

  const validateData = async () =>  {
    const data:data = {
      company_name:companyName,
      password:currentPassword,
    }
    let isValid: boolean;
    try {
      await CompanyNameChange.validate(data, { abortEarly: false });
      setErrors({}); // Reset errors if validation is successful
      props?.HandleData({
        company_name:companyName,
        password:currentPassword
      })
      isValid = true;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      isValid = false;
    }
    return isValid;
  };

  React.useImperativeHandle(ref, () => ({
    validateData
  }));

  return (
    <>
    <Input  onChange={(e)=> setcompanyName(e.target.value)} value={companyName} error={errors?.company_name} InputDivClass="mt-0" inputClass = "border border-zinc-800 rounded-lg h-11" label={<FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.INPUT" defaultMessage="New company name" />}></Input>
    <Input error={errors?.password} onChange={(e:any)=> setCurrentPassword(e.target.value)}
value={currentPassword} type='password' InputDivClass="mt-5" inputClass = "border border-zinc-800 rounded-lg h-11" label={<FormattedMessage
    id = 'RECRUITER.ACCOUNT.SETTINGS.INPUT.CURRENTPASS'
    defaultMessage = 'Current password'/>} ></Input>
    </>
  )
}

export default forwardRef(CompanyNameChange);