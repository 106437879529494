import React, { useEffect, useState } from "react";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import Footer from "app/pages/module/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle, faCircleCheck, faStar } from '@fortawesome/free-solid-svg-icons'
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { ChecklistTask } from "app/pages/company/Customer/ChecklistTemplate/ChecklistTemplatedata";
import { FormattedMessage } from "react-intl";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { toAbsoluteUrl } from "sr/helpers";
import { HTMLStringRender } from "sr/helpers/ui-components/HTMLStringRender";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { updateWorkorder } from "sr/utils/api/company/workorder/updateWorkorder";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
export const PendingJobs = () => {
    const [cleanerId, setCleanerId] = useState('')
    const [currentOrder, setCurrentOrder] = useState(null)
    const [status, setStatus] = useState<any>()
    const [buttonLoader, setButtonLoader] = useState(false)
    const {getData:getWorkOrders, data:workorders, loader:loader, pageination:pageination}:any = useGet()
    const {data:checklistData, loader:checklistLoader, getData:getChecklists} = useGet()
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setCleanerId(user_id)
    },[])
    useEffect(()=> {
        const fetchChecklist = async (id)=> {
            const queryParams = new URLSearchParams()
            if (id) queryParams.append('checklist_id', id)
                await getChecklists(()=> getTask(queryParams))
} 
if(currentOrder?.checklist_id) {
    fetchChecklist(currentOrder?.checklist_id?._id)
}
},[currentOrder?.checklist_id])

    useEffect(()=> {
        setCurrentOrder(workorders && workorders[0])
},[workorders])

    const fetchWorkOrder = async (cleaner_id)=> {
        const queryParams = new URLSearchParams()
        queryParams.append('status','assigned pending')
        if (cleaner_id) queryParams.append('cleaner_id', cleaner_id)
          let obj = {};
          let filter = {}
          if (Object.keys(obj).length>0) {
           filter['$and'] = [obj]
          }
          queryParams?.append('filter', JSON.stringify(filter))
          await getWorkOrders(() => getWorkorder(queryParams));
    }
    useEffect(()=> {
        if(cleanerId) {
          fetchWorkOrder(cleanerId)
        }
    },[cleanerId])
    const stepperIndex = 2;

    const HandleCurrentOrder = (id)=> {
        setCurrentOrder(workorders?.find((item)=>item?.id===id))
        setStatus('')
}

useEffect(()=> {
    const data = {
        id:currentOrder?.id,
        status:status
    }
const updateWorkorderstatus = async ()=> {
    setButtonLoader(true)
            const res = await updateWorkorder(data)
            if(res?.success===true) {
fetchWorkOrder(cleanerId)
setButtonLoader(false)
            }
            else {
                    setButtonLoader(false)
            }
}
if(status) {
updateWorkorderstatus()
}
},[status])

  return (
<>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
{buttonLoader && <LoaderFullScreen />}
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className='card-1 p-6 bg-white border border-gray-border rounded-xl'>
                        <div className='statistics flex items-center'>
                            <div className='font-bold  text-5xl mr-3'>
                             {workorders?.length || 0}
                            </div>
                            <div>
                                <span> <FormattedMessage id="PENDING.JOBS.TOTAL.TITLE" defaultMessage="Total Pending Jobs" />  </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ContentWithSpinner loader={loader}>
                    {workorders?.length> 0 ?
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                <div className='lg:flex block bg-white border border-gray-border rounded-xl'>

                    <div className='flex flex-col lg:w-2/5 w-full left-panel border-r'>
                        <div className='card-1 p-6 mb-5'>
         {   workorders?.map((data, index)=> (
                            <div key={index} onClick={()=> HandleCurrentOrder(data?.id)} className={`${currentOrder?.id===data?.id && 'bg-[#ecf5ff] hover:bg-[#ecf5ff]'} cursor-pointer border-neutral-200 border-b p-4 hover:bg-gray-50`}>
                                <h1 className='text-base font-bold  mb-1'>{data?.customer_id?.name}</h1>
                                <h3 className='text-sm font-bold  mb-3'>{data?.company_id?.company_name}</h3>
                            </div>
                            ))}
                        </div>
                    </div>

                    <div className='flex flex-col w-2/3 h-fit p-6 right-panel'>
                        <div className='header'>
                            <div className="flex justify-between items-center">
                                <div>
                            <h1 className='font-bold  text-xl'>{currentOrder?.customer_id?.name}</h1>
                            <div className='flex items-center mb-7'>
                                <span className='text-base text-gray-500 font-semibold  mr-1'>{currentOrder?.company_id?.company_name}</span>
                                {/* <span className=''>
                                    <FontAwesomeIcon icon={faStar} className='text-yellow-500 text-sm px-1' />
                                    <span className='text-sm text-gray-700'>3.5</span>
                                </span> */}
                                {/* <div className='border border-r m-2 border-gray-300 h-3'></div>
                                <span className='text-sm text-gray-700'>59  <FormattedMessage id="CLEANER.JOBS.REVIEWS" defaultMessage="Reviews" /> </span> */}
                            </div></div>
                            <div>
                                <DropDown onChange={(e:any)=> setStatus(e.target.value)} value={status} dropdowndiv={'mt-0'} className={'rounded-lg md:px-6'}>
                                    <option> <FormattedMessage id="PENDING.JOBS.FILTER.DROPDOWN.TYPE.ACTION" defaultMessage="Action" />  </option>
                                    <option value={'assigned'}><FormattedMessage id="PENDING.JOBS.FILTER.DROPDOWN.TYPE.ACCEPT" defaultMessage="Accept" /></option>
                                    <option value={'assigned rejected'}><FormattedMessage id="PENDING.JOBS.FILTER.DROPDOWN.TYPE.REJECT" defaultMessage="Reject" /></option>
                                </DropDown>
                            </div>
                            </div>
                            <div className='border-b'></div>

                            <div className='job-description mt-6'>
                                <h1 className='font-bold  text-base'><FormattedMessage id="PENDING.JOBS.JOB.DESCRIPTION.TITLE" defaultMessage="Job description" /></h1>
                             
                                <div><HTMLStringRender htmlContent={currentOrder?.description}></HTMLStringRender></div>
                            </div>
                            <div>
                                <div className="mt-6">
            <span className='font-bold  text-sm'> <FormattedMessage id="PENDING.JOBS.JOB.LOCATION.TITLE" defaultMessage="Location" />  :</span>
            <span className='text-sm text-gray-700 pl-1'>{currentOrder?.customer_location_id?.address?.address_line_1} {currentOrder?.customer_location_id?.address?.address_line_2} {currentOrder?.customer_location_id?.address?.city}</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.ENTRYTIME" defaultMessage="Entry Time" />  :</span>
                                    <span className='text-sm text-gray-700 pl-1'>{currentOrder?.entry_time}</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.EXITTIME" defaultMessage="Exit Time" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{currentOrder?.exit_time}</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage id="PENDING.JOBS.TEXT.TITLE.PAYTYPE" defaultMessage="GENERAL.TEXT.TITLE.PAYTYPE" />:</span>
                                <span className='text-sm text-gray-700 pl-1'>{currentOrder?.pay_type} (${currentOrder?.pay_type_rate})</span>
                                </div></div>
                            <div className='mt-6 mb-6 border border-neutral-200 rounded-[20px]'>
                                <table className="w-full text-left condidate-table">
    <thead className="border-collapse border-b border-neutral-200 capitalize">
        <tr>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="PENDING.JOBS.TABLE.COL.NAME.TASK" defaultMessage="Task" />  
            </th>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="PENDING.JOBS.TABLE.COL.NAME.DESCRIPTION" defaultMessage="Description" /> 
            </th>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="PENDING.JOBS.TEXT.TYPE" defaultMessage="Type" /> 
            </th>
        </tr></thead>
        <tbody>
        {checklistData?.map((data, index)=> (
                           <tr className="bg-white border-b  hover:bg-gray-50">
                           <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                          
                               <div className='text-base'>{data?.name}</div>
                               
                           </th>
                           <td className="px-6 py-4">
                               {data?.description}
                           </td>
                         
                   
                           <td className="px-6 py-4">
                           {data?.type}
                           </td>
                          
                       </tr>
                  ))}
        </tbody>
        </table></div>
      

                            </div>
                        
                    </div>
                </div>
                
                </div>
                :
                <div className='lg:container lg:mt-10 mt-10 px-4 lg:px-0 rounded-xl lg:max-w-6xl block bg-white border border-gray-border mx-auto'>
<div className='lg:container text-center mx-auto items-center justify-center lg:max-w-xl py-10'>
<div className='flex justify-center'>
    <img className='w-20' src={toAbsoluteUrl('/media/img/emtydisplay.png')}></img>
</div>
<div className='text-2xl font-bold'> You have no any pending jobs</div>
    </div> </div>
}
                </ContentWithSpinner>
            </main>
           <Footer />
  
           </>);
};
