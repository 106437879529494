import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getSavedJobs = async (cleaner_id:any) => {
  try {
return await get<ApiResponse<[]>>(`/cleaner/favoritejobs?cleaner_id=${cleaner_id}`).then((res)=> {
if(res.success===true) {
    return res
}
else {
    return null
}
})
  }
  catch(e:any) {
    HandleApiError(e)
  }
};
