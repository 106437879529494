import React, { useEffect, useState } from "react";
import { TableRow } from "./history/TableRow";
import { getTransaction } from "sr/utils/api/wallet/getTransaction";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { Pagination } from "app/pages/component/Pagination";
import { HistoryDetails } from "../HistoryDetails";
import { TransactionDateFilter } from "app/pages/component/TransactionDateFilter";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { FormattedMessage } from "react-intl";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { Filter } from "../../work-order/component/Filter";
import { Checkbox } from "sr/helpers/ui-components/CheckBox";
import CheckBoxHandle from "sr/helpers/functions/CheckBoxHandle";
import convertToISOFormat from "sr/helpers/functions/convertToISOFormat";

export const History = () => {
    const [companyId, setCompanyId] = useState<string>('')
    const [data, setData] = useState(null)
    const [details, setDetails] = useState<string>()
    const [loader, setLoader] = useState(true)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalPage, settotalPage] = useState<number>(1);
    const [contractors, setContractors]= useState([])
    const [contractor, setContractor] = useState("");
    const [status, setStatus] = useState(['credited', 'debited'])
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [dataAdded, setdataAdded] = useState()
    useEffect(()=> {
            const {user_id} = getUserDetails()
            setCompanyId(user_id)
    },[])
    const fetchTransaction = async ()=> {
        setLoader(true)
        const queryParams = new URLSearchParams()
        if (page) queryParams.append('page', page.toString());
        if (limit) queryParams.append('limit', limit.toString());   
        const filterarr = []
        if (status?.includes('credited')){
            filterarr?.push({"to":companyId})   
        } 
        if (status?.includes('debited')){
            filterarr?.push({"from":companyId})
        }
        if(contractor){
            filterarr.push({'contractor_id':contractor})
        } 
        if (from && to) queryParams?.append('createdAt>', convertToISOFormat(from))  
            if (from && to) queryParams?.append('createdAt<', convertToISOFormat(to))  
        const filter = {"$or":filterarr}
        queryParams.append('filter', JSON.stringify(filter))
                const res = await getTransaction(queryParams)
                if(res?.success===true) {
                    setData(res?.data)
                    settotalPage(Math.ceil(res?.pagination.total / limit));
                    setLoader(false)
                }
                else {
                    setLoader(false)
                }
    }
    useEffect(()=> {
            if(companyId) {
loadContractor()
            }
    },[companyId])
    useEffect(()=>{
        if(companyId){
        fetchTransaction()
        }
    },[companyId, page, contractor, status])
    const loadContractor = async ()=> {
        const queryParams = new URLSearchParams()
        queryParams.append('company_id', companyId)
        queryParams.append('status!', 'deleted')
        queryParams.append('limit', '100')
        try {
      const res = await getContractor(queryParams)
      if(res?.success===true) {
        setContractors(res?.data)
      }
        }
        catch(e) {
          
        }
      } 
      useEffect(()=>{
        if (!companyId) return
            if(from && to){
                fetchTransaction()
            }
      },[from, to])
      useEffect(()=>{
          if(dataAdded){
            fetchTransaction()
          }
      },[dataAdded])
  return (
    <ContentWithSpinner loader={loader} >
        {details ?
       <HistoryDetails setDetails={setDetails} setdataAdded ={setdataAdded} details={details}/>
       : 
    <>
    <div className="flex space-x-3 justify-normal mb-5 items-center">
    <TransactionDateFilter setFrom={setFrom} setTo={setTo}></TransactionDateFilter>
       <DropDown
                             labelClass="text-base"
                             value={contractor}
                             label={
                               <FormattedMessage
                                 id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.TITLE"
                                 defaultMessage="Contractor"
                               />
                             }
                             dropdowndiv="mt-1 w-26 pr-2 pb-2"
                             onChange={(e) => setContractor(e.target.value)}
                             className="rounded-lg"
                           >
                             <option value="">
                               <FormattedMessage
                                 id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.SELECT"
                                 defaultMessage="Select Contractor"
                               />
                             </option>
                             {contractors?.map((item, index) => (
                               <option key={index} value={item.id}>
                                 {item?.first_name} {item?.last_name}
                               </option>
                             ))}
                           </DropDown>
       <Filter name={<FormattedMessage id="MYJOB.FILTER.DROPDOWN.STATUS" defaultMessage='Status' /> } label={<FormattedMessage id="MYJOB.FILTER.DROPDOWN.STATUS" defaultMessage='Status' /> }>
      <Checkbox id='credited' name='credited' onChange={(e)=> CheckBoxHandle(e, status, setStatus)} checked={status?.includes('credited')} value='credited' label={'Credited'}></Checkbox>
      <Checkbox id='debited' name='debited' onChange={(e)=> CheckBoxHandle(e, status, setStatus)} checked={status?.includes('debited')} value='debited' label={'Debited'}></Checkbox>

   </Filter>
    </div>
    <div className="overflow-x-auto mt-4 rounded-[20px] border border-neutral-200">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
            <th scope="col" className="px-6 py-4 text-base ">
  Transaction Type
</th>  
            <th scope="col" className="px-6 py-4 text-base ">
 Activity
</th>
<th scope="col" className="px-6 py-4 text-base ">
 Amount
</th>
<th scope="col" className="px-6 py-4 text-base ">
  Date
</th>

            </tr>
        </thead>
        <tbody>
            {data?.map((item)=> (

        <TableRow setDetails={setDetails} item={item}/>
  )) }
          
        </tbody>
    </table>  
       <Pagination page={page} totalPage={totalPage} setPage={setPage} />
    
    </div>
    </>
}
   </ContentWithSpinner>
);
};
