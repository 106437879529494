import React, {useState, useEffect} from 'react'
import { SliderContainer } from '../Slider/SliderContainer'
import { data } from '../JobsData'
import JobItemCard from 'app/pages/jobs/component/JobItemCard'
import { JobsTabSidebar } from './Sidebar/JobsTabSidebar'
import { Model } from './Model'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs'
import Jobs from 'app/pages/component/types/Job'
import CompanyType from '../type/CompanyType'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { businessTypes, usaCities } from 'sr/i18n/messages/setting'
import { employment } from 'app/pages/company/jobpost/component/Jsonalldata'
import { getJobTypeCategory } from 'sr/utils/api/getJobTypeCategory'
interface JobsProps {
  data:CompanyType,
  loader:boolean,
  jobs:Jobs[]
  }
export const JobsTab:React.FC<JobsProps> = ({data, loader}) => {
  const [activemodel, setActiveModel] = useState('')
  const [id, setId] = useState(null)
  const [filterData, setFilterdata] = useState<any>({})
  const [jobs, setJobs] = useState([])
  const [fieldName, setFieldName] = useState('')
const params = useParams()
  useEffect(()=> {
    const {id} = params
       setId(id)
},[params])
    const [jobTypes, setJobTypes] = useState([])
            useEffect(()=>{
              const fetchJobType = async ()=>{
        try {
        const res = await getJobTypeCategory()
        if(res?.success===true){
          setJobTypes(res?.data)
        }
        }
        catch(e:any){
        
        }
              }
              fetchJobType()
            },[])


  const GetData = ()=> {
    if (activemodel==='Location') {
      return usaCities
    }
    else if (activemodel==='Bussiness Type') {
      return businessTypes
    }
    else if (activemodel==='Job Type'){
      return jobTypes
    }
    else if (activemodel==='Employment Type'){
      return employment
    }
  }
  const filters = [
    {label:'Location', fieldName:'location'}, {label:'Bussiness Type', fieldName:'business_type'}, {label:'Job Type', fieldName:'job_type'}, {label:'Employment Type', fieldName:'employment_type'}
  ]
  const fetchJobs = async ()=> {
    const queryParams = new URLSearchParams()
    if (id) queryParams.append('company_id', id)
    if (filterData?.business_type) {
      let businessType = filterData.business_type;
      if (businessType?.length ===1 ) {
        businessType += ',';
      }

      if (businessType?.length>0) queryParams.append('business_type', businessType);
    }
    let filter = [];
    Object.keys(filterData)?.forEach(item => {
      if(item==='business_type') {
          delete filterData[item]; 
      }
      if (item && filterData[item] && (filterData[item]?.length > 0 || Object.keys(filterData[item]).length > 0)) {
        const fill = {[item]: {"$in": filterData[item]}}
        filter?.push(fill)
      }  })
      let newfilter;
      if(filter?.length>0) {
          if(filter?.length===1)  {
       newfilter = filter[0]
          }
          else {
              newfilter = {"$and":filter}
          }
      }
      if (newfilter) queryParams.append('filter', JSON.stringify(newfilter))
        try {
          const result: any= await getJobs(queryParams);
          if(result?.success===true){
          setJobs(result?.data);
          setActiveModel('')
          // settotalPage(Math.ceil(result?.pagination.total/limit))
          // setLoader(false)
          
          }
      }
      catch(error) {
          console.log(error);
          // setLoader(false)
      }
      }
      useEffect(()=> {
            if(id) {
              fetchJobs()
            }
      },[id])
    
  return (
    <ContentWithSpinner loader={loader} height='h-52'>
    <div className='my-5'>
    {/* <SliderContainer data={data} text="Departments hiring at Digit Insurance" bg={'bg-gradient-to-b from-[#fff] to-[#fff3dd]'}/> */}
    </div>
    <div className='grid lg:grid-cols-3 grid-cols-1 gap-4 pt-4'>
    <div className='col-span-2'>
    <p className="font-bold font-base">{jobs?.length} <FormattedMessage id='COMPANIES.JOBS.JOB.OPENING.TITLE' defaultMessage='Job openings at' /> Digit Insurance</p>
    <div className="relative flex my-2 gap-2 flex-wrap">
    <span onClick={()=> setActiveModel('')
    } className={`${activemodel ? 'block bg-[#00000099] opacity-100' :'hidden'} cursor-pointer fixed top-0 left-0 w-full h-full z-50`}></span>
      {filters.map((data) => (
            <div onClick={()=>{ setActiveModel(data?.label);
              setFieldName(data?.fieldName)}
            } className="flex cursor-pointer items-center px-2 inline-block w-auto text-base text-gray-600 border border-gray-300 py-1 px-2 rounded-3xl">{data?.label} <svg onClick={()=>{ setActiveModel(data?.label);
              setFieldName(data?.fieldName)}
            } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3.5 h-3.5 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
          </div>
          
      ))
  }
  {activemodel &&
  <Model fetchJobs={fetchJobs} showModal = {activemodel} filterData={filterData} fieldName={fieldName} data={GetData()} setFilterdata={setFilterdata}/>
  
}
  </div>
    {
                    jobs.map((datI) => {
                        return <JobItemCard jobs={datI} link=''></JobItemCard>
                    })
                }
      </div>
      <JobsTabSidebar />
      </div>
  </ContentWithSpinner>
  )
}
