import React, { useEffect, useState } from "react";
import { MainWrapper } from "../component/MainWrapper";
import { Card, toAbsoluteUrl } from "sr/helpers";
import { MasterCard } from "./component/CardIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getFiles } from "sr/utils/api/getFiles";
import { getFile } from "sr/utils/api/getFile";
import { getCleaner } from "sr/utils/api/cleaner/getCleaner";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { getJob } from "sr/utils/api/individual/job/getJob";
import Job from "app/pages/component/types/individual/job";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { getJobType } from "sr/utils/api/cleaner/getJobType";
import getStripCustomerId from "sr/helpers/apiFunctions/strip/getStripCustomerId";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCardIcon } from "sr/helpers/functions/getCardIcon";
import { io } from "socket.io-client";
import { getIndividual } from "sr/utils/api/individual/getIndividual";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
export const Confirm = () => {
    const [profileImg, setProfileImg] = useState<string>() 
    const [cleanerData, setCleanerData] = useState<any>()
    const [jobId, setJobId] = useState<string>()
    const [loader, setLoader] = useState<string>('')
    const [data, setData] = useState<Job>()
    const [rate, setRate] = useState()
    const [cardDetails, setCardDetails] = useState<any>([])
    const [isProcessing, setIsProcessing] = useState(false);
    const [stripId, setStripId] = useState()
    const [userId, setUserid] = useState()
      const {loader:userloader, getData:fetchData, data:userData} = useGet()
    const navigate = useNavigate()

    useEffect(()=> {
      const {user_id} = getUserDetails()
      setUserid(user_id)
      },[])
       useEffect(()=> {
          const fetchDetails = async (userId) => {
              const queryParams = new URLSearchParams(); 
              if (userId) queryParams.append('id', userId); 
              await fetchData(()=> getIndividual(queryParams))
            }
            if(userId) {
              fetchDetails(userId)
            }
        },[userId])
    const params = useParams()
    useEffect(()=> {
        const {id} = params
        setJobId(id)
    },[params])
    const fetchJobData = async ()=> {
      const queryParams = new URLSearchParams()
      queryParams.append('id', jobId)
      setLoader('JobData')
        try {
            const res = await getJob(queryParams)
            if(res?.success===true) {
              setData(res?.data)
              setLoader('')
            }
            else {
              setLoader('')
            }
        }
        catch(e) {
            setLoader('')
        }
    }
    useEffect(()=> {
if(jobId) {
  fetchJobData()
}
    },[jobId])
    const fetchProfileImg = async (cleanerId)=> {
      setLoader('profileimg')
      const params = {
        cleaner_id:cleanerId
      }
  const profileImg = await getFiles(params)
  if(profileImg?.success===true) {
    const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
    if(filterfile && filterfile[0]?.file?.key) {
      const fileimg = await getFile(filterfile[0]?.file?.key);
      if(fileimg) {
      const newImageUrl = URL.createObjectURL(fileimg);
      setProfileImg(newImageUrl)
      setLoader('')
      }
      
    }}
    }

    useEffect(()=> {
        if(data?.cleaner_id?._id){
        fetchProfileImg(data?.cleaner_id?._id)
        fetchJobType()
        getStripCustomerId({setCardDetails:setCardDetails, setIsProcessing:setIsProcessing, setStripId:setStripId, customerId:userId})
        }
      },[data?.cleaner_id?._id])
      const fetchJobType = async ()=> {
        try {
        const jobtyperes = await getJobType(data?.cleaner_id?._id, undefined)
        if(jobtyperes?.success===true) {
          jobtyperes?.data?.map(item => {
            item?.job_type?.map((item1: string)=>{
              if(item1===data?.title) {
                setRate(item?.rate)
                
              }}
            )})
            }
          }
            catch(e) {
              
            }
      }
      const socket = io(process.env.REACT_APP_SOCKET); // Connect to the server
      const HandleMessage = ()=> {
        socket.on('connect', () => {
          // Register the user (replace 'userId123' with the actual user ID)
          socket.emit('register', userId);
        });
        const payload = {
          message: `${userData?.first_name} ${userData?.last_name} wants Hire you`,
          recipient_id: data?.cleaner_id?._id,
          sender_id:userId,
          individual_job_id:data?.id,
          type:'job_offered',
          sender_id_type:'individual',
          recipient_id_type:'cleaner'
          // Ensure this resolves to the correct recipient user ID
      };
      console.log(payload);
      
      socket.emit('sendmessage', payload); // Match the event name in the backend
        navigate(`/individual/confirm-and-chat/${jobId}`)
      }

    const topHeader = (
        <div className="bg-white flex justify-center w-full space-x-2 border border-neutral-200 py-3">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
  </svg>
  <p><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.TOP.TEXT" defaultMessage="You're almost done. We just need a few more details to connect you with your" />
<br /> <FormattedMessage id="INDIVIDUAL.REVIEW_TASK.TOP.TEXT.TASKER" defaultMessage="Tasker" />.</p>
        </div>
      )
  return (
    <MainWrapper topHeader={topHeader}>
      <ContentWithSpinner height="h-screen" loader={loader==='JobData'}>
  <div className="py-6 lg:px-14 mx-auto max-w-[60rem]">
        <div className="lg:grid lg:grid-cols-12 lg:gap-5">
        <div className="col-span-7">
            <div className="bg-white border border-neutral-300 rounded-lg px-3 lg:mb-0 h-fit pb-3">
        <p className="text-base font-satoshiBold py-3"><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.TITLE" defaultMessage="Review your task description" /></p>
        <div className="border border-neutral-500 rounded-lg p-2 h-28">
            <p>{data?.description}</p>
        </div>
        <div>
        <p className="text-base font-satoshiBold py-3"><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.PAYMENT_METHOD.TITLE" defaultMessage="Payment method" /></p>
        <div><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.PAYMENT_METHOD.DESCRIPTION" defaultMessage="You may see a temporary hold on your payment method in the amount of your Tasker's hourly rate. Don't worry - you'r only billed when your task is complete." />
</div>
        </div>
        
    <div className="flex items-center space-x-3 py-3 pl-3">
            {getCardIcon(cardDetails?.length && cardDetails[0]?.card?.brand)}
            <span>**** **** **** {cardDetails?.length && cardDetails[0]?.card?.last4}</span>
        </div>
        {cardDetails?.length>0 &&
        <p className="text-base font-satoshiBold text-[#2557A7] cursor-pointer mb-5"><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.CHANGE_PAYMENT" defaultMessage="Change payment" /></p>
}
        {/* <p className="text-base font-satoshiBold text-[#2557A7] cursor-pointer mb-5"><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.PROMO" defaultMessage="Do you have a promo code?" /></p> */}
        <div className="border border-neutral-300 w-full"></div>
       <img className="my-6" src={toAbsoluteUrl('/media/logos/logo2.png')} alt="" />
       <div className="text-xs"><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.BOTTOM.TEXT" defaultMessage="Join us in helping our neighbors in need find work and a place to call home." /></div>
       <Link className="mt-2 text-sm font-bold font-satoshiBold text-[#2557A7]  cursor-pointer underline" to="/individual/cleaner-profiles"><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.LEARN_MORE.BUTTON" defaultMessage="Learn More" /></Link>
            </div>
            <div className="flex justify-center mt-3 mb-3">
            <Link onClick={HandleMessage} to={`/individual/confirm-and-chat/${data?.id}`} className="text-center rounded-full font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy" ><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.CONFIRM.BUTTON" defaultMessage="Confirm and Chat" /></Link>
           </div></div>
            <div className="col-span-5">
               <div className="bg-white border border-neutral-300 rounded-lg mb-3 lg:mb-3">
                <div className="bg-[#F3F2F1]">
               <p className="text-base font-satoshiBold text-center mb-2">{data?.title}</p>
                <div className="flex justify-center mb-2">
                <img
            alt="Profile"
            className="rounded-full w-16 h-16"
            src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}
          /></div>
          <div className="text-sm text-center w-full">{data?.cleaner_id?.first_name} {data?.cleaner_id?.last_name}</div>
                </div>
                <ul className="px-3 py-3">
                
                <li  className="mb-3">
                    <div className="flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
  </svg>
<div>{DateFormat(data?.task_date)} at {data?.start_time}</div>
                    </div>
                </li>
                <li  className="mb-3">
                    <div className="flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
  </svg>
<div>{data?.streetAddress} {data?.unitorapt}</div>
                    </div>
                </li>
           
                </ul>
                    <div className="flex justify-start px-3">
                <div className="cursor-pointer font-satoshiBold border border-[#2557A7] text-[#2557A7] py-2 px-3 rounded-full my-3"><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.EDITTASK.BUTTON" defaultMessage="Edit Task" /></div>
                </div>
               </div>
               <div className="bg-white border border-neutral-300 rounded-lg mb-3 lg:mb-0 px-3 pb-3">
                <div className="flex justify-between w-full font-satoshiBold text-base my-5">
                <div><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.HOURLYRATE.TITLE" defaultMessage="Hourly Rate" /></div>
                <div>${rate}/hr</div>
                </div>
                <div className="flex flex-col space-y-6">
                <p>A $6.80 Trust and Support fee will be added to the final price, as well as any applicable sales tax.</p>
                <p>You will not be billed until your task is complete. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae deserunt doloremque perspiciatis ratione libero quia nemo necessitatibus aut expedita consectetur vitae accusantium praesentium reprehenderit corrupti, nostrum ex enim cupiditate dolorem.</p>
               <div><Link className="text-[#2557A7] mr-2 font-satoshiBold" to={'/'}><FormattedMessage id="INDIVIDUAL.REVIEW_TASK.LEARN_MORE.BUTTON" defaultMessage="Learn More" /></Link>about our cancellation policy</div>
                </div>
               </div>
            </div>
            </div>
            
            </div>
            </ContentWithSpinner>
  </MainWrapper>
  
  );
};
