import React from "react";

export const TaskTableHeader = () => {
  return (
    <div className="flex justify-between items-center py-3 border-b border-neutral-200 px-10">
    <div className="w-[30%] font-satoshiBold flex justify-center">Task Details</div>
    <div className="w-[11.5%] font-satoshiBold flex justify-center">Contractor Remark</div>
    <div className="w-[11.5%] font-satoshiBold flex justify-center">Contractor Status</div>
    <div className="w-[11.5%] font-satoshiBold flex justify-center">Remark</div>
    <div className="w-[11.5%] font-satoshiBold flex justify-center">Images</div>
    <div className="w-[11.5%] font-satoshiBold flex justify-center">Videos</div>
    <div className="w-[11.5%] font-satoshiBold flex justify-center">Make Action</div>
  </div>);
};
