import React, {useState, useEffect, useRef} from 'react'
import CompanyNameChange from './models/CompanyNameChange';
import { ModelWrapperWithButton } from '../../component/ModelWrapperWithButton';
import { FormattedMessage } from 'react-intl';
import { getUserDetails } from 'sr/helpers/functions/getUserDetails';
import { getCompany } from 'sr/utils/api/company/getCompany';
import { businessTypes, intentType } from 'sr/i18n/messages/setting';
import { toast } from 'react-toastify';
import { updateCompany } from 'sr/utils/api/company/updateCompany';
import ChangeBusinessType from './models/ChangeBusinessType';
import ChangeIntent from './models/ChangeIntent';
import ContentWithLoader from 'sr/layout/common/ContentLoader';
import ContentLoader from 'react-content-loader';
export const CompanySettings = () => {
    const [showModal, setShowModal] = useState(false)
    const [activeComponent, setActiveComponent] = useState<any>({});
    const [companyId, setCompanyId] = useState()
    const [data, setData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [loader, setLoader] = useState(true)
    const childRef = useRef(null);

    const HandleModel = (data:any) => {
        setShowModal(data)
}

const HandleData = (data:any) => {
    console.log(data);
    setFormData(data)
}

useEffect(()=> {
    const getdetails = getUserDetails()
    setCompanyId(getdetails.user_id)
  },[])

  const fetchData = async () => {
    setLoader(true)
    const queryParams = new URLSearchParams(); 
      if (companyId) queryParams.append('id', companyId); 
    const result = await getCompany(queryParams)
    if(result?.success === true) {
        setData(result?.data)
        console.log(result?.data);
        setLoader(false)
    }
    else {
        setLoader(false)
    }
}
  useEffect(()=> {
    if(companyId) {
    fetchData()
    }
  },[companyId])
  const HandleChange = async () => {
    let isValidData: boolean;
    if (childRef.current && childRef.current.validateData) {
      isValidData = await childRef.current.validateData();
    }
    if(isValidData) {
      const result = await updateCompany({id:companyId, ...formData})
      if(result?.success=== true) {
          fetchData()
          setShowModal(false)
      }
    }
  }
const AllModels = [
    {
      label: 'CompanyNameChange',
      componentName: CompanyNameChange,
      headertitle:<FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.CHANGE.TEXT" defaultMessage="Change your company name" />
    },
    {
        label: 'ChangeBusinessType',
        componentName: ChangeBusinessType,
        headertitle:<FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.POPUP.CHANGE.BUSINESS.TYPE" defaultMessage="Change Your Business Type" />
      },
      {
        label:'ChangeIntent',
        componentName:ChangeIntent,
        headertitle:<FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.CHANGE.INTENT.POPUP.TITLE" defaultMessage="Change Your Intent" />
      }
    ]

const openModel = (index: any) => {  
    const activeComp = AllModels.filter(function (e, i) {
      return i == index
    });      
    console.log(activeComp[0])
    setActiveComponent(activeComp[0]);

    setShowModal(true)
  };
  return (
    <ContentWithLoader loading={loader} content={<ContentLoader 
        speed={2}
        width={900}
        height={500}
        viewBox="0 0 900 500"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="-590" y="17" rx="3" ry="3" width="88" height="6" /> 
        <rect x="-161" y="4" rx="3" ry="3" width="52" height="9" /> 
        <rect x="-607" y="61" rx="3" ry="3" width="410" height="9" /> 
        <rect x="-821" y="118" rx="3" ry="3" width="380" height="9" /> 
        <rect x="-647" y="90" rx="3" ry="3" width="178" height="6" /> 
        <circle cx="-219" cy="13" r="20" /> 
        <rect x="-564" y="-18" rx="3" ry="3" width="88" height="6" /> 
        <rect x="-266" y="202" rx="3" ry="3" width="131" height="22" /> 
        <rect x="-580" y="-34" rx="0" ry="0" width="4" height="40" /> 
        <rect x="-524" y="22" rx="0" ry="0" width="157" height="3" /> 
        <rect x="-460" y="73" rx="0" ry="0" width="15" height="46" /> 
        <rect x="-541" y="79" rx="0" ry="0" width="161" height="44" /> 
        <rect x="-530" y="40" rx="0" ry="0" width="165" height="55" /> 
        <rect x="-488" y="92" rx="0" ry="0" width="200" height="63" /> 
        <rect x="554" y="46" rx="0" ry="0" width="40" height="12" /> 
        <rect x="260" y="70" rx="0" ry="0" width="2" height="1" /> 
        <rect x="-715" y="125" rx="0" ry="0" width="557" height="60" /> 
        <rect x="-531" y="97" rx="0" ry="0" width="476" height="30" /> 
        <rect x="-594" y="182" rx="0" ry="0" width="373" height="128" /> 
        <rect x="311" y="153" rx="0" ry="0" width="0" height="21" /> 
        <rect x="-563" y="123" rx="0" ry="0" width="84" height="2" /> 
        <rect x="-637" y="124" rx="0" ry="0" width="172" height="4" /> 
        <rect x="-566" y="133" rx="0" ry="0" width="141" height="12" /> 
        <rect x="-571" y="120" rx="0" ry="0" width="60" height="5" /> 
        <rect x="24" y="147" rx="0" ry="0" width="2" height="12" /> 
        <rect x="-598" y="191" rx="0" ry="0" width="132" height="11" /> 
        <rect x="-558" y="139" rx="0" ry="0" width="186" height="7" /> 
        <rect x="-647" y="187" rx="0" ry="0" width="498" height="30" /> 
        <rect x="-415" y="158" rx="0" ry="0" width="72" height="8" /> 
        <rect x="-635" y="159" rx="0" ry="0" width="481" height="32" /> 
        <rect x="124" y="187" rx="0" ry="0" width="2" height="26" /> 
        <rect x="-238" y="170" rx="0" ry="0" width="67" height="23" /> 
        <rect x="23" y="11" rx="0" ry="0" width="900" height="61" /> 
        <rect x="25" y="88" rx="0" ry="0" width="900" height="61" /> 
        <rect x="27" y="166" rx="0" ry="0" width="900" height="61" />
      </ContentLoader>}>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
    <div>
        <h2 className=''><FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.NAME" defaultMessage="Company Name" />  </h2>
        <div>{data?.company_name}</div>
    </div>
    <div onClick={()=> openModel(0)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
    <FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.CHANGE.BUTTON" defaultMessage="Change Company Name" /></div>
</div>
<div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
    <div>
        <h2 className=''><FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.BUSINESSTYPE" defaultMessage="Business Type" />  </h2>
        {businessTypes?.map((item, index)=> (
        <div key={index}>
        {data?.business_type?.includes(item.value) ? item.label : ''}
      </div>
        ))}
    </div>
    <div onClick={()=> openModel(1)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
    <FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.CHANGE.BUSINESSTYPE" defaultMessage="Change Business Type" /></div>
</div>
<div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
    <div>
        <h2 className=''><FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.INTENT" defaultMessage="Intent" />  </h2>
        {intentType.map((item, index)=> (
        <div key={index}>
        {data?.intent?.includes(item.value) ? item.label : ''}
      </div>
        ))}
    </div>
    <div onClick={()=> openModel(2)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
    <FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.CHANGE.INTENT" defaultMessage="Change Intent" /></div>
</div>
<ModelWrapperWithButton Save={HandleChange} showModal = {showModal} onCheckModel = {HandleModel} headertitle = {activeComponent.headertitle}>
    {<activeComponent.componentName HandleData={HandleData} data={data} ref={childRef}/>}
    </ModelWrapperWithButton>
    </ContentWithLoader>)
}
