import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";
export const getWorkOrderModel = async (params) => {
    try{
  return await get<ApiResponse<[]>>(`/workorder/model?populate=workorder_ids&${params}`).then((res)=> {
            if(res.success===true) {
                return res
            }
            else {
        return null
            }
  });
}
catch(e) {
    HandleApiError(e)
}
};
