import mapboxgl from 'mapbox-gl';
import AllCoordinate from 'app/pages/map-display/AllCoordinate';
export const initMap = (
  container: HTMLDivElement,
  coords: [number, number],
  stateName: string,
  stateId: string,
  districtName: string
) => {
  mapboxgl.accessToken =
    'pk.eyJ1IjoiZGl2eWFuc2h1cGF0aGFuaWEiLCJhIjoiY2tnNmJ1OWxxMGpsOTJ5cjBvZmhiZGo0NyJ9.Va14izEgFeTnPWmhNXc1Vw';

  const map = new mapboxgl.Map({
    container,
    style: 'mapbox://styles/mapbox/streets-v12',
    pitchWithRotate: false,
    center: coords,
    zoom: 10,
    accessToken: mapboxgl.accessToken,
    doubleClickZoom: false,
    stateName,
    stateId,
    districtName,
  });

  AllCoordinate.forEach((data) => {
    data.districtGeojson.forEach((coordinate) => {
      new mapboxgl.Marker()
        .setLngLat([coordinate[0],coordinate[1]])
        .addTo(map);
    });
  });  

  return map;
};
