import React from "react";
import { FormattedMessage } from "react-intl";

export const Pagination = ({page, totalPage, setPage}) => {
    const handlePageChange = (value) => {
        if(value > totalPage || value < 1 ) {
            return 
        }
    setPage(value)
    }
  return (
    <div className='flex items-center justify-center space-x-4 my-3'>
    <div onClick={()=> handlePageChange(page-1)} className='bg-white text-[#2557A7] cursor-pointer py-3 w-32 border justify-center border-neutral-200 hover:border-[#2557A7] rounded-lg flex space-x-2 items-center'>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
    
    <span className='font-satoshiBold'>
      <FormattedMessage
        id='PAGEINATION.PREVIOUS.BUTTON'
        defaultMessage='Previous'
      />
    </span>
    
     </div> 
     <div onClick={()=> handlePageChange(page+1)} className='bg-white text-[#2557A7] cursor-pointer py-3 w-32 border justify-center border-neutral-200 hover:border-[#2557A7] rounded-lg flex space-x-2 items-center'>
     <span className='font-satoshiBold'>
      <FormattedMessage
        id='PAGEINATION.NEXT.BUTTON'
        defaultMessage='Next'
      />
    </span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
    
     </div> 
      </div>
 );
};
