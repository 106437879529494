import React from "react";
import { post } from "../axios";
import ApiResponse from "sr/models/ApiResponse";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";

export const resetPassword = async (queryParams:any, postData:any) => {
    try {
  return await post<ApiResponse<[]>>(`/auth/reset?${queryParams}`, postData).then((res)=> {
    if(res?.success===true) {
      SuccessMessage('API.SUCCESS.PASSWORD.CHANGE')
        return res
    }
    else {
        return null
    }
  })
}
catch(e:any) {
    HandleApiError(e)
}
};
