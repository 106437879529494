import React, {useState, useEffect, useRef} from "react";
import { Input } from "sr/helpers";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { RadioInput } from "sr/helpers/ui-components/RadioInput";
import { FormattedMessage, useIntl } from "react-intl";
import { IndFilterDates } from "sr/i18n/messages/setting";
import { CheckBox } from "sr/helpers/ui-components/company/CheckBox";
import CheckBoxHandle from "sr/helpers/functions/CheckBoxHandle";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
export const CleanerFilter = () => {
    const [minRange, setMinRange] = useState(10)
    const [maxRange, setMaxRange] = useState(40)
    const [taskerType, setTaskerType]= useState("")
    const minRangeRef = useRef<HTMLInputElement>(null);
    const maxRangeRef = useRef<HTMLInputElement>(null);
    const [leftStyle, setleftStyle] = useState("")
    const [rightStyle, setrightStyle] = useState("")
    const [dates, setDates] = useState('')
    const [customDate, setCustomDate] = useState()
    const intl = useIntl()
  const TimeData = [
    {time:"Morning (8:00 - 12:00)"},
     {time:"Afternoon (12:00 - 17:00)"}, 
     {time:"Evening (17:00 - 21:00)"},
    {time:'Any'}
    ]
const [TimeofDay, setTimeofDay] = useState("")
  useEffect(()=> {
    console.log(TimeofDay)
  }, [TimeofDay])
  const TaskerTypeData = [
    {name:<FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.TASKERTYPE.ELITE" defaultMessage="Elite Tasker" />},
    {name:<FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.TASKERTYPE.GREAT" defaultMessage="Great Value" />}
  ]
useEffect(()=> {
  const gapRange = 10;
if(maxRange-minRange<gapRange) {
if(minRangeRef.current) {
  setMinRange(maxRange-gapRange)
}
else {
  setMaxRange(minRange+gapRange)
}
}
else {
setleftStyle((minRange/100)*100+"%")
setrightStyle((1-maxRange/100)*100+"%")
}
},[minRange, maxRange])
  return (
    <div className="col-span-4 bg-white border border-neutral-300 rounded-lg px-3 mb-3 lg:mb-0 h-fit">
    <div className=" border-b border-neutral-300 p-5">
      <p className="text-base font-satoshiBold pb-3"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.DATE.TITLE" defaultMessage="Date" /></p>
      <div className="flex items-start flex-wrap text-gray-500 font-satoshiBold font-bold space-x-2">
        {IndFilterDates.map((item, index) => (
          <CheckBox
          value={item.value}
          onChange={(e) => e.target.value===dates ? setDates('') : setDates(e.target.value)}
          checked = {dates===item.value}
          label={item.title}
          />
        ))}
      </div>
      {dates ==='custom' && 
     <ReactDatePicker
     onChange={(date)=> setCustomDate(date)}
     selected={customDate}
     label={intl.formatMessage({
       id: "INDIVIDUAL.CLEANERS.FILTER.DATE.TAG.CHOOSE_DATES",
       defaultMessage: "Choose Date",
     })}
   ></ReactDatePicker>
          

}
    </div>
    <div className="p-5">
      <p className="text-base font-satoshiBold pb-3"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.TIMEOFDAY." defaultMessage="Time of Day" /></p>
        {TimeData.map((item, index) => (
            <RadioInput
    key={index}
  name="filter"
  value={item.time}
  checked={TimeofDay === item.time}
  onChange={(e)=> setTimeofDay(e.target.value)}
  label={item.time}
/>
        ))}
     
    </div>
        <p className="border-b text-center leading-[1.5px] border-neutral-300"></p>
 
  <div className="border-b border-neutral-300 p-5">
      <p className="text-base font-satoshiBold pb-8"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.PRICE.TITLE." defaultMessage="Price" /></p>
     <div className="w-full rounded-full relative bg-gray-200 h-1.5">
        <div className={`bg-[#2557A7] rounded-full absolute left-0 right-0 h-1.5`} style={{left:leftStyle, right:rightStyle}}></div>
     </div>
     <div className="relative  mb-3">
        <input ref={minRangeRef} className="absolute -top-[6px] h-1.5 w-full pointer-events-none range-input"  value={minRange} type="range" min="0" max="100" onChange={(e:any)=> setMinRange(e.target.value)}></input>
        <input ref={maxRangeRef} className="absolute -top-[6px] h-1.5 w-full pointer-events-none range-input" value={maxRange} type="range" min="0" max="100" onChange={(e:any)=> setMaxRange(e.target.value)}></input>
     </div>
     <div className="flex justify-between w-full">
      <span>${minRange}</span>
      <span>${maxRange}</span>
     </div>
     <p className="text-center pt-3"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.PRICE.TEXT" defaultMessage="The average hourly rate is" /><span className="font-satoshiBold"> $56.69/hr</span></p>
      </div>
      <div className=" border-b border-neutral-300 p-5">
      {/* <p className="text-base font-satoshiBold pb-3"><FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.TASKERTYPE.TITLE" defaultMessage="Tasker Type" /></p> */}
      {/* {TaskerTypeData.map((item, index) => (
            <RadioInput
    key={index}
  name="Task"
  value={item.name}
  checked={taskerType === item.name.props.defaultMessage}
  onChange={(e:any)=> setTaskerType(e.target.value)}
  label={item.name}
/>
        ))} */}
      </div>
    </div>
  );
};
