import React from "react";
import { post } from "../axios";
import ApiResponse from "sr/models/ApiResponse";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";

export const uploadFile = async (postData:any) => {
  try {
        return await post<ApiResponse<[]>>('/util/directupload?file', postData, {headers:{'Content-Type': 'application/pdf',
      }}).then((res)=> {
                if(res.success===true) {
                    return res
                }
                else {
                    return null
                }
        })
  }
  catch(e:any) {
        HandleApiError(e)
  }
};
