import * as Yup from 'yup'

const companyRegisterSchema = Yup.object({
    username: Yup.string()
    .required('INPUT.VALIDATION.USERNAME')
    .min(3, 'INPUT.VALIDATION.USERNAME.MIN.CHARACTER')
    .max(20, 'INPUT.VALIDATION.USERNAME.MAX.CHARACTER')
    .matches(
      /^[a-zA-Z0-9_]+$/, 
      'INPUT.VALIDATION.USERNAME.MATCHES.CHARACTER'
    ),
    password:Yup.string().required("INPUT.VALIDATION.PASSWORD").min(6, 'INPUT.VALIDATION.PASSWORD.MIN.CHARACTER'),
    mobile_number:Yup.string().required('INPUT.VALIDATION.MOBILENUMBER'),
    email:Yup.string().email().required('INPUT.VALIDATION.EMAIL'),
    company_name:Yup.string().required('INPUT.VALIDATION.COMPANY.NAME'),
    business_type:Yup.array().min(1, 'INPUT.VALIDATION.COMPANY.BUSINESS.TYPE'),
    intent:Yup.array().min(1, 'INPUT.VALIDATION.COMPANY.BUSINESS.INTENT'),
    candidate_msg:Yup.bool().required("INPUT.VALIDATION.COMPANY.CANDIDATE.MSG"),
    
});

export default  companyRegisterSchema;
