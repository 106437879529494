import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post, put } from "sr/utils/axios";

export const AssignSupervisorLocation = async (postData) => {
    try {
  return await put<ApiResponse<[]>>('/supervisor/addlocations', postData).then((res)=>{
        if(res?.success===true) {
            SuccessMessage('API.SUCCESS.SUPERVISOR.LOCATION.ASSIGNED')
            return res
    }
        else {
            return null
        }
  })
}catch(e){
    HandleApiError(e)
}
};
