import { getCustomer } from "sr/utils/api/company/customer/getCustomer";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";

interface ParamsType {
  userId?:string,
  userType?:string,
  page?:number,
  limit?:number,
  selectedCustomer?:any[],
  customerLocationId?:string,
  setData?:(params:any)=> void
  setTotalPage?:(params:any)=> void
  setLoader?:(params:any)=> void
  setStepperIndex?:(num:any) => void
  setShowModel?:(value:boolean) => void
  shoudActive?:boolean
}
export const fetchCustomerData = async (params:ParamsType) => {
  try {
    if(params?.setLoader) params?.setLoader(true);
    const queryParams = new URLSearchParams();
    if (params?.userType === 'company') {
      if (params?.userId) queryParams.append('company_id', params?.userId);
    }
    if (params?.page) queryParams.append('page', params?.page.toString());
    if (params?.limit) queryParams.append('limit', params?.limit.toString());
    if (params?.selectedCustomer && params?.selectedCustomer.length > 0) {
      const quotedSelectedCustomer = params?.selectedCustomer.map(item => `"${item}"`);
      const filterString = `{ "type": { "$in": [${quotedSelectedCustomer.join(', ')}] } }`;
      queryParams.append('filter', filterString);
    }
    queryParams.append('status', 'active');
    let result;
    if (params?.userType === 'company') {
      result = await getCustomer(queryParams);
    } else {
      const queryParamsLocation = new URLSearchParams()
      queryParamsLocation.append('id', params?.customerLocationId);
      const res = await getCustomerLocation(queryParamsLocation);
      if (res?.success === true) {
        let allCustomerId;
        let uniqueCustomerId;
            if(Array?.isArray(res?.data)){
        allCustomerId = res?.data?.map(item => item?.customer_id);
        uniqueCustomerId = allCustomerId?.filter((item, index) => allCustomerId.indexOf(item) === index);
        queryParams.append('id', uniqueCustomerId?.join());     
      }
            if (typeof res?.data === 'object' && !Array.isArray(res?.data)){
              
                  allCustomerId = res?.data?.customer_id
                  queryParams.append('id', allCustomerId)
            }

        result = await getCustomer(queryParams);
      }
    }
    if (result?.success === true) {
      params?.setTotalPage && params?.setTotalPage(Math.ceil(result?.pagination.total / params?.limit));
      if(params?.shoudActive===true) {
        const activeData = result?.data?.filter(
                  (item) => item?.status === "active"
                );
                params?.setData(activeData);
                if(activeData?.length===0) {
                      params?.setShowModel(true)
                }
                if(activeData?.length>0) {
                          params?.setStepperIndex(1)
                        }
      } else {
      params?.setData(result?.data);
      }
    } 
    else {
      if(params?.setLoader) params?.setLoader(false);
    }
  } catch (e) {
    if(params?.setLoader) params?.setLoader(false);
    console.log(e);
    
  } finally {
    if(params?.setLoader) params?.setLoader(false);
  }
};
