import React, { useEffect, useState } from "react";
import fetchEmployment from "sr/helpers/apiFunctions/common/fetchEmployment";
import fetchMedical from "sr/helpers/apiFunctions/common/fetchMedical";
import fetchTraining from "sr/helpers/apiFunctions/common/fetchTraining";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getApplication } from "sr/utils/api/cleaner/getApplication";
import { getFile } from "sr/utils/api/getFile";
import { getFiles } from "sr/utils/api/getFiles";
import { CandidateProfile } from "./Candidate-details/CandidateProfile";
import { Input } from "sr/helpers";
import { FormattedMessage } from "react-intl";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { employment as employmentData } from "../../jobpost/component/Jsonalldata";
import PayChange from "../../jobpost/component/Model/PayChange";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import { createContractor } from "sr/utils/api/company/workorder/createContractor";
import { toast } from "react-toastify";
import { updateApplication } from "sr/utils/api/cleaner/updateApplication";
import { useFormik } from "formik";
import { contractorHireSchema } from "sr/utils/schemas/company/contractor/contractorHireSchema";
import { contractorHire } from "sr/utils/api/company/contractor/contractorHire";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const HirePopup = (props) => {
    const [loader, setLoader] = useState(false)
    const [candidate, setCandidate] = useState()
    const [backStatus, setBackStatus]  = useState()
    const [training, setTraining] = useState()
    const [medical, setMedical] = useState()
    const [employment, setEmployment] = useState()
    const [resume, setResume] = useState(null)
    const [resumeKey, setResumeKey] = useState(null)
    const [companyId, setCompanyId] = useState()
    const [joiningDate, setJoiningDate] = useState(new Date());
    const [currentEmployment, setCurrentEmployment] = useState()
    const [destination, setDestination]= useState()
    const [hourlyRate, setHourlyRate] = useState()
const [errors, setErrors] = useState(null)
    useEffect(()=> {
            const {user_id} = getUserDetails()
            setCompanyId(user_id)
    },[])

    const fetchCandidate = async (id) => {
        setLoader(true)
        try {
      const queryParams = new URLSearchParams();  
             queryParams.append('id', id.toString());
        const result = await getApplication(queryParams)
        if(result?.success===true) {
          setCandidate(result?.data)
          setBackStatus(result?.data?.status)
          fetchTraining({cleanerId:result?.data?.cleaner_id?._id, setData:setTraining})
            }
            fetchMedical({cleanerId:result?.data?.cleaner_id?._id, setData:setMedical})
            fetchEmployment({cleanerId:result?.data?.cleaner_id?._id, setData:setEmployment, setLoader:setLoader})
        }
        catch(e:any) {
        }
      }
      useEffect(()=> {
if(props?.data?.id) {
    fetchCandidate(props?.data?.id)
}
setDestination(props?.data?.job_id?.job_title)
if(props?.data?.job_id?.employment_type?.length>0){
setCurrentEmployment(props?.data?.job_id?.employment_type[0])
}
      },[props?.data?.id])
      const HandleDataProps = ()=> {

      }
      const initialValue = {
        joining_date:joiningDate,
        employment_type:currentEmployment,
          destination:destination,
          rate:hourlyRate,
          companyId:companyId,
      }
useEffect(()=>{
console.log(initialValue);

},[initialValue])
      const confirmConvert = async () => {
        try {
          const validData = contractorHireSchema.validateSync(initialValue, { abortEarly: false });
        setLoader(true)
try {
              const payLoad = {
                cleaner_id:props?.data?.cleaner_id?.id,
                company_id:companyId,
                status:'active'
              }
              const res = await createContractor(payLoad)
              if(res.success===true) {
                toast.success('Candidate Updated to Contractor')
                  const payload1 = {
                    id:props?.data?.id,
                    status:'hired'
                  }
                const result = await updateApplication(payload1)
                
                if(result?.success===true) {
                    try  {
                      const resultcontractor = await contractorHire({contractor_id:res.data?.id, ...initialValue})
                      if(resultcontractor?.success===true) {
                       if (props?.getContractorall) props?.getContractorall(companyId)
                        setLoader(false)
                      if(props?.setActiveTab) props?.setActiveTab('hired')
                       if (props?.fetchCandidate) props?.fetchCandidate()
                        SuccessMessage('API.SUCCESS.APPLICATION.STATUS.UPDATE', {STATUS:'Hired'})
                        if (props?.onCheckModel) props?.onCheckModel()
                      }
                  }
                  catch(e){
      console.log(e);
      
                  }
                    // Proceed with submission
                }
              }
              else {
                setLoader(false)
              }
            }
          
          catch(e) {
              setLoader(false)
          }
        } catch (err) {
          const fieldErrors = {};
    err.inner.forEach((error) => {
      fieldErrors[error.path] = error.message;
    });

          setErrors(fieldErrors)
        
        }
        
       }
       

      return (
        <ModelWrapperWithButton Save={confirmConvert} savetext={'Confirm'} showModal={props?.showHired} onCheckModel={props?.onCheckModel}>
    <div className="mx-auto w-[80%]">
        {loader && <LoaderFullScreen></LoaderFullScreen>}
    <p className="text-center  text-base">Do you want to hire this candidate?</p>
            <CandidateProfile candidate={props?.data} employment={employment} medical={medical} training={training}></CandidateProfile>
            <ReactDatePicker
            error = {errors?.joining_date}
                      onChange={(date) => setJoiningDate(date)}
                      selected={joiningDate}
                      label={
                        <FormattedMessage
                          id="RECRUITER.CANDIDATES.HIRE.JOINING.DATE"
                          defaultMessage="Joining Date"
                        />
                      }
                      inputClass="rounded-lg"
                      labelClass="mt-2 text-black"
                      InputDivClass="mt-5 w-26 pr-2"
                      type="date"
                      placeholder="Joining Date"
                    ></ReactDatePicker>
            <Input error={errors?.destination} labelClass="mt-2 text-black text-normal " value={destination} onChange={(e)=> setDestination(e.target.value)} type="text" label="Destination" placeholder="Enter Destination" InputDivClass="mt-5"></Input>        
<DropDown error={errors?.employment_type} labelClass={'mt-2 text-black text-normal '} dropdowndiv="mb-5 mt-5" label={<FormattedMessage id="CLEANER.JOBS.FILTER.EMPLOYMENTTYPE" ></FormattedMessage>} value={currentEmployment} onChange={(e:any)=> setCurrentEmployment(e.target.value)}>
    <option value={''}>Select Employment</option>
    {employmentData?.map((item)=> (
<option value={item?.value}>{item?.label}    
</option>
))}
</DropDown>
<PayChange dropdowndiv="mt-3" nodescription={true} data={props?.data?.job_id} HandleDataProps={HandleDataProps}></PayChange>
<div className='relative flex w-1/3'>
      <div className='absolute top-[34px] inset-0 z-50 w-6 h-6'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33" />
</svg>


      </div>
                 <Input 
                 onChange={(e:any)=> setHourlyRate(e.target.value)}
                  label = "Pay Rate (Hourly)"
                  placeholder="Enter Pay Rate (Hourly)"
                  type={'text'}
                    inputClass = 'border border-zinc-800 rounded-lg h-11'
                    InputDivClass = 'h-full w-full'
                    labelClass={'mt-2 text-sm text-normal '}
                    value={hourlyRate}
                    min={1}
                    error={errors?.rate}
                 />
                 </div>
            </div>
            </ModelWrapperWithButton>
   
 
  );
};
