import { useFormik } from "formik";
import React, {useState, useEffect, ForwardRefRenderFunction} from "react";
import { Input } from "sr/helpers";
import personalInformation from "sr/utils/schemas/cleaner/Personalinformation";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "sr/redux/reducers";
import { updateCleaner } from "sr/utils/api/cleaner/updateCleaner";
import { Address } from "../component/Address";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import AutoLocation from "sr/helpers/ui-components/AutoLocation";
import { CleanerComponentProps } from './CleanerComponentProps'
import { forwardRef } from "react";
import { getCleaner } from "sr/utils/api/cleaner/getCleaner";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
const CleanerPersonalInfo: ForwardRefRenderFunction<any, CleanerComponentProps> = (
  { HandleStepper = null, page = '', type = '',dataAddedHandle=null, id='' },
  ref
) => {

  const [address, setAddress] = useState(null)
  const [userData, setUserData] = useState(null)
  const [cleanerData, setCleanerData] = useState<any>()
  const [loader, setLoader] = useState<string>('')
    const intl = useIntl()
  const HandleAutoLocation = (data:any) =>{
    setAddress(data)
  }

  useEffect(()=> {
    const getdetails = getUserDetails()
    setUserData(getdetails)
    
  },[])
const initialValues = {
        id:page ==='popup' ? id: userData?.user_id,
        address: address
}
useEffect(()=> {
  const fetchData = async () => {
    const queryParams = new URLSearchParams()
    queryParams.append('id',id )
    setLoader('fetch')
    try {
      const cleanerData = await getCleaner(queryParams)
      if(cleanerData?.success===true){
        setCleanerData(cleanerData?.data)
        setLoader('')
      }
    }
    catch(e:any){
      console.log(e);
      
    }
  }
  if(page === 'popup' && type==='put'){
  fetchData()
  }   
},[id])
  const {values, errors, handleChange, handleSubmit, submitForm } = useFormik({
    initialValues: initialValues,
    enableReinitialize:true,
    onSubmit: async (values) => {
      setLoader('submit')
      try {
      const result = await updateCleaner(values)
      console.log(result);
        if(result.success===true) {
          if (HandleStepper !== null) {
       
              HandleStepper(1);
          
          }
          if(page === 'popup' ){
            dataAddedHandle(true)
            }
            setLoader('')
        
       }
      }
      catch(e:any){
        setLoader('')
      }
    },
  });
  React.useImperativeHandle(ref, () => ({
    submitForm,
  }));
  return (
    <>
    {loader==='submit' && <LoaderFullScreen />}
      <ContentWithSpinner loader={loader==='fetch'}>
      <form onSubmit={handleSubmit} className="mt-5">
        <AutoLocation isHideLatLang={true} HandleAutoLocation={HandleAutoLocation} address={cleanerData?.address}/>
        {page === 'popup' && type==='put' ? '':(
       <div className='mt-5'>
                    <button type='submit'
                      className='rounded-full
                                bg-pink
                                p-2
                                text-white
                                w-44
                                cursor-pointer
                                hover:bg-navy'
                      ><FormattedMessage id="REGISTER.BUTTON.SAVEANDCONTINUE" defaultMessage="Save and Continue" />
                    </button>
                  </div>
        )}
      </form>
      </ContentWithSpinner>
    </>
  );
}

export default forwardRef(CleanerPersonalInfo);