import React, { useEffect, useState } from "react";
import { MainWrapper } from "../../component/MainWrapper";
import { TopBar } from "../../component/TopBar";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { removeContractorLocation } from "sr/utils/api/company/workorder/removeContractorLocation";
import { assignContractorLocation } from "sr/utils/api/company/workorder/assignContractorLocation";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { useAuth } from "app/pages/module/auth";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { fetchCustomerLocations } from "sr/helpers/apiFunctions/fetchCustomerLocations";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { ContractorTab } from "./components/ContractorTab";
import { Location } from "./components/Location";
import { General } from "./components/General";
import { JobType } from "./components/JobType";
import { WorkOrders } from "./components/WorkOrders";

export const ContractorDetails = () => {
    const [id, setId] = useState<any>()
    const [customerLocations, setCustomerLocations] = useState([])
    const [userId, setuserId] = useState()
    const [buttonLoader, setButtonLoader] = useState(false)
    const [data, setdata] = useState<any>([])
    const [loader, setLoader] = useState(true)
    const [tab, settab] = useState('General')

    const TabHandle = (data:any) => {
      settab(data)
    }
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setuserId(user_id)
          },[])
          const {userType} = useAuth()
          const params = useParams()
          useEffect(()=> {
                const {id} = params
                setId(id)
          },[params])
         
          const loadContractor = async (id)=> {
            await fetchContractor({userType:userType, id:id, setData:setdata, setLoader:setLoader})
      }
          useEffect(()=> {
             
              if(id) {
                  loadContractor(id)
              }
          },[id])
      
        
      
   
  return (
    <MainWrapper >
      {buttonLoader && <LoaderFullScreen />}
    <div className='bg-white py-3'>
    <TopBar title = {<FormattedMessage
          id='RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.TITLE'
          defaultMessage='Contractor'
        />} 
        btntitle = {<FormattedMessage
          id='RECRUITER.SUPERVISOR.ALL.CONTRACTOR'
          defaultMessage='All Contractor'
        />} btntitlelink = '/contractor' postButton={<Link  to="/add-contractor" className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-3  text-white cursor-pointer hover:bg-navy' ><FormattedMessage id="RECRUITER.WORK.ORDER.CONTRACTOR.ADD.BTN" defaultMessage="Add Contractor" />
                                </Link>}  className='px-10'></TopBar>
                                <div className="px-10">
                                <div>
                                <div className='flex items-center space-x-4 mb-5'>
<Link to={`/contractor`}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#0078ae] w-5 h-5 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
</Link>
<div>
    <h2 className=''>{data?.first_name} {data?.last_name}</h2>
    <Link to={`/contractor`}>
      <p className='text-base  text-[#0078ae]'> <FormattedMessage id="RECRUITER.SUPERVISOR.BACKBUTTON" defaultMessage="Back" /></p>
      </Link>
</div>

</div> 

   

    <ContractorTab tab={tab} TabHandle={TabHandle}></ContractorTab>
                                  </div>
                                  {tab==='General' &&
                                 <General data={data}></General>
                                  }
                                
                                  {tab==='Location' && 
<Location data={data} loadContractor={loadContractor}></Location>
                                  }
                                  {tab==='Jobtype' && 
                                <JobType></JobType>
                                  }
                                  {tab==='Work Orders' && 
                               <WorkOrders></WorkOrders>
                                  }
</div>
</div>
        </MainWrapper>
  );
};
