import React, { useEffect, useState } from "react";
import { CompanyRoutes } from "./CompanyRoutes";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { useAuth } from "app/pages/module/auth";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { PendingDetails } from "app/pages/supervisor/PendingDetails";
import { SupervisorDetails } from "app/pages/company/work-order/supervisor/SupervisorDetails";
import { Contractor } from "app/pages/company/work-order/contractor/Contractor";
import { LayoutSplashScreen } from "sr/layout/master-layout";
import { FilterRoutes } from "sr/helpers/functions/FilterRoutes";
export const SupervisorRoutes = () => {
    const {userType} = useAuth()
    const [userId, serUserId] = useState()
    const supervisorData = useSelector((state:any)=> state?.supervisor?.data)
    const dispatch = useDispatch<AppDispatch>();
    const [filteredRoutes, setFilteredRoutes] = useState([])
    useEffect(()=> {
        const getdetails = getUserDetails()
        serUserId(getdetails.user_id)
      },[])


    useEffect(()=> {
            if(userType==='supervisor'){
                if(!supervisorData) {
                    if(userId){
                    dispatch(fetchSupervisor({id:userId}))
                    }
                }
            }
    },[dispatch, userId, userType])
    useEffect(()=> {
      if(supervisorData) {
         setFilteredRoutes(FilterRoutes(supervisorData))
      
      }
          },[supervisorData])
    if(!supervisorData) {
        return <LayoutSplashScreen />
      }

   return (
    <Routes>
        <Route>
      {filteredRoutes?.map((data, index) => (
        <Route key={index} path={data.path} element={<data.element />} />
      ))}
      <Route path='/supervisor/manage-work-order/:id' element={<PendingDetails/>} />
<Route path='/supervisor-details/:id' element={<SupervisorDetails />} />
<Route path='/*' element={<Navigate to={'/work-order'} replace />} />
      </Route>
    </Routes>
  );
  
};
