import React, {useState, useEffect} from 'react'
import AuthNavComponent from '../module/auth/AuthNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleCheck, faCircleXmark, faDollarSign, faL, faLocationDot, faStar, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { InterestedJobs } from './component/Sidebar/InterestedJobs'
import { Link, useParams } from 'react-router-dom'
import Footer from '../module/Footer'
import { FormattedMessage } from 'react-intl'
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs'
import { DaysConverter } from 'sr/helpers/functions/DaysConverter'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { HTMLStringRender } from 'sr/helpers/ui-components/HTMLStringRender'
import { getAdditionInfo } from 'sr/utils/api/company/getAdditionInfo'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import ContentWithLoader from 'sr/layout/common/ContentLoader'
import { StringLoader } from '../component/Loader/StringLoader'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { createFavoriteJob } from 'sr/utils/api/cleaner/createFavoriteJob'
import { useAuth } from '../module/auth'
import { getSavedJobs } from 'sr/utils/api/cleaner/getSavedJobs'
import { useNavigate } from 'react-router-dom'
import { ModelWrapperWithButton } from '../company/component/ModelWrapperWithButton'
import { createApplication } from 'sr/utils/api/cleaner/createApplication'
import { getFiles } from 'sr/utils/api/getFiles'
import { getFile } from 'sr/utils/api/getFile'
import { uploadFile } from 'sr/utils/api/uploadFile'
import Job from '../component/types/Job'
import { Button, Checkbox } from 'sr/helpers'
import { SocialShare } from '../component/SocialShare'
import { Helmet } from 'react-helmet';
import { ModelWrapper } from '../company/component/ModelWrapper'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { addReportJob } from 'sr/utils/api/cleaner/addReportJob'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { getJobTypeCategory } from 'sr/utils/api/getJobTypeCategory'
export default function JobDetails() {
      const {isAuthenticate, userType} = useAuth()
    const [job, setJob] = useState(null);
    const [companyName, setcompanyName] = useState()
    const {id} = useParams()
    const [postedDays, setpostedDays] = useState(null)
    const [companyAdditional, setCompanyAdditional] = useState(null)
    const [loader, setLoader] = useState("")
    const [cleanerId, setCleanerId] = useState(null)
    const [isSaved, setIsSaved] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [resume, setResume] = useState(null)
    const [newResume, setNewResume] = useState(null)
    const [isApplied, setIsApplied] = useState(false)
    const [message, setMessage] = useState('')
    const [applyWithOld, setapplyWithOld] = useState(false)
    const [reportJobPopup, setReportJobPopup] = useState(false)
    const [reportjob, setReportJob] = useState([])
    const [interestJob, setInterestJob]= useState(null)
    const navigate = useNavigate()
    useEffect(()=> {
        if(userType ==='cleaner') {
            const getdetails = getUserDetails()
            setCleanerId(getdetails?.user_id)
        }
    },[])

    const path = window.location.pathname
    const fullLink = `https://${process.env.REACT_APP_URL_DEV}${path}`

const fetchJobData = async (id)=> {
    
                    setLoader('Job')
                    const queryParams = new URLSearchParams();  
                    if (id) queryParams.append('id', id.toString());
                    try {
                        const result: any= await getJobs(queryParams);
                        if(result?.success===true) {
                        setJob(result?.data);
                        setpostedDays(DaysConverter(result?.data?.createdAt))
                        setcompanyName(result?.data?.company_id?.company_name)
                        setLoader('')
                      
                        }
                    }
                    catch(error) {
                        console.log(error);
                        setLoader('')
                    }
                }
                useEffect(()=> {
                    const isAppliedcheck = job?.application_ids?.find((item)=> item?.cleaner_id===cleanerId)
                    if(isAppliedcheck?.job_id===id) {
                        setIsApplied(true)
                    }

                },[job])
 const fetchAddInfo = async (company_id) => {
    setLoader('AddInfo')
    try {
        const addResult = await getAdditionInfo(company_id, undefined)
        setCompanyAdditional(addResult?.data)
        setLoader('')
    }
    catch(e:any) {
        setLoader('')
    }
 }  
 const FetchSavedJob = async (cleanerId)=> {
    const result = await getSavedJobs(cleanerId)
    if(result?.success===true) {
        const data = result?.data?.job_ids?.find((item)=> item?._id===id)
        if(data) {
            setIsSaved(true)
        }
        return result?.success;
    }
 }         
 useEffect(() => {
   if(id) {
    fetchJobData(id)
   }
}, [id]);
useEffect(()=> {
if(cleanerId) {
    FetchSavedJob(cleanerId)
}
},[cleanerId])

useEffect(()=> {
const fetchAdd = async () => {
    await fetchAddInfo(job?.company_id._id);
}
if(job?.company_id) {
    fetchAdd()
}
},[job?.company_id])

const HandleSave = async () => {
    setLoader('submit')
    if(!isAuthenticate) {
           navigate('/auth')
           return
    }
    if (isAuthenticate && userType !=='cleaner'){
        navigate('/auth')
           return
    }
    try {
        const data = {
            cleaner_id:cleanerId,
            job_id:id
        }
        const result = await createFavoriteJob(data)
        if(result.success===true) {
            setLoader('')
        FetchSavedJob(cleanerId)
        }
    }
    catch(e:any) {
setLoader('')
    }

    
}
const onCheckModel = () => {
    setShowModal(false)
}

const HandleApply = async () => {
    setLoader('submit')
    if(!isAuthenticate) {
        navigate('/auth')
        return
 }
 if (isAuthenticate && userType !=='cleaner'){
     navigate('/auth')
        return
 }
    const params = {
        cleaner_id:cleanerId
    }
     const resumeData = await getFiles(params)
    if(resumeData?.success===true) {
        const filterfile = resumeData?.data?.filter((item)=> item.file.fieldname==='resume')
        if(filterfile?.length>0) {
            setResume(filterfile[0]?.file?.originalname)
            setapplyWithOld(true)
            setLoader('')
        }
        // const resume = await getFile(filterfile[0].file.key)
        // if(resume) {
        //     console.log(resume);
        // // const rawPdfData: Uint8Array = resume as Uint8Array;
        // // const pdfBlob = new Blob([rawPdfData], { type: 'application/pdf' });
        // // const pdfFile = new File([pdfBlob], 'resume.pdf', { type: 'application/pdf' });
        //     // setResume(pdfFile)
        //      }
}
setLoader('')
setShowModal(true)
}
const HandleResume = async (e: any) => {
    setLoader('submit')
    try {
    const filedata = new FormData();
    filedata.append('cleaner_id', cleanerId)
    filedata.append('resume', e.target.files[0]);
    const fileres = await uploadFile(filedata)
   if(fileres?.success===true) {
    setNewResume(fileres?.data?.files[0].originalname)
    setLoader('')
   }
    }
    catch (error) {
        setLoader('')
      console.error('Error uploading resume:', error);
    }
  };
const ApplyJob = async () => {
    setLoader('submit')
    if(job?.require_resume===true) {
            if(!resume && !newResume) {
                setMessage('Please add Resume')
                setLoader('')
                return 
            }
    }
    const data = {
        job_id:id,
        answers:[],
        status:"active",
        // id:"65cb2086ef25cbd6f2efd5a1",
        cleaner_id:cleanerId
    }
const result = await createApplication(data)
if(result.success===true) {
    fetchJobData(id)
    setLoader('')
    setShowModal(false)
}  
}
const inappropriateContent = [
    { type: "Inappropriate Content" },
    { type: "Incomplete information about job / company" },
    { type: "Fake job / Non-recruitment related job / Scam" },
    { type: "Duplicate of another job on the site" },
    { type: "Incorrect Email ID" },
    { type: "Phone number not contactable" },
    { type: "Other" }
];
const HandleReportJob = async ()=> {
    if(!isAuthenticate && userType !== 'cleaner') {
        navigate('/auth')
    }
    const payload = {
            job_id:job?.id,
            cleaner_id:cleanerId,
            reasons:reportjob
    }
    const result = await addReportJob(payload) 
    if(result?.success===true) {
        setReportJobPopup(false)
    }

}
const [jobTypes, setJobTypes] = useState([])
    useEffect(()=>{
      const fetchJobType = async ()=>{
try {
const res = await getJobTypeCategory()
if(res?.success===true){
  setJobTypes(res?.data)
}
}
catch(e:any){

}
      }
      fetchJobType()
    },[])

    return (
        <>
              <Helmet>
        <title>{`${job?.job_title} Jobs`}</title>
        <meta property="og:title" content={`${job?.job_title} Jobs`} />
        <meta property="og:description" content={`${job?.job_title} Jobs`} />
</Helmet>

            <AuthNavComponent isLoggedin={true}></AuthNavComponent>
            {loader==='submit' && <LoaderFullScreen />}
            <ContentWithSpinner loader={loader==='Job'} height='h-screen'>
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className='lg:flex block'>
                    <div className='flex flex-col lg:w-2/3 w-full'>
                        <div className='card-1 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <div className='header'>
                                <h1 className='font-bold  text-lg'>{job?.job_title}</h1>
                                <ContentWithLoader loading={loader==='Company'} content={<StringLoader />}>
                                <h2 className='text-sm font-medium text-gray-800'>{companyName}</h2>
                                </ContentWithLoader>

                                {/* <h3 className='text-xs font-normal text-gray-500'>Hiring for Leading Client</h3> */}
                            </div>

                            <div className='job-attr mt-5'>
                                
                                <div className='row-2 flex'>
                                <div className='pr-8'>
                                        <FontAwesomeIcon icon={faDollarSign} className='text-gray-600 text-sm font-medium' />
                                        <span className='pl-2 text-gray-600 text-sm font-medium'>{job?.show_pay_by === 'Range' ? `${job?.min_amount}-${job?.max_amount}`: job?.min_amount||job?.max_amount||job?.exact_amount} {job?.rate}</span>
                                    </div>
                                    {job?.job_advanced_id?.reporting_address &&
                                    <div className='pr-8'>
                                        <FontAwesomeIcon icon={faLocationDot} className='text-gray-600 text-sm font-medium' />
                                        <span className='pl-2 text-gray-600 text-sm font-medium'>{job?.job_advanced_id?.reporting_address?.address_line_1} {job?.job_advanced_id?.reporting_address?.address_line_2} {job?.job_advanced_id?.reporting_address?.city} {job?.job_advanced_id?.reporting_address?.state}</span>
                                    </div>
}
                                </div>
                                <div className='border-t border-gray-200 my-5'>
                                </div>
                                <div className='lg:flex block justify-between items-center'>
                                    <div className='row-1 flex'>
                                        <div className='pr-8'>
                                            <span className='lg:pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='ECOMMERCE.COMMON.TEXT.POSTED' defaultMessage={'Posted'}/>:</span>
                                            <span className='lg:pl-2 text-gray-900 text-sm font-semibold'>  <FormattedMessage 
                           id='CLEANER.JOBS.TIME'
                           defaultMessage='{postedDays} Days Ago'
                           values={{postedDays}}
                        /></span>
                                        </div>
                                        <div className='pr-8'>
                                            <span className='pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='JOBDETAILS.OPENINGS' defaultMessage='Openings' />:</span>
                                            <span className='pl-2 text-gray-900 text-sm font-semibold'>{job?.job_advanced_id?.hire_count || 1 }</span>
                                        </div>
                                        <div className='pr-8'>
                                            <span className='pl-2 text-gray-600 text-sm font-normal'><FormattedMessage id='CLEANER.JOBS.JOBAPPLICANTS' defaultMessage='Job Applicants' />:</span>
                                            <span className='pl-2 text-gray-900 text-sm font-semibold'>{job?.application_ids?.length || 0}</span>
                                        </div>
                                    </div>

                                    <div className='mt-8 lg:m-0'>
                                        {  isApplied ? 
                                        <button className='bg-[#ff39b3] font-bold text-white hover:bg-navy p-2 w-24 rounded-3xl mr-3 font-bold'><FormattedMessage id='APPLIEDJOBS.APPLIED' defaultMessage='Applied' /></button>
:
<button onClick={HandleApply} className='bg-[#ff39b3] font-bold text-white hover:bg-navy p-2 w-24 rounded-3xl mr-3 font-bold'><FormattedMessage id='JOBS.APPLYBUTTON' defaultMessage='Apply' /></button>

}
                                          
                                         <button onClick={HandleSave} className={`border border-[#ff39b3] hover:bg-red-50 p-2 w-24 rounded-3xl font-bold ${isSaved ? 'text-[#ff39b3]': 'text-[#ff39b3]'}`}><div> {isSaved ? <FormattedMessage id='JOBS.SAVEDBUTTON' defaultMessage='Saved'></FormattedMessage>: <FormattedMessage id='JOBS.SAVEBUTTON' defaultMessage='Save' />  }</div></button>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='card-2 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <div className='flex flex-col p-6 bg-gray-50 border border-gray-border rounded-xl'>
                                <h1 className='font-bold  text-base'>
                                    <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.TITLE'
                                    defaultMessage='Job Highlights'
                                    />
                                </h1>
                                {job?.benefits?.length>0 &&
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.BENIFITS'
                                    defaultMessage='Benefits'
                                    />:</h3>
                                    <div className='flex flex-wrap'>
                                    {job?.benefits?.toString().replaceAll(',', ', ')}                                      
                                 </div>
                                    
                                </div>
}
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.JOBTYPE'
                                    defaultMessage='Job Type'
                                    />: </h3>
   <div className='flex flex-wrap'>
   {job?.job_type?.map((item, index) => (
  <div className='mr-2' key={index}>
    <FormattedMessage id={getLabelByValue(item, jobTypes)||'DEMO'} />
    {index < job?.job_type?.length - 1 && ','} {/* Add comma except for the last item */}
  </div>
))}

                                    </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.EMPLOYMENTTYPE'
                                    defaultMessage='Employment Type'
                                    />: </h3>
                                       <div className='flex flex-wrap '>
                                    {job?.employment_type?.toString().replaceAll(',', ', ')}
                                    </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.HIGHLIGHT.SCHEDULE'
                                    defaultMessage='Schedule'
                                    />: </h3>
                                    <div className='flex  flex-wrap '>
                                    {job?.schedule?.toString().replaceAll(',', ', ')}
                                 </div>
                                </div>
                                <div className='mt-3 flex space-x-2'>
                                    <h3 className='font-bold  text-base whitespace-nowrap'>   <FormattedMessage 
                                    id='JOBDETAILS.SUPPLEMENTALPAY'
                                    defaultMessage='Supplemental Pay'
                                    />: </h3>
                                    <div className='flex flex-wrap '>
                                    {job?.job_advanced_id?.supplemental_pay?.toString().replaceAll(',', ', ')}
                                 </div>
                                </div>
                            </div>
                            <div className='job-description mt-6'>
                                <h1 className='font-bold  text-base'>
                                <FormattedMessage 
                                    id='JOBDETAILS.JOBDESCRIPTION'
                                    defaultMessage='Job Description'
                                    />
                                </h1>
                                <div><HTMLStringRender htmlContent={job?.job_description}></HTMLStringRender></div>
                            </div>

                            <div className='mt-6'>
                                {/* <div><span className='font-bold  text-sm'> <FormattedMessage id="JOB.STARTDATE.TITLE" defaultMessage="Start Date" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{DateFormat(job?.start_date)}</span>
                                </div> */}
                               {job?.job_advanced_id?.recruitment_timeline &&
                                <div><span className='font-bold  text-sm'>
                                <FormattedMessage id="JOB.RECRUITEMENT.TIMELINE.TITLE" defaultMessage="Recruitement Timeline" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{DateFormat(job?.job_advanced_id?.recruitment_timeline)}</span>
                                </div>
}
{job?.job_advanced_id?.application_deadline &&
                                <div><span className='font-bold  text-sm'>
                                <FormattedMessage id="JOB.APPLICATION.DEADLINE.TITLE" defaultMessage="Application Deadline" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{DateFormat(job?.job_advanced_id?.application_deadline)}</span>
                                </div>
}
{job?.rate &&
                                <div><span className='font-bold  text-sm'>
                                <FormattedMessage id="JOB.PAYTYPE.TITLE" defaultMessage="Pay Type" />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>{job?.rate}</span>
                                </div>
}

                                {/* <h3 className='mt-5 font-bold  text-sm'><FormattedMessage 
                                    id='JOB.EDUCATION.TITLE'
                                    defaultMessage='Education'
                                    /></h3>
                                <div><span className='font-bold  text-sm'><FormattedMessage 
                                    id='JOB.EDUCATION.UG.TITLE'
                                    defaultMessage='UG'
                                    />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>Any</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage 
                                    id='JOB.EDUCATION.PG.TITLE'
                                    defaultMessage='PG'
                                    />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>Any</span>
                                </div>
                                <div><span className='font-bold  text-sm'><FormattedMessage 
                                    id='JOB.EDUCATION.DOCTORATE.TITLE'
                                    defaultMessage='Doctorate'
                                    />:</span>
                                    <span className='text-sm text-gray-700 pl-1'>
                                        <FormattedMessage 
                                    id='JOB.EDUCATION.DOCTORATE.NOT.REQUIRED'
                                    defaultMessage='Doctorate Not Required'
                                    /></span>
                                </div> */}

                                {/* <h3 className='mt-5 font-bold  text-sm'> <FormattedMessage 
                                    id='JOB.KEY.SKILLS'
                                    defaultMessage='Key Skills'
                                    /></h3>
                                <div className='flex mt-2'>
                                    <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>Azure</div>
                                    <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>GCP</div>
                                    <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>AWS</div>
                                    <div className='border py-1 px-3 rounded-3xl text-sm font-semibold text-gray-600 mr-1'>ES5</div>
                                </div> */}
                                  <div className='w-full flex justify-between border-t-2 items-center mt-4'>
                            <SocialShare title={job?.job_title} fullLink={fullLink}/>
                                  <p onClick={()=> setReportJobPopup(true)} className='text-sm font-bold  text-[#275df5] cursor-pointer'>
  <FormattedMessage 
  id='JOB.REPORT.TITLE'
  defaultMessage='Report this Job'
  />
</p>          </div>
                            </div>
                        </div>

                        <div className='card-2 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <h1 className='font-bold  text-base'>
                                <FormattedMessage
                                id='JOB.ABOUT.COMPANY.TITLE'
                                defaultMessage={'About Company'}
                                />
                            </h1>
                            <ContentWithLoader loading={loader==='AddInfo'} content={<StringLoader />}>
                            <div>{companyAdditional?.about_company}</div>
                            </ContentWithLoader>

                            <h3 className='mt-5 font-bold  text-sm'>
                            <FormattedMessage
                                id='JOB.COMPANY.INFO.TITLE'
                                defaultMessage={'Company Info'}
                                />
                                </h3>
                            <div><span className='font-bold  text-sm'><FormattedMessage
                                id='JOB.COMPANY.ADDRESS.TITLE'
                                defaultMessage={'Address'}
                                />:</span>
                                <span className='text-sm text-gray-700 pl-1'>{job?.job_advanced_id?.reporting_address?.address_line_1} {job?.job_advanced_id?.reporting_address?.address_line_2} {job?.job_advanced_id?.reporting_address?.city} {job?.job_advanced_id?.reporting_address?.state}</span>
                            </div>
                            <div className='flex space-x-2 my-4'>
                                        {companyAdditional?.social_link_fb &&
<Link className="rounded-lg py-0.5 px-2 transition-ease-out duration-25 bg-[#3b5998] hover-[#2d4373] border-[#3b5998] 
                                     hover:bg-[#2d4373] hover:border-[#2d4373] active:bg-[#2d4373] active:border-[#2d4373]" to={companyAdditional?.social_link_fb}>

                                        <div aria-hidden="true" className="inline-block fill-white stroke-none">
                                        <svg className="h-4 w-4 m-0 align-middle inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z">
                                                </path></svg></div>
                                                </Link>}
                                             {companyAdditional?.social_link_twitter &&
                                            <Link className="rounded-lg transition-ease-out duration-25 bg-[#0288D1] hover:bg-[#2795e9] hover:border-[#2795e9] active:bg-[#2795e9] active:border-[#2795e9]" to={companyAdditional?.social_link_twitter}>
                                           
                                        <div aria-hidden="true" className="inline-block fill-white stroke-none">
<svg className='h-8 w-8 m-0 align-middle inline-block' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48">
    <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"/>
    <path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"/></svg>
    
    </div></Link> }
                                </div>   
                        </div>
                    </div>

                   { job &&  <InterestedJobs job={job}/> }
                  </div>
                </div>
            </main>
            <ModelWrapperWithButton showModal={showModal} onCheckModel = {onCheckModel} savetext={'Continue'} Save={ApplyJob}>
                {message && <p>{message}</p>}
               {resume && <div>
                    <p className=''>Continue With</p>
                    <div className='flex space-x-3'>
                            <Checkbox label={resume} checked={applyWithOld===true} value={applyWithOld} onChange={(e:any)=> setapplyWithOld(!applyWithOld)} id={'resumeshow'}></Checkbox>
                    </div>
                  </div>
                  }
{!applyWithOld && <>
                <div className=' mt-5'>Upload new CV</div>
                <div className="flex items-center justify-center w-full mt-2">
    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">Supported Formats: doc, docx, rtf, pdf, upto 2 MB
</p>
        </div>
        <input onChange={HandleResume} id="dropzone-file" type="file" className="hidden" />
    </label>
</div> 
{    newResume &&  
<div className="flex items-center justify-between  w-full my-4 px-5">
  <p className="">{newResume} </p>
</div>
}
</>}
               
            </ModelWrapperWithButton>
            {reportJobPopup &&
            <ModelWrapper showModal={reportJobPopup} rounded="rounded-[10px]"
            background="bg-[#00000099]"
            position="fixed"
            opacity="opacity-100"
            topPosition="top-[10%]"
            rightPosition="inset-0"
            width="w-[50%] mx-auto">
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative  my-6 max-w-3xl w-4/5 h-4/5">
              {/*content*/}
              <div className="border-0 bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300  relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
    
                  <h1 className='font-bold text-3xl mb-4 '>Report this job</h1>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  
                  >
                    <span onClick={()=> setReportJobPopup(false)} className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body  */}
                    {inappropriateContent?.map((item)=> (
                    <Checkbox label={item?.type} id={item?.type} value={item?.type} checked={reportjob?.includes(item?.type)} onChange={(e)=> CheckBoxHandle(e, reportjob, setReportJob)}></Checkbox>
                    ))}
    <div className='flex justify-end'>
    <button onClick={HandleReportJob} className='bg-[#ff39b3] font-bold text-white hover:bg-navy p-2 w-24 rounded-3xl mr-3'><FormattedMessage id='AUTH.GENERAL.SUBMIT_BUTTON'></FormattedMessage></button>

    </div>
                </div></div></div>
            </ModelWrapper>
}
            </ContentWithSpinner>
            <Footer />
        </>
    )
}
