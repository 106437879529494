import React, { useEffect, useState } from "react";

export const useGet = () => {
    const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [pageination, setPageInation] = useState()
  const getData = async (APIFunction)=> {
    setLoader(true)
    try {
          const result = await APIFunction()
        if(result?.success===true) {
          setData(result?.data)
          setPageInation(result?.pagination)
          setLoader(false)
        }
        else {
            setLoader(false)
        }
        }
        catch(e){
          setLoader(false)
        }
  }
  return {getData, data, loader, pageination}
};
