// import {useIntl} from 'react-intl'
// import {PageTitle} from 'sr/layout/master-layout'
import React, {useEffect} from 'react'

// type Props = {}

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    // document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    // return () => {
    //   document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    // }
  }, [])

  return (
    <>
      {/* breadcrumbs */}
      {/* <PageTitle
        breadcrumbs={[
          {title: 'breadcrumb', path: '/dashboard', isActive: true},
          {title: 'breadcrumb2', path: '', isActive: false},
        ]}
        description='Description'
      >
        Dashboard
      </PageTitle> */}

      {/* begin::tabs */}
      <div className='container-fluid border-2 border-blue-400 h-[100vh]'>
        {/* <h1>Welcome to the Demo Poc </h1> */}
      </div>
      {/* end::tabs */}
    </>
  )
}

export default function DashboardWrapper() {
  // for using different languages and changing the text in to other languages
  // const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </>
  )
}
