import React, {useState, ReactNode, useEffect} from "react";
import { TopBanner } from "./TopBanner";
import { toAbsoluteUrl } from "sr/helpers";
import { FormattedMessage } from "react-intl";
import { Input } from "sr/helpers";
import AutoLocation from "sr/helpers/ui-components/AutoLocation";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { supplementalpaydata } from "./Jsonalldata";
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox';
import { LevelBar } from "sr/helpers/ui-components/LevelBar";
import { MainWrapper } from "../../component/MainWrapper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { useFormik } from "formik";
import { AdvanceDetailsSchema } from "sr/utils/schemas/company/jobPost/AdvanceDetailsSchema";
import { addAdvanceDetails } from "sr/utils/api/cleaner/company/jobPost/addAdvanceDetails";
import { getJobs } from "sr/utils/api/cleaner/jobs/getJobs";
import { useValidationErrorHandle } from "sr/helpers/functions/validationErrorHandle";
import { scrollToError } from "sr/helpers/functions/scrollToError";
export const AdvanceDetails = () => {
    const [supplemental, setSupplemental] = useState<any[]>([])
    const [specificAddress, setspecificAddress] = useState("");
    const [applicationDeadline, setApplicationDeadline] = useState()
    const [hireCount, setHireCount] = useState()
    const [timeline, setTimeline] = useState()
    const [autoLocation, setAutoLocation] = useState({})
    const [startDate, setStartDate] = useState(new Date());
  const validationErrorHandle = useValidationErrorHandle()
    const HandleAutoLocation = (data:any)=>  {
      setAutoLocation(data)
    }
    const params = useParams()
    const navigate = useNavigate()
    const {job_id} = params
    const initialValues = {
      job_id:job_id,
      reporting_address: autoLocation,
    hire_count: hireCount,
    recruitment_timeline:timeline,   
    supplemental_pay: supplemental,
    application_deadline: applicationDeadline
    }

    const {errors, submitForm} = useFormik({
      initialValues:initialValues,
      validationSchema:AdvanceDetailsSchema,
      enableReinitialize:true,
      onSubmit:async (values)=> {
        const result = await addAdvanceDetails(values)
        if(result?.success) {
          navigate(`/job-post/review/${result?.data?.job_id}`)
        }
  
      }

    })

    const fetchData = async (jobId:any) => {
      const queryParams = new URLSearchParams()
      queryParams.append('id', jobId?.toString())
      try {
        const result = await getJobs(queryParams )
        if(result?.success===true) {
          console.log(result?.data);          
          const dateObject = result?.data?.start_date;
          setStartDate(dateObject ? new Date(dateObject) : dateObject);
          setApplicationDeadline(dateObject ? new Date(dateObject) : dateObject)   
        }
      }
      catch(error) {
        console.log(error);
      }
    }

  useEffect(()=> {
    if(job_id){
      fetchData(job_id)
    }
  },[job_id]);
   useEffect(() => {
     scrollToError(errors)
    }, [errors]);
  
  return (
<MainWrapper>
 <LevelBar progress={2} total={2}/>
 <div className='bg-white'>
            <div className='pt-10 container mx-auto max-w-3xl'>
 <TopBanner img = {toAbsoluteUrl('/media/img/jobpost.png')} text = {<FormattedMessage id="RECRUITER.POSTJOB.ADVANCEDETAILS.TITLE" defaultMessage="Advanced Details" />}></TopBanner>
     <div className="pt-10">
          <AutoLocation HandleAutoLocation = {HandleAutoLocation} errors={errors?.reporting_address}/>
 <DropDown error={errors.hire_count} onChange={(e)=> setHireCount(e.target.value)} value={hireCount} name='selectjob' label={<FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.NUMBEROFPEOPLE.TITLE" defaultMessage="Number of people you wish to hire for this job?" />
} className="h-11 mt-2 border border-zinc-800 rounded-lg">
                    <option selected><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.NUMBEROFPEOPLE.OPTIONS" defaultMessage="Select an option" /></option>
                    {Array.from({ length: 10 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>{index + 1}</option>
                    ))}
                </DropDown>
            
                <ReactDatePicker 
                error={errors.hire_count}
InputDivClass="mt-2"
onChange={(date)=> setTimeline(date)}
selected={timeline}
inputClass="bg-white border border-zinc-800 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-blue-500 focus:border-[#0078ae] focus:shadow-[0_3px_0px_0px_rgb(37,87,167)] block w-full pl-10 p-2 uppercase"
label={<FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.RECRUITMENTTIMELINE.TITLE" defaultMessage="Position needs filled by" />}>
</ReactDatePicker>
                <div className='pb-4'>
        <p className='pt-8 text-base '><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY" defaultMessage="Supplemental Pay" /></p>
<div className="flex flex-wrap space-x-4">
{supplementalpaydata.map((data, index) => (
<CheckBox
value={data.name.props.defaultMessage}
onChange={(e) => CheckBoxHandle(e, supplemental, setSupplemental)}
checked = {supplemental.includes(data.name.props.defaultMessage)} />
))}
   </div>
   {errors.supplemental_pay ? (
          <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(errors.supplemental_pay as ReactNode)}</p>
        ) : (
          ''
        )}

         </div>    


<ReactDatePicker 
InputDivClass="mt-2"
onChange={(date)=> setApplicationDeadline(date)}
error={errors.application_deadline}
selected={applicationDeadline}
minDate={startDate ? new Date(startDate): null}
inputClass="bg-white border border-zinc-800 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-blue-500 focus:border-[#0078ae] focus:shadow-[0_3px_0px_0px_rgb(37,87,167)] block w-full pl-10 p-2 uppercase"
label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.APPLICATION.DEADLINE" defaultMessage="Accepting applications until" />}></ReactDatePicker>

                </div>
                <div className='w-full mt-4 text-right flex justify-between items-center pb-10'>
      <Link  to={`/job-post/add-basic-details/${job_id}`} className='rounded-lg font-bold bg-white border  border-neutral-200 flex items-center justify-center p-2.5
                                text-[#0078ae] w-28 cursor-pointer hover:bg-neutral-200 hover:border-[#0078ae] space-x-2'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
             <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg><FormattedMessage id="RECRUITER.POSTJOB.REVIEW.BACK.BUTTON" defaultMessage="Back" /> </Link>
            <div className='flex space-x-2'> 
            {/* <button className='rounded-lg font-bold bg-white p-2.5
                                text-[#0078ae] w-28 cursor-pointer hover:bg-neutral-200 hover:border-[#0078ae] space-x-2'>
         <FormattedMessage id="RECRUITER.POSTJOB.REVIEW.PREVIEW.BUTTON" defaultMessage="Preview" /> </button> */}
            <button onClick={submitForm}  type='submit'className='rounded-lg font-bold bg-[#ff39b3] font-bold  p-2.5  text-white  w-32 cursor-pointer hover:bg-navy' ><FormattedMessage id="RECRUITER.POSTJOB.REVIEW.PREVIEW.CONFIRM.BUTTON" defaultMessage="Confirm" />
                                    </button></div></div>
    
</div></div>
</MainWrapper>);
};
