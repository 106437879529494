import { toast } from "react-toastify";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export const verifyOTP = async (postData:any) => {
    try {
        return await post<ApiResponse<[]>>('/auth/verifyotp', postData).then((res)=> {
            if(res.success === true) {
                SuccessMessage('API.SUCCESS.OTP.VERIFY')
                return res
            }
            else {
                return null
            }
        })
    }
    catch(e:any) {
     HandleApiError(e)
    }
}