import React, { useEffect, useState } from "react";
import { MainWrapper } from "../../component/MainWrapper";
import { TopBar } from "../../component/TopBar";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { removeContractorLocation } from "sr/utils/api/company/workorder/removeContractorLocation";
import { assignContractorLocation } from "sr/utils/api/company/workorder/assignContractorLocation";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "sr/redux/store";
import { useAuth } from "app/pages/module/auth";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { fetchCustomerLocations } from "sr/helpers/apiFunctions/fetchCustomerLocations";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";

export const ContractorDetails = () => {
    const [loader, setLoader] = useState(true)
    const [data, setdata] = useState<any>([])
    const [id, setId] = useState<any>()
    const [customerLocations, setCustomerLocations] = useState([])
    const [userId, setuserId] = useState()
    const dispatch = useDispatch<AppDispatch>()
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
    const [customerLocationId, setCustomerLocationId] = useState<string>()
    const [buttonLoader, setButtonLoader] = useState(false)
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setuserId(user_id)
          },[])
          const {userType} = useAuth()
          const params = useParams()
          useEffect(()=> {
                const {id} = params
                setId(id)
          },[params])
          useEffect(()=> {
            if(userId && userType==='supervisor') {
              if(!supervisorData){
                dispatch(fetchSupervisor({id:userId}))
              }
            }
    },[userId, userType, dispatch])
    useEffect(()=> {
      const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
      setCustomerLocationId(id?.join());

    },[supervisorData])
        const loadCustomerLocation = async ()=> {
            await fetchCustomerLocations({userType:userType, userId:userId, customerLocationId:customerLocationId, setCustomerLocations:setCustomerLocations, setLoader:setLoader})
        }
        useEffect(()=> {
            if(userId && userType) {
              if(userType==='supervisor'){
                if(customerLocationId){
                  loadCustomerLocation()
                }
              }
              else {
                loadCustomerLocation()
              }
            }
      },[userId, customerLocationId])
        
      const loadContractor = async (id)=> {
        await fetchContractor({userType:userType, id:id, setData:setdata, setLoader:setLoader})
  }
      useEffect(()=> {
         
          if(id) {
              loadContractor(id)
          }
      },[id])
    const AssignLocation = async (customerLocationid)=> {
      setButtonLoader(true)
        const payLoad = {
          contractor_id:id,
          customer_location_ids:[customerLocationid]
        }
        const res = await assignContractorLocation(payLoad)
        if(res?.success===true) {
          setButtonLoader(false)
                loadCustomerLocation()
                loadContractor(id)
        }
        
      }
      const checkAssigned = (customerLocationid)=> {
          return  data?.customer_location_ids?.some((item) => item?._id===customerLocationid)
      }
      const handleRemoveLocation = async (customerLocationid)=> {
        setButtonLoader(true)
            const payLoad = {
              contractor_id:id,
              customer_location_ids:customerLocationid
            }
            const res = await removeContractorLocation(payLoad)
            if(res.success===true) {
              setButtonLoader(false)
              loadCustomerLocation()
              loadContractor(id)
            }
      }
  return (
    <MainWrapper >
      {buttonLoader && <LoaderFullScreen />}
    <div className='py-3'>
    <TopBar title = {<FormattedMessage
          id='RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.TITLE'
          defaultMessage='Contractor'
        />} 
        btntitle = {<FormattedMessage
          id='RECRUITER.SUPERVISOR.ALL.CONTRACTOR'
          defaultMessage='All Contractor'
        />} btntitlelink = '/contractor' postButton={<Link  to="/add-contractor" className='rounded-lg font-bold bg-[#2557A7] px-5 py-3  text-white cursor-pointer hover:bg-navy' ><FormattedMessage id="RECRUITER.WORK.ORDER.CONTRACTOR.ADD.BTN" defaultMessage="Add Contractor" />
                                </Link>}  className='px-10'></TopBar>
                                <div className='pl-10'>
                                <div className='flex items-center space-x-4 mb-5'>
<Link to={`/contractor`}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#2557A7] w-5 h-5 cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
</Link>
<div>
    <h2 className='font-satoshiBold'>{data?.first_name} {data?.last_name}</h2>
    <Link to={`/contractor`}>
      <p className='text-base font-satoshiBold text-[#2557A7]'> <FormattedMessage id="RECRUITER.SUPERVISOR.BACKBUTTON" defaultMessage="Back" /></p>
      </Link>
</div>

</div> 
<ContentWithSpinner loader={loader}>

<table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
          <tr>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
              <FormattedMessage id="INPUT.ADDRESS1.TITLE" defaultMessage="Address 1" />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
              <FormattedMessage id="INPUT.ADDRESS2.TITLE" defaultMessage="Address 2" />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.LOCATION.TITLE" defaultMessage="City" />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.STATE.TITLE" defaultMessage="State" />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.ZIPCODE.TITLE" defaultMessage="Zip Code" />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.COUNTRY.TITLE" defaultMessage="Country" />
              </th>
              <th scope="col" className="px-6 py-4 text-base font-satoshiBold">
              <FormattedMessage id="RECRUITER.CUSTOMER.TAB.COUNTRY.ACTION.TITLE" defaultMessage="Actions" />
              </th>
          </tr>
        </thead>
        <tbody>
          {customerLocations?.map((item, index)=> {
            return (
              <tr key={index} className="bg-white border-b  hover:bg-gray-50">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900">                
                    <div className='text-base'>{item?.address?.address_line_1}</div>                    
                </th>
                <td className="px-6 py-4">{item?.address?.address_line_2}</td>
                <td className="px-6 py-4">{item?.address?.city}</td>
                <td className="px-6 py-4">{item?.address?.state}</td>
                <td className="px-6 py-4">{item?.address?.postal}</td>
                <td className="px-6 py-4">{item?.address?.country}</td>
                <td className="px-6 py-4 whitespace-nowrap">

               
{checkAssigned(item?.id) ? 
<div className="flex space-x-3">
  <button className='rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy'>
  Assigned
</button>
<button onClick={()=> handleRemoveLocation(item?.id)} className='rounded-lg font-bold text-white px-5 py-2  bg-red-500  border-2 cursor-pointer hover:text-white hover:border-white '>
UnAssign
</button>
</div>
:
   <button onClick={()=> AssignLocation(item?.id)} className='rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy'>
  Assign Location
</button>
          }
                
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

  {/* <div className="flex justify-center mt-3">
   <button onClick={()=> AssignLocation()} className='rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy'>
  Assign Location
</button>
</div> */}
</ContentWithSpinner>
    </div>
                                  </div>

        </MainWrapper>
  );
};
