import React, {ReactNode, useEffect, useRef, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { Filter } from './component/Filter'
import { Checkbox, index } from 'sr/helpers'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { WhiteBtn } from './component/WhiteBtn'
import { TableRow } from './component/TableRow'
import { Link } from 'react-router-dom'
import { WorkOrderPopup } from './component/WordOrderPopup'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { updateWorkorder } from 'sr/utils/api/company/workorder/updateWorkorder'
import { ReactDatePicker } from 'sr/helpers/ui-components/ReactDatePicker'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
import { useAuth } from 'app/pages/module/auth'
import { fetchCustomerData } from 'sr/helpers/apiFunctions/fetchCustomerData'
import { Pagination } from 'app/pages/component/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSupervisor } from 'sr/redux/reducers/supervisor'
import { AppDispatch } from 'sr/redux/store'
import { fetchWorkOrder } from 'sr/helpers/apiFunctions/WorkOrder/fetchWorkOrder'
import { StatusTab } from './component/StatusTab'
import { fetchCustomerLocations } from 'sr/helpers/apiFunctions/fetchCustomerLocations'
import { fetchWorkOrderModel } from 'sr/helpers/apiFunctions/WorkOrder/fetchWorkOrderModel'
import { Empty } from '../candidates/component/Empty'
import { ModelWrapper } from '../component/ModelWrapper'
import { DeleteConfirmation } from 'app/pages/component/DeleteConfirmation'
import { deleteRecWorkOrder } from 'sr/utils/api/company/workorder/deleteRecWorkOrder'
import { AddressDisplay } from 'sr/helpers/functions/AddressDisplay'
export const WorkOrder = () => {
    const [customer, SetCustomer] = useState('')
    const [location, setLocation] = useState('')
    const [customerLocations, setCustomerLocations] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [status, setStatus] = useState<any>(['publish','draft'])
    const [userId, setUserId] = useState(null)
    const [data, setData] = useState([])
    const [loader,setLoader] = useState(true)
    const [editId, setEditId] = useState(null)
    const [limit, setLimit] = useState<number>(50)
    const [page, setPage] = useState<number>(1)
    const [totalPage, settotalPage] = useState(0)
    const [customerLocationId, setCustomerLocationId] = useState<string>()
    const {userType} = useAuth()
    const [statusCount, setStatusCount] = useState<any>()
    const [customers, setCustomers] = useState<any>()
    const dispatch = useDispatch<AppDispatch>();
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
    const [Filterdata, setFilterdata] = useState<any>()
    const [isCompletionDate, setIsCompletionDate] = useState(false)
    const [type, setType] = useState<string>('one time')
    const [active, setActive] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const [deleteId, setDeleteId] = useState<string>('')
    const [customDateFilterError, setCustomDateFilterError] = useState(null)
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserId(user_id)
    },[])
    useEffect(()=> {
console.log(showDelete);

    },[showDelete])

    //If supervisordata is not available 
      useEffect(()=> {
              if(userId && userType==='supervisor') {
                if(!supervisorData){
                  dispatch(fetchSupervisor({id:userId}))
                }
              }
      },[userId, userType, dispatch])
      const fetchAllLocation = async (companyId)=> {
        const data= await fetchCustomerLocations({userType:userType, companyId:companyId})
        setCustomerLocationId(data?.map((item)=> item?.id)?.join())
      }
      // if assigned location to supervisor then use supervisor location else use all customer locations
      useEffect(()=> {
        if(supervisorData){
        const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
        if(id?.length>0){
        setCustomerLocationId(id?.join());
        }
        else {
        fetchAllLocation(supervisorData?.company_id?._id)
      }
      }
        
       },[supervisorData])
  const loadWorkOrder = async ()=> {
      await fetchWorkOrder(userType, userId, page, limit, startDate, endDate, status, location, customerLocationId, customer, setLoader, setData, settotalPage, setStatusCount)
  }
  const loadWorkOrderModel = async ()=> {
    await fetchWorkOrderModel({userType:userType, userId:userId, setLoader:setLoader, setData:setData, customerLocationId:customerLocationId})
  }

const loadCustomerData = async ()=> {
  await fetchCustomerData({
    userId:userId, userType:userType, page:page, limit:limit, customerLocationId:customerLocationId, setData:setCustomers
 } );
}

  useEffect(()=> {
    if(userId && userType) {
      if(userType==='supervisor'){
        if(customerLocationId){
          if(type==='one time'){
      loadWorkOrder()
          }
          if(type==='recurring'){
            loadWorkOrderModel()
          }
      }
    }
      else {
        if(type==='one time'){
          loadWorkOrder()
              }
              if(type==='recurring'){
                loadWorkOrderModel()
              }
      }
    }

},[userId, status, customerLocationId, customer, location, limit, page, type, (startDate&& endDate)])
useEffect(()=> {
  if(userId && userType) {
    if(userType==='supervisor'){
      if(customerLocationId){
  loadCustomerData()
  }
}
else {
  loadCustomerData()
}}
},[userId, customerLocationId])
const processedData = typeof customers === 'object' && !Array.isArray(customers) ? [customers] : customers;
const loadCustomerLocation = async ()=> {
  await fetchCustomerLocations(userType==='supervisor' ? {customerLocationId:customerLocationId,  setCustomerLocations:setCustomerLocations}: {customerLocationId:customerLocationId,  setCustomerLocations:setCustomerLocations, companyId:userId})
}
useEffect(()=> {
 
  if(userId && userType) {
    if(userType==='supervisor'){
      if(customerLocationId){
    loadCustomerLocation()
  }
    }
  else {
    loadCustomerLocation()
  }
  }
  
  },[userId, userType, customerLocationId])
  useEffect(()=> {
    if(customer){
      loadCustomerLocation()
    }
  },[customer])
const intl = useIntl()
      const HandleModelByProps = (data) => {
        if(data===false) {
          setEditId('')
        }
        if(data===true){
          setEditId('')
          loadWorkOrder()
        }
        setShowDelete(false)
        setDeleteId('')
       
       }
       const HandleDelete = async (id)=> {
          const formData = {
            id:id,
            status:'deleted'
          }
          let res;
          if(type==='one time') {
           res = await updateWorkorder(formData)
          }
          else {
            res = await deleteRecWorkOrder(id)
          }
          if(res?.success===true) {
            SuccessMessage('API.SUCCESS.WORKORDER.DELETE')
            setDeleteId('')
            setShowDelete(false)
            loadWorkOrder()
          }
       }
       useEffect(()=> {
        if(Filterdata){
            setIsCompletionDate(Filterdata?.some((item)=> item?.completion_date))
        }
       },[Filterdata])

       useEffect(()=>{
if(type==='recurring') {
  setFilterdata(data)
}
       },[data])
       useEffect(()=>{
          if(startDate) {
            console.log('rrr');
            
            setCustomDateFilterError({end:'INPUT.VALIDATION.WORKORDER.FILTER.ERROR'})
          }
       },[startDate])
       useEffect(()=>{
        if(endDate && startDate){
        setCustomDateFilterError(null)
        if(!startDate){
          setCustomDateFilterError({start:'INPUT.VALIDATION.WORKORDER.START.FILTER.ERROR'})
        }
        }
       },[endDate, startDate])
       useEffect(()=>{
console.log(location);

       },[location])
  return (
   <MainWrapper>
    <ContentWithSpinner loader={loader} height='h-screen'>
    <div className="py-3">
        <div className='mb-2'>
    <h1 className='text-2xl  border-r border-neutral-200 pr-4'><FormattedMessage id="RECRUITER.WORKORDER.TITLE" defaultMessage="Work Order Management" /></h1>
    
   </div>
   <div className='flex space-x-2 items-center'>
   <Filter  name={status?.includes('publish') && status?.includes('draft') ? 'All' : status?.includes('publish') ? <FormattedMessage id='RECRUITER.WORKORDER.FILTER.TYPE.PUBLISH'></FormattedMessage>:status?.includes('draft') ? <FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.ASSIGNED.DRAFT"></FormattedMessage> :'Select' } label={<FormattedMessage id="MYJOB.FILTER.DROPDOWN.STATUS" defaultMessage='Status' /> }>
      <Checkbox id='publish' name='publish' onChange={(e)=> CheckBoxHandle(e, status, setStatus)} checked={status?.includes('publish')} value='publish' label={<FormattedMessage id='RECRUITER.WORKORDER.FILTER.TYPE.PUBLISH'></FormattedMessage>}></Checkbox>
      <Checkbox id='draft' name='draft' onChange={(e)=> CheckBoxHandle(e, status, setStatus)} checked={status?.includes('draft')} value='draft' label={<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.ASSIGNED.DRAFT"></FormattedMessage>}></Checkbox>

   </Filter>
   <DropDown labelClass='xl:text-base lg:text-sm' value={customer} label={intl.formatMessage({ 
    id: 'RECRUITER.WORKORDER.FILTER.CUSTOMER.TITLE',
    defaultMessage: 'Customer',
  })}

 dropdowndiv='mt-0 ' onChange={(e) => SetCustomer(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="RECRUITER.WORKORDER.FILTER.SELECTCUSTOMER" defaultMessage="Select Customer"/></option>
  {processedData?.map((item, index)=> (
    <option key={index} value={item?.id}>{item?.name}</option>
  ))}
  </DropDown>
  <DropDown labelClass='xl:text-base lg:text-sm' value={location} label={intl.formatMessage({
    id: 'RECRUITER.WORKORDER.FILTER.LOCATION',
    defaultMessage: 'Location',
  })}
 dropdowndiv='mt-0 w-40 relative group' onChange={(e) => setLocation(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION" defaultMessage="Select Location" /></option>
    {(Array.isArray(customerLocations) 
  ? customerLocations 
  : [customerLocations]
)?.map((item, index)=> (
    <option key={index} value={item?.id}>{AddressDisplay(item?.address)}</option>
  ))
}
  </DropDown>
  <ReactDatePicker 
    label={<FormattedMessage id="RECRUITER.WORKORDER.FILTER.SCHEDULED.START.DATE" defaultMessage="Scheduled Start date" />} 
    inputClass="rounded-lg" 
    labelClass="mt-0 text-black text-center xl:text-base lg:text-sm" 
    InputDivClass={` ${customDateFilterError?.start ? 'mt-10':'mt-0'} w-40`} 
    placeholder="Enter company joining date"
    isDefaultSelected={false}
    onChange={(date)=> setStartDate(date)}
    selected={startDate}
    error={customDateFilterError?.start}>
  </ReactDatePicker>
  <ReactDatePicker
    label={<FormattedMessage id="RECRUITER.WORKORDER.FILTER.SCHEDULED.END.DATE" defaultMessage="Scheduled End date" />} 
    inputClass="rounded-lg" 
    labelClass="mt-0 text-black text-center xl:text-base lg:text-sm" 
    InputDivClass={` ${customDateFilterError?.end ? 'mt-10':'mt-0'} w-40`}
    placeholder="Enter company joining date"
    isDefaultSelected={false}
    onChange={(date)=> setEndDate(date)}
    selected={endDate}
    error = {customDateFilterError?.end}>
  </ReactDatePicker>
  <DropDown className='rounded-lg' labelClass='xl:text-base lg:text-sm' dropdowndiv='mt-0' label={<FormattedMessage id='MYJOB.FILTER.DROPDOWN.TYPE.TITLE' defaultMessage='Work Order Type' />}  value={type} onChange={(e)=> setType(e.target.value)}>
  <option value={'one time'}>One Time</option>
  <option value={'recurring'}>Recurring</option>
   </DropDown>
 </div>
 <div className={`flex items-center w-full max-w-full ${type==='recurring' ? 'justify-end': 'justify-between'} mt-8 space-x-4`}>
  {type==='one time' &&
 <StatusTab className='xl:max-w-[80%] lg:max-w-[75%] md:max-w-[70%]' data={data} setFilterdata={setFilterdata} statusCount={statusCount} setActive={setActive}/>
  }
 {(userType === 'company' || (userType === 'supervisor' && supervisorData?.access?.work_order_management?.add_work_order)) && (
 <div className='xl:max-w-[20%] lg:max-w-[25%]'>
 <Link to='/schedule-work-order'>
    <WhiteBtn
      svg={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      }
      title={
        <FormattedMessage
          id="RECRUITER.WORKORDER.ADD.WORK.ORDER"
          defaultMessage="Add Work Order"
        />
      }
    />
  </Link>
  </div>
)}

</div>
 
 <div className="overflow-x-auto mt-4 rounded-[20px]">
    <table className="w-full text-left condidate-table">
        <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
            <tr>
            <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.WORKORDER.TITLE" defaultMessage="Title" />
                </th>
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.CUSTOMER.NAME.TITLE" defaultMessage="Customer Name" />
                </th>
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.LOCATION" defaultMessage="Location" />
                </th>
{type ==='one time' && 
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.SCHEDULE.DATE" defaultMessage="Schedule Date" />
                </th>
}
                {isCompletionDate &&
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.COMPLETION.DATE" defaultMessage="Completion Date" />
                </th>
}

                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.RATE.DATE" defaultMessage="Amount" />
                </th>
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME.STATUS" defaultMessage="Status" />
                </th>
                {((type ==='recurring') || (type==='one time' && (active==='scheduled'|| active==='in progress'))) &&
                <th scope="col" className="px-6 py-4 text-base ">
                <FormattedMessage id="WORKORDER.TABLE.COL.NAME" defaultMessage="Action" />
                </th>
}
            </tr>
        </thead>
        <tbody>
          {Filterdata?.length>0 ?
          
          Filterdata?.map((item, index)=> (
        <TableRow 
        active={active}
        key={index} 
        type={type}
         item={item} 
         setEditId={setEditId}
         setShowDelete={setShowDelete}
         setDeleteId={setDeleteId}
           isCompletionDate={isCompletionDate}
           editPermission={supervisorData?.access?.work_order_management?.update_work_order}
            rejectPermission ={supervisorData?.access?.work_order_management?.delete_work_order}
           />
))
:
<tr>
<td colSpan={7} className="text-center">
  <Empty text="No records to display" className='py-14'/>
</td>
</tr>
}
          
        </tbody>
    </table>
    {Filterdata?.length>0 &&
     <div className='flex justify-center'>
    <p className=' mt-3'>
      {/* Workorders:{data?.length} of {limit}  */}
      Page:{page} of {totalPage}</p>
  </div>
}
  {totalPage>1 &&
    <Pagination page={page} totalPage={totalPage} setPage={setPage} />
  }
</div>
    </div>
    </ContentWithSpinner>
    {editId ?
    <WorkOrderPopup page={type==='one time' ? 'workorder' : 'Workordermodel'} editId={editId} HandleModelByProps={HandleModelByProps}/>: null}
    {showDelete &&
    <ModelWrapper rounded="rounded-[10px]"
    background="bg-[#00000099]"
    position="fixed"
    opacity="opacity-100"
    topPosition="top-[10%]"
    rightPosition="inset-0"
    width="w-[50%] mx-auto" showModal={showDelete} 
    HandleModelByProps={HandleModelByProps}>
      <DeleteConfirmation  onCancel={()=> HandleModelByProps(false)} onDelete={()=> HandleDelete(deleteId)}/>
    </ModelWrapper>
    }
   </MainWrapper>
  )
}
