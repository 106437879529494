import React, { useState, useEffect, ForwardRefRenderFunction, forwardRef } from "react";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { Input } from "sr/helpers";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { CleanerComponentProps } from "../CleanerComponentProps";
import { getCleaner } from "sr/utils/api/cleaner/getCleaner";
import { updateCleaner } from "sr/utils/api/cleaner/updateCleaner";
const CleanerPersonalDetails: ForwardRefRenderFunction<any, CleanerComponentProps> = (
    { HandleStepper = null, page = '', type = '', dataAddedHandle=null, id='' },
    ref
  ) => {
    const [username, setUsername] = useState()
    const [mobileNo, setMobileNo] = useState()
    const [firstName, setfirstName] = useState()
    const [lastName, setlastName] = useState()
    const [email, setEmail] = useState()
    const [dateOfBirth, setDateOfBirth] = useState()
    const [loader, setLoader] = useState(false)
    const [cleanerData, setCleanerData] = useState<any>()
    const intl = useIntl();
    interface FormValues {
        id:string,
        username: string;
        mobile_number: string;
        first_name: string;
        last_name: string;
        email: string;
        date_of_birth: string;
      }
      
      const initialValues:FormValues = {
        id:id,
        username:username,
        mobile_number:mobileNo,
        first_name:firstName,
        last_name:lastName,
        email:email,
        date_of_birth:dateOfBirth
      }
      useEffect(()=> {
        const fetchData = async () => {
          setLoader(true)
          try {
            const cleanerData = await getCleaner(undefined, id)
            if(cleanerData?.success===true){
              setCleanerData(cleanerData?.data)
              setUsername(cleanerData?.data?.username)
              setfirstName(cleanerData?.data?.first_name)
              setlastName(cleanerData?.data?.last_name)
              setMobileNo(cleanerData?.data?.mobile_number)
              setDateOfBirth(cleanerData?.data?.date_of_birth)
              setEmail(cleanerData?.data?.email)
              setLoader(false)
            }
          }
          catch(e:any){
            console.log(e);
            
          }
        }
        fetchData()
    },[])
        const {errors, handleSubmit, submitForm} = useFormik({
            initialValues:initialValues,
            enableReinitialize:true,
            onSubmit:async (values)=> {
                const result = await updateCleaner(values)
                if(result?.success===true) {
                    dataAddedHandle(true)
                }
            }
        })
        React.useImperativeHandle(ref, () => ({
            submitForm,
          }));
  return (
    <ContentWithSpinner loader={loader}>
            <form className='mt-5' onSubmit={handleSubmit}>
            <Input
              onChange={(e)=> setUsername(e.target.value)}
              value={username}
                    name="username"
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME",
                      defaultMessage: "Username",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME.PLACEHOLDER",
                      defaultMessage: "Enter your username",
                    })}
                    type={"text"}
                    error={errors.username}
                  ></Input>
                <Input
                onChange={(e)=> setMobileNo(e.target.value)}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.MOBILENO",
                    defaultMessage: "Mobile Number",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.LASTMOBILENONAME.PLACEHOLDER",
                    defaultMessage: "Enter your Mobile Number",
                  })}
                  type={"tel"}
                  name="mobile_number"
                  error={errors.mobile_number}
                  value={InputNumberFormat(mobileNo)}
                ></Input>
                <Input
                onChange={(e)=> setfirstName(e.target.value)}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME",
                    defaultMessage: "First Name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME.PLACEHOLDER",
                    defaultMessage: "Enter your first name",
                  })}
                  type={"text"}
                  name="first_name"
                  value={firstName}
                  error={errors.first_name}
                ></Input>
                <Input
                value={lastName}
                onChange={(e)=>setlastName(e.target.value)}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.LASTNAME",
                    defaultMessage: "Last Name",
                  })}
                  placeholder={"Enter your last name"}
                  type={"text"}
                  name="last_name"
                  error={errors.last_name}
                ></Input>
                <Input
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.ADDRESS",
                    defaultMessage: "Email Address",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.PLACEHOLDER",
                    defaultMessage: "Enter your email id",
                  })}
                  type={"email"}
                  name="email"
                  error={errors.email}
                ></Input>
                 <ReactDatePicker
          onChange={(date)=> setDateOfBirth(date)}
          selected={dateOfBirth}
          label={intl.formatMessage({
            id: "AUTH.INPUT.DATEOFBIRTH",
            defaultMessage: "Date of Birth",
          })}
          error={errors.date_of_birth}
        ></ReactDatePicker>
            </form>
            </ContentWithSpinner>
    );
};
export default forwardRef(CleanerPersonalDetails);