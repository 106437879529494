import ContentLoader from "react-content-loader";
import React from "react";

export const JobLoaderCard = () => {
  return ( 
    <div>
   {[{},{},{}].map(()=> (
    <div className="mb-5">
  <ContentLoader 
    speed={2}
    width={860}
    height={198}
    viewBox="0 0 860 198"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="-590" y="17" rx="3" ry="3" width="88" height="6" /> 
    <rect x="-161" y="4" rx="3" ry="3" width="52" height="9" /> 
    <rect x="-607" y="61" rx="3" ry="3" width="410" height="9" /> 
    <rect x="-821" y="118" rx="3" ry="3" width="380" height="9" /> 
    <rect x="-647" y="90" rx="3" ry="3" width="178" height="6" /> 
    <circle cx="-219" cy="13" r="20" /> 
    <rect x="-564" y="-18" rx="3" ry="3" width="88" height="6" /> 
    <rect x="-266" y="202" rx="3" ry="3" width="131" height="22" /> 
    <rect x="-580" y="-34" rx="0" ry="0" width="4" height="40" /> 
    <rect x="-524" y="22" rx="0" ry="0" width="157" height="3" /> 
    <rect x="-460" y="73" rx="0" ry="0" width="15" height="46" /> 
    <rect x="-541" y="79" rx="0" ry="0" width="161" height="44" /> 
    <rect x="-530" y="40" rx="0" ry="0" width="165" height="55" /> 
    <rect x="-488" y="92" rx="0" ry="0" width="200" height="63" /> 
    <rect x="158" y="102" rx="0" ry="0" width="2" height="1" /> 
    <rect x="-715" y="125" rx="0" ry="0" width="557" height="60" /> 
    <rect x="-531" y="97" rx="0" ry="0" width="476" height="30" /> 
    <rect x="-594" y="182" rx="0" ry="0" width="373" height="128" /> 
    <rect x="209" y="185" rx="0" ry="0" width="0" height="21" /> 
    <rect x="-563" y="123" rx="0" ry="0" width="84" height="2" /> 
    <rect x="-637" y="124" rx="0" ry="0" width="172" height="4" /> 
    <rect x="-566" y="133" rx="0" ry="0" width="141" height="12" /> 
    <rect x="-571" y="120" rx="0" ry="0" width="60" height="5" /> 
    <rect x="24" y="147" rx="0" ry="0" width="2" height="12" /> 
    <rect x="-598" y="191" rx="0" ry="0" width="132" height="11" /> 
    <rect x="-558" y="139" rx="0" ry="0" width="186" height="7" /> 
    <rect x="-647" y="187" rx="0" ry="0" width="498" height="30" /> 
    <rect x="-415" y="158" rx="0" ry="0" width="72" height="8" /> 
    <rect x="-635" y="159" rx="0" ry="0" width="481" height="32" /> 
    <rect x="124" y="187" rx="0" ry="0" width="2" height="26" /> 
    <rect x="-238" y="170" rx="0" ry="0" width="67" height="23" /> 
    <rect x="-6" y="0" rx="20" ry="20" width="860" height="195" />
  </ContentLoader>
  </div>
  ))}
 </div> );
};
