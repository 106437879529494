import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "sr/helpers";
export const TaskManageButton = ({approveOnClick=null, rejectOnClick=null, type=null, checkSupervisorStatus=null, CheckContractorComplete=null}) => {
  return (
  <div className="flex justify-center my-5">
{
    checkSupervisorStatus==='approved' && 
  <Button text={<FormattedMessage id= "SUPERVISOR.PENDING.JOB.APPROVED.BTN" defaultMessage="Approved" />} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
}
{
   checkSupervisorStatus==='pending' &&
    <>
    <Button onClick={approveOnClick} text={<FormattedMessage id="SUPERVISOR.PENDING.JOB.APPROVE.BTN" defaultMessage="Approve" />} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
    <Button onClick={rejectOnClick} text={<FormattedMessage id="SUPERVISOR.PENDING.JOB.REJECT.BTN" defaultMessage="Reject" />} className={'bg-red-600	mx-5  text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>   
    </>
}
{
    checkSupervisorStatus==='rejected' &&
   <Button text={<FormattedMessage id="SUPERVISOR.PENDING.JOB.REJECTED.BTN" defaultMessage="Rejected" />} className={'bg-red-600	mx-5 text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
   }
   {
    type==='workorder' && 
    <>
    <Button onClick={approveOnClick} text={<FormattedMessage id="SUPERVISOR.PENDING.JOB.APPROVE.BTN" defaultMessage="Approve" />} className={'bg-[#ff39b3] font-bold text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>
    <Button onClick={rejectOnClick} text={<FormattedMessage id="SUPERVISOR.PENDING.JOB.REJECT.BTN" defaultMessage="Reject" />} className={'bg-red-600	mx-5  text-white capitalize '} hoverbg={'bg-[#ff39b3] font-bold '}></Button>   
    </>
   }

      </div>);
};
