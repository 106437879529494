import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "sr/helpers";
import { FormattedMessage } from "react-intl";
export const Current = () => {
  return (<>
    <div className="bg-white border border-neutral-300 rounded-lg my-3">
    <div className="flex justify-between px-3 items-center py-3">
      <p className="text-xl ">Electrical help</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>
    <div className="bg-gray-50 border border-gray-border">
      <div className="pl-10 py-2">
<FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.CURRENT.STATUS.TEXT" defaultMessage="Taskers usually contact you to chat about your task in" />      
        <b> <FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.CURRENT.STATUS.TEXT.NEXT" defaultMessage="less than 1 hour." /></b>
      </div>
    </div>
    <div className="p-3">
      <div className="flex justify-between">
        <div className="flex space-x-3 items-center">
          <img
            className="w-10 h-10 rounded-full"
            src={toAbsoluteUrl("/media/img/news-2.jpg")}
          ></img>
          <div className="text-lg ">Alex M.</div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
          />
        </svg>
      </div>
      <ul className="pt-3">
        <li className="mb-3">
          <div className="flex space-x-2 items-center font-semibold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              ></path>
            </svg>
            <div>Friday, Nov 24 2023</div>
          </div>
        </li>
        <li className="mb-3">
          <div className="flex space-x-2 items-center font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

            <div>15:00</div>
          </div>
        </li>
      </ul>
      <Link to={''} className="cursor-pointer text-[#0078ae] underline font-bold"><FormattedMessage id="INDIVIDUAL.VIEW.MORE.BUTTON" defaultMessage="View More Details" /></Link>
    </div>
  </div>
   <div className="my-8 flex flex-col text-center space-y-4">
   <h1 className="text-2xl "><FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.SOMETHING.ELSE" defaultMessage="Have something else on your to-do list ?" /></h1>
   <div> <FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.TEXT" defaultMessage="Book your next task or manage future to-dos with Janitorial" /></div>
   <div className="flex justify-center">
   <div className="text-white cursor-pointer  bg-[#ff39b3] font-bold py-2 px-6 rounded-lg my-3"><FormattedMessage id="INDIVIDUAL.CONFIRM.BOOK.CHECK_IT.BUTTON" defaultMessage="Check it Off Your List" /></div>
</div>
</div>
</>
  );};
