import React, {useEffect, useState} from 'react'
import { RadioInput } from 'sr/helpers/ui-components/RadioInput';
import { InputSearch } from 'sr/helpers/InputSearch';
import { HideShow } from './HideShow';
import { TextHideShow } from './TextHideShow';
import { FormattedMessage, useIntl } from 'react-intl';
import { businessTypes } from 'sr/i18n/messages/setting';
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { usaCities } from 'sr/i18n/messages/setting';
import { jobTypes } from 'app/pages/company/jobpost/component/Jsonalldata';
import { employment } from 'app/pages/company/jobpost/component/Jsonalldata';
import { payTypes } from 'sr/i18n/messages/setting';
export const Filter = ({HandleFilterData}) => {
  const [businessType, setBusinessType] = useState([])
  const [location, setLocation] = useState([])
  const [jobType, setJobType] = useState([])
  const [empType, setEmpType] = useState([])

  const [payType, setPayType] = useState([])
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const filter = {"$and":[{"job_advanced_id.reporting_address.city":{"$in":["Kota"]}}, {"job_type":{"$in":["Cleaner"]}}]}
  const toggleShow = () =>{
        setShow(!show)
    }
    useEffect(()=> {
  const filter = {
    business_type:businessType,
    location:location,
    job_type:jobType,
    employment_type:empType, 
    payType:payType,
    createdAt:{
      from:from,
      to:to
    }
  }
  HandleFilterData(filter)
    },[businessType, location, jobType, empType, payType, from, to])

  return (
    <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
    <div className='font-bold py-4 border-b-[1px]	'>
      <FormattedMessage 
      id='CLEANER.JOBS.FILTER.TITLE'
      defaultMessage='All Filter'
      />
    </div>
   <HideShow viewmore={true} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.BUSINESSTYPE', defaultMessage:'Business Type'})} data={businessTypes} newData={businessType}  onChange={(e:any) => CheckBoxHandle(e, businessType, setBusinessType )} />
   <HideShow viewmore={true} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.LOCATION', defaultMessage:'Location'})} searchPlaceholder={intl.formatMessage({id:'CLEANER.JOBS.FILTER.LOCATION.SEARCH.PLACEHOLDER', defaultMessage:'Search Location'})} data={usaCities} newData={location} onChange={(e:any)=> CheckBoxHandle(e, location, setLocation)}/>
   <HideShow viewmore={true} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.EMPLOYMENTTYPE', defaultMessage:'Employment Type'})} data={employment} newData={empType} onChange={(e:any)=> CheckBoxHandle(e, empType, setEmpType)}/>
   <HideShow viewmore={true} SearchShow = {false} Title ={intl.formatMessage({id:'CLEANER.JOBS.FILTER.JOBTYPE', defaultMessage:'Job Type'})} searchPlaceholder={intl.formatMessage({id:'CLEANER.JOBS.FILTER.JOBTYPE.SEARCH.PLACEHOLDER', defaultMessage:'Search Job Type'})} data={jobTypes} newData={jobType} onChange={(e:any)=> CheckBoxHandle(e, jobType, setJobType)} />
   <HideShow viewmore={false} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.RATE', defaultMessage:'Rate'})} data={payTypes} onChange={(e:any)=> CheckBoxHandle(e, payType, setPayType)} newData={payType}/>
   <TextHideShow fromValue={from} toValue={to} From={(date)=> setFrom(date)} To={(date)=> setTo(date)}  Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.JOBPOSTINGDATE', defaultMessage:'Job Posting Date'})} />
 
</div>
  )
}
