import { faDollarSign, faLocationDot, faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { HTMLStringRender } from "sr/helpers/ui-components/HTMLStringRender";
import { getCustomer } from "sr/utils/api/company/customer/getCustomer";
import { getCustomerLocation } from "sr/utils/api/company/customer/getCustomerLocation";
import { DaysConverter } from "sr/helpers/functions/DaysConverter";
export const WorkOrderCard = ({data}) => {
  return (
<Link to={`/cleaner/work-order-details/${data?.id || data?._id}`}>
            <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
                <div>
                    <div className='font-bold  text-lg'>
                      
                    </div>
                    <div className='lg:flex block items-center'>
                        <div>
                    <div className=' font-bold pb-1'>{data?.title}</div>
                        <div className='text-sm font-bold'>{data?.customer_id?.name}</div>
                        </div>
                        {/* {
                            isSavedJobs &&
                            <div className='items-center justify-items-start lg:justify-items-end grid grid-cols-2 divide-x divide-solid divide-slate-400'>
                                <div>
                                    <span className='px-2'>
                                        <FontAwesomeIcon icon={faStar} className='text-yellow-500 text-sm' />
                                        <span className='text-sm text-gray-500  font-bold'>3.5</span>
                                    </span>
                                </div>
                                <div className='text-sm text-gray-500  font-bold pl-[10px]'>
                                    729 <FormattedMessage id='CLEANER.JOBS.REVIEWS' defaultMessage='Reviews'></FormattedMessage>
                                </div>
                            </div>
                        } */}
                    </div>
                </div>

                <div className="mt-3 grid grid-cols-4 divide-x">
                    <div className='lg:px-0 px-4'>
                        <FontAwesomeIcon icon={faDollarSign} />
                        <span className='pl-2'>{data?.pay_type} (${data?.pay_type_rate})</span>
                    </div>
                    {data?.customer_location_id?.address &&
                    <div className='lg:px-0 px-4 text-center'>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span className='pl-2'>{data?.customer_location_id?.address?.address_line_1} {data?.customer_location_id?.address?.address_line_2} {data?.customer_location_id?.address?.city} {data?.customer_location_id?.address?.state}</span>
                    </div>
}
                 
                    <div className='lg:px-0 px-4 flex justify-center'>
                    <div className='pl-2 text-gray-600 text-sm font-normal'>
                        Entry Time:
                        </div>
                        <span className='pl-2 '>{data?.entry_time}</span>
                    </div>
                    
                    <div className='lg:px-0 px-4 flex justify-center'>
                    <div className='pl-2 text-gray-600 text-sm font-normal '>
                        Exit Time:
                        </div>
                        <span className='pl-2 '>{data?.exit_time}</span>
                    </div>
                </div>

                <div className='flex mt-2 items-center'>
                    <FontAwesomeIcon icon={faNoteSticky} />
                    <p className='pl-2 flex'><HTMLStringRender htmlContent={data?.description?.slice(0,100)}></HTMLStringRender>{data?.description?.length>100 && '...' }</p>
                </div>

                <div className='mt-3 flex items-center justify-between'>
                    <div className='text-xs font-bold  text-gray-500'>
                        <FormattedMessage 
                           id='CLEANER.JOBS.TIME'
                           defaultMessage='{DayNumber} Days Ago'
                           values={{DayNumber:DaysConverter(data?.createdAt)}}
                        />
                    </div>
                    <div>
                      
                            <Link to={`/cleaner/work-order-details/${data?.id}`} className='bg-[#ff39b3] font-bold hover:bg-navy text-white px-4 py-2 w-28 rounded-3xl'>
                                <FormattedMessage 
                                id='CLEANER.JOBS.CARD.BUTTON'
                                defaultMessage='Details'
                                />
                                </Link>
                
                    </div>
                    
                </div>
                <div className='flex justify-end mt-5 '>
                    {/* {
                            isSavedJobs &&
                            <button className='w-20 rounded-3xl flex justify-center items-center'>
                                <FontAwesomeIcon icon={faBookmark} className='mr-2'></FontAwesomeIcon>
                                <span className='font-bold  text-sm'> <FormattedMessage id="CLEANER.SAVEDJOBS.SAVED.TEXT" defaultMessage="Saved" />  </span>
                            </button>
                        } */}
                    </div>
            </div>
        </Link>
     );
};
