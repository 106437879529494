import { InterestedJobs } from 'app/pages/jobs/component/Sidebar/InterestedJobs'
import React from 'react'

export const JobsTabSidebar = () => {
  return (
    <div className='col-span-1'>
  <InterestedJobs width='w-full'/>
    </div>
  )
}
