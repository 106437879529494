import React, { useEffect, useState } from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { ReactDatePicker } from 'sr/helpers/ui-components/ReactDatePicker'
import { FormattedMessage } from 'react-intl'
export const HireTimelineChange = (props) => {
  const [timeline, setTimeline] = useState(new Date())

  useEffect(()=> {
    const recruitmentTimeline =
    props?.data?.job_advanced_id?.recruitment_timeline;
    if (recruitmentTimeline) {
      const newdate = new Date(recruitmentTimeline);
      setTimeline(newdate);
    }
  },[props?.data])

  useEffect(()=> {
    const data = {
      recruitment_timeline:timeline
    }
    props?.HandleDataProps(data)
  },[timeline])
  return (
    <ReactDatePicker 
InputDivClass="mt-2"
onChange={(date)=> setTimeline(date)}
selected={timeline}
inputClass="bg-white border border-zinc-800 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-blue-500 focus:border-[#2557A7] focus:shadow-[0_3px_0px_0px_rgb(37,87,167)] block w-full pl-10 p-2 uppercase"
label={<FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.RECRUITMENTTIMELINE.TITLE" defaultMessage="Position needs filled by" />}>
</ReactDatePicker>
  )
}
