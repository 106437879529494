import React, {useEffect, useState} from 'react'
import { FormattedMessage } from 'react-intl'
export const CustomerTab = (props) => {
    const tabsdata = [
        {name:<FormattedMessage id="RECRUITER.CUSTOMER.TAB.GENERAL" defaultMessage="General" />,
         svgicon:  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
         <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
       </svg>},
        {name:<FormattedMessage id="RECRUITER.CUSTOMER.TAB.LOCATION" defaultMessage="Location" />, 
        svgicon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
      </svg>},
      {
        name:<FormattedMessage id="RECRUITER.CUSTOMER.TAB.CHECKLIST" defaultMessage="Checklist" />,
        svgicon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
      </svg>
      }
    ]
    const HandleTabChange = (data)=> {
      if(props?.tabChange) {
        props?.TabHandle(data)
      }
      else if(props?.tab==='Location') {
        
        if(data==='General') {
          props?.TabHandle(data)
        }
      }
      else if(props?.tab==='Checklist'){
        props?.TabHandle(data)
      }
      }
  return (
<div className='bg-[#FAF9F8] flex justify-center'>
            {tabsdata.map((data) => (
            <div onClick={()=> HandleTabChange(data.name.props.defaultMessage)} className={`${data.name.props.defaultMessage === props.tab ? 'text-[#0078ae] border-[#0078ae] ':'border-neutral-200' } cursor-pointer flex items-center py-4 justify-center w-1/3 space-x-2 border`}>
           {data.svgicon}
                <span>{data.name}</span>
        </div>
        
        ))}
             
              
        </div>
  )
}
