import React, {useState} from 'react'
import { Sidebar } from './Sidebar'
import { Header } from './Header'
export const MainWrapper = ({children, ...props}) => {
    const [isOpen, setIsOpen] = useState(false)
    const HandleOpen = () => {
        setIsOpen(!isOpen)
    }
  return (
    <>
    <Sidebar isOpen = {isOpen} HandleOpen = {HandleOpen} />
    <Header isOpen = {isOpen} HandleOpen = {HandleOpen}/>
   {props.topHeader && props.topHeader}
    <main className={`${isOpen ? 'lg:pl-52 ': 'lg:pl-20'} duration-300 bg-[#f3f2f1] lg:px-8 px-5`}>
          {children}
        </main>
    </>
    
  )
}
