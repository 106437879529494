import { toast } from "react-toastify"

interface paramsType {
    setLoader?:(value:boolean)=> void
    checklistData:any[]
    managetasks:any[]
    setShowModal?:(value:any)=> void
    type:string
    
}
export const CheckMandatorTask = async  (params:paramsType)=> {
    params?.setLoader(true)
    let camt = true;
    const mandatoryTasks = params?.checklistData?.filter((item)=> item?.type=== 'Mandatory')
    if(mandatoryTasks?.length>0){
        for(let i=0; i<mandatoryTasks?.length; i++){
            const result = params?.managetasks?.some((item)=> (item?.task_id?._id=== mandatoryTasks[i]?.id) && (item?.supervisor_status==='approved')||item?.supervisor_status==='rejected')
            if(result===true){
                params?.setLoader(false)
            }
            else {
              toast.error('Please approve or reject each task')
              camt = false
              params?.setLoader(false)
              break
            }
        }
    }
  
    if(camt===true){
        if(params?.type==='approve'){
        params?.setShowModal('hours')
        }
        if(params?.type==='reject') {
            params?.setShowModal('reject')
        }

        params?.setLoader(false)
    }
    }