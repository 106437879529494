import { useGet } from "app/pages/company/Hooks/Api/useGet";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { getfavoriteWorkOrder } from "sr/utils/api/cleaner/workorder/getfavoriteWorkOrder";
import { JobLoaderCard } from "../component/JobLoaderCard";
import { WorkOrderCard } from "./compoent/WorkOrderCard";

export const SavedWorkOrder = () => {
    const [cleanerId, setcleanerId] = useState()
    const {data:savedWorkOrder, getData:getSavedWorkOrder, loader:loader} = useGet()
   useEffect(()=> {
            const result = getUserDetails()
            setcleanerId(result.user_id)
     },[])    
     useEffect(()=> {
            const fetchWorkOrder = async ()=> {
                        const queryParams = new URLSearchParams()
                        queryParams.append('cleaner_id', cleanerId)
                        await getSavedWorkOrder(()=> getfavoriteWorkOrder(queryParams))
            }
            if(cleanerId) {
                fetchWorkOrder()
            }
     },[cleanerId])
    
  return (<>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <main>
    <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className='lg:flex flex-col lg:w-2/3 block'>
                        <div className='card-1 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <div className='header flex items-center space-x-3'>
                                <h1 className='font-medium text-2xl inline'>{savedWorkOrder?.workorder_ids?.length || 0}</h1>
                                
                                <span className='font-semibold text-sm text-gray-600'>  <FormattedMessage id="CLEANER.SAVEDJOBS.TITLE" defaultMessage="Saved Jobs" />  </span>
                            </div>
                        </div>
                        <ContentWithLoader loading={loader} content={<JobLoaderCard></JobLoaderCard>}>
                <div className="col-span-3">
                {
                    savedWorkOrder?.workorder_ids?.map((datI, index) => {
                        return <WorkOrderCard key={index} data={datI}></WorkOrderCard>
                    })
                }
                {/* <Pageination page = {page} totalpage= {totalPage} limit = {limit} onPageChange = {handlePageChange}/> */}
                </div>
                </ContentWithLoader>

                        </div></div>

        </main>
        </>);
};
