
import { Spinner } from 'sr/helpers';
interface Props {
  loader?: boolean,
  children: React.ReactNode
  height:string
}

const ContentWithSpinner = ({loader, children, height='' }: Props) => {
  return loader ? (
    <Spinner size={`w-10 h-10`} className={`flex justify-center w-full items-center ${height && height}`}/>
) : (
  <>{children}</>
);
};
export default ContentWithSpinner;
ContentWithSpinner.defaultProps = {
  height:''
}