import React, {useState, ForwardRefRenderFunction, forwardRef, useEffect} from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Range } from '../ShowPayByInput/Range'
import { StartingAmount } from '../ShowPayByInput/StartingAmount'
import { MaximumAmount } from '../ShowPayByInput/MaximumAmount'
import { ExactAmount } from '../ShowPayByInput/ExactAmount'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik';

interface data {
    show_pay_by: string,
    min_amount : number,
    max_amount: number,
    exact_amount: number,
    rate: string,
}

const PayChange: ForwardRefRenderFunction<any, any> = (props, ref) => {
    const [ShowPayBy, setShowPayBy] = useState('Range')
    const [errors, setErrors] = useState<any>({});
    const PayChangeSchema = Yup.object({
        min_amount : Yup.number().min(0).positive("INPUT.VALIDATION.MINAMOUNT.POSITIVE"),
        max_amount : Yup.number().min(0).positive("INPUT.VALIDATION.MAXAMOUNT.POSITIVE"),
        exact_amount : Yup.number().min(0).positive("INPUT.VALIDATION.EXACTAMOUNT.POSITIVE"),
    });

    useEffect(()=> {
        setShowPayBy(props?.data?.show_pay_by)
    },[])

    const validateData = async (data) =>  {
        const newData:data = {
            show_pay_by:ShowPayBy,
            min_amount : data?.min_amount,
            max_amount: data?.max_amount,
            exact_amount: data?.exact_amount,
            rate:data?.payType,
        }
        let isValid: boolean;
        try {
            await PayChangeSchema.validate(data, { abortEarly: false });
            setErrors({}); // Reset errors if validation is successful
            props?.HandleDataProps(newData)
            isValid = true;
        } catch (validationErrors) {
            const newErrors = {};
            validationErrors.inner.forEach(error => {
            newErrors[error.path] = error.message;
            });
            setErrors(newErrors);
            isValid = false;
        }
        return isValid;
    };
      
    React.useImperativeHandle(ref, () => ({
    validateData
    }));

    const HandleShowPay = (data)=> {
        const newdata = {
            show_pay_by:ShowPayBy,
            min_amount : data?.min_amount,
            max_amount: data?.max_amount,
            exact_amount: data?.exact_amount,
            rate:data?.payType,
        }
        props?.HandleDataProps(newdata)
    }
  return (
    <>
    <p className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.PAY.TITLE" defaultMessage="Pay" /></p>
    <div className='flex'>
    <p className='text-base'><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.PAY.TEXT" defaultMessage="Review the pay we estimated for your job and adjust as needed." />
</p>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
<path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
</svg>
    </div>
    <div className='w-1/3'>
    <DropDown onChange={(e) => setShowPayBy(e.target.value)} value={ShowPayBy} name={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.RANGE" defaultMessage="Range" />} label={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SHOWPAYBY.TITLE" defaultMessage="Show Pay By" />} className="h-11 mt-2 border border-zinc-800 rounded-lg">
                <option value="Range"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.RANGE" defaultMessage="Range" /></option>
                <option value="Starting amount"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.STARTING_AMOUNT" defaultMessage="Starting amount" /></option>
                <option value="Maximum amount"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.MAXIMUM_AMOUNT" defaultMessage="Maximum amount" /></option>
                <option value="Exact amount"><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.SHOWPAYBY.EXACT_AMOUNT" defaultMessage="Exact amount" /></option>
            </DropDown>
            </div>
            {ShowPayBy==="Range" && 
                <Range errors={errors} HandleShowPay={HandleShowPay} minAmount={props?.data?.min_amount} maxAmount={props?.data?.max_amount}  pay={props?.data?.rate}/>}
                    {ShowPayBy==="Starting amount" && 
             <StartingAmount errors={errors} HandleShowPay={HandleShowPay} minAmount={props?.data?.min_amount} pay={props?.data?.rate} />
             }
                    {ShowPayBy==="Maximum amount" && 
               <MaximumAmount errors={errors}  HandleShowPay={HandleShowPay} maxAmount={props?.data?.max_amount} pay={props?.data?.rate} />
               }
                    {ShowPayBy==="Exact amount" && 
               <ExactAmount errors={errors} HandleShowPay={HandleShowPay} exactAmount={props?.data?.exact_amount} pay={props?.data?.rate}/> }
           </>
  )
}
export default forwardRef(PayChange);