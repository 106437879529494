import React, {useEffect, useState} from 'react'
import { RadioInput } from 'sr/helpers/ui-components/RadioInput';
import { InputSearch } from 'sr/helpers/InputSearch';
import { HideShow } from './HideShow';
import { TextHideShow } from './TextHideShow';
import { FormattedMessage, useIntl } from 'react-intl';
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle';
import { businessTypes } from 'sr/i18n/messages/setting';
import { usaCities } from 'sr/i18n/messages/setting';
export const Filter = ({HandleFilterData = null}) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [businessType, setBusinessType] = useState([])
  const [location, setLocation] = useState('')
    const toggleShow = () =>{
        setShow(!show)
    }
    useEffect(()=> {
    const filter = {
      business_type:businessType

    }
    HandleFilterData(filter)
    },[businessType])
  return (
    <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
    <div className='font-bold py-4 border-b-[1px]	'>
      <FormattedMessage 
      id='CLEANER.JOBS.FILTER.TITLE'
      defaultMessage='All Filter'
      />
    </div>
    <HideShow viewmore={true} SearchShow = {false} Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.BUSINESSTYPE', defaultMessage:'Business Type'})} data={businessTypes} newData={businessType}  onChange={(e:any) => CheckBoxHandle(e, businessType, setBusinessType )} />    
      {/* <HideShow SearchShow = {true} Title = {intl.formatMessage({id:'CLEANER.JOBS.FILTER.LOCATION', defaultMessage:'Location'})} searchPlaceholder={intl.formatMessage({id:'CLEANER.JOBS.FILTER.LOCATION.SEARCH.PLACEHOLDER', defaultMessage:'Search Location'})} data={usaCities} newData={location} onChange={(e:any)=> CheckBoxHandle(e, location, setLocation)}/> */}
 
</div>
  )
}
