import React from "react";
import { createIntl, createIntlCache } from 'react-intl';
import { toast } from "react-toastify";
import data from '../../i18n/messages/en.json'

const cache = createIntlCache();
let allerrormessage = {}
const fetchData = async ()=> {
  for (let key in data) {
    if (key.startsWith("API.ERROR")) {
      
      allerrormessage[key] = data[key]
  }
}
}

const intl = createIntl(
  {
    locale: 'en',
    messages:allerrormessage,
  },
  cache
);

export const HandleApiError = (e:any) => {
  fetchData()
    const translatedMessage = intl.formatMessage({
        id: `API.ERROR.${e?.response?.data?.msg}`,
        defaultMessage: 'An error occurred',
      });
      console.log(translatedMessage);
      
      toast.error(translatedMessage||e.message)
};
