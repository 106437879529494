import React, {useEffect, useState} from 'react'
import { ModelWrapper } from '../../component/ModelWrapper'
export const Filter = ({children, childrenClass='px-3 py-1.5', ...props}) => {
    const [showModal, setShowModal] = useState(false)
    const HandleModelByProps = (data) => {
        setShowModal(data)
       }
       useEffect(()=> {
          if(props?.trackClick) {
            setShowModal(false)
          }
       },[props?.trackClick])
  return (
    <div className={`${props.mainClass ? props.mainClass : null} relative`}>
    <label className='block '>{props.label}</label>
 <div onClick={()=> setShowModal(!showModal)} className='bg-white hover:text-[#0078ae] cursor-pointer py-1.5 border border-[#d4d2d0] hover:border-[#0078ae] rounded-lg flex justify-between space-x-2 items-center px-4'>
  <span>{props.name}</span>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg></div>
{showModal &&
<ModelWrapper border={props?.border} showModal={showModal} HandleModelByProps = {HandleModelByProps} showModel = {showModal} width = {props?.width ? props?.width : 'w-56'} topPosition='top-12'>
  <div className={`${childrenClass} text-black`}>
    {children}
</div>
</ModelWrapper>
}
</div>
  )
}
