import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getTask = async (params) => {
  return await get<ApiResponse<[]>>(`/customer/tasklist?${params}`).then((res)=> {
    try {
    if(res.success===true) {
        return res
    }
    else {
        return null
    }
}
catch(e){
    
}
  })
};
