import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
export const SocialShare = (props) => {

  return (
  <div className='flex space-x-2 my-4'>

<a target="_blank" className="rounded-lg py-0.5 px-2 transition-ease-out duration-25 bg-[#3b5998] hover-[#2d4373] border-[#3b5998] 
   hover:bg-[#2d4373] hover:border-[#2d4373] active:bg-[#2d4373] active:border-[#2d4373]" href={`https://facebook.com/sharer/sharer.php?quote=${encodeURIComponent(
    props?.title
  )}&u=${encodeURIComponent(props?.fullLink)}`}>

      <div aria-hidden="true" className="inline-block fill-white stroke-none">
      <svg className="h-4 w-4 m-0 align-middle inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z">
              </path></svg></div>
              </a>
              <a target="_blank" href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(
          props?.title
        )}&url=${encodeURIComponent(props?.fullLink)}`} className="rounded-lg py-0.5 px-2 transition-ease-out duration-25 bg-[#55acee] hover:bg-[#2795e9] hover:border-[#2795e9] active:bg-[#2795e9] active:border-[#2795e9]">

      <div aria-hidden="true" className="inline-block fill-white stroke-none"><svg className="h-4 w-4 m-0 align-middle inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"></path>
          </svg></div>
          </a>
          
          <a target="_blank" className="rounded-lg transition-ease-out duration-25 bg-[#0288D1] hover:bg-[#2795e9] hover:border-[#2795e9] active:bg-[#2795e9] active:border-[#2795e9]" href={`https://www.linkedin.com/sharing/share-offsite/?url=${props?.fullLink}`}>
         
      <div aria-hidden="true" className="inline-block fill-white stroke-none">
<svg className='h-8 w-8 m-0 align-middle inline-block' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48">
<path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"/>
<path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"/></svg>

</div></a>
</div>     
)};
