import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from 'sr/helpers'
import { ModelItems, ModelBottomItem } from './SidebarItems'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'app/pages/module/auth'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { useGet } from 'app/pages/company/Hooks/Api/useGet'
import { getIndividual } from 'sr/utils/api/individual/getIndividual'
export const Header = (props) => {
  const {logout} = useAuth()
    const [model, setModel] = useState(false)
    const [userId, setUserId] = useState()
    const [showeffect, setShowEffect] = useState(false)
    const [showRightHeader, setShowRightHeader] = useState(false)
    const HandleClick = () => {
      props.HandleOpen();
  }
  const {loader:loader, getData:fetchData, data:data} = useGet()
/* Open/End Model Popup With Effects */
const HandleModel = ()=> {
  if(!model){
    setModel(true)
    setTimeout(() => {
      setShowEffect(true)
    }, 200);
  }
  else {
    setTimeout(() => {
      setShowEffect(false)
    }, 200);
    setTimeout(() => {
      setModel(false)
    }, 500);
  }
}
  useEffect(()=> {
    const getdetails = getUserDetails()
    setUserId(getdetails?.user_id)
  },[])
  useEffect(()=> {
    const fetchDetails = async (userId) => {
        const queryParams = new URLSearchParams(); 
        if (userId) queryParams.append('id', userId); 
        await fetchData(()=> getIndividual(queryParams))
      }
      if(userId) {
        fetchDetails(userId)
      }
  },[userId])
  return (
    <nav className={`${props.isOpen ? 'lg:pl-48': 'lg:pl-16'} duration-300 border border-b-text-gray-500 relative z-40`}>
<div className="w-full flex bg-white justify-between items-center pr-5">
  <div className='flex lg:block space-x-2'>
    <div onClick={HandleClick} className='bg-[#2d2d2d] lg:hidden flex justify-center items-center px-3 cursor-pointer'>
    <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="w-6 h-6 text-white"><path d="M3.5 5.25h13a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zm13 4h-13a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5zm0 4h-13a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5z"></path></svg>
    </div>
   <img className='py-1.5' width = {120}  src={toAbsoluteUrl('/media/logos/logo2.png')} alt='logo' />
  </div>
  <div onClick={()=> setShowRightHeader(!showRightHeader)} className={`items-center lg:hidden ${showRightHeader ? 'hidden':'flex md:flex' }`}>
  <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-6 h-6"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg>
  </div>
  <div className={`${showRightHeader  ?'translate-x-0 delay-100':"translate-x-[100%]" } lg:flex lg:flex-row flex-col lg:space-x-4 space-y-5 lg:space-y-0 md:fixed fixed lg:static bg-white inset-0 shadow-xl transition duration-300 lg:shadow-none lg:translate-x-0 z-20`} >
    <div onClick={()=> setShowRightHeader(!showRightHeader)} className='lg:hidden px-5 flex justify-between border-b border-neutral-300 py-3'>
    <div className='text-lg font-satoshiBold'>{data?.username}</div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

    </div>
   <Link className='flex flex-1 items-center space-x-2 border-r border-text-gray-500 lg:pr-4 px-5' to="#">
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5"><path fill-rule="evenodd" d="M3.5 3a.5.5 0 00-.5.5v17a.5.5 0 00.5.5H6v-6h5v6h9.5a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H14V3.5a.5.5 0 00-.5-.5h-10zM5 9h7v1H5V9zm7-3H5v1h7V6z" clipRule="evenodd"></path></svg>
   <div className='block'>
      <p className='-mb-1'>{data?.username}</p>
      <p className='text-[#767676] text-sm'><FormattedMessage id="INDIVIDUAL.TOPBAR.MENU.OWNER.TITLE" defaultMessage="owner" />:{data?.email}</p>
   </div>
      </Link>
      <Link className='flex items-center space-x-2 relative px-5 lg:px-0 z-50' to="#">
      <svg onClick={HandleModel} xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-5 h-5"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>   
      <span onClick={HandleModel}>   {data?.email}</span>
      <svg onClick={HandleModel} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg>
{model &&
<div className={`${showeffect ? 'modeleffect':'popup' }  w-[22rem] transitioncss z-50 absolute top-14 right-2 bg-white rounded-lg border border-slate-400	`}>
<div className='p-4'>
    {/* <h1 className='font-satoshiBold text-lg'>
        Vishal
    </h1> */}
    {/* <ul>
        {ModelItems.map((data)=> (
          <Link to={'#'}>
        <li className='flex items-center space-x-2 py-3'>
        {data.icon}
        <span>{data.text}</span>
        </li>
        </Link>
        )) }
    </ul>
    <hr className='pt-2 border-slate-300'/> */}
    <h1 className='font-satoshiBold text-lg	 py-2'>
    {data?.email}
    </h1>
    <ul>
        {ModelBottomItem.map((data)=> (
          <Link to={data.link ? data.link : '#'}>
        <li onClick={()=> data.text.props.defaultMessage==='Sign Out' &&  logout()} className='flex items-center space-x-2 py-3'>
        {data.icon}
        <span>{data.text}</span>
        </li>
        </Link>
        )) }
        
    </ul>

</div>
</div>
}
   </Link>
  </div>
</div></nav>
  )
}
