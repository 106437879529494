import React, { useState } from "react";
import { WhiteBtn } from "../../work-order/component/WhiteBtn";
import { BillingContactForm } from "./BillingContactForm";
import { BillingInfoForm } from "./BillingInfoForm";
export const BillingInformation = () => {
    const [billingCForm, setBillingCForm] = useState(false)
    const [infoForm, setInfoForm] = useState(false)
  return (
    <div className="grid grid-cols-12 gap-8">
    <div className="col-span-9">
    <div className="bg-white rounded-lg p-5 mb-5">
 {billingCForm ? 
 <BillingContactForm  onClick={()=> setBillingCForm(false)}/>
 :<>
    <div className="flex justify-between">
    <div className=" text-lg">Billing contacts</div>
    <WhiteBtn onClick={()=> setBillingCForm(true)} title="Edit"></WhiteBtn>
    </div>
    <div className="flex justify-between w-full flex-wrap">
        <div className="w-1/2 mb-3">
        <div className="">Name</div>
        <p>Vishal Rathaur</p>
        </div>
        <div className="w-1/2 mb-3 text-left">
        <div className="">Phone</div>
        <p>0000000000</p>
        </div>
        <div className="w-1/2 mb-3">
        <div className="">Company</div>
        <p>Max Tech Info</p>
        </div>
        <div className="w-1/2 mb-3">
        <div className="">Fax</div>
        <p></p>
        </div>
        <div className="w-1/2 mb-3">
        <div className="">Billing email</div>
        <p>jmmtech@gmail.com</p>
        </div>
    </div>
 </>}
    </div>
    <div className="bg-white rounded-lg mb-5">
    {infoForm ? 
      <BillingInfoForm onClick={()=> setInfoForm(false)}/>:
      <>
    <div className="flex justify-between pt-5 px-5">
    <div className=" text-lg">Billing information</div>
    <WhiteBtn onClick={()=> setInfoForm(true)} title="Edit"></WhiteBtn>
    </div>
    <div className="flex justify-between w-full flex-wrap px-5">
        <div className="w-1/2 mb-3">
        <div className="">Country</div>
        <p>IN</p>
        </div>
        <div className="w-1/2 mb-3 text-left">
        <div className="">Currency</div>
        <p>($)Dollar</p>
        </div>
        <div className="w-1/2 mb-3">
        <div className="">Billing address</div>
        <p></p>
        </div>
      </div>
      </> }
    </div>
    </div>
    </div>
    );
};
