import React from 'react'
export const ModelWrapper = ({children, ...props}) => {
    const HandleModel = (data) =>{
        props.HandleModelByProps(data)
    }
  return (
    <>
    <div className={`${props.topClass} justify-center items-center ${props.position} ${props.topPosition} ${props.rightPosition}  z-50 outline-none focus:outline-none`}>
    <div className={`relative  my-6 ${props.width} `}>
      {/*content*/}
      <div className={`${props.border} ${props.rounded} bg-white shadow-sm shadow-slate-300  relative flex flex-col w-full outline-none focus:outline-none`}>
       {children}
      </div>
    </div>
  </div>
  <span onClick={()=>HandleModel(false)} className={`fixed w-full h-full top-0 left-0 delay-100 visible ${props.background} ${props.opacity}`}></span>
        </>)
}
ModelWrapper.defaultProps =  {
width:'w-80',
border:'border-0',
topPosition:'top-5',
rounded:'rounded-[15px]',
position:'absolute',
opacity:'',
background:'',
topClass:''
}
