import React, {useState, useEffect} from "react";
import { MainWrapper } from "../component/MainWrapper";
import { CleanerProfileCard } from "./component/CleanerProfileCard";
import { CleanerFilter } from "./component/CleanerFilter";
import { FormattedMessage } from "react-intl";
import { getCleaner } from "sr/utils/api/cleaner/getCleaner";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { useParams } from "react-router-dom";
import { getJob } from "sr/utils/api/individual/job/getJob";
import { getAvailabilityCleaner } from "sr/utils/api/cleaner/getAvailabilityCleaner";
export const CleanerProfiles = () => {
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  const [jobId, setJobId] = useState('')
  const [jobData, setJobData] = useState(null)
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const params = useParams()
  const [minRange, setMinRange] = useState(0)
      const [maxRange, setMaxRange] = useState(100)
  useEffect(()=> {
      const {id, from, to} = params
      setStartTime(from)
      setEndTime(to)
      setJobId(id)
  },[params])
  const fetchCleaner = async ()=> {
    setLoader(true)
    const ScheduleDate = new Date(jobData?.schedule_date)
    const dayName = ScheduleDate.toLocaleDateString("en-US", { weekday: "long" });
    // const filter = 
    //   [ { "day": {dayName}, "start_time": startTime, "end_time": endTime } ]
    const queryParams = new URLSearchParams()
      queryParams.append('job_type', jobData?.title)
      // queryParams.append('filter', JSON.stringify(filter))

    try {
        const res = await getAvailabilityCleaner(queryParams)
        if(res?.success===true) {
          setData(res?.data)
            setLoader(false)
          }
        else {
          setLoader(false)
        }
    }
    catch(e:any) {
setLoader(false)
    }
   }
  
  useEffect(()=> {
    if(jobData?.title) {
      fetchCleaner()
    }
  },[jobData?.title, startTime, endTime])
  const fetchJobData = async ()=> {
    const queryParams = new URLSearchParams()
    queryParams.append('id', jobId)
      try {
          const res = await getJob(queryParams)
          if(res?.success===true) {
            setJobData(res?.data)
          }
          else {
          }
      }
      catch(e) {
      }
  }
  useEffect(()=> {
if(jobId) {
fetchJobData()
}
  },[jobId])
  const topHeader = (
    <div className="w-full">
        <div className="bg-white flex justify-center space-x-2 border border-neutral-200 py-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
          </svg>
          <FormattedMessage id="INDIVIDUAL.BOOKTASK.CLEANERLIST.DESCRIPTION" defaultMessage="Filter and sort to find your Cleaner. Then view their availability to request your date and time." />
        </div>
      </div>
  )
  return (
    <MainWrapper topHeader={topHeader}>
      <ContentWithSpinner loader={loader} height="h-screen">
      <div className="py-3 lg:px-14 mx-auto">
        <div className="lg:grid lg:grid-cols-12 lg:gap-3">
     <CleanerFilter setStartTime={setStartTime} setEndTime={setEndTime}/>
          <div className="col-span-8">
            {data.map((item)=> (
          <CleanerProfileCard item={item} />
          ))}
          </div>
        </div>
      </div>
      </ContentWithSpinner>
    </MainWrapper>
  );
};
