import React from "react";
import ContentLoader from "react-content-loader";
export const JobsLoadingCard = () => {
  return (
   <div>
        {
  [{},{},{}].map(()=> (
    <div className=" bg-white px-4 py-6  rounded-[20px] mb-6">
  <ContentLoader 
    speed={2}
    width={1000}
    height={160}
    viewBox="0 0 1291 215"
    backgroundColor="#fff"
    foregroundColor="#e3e3e3"
  >
    <rect x="-358" y="-10" rx="3" ry="3" width="67" height="31" /> 
    <rect x="-235" y="-34" rx="3" ry="3" width="140" height="11" /> 
    <rect x="-300" y="-15" rx="3" ry="3" width="53" height="11" /> 
    <rect x="-381" y="-11" rx="3" ry="3" width="72" height="11" /> 
    <rect x="-407" y="17" rx="3" ry="3" width="100" height="11" /> 
    <rect x="-331" y="24" rx="3" ry="3" width="37" height="11" /> 
    <rect x="-462" y="41" rx="3" ry="3" width="140" height="11" /> 
    <rect x="-353" y="11" rx="3" ry="3" width="173" height="11" /> 
    <rect x="6" y="30" rx="0" ry="0" width="300" height="14" /> 
    <rect x="6" y="50" rx="0" ry="0" width="300" height="13" /> 
    <rect x="6" y="70" rx="0" ry="0" width="300" height="13" />
  </ContentLoader>
  </div>
  ))}
 </div> );
};
