import React, {useRef, useState} from 'react'
import { MainWrapper } from '../component/MainWrapper'
import { LocationEdit } from './components/CustomerDetails/LocationEdit'
import { ModelWrapperWithButton } from '../component/ModelWrapperWithButton'
import  TaskForm  from '../Customer/components/forms/TaskForm'
import { GeneralForm } from '../Customer/components/forms/GeneralForm'
import ChecklistForm  from '../Customer/components/forms/ChecklistForm'
import { CustomerTab } from './components/CustomerTab'
import { Location } from './components/Tables/Location'
import { Checklist } from './components/Tables/Checklist'
import { Task } from './components/Tables/Task'
import { LocationDetailsView } from './components/LocationDetailsView'
import GeneralTab from './components/AddCustomer/GeneralTab'
import { FormattedMessage } from 'react-intl'
import { Button } from 'sr/helpers/ui-components/company/Button'
import { LocationTab } from './components/AddCustomer/LocationTab'
import { ChecklistTab } from './components/AddCustomer/ChecklistTab'
import { Link, useNavigate } from 'react-router-dom'
export const CustomerDetails = () => {
  interface TabRef {
    submitForm: () => void;
  }
  
  const [LocationView, setLocationView] = useState(false)
    const navigate = useNavigate();
    const [tab, settab] = useState('General')
    const [showModel, setShowModel] = useState(false)
    const [activeComponent, setActiveComponent] = useState<any>({});
    const [showTask, setShowTask] = useState(false)
    const [Locations, setLocations] = useState()
    const TabHandle = (data:any) => {
      settab(data)
    }
    const HandleModel = (data) => {
        setShowModel(data)
    }
    const onCheckModel = (data) => {
      setLocationView(false)
    }
    const AllModel = [
        {
            name:'GeneralEdit',
            componentName:GeneralForm
        },
        {
            name:'LocationEdit',
            componentName:LocationEdit
        }, 
        {name:'ChecklistEdit',
        componentName:ChecklistForm },
        {name:'TaskEdit',
        componentName:TaskForm },
        {name:'Task',
        componentName:Task }
    ]
    const openPopup = (index: any) => {  
        const activeComp = AllModel.filter(function (e, i) {
          console.log(i == index)
          return i == index
        });      
        setActiveComponent(activeComp[0]);
        console.log('activeComponent', activeComponent)
        setShowModel(true)
      };
      const childRef = useRef<TabRef>(null)
      const HandleButtonClick = ()=> {
        if (childRef.current && childRef.current.submitForm) {
          childRef.current.submitForm()
        }
      }
      const dataAddedHandle = (data)=> {
        if(data===true) {
          TabHandle('Location')
        }
      }
      const HandleLocation = (data)=> {
        setLocations(data)
      }
  return (
    <MainWrapper>
      <div className='bg-white'>
        <div className='flex items-center space-x-4'>
          <button
            className="p-2 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={() => navigate(-1)}>
            <span className="bg-transparent text-black opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <div className='py-3 px-10 mx-auto bg-white'>
          <CustomerTab tab={tab} TabHandle={TabHandle} tabChange={true}/>
          {tab === 'General' && (
            <>
              <GeneralTab ref={childRef} dataAddedHandle={dataAddedHandle}/>
              <Button onClick={HandleButtonClick} text={<FormattedMessage id="RECRUITER.CUSTOMER.SAVE.BUTTON" defaultMessage="Save" />}></Button>
            </>
          )}
          {tab === 'Location' && <LocationTab TabHandle={TabHandle}/>}
          {tab ===  'Checklist' && <ChecklistTab />}
          <ModelWrapperWithButton showModal={showModel} onCheckModel={HandleModel}>
            {<activeComponent.componentName />}
          </ModelWrapperWithButton>
          <ModelWrapperWithButton nofooter = {true} showModal = {LocationView} onCheckModel={onCheckModel}> 
            <LocationDetailsView id={activeComponent}/>
          </ModelWrapperWithButton>
        </div>
      </div>
   </MainWrapper>
  )
}
