import React, {useState} from "react";
import Select from 'react-select';
import { useValidationErrorHandle } from "../functions/validationErrorHandle";


export const ReactSelect = (props) => {
  const validationErrorHandle = useValidationErrorHandle()
    return (
      <div className={props.InputDivClass}>
             {props.label ? (
          <label className={` ${props.labelClass} text-normal font-satoshiBold`}>
            {props.label}
          </label>
        ) : (
          ''
        )}
          <Select
            isMulti={props?.isMulti}
            isSearchable={props?.isSearchable}
            defaultValue={props?.defaultValue}
            onChange={props?.onChange}
            options={props?.options}
            placeholder={props?.placeholder}
          />
{props?.error && <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(props?.error)}</p>}
        </div>
      );
    }
    ReactSelect.defaultProps = {
      InputDivClass:'mt-10',
      labelClass: 'mt-2 text-gray-700 text-sm',
    }