import React, { useState, forwardRef, ForwardRefRenderFunction, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Input } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { useFormik } from 'formik'
import { ReactDatePicker } from 'sr/helpers/ui-components/ReactDatePicker'
import { conditionSchema } from 'sr/utils/schemas/cleaner/conditionSchema'
import { createMedicalCondition } from 'sr/utils/api/cleaner/createMedicalCondition'
import { CleanerComponentProps } from './CleanerComponentProps'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getMedicalCondition } from 'sr/utils/api/cleaner/getMedicalCondition'
import { updateMedical } from 'sr/utils/api/cleaner/updateMedical'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
const CleanerMedicalCondition: ForwardRefRenderFunction<any, CleanerComponentProps> = (
  { HandleStepper = null, page = '', type = '', dataAddedHandle=null, id='' },
  ref
) => {
  const [userData, setUserData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
  useEffect(()=> {
    const getdetails = getUserDetails()
    setUserData(getdetails)
  },[])
  const intl = useIntl()
  const [AddMoreClicked, setAddMoreClicked] = useState(false)
  const [sinceWhen, setSinceWhen] = useState(null)
  const [condition, setCondtion] = useState("")
  const [description, setDescription] = useState("")
  const initialValues = {
    cleaner_id:userData?.user_id,
    condition:condition,
    since_when :sinceWhen,
   description:description
  }
  const initialValuesPut = {
    cleaner_id:userData?.user_id,
    condition:condition,
    since_when :sinceWhen,
   description:description,
   id:id
  }
  useEffect(()=> {
    const fetchData = async () => {
      setLoader(true)
      try {
        const medicalCondition = await getMedicalCondition(undefined,id)
        if(medicalCondition.success===true){
          setCondtion(medicalCondition?.data?.condition)
          setSinceWhen(medicalCondition?.data?.since_when)
          setDescription(medicalCondition?.data?.description)
          setLoader(false)
        }
      }
      catch(e:any){
        console.log(e);
        setLoader(false)
      }
    }
    if(page === 'popup' && type==='put'){
    fetchData()
    }
  },[id, page, type])
  const {handleSubmit, errors, submitForm} = useFormik({
    initialValues:page === 'popup' && type==='put'? initialValuesPut:initialValues,
    enableReinitialize:true,
    onSubmit:async (values, helpers)=> {
      setButtonLoader(true)
try {
  let result
  if(page === 'popup' && type==='put'){
    console.log(values);
    
    result = await updateMedical(values)
  }
  else {
  result = await createMedicalCondition(values)
  }
if(result.success===true) {
  setButtonLoader(false)
  if(page === 'popup' ){
    dataAddedHandle(true)
    }
  if(AddMoreClicked){
  helpers.resetForm()
  setSinceWhen(null)
  setCondtion("")
  setDescription("")
  setAddMoreClicked(false)
  }
  else {
    if (HandleStepper !== null) {
      HandleStepper(6);
    }
  
  }
}
}
catch(e:any) {
  console.log(e);
  setButtonLoader(false)
}
    },
    validationSchema:conditionSchema
  })
  React.useImperativeHandle(ref, () => ({
    submitForm,
  }));
  return (
    <ContentWithSpinner loader={loader}>
            {buttonLoader && <LoaderFullScreen />}
            <form className='mt-5' onSubmit={handleSubmit}>
            <Input
            name='condition'
            value={condition}
            onChange={(e)=> setCondtion(e.target.value)}
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.MEDICALCONDITION.INPUT.CONDITION',
    defaultMessage: 'Condition',
  })}
  type="text"
  placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.MEDICALCONDITION.INPUT.CONDITION.PLACEHOLDER',
    defaultMessage: 'Enter Condition',
  })}
  error={errors.condition}
/>

<ReactDatePicker 
onChange={(date)=> setSinceWhen(date)}
selected={sinceWhen}
error={errors.since_when}
label={intl.formatMessage({
    id: 'CLEANER.PROFILE.MEDICALCONDITION.SINCE.WHEN',
    defaultMessage: 'Since When',
    
  })}></ReactDatePicker>

<Input
name='description'
value={description}
            onChange={(e)=> setDescription(e.target.value)}
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.MEDICALCONDITION.DESCRIPTION',
    defaultMessage: 'Description',
  })}
  type="text"
  placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.MEDICALCONDITION.DESCRIPTION.PLACEHOLDER',
    defaultMessage: 'Enter description',
  })}
  error={errors.description}
  />
<div className='mt-5 flex space-x-5'>
  {page === 'popup' && (type === 'add'|| type==='put') ? '':(<>
    
                    <ButtonAuth loader={buttonLoader}
                      ><FormattedMessage id="REGISTER.BUTTON.SAVEANDCONTINUE" defaultMessage="Save and Continue" />
                    </ButtonAuth>
                   
                    <ButtonAuth loader={buttonLoader} onClick={()=> setAddMoreClicked(true)} >Add More</ButtonAuth>
                
                    <div className='rounded-full text-center
                 bg-[#ff39b3] font-bold
                 p-2
                 text-white
                 w-44
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy' onClick={()=> HandleStepper(6)}>Skip</div>
  </>)
  }
                  </div>
            </form>
       </ContentWithSpinner>
  )
}
export default forwardRef(CleanerMedicalCondition);
