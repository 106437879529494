import { useAuth } from "app/pages/module/auth";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { fetchCustomerLocations } from "sr/helpers/apiFunctions/fetchCustomerLocations";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { AppDispatch } from "sr/redux/store";
import { assignContractorLocation } from "sr/utils/api/company/workorder/assignContractorLocation";
import { removeContractorLocation } from "sr/utils/api/company/workorder/removeContractorLocation";

export const Location = (props) => {
    const [id, setId] = useState<any>()
    const [customerLocations, setCustomerLocations] = useState([])
    const [userId, setuserId] = useState()
    const params = useParams()
    const [buttonLoader, setButtonLoader] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
    const [customerLocationId, setCustomerLocationId] = useState<string>()
    const {userType} = useAuth()
    const [loader, setLoader] = useState(true)
    
    useEffect(()=> {
        if(userId && userType==='supervisor') {
          if(!supervisorData){
            dispatch(fetchSupervisor({id:userId}))
          }
        }
},[userId, userType, dispatch])
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setuserId(user_id)
          },[])
          useEffect(()=> {
            const {id} = params
            setId(id)
      },[params])
      const loadCustomerLocation = async ()=> {
        await fetchCustomerLocations({userType:userType, companyId:userId, customerLocationId:customerLocationId, setCustomerLocations:setCustomerLocations, setLoader:setLoader})
    }
      const AssignLocation = async (customerLocationid)=> {
        setButtonLoader(true)
          const payLoad = {
            contractor_id:id,
            customer_location_ids:[customerLocationid]
          }
          const res = await assignContractorLocation(payLoad)
          if(res?.success===true) {
            setButtonLoader(false)
            props?.loadContractor(id)
                  loadCustomerLocation()
          }
          
        }

        useEffect(()=> {
            const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
            setCustomerLocationId(id?.join());
      
          },[supervisorData])

        const checkAssigned = (customerLocationid)=> {
            return  props?.data?.customer_location_ids?.some((item) => item?._id===customerLocationid)
        }
        const handleRemoveLocation = async (customerLocationid)=> {
          setButtonLoader(true)
              const payLoad = {
                contractor_id:id,
                customer_location_ids:customerLocationid
              }
              const res = await removeContractorLocation(payLoad)
              if(res.success===true) {
                setButtonLoader(false)
                props?.loadContractor(id)
                loadCustomerLocation()
              }
        }
        useEffect(()=> {
            if(userId && userType) {
              if(userType==='supervisor'){
                if(customerLocationId){
                  loadCustomerLocation()
                }
              }
              else {
                loadCustomerLocation()
              }
            }
      },[userId, customerLocationId])
      
  return (
    <ContentWithSpinner loader={loader} height="h-screen">

    <table className="w-full text-left condidate-table mt-10">
            <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
              <tr>
                  <th scope="col" className="px-6 py-4 text-base ">
                  <FormattedMessage id="INPUT.ADDRESS1.TITLE" defaultMessage="Address 1" />
                  </th>
                  <th scope="col" className="px-6 py-4 text-base ">
                  <FormattedMessage id="INPUT.ADDRESS2.TITLE" defaultMessage="Address 2" />
                  </th>
                  <th scope="col" className="px-6 py-4 text-base ">
                  <FormattedMessage id="RECRUITER.CUSTOMER.TAB.LOCATION.TITLE" defaultMessage="City" />
                  </th>
                  <th scope="col" className="px-6 py-4 text-base ">
                  <FormattedMessage id="RECRUITER.CUSTOMER.TAB.STATE.TITLE" defaultMessage="State" />
                  </th>
                  <th scope="col" className="px-6 py-4 text-base ">
                  <FormattedMessage id="RECRUITER.CUSTOMER.TAB.ZIPCODE.TITLE" defaultMessage="Zip Code" />
                  </th>
                  <th scope="col" className="px-6 py-4 text-base ">
                  <FormattedMessage id="RECRUITER.CUSTOMER.TAB.COUNTRY.TITLE" defaultMessage="Country" />
                  </th>
                  <th scope="col" className="px-6 py-4 text-base ">
                  <FormattedMessage id="RECRUITER.CUSTOMER.TAB.COUNTRY.ACTION.TITLE" defaultMessage="Actions" />
                  </th>
              </tr>
            </thead>
            <tbody>
              {customerLocations?.map((item, index)=> {
                return (
                  <tr key={index} className="bg-white border-b  hover:bg-gray-50">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900">                
                        <div className='text-base'>{item?.address?.address_line_1}</div>                    
                    </th>
                    <td className="px-6 py-4">{item?.address?.address_line_2}</td>
                    <td className="px-6 py-4">{item?.address?.city}</td>
                    <td className="px-6 py-4">{item?.address?.state}</td>
                    <td className="px-6 py-4">{item?.address?.postal}</td>
                    <td className="px-6 py-4">{item?.address?.country}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
    
                   
    {checkAssigned(item?.id) ? 
    <div className="flex space-x-3">
      <button className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy'>
      Assigned
    </button>
    <button onClick={()=> handleRemoveLocation(item?.id)} className='rounded-lg font-bold text-white px-5 py-2  bg-red-500  border-2 cursor-pointer hover:text-white hover:border-white '>
    UnAssign
    </button>
    </div>
    :
       <button onClick={()=> AssignLocation(item?.id)} className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy'>
      Assign Location
    </button>
              }
                    
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
    
      {/* <div className="flex justify-center mt-3">
       <button onClick={()=> AssignLocation()} className='rounded-lg font-bold bg-[#ff39b3] font-bold px-5 py-2  text-white cursor-pointer hover:bg-navy'>
      Assign Location
    </button>
    </div> */}
    </ContentWithSpinner>
);
};
