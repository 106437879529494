import React, {useEffect, useState} from 'react'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { Input } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { payTypes } from 'sr/i18n/messages/setting'
export const Range = (props) => {
  const [pay, setPay] = useState('per hour')
  const [minAmount, setMinAmount] = useState()
  const [maxAmount, setMaxAmount] = useState()
  const HandleChange = (e) => {
    setPay(e.target.value)
}
useEffect(()=> {
setMaxAmount(props?.maxAmount)
setMinAmount(props?.minAmount)
setPay(props?.pay)
},[])
const RangeData = {
  min_amount:minAmount,
  max_amount:maxAmount,
  payType:pay ? pay : 'per hour'
}
useEffect(()=> {
  props.HandleShowPay(RangeData)
},[maxAmount, maxAmount, pay])

  return (
    <div className='flex space-x-4 w-full mt-10 items-start'>
      <div className='relative flex w-1/3'>
      <div className='absolute top-[34px] inset-0 z-50 w-6 h-6'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33" />
</svg>


      </div>
    <Input 
    onChange={(e)=> setMinAmount(e.target.value)}
    value={minAmount}
     label = {<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.MINIMUM.TITLE" defaultMessage="Minimum" />}
     placeholder="Enter Min Amount"
     type={'text'}
       inputClass = 'border border-zinc-800 rounded-lg h-11'
       InputDivClass = "mt-0 h-full w-full"
       labelClass={'mt-2 text-sm text-normal font-satoshiBold'}
       min={1}
       error={props?.errors?.min_amount}
    />

    </div>
    <div className='relative flex w-1/3'>
      <div className='absolute top-[34px] inset-0 z-50 w-6 h-6'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33" />
</svg>


      </div>
     <Input 
     onChange={(e)=> setMaxAmount(e.target.value)}
     handleBlur={props?.handleBlur}
     value={maxAmount}
     id='max_amount'
     name='max_amount'
     label = {<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.MAXIMUM.TITLE" defaultMessage="Maximum" />}
     placeholder="Enter Max Amount"
     type={'text'}
     labelClass={'mt-2 text-sm text-normal font-satoshiBold'}
       inputClass = 'border border-zinc-800 rounded-lg h-11'
       InputDivClass = "h-full w-full"
       min={1}
       error={props?.errors?.max_amount}
    />
    </div>
    <DropDown
    onChange={HandleChange}
    labelMargin={'mt-1 text-sm text-normal font-satoshiBold'}
    value={pay}
    dropdowndiv='flex-1' 
    name="Rate" 

    label={<FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.TITLE" defaultMessage="Rate" />}
    className="h-11 border border-zinc-800 rounded-lg"
    
   >
       {payTypes.map((data)=> (
<option value={data.value}>{data.label}</option>
           ))}
       </DropDown>
       </div> 
  )
}
