import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'

// Define a type for the slice state
interface RegisterCompany {
    username: string,
    email: any,
    mobile_number: any,
    company_name: any,
    business_type: any,
    intent: any,
    candidate_msg:any,
    status: any,
    id:any
}

// Define the initial state using that type
const initialState: RegisterCompany = {
    username: '',
    email: '',
    mobile_number: '',
    company_name: '',
    business_type: '',
    intent: '',
    candidate_msg:'',
    status: '',
    id:''
}

const registerCompanySlice = createSlice({
    name: 'RegisterCompany',
    initialState,
    reducers: {
      // Define an action to update the state with the registered user data
      registerUserCompany: (state, action) => {
        return action.payload;
      },
      // Add other actions if needed
    },
  });
  export const { reducer } = registerCompanySlice;
  export const { registerUserCompany } = registerCompanySlice.actions;
    