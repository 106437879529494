import React, { useEffect, useState } from "react";
import { RadioInput } from 'sr/helpers/ui-components/company/RadioInput'
import { FormattedMessage } from "react-intl";
export const RequireResume = (props) => {
    const [resumeRequire, setResumeRequire] = useState(false)
    useEffect(()=> {
        setResumeRequire(props?.data?.require_resume)
    },[props?.data])
    useEffect(()=> {
      const data = {
        require_resume:resumeRequire
      }
      props.HandleDataProps(data)
      
    },[resumeRequire])
  return ( 
    <div className='pt-10'>
    <p className='text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.REQUIRERESUME.TITLE" defaultMessage="Require a resume to apply?" /> </p>   
   <RadioInput 
   name="Yes" value={true} checked={resumeRequire === true} 
   onChange= {(e:any)=>setResumeRequire(true) } 
   label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.REQUIRERESUME.YES" defaultMessage="Yes, require it" />}
   inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
   <RadioInput 
   name="No"
    value={false}
     checked={resumeRequire === false} 
      onChange={(e:any)=>setResumeRequire(false) }
      label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.REQUIRERESUME.NO" defaultMessage="No, don’t ask for it" />}
      inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
      </div>);
};
