import React, { useEffect, useState } from "react";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import Footer from "app/pages/module/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle, faCircleCheck, faStar } from '@fortawesome/free-solid-svg-icons'
import { CheckBox } from "sr/helpers/ui-components/company/CheckBox";
import { Button, Input } from "sr/helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { useNavigate, useParams } from "react-router-dom";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { getTask } from "sr/utils/api/company/customer/getTask";
import { createtaskManage } from "sr/utils/api/company/workorder/createtaskManage";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { getContractor } from "sr/utils/api/company/workorder/getContractor";
import { getTaskManage } from "sr/utils/api/company/workorder/getTaskManage";
import ContentWithLoader from "sr/layout/common/ContentLoader";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { toast } from "react-toastify";
import { updateWorkorder } from "sr/utils/api/company/workorder/updateWorkorder";
import { date } from "yup";
import { DateFormatForApi } from "sr/helpers/functions/DateFormatForApi";
import { manageTaskSubmit } from "sr/utils/api/company/workorder/manageTaskSubmit";
import { getManageTaskSubmit } from "sr/utils/api/company/workorder/getManageTaskSubmit";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { ModelWrapperWithButton } from "app/pages/company/component/ModelWrapperWithButton";
import { TaskDetailsCard } from "./component/TaskDetailsCard";

export const MyJobDetails = () => {
  const {getData:getWorkOrders, data:workorder, loader:loaderWorkOrder, pageination:pageination}:any = useGet()
  const {data:checklistData, loader:checklistLoader, getData:getChecklists} = useGet()
  const [loader, setLoader] = useState(false)
  const [workHours, setWorkHours] = useState(0)
  const intl = useIntl()
  const params = useParams()
  const [workorderId, setWorkOrderId] = useState<any>()
  const [cleanerId, setCleanerId] = useState()
  const {data:contractor, getData:getContractorDetails, loader:contractorLoader} = useGet()
  const {data:managetasks, getData:getManageTaskList, loader:taskmanageLoader} = useGet()
 const {data:submittask, getData:getTaskSubmitted, loader:trackloader} = useGet()
 const navigate = useNavigate()
 const fetchManageTask = async ()=> {
  const queryParams = new URLSearchParams()
  if (workorderId) queryParams?.append('workorder_id', workorderId)
  await getManageTaskList(()=> getTaskManage(queryParams))
  return true;
}
const fetchTaskTrack = async ()=> {
const queryParams = new URLSearchParams()
  if (workorderId) queryParams?.append('workorder_id', workorderId)
    await getTaskSubmitted(()=> getManageTaskSubmit(queryParams)) 

return true
}
useEffect(()=> {
if(workorderId) {
fetchTaskTrack()
fetchManageTask()
}
},[workorderId])

  

  useEffect(()=> {
      const {user_id} = getUserDetails()
      setCleanerId(user_id)
  },[])

  useEffect(()=> {
    const FetchContractor = async (cleanerId)=> {
      const useParams = new URLSearchParams()
      if (cleanerId) useParams?.append('cleaner_id', cleanerId)
    await getContractorDetails(()=> getContractor(useParams))
}
if (cleanerId) {
  FetchContractor(cleanerId)
}
  },[cleanerId])

  useEffect(()=> {
    const {id} = params
      setWorkOrderId(id)
  },[params])
    const stepperIndex = 2;
    const fetchWorkOrder = async (workorderId)=> {
      const queryParams = new URLSearchParams()
      if (workorderId) queryParams.append('id', workorderId)
        await getWorkOrders(() => getWorkorder(queryParams));
  }
    useEffect(()=> {
   
      if(workorderId) {
        fetchWorkOrder(workorderId)
      }
  },[workorderId])
  useEffect(()=> {
    const fetchChecklist = async (id)=> {
      const queryParams = new URLSearchParams()
      if (id) queryParams.append('checklist_id', id)
          await getChecklists(()=> getTask(queryParams))
}
      if(workorder?.checklist_id?._id) {
        fetchChecklist(workorder?.checklist_id?._id)
      }

  },[workorder?.checklist_id?._id])
    const HandleTaskSubmit = async  ()=> {
      setLoader(true)
      let camt = true;
      const mandatoryTasks = checklistData?.filter((item)=> item?.type=== 'Mandatory')
      if(mandatoryTasks?.length>0){
          for(let i=0; i<mandatoryTasks?.length; i++){
              const result = managetasks?.some((item)=> item?.task_id?._id=== mandatoryTasks[i]?.id)
              if(result===true){
                
              }
              else {
                toast.error('You have not completed all mandatory tasks')
                camt = false
                setLoader(false)
                break
              }
          }
      }

      if(camt===true){
        console.log(workorderId);
        
        navigate(`/review-work-order/${workorderId}`)
setLoader(false)
      }
      
    }
    const HandleStartWorkOrder = async ()=> {
      setLoader(true)
      const payload = {
        id:workorder?.id,
        workorder_status:'in progress',
      }
      const res = await updateWorkorder(payload)
      if(res?.success===true) {
        await fetchWorkOrder(workorderId)
        setLoader(false)
        toast.success('Your Work Order is Started')
      }
    }
    
   
  return ( <>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <main>
      {loader && <LoaderFullScreen />}
      <ContentWithSpinner loader={checklistLoader} height="h-screen">
    <div className="py-3 lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0">
        <div className='mb-5'>
    <h1 className='text-2xl font-satoshiBold'>
      <FormattedMessage 
      id="MYJOB.MANAGE_JOB.TITLE"
      defaultMessage="Mark As Completed"
      />
    </h1>
   </div>
   <div className="bg-white rounded-[20px] shadow-sm shadow-slate-300">
    {
    workorder?.workorder_status==='scheduled' ?
    <div className="flex justify-center my-3 py-10">
    <Button onClick={()=> HandleStartWorkOrder()} text={'Start Work Order'} className={'bg-[#2557A7] text-white capitalize '} hoverbg={'bg-[#2557A7]'}></Button>
    </div>
    :
    submittask?.length>0 &&submittask[0]?.status==='rejected' ?
    <div className="text-center py-10 "> The Work Order is Rejected from Supervisor </div>
    :
   submittask?.length>0 && submittask[0]?.contractor_status==='completed' ? 

   submittask?.length>0 && submittask[0]?.supervisor_status==='completed' ?
      <div className="text-center py-10 ">{`Work Order completed with approved ${submittask[0]?.approve_time} hours successfully`}</div>
      :
        <div className="text-center py-10 "> Task Submitted Successfully </div>
        :  
        <>
   { checklistData?.map((item, index)=> (
   <TaskDetailsCard item={item} managetasks={managetasks} workorderId={workorderId} contractor={contractor} fetchManageTask={fetchManageTask}/>
   ))}

   <div className="flex justify-center my-3">
<Button onClick={()=> HandleTaskSubmit()} text={<FormattedMessage id="MYJOB.NEXT.BUTTON" defaultMessage="Next" />} className={'bg-[#2557A7] text-white capitalize '} hoverbg={'bg-[#2557A7]'}></Button>
   </div>
   </>
  }
</div>
   </div>
   </ContentWithSpinner>
</main>
<Footer />
</>
  );
};
