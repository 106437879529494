import ApiResponse from "sr/models/ApiResponse";
import { get } from "../axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
export const getFiles = async (params) => {
try {
    
    const queryParams = new URLSearchParams();  
    if (params?.company_id) queryParams.append('company_id', params?.company_id.toString());
    if (params?.cleaner_id) queryParams.append('cleaner_id', params?.cleaner_id.toString());
    if (params?.individual_id) queryParams.append('individual_id', params?.individual_id.toString());
    console.log(queryParams);
    
        return await get<ApiResponse<[]>>(`/util/findfile?${queryParams}`).then((res)=> {
    if(res.success===true) {
        return res
    }
    else {
        return null
    }
})
}
catch(e:any) {
    HandleApiError(e)
}
};
