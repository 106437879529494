import React, {useEffect, useState} from 'react'
import { toAbsoluteUrl } from '../../../../sr/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faL, faMagnifyingGlass, faSearch } from '@fortawesome/free-solid-svg-icons'
import { SidebarMenuLinks } from './SidebarMenuLinks'
import { FormattedMessage, useIntl } from 'react-intl';
import { Sidebar } from 'app/pages/cleaner/component/Sidebar'
import { useAuth } from './core/Auth'
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getFiles } from 'sr/utils/api/getFiles'
import { getFile } from 'sr/utils/api/getFile'
import { getCleaner } from 'sr/utils/api/cleaner/getCleaner'
import CleanerType from 'app/pages/cleaner/component/type/CleanerType'

export default function AuthNavComponent(props) {
    const intl = useIntl()
    const [isOpened, setIsOpened] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const {isAuthenticate, userType} = useAuth()
    const [search, setSearch] = useState()
    const [show, setshow] = useState(false)
    const [profileImg, setProfileImg] = useState<string>('')
    const [cleanerId, setCleanerId] = useState<string>()
    const [cleanerData, setCleanerData] = useState<CleanerType>(null)
    const ToggleHandle = () => {
        setIsOpened(!isOpened);
    }
    const navigate = useNavigate()
    const SidebarHandle = ()=> { 
            setSidebarOpen(false)
    }
    useEffect(()=> {
if(isAuthenticate===false) {
  setSidebarOpen(false)
}
    },[isAuthenticate])
    const HandleSubmit = (e)=> {
      e.preventDefault()
     if(e.target[0].value){
      navigate(`/search/${e.target[0].value}`)
     }
     
    }
    useEffect(()=> {
      const getdetails = getUserDetails()
setCleanerId(getdetails?.user_id)
    },[])

    const fetchProfileImg = async (cleanerId)=> {
      const params = {
        cleaner_id:cleanerId
      }
  const profileImg = await getFiles(params)
  if(profileImg?.success===true) {
    const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
    if(filterfile && filterfile[0]?.file?.key) {
      const fileimg = await getFile(filterfile[0]?.file?.key);
      console.log(fileimg);
      
      if(fileimg) {
      // const rawImageData = new Uint8Array(fileimg as ArrayBuffer);
      // const blob = new Blob([rawImageData], { type: 'image/png' });
      const newImageUrl = URL.createObjectURL(fileimg);
      setProfileImg(newImageUrl)
      }
      
    }}
    }
    // const fetchCleaner = async (cleanerId) => {
    //   const cleanerData = await getCleaner(undefined, cleanerId);
      
    //   if (cleanerData?.success) {
    //     setCleanerData(cleanerData?.data);

    //   }
    // };
    useEffect(()=> {
if(cleanerId) {
  fetchProfileImg(cleanerId)
}

    },[cleanerId])

    return (<>
        <nav className={` ${isOpened? 'z-50	sticky top-0 inset-x-0 ': 'static'} bg-navy text-white flex items-center p-4`}>
            <Link to="/" className='z-50'>
            <img width={150} src={toAbsoluteUrl('/media/logos/logo2.png')} alt=''
                className='bg-white rounded-lg' />
                </Link>
            {
                props.isLoginPage &&
                (
                    <ul className='flex-1 flex justify-end'>
                        <Link to='/create-company' replace>
                            <li className='cursor-pointer hover:text-pink font-mono'> <FormattedMessage
        id="AUTH.GENERAL.REGISTER.CLEANING.COMPANY"
        defaultMessage="Register as Cleaning Company"
      /></li>
                        </Link>
                    </ul>
                )
            }

            {
                props.isLoggedin && ( <>
                <div
            className={`z-20 opacity-100 lg:flex lg:justify-end lg:grow fixed inset-0 bg-white lg:bg-transparent shadow-xl transition duration-300 lg:w-20 w-[80%] lg:static lg:shadow-none lg:translate-x-0 ${
              isOpened ? "translate-x-0 delay-100	dark:bg-gray-800 border-gray-100":'-translate-x-[100%]'
            }`}
          >
            <hr className="border-gray-200 my-2 mt-24 lg:hidden" />
            <div className='flex lg:hidden items-center space-x-4 px-4'>
            <div className="radius-50 ">
                <img alt="Profile" className="rounded-full w-14 h-14" src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}/>
                </div>
            <div className='text-black'>
            <div className="font-bold text-base">{cleanerData?.first_name} {cleanerData?.last_name}</div>
                </div> 
            </div>
            <hr className="border-gray-200 my-2 lg:hidden" />
            <SidebarMenuLinks />
                    <ul className='lg:flex-1 lg:flex lg:justify-center lg:items-center lg:text-white text-black lg:m-0 hidden'>
                       <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'> <Link  to='/cleaner-jobs' replace className='mr-10 hover:text-pink'>
                          <FormattedMessage 
                          id='NAVBAR.COMPANY.JOBS.TITLE'
                          defaultMessage='Jobs'
                         />
                            </Link>
                            <div className="group-hover:block dropdown-menu absolute hidden h-auto z-10">
                            {isAuthenticate && userType === 'cleaner' &&
        <ul className="top-0 w-64 bg-white shadow px-6 py-8 rounded-lg">
        <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/applied-jobs'>
            <FormattedMessage 
                          id='NAVBAR.APPLIED.JOBS.TITLE'
                          defaultMessage='Applied Jobs'
                         />
                         
                         </Link></li>
            <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/saved-jobs'>
            <FormattedMessage 
                          id='NAVBAR.SAVED.JOBS.TITLE'
                          defaultMessage='Saved Jobs'
                         />
                </Link></li>
        </ul>
}
        </div>
                            </li>
                            <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'>
                                 <Link  to='/individual-jobs' replace className='mr-10 hover:text-pink'>
                                 <FormattedMessage 
                          id='NAVBAR.INDIVIDUAL.JOBS.TITLE'
                          defaultMessage='Individual Jobs'
                         />
                            </Link>
                            <div className="group-hover:block dropdown-menu absolute hidden h-auto z-10">

        <ul className="top-0 w-64 bg-white shadow px-6 py-8 rounded-lg">
        <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/individual/applied-jobs'>
        <FormattedMessage 
                          id='NAVBAR.APPLIED.JOBS.TITLE'
                          defaultMessage='Applied Jobs'
                         />
            </Link></li>
            <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/individual/saved-jobs'>
            <FormattedMessage 
                          id='NAVBAR.SAVED.JOBS.TITLE'
                          defaultMessage='Saved Jobs'
                         />
                </Link></li>

        </ul>
        </div>
                            </li>
                            
                            <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'>
                                 <Link  to='/cleaner/work-order' replace className='mr-10 hover:text-pink'>
                                 <FormattedMessage 
                          id='NAVBAR.WORK.ORDER.TITLE'
                          defaultMessage='Work Order'
                         />
                             </Link>
                             {isAuthenticate && userType === 'cleaner' &&
                             <div className="group-hover:block dropdown-menu absolute hidden h-auto z-10">

<ul className="top-0 w-64 bg-white shadow px-6 py-8 rounded-lg">
    {/* <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/pending-jobs'>
    <FormattedMessage 
                  id='NAVBAR.PENDING.JOBS.TITLE'
                  defaultMessage='Pending Workorder'
                 />
        </Link></li> */}
        <li className="py-1"><Link className="block text-base cursor-pointer text-black" to='/cleaner/applied-work-order'> <FormattedMessage 
                  id='NAVBAR.APPLIED.WORK.ORDER.TITLE'
                  defaultMessage='Applied Work Order'
                 /></Link></li>
                                             <li className='py-1'>
                                 <Link  to='/my-jobs' className='block text-base cursor-pointer text-black'>
                                 <FormattedMessage 
                          id='NAVBAR.MY.JOBS.TITLE'
                          defaultMessage='My Jobs'
                         />
                             </Link>
                       
                            </li>
                            <li className='py-1'>
                                 <Link  to='/saved-work-order' className='block text-base cursor-pointer text-black'>
                                 <FormattedMessage 
                          id='NAVBAR.SAVED.WORK.ORDER.TITLE'
                          defaultMessage='Saved Work Order'
                         />
                             </Link>
                       
                            </li>
</ul>
</div>
}
                            </li>
                        
                            <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'> <Link  to='/companies' replace className='mr-10 hover:text-pink'>
                            <FormattedMessage 
                          id='NAVBAR.COMPANIES.TITLE'
                          defaultMessage='Companies'
                         />  </Link>
                       
                            </li>
{/* 
                            <li className='group  relative dropdown cursor-pointer font-mono font-bold lg:font-normal py-2 px-4 lg:p-0'> 
                            <Link  to='/cleaner/message' replace className='mr-10 hover:text-pink'>
                            Message  </Link>
                        
                            </li> */}
                    </ul> </div>
                    <div className='lg:block hidden'>
                      <form onSubmit={(e:any)=> HandleSubmit(e)} className='relative flex'>
                                                <input onClick={()=> setshow(true)} type='search' className='w-60 h-10 rounded-3xl p-5 text-gray-900'
                                                    placeholder={intl.formatMessage({
                                                        id:"INPUT.SEARCH.JOBS.PLACEHOLDER",
                                                        defaultMessage:"Search jobs"
                                                    }) }>
                                                </input>
                              <button className='h-8  w-8 rounded-full bg-pink flex justify-center items-center absolute top-1 right-2'>              
                              <FontAwesomeIcon icon={faMagnifyingGlass} className='text-white text-lg font-bold font-satoshiBold' />
                              </button>   
                              </form>
                                            </div>
                    
                                            <div className='profile-container flex-1'>
                                                <div className='flex gap-4 items-center justify-end'>
                                               {isAuthenticate && userType === 'cleaner' ?
                                                    <div onClick={()=> setSidebarOpen(true)} className='p-[2px] bg-white rounded-full float-right cursor-pointer'>
                                                        <img
                                                            className='lg:w-14 lg:h-14 w-10 h-10 rounded-full'
                                                            src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}></img>
                                                    </div>
                                                   :<>
                                                   <Link to={'/auth'} className="bg-transparent hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded">
  Login
</Link>
<Link to={'/create-cleaner'} className="bg-pink hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border rounded">
  Register
</Link>
</>
                                                }
                                             
          
                                                <div onClick={ToggleHandle} className="lg:hidden z-20">
              <div
                className={`w-6 h-0.5 rounded bg-white transation duration-300 ${
                  isOpened ? "translate-y-2 rotate-45" : ""
                } `}
              ></div>
              <div
                className={`w-6 h-0.5 mt-1.5 rounded bg-white transation duration-300 ${
                  isOpened ? "opacity-0" : ""
                } `}
              ></div>
              <div
                className={`w-6 h-0.5 mt-1.5 rounded bg-white transation duration-300 ${
                  isOpened ? "-translate-y-2 -rotate-45" : ""
                } `}
              ></div>
            </div>
          </div>                                            </div>
                    </>
                                               
                )
            }

<span onClick={ToggleHandle} className={`fixed w-full h-full top-0 left-0 delay-100 visible opacity-100 bg-[#00000099] ${isOpened ? 'block':'hidden'}`}></span>
        </nav>
        <Sidebar sidebarOpen={sidebarOpen} SidebarHandle={SidebarHandle}></Sidebar>
        </>
    )
}
