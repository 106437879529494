import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createApplication = async (postData:any) => {
  try {
    return await post<ApiResponse<[]>>('/application/', postData).then((res)=> {
        if(res.success===true) {
         SuccessMessage("API.SUCCESS.APPLY.JOB")
            return res
        }
        else {
            toast.error(res.error)
            return null
        }
    })
  }
  catch(e:any) {
        HandleApiError(e)
        return null
  }
};
