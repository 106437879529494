
import React, {useEffect, useState} from "react";
import { Input } from "sr/helpers";
import { MainWrapper } from "../component/MainWrapper";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { useDispatch, useSelector } from 'react-redux';
import { updateForm } from "sr/redux/reducers/individualJob";
import { getAddress } from "sr/utils/api/individual/address/getAddress";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { createTasklist } from "sr/utils/api/company/customer/createTasklist";
import { getTasklist } from "sr/utils/api/individual/tasklist/getTasklist";
import { Button } from "sr/helpers/ui-components/company/Button";
import { useFormik } from "formik";
import { addJob } from "sr/utils/api/individual/job/addJob";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import getLabelByValue from "sr/helpers/functions/getLabelByValue";
import { jobTypes } from "app/pages/company/jobpost/component/Jsonalldata";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
export const JobPostDetails = () => {
  const intl = useIntl()
    const [street, setStreet] = useState("")
    const [unitStreet, setUnitStreet] = useState("")
    const [showAddress, setShowAddress] = useState(true)
    const [showtasklist, setShowTasklist] = useState(true)
    const [location, setLocation] = useState()
    const [description, setDescription] = useState('')
    const [tasklist, setTasklist] = useState()
    const [userId, setUserId] = useState<string>('')
    const [tasklists, setTasklists] = useState([])
    const [locations, setLocations] = useState([])
    const [loader, setLoader] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    const [from, setFrom] = useState()
    const [to, setTo] = useState()
    const formData = useSelector((state:any) => state.form); 
    const dispatch = useDispatch()
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserId(user_id)
    },[])
    const HandleAddress = ()=> {
        setShowAddress(false)
    }
    const payload =  {
streetAddress: street,
unitorapt: unitStreet,
address: location,
description: description,
location_id: location,
schedule_date:startDate,
from:from,
to:to
    }
    const handleUpdateForm = () => {

      dispatch(updateForm(payload));
    };
    const fetchLocation = async (userId)=> {
    try {
      const params = new URLSearchParams()
     if (userId) params.append('individual_id', userId)
      params.append('status!', 'deleted')
    const res = await getAddress(params)
     if(res?.success===true) {
      setLocations(res?.data)
      // setLoader('')
     }
     else {
    // setLoader('')
     }
    }
    catch(e:any) {
      // setLoader('')
    }
    }
    // const fetchTasklisk = async (userId)=> {
    //   const params = new URLSearchParams()
    //   params.append('individual_id', userId)
    //   params.append('status!', 'deleted')
    //   try {
    //       const res = await getTasklist(params)
    //       if(res?.success===true) {
    //         setTasklists(res?.data)
    //       }
    //       else {
    //       }
    //   }
    //   catch(e:any) {
    //   }
    // }
    useEffect(()=> {
if(userId) {
  fetchLocation(userId)
  // fetchTasklisk(userId)
}
    },[userId])
    const navigate = useNavigate()
    
    const {submitForm} = useFormik({
      enableReinitialize:true,
      initialValues:{individual_id:userId, status:'publish', work_status:'open', ...payload, title:formData.title},
      onSubmit:async (values)=> {
        setLoader(false)
        try {
          const res = await addJob(values)
          if(res?.success===true) {
            setLoader(false)
            navigate('/jobs')
          }
          else {
              setLoader(false)
          }
        }
        catch(e:any) {
          setLoader(false)
          HandleApiError(e)
        }
      }
    })
    const topHeader = (
      <div className="bg-white flex justify-center w-full space-x-2 border border-neutral-200 py-3">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
<p><FormattedMessage id="INDIVIDUAL.BOOKTASK.TOP.DESCRIPTION" defaultMessage="Tell us about your task. We use these details to show cleaner in your area who fit your needs." />
</p>
      </div>
    )
  return (
    <MainWrapper topHeader={topHeader}>
      {loader && <LoaderFullScreen />}
    <div className=''>
        <div className="lg:mx-10 mx-0 md:mx-5 py-5">
        <p className="text-lg font-satoshiBold pb-2">{getLabelByValue(formData?.title, jobTypes)}</p>
        <div className="bg-white rounded-lg p-4 text-base border border-neutral-300 mb-3">
            <div className="flex justify-between">
        <p className="text-base pb-3 font-satoshiBold"><FormattedMessage id="INDIVIDUAL.BOOKTASK.INPUT.TASK.LOCATION.TITLE" defaultMessage="Your task location" /></p>
        {!showAddress && 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#2557A7]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
      
        }
        </div>
        {showAddress ? <>
        <div>
        <Input onChange={(e:any)=> setStreet(e.target.value)} value={street} inputClass="border border-neutral-400 rounded-lg h-11" InputDivClass="mt-0 mb-3" placeholder={intl.formatMessage({id:"INDIVIDUAL.BOOKTASK.INPUT.TASK.STREET.PLACEHOLDER", defaultMessage:"Street address"})}></Input>
        <Input onChange={(e:any)=> setUnitStreet(e.target.value)} value={unitStreet}  inputClass="border border-neutral-400 rounded-lg h-11" InputDivClass="mt-0 mb-3" placeholder={intl.formatMessage({id:"INDIVIDUAL.BOOKTASK.INPUT.TASK.UNIT.PLACEHOLDER", defaultMessage:"Unit or Apt #"})}></Input>
        <div className="font-satoshiBold text-lg text-center pt-2">Or</div>
        <DropDown labelClass='xl:text-base lg:text-sm' value={location} label={intl.formatMessage({
    id: 'RECRUITER.WORKORDER.FILTER.LOCATION',
    defaultMessage: 'Location',
  })}
 dropdowndiv='mt-0' onChange={(e) => setLocation(e.target.value)} className='rounded-lg' >
  <option value=''><FormattedMessage id="RECRUITER.WORKORDER.FILTER.SELECT.LOCATION" defaultMessage="Select Location" /></option>
{locations?.map((item)=> (
  <option value={item?.id}>{item?.address?.address_line_1.slice(0,10)} {item?.address?.city}</option>
))
}
  </DropDown>
</div>
        <div className="flex justify-center pt-3">
        <div onClick={HandleAddress} className="text-center rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy" ><FormattedMessage id="INDIVIDUAL.BOOKTASK.CONTINUE.BUTTON" defaultMessage="Continue" /></div>
        </div>
        </>:
        <div className="flex justify-between">
            <div className="flex space-x-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>
<p>{street} {unitStreet}</p>
            </div>
<p className="text-[#2557A7]">Good News JMM is Available in your area</p>
        </div> }
        </div>
        <div className="bg-white rounded-lg p-4 text-base border border-neutral-300 mb-3">
            <div className="flex justify-between">
        <p className="text-base pb-3 font-satoshiBold"><FormattedMessage id="INDIVIDUAL.BOOKTASK.INPUT.TASK.TITLE" defaultMessage="Tell us the details of your task" /></p>
        {!showAddress && 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#2557A7]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
      
        }
        </div>
        <textarea value={description} onChange={(e)=> setDescription(e.target.value)} rows={3} className="flex-1 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#2557A7] focus:shadow-inputshadow outline-0" 
        placeholder={intl.formatMessage({id:"INDIVIDUAL.BOOKTASK.INPUT.TASK.PLACEHOLDER", defaultMessage:"Provide a summary of what you need done for your tasker."}) }>
        </textarea>
        </div>
        <div className="bg-white rounded-lg p-4 text-base border border-neutral-300 mb-3">
        <ReactDatePicker
                      onChange={(date)=> setStartDate(date)}
                      selected={startDate}
                      label={
                        <FormattedMessage
                          id="RECRUITER.SCHEDULEWORKORDER.INPUT.SCHEDULEDATE"
                          defaultMessage="Schedule Date"
                        />
                      }
                      inputClass="rounded-lg"
                      labelClass="mt-2 text-black"
                      InputDivClass="mt-0 w-26 pr-2 pb-2"
                      type="date"
                      placeholder="Schedule date"
                    ></ReactDatePicker>
                    <div className="">
                      <p className="text-lg text-black font-satoshiBold">Date Range</p>
                        <Input
                    onChange={(e)=> setFrom(e.target.value)}
                    value={from}
                    label={
                      <FormattedMessage
                        id="INDIVIDUAL.CLEANER.SCHEDULEWORKORDER.INPUT.FROM"
                        defaultMessage="From"
                      />
                    }
                    inputClass="rounded-lg"
                    labelClass="mt-2 text-black"
                    InputDivClass="mt-0 pr-2 pb-2"
                    type="time"
                    placeholder={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.ENTRYTIME"
                        defaultMessage="Entry Time"
                      />
                    }
                  ></Input>
                           <Input
                    onChange={(e)=> setTo(e.target.value)}
                    value={to}
                    label={
                      <FormattedMessage
                        id="INDIVIDUAL.CLEANER.SCHEDULEWORKORDER.INPUT.TO"
                        defaultMessage="To"
                      />
                    }
                    inputClass="rounded-lg"
                    labelClass="mt-2 text-black"
                    InputDivClass="mt-0 pr-2 pb-2"
                    type="time"
                    placeholder={
                      <FormattedMessage
                        id="RECRUITER.SCHEDULEWORKORDER.INPUT.ENTRYTIME"
                        defaultMessage="Entry Time"
                      />
                    }
                  ></Input>
                  </div>
                       <div className="flex justify-center pt-3 space-x-2">
        <button onClick={submitForm} className="text-center rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy">Post Job</button>
        <Link onClick={()=> handleUpdateForm()} to={'/individual/cleaner-profiles'} className="text-center rounded-lg font-bold bg-[#2557A7] px-5 py-2  text-white cursor-pointer hover:bg-navy"><FormattedMessage id="INDIVIDUAL.BOOKTASK.CLEANERSSHOW.BUTTON" defaultMessage="See Cleaner & Prices" /></Link>
        
        </div>
          </div>
    </div></div>
    </MainWrapper>
  );
};
