import * as Yup from 'yup'

const CustomerSchema = Yup.object({
    name:Yup.string().required('INPUT.VALIDATION.CUSTOMER.NAME'),
    mobile_number:Yup.string().required('INPUT.VALIDATION.CUSTOMER.MOBILE.NO'),
    email:Yup.string().email().required('INPUT.VALIDATION.CUSTOMER.EMAIL'),
    type:Yup.string().required('INPUT.VALIDATION.CUSTOMER.TYPE'),
    remarks:Yup.string()
})

export default CustomerSchema;