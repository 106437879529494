import React from "react";
import { createIntl, createIntlCache } from 'react-intl';
import { toast } from "react-toastify";
import data from '../../i18n/messages/en.json'
const cache = createIntlCache();
let allerrormessage = {}
const fetchData = async ()=> {
  for (let key in data) {
    if (key.startsWith("API.SUCCESS")) {
      
      allerrormessage[key] = data[key]
  }
}
}

const intl = createIntl(
  {
    locale: 'en',
    messages:allerrormessage,
  },
  cache
);
export const SuccessMessage = (message, values={}) => {
  fetchData()
    const translatedMessage = intl.formatMessage({
        id: message,
        defaultMessage: 'Success',
      },
    values);
      toast.success(translatedMessage)
};