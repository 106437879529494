import { useEffect,useState } from 'react'
import AuthNavComponent from '../module/auth/AuthNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle, faCircleCheck, faX } from '@fortawesome/free-solid-svg-icons'
import Footer from '../module/Footer'
import {FormattedMessage } from 'react-intl'
import { getApplication } from 'sr/utils/api/cleaner/getApplication'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { DaysConverter } from 'sr/helpers/functions/DaysConverter'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { toAbsoluteUrl } from 'sr/helpers'
import { ChatPopup } from './component/ChatPopup'
import { ApplicationTab } from '../component/ApplicationTab'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { ApplicationStatus } from 'sr/i18n/messages/setting'
import { updateApplication } from 'sr/utils/api/cleaner/updateApplication'
import { toast } from 'react-toastify'
export default function AppliedJobs() {
    const [cleanerId, setCleanerId] = useState(null)
    const [totalApplied, settotalApplied] = useState(null)
    const [currentJob, setCurrentJob] = useState(null)
    const [loader, setLoader] = useState(false)
    const [applicationUpdateCount, setapplicationUpdateCount] = useState(0)
    const [activetab, setActiveTab] = useState('active')
    const [tabCount, setTabCount] = useState<any>({})
    const [filteredJob, setFilteredJob] = useState([])
    useEffect(() => {
        const getdetails = getUserDetails();
        if (getdetails) {
          setCleanerId(getdetails.user_id);
        }
      }, []);
    const stepperIndex = 2;
    const count = 5;
    const totalAppliedC = totalApplied?.length
    const fetchAppliedJobs = async ()=> {
        setLoader(true)
        try {
            const queryParams = new URLSearchParams();  
           queryParams.append('cleaner_id', cleanerId.toString());
            const result = await getApplication(queryParams)
            if(result.success===true) {
                settotalApplied(result?.data)
                setTabCount(result?.pagination?.statusCounts)
                let applicationUpdate = 0
                for(let i = 0; i<result?.data?.length; i++) {
                    if(result?.data[i]?.createdAt !== result?.data[i]?.updatedAt) {
                        applicationUpdate++
                    }
                }
                setapplicationUpdateCount(applicationUpdate)
                setLoader(false)
            }
        }
        catch(e) {
setLoader(false)
        }
    }
    useEffect(()=> {
     
        if(cleanerId) {
            fetchAppliedJobs()
        }
    },[cleanerId])

    useEffect(()=> {
        if(filteredJob?.length>0){
       setCurrentJob(filteredJob[0])
        }
        else {
            setCurrentJob('')
        }
    },[filteredJob])
    const HandleCurrentJob = (id) => {
            const res = filteredJob.find((item)=> item.id===id)
            setCurrentJob(res)
    }
    
    const currentJobStatus = (Status, updatedAt)=> {
        const Show = ()=> (
            <li className="mb-10 ml-6">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 2 ? 'bg-green-500' : 'bg-gray-500'}`}>
                    <FontAwesomeIcon icon={faCheck} className='text-white text-xs' />
                </span>
                <h3 className="font-bold  leading-tight text-gray-800">{getLabelByValue(Status, ApplicationStatus)}</h3>
                <p className="text-sm">{DateFormat(updatedAt)}</p>
            </li>
        )
        switch (Status) {
            case 'shortlist':
                return <Show />
                case 'hired':
                    return <Show />
                case 'contacting':
                        return <Show />   
                case 'reviewed':
                            return <Show />     
                break;
            case 'rejected':
                return  (  <li className="mb-10 ml-6 text-red-500">
                                        <span className={`absolute flex text-red-500 items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 2 ? 'bg-red-500' : 'bg-gray-500'}`}>
                                            <FontAwesomeIcon icon={faX} className='text-xs text-white' />
                                        </span>
                                        <h3 className="font-bold text-red-500  leading-tight"><FormattedMessage id='RECRUITER.POSTED.JOB.NEW.REJECTED'  defaultMessage={'rejected'} /></h3>
                                        <p className="text-sm text-red-500">{DateFormat(updatedAt)}</p>
                                    </li>)
                break;
            default:
             return  (<li className="mb-10 ml-6">
<span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 2 ? 'bg-green-500' : 'bg-gray-500'}`}>
    <FontAwesomeIcon icon={stepperIndex > 2 ? faCheck : faCircle} className='text-white text-xs' />
</span>
<h3 className="font-bold  leading-tight text-gray-800"><FormattedMessage id='APPLIEDJOBS.APPLICATION.RECRUITER.ACTION'  defaultMessage={'Awaiting Recruiter Action'} /></h3>
</li>)

        }
    }
    const HandleActiveTab = (tab)=> {
            setActiveTab(tab)
    }

    useEffect(()=> {
setFilteredJob(totalApplied?.filter((item)=> item?.status?.toLowerCase() === activetab?.toLowerCase()))
    },[activetab, totalApplied])
    const HandleWithdrawn = async (id)=> {
        setLoader(true)
        try {
const payLoad = {
    id:id,
    status:'withdrawn'
}
const res = await updateApplication(payLoad)
if(res?.success===true) {
    toast.success('Withdrawn Successfully')
        fetchAppliedJobs()
}
        }
        catch(e){
setLoader(false)
        }
    }
    return (
        <>
            <AuthNavComponent isLoggedin={true}></AuthNavComponent>
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className='card-1 p-6 bg-white border border-gray-border rounded-xl'>
                        <div className='statistics flex items-center'>
                            <div className='font-bold  text-5xl mr-3'>
                            {totalAppliedC}
                            </div>
                            <div>
                                <span><FormattedMessage id="APPLIEDJOBS.TOTAL" defaultMessage="Total" />    </span>
                                <br />
                                <span><FormattedMessage id="APPLIEDJOBS.APPLIES" defaultMessage="applies" /> </span>
                            </div>
                            <div className='border border-r border-black h-10 mx-4'></div>
                            <div className='font-bold  text-5xl mr-3'>
                               {applicationUpdateCount}
                            </div>
                            <div>
                                <span><FormattedMessage id="APPLIEDJOBS.APPLICATION" defaultMessage="Application" /> </span>
                                <br />
                                <span><FormattedMessage id="APPLIEDJOBS.UPDATES" defaultMessage="updates" /> </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ContentWithSpinner loader={loader} height='h-80'>
              
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <ApplicationTab  HandleActiveTab={HandleActiveTab} activeTab={activetab} tabCount={tabCount}></ApplicationTab>
            {filteredJob?.length>0 ?
                <div className='lg:flex block bg-white border border-gray-border rounded-xl mt-3'>

                    <div className='flex flex-col lg:w-2/5 w-full left-panel border-r'>
                        <div className='card-1 pb-6 mb-5'>
                          
                    {filteredJob?.map((item, index)=> (
                            <div key={index} onClick={()=> HandleCurrentJob(item?.id)} className={`${currentJob?.id===item?.id && 'bg-[#ecf5ff] hover:bg-[#ecf5ff]'} cursor-pointer border-neutral-200 border-b p-4 hover:bg-gray-50`}>
                                <h1 className='text-base font-bold  mb-1'>{item?.job_id?.job_title}</h1>
                                <h3 className='text-sm font-bold  mb-3'>{item?.job_id?.company_id?.company_name}</h3>

                                <div className='flex justify-between items-center'>
                                    <div className='text-xs font-semibold text-gray-800 border border-gray-300 py-1 px-2 rounded-3xl'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-green-500'></FontAwesomeIcon>
                                        <FormattedMessage id="APPLIEDJOBS.APPLICATION.TIME.STATUS" defaultMessage="Application sent {countnumber} week ago" values={{countnumber:DaysConverter(item?.createdAt)}}/>  
                                    </div>
                                  
                                </div>
                            </div>
))}
                     
                     
                        </div>
                    </div>

                    <div className='flex flex-col w-2/3 h-fit p-6 right-panel'>
                        <div className='header'>
                            <div className='flex justify-between'>
                                <div>
                            <h1 className='font-bold  text-xl'>{currentJob?.job_id?.job_title}</h1>
                            <div className='flex items-center mb-7'>
                                <span className='text-base text-gray-500 font-semibold  mr-1'>{currentJob?.job_id?.company_id?.company_name}</span>
                                </div>
                                </div>
                                {currentJob?.status !=='withdrawn' &&
                                <div>
                        <button onClick={()=> HandleWithdrawn(currentJob?.id)} className='bg-[#ff39b3] font-bold text-white hover:bg-navy p-2 w-24 rounded-3xl mr-3'><FormattedMessage id='JOBS.WITHDRAWN' defaultMessage='Withdrawn' /></button>
                       </div>
}

                            </div>

                            <div className='border-b'></div>

                            <div className='mt-7'>
                                <strong className='text-lg font-bold '> <FormattedMessage id='APPLIEDJOBS.APPLICATION.STATUS'  defaultMessage={'Application Status'} /></strong>
                                <ol className="relative text-gray-500 border-l border-gray-800 mt-2">
                                    <li className="mb-10 ml-6">
                                        <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 0 ? 'bg-green-500' : 'bg-gray-500'}`}>
                                            <FontAwesomeIcon icon={stepperIndex > 0 ? faCheck : faCircle} className='text-white text-xs' />
                                        </span>
                                        <h3 className="font-bold  leading-tight text-gray-800"><FormattedMessage id='APPLIEDJOBS.APPLIED'  defaultMessage={'Applied'} /></h3>
                                        <p className="text-sm">{DateFormat(currentJob?.createdAt)}</p>
                                    </li>
                                    <li className="mb-10 ml-6">
                                        <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${stepperIndex >= 1 ? 'bg-green-500' : 'bg-gray-500'}`}>
                                            <FontAwesomeIcon icon={stepperIndex > 1 ? faCheck : faCircle} className='text-white text-xs' />
                                        </span>
                                        <h3 className="font-bold  leading-tight text-gray-800"><FormattedMessage id='APPLIEDJOBS.APPLICATION.SENT'  defaultMessage={'Application Sent'} /></h3>
                                        <p className="text-sm">{DateFormat(currentJob?.createdAt)}</p>
                                    </li>
                                   {currentJobStatus(currentJob?.status, currentJob?.updatedAt)}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
:
                <div className='lg:container lg:mt-10 mt-10 px-4 lg:px-0 rounded-xl lg:max-w-6xl block bg-white border border-gray-border mx-auto'>
<div className='lg:container text-center mx-auto items-center justify-center lg:max-w-xl py-10'>
<div className='flex justify-center'>
    <img className='w-20' src={toAbsoluteUrl('media/img/emtydisplay.png')}></img>
</div>
<div className='text-2xl font-bold'> You have not applied to any jobs</div>
    </div> </div>
}</div>

                </ContentWithSpinner>
            </main>
            {currentJob && 
            <ChatPopup data={currentJob}/>
}
           <Footer />
        </>
    )
}
