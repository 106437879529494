import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateWorkorder = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/workorder', putData).then((res)=> {
    if(res?.success===true){
        return res
    }
    else {

    }
  });
}catch(e){
    HandleApiError(e)
}
};
