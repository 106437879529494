import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import AuthHttpHandler from 'sr/redux/services/index'

// Define a type for the slice state
interface LoginState {
  data: any
  token: string
  loading: boolean
  message: string
  type: any
}

// Define the initial state using that type
const initialState: LoginState = {
  data: [],
  token: '',
  loading: false,
  message: '',
  type: '',
}

// actions
type LoginData = {
  email: string
  password: string
}

// login user request with data
export const LoginUserAction: any = createAsyncThunk(
  'auth/userLogin',
  // naming convention (reducer/action_name)
  async (data: LoginData) => {
    console.log('data')
    const res = await AuthHttpHandler.userLogin({
      password: data.password,
      email: data.email,
    })
    return res.data.data
  }
)

const {reducer} = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: {
    // initialize request
    [LoginUserAction.pending]: (state: any, action: any) => {
      console.log('loginUser')
      state.loading = true
    },

    // have any response from api with status code 200
    [LoginUserAction.fulfilled]: (state: any, action: any) => {
      const {loginUser, errors} = action.payload
      console.log(loginUser)
      if (loginUser) {
        // state management
        state.data = loginUser
        state.loading = false
        state.token = loginUser.token
        state.message = 'Login Successfully!'
        // storage for access
        sessionStorage.setItem('token', loginUser.token)
        localStorage.setItem('token', loginUser.token)
        localStorage.setItem('scenarios', JSON.stringify(loginUser.scenarios))
        localStorage.setItem('role', loginUser.role.name)
        localStorage.setItem('name', loginUser.name)
        // toasts
        toast.success('Login Successfully!')
        console.log('Login Successfully!')
      }
      if (errors) {
        // error handling for response
        console.log('comment')
        state.loading = false
        state.message = errors[0].message
        toast.error(errors[0].message)
      }
    },

    // not have any response from api
    [LoginUserAction.rejected]: (state: any, action: any) => {
      state.loading = false
      toast.error('Something went wrong! or Connection request Error!')
    },
  },
})

export default reducer
