import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getAddress = async (params) => {
  return await get<ApiResponse<[]>>(`/address?${params}`).then((res)=> {
    try {
    if(res?.success===true) {
        return res
    }
    else {
        return null
    }
  }
  catch(e:any) {
HandleApiError(e)
  }
  }
)
}

