import React, {useEffect, useRef, useState} from 'react'
import { ModelWrapper } from 'app/pages/company/component/ModelWrapper'
import { ChatBottom } from 'app/pages/company/component/Messages/ChatBottom'
import { FormattedMessage } from 'react-intl'
import { DateFormat } from 'sr/helpers/functions/DateFormat'
import { TimeFormat } from 'sr/helpers/functions/TimeFormat'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getMessage } from 'sr/utils/api/cleaner/getMessage'
import { formatISO } from 'date-fns'
import { scrollToBottom } from 'sr/helpers/functions/scrollToBottom'
import { useSocket } from 'app/pages/component/SocketContext'
import getTimeFromDate from 'sr/helpers/functions/getTimeFromDate'
import { updateMessage } from 'sr/utils/api/cleaner/updateMessage'
import { DisplayMessageReceiver } from 'app/pages/component/DisplayMessageReceiver'
export const ChatPopup = ({data}) => {
    const [modelShow, setModelshow] = useState(false)
    const [chatboxShow, setChatboxShow] = useState(false)
    const [allmessage, setAllMessage] = useState(null)
    const [message, setMessage] = useState('');
      const [page, setPage] = useState(1)
      const [limit, setLimit] = useState(10)
      const [totalPage, setTotalPage] = useState(0)
  
  const ref = useRef(null)
  const socket = useSocket()
    const [userId, setUserId] = useState()
      const HandleModelByProps = (data) => {
        setModelshow(data)
    }
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserId(user_id)
    },[])
    
    useEffect(() => { 
      if (!userId) return; 
      if(socket){
        // Register the user (replace 'userId123' with the actual user ID)
        socket.emit('register', userId);
        socket.off('sendmessage');

      }

      socket.on('sendmessage', ({ from, message }) => {
        if(message?.job_id===data?.job_id?._id){
        const currentDateTime = formatISO(new Date());
        const newmessage = {createdAt:currentDateTime, ...message}
        setAllMessage((preview)=> [...preview, newmessage]) 
        scrollToBottom(ref)   
        }
      });   
      return () => {
        socket.off('sendmessage');
      };   
    }, [userId, data]);
  
    const sendMessage = () => {
      const payload = {
          message: message,
          recipient_id: data?.job_id?.company_id?.id,
          sender_id:userId,
          job_id:data?.job_id?._id,
          sender_id_type:'cleaner',
          recipient_id_type:'company',
          // Ensure this resolves to the correct recipient user ID
      };
      socket.emit('sendmessage', payload); // Match the event name in the backend
      setMessage(''); // Clear the input
      const currentDateTime = formatISO(new Date());
      const newmessage = {createdAt:currentDateTime, ...payload}
      setAllMessage((preview)=> [...preview, newmessage])
  };
  

   const handleCleaner = ()=> {
    
    setMessage('')
   }
   const fetchChat = async (job_id)=> {
    const queryParams = new URLSearchParams()
    queryParams?.append('job_id', job_id)
    queryParams.append('page', page?.toString())
        queryParams.append('limit', limit?.toString())
try {
const res = await getMessage(queryParams)
if(res?.success===true) {
setAllMessage((item) => [...(item || []), ...(res?.data || [])]);  
setTotalPage(Math.ceil(res?.pagination?.total/limit))
}
else {

}
}
catch(e) {

}
   }
   useEffect(()=> {
if(data?.job_id?._id) {
  fetchChat(data?.job_id?._id)
}
   },[data?.job_id?._id, page])
   
       const handleScroll = () => {
        console.log(ref.current?.scrollTop);
        if (ref.current?.scrollTop === 0 && totalPage>page) {
         setPage((prev) => prev + 1);
       }
      }
      useEffect(() => {
        if (page === 1) {
          scrollToBottom(ref);
        }
      }, [allmessage]); // Scroll after messages update
  return (
    <div className='fixed right-5 bottom-0'>
        <div className={`w-[20rem]	 ${chatboxShow ? 'h-[28rem]':'h-14'} shadow-boxshadowpage rounded-t-[18px] `}>
    <div className='flex bg-[#2D2D2D] justify-between items-center px-3 text-white py-2.5 rounded-t-[18px] space-x-6'>
        <div onClick={()=> setChatboxShow(!chatboxShow)} className='cursor-pointer flex items-center space-x-3 mr-16 '>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white">
  <path fillRule="evenodd" d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z" clipRule="evenodd" />
</svg>
<span className=''>  {data?.job_id?.company_id?.company_name}</span>
        </div>
        <div className='flex items-center text-white'>
    {/* <svg className="w-10 h-10 p-2 rounded-lg cursor-pointer hover:bg-[#595959]" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.5 13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-15z"></path></svg>
    <svg className="w-10 h-10 p-2 rounded-lg cursor-pointer hover:bg-[#595959]" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.364 9.89A18.007 18.007 0 013 3.5a.49.49 0 01.493-.5h4.502c.276 0 .5.224.51.5.048 1.204.27 2.394.659 3.534a.513.513 0 01-.12.528l-2.438 2.439a16.007 16.007 0 007.391 7.392l2.438-2.437a.513.513 0 01.528-.12c1.14.39 2.332.612 3.537.66.276.011.5.234.5.51v4.5a.49.49 0 01-.5.494A18.01 18.01 0 014.364 9.89z"></path></svg> */}
    <div onClick={()=> setModelshow(!modelShow)} className='p-2 hover:bg-[#595959] rounded-lg cursor-pointer relative'>
                <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-label="More actions" className="w-6 h-6 text-white"><path d="M12 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
                {modelShow &&
              <ModelWrapper rightPosition = 'right-0' width = 'w-52' border = 'border border-black'  HandleModelByProps = {HandleModelByProps}>
              <ul className='px-3 pb-2  text-base font-bold text-[#0078ae]'>
              <li className='py-3'><FormattedMessage id="RECRUITER.CANDIDATE.CHATBOX.MENU.VIEW" defaultMessage="View in message inbox" /></li>
              <li className='py-3'><FormattedMessage id="RECRUITER.CANDIDATE.CHATBOX.MENU.POLICY" defaultMessage="Messaging policy and terms" /></li>
              </ul>
              </ModelWrapper>
                  }
               </div></div>
    </div>
    <div className='bg-white'>
    <div className="flex flex-col  justify-between h-96">
<div onScroll={handleScroll} ref={ref} className='flex-1 h-96  overflow-auto mb-3'>
    <div className='py-8 text-center'>
    {DateFormat(data?.createdAt)}
    </div>
    <div className="flex items-center justify-center pb-4">
  <div className="border-t flex-1 mr-4"></div>
  <span className="text-black ">Today</span>
  <div className="border-t flex-1 ml-4"></div>
</div>
<div>
{
  allmessage?.sort((a, b) => {
      const dateA = new Date(a?.createdAt);
      const dateB = new Date(b?.createdAt);
      return dateA.getTime() - dateB.getTime();
        })?.map((item, index)=> (<>

  {(item?.sender_id?.id || item?.sender_id)  === data?.cleaner_id?._id ?(
<div className='flex justify-end space-x-2 px-2 pb-6'>
<svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="w-8 h-8 text-[#0d2d5e]"><path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path></svg>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className=' text-base'> You </div>
    <div className='text-sm'>
    {getTimeFromDate(item?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
{item?.message}
    </div>
    </div></div>
  ) : (
<div className='flex justify-start space-x-2 px-2 pb-6'>
    <div className='bg-[#0d2d5e] rounded-full text-white max-h-7 max-w-[1.75rem] px-4 py-4 flex items-center justify-center'>
        {data?.job_id?.company_id?.company_name?.slice(0, 1)?.toUpperCase()}
        
    </div>
    <div>
    <div className='flex-1 flex justify-start items-center space-x-2'>
    <div className=' text-base'> {data?.job_id?.company_id?.company_name}</div>
    <div className='text-sm'>
    {getTimeFromDate(item?.createdAt)}
    </div>
    </div>
    <div className='text-[#595959]'>
<DisplayMessageReceiver item={item}></DisplayMessageReceiver>
    </div></div>
</div>
  )}</> 
    ))}
</div>
</div>
<ChatBottom type={'popup'} value={message} Send={sendMessage} onChange = {(e)=> setMessage(e.target.value)} Clear={handleCleaner}/>
</div>
</div>
    </div></div>
  )
}
