import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl';
import getLabelByValue from 'sr/helpers/functions/getLabelByValue';
import { businessTypes } from 'sr/i18n/messages/setting';
import ContentWithLoader from 'sr/layout/common/ContentLoader';
import { StringLoader } from '../../../component/Loader/StringLoader';
import { MultiStringLoader } from 'app/pages/component/Loader/MultiStringLoader';

export const Profile = ({data, loader, logo}) => {
  return (
    <div className='z-10 relative lg:flex block gap-4'>
    <div className='lg:w-40 lg:h-40 w-10 h-10'>
        <div className=' absolute -top-14 lg:w-40 lg:h-40 w-20 h-20 lg:left-0 left-1/2 transform lg:transform-none -translate-x-1/2 border rounded-lg '>
            {logo ? 
        <img alt="Profile" className="rounded-lg w-40 h-40" src={logo} />
:
<img alt="Profile" className="rounded-lg w-40 h-40" src="/media/icons/displayProfilePlaceholder.png" />
            }
    </div></div>
    <div className='inline-block flex-1 w-full mx-auto text-center'>
        <div className='lg:flex block text-center items-center gap-4'>
            <ContentWithLoader content={<StringLoader />} loading={loader}>
            <div className='font-bold font-satoshiBold text-xl'>
                {data?.company_name}
            </div>
            </ContentWithLoader>
            <div className="items-center flex justify-center divide-x divide-solid divide-slate-400 gap-1">
            {/* <div className="flex items-center">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star text-yellow-500 text-xs mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path></svg>
                <div className="text-xs text-gray-500 font-satoshiBold font-bold">3.8</div></div>

            <div className="text-xs	 text-gray-500 font-satoshiBold font-bold pl-1">
                (101 <FormattedMessage id='CLEANER.JOBS.REVIEWS' defaultMessage='Reviews' />)
            </div>
            </div> */}</div>

            </div>
            <div className="flex my-2 gap-2 flex-wrap lg:justify-start justify-center">
                <ContentWithLoader loading={loader} content={<MultiStringLoader />}>
                {data?.business_type?.map((data, index)=> (
            <div key={index} className="inline-block w-auto font-semibold text-xs text-gray-900 border border-gray-300 py-1 px-2 rounded-3xl">{getLabelByValue(data, businessTypes)}</div>
       )) }
       </ContentWithLoader>
            </div>


</div>
{/* <div>
    <div className='lg:flex block items-center gap-10 lg:w-[15rem] w-full lg:justify-end text-center'>
      <p className='text-xs break-all font-bold text-gray-500 lg:m-0 my-4'>6.5K <FormattedMessage id='COMPANY.FOLLOWERS.COUNT' defaultMessage='followers' /></p>   
      <button className='bg-[#275df5] font-bold hover:bg-navy text-white p-2 w-28 rounded-3xl '>+ <FormattedMessage id="COMPANY.FOLLOWERS.BUTTON.TITLE" defaultMessage="Follow" /></button>
</div>

</div> */}
</div>
  )
}
