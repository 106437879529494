import { getFile } from "sr/utils/api/getFile"

interface paramsType  {
files?:[],
setPreviewImages?:(item)=> void,
previewImages?:[],
type?:string,
previewVideo?:[]
setPreviewVideo?:(item)=> void
setLoader?:(bool)=> void
}
const fetchFile = async (key) => {
    const res = await getFile(key)
    if(res) {
        return res
    }
}
export const setPreview = async (params:paramsType)=> {
    if (params?.files?.length) {
        let videos = []
        let images = []
               for (const item of params?.files) {
            const data = await fetchFile(item)
            if(params?.type==='images'){
                images.push({blob:URL.createObjectURL(data), key:item })
            params?.setLoader(false)
            }
         if(params?.type==='videos'){
           videos.push({blob:URL.createObjectURL(data), key:item })
            params?.setLoader(false)
         }
               }
               if(params?.type==='images'){
                params?.setPreviewImages(images)
               }
                if(params?.type==='videos'){
                        params?.setPreviewVideo(videos)
                }
    }
        }
        