import React,{useEffect, useState} from 'react'
import { Input } from 'sr/helpers';
import { RadioInput } from 'sr/helpers/ui-components/company/RadioInput'
export const CompanyNameChange = (props) => {
    const [reason, setreason] = useState()
  const [companyName, setcompanyName] = useState()
    const allReasons = [
        "Company name is incorrect or misspelled",
        "Posting on behalf of another company",
        "Own multiple businesses",
        "Need to post confidentially",
        "Other"
        ];   
        useEffect(()=> {
          const data = {
            companyName:companyName
          }
          props?.HandleDataProps(data)
        },[companyName])  
  return (
   <div className=''>
                 <p className='text-base font-satoshiBold'>Reason for company name change</p>   
                 <p>We use this information to tailor your experience.</p>
                 {allReasons.map((data)=> (
                <RadioInput name={data} value={data} checked={reason === data} 
                onChange= {(e)=>setreason(e.target.value) } 
                label={data}
                inputcss='border border-zinc-800 h-11 rounded-lg my-2'/>
                   ))}
                    <Input onChange={(e:any)=> setcompanyName(e.target.value)} InputDivClass="mt-0" inputClass = "border border-zinc-800 rounded-lg h-11" label="Update company name for this job" value={companyName}></Input>
   </div>
  )
}
