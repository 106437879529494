import { CompanySettingData } from 'app/pages/company/component/SidebarItems'
import React from 'react'
import { SidebarData } from './SidebarData'
export const Sidebar = ({activeTab, HandleTabs}) => {
  return (
    <div className='h-screen bg-white rounded-lg py-4'>
        <h1 className='px-3 font-satoshiBold text-xl'>Settings</h1>
        <ul>
        {SidebarData.map((data)=> (
          
         <li onClick={() => HandleTabs(data.text)} className={`cursor-pointer flex items-center rounded-lg hover:rounded hover:bg-[#EEF1FE] my-2 px-3 py-2 ${activeTab === data.text  ? 'bg-[#EEF1FE] border border-[#2557A7]' : '' }`}>
            <span className="p-1.5">
{data.icon}
            </span> 
        <div>
            <div className={`block font-satoshiBold text-base`}>
  <span>{data.text}</span>
</div>
<div className={`block text-sm`}>
  <span>{data.smalltext}</span>
</div>
</div>

         </li>
))}
        </ul>
    </div>
  )
}
