import { FormattedMessage } from "react-intl"

export const businessTypes = [
    { value: "FT", label: <FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.BUILDING" defaultMessage="Building Service Contractors" /> },
    { value: "PT", label: <FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.UNIVERSITY" defaultMessage="Universities" /> },
    { value: "WKD", label: <FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.IN_HOUSE_PROVIDERS" defaultMessage="In house providers" />},
    { value: "OD", label: <FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.MUNICIPALITIES" defaultMessage="Municipalities" /> },
    { value: "ODM", label: <FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.HOSPITALS" defaultMessage="Hospitals" /> },
    { value: "ODAI", label:<FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.AIRPORTS" defaultMessage="Airports" /> },
    { value: "ODAR", label:<FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.ARENAS" defaultMessage="Arenas" />},
    { value: "ODR", label: <FormattedMessage id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE.RESIDENTIAL" defaultMessage="Residential" />}
]
export const CandidateSortType = [
    {value:'newest', label:<FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.SORT.TYPE.NEWEST'
        defaultMessage='Apply date (newest)'
      />},
      {value:'oldest', label: <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.SORT.TYPE.OLDEST'
        defaultMessage='Apply date (oldest)'
      />},
      {value:'a-z', label: <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.SORT.TYPE.AZ'
        defaultMessage='Alphabetical (A-Z)'
      />},
      {value:'z-a', label:<FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.SORT.TYPE.ZA'
        defaultMessage='Alphabetical (Z-A)'
      />}
]
export const TabData = [
    { 
      tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.ACTIVE'
        defaultMessage='active'
      />
    ), 
    value:'active'},
    { tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.SHORTLIST'
        defaultMessage='shortlist'
      />
    ),
    value:'shortlist'
      
  },
    { tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.AWAITINGREVIEW'
        defaultMessage='awaiting-reviews'
      />
    ), 
    value:'awaiting-reviews' },
    { tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.REVIEWED'
        defaultMessage='reviewed'
      />
    ),
    value:'reviewed' },
    { tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.CONTACTING'
        defaultMessage='contacting'
      />
    ), 
    value:'contacting' },
    { tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.REJECTED'
        defaultMessage='rejected'
      />
    ),
    value:'rejected' },
    { tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.HIRED'
        defaultMessage='hired'
      />
    ), 
  value:'hired' },
    { tabname: (
      <FormattedMessage
        id='RECRUITER.CANDIDATES.FILTERS.WITHDRAWN'
        defaultMessage='withdrawn'
      />
    ),
   value:'withdrawn'},
  ]
export const MainWorkOrderStatus = [
    {value:"publish", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.PUBLISH" defaultMessage="Publish" /> },
    {value:"draft", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.ASSIGNED.DRAFT" defaultMessage="Draft" />},
]
export const WorkOrderStatus = [
  {value:"scheduled", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.SCHEDULED" defaultMessage="Scheduled" />, type:'workorder'},
{value:"in progress", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.INPROGRESS" defaultMessage="In Progress" />, type:'workorder'},
{value:"completed", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.COMPLETED" defaultMessage="Completed" />, type:'workorder'}
]
export const ApprovalStatus = [
    {value:"pending", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.PENDING" defaultMessage='Pending' />, type:'approval'},
    {value:"completed", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.APPROVED" defaultMessage='Approved' />, type:'approval'},
    {value:'rejected', label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.REJECTED" defaultMessage='Rejected' />, type:'approval'}
]
export const PaymentStatus = [
    {value:"pending", label:"Payment Pending", type:"payment"},
        {value:"completed", label:'Payment Completed', type:"payment"},
]
export const TaskStatus = [
    {value:"completed", label:'Completed'},
    {value:"pending", label:"Pending"}
]

export const intentType = [ 
    {value:"Hiring", label:<FormattedMessage id="RECRUITER.REGISTER.INPUT.CHOOSE.INTENT.TYPE.HIRING" defaultMessage="Hiring" />},
    {value:"Bidding", label:<FormattedMessage id="RECRUITER.REGISTER.INPUT.CHOOSE.INTENT.TYPE.BIDDING" defaultMessage="Bidding" />},
    {value:"Subcontract", label:<FormattedMessage id="RECRUITER.REGISTER.INPUT.CHOOSE.INTENT.TYPE.SUBCONTRACT" defaultMessage="Subcontract" />},
    {value:"Scheduling", label:<FormattedMessage id="RECRUITER.REGISTER.INPUT.CHOOSE.INTENT.TYPE.SCHEDULING" defaultMessage='Scheduling' />}
]

export const payTypes = [
    {value:"per hour", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.PERHOUR" defaultMessage="per hour" /> },
    {value:"per day", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.PERDAY" defaultMessage="per day" /> },
    {value:"per week", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.PERWEEK" defaultMessage="per week" /> },
    {value:"per month", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.PERMONTH" defaultMessage="per month" /> },
    {value:"per year", label: <FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.RATE.PERYEAR" defaultMessage="per year" /> },
  ];
  export const customerType = [
    {value:"Hospital", label:<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TYPE.HOSPITAL" defaultMessage="Hospital" />},
    {value:"Airport", label:<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TYPE.AIRPORT" defaultMessage="Airport" />},
    {value:"Corporate", label:<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.TYPE.CORPORATE" defaultMessage="Corporate" />}
  ]

export const usaCities = [
    { label: 'New York', value: 'New York' },
    { label: 'Los Angeles', value: 'Los Angeles' },
    { label: 'Chicago', value: 'Chicago' },
    { label: 'Houston', value: 'Houston' },
    { label: 'Phoenix', value: 'Phoenix' },
    { label: 'Philadelphia', value: 'Philadelphia' },
    { label: 'San Antonio', value: 'San Antonio' },
    { label: 'San Diego', value: 'San Diego' },
    { label: 'Dallas', value: 'Dallas' },
    { label: 'San Jose', value: 'San Jose' },
    { label: 'Austin', value: 'Austin' },
    { label: 'Jacksonville', value: 'Jacksonville' },
    { label: 'San Francisco', value: 'San Francisco' },
    { label: 'Columbus', value: 'Columbus' },
    { label: 'Indianapolis', value: 'Indianapolis' }
  ]; 
export const CandidateStatus = [
    {label:"Pending", 
        value:"pending"
    },
    {label:"Active", 
        value:"active"
    }
]
  export const province = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "American Samoa",
        value: "AS"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "District Of Columbia",
        value: "DC"
    },
    {
        label: "Federated States Of Micronesia",
        value: "FM"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Guam",
        value: "GU"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Marshall Islands",
        value: "MH"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Northern Mariana Islands",
        value: "MP"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Palau",
        value: "PW"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Puerto Rico",
        value: "PR"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virgin Islands",
        value: "VI"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    }
]
 export const Countries = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Åland Islands", label: "Åland Islands" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    { value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    { value: "Congo, The Democratic Republic of The", label: "Congo, The Democratic Republic of The" },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Cote D'ivoire", label: "Cote D'ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Curaçao", label: "Curaçao" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    { value: "French Southern Territories", label: "French Southern Territories" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-bissau", label: "Guinea-bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Heard Island and Mcdonald Islands", label: "Heard Island and Mcdonald Islands" },
    { value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran, Islamic Republic of", label: "Iran, Islamic Republic of" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Korea, Democratic People's Republic of", label: "Korea, Democratic People's Republic of" },
    { value: "Korea, Republic of", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Lao People's Democratic Republic", label: "Lao People's Democratic Republic" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    { value: "Macedonia, The Former Yugoslav Republic of", label: "Macedonia, The Former Yugoslav Republic of" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia, Federated States of", label: "Micronesia, Federated States of" },
    { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Reunion", label: "Reunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russia", label: "Russia" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Helena", label: "Saint Helena" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    { value: "Saint Vincent and The Grenadines", label: "Saint Vincent and The Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Georgia and The South Sandwich Islands", label: "South Georgia and The South Sandwich Islands" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Eswatini", label: "Eswatini" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Taiwan (ROC)", label: "Taiwan (ROC)" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania, United Republic of", label: "Tanzania, United Republic of" },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-leste", label: "Timor-leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    { value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" }
    ];
   export const indiaState =  [
        {
        value: "AN",
        label: "Andaman and Nicobar Islands"
        },
        {
        value: "AP",
        label: "Andhra Pradesh"
        },
        {
        value: "AR",
        label: "Arunachal Pradesh"
        },
        {
        value: "AS",
        label: "Assam"
        },
        {
        value: "BR",
        label: "Bihar"
        },
        {
        value: "CG",
        label: "Chandigarh"
        },
        {
        value: "CH",
        label: "Chhattisgarh"
        },
        {
        value: "DH",
        label: "Dadra and Nagar Haveli"
        },
        {
        value: "DD",
        label: "Daman and Diu"
        },
        {
        value: "DL",
        label: "Delhi"
        },
        {
        value: "GA",
        label: "Goa"
        },
        {
        value: "GJ",
        label: "Gujarat"
        },
        {
        value: "HR",
        label: "Haryana"
        },
        {
        value: "HP",
        label: "Himachal Pradesh"
        },
        {
        value: "JK",
        label: "Jammu and Kashmir"
        },
        {
        value: "JH",
        label: "Jharkhand"
        },
        {
        value: "KA",
        label: "Karnataka"
        },
        {
        value: "KL",
        label: "Kerala"
        },
        {
        value: "LD",
        label: "Lakshadweep"
        },
        {
        value: "MP",
        label: "Madhya Pradesh"
        },
        {
        value: "MH",
        label: "Maharashtra"
        },
        {
        value: "MN",
        label: "Manipur"
        },
        {
        value: "ML",
        label: "Meghalaya"
        },
        {
        value: "MZ",
        label: "Mizoram"
        },
        {
        value: "NL",
        label: "Nagaland"
        },
        {
        value: "OR",
        label: "Odisha"
        },
        {
        value: "PY",
        label: "Puducherry"
        },
        {
        value: "PB",
        label: "Punjab"
        },
        {
        value: "RJ",
        label: "Rajasthan"
        },
        {
        value: "SK",
        label: "Sikkim"
        },
        {
        value: "TN",
        label: "Tamil Nadu"
        },
        {
        value: "TS",
        label: "Telangana"
        },
        {
        value: "TR",
        label: "Tripura"
        },
        {
        value: "UP",
        label: "Uttar Pradesh"
        },
        {
        value: "UK",
        label: "Uttarakhand"
        },
        {
        value: "WB",
        label: "West Bengal"
        }
        ]

export const jobStatus = [
  {value:"open", label:<FormattedMessage
  id="RECRUITER.POSTED.JOB.TEXT.OPEN" defaultMessage="Open"
/>},
{value:"pause", label:<FormattedMessage
id="RECRUITER.POSTED.JOB.TEXT.PAUSE"
defaultMessage="Pause"
/>},
{
  value:"closed", label: <FormattedMessage
  id="RECRUITER.POSTED.JOB.TEXT.CLOSED"
  defaultMessage="Closed"
/>
}
]     
export const individualJobStatus = [
    {value:"open", label:<FormattedMessage
        id='INDIVIDUAL.JOBS.FILTER.DROPDOWN.STATUS.OPEN'
        defaultMessage='Open'
      />},
      {value:"assigned", label:<FormattedMessage
        id='INDIVIDUAL.JOBS.FILTER.DROPDOWN.STATUS.ASSIGNED'
        defaultMessage='Assigned'
      />},
      {value:"completed", label:<FormattedMessage
        id='INDIVIDUAL.JOBS.FILTER.DROPDOWN.STATUS.COMPLETED'
        defaultMessage='Completed'
      />}   
]   
export const IndiJobStatus = [
    {value:"publish", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.PUBLISH" defaultMessage="Publish" /> },
    {value:"draft", label:<FormattedMessage id="RECRUITER.WORKORDER.FILTER.TYPE.ASSIGNED.DRAFT" defaultMessage="Draft" />},
]

export const ApplicationStatus = [
  {value:"active", label:<FormattedMessage
  id='RECRUITER.CANDIDATES.FILTERS.ACTIVE'
  defaultMessage='Active'
/>},
  {value:"awaiting-reviews", label:<FormattedMessage
  id='RECRUITER.CANDIDATES.FILTERS.AWAITINGREVIEW'
  defaultMessage='awaiting-reviews'
/>},
{value:"reviewed", label:<FormattedMessage
id='RECRUITER.CANDIDATES.FILTERS.REVIEWED'
defaultMessage='Reviewed'
/>},
{value:"contacting", label:<FormattedMessage
id='RECRUITER.CANDIDATES.FILTERS.CONTACTING'
defaultMessage='Contacting'
/>},
{
value:"rejected", label:<FormattedMessage
id='RECRUITER.CANDIDATES.FILTERS.REJECTED'
defaultMessage='Rejected'
/>
},
{
  value:"hired", label:<FormattedMessage
  id='RECRUITER.CANDIDATES.FILTERS.HIRED'
  defaultMessage='Hired'
/>
  },
  {
    value:"shortlist", label:<FormattedMessage
    id='RECRUITER.CANDIDATES.FILTERS.SHORTLIST'
    defaultMessage='Shortlist'
  />
    },
    {
      value:"withdrawn", label:<FormattedMessage id="RECRUITER.CANDIDATES.FILTERS.WITHDRAWN" defaultMessage="Withdrawn"></FormattedMessage>
    }
]
export const WorkOrderApplicationStatus = [
    {value:"active", label:<FormattedMessage
    id='RECRUITER.CANDIDATES.FILTERS.ACTIVE'
    defaultMessage='Active'
  />},
    {
      value:"accept", label:<FormattedMessage
      id='RECRUITER.SIDEBAR.ACTION.TYPE.ACCEPT'
      defaultMessage='Accept'
    />
      },
      {
        value:"reject", label:<FormattedMessage
        id='RECRUITER.SIDEBAR.ACTION.TYPE.REJECT'
        defaultMessage='Reject'
      />
        },
  ]

export const WorkOrderPayType = [
    {value:'Fixed Rate', label:<FormattedMessage
        id='RECRUITER.SCHEDULEWORKORDER.PAYTYPE.FIXED'
        defaultMessage='Fixed Rate' />},
        {value:'Hourly Rate', label:<FormattedMessage
            id='RECRUITER.SCHEDULEWORKORDER.PAYTYPE.HOURLY'
            defaultMessage='Hourly Rate' />}

]  
export const securityPermission = [
    {
        title: 'Work Order Management', 
        description: 'User can View, Add, Update, Delete Permission', 
        permission: [
            { title: 'View Work Order' }, 
            { title: 'Add Work Order' }, 
            { title: 'Update Work Order' }, 
            { title: 'Delete Work Order' }
        ]
    },
    {
        title: 'Contractor Management', 
        description: 'User can View, Add, Update, Delete Permission', 
        permission: [
            { title: 'View Contractor' }, 
            { title: 'Add Contractor' }, 
            { title: 'Update Contractor' }, 
            { title: 'Delete Contractor' }
        ]
    },
    {
        title: 'Customer Management', 
        description: 'User can View, Add, Update, Delete Permission', 
        permission: [
            { title: 'View Customer' }, 
            { title: 'Add Customer' }, 
            { title: 'Update Customer' }, 
            { title: 'Delete Customer' }
        ]
    }
];
export const IndFilterDates = [
  {title:<FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.DATE.TAG.TODAY" defaultMessage="Today" />
    ,value:'todat'
  },
  {title:<FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.DATE.TAG.WITHIN_THREE_DAYS" defaultMessage="Within 3 Days" />,
    value:'3days'
  },
  {title:<FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.DATE.TAG.WITHIN_A_WEEK" defaultMessage="Within A Week" />,
    value:'withinaweek'
  },
  {title:<FormattedMessage id="INDIVIDUAL.CLEANERS.FILTER.DATE.TAG.CHOOSE_DATES" defaultMessage="Choose Dates" />,
    value:'custom'
  }
]