import React, { useEffect, useState } from "react";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";

export const StartDate = (props) => {
    const [startDate, setStartDate] = useState(new Date())
    
    useEffect(() => {
      const dateObject = new Date(props?.data?.start_date);
      setStartDate(dateObject);
    }, [props?.data]);
    

    useEffect(()=> {
      const data = {
        start_date:startDate
      }
      props?.HandleDataProps(data)
    },[startDate])
  return (
  <><div className='pt-2'>
  <p className='text-base font-satoshiBold'>Start Date
</p>   
 </div>

<div className="relative max-w-xs">
<ReactDatePicker InputDivClass="mt-2" className="bg-white border border-zinc-800 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-blue-500 focus:border-[#2557A7] focus:shadow-[0_3px_0px_0px_rgb(37,87,167)] block w-full pl-10 p-2 uppercase" 
selected={startDate}
onChange={(date)=>setStartDate(date) }
minDate={new Date()}
/>
</div>
</>
);
};
