import React, {ForwardRefRenderFunction, forwardRef, useEffect, useState} from 'react'
import { Input, TextArea } from 'sr/helpers'
import DropDown from 'sr/helpers/ui-components/DropDown.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { createTasklist } from 'sr/utils/api/company/customer/createTasklist'
import tasklistSchema from 'sr/utils/schemas/company/customer/TasklistSchema'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
interface TabRef {
  submitForm: () => void;
}
export interface ChecklistFormType {
  dataAddedHandle?: (arg: any) => void | null;
}
const TaskForm: ForwardRefRenderFunction<TabRef, ChecklistFormType> = ({dataAddedHandle=null}, ref) => {
    const [task, setTask] = useState('')
    const [description, setDescription] = useState('')
    const [selectType, setselectType] = useState('Optional')
    const [customerId, setCustomerId] = useState(null)
    const [companyId, setCompanyId] = useState(null)
    const [checklistId, setchecklistId] = useState(null)
    const [loader, setLoader] = useState(false)
const intl = useIntl()

const params = useParams()

    useEffect(()=> {
        const userDetails = getUserDetails()
        setCompanyId(userDetails?.user_id)
    },[])
    useEffect(()=> {
            const {id, checklistId} = params
            setCustomerId(id)
            setchecklistId(checklistId)
    },[params])

const InitialValues = {
company_id:companyId, 
customer_id:customerId,
checklist_id:checklistId,
name:task,
type:selectType,
description:description,
status:'active'
}

const {errors, submitForm} = useFormik({
initialValues:InitialValues, 
enableReinitialize:true,
validationSchema:tasklistSchema,
onSubmit:async (values)=> {
  setLoader(true)
try {
const res = await createTasklist(values)
if(res.success===true) {
  SuccessMessage('API.SUCCESS.CUSTOMER.TASKLIST.ADD')
  dataAddedHandle('Task');
  setTask('');
  setDescription('');
  setselectType('');
  setLoader(false)
}
else {
setLoader(false)
}
}
catch(e){
  setLoader(false)
}
}
})

React.useImperativeHandle(ref, () => ({
  submitForm,
}));
  return (
    <div>
      {loader && <LoaderFullScreen />}
 <Input onChange={(e)=> setTask(e.target.value)} error={errors.name} value={task} InputDivClass="mt-2" label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.CHECKLIST.TASK.TITLE" defaultMessage="Task" />}
                    placeholder={''}
                    type={'text'}
                    inputClass = 'border border-zinc-800 rounded-lg h-11'>
                </Input>
                <div className='mt-10'>
                  <TextArea error={errors.description} labelClass={'text-normal  text-sm'} rows={4} label={<FormattedMessage id="RECRUITER.CUSTOMER.INPUT.CHECKLIST.DESCRIPTION.TITLE" defaultMessage="Description" />} 
                  className={'block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-[#0078ae] focus:shadow-inputshadow outline-0'} 
                  htmlFor="Description" placeholder={intl.formatMessage({id:'RECRUITER.CUSTOMER.INPUT.CHECKLIST.DESCRIPTION.PLACEHOLDER', defaultMessage:'Write your Description here...'})} onChange={(e)=> setDescription(e.target.value)} value={description}>

                  </TextArea>
             
</div>

                <DropDown error={errors.type} onChange={(e)=> setselectType(e.target.value)} value={selectType} dropdowndiv='text-gray-700 mt-10' labelMargin="mb-1" label={<FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TYPE.TITLE" defaultMessage="Type" />} className='border border-zinc-800 rounded-lg h-11'>
                    <option value=''><FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TASK.INPUT.SELECT.TYPE" defaultMessage="Select Type" /></option>
                    <option value='Mandatory'><FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TYPE.MANDATORY" defaultMessage="Mandatory" /></option>
                    <option value='Optional'><FormattedMessage id="RECRUITER.CUSTOMER.CHECKLIST.TASK.TYPE.OPTIONAL" defaultMessage="Optional" /></option>
                </DropDown>
    </div>
  )
}

export default forwardRef(TaskForm)