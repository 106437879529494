import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "../axios";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";

export const forgetPassword = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/auth/forgot', postData).then((res)=> {
    if(res.success===true) {
        return res
    }
    else {
        return null
    }
}
  )}
catch(e:any) {
HandleApiError(e)
}
};
