import React from "react";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { put } from "sr/utils/axios";

export const updateCustomer = async (putData, successMessage = '') => {
    try {
  return await put<ApiResponse<[]>>('/customer/', putData).then((res)=> {
    if(res.success===true) {
        SuccessMessage(successMessage ? successMessage : 'API.SUCCESS.CUSTOMER.CUSTOMER.UPDATE')
        return res
    }
    else {
        return null
    }
  })
}
catch(e:any) {
    HandleApiError(e)
}
};
