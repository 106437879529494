import React from "react";
import { createTasklist } from "sr/utils/api/company/customer/createTasklist";

export const addTasks = async (tasks: any[], checklistId: any) => {
    const taskPromises = tasks.map(async (task) => {
        const payload = {name:task?.name, description:task?.description, type:task?.type,customer_id:task?.customer_id, company_id:task?.company_id, checklist_id: checklistId };
        const taskres = await createTasklist(payload);
        return taskres;
    })
    const results = await Promise.all(taskPromises);
    if(results){
    const allSuccessful = results.every(result => result?.success === true);
    if(allSuccessful){
        return allSuccessful
    }
    else {
        return false
    }
    }

}
