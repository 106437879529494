import ApiResponse from "sr/models/ApiResponse";
import { get } from "../axios";
import { toast } from "react-toastify";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { AxiosRequestConfig } from "axios";
export const getFile = async (key) => {

    try {
        const res = await get<any | null>(`/util/linkfile`,{key}, {
            responseType: "blob",
            'Content-Type': 'multipart/form-data'
          } as AxiosRequestConfig);
          return res;
//         return await get(`/util/linkfile?key=${key}`,).then((res)=> {
//     if(res) {
//         return res
//     }
//     else {
//         return null
//     }
// })
}
catch(e:any) {
    HandleApiError(e)
}
};
