import React, { useState } from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import { Balance } from "./component/wallet/Balance";
import { History } from "./component/wallet/History";
export const CleanerWallet = () => {
    const [activeTab, setActiveTab] = useState('Balance') 
    const Tabs = [
        {label:'Balance', value:'Balance'},
        {label:'Payment methods', value:'Payment methods'},
        {label:'Billing history', value:'Billing history'}
    ]
  return (
    <>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
    <main className="lg:container lg:block block mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0">
  <div className={`flex-1 flex space-x-8 border-b border-gray-200 mb-5`}>
   <div className='flex-1  flex space-x-10 overflow-hidden justify-start'>
     {Tabs?.map((data)=> (
       <span  onClick={()=> setActiveTab(data?.value)} className={`${activeTab  === data?.value ? "text-[#0078ae] border-b-2 border-[#0078ae]":""} hover:text-[#0078ae]  text-base inline-flex flex-shrink-0 cursor-pointer pb-1`}>{data?.label}</span>
       )) }
   </div>

    </div>

    <div className="text-lg  mb-5">
        <p>{activeTab}</p>
    </div>

{
    activeTab ==='Balance' &&
 <Balance />
}
{
    activeTab ==='Billing history' &&
 <History />
}
  
</main>
    </>
  );
};
