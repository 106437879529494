import React, { useEffect, useState, useRef, forwardRef,ForwardRefRenderFunction } from 'react'
import { Input } from 'sr/helpers'
import {useIntl } from 'react-intl'
import { useFormik } from 'formik'
import reference from 'sr/utils/schemas/cleaner/reference'
import { FormattedMessage } from 'react-intl'
import { createReference } from 'sr/utils/api/cleaner/createReference'
import { useSelector } from 'react-redux'
import { RootState } from 'sr/redux/reducers'
import { RadioInput } from 'sr/helpers/ui-components/RadioInput'
import { useParams } from 'react-router-dom'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { getReference } from 'sr/utils/api/cleaner/getReference'
import { updateReference } from 'sr/utils/api/cleaner/updateReference'
import { CleanerComponentProps } from './CleanerComponentProps'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import { ButtonAuth } from 'sr/helpers/ui-components/ButtonAuth'
import { InputNumberFormat } from 'sr/helpers/functions/InputNumberFormat'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
const CleanerReference: ForwardRefRenderFunction<any, CleanerComponentProps> = (
  { HandleStepper = null, page = '', type = '', dataAddedHandle=null, id='' },
  ref
) => {

  const registeredData = useSelector((state: RootState) => state.registerCleaner);
  const [AddMoreClicked, setAddMoreClicked] = useState(false)
  const [visableEmployer, setvisableEmployer] = useState(false)
  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [email, setEmail] = useState("")
  const [mobileNo, setMobileNo] = useState('')
  const [userData, setUserData] = useState(null)
  const [loader, setLoader] = useState(false)
  const[buttonLoader, setButtonLoader] = useState(false)
  useEffect(()=> {
    const getdetails = getUserDetails()
    setUserData(getdetails)
  },[])
  const initialValues = {
    cleaner_id:userData?.user_id,
    first_name:firstName,
    last_name:lastName,
    email:email,
    mobile_number:mobileNo,
    visible_to_employer:visableEmployer,
  }
  const initialValuesPut = {
    cleaner_id:userData?.user_id,
    first_name:firstName,
    last_name:lastName,
    email:email,
    mobile_number:mobileNo,
    visible_to_employer:visableEmployer,
    id:id
  }

  useEffect(()=> {
    const fetchData = async () => {
      setLoader(true)
      try {
        const referenceData = await getReference(undefined,id)
        if(referenceData.success===true){

          console.log(referenceData?.data);
          
        setfirstName(referenceData?.data?.first_name)
        setlastName(referenceData?.data?.last_name)
        setEmail(referenceData?.data?.email)
        setMobileNo(referenceData?.data?.mobile_number)
        setFieldValue('id', id)
          setLoader(false)

        }
      }
      catch(e:any){
        console.log(e);
        
      }
    }
    if(page === 'popup' && type==='put'){
    fetchData()
    }
    console.log(id);
    
  },[id])

    const intl = useIntl()
    const {handleSubmit, errors, submitForm, setFieldValue} = useFormik({
      enableReinitialize:true,
      initialValues:page === 'popup' && type==='put' ? initialValuesPut:initialValues,
      validationSchema:reference,
      
      onSubmit:async (values, helpers)=> {
        setButtonLoader(true)
        try {
          let result
          if(page === 'popup' && type==='put'){
            result = await updateReference(values)
          }
          else {
          result = await createReference(values)
          }
          if(result.success === true) {
            setButtonLoader(false)
            if(page === 'popup'){
            dataAddedHandle(true)
            }
            if(AddMoreClicked) {
              setfirstName("")
              setlastName("")
              setMobileNo("")
              setEmail("")
              setvisableEmployer(false)
              helpers.resetForm()
              setAddMoreClicked(false)
            }
            else {
              if (HandleStepper !== null) {
                HandleStepper(2);
              }
            
            }
          }
        }
        catch(e:any) {
          console.log(e);
          setButtonLoader(false)
        }

      }
    })
    React.useImperativeHandle(ref, () => ({
      submitForm,
    }));
  
    return (
       
        <ContentWithSpinner loader={loader}>
          {buttonLoader && <LoaderFullScreen />}
            <form className='mt-5' onSubmit={handleSubmit}>
                <Input 
                value={firstName}
                onChange={(e)=> setfirstName(e.target.value)}
                label={intl.formatMessage({
                    id:'CLEANER.PROFILE.REFERENCE.INPUT.FIRSTNAME',
                    defaultMessage:'Reference First Name'
                })} 
                type="text" 
                placeholder={intl.formatMessage({
                    id:'CLEANER.PROFILE.REFERENCE.INPUT.FIRSTNAME.PLACEHOLDER',
                    defaultMessage:'Enter your reference first name'
                })}
                name='first_name'
                error={errors.first_name}
                ></Input>
                <Input
                value={lastName}
                onChange={(e)=> setlastName(e.target.value)}
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.REFERENCE.INPUT.LASTNAME',
    defaultMessage: 'Reference Last Name',
  })}
  type="text"
  placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.REFERENCE.INPUT.LASTNAME.PLACEHOLDER',
    defaultMessage: 'Enter your reference last name',
  })}
  name='last_name'
  error={errors.last_name}
/>

<Input
value={email}
onChange={(e)=> setEmail(e.target.value)}
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.REFERENCE.INPUT.EMAIL',
    defaultMessage: 'Reference Email Address',
  })}
  type="text"
  placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.REFERENCE.INPUT.EMAIL.PLACEHOLDER',
    defaultMessage: 'Enter your reference email address',
  })}
  name='email'
  error={errors.email}
/>

<Input
onChange={(e)=> setMobileNo(e.target.value)}
  label={intl.formatMessage({
    id: 'CLEANER.PROFILE.REFERENCE.INPUT.MOBILE',
    defaultMessage: 'Reference Mobile Number',
  })}
  type="text"
  placeholder={intl.formatMessage({
    id: 'CLEANER.PROFILE.REFERENCE.INPUT.MOBILE.PLACEHOLDER',
    defaultMessage: 'Enter your reference mobile number',
  })}
  name='mobile_number'
  error={errors.mobile_number}
  value={InputNumberFormat(mobileNo)}
/>
<div className='mt-5'>
                    <p className="mt-10 mb-1 text-gray-700 text-sm "><FormattedMessage id="CLEANER.PROFILE.REFERENCE.RADIOINPUT" defaultMessage="Visible to employer" /></p> 
                    <div className="flex space-x-6">
                           <RadioInput name='Visability' value={true} label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.ALLOWCANDIDATE.YES" defaultMessage="Yes" />} checked={visableEmployer===true} onChange={()=> { setvisableEmployer(true)}}  /> 
                           <RadioInput
 name='Visability' value={false} label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.ALLOWCANDIDATE.NO" defaultMessage="No" />} checked={visableEmployer===false} onChange={()=> { setvisableEmployer(false)}}  />
                                    </div></div>
<div className='mt-5 flex space-x-5'>
  {page === 'popup' && type === 'add'||type==='put'  ? '':(<>
    
                    <ButtonAuth loader={buttonLoader}><FormattedMessage id="REGISTER.BUTTON.SAVEANDCONTINUE" defaultMessage="Save and Continue" />
                    </ButtonAuth>
                   
                    <ButtonAuth loader={buttonLoader} onClick={()=> setAddMoreClicked(true)} 
                                    
                                >Add More</ButtonAuth>
                
                    <div className='rounded-full text-center
                 bg-[#ff39b3] font-bold
                 p-2
                 text-white
                 w-44
                 cursor-pointer mb-5 mt-5
                 hover:bg-navy'
                                onClick={()=> HandleStepper(2)}>Skip</div>
  </>)
  }
                  </div>
            </form>
        </ContentWithSpinner>
    )
}

export default forwardRef(CleanerReference);