import React from "react";
import { MainWrapper } from "../component/MainWrapper";

export const CleanerCompanyProfile = () => {
  return (
  <MainWrapper>
    <div className='py-3'>
      
    </div>
  </MainWrapper>
  
  )
};
