import { useGet } from "app/pages/company/Hooks/Api/useGet";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { getIndividual } from "sr/utils/api/individual/getIndividual";
import { createStripCustomer } from "sr/utils/api/strip/createStripCustomer";
import {
    useStripe,
    useElements,
    CardElement,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
  } from '@stripe/react-stripe-js';
import { createStripIntent } from "sr/utils/api/strip/createStripIntent";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { toast } from "react-toastify";
import { getCardIcon } from "sr/helpers/functions/getCardIcon";
import getStripCustomerId from "sr/helpers/apiFunctions/strip/getStripCustomerId";
import CreateIntent from "sr/helpers/apiFunctions/strip/createIntent";
import { getCompany } from "sr/utils/api/company/getCompany";
import { Input } from "sr/helpers";
import { capitalizeFirstLetter } from "sr/helpers/functions/firstletterCapital";
import { ModelWrapperWithButton } from "../../component/ModelWrapperWithButton";
import { deleteCard } from "sr/utils/api/strip/deleteCard";
import { createDefaultCard } from "sr/utils/api/strip/createDefaultCard";
import { getDefaultCard } from "sr/utils/api/strip/getDefaultCard";
  
export const PaymentForm = (props) => {
    const [userId, setUserid] = useState()
    const [isProcessing, setIsProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [stripId, setStripId] = useState()
    const [cardDetails, setCardDetails] = useState<any>([])
    const [data, setData] = useState<any>()
    const [showModel, setShowModel] = useState(false)
  const [defaultCard, setDefaultCard] = useState(null)
    useEffect(()=> {
        const {user_id} = getUserDetails()
        setUserid(user_id)
        },[])
        let cardnumberElement = useRef(null);
        let cardcvv = useRef(null); 
        let cardExpiry = useRef(null);

useEffect(()=> {
  if (!elements) return;
  console.log(elements);
  
  console.log('element is available');
  
   cardnumberElement.current = elements?.getElement(CardNumberElement)
   if(cardnumberElement){
    console.log('cardnumberelement is available')
   }
     cardcvv.current = elements?.getElement(CardCvcElement)
     cardExpiry.current = elements?.getElement(CardExpiryElement)
},[showModel, elements])
        const fetchData = async (userId) => {
          
          try {
            const queryParams = new URLSearchParams(); 
            if (userId) queryParams.append('id', userId); 
          const result = await getCompany(queryParams)
          if(result?.success === true) {
              setData(result?.data)
              console.log(result?.data);
              
          }
      }
      catch(error) {
          
      }
      }
          
          useEffect(()=> {
                if(userId) {
                  fetchData(userId)
                  getStripCustomerId({setCardDetails:setCardDetails, setIsProcessing:setIsProcessing, setStripId:setStripId, customerId:userId})
                }
          },[userId])
          useEffect(()=>{
console.log(cardnumberElement?.current);

          },[cardnumberElement])
          console.log(cardnumberElement?.current);
    const CreateStripCustomer = async (e)=> {
      e.preventDefault();
      if(!cardnumberElement?.current){
        console.log('card element noty available')
      }
      console.log(cardnumberElement?.current);
          if (stripId) {
            CreateIntent({customerId:userId, 
              cardnumberElement:cardnumberElement?.current, 
              stripe:stripe, elements:elements, 
              setIsProcessing:setIsProcessing, 
            stripId:stripId,
            setCardDetails:setCardDetails,
            email:data?.email,
            currency:'USD',
            cardcvv:cardcvv?.current,
              cardExpiry:cardExpiry?.current,
              setShowModel:setShowModel,
              onCheckModel:onCheckModel
            })
          }
      else {
      
        const payload = {
          cleanpig_id:userId,
          type:"company"
        }
            const res = await createStripCustomer(payload)
            if(res?.success===true) {
                if(res?.data?.customer) {
                  CreateIntent({customerId:userId, 
              cardnumberElement:cardnumberElement?.current, 
              stripe:stripe, elements:elements, 
              setIsProcessing:setIsProcessing, 
            stripId:res?.data?.customer,
            setCardDetails:setCardDetails,
            email:data?.email,
            currency:'USD',
            cardcvv:cardcvv?.current,
              cardExpiry:cardExpiry?.current,
              setShowModel:setShowModel,
              onCheckModel:onCheckModel
            })
            
                }
                
            }
          }
    }
   
    const onCheckModel = ()=>{
      if (props?.setShowAddModal) props?.setShowAddModal('wallet')
    }
    
    
const stripeInputStyles = {
  base: {
    '::placeholder': {
      color: '#888',              // Placeholder color
    },


  },
  focus: {
    borderColor: '#666',          // Border color on focus
  },
  invalid: {
    color: '#e5424d',             // Color for invalid input
    borderColor: '#e5424d',       // Border color for invalid input
  },
};
const handleDelete = async (id)=>{
  try {
  setIsProcessing(true)
  const queryParams = new URLSearchParams()
  queryParams.append('method_id', id)
  const res = await deleteCard(queryParams)
  if(res?.success===true){
    toast.success('Card Deleted Successfully')
    getStripCustomerId({setCardDetails:setCardDetails, setIsProcessing:setIsProcessing, setStripId:setStripId, customerId:userId})
  }
  else{
    setIsProcessing(false)
  }
}
catch(e){
  setIsProcessing(false)
}
}
    useEffect(()=>{
        if(props?.type==='popup' && props?.showAddModel){
          setShowModel(true)
        }
    },[props?.type, props?.showAddModel])
    const handleDefault = async (cardNumber, cardType)=>{
      setIsProcessing(true)
      try {
      const payLoad = {
        company_id:userId,
        card:cardNumber,
        card_type:cardType
      }
      const res = await createDefaultCard(payLoad)
      if(res?.success===true){
        setIsProcessing(false)
          fetchDefaultCard()
      }
      else{
        setIsProcessing(false)
          return null
      }

      }
      catch(e:any){
          setIsProcessing(false)
      }
    }
    const fetchDefaultCard = async ()=>{
      setIsProcessing(true)
      const queryParams = new URLSearchParams()
      queryParams.append('company_id', userId)
        try{
            const res = await getDefaultCard(queryParams)
            if(res?.success===true){
              setDefaultCard(res?.data)
              setIsProcessing(false)
            }
            else{
              setIsProcessing(false)
            }
        }
        catch(e){
setIsProcessing(false)
        }
    }
    useEffect(()=>{
        if(userId){
          fetchDefaultCard()
        }
    },[userId])
  return (
    <>
      {isProcessing && <LoaderFullScreen />}
    <div className="py-3">
    <div className="pb-5">
      {/* <p><span className=""><FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TITLE" defaultMessage="Available Account Balance" />:</span>$0</p>
      <p>*<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.AVAILABLE.BALANCE.TEXT" defaultMessage="Account balances are automatically applied when a task is Completed" />
  </p> */}
      </div>
      </div>
      <div className=" max-w-[32rem] mx-auto">
    {cardDetails?.map((item)=> (
    <div className="flex space-x-3 my-3 px-6 border-neutral-200 border w-full justify-center">
      <div className="border-r border-neutral-200 flex items-center space-x-4 w-[70%]">
        <div>
            {getCardIcon(item?.card?.brand)}
            </div>
            <div>
              <div>{capitalizeFirstLetter(item?.card?.brand)}</div>
            <span>**** **** **** {item?.card?.last4}</span>
            </div>
            </div>
            <div className="w-[30%] py-2">
           <p onClick={()=> handleDelete(item?.id)} className=" border border-[#d4d2d0]  hover:border-[#0078ae]   flex justify-center items-start w-[8rem] py-[8px] mb-3 cursor-pointer  rounded-lg text-[#0078ae]  ">Delete</p>
           {defaultCard?.card===item?.card?.last4 ?
                      <p className=" border border-[#d4d2d0]  hover:border-[#0078ae]  flex justify-center items-start w-[8rem] py-[8px] cursor-pointer  rounded-lg text-[#0078ae] ">Default</p>
           :

           <p onClick={()=> handleDefault(item?.card?.last4, item?.card?.brand)} className=" border border-[#d4d2d0]  hover:border-[#0078ae]  flex justify-center items-start w-[8rem] py-[8px] cursor-pointer  rounded-lg text-[#0078ae] ">Set as Default</p>
}
           </div>
        </div>
        ))
      }

      <p onClick={()=> setShowModel(true)} className=" text-[#275df5] cursor-pointer ">Add Credit Card</p>
   
</div>
<ModelWrapperWithButton showModal={showModel} onCheckModel={()=> {setShowModel(false); props?.setShowAddModal && props?.setShowAddModal('')}} Save={CreateStripCustomer}>
      <form>
      {elements ? (
         <>
                         <label htmlFor="card-number" className="block text-normal font-medium  mt-3">
      Card Number:
    </label>
  
         <div id="card-number" className="border border-zinc-800 rounded-lg h-11 mb-3 flex items-center w-full px-2">
          
            <CardNumberElement options={{ style: stripeInputStyles }} className="w-full"/>
            </div>
  
            <div className="flex space-x-3 w-full">
            <div className="w-1/2">
            <label htmlFor="card-expiry" className="block text-normal font-medium  ">
      Expiry Date
    </label>
            <div id="card-expiry" className="border border-zinc-800 rounded-lg h-11 flex items-center w-full px-2">
            <CardExpiryElement options={{ style: stripeInputStyles }} className="w-full"></CardExpiryElement>
            </div>
            </div>
              <div className="w-1/2">
            <label htmlFor="card-cvc" className="block text-normal font-medium ">
      CVC
    </label>
  
            <div id="card-cvc" className="border border-zinc-800 rounded-lg h-11 mb-3 flex items-center w-full px-2">
            <CardCvcElement options={{ style: stripeInputStyles }} className="w-full"></CardCvcElement>
            </div>
            </div>
          
            </div>  
       
            </> ) : (
        <p>Loading payment form...</p>
      )}
    </form>
    </ModelWrapperWithButton>
      </>
  );
};
