import React, {useEffect, useRef, useState} from 'react'
import AuthNavComponent from './AuthNav'
import { Input, toAbsoluteUrl } from 'sr/helpers'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { FormDetails } from 'sr/helpers/ui-components/FormDetails';
import { useFormik } from 'formik';
import { verifyOTP } from 'sr/utils/api/verifyOTP';
import * as Yup from 'yup'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails';
import { getCompany } from 'sr/utils/api/company/getCompany';
import { getIndividual } from 'sr/utils/api/individual/getIndividual';
import { getSupervisor } from 'sr/utils/api/company/workorder/getSupervisor';
import { getCleaner } from 'sr/utils/api/cleaner/getCleaner';
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen';
import { ModelWrapperWithButton } from 'app/pages/company/component/ModelWrapperWithButton';
import { useIntl } from 'react-intl';
import { updateCompany } from 'sr/utils/api/company/updateCompany';
import { updateIndividual } from 'sr/utils/api/individual/updateIndividual';
import { updateSupervisor } from 'sr/utils/api/company/workorder/updateSupervisor';
import { updateCleaner } from 'sr/utils/api/cleaner/updateCleaner';
import { sendOTP } from 'sr/utils/api/sendOTP';
import { toast } from 'react-toastify';
import { HandleApiError } from 'sr/helpers/functions/HandleApiError';
export default function VerifyOTP() {
    const [registeredData, setRegisteredData] = useState(null)    
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [userId, setUserId] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [showModel, setShowModel] = useState(false)
    useEffect(()=> {
            const {user_id} = getUserDetails()
            setUserId(user_id)
    },[])
    const fetchData = async ()=> {
        try {
            const queryParams = new URLSearchParams()
                queryParams.append('id', userId)
                let res;
                if(localStorage.getItem('RegisterAs') === 'Company') {
                    res = await getCompany(queryParams)
                }
                else if(localStorage.getItem('RegisterAs') === 'Individual') {
                    res = await getIndividual(queryParams)
                }
                else if(localStorage.getItem('RegisterAs')==='supervisor')
                {
                   res = await getSupervisor(queryParams)
                }
                else {         
                    res = await getCleaner(queryParams)
                    
                }
                if(res?.success===true) {
                    setRegisteredData(res?.data)
                    setLoader(false)
                }
                else {
                    setLoader(false)
                }
            }
        catch(e){
setLoader(false)
        }
    }
    useEffect(()=> {  
            if(userId) {
                fetchData()
            }
    },[userId])
    const intl = useIntl()
    const inputRefs = useRef<Array<React.RefObject<HTMLInputElement>>>([]);
    if (inputRefs.current.length === 0) {
        inputRefs.current = Array.from({ length: otp.length }, () => React.createRef<HTMLInputElement>());
      }
  useEffect(() => {
    console.log('OTP state has changed:', otp);
    console.log('Refs array:', inputRefs.current);
  }, [otp]);
    
  const handleInputChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);
    if (value.length === 1 && index < otp.length - 1) {
        inputRefs.current[index + 1]?.current?.focus();
      }
  
  };

const initialValues = {
    username:registeredData?.username,
    otp:otp.join('')
}
const {errors, submitForm} = useFormik({
    initialValues:initialValues,
    enableReinitialize:true,
    validationSchema:Yup.object({
        otp:Yup.string().required("Please Enter 4 Digit Verification Code")
    }),
    onSubmit:async (values)=> {
        try {
const result = await verifyOTP(values)
if(result.success ===true) {
        if(localStorage.getItem('RegisterAs') === 'Company') {
            navigate('/company-profile')
        }
        else if(localStorage.getItem('RegisterAs') === 'Individual') {
            navigate('/individual/jobs')
        }
        else if(localStorage.getItem('RegisterAs')==='supervisor')
        {
            navigate('/supervisor/work-order')
        }
        else {
            const result = await verifyOTP(values)
            if(result.success === true) {
             navigate('/cleaner-profile')
            }
        }
    }
    else {
          
    
    }
    }
catch(e){

}}
})
const payload = {
    id:registeredData?.id,
    email:email
}
const handleEmailChange = async ()=> {
    setLoader(true)
try {
let res;
if(localStorage.getItem('RegisterAs') === 'Company') {
    res = await updateCompany(payload)
}
else if(localStorage.getItem('RegisterAs') === 'Individual') {
    res = await updateIndividual(payload)
}
else if(localStorage.getItem('RegisterAs')==='supervisor')
{
   res = await updateSupervisor(payload)
}
else {         
    res = await updateCleaner(payload)
}
if(res?.success) {
    const otpresult = await sendOTP({username:registeredData?.username})
    if(otpresult?.success===true) {
        setShowModel(false)
            fetchData()
    }
}
}
catch(e:any) {
    setLoader(false)
}
}
const onCheckModel = ()=> {
    setShowModel(false)
}
useEffect(()=> {
console.log(inputRefs);

},[inputRefs])

    return (
        <>
            <AuthNavComponent></AuthNavComponent>
            <main>
                {loader && <LoaderFullScreen></LoaderFullScreen>}
                <div className='lg:container mx-auto lg:max-w-6xl lg:my-8 my-8 px-4 lg:px-0 lg:flex block'>
                    <FormDetails />
                    <div className='lg:flex-1 lg:flex block flex-col lg:mt-0 mt-4 h-fit lg:mx-12 mx-auto p-6 bg-white border border-gray-border rounded-xl'>
                        <h1 className='font-satoshiBold text-xl'>Verify email address</h1>
                        <div className='max-w-lg'>
                            <form className='mt-5'>
                                <label className={`text-normal font-satoshiBold text-sm`}>
                                    Please enter the verification code sent to your email address {registeredData?.email}
                                    <FontAwesomeIcon onClick={()=> setShowModel(true)} icon={faPen} className='ml-2 text-gray-800 cursor-pointer' />
                                </label>
                                <div className='flex'>
      {otp.map((digit, index) => (
        <div className='w-16 mr-5' key={index}>
          <input
            type="tel"
            maxLength={1}
            className='mt-2 text-gray-700 text-sm block w-full md:px-4 sm:px-1 md:py-2 sm:py-1 sm:text-sm md:text-md text-gray-700 placeholder-gray-500 bg-white border border-gray-border rounded-2xl
                        focus:border-sky-200 focus:ring-sky-200 focus:outline-none focus:ring focus:ring-opacity-40'
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value)}
            ref={inputRefs.current[index] }
          />
        </div>
      ))}
    </div>
                               
                            </form>
                            <div className='mt-5 flex space-x-10'>
                                <button type='submit'
                                    className='rounded-full
                                bg-pink
                                p-2
                                text-white
                                w-44
                                cursor-pointer
                                hover:bg-navy'
                                onClick={submitForm}>Verify</button>
                          <button type='submit'
                                    className='rounded-full
                                bg-pink
                                p-2
                                text-white
                                w-44
                                cursor-pointer
                                hover:bg-navy'
                                onClick={() => { localStorage.getItem('RegisterAs') === 'Company' ? navigate('/company-profile') : localStorage.getItem('RegisterAs') === 'Individual' ? navigate('/individual/jobs'): localStorage.getItem('RegisterAs')==='supervisor' ? navigate('/supervisor/work-order'):  navigate('/cleaner-profile')}}>Skip</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ModelWrapperWithButton Save={handleEmailChange} showModal={showModel} onCheckModel ={onCheckModel}>
            <Input
                onChange={(e)=> setEmail(e.target.value)}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.ADDRESS",
                    defaultMessage: "Email Address",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL.PLACEHOLDER",
                    defaultMessage: "Enter your email id",
                  })}
                  type={"email"}
                  name="email"
                  value={email}
                ></Input>
            </ModelWrapperWithButton>
        </>
    )
}