import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";

export const createTasklist = async (postData) => {
    try {
  return await post<ApiResponse<[]>>('/individual/checklist ', postData).then((res)=> {
    if(res?.success===true) {
      SuccessMessage('API.SUCCESS.INDIVIDUAL.TASKLIST.CREATE')
        return res
    }
    else {
        return null
    }
  })
}
catch(e:any) {
    HandleApiError(e)
}
};
