import React, { useEffect, useState } from 'react'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { supplementalpaydata } from '../Jsonalldata'
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox'
import { FormattedMessage } from 'react-intl'

export const SupplementalPayChange = (props) => {
    const [supplemental, setSupplemental] = useState([])
    useEffect(()=> {
        setSupplemental(props?.data?.job_advanced_id?.supplemental_pay)
    },[props?.data])
    useEffect(()=> {
      const data = {
        supplemental_pay:supplemental
      }
      props?.HandleDataProps(data)
    },[supplemental])
  return (
    <div className='pb-4'>
    <p className='pt-8 text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDPAYBENIFIT.INPUT.SUPPLEMENTALPAY" defaultMessage="Supplemental Pay" /></p>
<div className="flex flex-wrap space-x-4">
{supplementalpaydata.map((data, index) => (
<CheckBox
value={data.name.props.defaultMessage}
onChange={(e) => CheckBoxHandle(e, supplemental, setSupplemental)}
checked = {supplemental?.includes(data.name.props.defaultMessage)} />
))}
</div>

     </div>    
  )
}
