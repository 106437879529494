import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
export const getCleaner = async (cleaner_id?:any, id?:any) => {
    const queryParams = new URLSearchParams();  
      if (cleaner_id) queryParams.append('cleaner_id', cleaner_id.toString());
      if (id) queryParams.append('id', id.toString());
  try {
        return await get<ApiResponse<[]>>(`/cleaner?${queryParams}`).then((res)=> {
            if(res.success===true) {
                return res
            }
            else {
                return null
            }
        })
  }
  catch(e:any){
    HandleApiError(e);
            return null

  }

}
