import React, { useEffect, useState } from "react";
import { Input } from "sr/helpers";
import { FormattedMessage } from "react-intl";
export const MobileNumberChange = (props) => {
  const [mobileNo, setMobileNo] = useState()
  useEffect(()=> {
      setMobileNo(props?.data?.application_call_mobile_number)
  },[props?.data])

  useEffect(()=> {
    const data = {
      application_call_mobile_number:mobileNo
    }
    props.HandleDataProps(data)
  },[])
  return (

<div className='px-4 pt-4'>
            <Input 
            inputClass = "border border-zinc-800 rounded-lg h-11"
            InputDivClass="mt-0" label={<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.CALL" defaultMessage="Let applicants call you directly for this job" />     }
            smalllabel = {<FormattedMessage id="RECRUITER.POSTJOB.SETPREFERENCE.INPUT.MOBILENO" defaultMessage="Mobile number" />}
            placeholder="XXXX-XXXXX"
            value={mobileNo}
            onChange={(e:any)=> setMobileNo(e.target.value)}
            labelClass = "text-base"
            smalllabelclass="font-bold mt-3"
            >
            </Input>
            
        </div>  
  );
};
