import React, { useEffect, useState } from 'react'
import AuthNavComponent from '../module/auth/AuthNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faLocationDot, faNoteSticky, faStar, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import JobItemCard from './component/JobItemCard'
import Footer from '../module/Footer'
import { FormattedMessage } from 'react-intl'
import { getSavedJobs } from 'sr/utils/api/cleaner/getSavedJobs'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { toAbsoluteUrl } from 'sr/helpers'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
export default function SavedJobs() {
    const [cleanerId, setcleanerId] = useState()
    const [SavedJobs, setSavedJobs] = useState(null)
    const [loader, setLoader] = useState(false)
    const FetchSavedJob = async (cleanerId)=> {
        try {
            setLoader(true)
        const result = await getSavedJobs(cleanerId)
        if(result?.success===true) {
            setSavedJobs(result?.data?.job_ids)
            setLoader(false)
        }
    }
    catch(e){
        setLoader(false)
    }
     }  
     useEffect(()=> {
            const result = getUserDetails()
            setcleanerId(result.user_id)
     },[])    
     useEffect(()=> {
            if(cleanerId) {
                FetchSavedJob(cleanerId)
            }
     }, [cleanerId])
    return (
        <>
            <AuthNavComponent isLoggedin={true}></AuthNavComponent>
            <main>
            <ContentWithSpinner loader={loader} height='h-80'>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className='lg:flex flex-col lg:w-2/3 block'>
                        <div className='card-1 p-6 mb-5 bg-white border border-gray-border rounded-xl'>
                            <div className='header flex items-center space-x-3'>
                                <h1 className='font-medium text-2xl inline'>{SavedJobs?.length || 0}</h1>
                                <span className='font-semibold text-sm text-gray-600'>  <FormattedMessage id="CLEANER.SAVEDJOBS.TITLE" defaultMessage="Saved Jobs" />  </span>
                            </div>
                        </div>

                        <div>
                            {SavedJobs?.length >0 ?
                                SavedJobs?.map((datI) => {
                                    return <JobItemCard jobs={datI} isSavedJobs={true} link={`/job-detail/${datI?._id}`}></JobItemCard>
                                })
                                :
                                <div className='lg:container lg:mt-10 mt-10 px-4 lg:px-0 rounded-xl lg:max-w-6xl block bg-white border border-gray-border mx-auto'>
<div className='lg:container text-center mx-auto items-center justify-center lg:max-w-xl py-10'>
<div className='flex justify-center'>
    <img className='w-20' src={toAbsoluteUrl('media/img/emtydisplay.png')}></img>
</div>
<div className='text-2xl font-bold'> You have not any saved jobs</div>
    </div> </div>
                            }
                        </div>
                    </div>
                </div>
                </ContentWithSpinner>
            </main>
            <Footer />
        </>
    )
}
