import { getFile } from "sr/utils/api/getFile";
export const DownloadResume = async (resumeName, resumeKey) => {
    const resumeData = await getFile(resumeKey)
    if(resumeData) {
      const fileUrl =  URL.createObjectURL(resumeData);
      
          const fileName = resumeName;
      const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
  
    // Append the anchor to the body
    document.body.appendChild(link);
  
    // Trigger the click event to start the download
    link.click();
  
    // Remove the anchor from the body
    document.body.removeChild(link);
    }
  }