import { Formik, Form, Field, ErrorMessage } from 'formik'
import JobHttpHandle from 'sr/redux/services/job'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import 'tailwindcss/tailwind.css'
import 'index.css';

export default function CreateJob() {

    const validationSchema = Yup.object().shape({
        company_id: Yup.string().required("Company is required"),
        title: Yup.string().max(100).required("Title is required"),
        description: Yup.string().max(500).required("Job description is required"),
        schehdule_date: Yup.string().required("Schedule date is required"),
        reccurance: Yup.string().required("Recurring is required"),
        class: Yup.string().required("Class is required"),
        location: Yup.string().required("Location is required"),
        start_time: Yup.string().required("Start date is required"),
        end_time: Yup.string().required("End date is required"),
        duration: Yup.number().required("Duration is required"),
        pay_type: Yup.string().required("Pay type is required"),
        rate: Yup.number().required("Rate is required"),
        radius: Yup.number().integer().required("Job radius is required"),
        instructions: Yup.string().required("Instructions is required"),
        vacancies: Yup.number().required("Vacancies is required"),
    });

    const initialValues = {
        company_id: "6431846641b330bcd22e7ca6",
        title: "",
        description: "",
        schehdule_date: "",
        reccurance: "",
        class: "",
        location: "",
        start_time: "",
        end_time: "",
        duration: "",
        pay_type: "",
        rate: "",
        radius: "",
        instructions: "",
        vacancies: "",
    }

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const res = await JobHttpHandle.jobCreation(values);
            console.log("Job Created Successfully", res.data);
            toast.success(`Job ${res.data.title} is created sucessfully`);
            setSubmitting(false);
            resetForm();
        } catch (error) {
            toast.error('An error occurred while creating job. Please try again later.');
            setSubmitting(false);
        }
    };

    return (
        <div className="flex justify-center items-center">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {({ setFieldValue, isSubmitting }) => (
                    <Form className="w-full max-w-lg">
                        <div className='mt-10'>
                            <h1 className="text-base font-bold leading-7 text-gray-900">Create New Job</h1>
                            <p className="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="title">
                                    Job Title
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="title"
                                    type="text"
                                    name="title"
                                    placeholder="Enter job title"
                                />
                                <ErrorMessage name="title" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="description">
                                    Job Description
                                </label>
                                <Field
                                    as="textarea"
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="description"
                                    name="description"
                                    placeholder="Enter job description"
                                />
                                <ErrorMessage name="description" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="schehdule_date">
                                    Schedule date
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="schehdule_date"
                                    type="date"
                                    name="schehdule_date"
                                    placeholder="Enter job schehdule date"
                                />
                                <ErrorMessage name="schehdule_date" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="reccurance">
                                    Recurrence
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="reccurance"
                                    type="text"
                                    name="reccurance"
                                    placeholder="Enter reccurance"
                                />
                                <ErrorMessage name="reccurance" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="class">
                                    Class
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="class"
                                    type="text"
                                    name="class"
                                    placeholder="Enter class"
                                />
                                <ErrorMessage name="class" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="location">
                                    Location
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="location"
                                    type="text"
                                    name="location"
                                    placeholder="Enter location"
                                />
                                <ErrorMessage name="location" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="start_time">
                                    Start date
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="start_time"
                                    type="date"
                                    name="start_time"
                                    placeholder="Enter job schehdule date"
                                />
                                <ErrorMessage name="start_time" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="end_time">
                                    End date
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="end_time"
                                    type="date"
                                    name="end_time"
                                    placeholder="Enter job schehdule date"
                                />
                                <ErrorMessage name="end_time" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="duration">
                                    Duration
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="duration"
                                    type="number"
                                    name="duration"
                                    placeholder="Enter duration"
                                />
                                <ErrorMessage name="duration" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="pay_type">
                                    Pay Type
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="pay_type"
                                    type="text"
                                    name="pay_type"
                                    placeholder="Enter pay type"
                                />
                                <ErrorMessage name="pay_type" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="rate">
                                    Rate
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="rate"
                                    type="number"
                                    name="rate"
                                    placeholder="Enter rate"
                                />
                                <ErrorMessage name="rate" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="radius">
                                    Radius
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="radius"
                                    type="number"
                                    name="radius"
                                    placeholder="Enter radius"
                                />
                                <ErrorMessage name="radius" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="instructions">
                                    Intructions
                                </label>
                                <Field
                                    as="textarea"
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="instructions"
                                    type="text"
                                    name="instructions"
                                    placeholder="Enter instruction"
                                />
                                <ErrorMessage name="instructions" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs mb-2" htmlFor="vacancies">
                                    Vacancies
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="vacancies"
                                    type="number"
                                    name="vacancies"
                                    placeholder="Enter vacancies"
                                />
                                <ErrorMessage name="vacancies" component="p" className="text-red-500 text-xs italic" />
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <button
                                    type="submit"
                                    className="brancd-primary-button hover:bg-blue-700 font-bold py-2 px-4 rounded"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Creating...' : 'Create'}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}