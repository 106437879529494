import React, {useState} from 'react'
import { EmailChange } from './models/EmailChange'
import { PasswordChange } from './models/PasswordChange'
import { ChangePhone } from './models/ChangePhone'
import { ModelWrapperWithButton } from 'app/pages/company/component/ModelWrapperWithButton'
export const AccountSettings = () => {
    const [showModal, setShowModal] = useState(false)
    const [activeComponent, setActiveComponent] = useState<any>({});
    const HandleModel = (data) => {
        setShowModal(data)
}
const AllModels = [
    {
      label: 'EmailChange',
      componentName: EmailChange,
      headertitle:'Change Email'
    },
    {
        label: 'CompanyNameChange',
        componentName: PasswordChange,
        headertitle:'Change Password'
      },
    {
        label:'ChangePhone',
        componentName:ChangePhone,
        headertitle:'Change Phone Number'
    }
    ]

const openModel = (index: any) => {  
    const activeComp = AllModels.filter(function (e, i) {
      return i == index
    });      
    console.log(activeComp[0])
    setActiveComponent(activeComp[0]);

    setShowModal(true)
  };
  return (
    <>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'>Email</h2>
            <div>agristudyhindi@gmail.com</div>
        </div>
        <div onClick={()=> openModel(0)} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
                Change email</div>
    </div>
    
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'>Password</h2>
            <div>••••••••</div>
        </div>
        <div onClick={() => openModel(1)} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
                Change password</div>
    </div>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'>Phone number</h2>
            <div>+919140605066</div>
        </div>
        <div onClick={()=> openModel(2)} className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
                Change Phone Number</div>
    </div>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'>Third party authentication</h2>
            <div>No third-party applications have access to your account
    </div>
        </div>
    </div>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className='font-satoshiBold'>agristudyhindi@gmail.com</h2>
        </div>
        <div className="text-[#2557A7] hover:text-[#2557A7] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg font-satoshiBold text-base px-3 cursor-pointer border border-neutral-200">
                Sign out</div>
    </div>
    <ModelWrapperWithButton showModal = {showModal} onCheckModel = {HandleModel} headertitle = {activeComponent.headertitle}>
    {<activeComponent.componentName />}
    </ModelWrapperWithButton>
    </>
  )
}
