import React from "react";
import { Input } from "sr/helpers";

export const ApproveWorkHours = ({cworkHours, aworkHours, setAWorkHours}) => {
  return (
    <>
    <Input
                  label={'Contractor Work Completion Time (Hours)'}
                  type="number"
                  inputClass="rounded-lg"
                  labelClass="mt-2 text-black"
                  InputDivClass="mt-5 w-96  pr-2 pb-2 mx-auto"
                  placeholder="Number of hours"
                  value={cworkHours}
                />
  <div className="text-center">Approved To</div>
  <Input
                  label={'Enter Approved Time (Hours)'}
                  type="number"
                  inputClass="rounded-lg"
                  labelClass="mt-2 text-black"
                  InputDivClass="mt-2 w-96  pr-2 mb-2 mx-auto"
                  placeholder="Number of hours"
                  onChange={(e)=> setAWorkHours(e.target.value)}
                  value={aworkHours}
                />   
                  </>
  );
};
