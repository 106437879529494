import React, {useState, useEffect, ForwardRefRenderFunction, forwardRef} from 'react'
import { FormattedMessage } from "react-intl";
import { businessTypes } from "sr/i18n/messages/setting";
import { ReactSelect } from "sr/helpers/ui-components/ReactSelect";
import * as Yup from 'yup'

interface data {
  business_type: Array<string>;
}


const ChangeBusinessType: ForwardRefRenderFunction<any, any> = (props, ref) => {
  const [businessType, setBusinessType] = useState(props?.data?.business_type);
  const [errors, setErrors] = useState<any>({});

  useEffect(()=> {
    props?.HandleData({
      business_type: businessType
    });
    if(Object.keys(errors).length !== 0 ){
      validateData();
    }
  },[businessType]);

  const CompanyNameChange = Yup.object({
    business_type: Yup.array().min(1, 'Please select business type'),
  });

  const validateData = async () =>  {
    const data:data = {
      business_type: businessType,
    }
    let isValid: boolean;
    try {
      await CompanyNameChange.validate(data, { abortEarly: false });
      setErrors({}); // Reset errors if validation is successful
      props?.HandleData(data);
      isValid= true;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      isValid= false;
    }
    return isValid;
  };

  React.useImperativeHandle(ref, () => ({
    validateData
  }));

  return (  
    <div className="relative w-full">
      <ReactSelect 
        label={
          <FormattedMessage
            id="RECRUITER.REGISTER.INPUT.SELECT.BUSSINESS.TYPE"
            defaultMessage="Select Business Type"
          />
        }
        placeholder="Select Business Type"
        type={"text"}
        onChange={(selectedOptions) => setBusinessType(selectedOptions.map(option => option.value))}
        value={businessType}
        isMulti={true}
        options={businessTypes}
        defaultValue={businessTypes?.filter((item) => businessType?.includes(item?.value))}
      />
      {errors?.business_type && (
        <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{errors?.business_type as React.ReactNode}</p>
      )}
    </div>
  );
};

export default forwardRef(ChangeBusinessType);