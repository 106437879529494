import React from "react";
import ApiResponse from "sr/models/ApiResponse";
import { post } from "sr/utils/axios";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
export const addAdvanceDetails = async (postData:any) => {
    try {
      return await post<ApiResponse<[]>>('/job/advanced', postData).then((res)=> {
          if(res.success === true){
              return res
          }
          else {
              return null
          }
      })
    }
    catch(e:any) {
      HandleApiError(e);
            return null
    }
  };
  