import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { PaymentForm } from "./PaymentForm";
import { useState } from 'react';
  
export const Payment = () => {
    const stripePromise = loadStripe('pk_test_51PJeeVBcHXM7Sw7Mi069GFF7solI8yb9yXweLRDJPj3ozJ3TsN30qcT2P6AAQ7NS1dEEHUOz7I4Yt3FKvJiALPMg00vwnUCuzC');
    const options = {
      appearance: {/*...*/},
    };
  
  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm/>
    </Elements> 
  );
};
