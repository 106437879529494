import { useGet } from "app/pages/company/Hooks/Api/useGet";
import { useAuth } from "app/pages/module/auth";
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchContractor } from "sr/helpers/apiFunctions/contractor/fetchContractor";
import { fetchCustomerLocations } from "sr/helpers/apiFunctions/fetchCustomerLocations";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { RadioInput } from "sr/helpers/ui-components/company/RadioInput";
import DropDown from "sr/helpers/ui-components/DropDown.tsx";
import { ReactDatePicker } from "sr/helpers/ui-components/ReactDatePicker";
import { fetchSupervisor } from "sr/redux/reducers/supervisor";
import { AppDispatch } from "sr/redux/store";
import { getWorkorder } from "sr/utils/api/company/workorder/getWorkorder";
import { addTasks } from "../../function/addTasks";
import { checklistHandle } from "../../function/checklistHandle";
import { createWorkorder } from "sr/utils/api/company/workorder/createWorkorder";
import { updateManageTaskSubmit } from "sr/utils/api/company/workorder/updateManageTaskSubmit";
import { updateWorkorder } from "sr/utils/api/company/workorder/updateWorkorder";
import { useNavigate } from "react-router-dom";
import { ApproveWorkHours } from "./ApproveWorkHours";
import { toast } from "react-toastify";
import { CheckMandatorTask } from "./function/CheckMandatoryTask";
import { Rating } from "../../Rating";
import { createfeedBack } from "sr/utils/api/company/workorder/createfeedBack";
interface RejectWorkOrderProps {
    workorderId: string;
    managetasks: any[]; 
    tasklist:any[];
    taskTrackId:any
    dataAddedHandle:any
    submittask:any
    setShowModal:any
  }
  
  const RejectWorkOrder:ForwardRefRenderFunction<any, RejectWorkOrderProps> = ({workorderId, managetasks, tasklist, taskTrackId, dataAddedHandle=null, submittask, setShowModal}, ref) => {
    const [randC, setRandC] = useState(false)   // Reject and Create
    const [sameContractor, setSameContractor] = useState(true)
    const {userType} = useAuth()
    const [userId, setUserId] = useState('')
    const [customerLocationId, setCustomerLocationId] = useState<string>()
    const supervisorData = useSelector((item:any)=> item?.supervisor?.data)
    const [Contractors, setContractors] = useState<any>([])
    const [contractor, setContractor] = useState()
    const [onetimeDate, setOneTimeDate] = useState();
    const [checklist, setChecklist] = useState('All Task')
    const [payment, setPayment] = useState('')
    const [reviewData, setReviewData] = useState<any>()
    const [cworkHours, setCWorkHours] = useState()  // C - Contractor
const [aworkHours, setAWorkHours] = useState()   // A - Approved
const [showNTO, setShowNTO] = useState(false)  // NTO - Not Completed Option
const [error, setError] = useState(null)
    const {getData:getWorkOrders, data:workorder, loader:loaderWorkOrder, pageination:pageination}:any = useGet()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    useEffect(() => {
        const { user_id } = getUserDetails();
        if(userType==='supervisor'){
          setUserId(user_id)
        }
      }, []);
      useEffect(()=> {
        if(managetasks?.length>0) {
           setShowNTO(managetasks?.some((item)=> item?.supervisor_status==='rejected'))
        }
      },[managetasks])
      useEffect(()=> {
        if(userId && userType==='supervisor') {
          if(!supervisorData){
            dispatch(fetchSupervisor({id:userId}))
          }
        }
  },[userId, userType, dispatch])
  useEffect(()=> {
    if(submittask?.length>0) {
      setAWorkHours(submittask[0]?.work_completion_time)
      setCWorkHours(submittask[0]?.work_completion_time)
    }
    
      },[submittask])
      const loadContractor = async () => {
        await fetchContractor({userType:userType, userId:userId, customerLocationId:customerLocationId, setData:setContractors})
      };
      const fetchAllLocation = async (companyId)=> {
        const data= await fetchCustomerLocations({userType:userType, companyId:companyId})
        setCustomerLocationId(data?.map((item)=> item?.id)?.join())
      }
      useEffect(()=> {
        if(supervisorData){
        const id:Array<any> =  supervisorData?.customer_location_ids?.map((item)=> item?._id)
        if(id?.length>0){
        setCustomerLocationId(id?.join());
        }
        else {
        fetchAllLocation(supervisorData?.company_id?._id)
      }
      }
        
       },[supervisorData])
       useEffect(()=> {
        if(userId && userType) {
          if(userType==='supervisor'){
            if(customerLocationId){
        loadContractor()
        }
      }}
      },[userId, customerLocationId, userType])
      useEffect(()=> {
        const fetchWorkOrder = async (workorderId)=> {
            const queryParams = new URLSearchParams()
            if (workorderId) queryParams.append('id', workorderId)
              await getWorkOrders(() => getWorkorder(queryParams));
        }
        if(workorderId) {
          fetchWorkOrder(workorderId)
        }
    },[workorderId])
    const HandleWorkOrderCopy = async ()=> {
      if(randC===false){
        setShowModal(false)
        return
      }
      if(!onetimeDate) {
        toast.error('Enter Scheduled Date')
        setError({scheduleDate:'INPUT.VALIDATION.WORKORDER.SCHEDULEDATE'})
        return
      }
  const checklistId = await checklistHandle(checklist, workorder, tasklist, managetasks)   
  if(!checklistId) {
    toast.success('All Tasks is Approved')
    return 
  }
 if(checklistId){
  let payload = {}
  if(sameContractor === true){
      payload = { ...workorder, checklist_id: checklistId, approval_status:'', workorder_status:'scheduled',one_time_date:onetimeDate }
  }
  else {
    payload = { ...workorder, checklist_id: checklistId, contractor_id:contractor, approval_status:'', workorder_status:'scheduled', one_time_date:onetimeDate }
  }
 const resworkorder = await createWorkorder(payload)
 if(resworkorder?.success===true) {
  const res = await updateManageTaskSubmit({
    workorder_id:workorderId,
    id:taskTrackId,
    approve_time:aworkHours,
    status:'rejected',
    new_workorder_id:resworkorder?.data?.id,
    rescheduled:true,
    supervisor_status:'rejected',
    supervisor_id:userId
  })
  if(res?.success) {
    const payLoad = {
      id:workorderId,
      approval_status:'rejected'
      }
      const result = await updateWorkorder(payLoad)
      if(result?.success===true){
        const feedbackpayload = {
          workorder_id:workorder?.id,
          supervisor_id:userId,
          contractor_id:workorder?.contractor_id?._id,
          internal_supervisor_rating:reviewData?.internalRating,
          internal_supervisor_feedback:reviewData?.internalFeedback,
          external_supervisor_feedback:reviewData?.externalFeedback,
          external_supervisor_rating:reviewData?.externalFeedback
        }
        const feedbackres = await createfeedBack(feedbackpayload)
        navigate('/work-order')
      }
 }
}

 }

    }
    React.useImperativeHandle(ref, () => ({
     HandleWorkOrderCopy
      }));
  return (
  <>
     <div className="mb-5">
        <p className=" text-base">Do you want reject this Workorder and create New ?</p>
        <RadioInput label={'Yes'} name={'yes'} checked={randC===true} value={true} onChange={()=> setRandC(true)} inputcss='border border-zinc-800 h-11 rounded-lg my-2'></RadioInput>
       
        <RadioInput label={'No'} name={'no'} checked={randC===false} value={false} onChange={()=> setRandC(false)} inputcss='border border-zinc-800 h-11 rounded-lg my-2'></RadioInput>
     
      </div>
      {
      randC && <>  
     <ApproveWorkHours cworkHours={cworkHours} aworkHours={aworkHours} setAWorkHours={setAWorkHours}></ApproveWorkHours>
      <div className="mb-5">
      <p className=" text-base">Do you want Create Checklist With</p>
      <RadioInput label={'All Task'} name={'All Task'} checked={checklist==='All Task'} value={'All Task'} onChange={(e)=> setChecklist(e.target.value)} inputcss='border border-zinc-800 h-11 rounded-lg my-2'></RadioInput>
      {showNTO &&
      <RadioInput label={'Not Completed Task'} name={'Not Completed Task'} checked={checklist==='Not Completed Task'} value={'Not Completed Task'} onChange={(e)=> setChecklist(e.target.value)} inputcss='border border-zinc-800 h-11 rounded-lg my-2'></RadioInput>
  }
      </div>
    <div className="mb-5">
      <p className=" text-base">Do you want assign this Workorder to same Contractor ?</p>
      <RadioInput label={'Yes'} name={'Yes'} checked={sameContractor===true} value={true} onChange={()=> setSameContractor(true)} inputcss='border border-zinc-800 h-11 rounded-lg my-2'></RadioInput>
    
      <RadioInput label={'No'} name={'No'} checked={sameContractor===false} value={false} onChange={()=> setSameContractor(false)} inputcss='border border-zinc-800 h-11 rounded-lg my-2'></RadioInput>
      </div>
     {!sameContractor &&
<DropDown
                labelClass="text-base"
                value={contractor}
                label={
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.TITLE"
                    defaultMessage="Contractor"
                  />
                }
                dropdowndiv="mt-1 w-26 pr-2 pb-2"
                onChange={(e) => setContractor(e.target.value)}
                className="rounded-lg"
              >
                <option value="">
                  <FormattedMessage
                    id="RECRUITER.SCHEDULEWORKORDER.INPUT.CONTRACTOR.SELECT"
                    defaultMessage="Select Contractor"
                  />
                </option>
                {Contractors?.map((data, index) => (
                  <option key={index} value={data?.id}>
                    {data?.first_name} {data?.last_name}
                  </option>
                ))}
              </DropDown>
}

<ReactDatePicker
            onChange={(date) => setOneTimeDate(date)}
            selected={onetimeDate}
            label={
              <FormattedMessage
                id="RECRUITER.SCHEDULEWORKORDER.INPUT.SCHEDULEDATE"
                defaultMessage="Schedule Date"
              />
            }
            inputClass="rounded-lg"
            labelClass="mt-2 text-black"
            InputDivClass="mt-0 w-26 pr-2 mb-5"
            type="date"
            placeholder="Enter date"
            error={error?.scheduleDate}
          ></ReactDatePicker>
          <Rating setReviewData = {setReviewData} />

      </>}
      </>);
};
export default forwardRef(RejectWorkOrder)