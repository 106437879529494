import { useValidationErrorHandle } from "../functions/validationErrorHandle"

type Props = {
  labelClass?: any
  label?: any
  name?: any
  onChange?: any
  className?: any
  required?: any
  value?: any
  children?: any
  error?: any
  type?: any
  labelMargin?: any
  dropdowndiv?:any
}

export default function DropDown({
  labelClass,
  label,
  name,
  onChange,
  className,
  required,
  children,
  error,
  labelMargin,
  value,
  dropdowndiv
}: Props) {
  const validationErrorHandle = useValidationErrorHandle()
  return (
    <div className={` ${dropdowndiv}`}>
      <label
        className={` ${labelClass}`}
        htmlFor={name}
      >
        {label ? <p className={` ${labelMargin} `}>{label}</p> : ' '}
        <select
        
          id={name}
          onChange={onChange}
          className={` ${className}  md:px-2 sm:px-1 md:py-2 sm:py-1 block w-full bg-white border rounded-2xl
          focus:border-sky-200 focus:ring-sky-200 focus:outline-none focus:ring focus:ring-opacity-40`}
          name={name}
          value={value}
          required={required}
        >
          {children}
        </select>
        {error ? <p className='mt-1 text-red-400 md:text-sm sm:text-[.65rem]'>{validationErrorHandle(error)}</p> : ''}
      </label>
    </div>
  )
}

DropDown.defaultProps = {
  className: '',
  labelClass: 'mb-2 text-sm',
  label: '',
  name: '',
  type: '',
  onChange: '',
  value: '',
  required: false,
  error: '',
  children: '',
  labelMargin: '',
  dropdowndiv: 'mt-10',
}
