import { Amex, Discover, MasterCard, Visa } from "app/pages/individual/jobs/component/CardIcon";
export const getCardIcon = (name) => {
  switch (name) {
      case 'visa':
        return <Visa />
      case 'mastercard':
        return <MasterCard />
      case 'amex':
        return  <Amex /> 
      case 'discover':
        return <Discover />
      default:
        return null   
  }


}; 
