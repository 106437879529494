import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import ApiResponse from "sr/models/ApiResponse";
import { get } from "sr/utils/axios";

export const getSupervisor = async (queryParams) => {
    try {
  return await get<ApiResponse<[]>>(`/supervisor?${queryParams}`).then((res)=>{
        if(res?.success===true){
                    return res
        }
        else {
return null
        }
  })
}catch(e) {
    HandleApiError(e)
}
};
