import { faCheck, faCircle, faStar, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGet } from "app/pages/company/Hooks/Api/useGet";
import Footer from "app/pages/module/Footer";
import { useAuth } from "app/pages/module/auth";
import AuthNavComponent from "app/pages/module/auth/AuthNav";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "sr/helpers";
import { DateFormat } from "sr/helpers/functions/DateFormat";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { getapplyWorkOrder } from "sr/utils/api/company/workorder/getapplyWorkOrder";

export const AppliedWorkOrder = () => {
    const {isAuthenticate, userType} = useAuth()
    const [cleanerId, setCleanerId] = useState(null)
    const [currentOrder, setCurrentOrder] = useState(null)
    const {data:application, getData:getApplication, loader:loader} = useGet()
    
    useEffect(()=> {
            setCurrentOrder(application && application[0])
    },[application])
    
    useEffect(()=> {
        if(userType ==='cleaner') {
            const getdetails = getUserDetails()
            setCleanerId(getdetails?.user_id)
        }
    },[])

    const HandleCurrentOrder = (id)=> {
            setCurrentOrder(application?.find((item)=>item?.id===id))
    }

    const getappliedworkorder = async (cleanerId)=> {
            const params = new URLSearchParams()
            if (cleanerId) params.append('cleaner_id', cleanerId)
              await getApplication(()=> getapplyWorkOrder(params))  
    }

    useEffect(()=>{
        if(cleanerId) {
            getappliedworkorder(cleanerId)
        }
    },[cleanerId])
useEffect(()=> {
console.log(currentOrder);

},[currentOrder])
    const currentJobStatus = (Status, updatedAt)=> {
        switch (Status) {
            case 'accept':
                return (<li className="mb-10 ml-6">
                <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${'bg-green-500'}`}>
                    <FontAwesomeIcon icon={faCheck} className='text-white text-xs' />
                </span>
                <h3 className="font-bold font-satoshiBold leading-tight text-gray-800"><FormattedMessage id='RECRUITER.SIDEBAR.ACTION.TYPE.ACCEPT'  defaultMessage={'Accept'} /></h3>
                <p className="text-sm">{DateFormat(updatedAt)}</p>
            </li>)
                break;
            case 'reject':
                return  (  <li className="mb-10 ml-6 text-red-500">
                                        <span className={`absolute flex text-red-500 items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${'bg-red-500'}`}>
                                            <FontAwesomeIcon icon={faX} className='text-xs text-white' />
                                        </span>
                                        <h3 className="font-bold text-red-500 font-satoshiBold leading-tight"><FormattedMessage id='RECRUITER.SIDEBAR.ACTION.TYPE.REJECT'  defaultMessage={'reject'} /></h3>
                                        <p className="text-sm text-red-500">{DateFormat(updatedAt)}</p>
                                    </li>)
                break;
            default:
             return  (<li className="mb-10 ml-6">
<span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white ${'bg-green-500'}`}>
    <FontAwesomeIcon icon={faCheck} className='text-white text-xs' />
</span>
<h3 className="font-bold font-satoshiBold leading-tight text-gray-800"><FormattedMessage id='APPLIEDJOBS.APPLICATION.RECRUITER.ACTION'  defaultMessage={'Awaiting Recruiter Action'} /></h3>
</li>)

        }
    }
  return (<>
    <AuthNavComponent isLoggedin={true}></AuthNavComponent>
            <main>
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                    <div className='card-1 p-6 bg-white border border-gray-border rounded-xl'>
                        <div className='statistics flex items-center'>
                            <div className='font-bold font-satoshiBold text-5xl mr-3'>
                                {application?.length}
                            </div>
                            <div>
                                <span> <FormattedMessage id="APPLIED.TOTAL.WORK.ORDER.TITLE" defaultMessage="Total Applied Work Order" />  </span>
                            </div>
                        </div>
                    </div>
                </div>
                 <ContentWithSpinner loader={loader} height='h-80'> 
                 {application?.length >0 ?
                <div className='lg:container mx-auto lg:max-w-6xl lg:mt-10 mt-10 px-4 lg:px-0'>
                <div className='lg:flex block bg-white border border-gray-border rounded-xl'>

                    <div className='flex flex-col lg:w-2/5 w-full left-panel border-r'>
                        <div className='card-1 p-6 mb-5'>
            {application?.map((item, index)=> (
                            <div key={index} onClick={()=> HandleCurrentOrder(item?.id)} className={`${currentOrder?.id===item?.id && 'bg-[#ecf5ff] hover:bg-[#ecf5ff]'} cursor-pointer border-b border-neutral-200 py-3`}>
                                <h1 className='text-base font-bold font-satoshiBold mb-1'>{item?.workorder_id?.title}</h1>
                                <h1 className='text-sm font-bold font-satoshiBold mb-1'>{item?.workorder_id?.customer_id?.name}</h1>
                                <h3 className='text-xs font-bold font-satoshiBold mb-3'>{item?.workorder_id?.company_id?.company_name}</h3>
                            </div>
                            ))}
                        </div>
                    </div>

                    <div className='flex flex-col w-2/3 h-fit p-6 right-panel'>
                        <div className='header'>
                            <div className="flex justify-between items-center">
                                <div>
                            <h1 className='font-bold font-satoshiBold text-xl'>{currentOrder?.workorder_id?.customer_id?.name}</h1>
                            <div className='flex items-center mb-7'>
                                <span className='text-base text-gray-500 font-semibold font-satoshiBold mr-1'>{currentOrder?.workorder_id?.company_id?.company_name}</span>
                                {/* <span className=''>
                                    <FontAwesomeIcon icon={faStar} className='text-yellow-500 text-sm px-1' />
                                    <span className='text-sm text-gray-700'>3.5</span>
                                </span> */}
                                {/* <div className='border border-r m-2 border-gray-300 h-3'></div>
                                <span className='text-sm text-gray-700'>59  <FormattedMessage id="CLEANER.JOBS.REVIEWS" defaultMessage="Reviews" /> </span>
                           */}
                            </div>
                            </div>
                            </div>
                            <div className='border-b'></div>
                            <div className='mt-7'>
                                <strong className='text-lg font-bold font-satoshiBold'> <FormattedMessage id='APPLIEDJOBS.APPLICATION.STATUS'  defaultMessage={'Application Status'} /></strong>
                                <ol className="relative text-gray-500 border-l border-gray-800 mt-2">
                                    <li className="mb-10 ml-6">
                                        <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white bg-green-500`}>
                                            <FontAwesomeIcon icon={faCheck} className='text-white text-xs' />
                                        </span>
                                        <h3 className="font-bold font-satoshiBold leading-tight text-gray-800"><FormattedMessage id='APPLIEDJOBS.APPLIED'  defaultMessage={'Applied'} /></h3>
                                        <p className="text-sm">{DateFormat(currentOrder?.createdAt)}</p>
                                    </li>
                                    <li className="mb-10 ml-6">
                                        <span className={`absolute flex items-center justify-center w-4 h-4 -left-2 p-2 rounded-full ring-4 ring-white bg-green-500`}>
                                            <FontAwesomeIcon icon={faCheck} className='text-white text-xs' />
                                        </span>
                                        <h3 className="font-bold font-satoshiBold leading-tight text-gray-800"><FormattedMessage id='APPLIEDJOBS.APPLICATION.SENT'  defaultMessage={'Application Sent'} /></h3>
                                        <p className="text-sm">{DateFormat(currentOrder?.createdAt)}</p>
                                    </li>

                                    {currentJobStatus(currentOrder?.status, currentOrder?.updatedAt)}
                                
                                </ol>
                            </div>
      

                            </div>
                        
                    </div>
                </div></div>
                :
                <div className='lg:container lg:mt-10 mt-10 px-4 lg:px-0 rounded-xl lg:max-w-6xl block bg-white border border-gray-border mx-auto'>
<div className='lg:container text-center mx-auto items-center justify-center lg:max-w-xl py-10'>
<div className='flex justify-center'>
    <img className='w-20' src={toAbsoluteUrl('/media/img/emtydisplay.png')}></img>
</div>
<div className='text-2xl font-bold'> You have not applied to any Work Order</div>
    </div> </div>
}
                </ContentWithSpinner>
            </main>
           <Footer />
  
           </>);
};
