import AuthNavComponent from "./AuthNav";
import { Button, Input, toAbsoluteUrl } from "sr/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { FormDetails } from "sr/helpers/ui-components/FormDetails";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import { useState } from "react";
import { authSchema } from "sr/utils/schemas/authSchema";
import { login } from "sr/utils/api/login";
import { toast } from "react-toastify";
import { ButtonAuth } from "sr/helpers/ui-components/ButtonAuth";
import { useAuth } from "./core/Auth";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
export default function SigninPage() {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [LoginType, setLoginType] = useState("")
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const {persistToken} = useAuth()
  const initialValues = {
    username:username,
    password:password
  }
  const intl = useIntl();
  const {handleChange, submitForm, handleSubmit, errors} = useFormik({
    initialValues:initialValues,
    enableReinitialize:true,
    validationSchema:authSchema,
    onSubmit:async (values)=> {
      try {
        setLoader(true)
      const result = await login(values)
      if(result.success=== true) {
        setLoader(false)
        const returnType = result.data.user.type
        const data = {
          userType:result?.data?.user.type,
          user_id:result?.data?.auth.user_id,
          jwt:result?.data?.auth.jwt
        }
        if (LoginType?.toLowerCase() === returnType?.toLowerCase() || (returnType?.toLowerCase() === 'supervisor' && LoginType?.toLowerCase()==='company')) {
          SuccessMessage("API.SUCCESS.LOGIN")
        localStorage.setItem('auth', JSON.stringify(data))
        persistToken()
        switch (LoginType) {
          case 'company':
            if(LoginType===returnType) {
            navigate('/jobs-listing');
            }
            else {
              navigate('/supervisor/work-order')
            }
            break;
          case 'cleaner':
            navigate('/cleaner-jobs');
            break;
          case 'individual':
            navigate('/individual/jobs');
            break;
        }}
        else {
          toast.error("Your Details are incorrect")
          setLoader(false)
        }
      }}
      catch(e:any) {
        setLoader(false)
      }
    }
  })
  return (
    <>
      <AuthNavComponent isLoginPage={true}></AuthNavComponent>
      <main>
        <div className="lg:container mx-auto lg:max-w-6xl lg:mt-8 mt-8 px-4 lg:px-0">
          <div className="lg:flex block">
            <FormDetails />
            <div className="lg:flex-1 lg:flex flex-col h-fit lg:mx-12 block p-6 bg-white border border-gray-border rounded-xl">
              <h1 className="font-satoshiBold text-xl">
                <FormattedMessage
                  id="AUTH.LOGIN.BUTTON"
                  defaultMessage="Login"
                />
              </h1>
              <div className="max-w-lg">
             
                  <Input
                    onChange={(e)=> setUsername(e.target.value)}
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.USERNAME",
                      defaultMessage: "Enter Username",
                    })}
                    type="email"
                    value={username}
                    error={errors.username}
                    placeholder={intl.formatMessage({
                      id: "AUTH.LOGIN.INPUT.USERNAME.PLACEHOLDER",
                      defaultMessage: "Enter your username",
                    })}
                  ></Input>
                  <Input
                   onChange={(e)=> setPassword(e.target.value)}
                    label={intl.formatMessage({
                      id: "AUTH.INPUT.PASSWORD",
                      defaultMessage: "Password",
                    })}
                    placeholder={intl.formatMessage({
                      id: "AUTH.LOGIN.INPUT.PASSWORD.PLACEHOLDER",
                      defaultMessage: "Enter your password",
                    })}
                    value={password}
                    type={"password"}
                    error={errors.password}
                  ></Input>
           
                <div className="mt-2 flex justify-end">
                  <Link
                    to={"/forget-password"}
                    className="cursor-pointer hover:text-pink underline float-right"
                  >
                    <FormattedMessage
                      id="AUTH.FORGOT.TITLE"
                      defaultMessage="Forgot Password?"
                    />
                  </Link>
                </div>
                <div className="mt-5 flex space-x-10">
                  <div 
                  // to={"/cleaner-jobs"}
                  >
                    <ButtonAuth loader={loader}
                    onClick={() => {submitForm(); setLoginType('cleaner'); }}
                    >
                      {" "}
                      <FormattedMessage
                        id="AUTH.GENERAL.LOGIN.CLEANER"
                        defaultMessage="Login as Cleaner"
                      />
                    </ButtonAuth>
                  </div>
                  <div >
                    <ButtonAuth loader={loader}
                    onClick={() => {submitForm(); setLoginType('individual'); }}
                    >
                      <FormattedMessage
                        id="AUTH.GENERAL.LOGIN.INDIVIDUAL"
                        defaultMessage="Login as Individual"
                      />
                    </ButtonAuth>
                  </div>
                  <div 
                  // to={"/jobs-listing"}
                  >
                    <ButtonAuth loader={loader} onClick={() => {submitForm(); setLoginType('company'); }}>
                      <FormattedMessage
                        id="AUTH.GENERAL.LOGIN.COMPANY"
                        defaultMessage="Login as Company"
                      />
                    </ButtonAuth>
                  </div>
                </div>
                <div className="mt-5 flex space-x-10">
                  <Link
                  to="/create-cleaner"
                  >
                    <ButtonAuth loader={loader}
                    >
                      <FormattedMessage
                        id="AUTH.GENERAL.REGISTER.CLEANER"
                        defaultMessage="Register as Cleaner"
                      />
                    </ButtonAuth>
                  </Link>
                  <Link to="/registerasindividual" replace>
                    <ButtonAuth loader={loader}
                      
                    >
                      <FormattedMessage
                        id="AUTH.GENERAL.REGISTER.INDIVIDUAL"
                        defaultMessage="Register as Individual"
                      />
                    </ButtonAuth>
                  </Link>
                  <Link to="/create-company" replace>
                    <ButtonAuth loader={loader}
                    >
                      <FormattedMessage
                        id="AUTH.GENERAL.REGISTER.COMPANY"
                        defaultMessage="Register as Company"
                      />
                    </ButtonAuth>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
