import React from "react";
import DashboardWrapper from "app/pages/dashboard/DashboardWrapper";
import ReportWrapper from 'app/pages/reports/ReportWrapper'
import CreateJob from 'app/pages/jobs/createJob'
import { Jobs } from 'app/pages/individual/jobs/jobs'
import { Candidates } from 'app/pages/company/candidates/Candidates'
import { CandidateView } from 'app/pages/company/candidates/CandidateDetails'
import { JobsListing } from 'app/pages/company/jobs-listing/JobsListing'
import { PostedJobDetails } from 'app/pages/company/jobs-listing/PostedJobDetails'
import { Messages } from 'app/pages/company/Messages'
import { Settings } from 'app/pages/company/settings/Settings'
import { WorkOrder } from 'app/pages/company/work-order/WorkOrder'
import { ScheduleWorkOrder } from 'app/pages/company/work-order/ScheduleWorkOrder'
import { ManageSchedule } from 'app/pages/company/work-order/ManageSchedule'
import { AddCustomer } from 'app/pages/company/Customer/AddCustomer'
import { ManageCustomer } from 'app/pages/company/Customer/ManageCustomer'
import { CustomerDetails } from 'app/pages/company/Customer/CustomerDetails'
import { BasicDetails } from 'app/pages/company/jobpost/component/BasicDetails'
import { AdvanceDetails } from 'app/pages/company/jobpost/component/AdvanceDetails'
import { Review } from 'app/pages/company/jobpost/component/Review'
import { BillingAndInvoices } from 'app/pages/company/BillingAndInvoices/BillingAndInvoices'
import { WorkOrderView } from 'app/pages/company/work-order/WorkOrderView'
import { WorkOrderProgress } from 'app/pages/company/work-order/WorkOrderProgress'
import { Supervisor } from 'app/pages/company/work-order/supervisor/Supervisor'
import { AddSupervisor } from 'app/pages/company/work-order/supervisor/AddSupervisor'
import { SupervisorDetails } from 'app/pages/company/work-order/supervisor/SupervisorDetails'
import { Contractor } from 'app/pages/company/work-order/contractor/Contractor'
import { AddContractor } from 'app/pages/company/work-order/contractor/AddContractor'
import { ContractorDetails } from 'app/pages/company/work-order/contractor/ContractorDetails'
import { Calendar } from "app/pages/company/work-order/calendar/Calendar";

export const CompanyRoutes = [ 
    { path:'/dashboard' ,element:DashboardWrapper},
    { path:'/create-job' ,element:CreateJob },
    { path:'/jobs' ,element:Jobs,  },
    { path:'/reports' ,element:ReportWrapper },
    { path:'/job-post/Add-Basic-Details/' ,element:BasicDetails },
    { path:'/job-post/Add-Basic-Details/:job_id' ,element:BasicDetails },
    { path:'/job-post/Add-Advance-Details/:job_id' ,element:AdvanceDetails },
    { path:'/job-post/review/:job_id' ,element:Review, },
    { path:'/candidates' ,element:Candidates  },
    { path:'/candidates/:status' ,element:Candidates },
    { path:'/candidates/job/:job_id' ,element:Candidates },
    { path:'/candidates/:status/:job_id' ,element:Candidates },
    { path:'/candidate-details/:id' ,element:CandidateView },
    { path:'/jobs-listing'   ,element:JobsListing },
    { path:'/postedjob-details/:id' ,element:PostedJobDetails },
    { path:'/company/settings' ,element:Settings },
    { path:'/work-order/calendar' ,element:Calendar },
    { path:'/messages' ,element:Messages },
    { path:'/work-order' ,element:WorkOrder, permission:'view_work_order' },
    { path:'/work-order-details/:id' ,element:WorkOrderView, permission:'view_work_order' },
    { path:'/work-order-progress/:id' ,element:WorkOrderProgress },
    { path:'/manage-schedule' ,element:ManageSchedule },
    { path:'/schedule-work-order' ,element:ScheduleWorkOrder, permission:'add_work_order' },
    { path:'/add-customer' ,element:AddCustomer, permission:'add_customer' },
    { path:'/add-customer/:id' ,element:AddCustomer, permission:'add_customer' },
    { path:'/add-customer/:id/:checklistId' ,element:AddCustomer, permission:'add_customer' },
    { path:'/manage-customer' ,element:ManageCustomer, permission:'view_customer' },
    { path:'/contractor' ,element:Contractor, permission:'view_contractor' },
    { path:'/contractor-details/:id' ,element:ContractorDetails, permission:'view_contractor' },
    { path:'/supervisor' ,element:Supervisor },
    { path:'/add-contractor' ,element:AddContractor },
    { path:'/add-supervisor' ,element:AddSupervisor },
    { path:'/supervisor-details/:id' ,element:SupervisorDetails },
    { path:'/customer-details' ,element:CustomerDetails, permission:'view_customer' },
    { path:'/customer-details/:id' ,element:CustomerDetails, permission:'view_customer' },
    { path:'/customer-details/:id/:checklistId' ,element:CustomerDetails, permission:'view_customer' },
    { path:'/billing-and-invoices' ,element:BillingAndInvoices },
   ]
