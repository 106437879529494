import React, { useEffect, useState } from "react";
import { SidebarMenuLinks } from "app/pages/module/auth/SidebarMenuLinks";
import { toAbsoluteUrl } from "sr/helpers";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useAuth } from "app/pages/module/auth";
import { getCleaner } from "sr/utils/api/cleaner/getCleaner";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import CleanerType from "./type/CleanerType";
import { getEmployment } from "sr/utils/api/cleaner/getEmployment";
import { FindCurrentEmployer } from "sr/helpers/functions/FindCurrentEmployer";
import { getFiles } from "sr/utils/api/getFiles";
import { getFile } from "sr/utils/api/getFile";
export const Sidebar = ({sidebarOpen, SidebarHandle}) => {
    const [cleanerData, setCleanerData] = useState<CleanerType>(null)
    const {logout} = useAuth()
    const [cleanerId, setcleanerId] = useState(null)
    const [currentEmployer, setCurrentEmployer] = useState(null)
    const [profileImg, setProfileImg] = useState('')
    const navigate = useNavigate();
    useEffect(()=> {
      const getdetails = getUserDetails()
      setcleanerId(getdetails?.user_id)
    },[])
    const fetchEmploymentData = async (cleanerId) => {
      
      const employmentData = await getEmployment(cleanerId, undefined);
      
      if (employmentData?.success) {
        setCurrentEmployer(FindCurrentEmployer(employmentData.data))
      }
    };
    const fetchProfileImg = async (cleanerId)=> {
      const params = {
        cleaner_id:cleanerId
      }
  const profileImg = await getFiles(params)
  if(profileImg?.success===true) {
    const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
    if(filterfile && filterfile[0]?.file?.key) {
      const fileimg = await getFile(filterfile[0]?.file?.key);
      if(fileimg) {
      // const rawImageData = new Uint8Array(fileimg as ArrayBuffer);
      // const blob = new Blob([rawImageData], { type: 'image/png' });
      const newImageUrl = URL.createObjectURL(fileimg);
      setProfileImg(newImageUrl)
      }
      
    }}
    }
    useEffect(()=> {
        const fetchCleaner = async (cleanerId) => {
          const queryParams = new URLSearchParams()
    queryParams.append('id',cleanerId )
            const cleanerData = await getCleaner(queryParams);
            
            if (cleanerData?.success) {
              setCleanerData(cleanerData?.data);

            }
          };
          if(cleanerId) {
          fetchCleaner(cleanerId)
          fetchEmploymentData(cleanerId)
          fetchProfileImg(cleanerId)
          }
    },[sidebarOpen])
  return (
    <>
    <div className="z-50 sticky inset-0">
  <div className={`z-20 opacity-100 fixed inset-y-0 right-0 hidden lg:block bg-white shadow-xl duration-300 w-[25%] rounded-l-xl ${sidebarOpen ? 'translate-x-0 delay-100	dark:bg-gray-800 border-gray-100':'translate-x-[100%]'}`}>
  <hr className="border-gray-200 my-2 mt-24" />
            <div className='flex items-center space-x-4 px-4'>
            <div className="radius-50 ">
                <img alt="Profile" className="rounded-full w-24 h-24" src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')}/>
                </div>
            <div className='text-black'>
            <div className="font-bold text-lg">{cleanerData?.first_name} {cleanerData?.last_name}</div>
            <div className="font-700 text-xs">{currentEmployer?.job_title}</div>
            <Link to={'/cleaner-profile-page'} className="font-700 text-xs text-[#2557A7] font-satoshiBold mt-3 cursor-pointer">View & Update Profile</Link>
                </div> 
            </div>
            <hr className="border-gray-200 my-2" />
            <ul className="relative m-0 list-none px-[0.2rem]">
            <li className="relative">
                <Link to='/cleaner-jobs'
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-sm text-black outline-none transition duration-300 ease-linear hover:bg-slate-50  hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                >
                    <span
                        className="mr-4 [&>svg]:h-4 [&>svg]:w-4 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
</svg>

                    </span>
                    <span className='text-sm font-satoshiBold font-bold'>Home</span>
                </Link>
            </li>
         
            <li onClick={() => {
              logout();
              navigate('/auth');
            }} className="relative">
                <div
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-sm text-black outline-none transition duration-300 ease-linear hover:bg-slate-50  hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                >
                    <span
                        className="mr-4 [&>svg]:h-4 [&>svg]:w-4 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                       <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
        className="w-4 h-4"
      >
        <path d="M4 3a2 2 0 00-2 2v9.998a2 2 0 002 2h5.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-6v-11h6a.5.5 0 00.5-.5V3.5a.5.5 0 00-.5-.5H4z"></path>
        <path d="M12.274 13.795a.5.5 0 000 .707l.354.353a.5.5 0 00.707 0l4.52-4.52a.503.503 0 00-.004-.71l-4.477-4.48a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l3.041 3.043H7.517a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h7.803l-3.046 3.046z"></path>
      </svg>

                    </span>
                    <span className='text-sm font-satoshiBold font-bold'>Sign Out</span>
                </div>
            </li>
            </ul>
      
  </div>
  </div>
  <span onClick={SidebarHandle} className={`fixed w-full h-full top-0 left-0 delay-100 z-10 visible opacity-100 bg-[#00000099] ${sidebarOpen ? 'block':'hidden'}`}></span>
  </>
  );
};
