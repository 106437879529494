import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'

// Define a type for the slice state
interface RegisterCleaner {
  username:'',
    first_name: string,
    last_name: string,
    mobile_number: any,
    email: any,
    date_of_birth: any,
    user_id: any,
    status: any,
    createdAt: any,
    updatedAt:any,
    id: any
}

// Define the initial state using that type
const initialState: RegisterCleaner = {
    username:'',
    first_name: '',
    last_name: '',
    mobile_number: '',
    email: '',
    date_of_birth: '',
    user_id: '',
    status: '',
    createdAt: '',
    updatedAt:'',
    id: ''
}

const registerCleanerSlice = createSlice({
    name: 'registerCleaner',
    initialState,
    reducers: {
      // Define an action to update the state with the registered user data
      registerUser: (state, action) => {
        return action.payload;
      },
      // Add other actions if needed
    },
  });
  export const { reducer } = registerCleanerSlice;
  export const { registerUser } = registerCleanerSlice.actions;
    