import { Link } from "react-router-dom";
import { DateFormat } from "sr/helpers/functions/DateFormat";

export const TableRow = ({item, setDetails}) => {
  return (
    <tr onClick={()=> setDetails(item)} className="bg-white cursor-pointer border-b  hover:bg-gray-50">
      <td  className="px-6 py-4">
        
           {item?.type ==='transfer' && 'Debited'} {item?.type ==='mint' && 'Credited'}
        </td>

        <td className="px-6 py-4">
<div className="text-base">
   Completed
</div>
        </td>

        <td className="px-6 py-4">
{ item?.type ==='transfer' && `-$${Number(item?.amount)?.toFixed(2)}`}{ item?.type ==='mint' && `+$${Number(item?.amount)?.toFixed(2)}`}
  </td>
  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
     {DateFormat(item?.createdAt)}
        </th>
    </tr>
     );
};
