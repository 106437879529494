import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import CompanyType from './type/CompanyType'
import { businessTypes } from 'sr/i18n/messages/setting'
import getLabelByValue from 'sr/helpers/functions/getLabelByValue'
import { getFiles } from 'sr/utils/api/getFiles'
import { getFile } from 'sr/utils/api/getFile'
import ContentWithLoader from 'sr/layout/common/ContentLoader'
import { ProfileImgCard } from './ProfileImgCard'
import { toAbsoluteUrl } from 'sr/helpers'
interface ChildProps  {
data:CompanyType
}
export const CompanyCard:React.FC<ChildProps> = ({data}) => {
    const [loader, setLoader] = useState(false)
    const [logo, setLogo] = useState(null)
   useEffect(()=> {
    const ProfileImg = async()=> {
        setLoader(true)
        try {
            const params = {
                company_id:data?.id
              }
          const profileImg = await getFiles(params)
          if(profileImg?.success===true) {
            const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
            if(filterfile && filterfile[0]?.file?.key) {
              const fileimg = await getFile(filterfile[0]?.file?.key);
              if(fileimg) {
              const newImageUrl = URL.createObjectURL(fileimg);
              setLogo(newImageUrl)
              setLoader(false)
              }}}
        }
        catch(e) {
            setLoader(false)
        }
        
    }
    if(data?.id) {
        ProfileImg()
    }
   },[data?.id])
  return (
    <Link to={`/company-details/${data?.id}`}>
    <div  className='col-span-1'>
                                    <div className='bg-white p-6 rounded-[20px] shadow-sm shadow-slate-300 w-full mb-5'>
                                        <div className='flex gap-4 relative'>
                                            <div className='h-16 w-16'>
                                                <ContentWithLoader loading={loader} content={<ProfileImgCard />}>
                                                    {logo ? 
                                                    <img className='h-16 w-16' src={logo && logo}></img>
                                                    :
<img className="rounded" src={toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')} />
                                                }
                                                
                                                </ContentWithLoader>
                                            </div>
                                            <div>
                                                <p className='font-bold font-satoshiBold text-lg'>{data && data.company_name}</p>
                                                <div className="items-center flex divide-x divide-solid divide-slate-400 gap-2 mb-2 ">
                                                    
                                                    {/* <div className="px-2 flex items-center">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star text-yellow-500 text-xs mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path></svg>
                                                    <div className="text-xs text-gray-500 font-satoshiBold font-bold">3.5</div></div> */}
                                                  
                                                    {/* <div className="text-xs	 text-gray-500 font-satoshiBold font-bold pl-[10px]">
                                                   55 <FormattedMessage id="CLEANER.JOBS.REVIEWS" defaultMessage="Reviews" />
                                                        </div> */}
                                                    
                                                    </div>
                                               <div className='flex gap-2 flex-wrap overflow-hidden'>
                                                { data?.business_type?.slice(0,3).map((item, index) => (
                                                    <div key={index}  className='font-semibold text-xs text-gray-900 border border-gray-300 py-1 px-2 rounded-3xl'>
                                                        {getLabelByValue(item, businessTypes)}
                                                    </div>

))}
                                                    </div>
                                            </div>
                                            <div className="h-full absolute flex items-center  right-0 top-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path></svg></div>  
                                        </div>
                                    </div>
                                </div>
                                </Link>
  )
}
