import React, { useEffect, useState } from "react";
import AuthNavComponent from "../module/auth/AuthNav";
import { FormDetails } from "sr/helpers/ui-components/FormDetails";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, index, toAbsoluteUrl } from "sr/helpers";
import { ButtonAuth } from "sr/helpers/ui-components/ButtonAuth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { InputNumberFormat } from "sr/helpers/functions/InputNumberFormat";
import { getSupervisor } from "sr/utils/api/company/workorder/getSupervisor";
import { useGet } from "../company/Hooks/Api/useGet";
import { registerSupervisor } from "sr/utils/api/supervisor/registerSupervisor";
import { login } from "sr/utils/api/login";
import { useAuth } from "../module/auth";
import supervisorRSchema from "sr/utils/schemas/company/supervisor/supervisorRSchema";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { sendOTP } from "sr/utils/api/sendOTP";
export const RegisterasSupervisor = () => {
    const [mobileNo, setMobileNo]= useState()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [email, setEmail] = useState()
    const [loader, setLoader] = useState(false)
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [supervisorId, setSupervisorId] = useState<string>()
    const {persistToken} = useAuth()
const params = useParams()
const navigate = useNavigate()

useEffect(()=> {
                const {id} =params
                setSupervisorId(id)
},[params])
const {loader:loadersupervisor, getData:getSupervisors, data:data} = useGet()


    const intl = useIntl();
    const fetchSupervisor = async (supervisorId)=>{
        const queryParams = new URLSearchParams()
        if (supervisorId) queryParams.append('id', supervisorId)
            await getSupervisors(()=> getSupervisor(queryParams))
        
        }
        useEffect(()=>{
        if(supervisorId) {
            fetchSupervisor(supervisorId)
        }
        }, [supervisorId])

        useEffect(()=> {
                    setFirstName(data?.first_name)
                    setLastName(data?.last_name)
                    setEmail(data?.email)
                    setMobileNo(data?.mobile_number)
                    
        },[data])
    const initialValues = {
        username: username,
        id:supervisorId,
        password: password,
        email: email,
        mobile_number: mobileNo,
        first_name:firstName,
        last_name:lastName,
        status:'active'
      };
      const {errors, submitForm} = useFormik({  
        initialValues:initialValues,  
        enableReinitialize:true,
        validationSchema:supervisorRSchema,
        validateOnBlur: true,  // Validate when an input is blurred (user leaves the field)
    validateOnChange: true,  // Validate when input value is changed

        onSubmit:async (values)=> {
            setLoader(true)
                    try {
                           const res = await registerSupervisor(values)
                           if(res?.success===true){
                            const otpresult = await sendOTP({username:values?.username})
                            setLoader(false)
                            const loginresult = await login({username:values.username, password:values.password})
                            if(loginresult?.success===true) {
                                const data = {
                                    userType:loginresult?.data?.user.type,
                                    user_id:loginresult?.data?.auth.user_id,
                                    jwt:loginresult?.data?.auth.jwt
                                  }
                                  localStorage.setItem('auth', JSON.stringify(data))  
                                  persistToken()
        navigate('/verify-otp')
                            }
                           }  
                           else {
                            setLoader(false)
                           }      
                    }
                    catch(e){
setLoader(false)
                    }
        }})
  return  (<>
  {/* <AuthNavComponent></AuthNavComponent> */}
  <main>
    <ContentWithSpinner loader={loadersupervisor} height="h-screen">
    <div className="lg:container mx-auto lg:max-w-3xl lg:mt-24 mt-8 px-4 lg:px-0">
    <div className="lg:flex block">
      
        {data?.status==='pending' ? 
          <>
        {/* <FormDetails /> */}
        <div className="lg:flex-1 lg:flex block w-full flex-col h-fit lg:mx-12 mx-auto lg:p-6  px-4 py-4 bg-white border border-gray-border rounded-xl">
        <div className="flex justify-center">
                       <Link to="/" className='z-50'>
                                   <img width={150} src={toAbsoluteUrl('/media/logos/logo2.png')} alt=''
                                       className='bg-white rounded-lg' />
                                       </Link>
                                       </div>
          <h1 className=" text-xl mt-10">
            <FormattedMessage
              id="ECOMMERCE.COMMON.CREATE_AN_ACCOUNT.TEXT"
              defaultMessage="Create an Account"
            />
          </h1>
       
          <div className="w-full">
            <form>
            <Input
                onChange={(e)=> setLastName(e.target.value)}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME",
                    defaultMessage: "First Name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.FIRSTNAME.PLACEHOLDER",
                    defaultMessage: "Enter your first name",
                  })}
                  type={"text"}
                  name="first_name"
                  value={firstName}
                  error={errors.first_name}
                ></Input>
                <Input
                onChange={(e)=> setLastName(e.target.value)}
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.LASTNAME",
                    defaultMessage: "Last Name",
                  })}
                  placeholder={"Enter your last name"}
                  type={"text"}
                  name="last_name"
                  error={errors.last_name}
                  value={lastName}
                ></Input>
              <Input
              value={username}
              onChange={(e:any)=> setUsername(e.target.value)}
              error={errors.username}
                label={intl.formatMessage({
                  id: "AUTH.INPUT.USERNAME",
                  defaultMessage: "Username",
                })}
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.USERNAME.PLACEHOLDER",
                  defaultMessage: "Enter your user name",
                })}
                type={"text"}
              ></Input>
              <Input
              onChange={(e:any)=> setPassword(e.target.value)}
              value={password}
              error={errors.password}
                label={intl.formatMessage({
                  id: "AUTH.INPUT.PASSWORD",
                  defaultMessage: "Password",
                })}
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
                  defaultMessage: "Enter your password",
                })}
                type={"password"}
              ></Input>
              <Input
              onChange={(e)=> setMobileNo(e.target.value)}
              value={InputNumberFormat(mobileNo)}
              error={errors.mobile_number}
                label={intl.formatMessage({
                  id: "AUTH.INPUT.MOBILENO",
                  defaultMessage: "Mobile Number",
                })}
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.LASTMOBILENONAME.PLACEHOLDER",
                  defaultMessage: "Enter your Mobile Number",
                })}
                type={"tel"}
              ></Input>
              <Input
              error={errors.email}
               onChange={(e:any)=> setEmail(e.target.value)}
               value={email}
                label={intl.formatMessage({
                  id: "AUTH.INPUT.EMAIL.ADDRESS",
                  defaultMessage: "Email Address",
                })}
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.EMAIL.PLACEHOLDER",
                  defaultMessage: "Enter your email id",
                })}
                type={"email"}
              ></Input>
               
              
            
            </form>
            <div className="flex justify-center">
            <ButtonAuth loader={loader}
                  onClick={() => {
                    submitForm()
                    localStorage.setItem("RegisterAs", "supervisor");
                  }}
                >
                  <FormattedMessage
                    id="AUTH.GENERAL.CREATE.ACCOUNT.BUTTON"
                    defaultMessage="Create Account"
                  />
                </ButtonAuth>
                </div>
            <div className="mt-10 flex justify-center">
              <FormattedMessage
                id="AUTH.ALREADY.HAVE.ACCOUNT"
                defaultMessage="Already have an account?"
              />
              &nbsp;&nbsp;
              <Link to="/auth" replace>
                <a className="cursor-pointer hover:text-pink underline">
                  {" "}
                  <FormattedMessage
                    id="AUTH.SIGN_IN_HERE"
                    defaultMessage="Sign in here"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
        </>
:  
<div className="lg:flex-1 lg:flex block w-full flex-col h-fit lg:mx-12 mx-auto lg:p-6  px-4 py-4 bg-white border border-gray-border rounded-xl">
  <p>You have already registered </p>
  </div>

}
      </div>
    </div>
    </ContentWithSpinner>
  </main>
</>);
};
