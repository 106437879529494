import { get } from "sr/utils/axios"
import { toast } from "react-toastify"
import ApiResponse from "sr/models/ApiResponse"
import { HandleApiError } from "sr/helpers/functions/HandleApiError";

export const getJobs = async (queryParams) => {
    try {
    return await get<ApiResponse<[]>>(`/job/?${queryParams}`).then((res) => {
    if (res.success === true) {
      return res
    }})}
    catch(e:any) {
      HandleApiError(e);
      return null
    }
}