import { ModelWrapperWithButton } from "app/pages/company/component/ModelWrapperWithButton";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Input } from "sr/helpers";
import { getUserDetails } from "sr/helpers/functions/getUserDetails";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { Button } from "sr/helpers/ui-components/company/Button";
import { ReactSelect } from "sr/helpers/ui-components/ReactSelect";
import { addConJobType } from "sr/utils/api/company/contractor/addConJobType";
import EditJobType from "./jobtype/EditJobType";
import { getContractorHire } from "sr/utils/api/company/contractor/getContractorHire";
import { getConJobType } from "sr/utils/api/company/contractor/getConJobType";
import { contractorJobTypeSchema } from "sr/utils/schemas/company/contractor/contractorJobTypeSchema";
import ContentWithSpinner from "sr/layout/common/ContentWithSpiner";
import { LoaderFullScreen } from "sr/layout/common/LoaderFullScreen";
import { deleteConJobType } from "sr/utils/api/company/contractor/deleteConJobType";
import { getJobTypeCategory } from "sr/utils/api/getJobTypeCategory";
import getLabelByValue from "sr/helpers/functions/getLabelByValue";

export const JobType = () => {
    const [jobType, setJobType] = useState(null)
    const [rate, setRate] = useState()
    const [companyId, setCompanyId] = useState<string>('')
    const [contractorId, setContractorId] = useState<string>('')
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState([])
    const params = useParams()
    const [loader, setLoader] = useState('Fetch')
    const [currentData, setCurrentData] = useState<string>('')
    useEffect(()=> {
            const {id} = params
            setContractorId(id)
    },[params])
useEffect(()=> {
const {user_id} = getUserDetails()
setCompanyId(user_id)
},[])

    const initialValues = {
        job_type:jobType, 
        rate:rate,
        contractor_id:contractorId, 
        company_id:companyId
    }
    const {errors, handleSubmit} = useFormik({
        initialValues:initialValues,
        enableReinitialize:true,
        validationSchema:contractorJobTypeSchema,
            onSubmit:async (values)=> {
              setLoader('Submit')
                try {
                    const res = await addConJobType(values)
                    if(res?.success===true) {
                      setLoader('')
                    getJobType()
                    }
                }
                catch(e){
                  setLoader('')
                    HandleApiError(e)
                }
            }
    })

    const intl = useIntl()
    
    const HandleEdit = (data)=> {
      setCurrentData(data)
            setShowModal(true)
    }
    const HandleDelete = async (id)=>{
          try {
              setLoader('Submit')
              const res = await deleteConJobType({id:id})
              if(res?.success===true) {
                    setLoader('')
                    setData(data?.filter((item)=> item?.id !==res?.data?.id))
              }
              else{
                  setLoader('')
              }
          }
          catch(e){
              setLoader('')
          }
    }
    const onCheckModel = (data)=> {
            setShowModal(false)
    }
    const getHireDetails = async ()=> {
        const queryParams = new URLSearchParams()
        queryParams?.append('contractor_id', contractorId)
    try {
    const res = await getContractorHire(queryParams)
    if(res?.success===true) {
        setRate(res?.data?.rate)
    
    }
    }
    catch(e){
        
    }
    }
    useEffect(()=> {
        if(contractorId) {
            getHireDetails()
        }
        },[contractorId])
        const getJobType = async ()=> {
          setLoader('Fetch')
            const queryParams = new URLSearchParams()
            queryParams.append('contractor_id', contractorId)
          try {
              const res = await getConJobType(queryParams)
              if(res?.success===true) {
                  setData(res?.data)
                  setLoader('')
              }
          }
          catch(e) {
              setLoader('')
          }
        }
        useEffect(()=> {
if(contractorId) {
  getJobType()
}
        },[contractorId])

            const [jobTypes, setJobTypes] = useState([])
                    useEffect(()=>{
                      const fetchJobType = async ()=>{
                try {
                const res = await getJobTypeCategory()
                if(res?.success===true){
                  setJobTypes(res?.data)
                }
                }
                catch(e:any){
                
                }
                      }
                      fetchJobType()
                    },[])
                    const options = jobTypes?.map((item) => ({
                      value: item?.value, // Assuming `value` exists
                      label: <FormattedMessage id={item?.label} />,
                    }));
                        
  return (
  <ContentWithSpinner loader={loader==='Fetch'} height="h-screen">
    {loader === 'Submit' && <LoaderFullScreen />}
    <ReactSelect label={intl.formatMessage({
        id:'CLEANER.PROFILE.JOBTYPE.TITLE',
        defaultMessage:'Job Type'
    })} 
    type="text"
    onChange={(selectedOptions) => setJobType(selectedOptions?.value)}

    placeholder={intl.formatMessage({
        id:'CLEANER.PROFILE.JOBTYPE.INPUT.PLACEHOLDER',
        defaultMessage:'Enter Job Type'
    })}
    error={errors.job_type}
    value={jobType}
    options={options}
   />
<Input
onChange={(e:any)=> setRate(e.target.value)}
error={errors.rate}
value={rate}
name="rate"
label={intl.formatMessage({
id: 'CLEANER.PROFILE.JOBTYPE.RATE',
defaultMessage: 'Hourly Rate',
})}
type="number"
placeholder="$"
/>
<Button margin={'py-3'} onClick={handleSubmit} text={<FormattedMessage id="RECRUITER.CUSTOMER.SAVE.BUTTON" defaultMessage="Save" />}></Button>

<table className="w-full text-left condidate-table">
    <thead className="bg-[#FAF9F8] border-collapse border-b border-neutral-200 capitalize">
        <tr>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="RECRUITER.CONTRACTOR.JOBTYPE.TABLE.TITLE" defaultMessage="Job Type" />
                   </th>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="RECRUITER.CONTRACTOR.JOBTYPE.TABLE.RATE" defaultMessage="Rate (Hourly)" />
            </th>
            <th scope="col" className="px-6 py-4 text-base ">
            <FormattedMessage id="RECRUITER.CONTRACTOR.JOBTYPE.TABLE.ACTION" defaultMessage="Action" />
            </th>

            </tr></thead>
            <tbody>
              {data?.map((item)=> (
                <tr className="bg-white border-b  hover:bg-gray-50">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"> <div className='text-base  '>{getLabelByValue(item?.job_type, options)}</div></td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"> <div className='text-base'>{item?.rate}</div></td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">  <div className="flex items-center space-x-4">
                      <div className="border border-neutral-200 rounded-lg inline-flex  divide-x w-auto">
                        <div
                          onClick={() => HandleEdit(item)}
                          className="py-1.5 px-2 group cursor-pointer relative hover:text-[#1F662C] hover:rounded-l-lg hover:border-2 hover:border-[#4DA65A] hover:bg-[#E4F7E6]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-6 mt-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                          <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                            <FormattedMessage
                              id="ECOMMERCE.COMMON.ICON.TOOLTIP.EDIT"
                              defaultMessage="Edit"
                            />
                          </span>
                        </div>
                        <div
                          onClick={() => HandleDelete(item?.id)}
                          className="py-1.5 px-2 group cursor-pointer relative hover:text-[#a9252b] hover:border-2 hover:rounded-r-lg hover:border-[#f76266] hover:bg-[#FEEEEF]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 text-black"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                            <FormattedMessage
                              id="ECOMMERCE.COMMON.ICON.TOOLTIP.DELETE"
                              defaultMessage="Delete"
                            />
                          </span>
                        </div>
                      </div>
                    </div></td>
                </tr>
                ))}
            </tbody>
            </table>

{showModal && 
<EditJobType currentData={currentData} showModal={showModal} onCheckModel={onCheckModel}></EditJobType>

}

</ContentWithSpinner> );
};
