import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { getJobs } from 'sr/utils/api/cleaner/jobs/getJobs'
import ContentWithSpinner from 'sr/layout/common/ContentWithSpiner'
import ContentWithLoader from 'sr/layout/common/ContentLoader'
import { Link, useParams } from 'react-router-dom'
import { DaysConverter } from 'sr/helpers/functions/DaysConverter'
import _ from 'lodash';

export const InterestedJobs = (props) => {
    const [jobs, setJobs] = useState(null)
    const [loader, setLoader] = useState(false)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [jobId, setJobId] = useState('')

    const param = useParams()

    useEffect(()=> {
            const {id} = param
            setJobId(id)
    },[param])

    useEffect(()=> {
            const fetchInterestJobs = async () => {
                setLoader(true)
                const queryParams = new URLSearchParams();  
                const filters = [{"status": {"$in": ["open"]}},{"job_type":{"$in":props?.job?.job_type}}]
                const filter = {"$and":filters};
                queryParams?.append('city', props?.job?.job_advanced_id?.reporting_address?.city?.toString())
                queryParams?.append('page', page.toString())
                queryParams.append('limit', limit.toString())
                if (filter) queryParams.append('filter', JSON.stringify(filter))

                try {
                    const result: any= await getJobs(queryParams);
                    if(result?.success===true) {
                        const data = result?.data?.filter((item)=> item?.id !== jobId)
                        console.log(data);
                        setJobs(data)
                        setLoader(false)
            }
        }
            catch(e) {
                setLoader(false)
            }}
            if(props?.job?.job_advanced_id?.reporting_address?.city && jobId) {
                fetchInterestJobs()
            }
},[props?.job?.job_advanced_id?.reporting_address?.city, jobId])
    const DayNumber = 15;
  return (
    <div className={`lg:flex block flex-col h-fit lg:ml-5 m-0 p-6 bg-white border border-gray-border rounded-xl ${props.width}`}>

    <h1 className='font-bold  text-base'>
    <FormattedMessage 
  id='INTERESTED.JOBS.TITLE'
  defaultMessage='Jobs you might be interested in'
/>

    </h1>
    <ContentWithSpinner loader={loader} height='h-20'>
    {
        jobs?.length>0  ?
        jobs?.map((item) => {
            const postedDays = DaysConverter(item?.createdAt);
            return (
                <Link  to={`/job-detail/${item?.id}`}>
                    <div className='recommended-job-list-item hover:bg-gray-50 py-3'>
                        <h3 className='mt-5 font-bold  text-sm'>{item?.job_title}</h3>
                        <h4 className='font-medium text-sm text-gray-600'>{item?.company_id?.company_name}</h4>

                        <div className='mt-2 flex justify-between items-center'>
                            <div>
                                <FontAwesomeIcon icon={faLocationDot} className='text-sm text-gray-700 font-semibold' />
                                <span className='pl-2 text-sm text-gray-700 font-semibold'>{item?.job_advanced_id?.reporting_address?.address_line_1||item?.job_advanced_id?.reporting_address?.address_line_2||item?.job_advanced_id?.reporting_address?.city}</span>
                            </div>
                            <div className='text-xs text-gray-400'>
                                <FormattedMessage
                                id='ECOMMERCE.COMMON.TEXT.POSTED'
                                defaultMessage='Posted'
                                /> <FormattedMessage
                                id='CLEANER.JOBS.TIME'
                                defaultMessage='{postedDays} Days Ago'
                                values={{postedDays}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='border-t border-gray-200'>
                    </div>
                    </Link>
            )
        })
        :
        <p className=' text-lg text-center mt-3'>No Jobs Found</p>
    }
    </ContentWithSpinner>

</div>
  )
}
InterestedJobs.defaultProps = {
    width: 'lg:w-1/3 w-full',
  }