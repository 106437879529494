import React, {useEffect, useRef, useState} from 'react'
import { ModelWrapperWithButton } from '../../component/ModelWrapperWithButton'
import EmailChange from 'app/pages/company/settings/Components/models/EmailChange'
import PasswordChange from './models/PasswordChange'
import ChangePhone from './models/ChangePhone'
import { toAbsoluteUrl } from 'sr/helpers'
import { FormattedMessage } from 'react-intl'
import { getUserDetails } from 'sr/helpers/functions/getUserDetails'
import { useGet } from 'app/pages/company/Hooks/Api/useGet'
import { getIndividual } from 'sr/utils/api/individual/getIndividual'
import { useAuth } from 'app/pages/module/auth'
import { updateIndividual } from 'sr/utils/api/individual/updateIndividual'
import NameChange from './models/NameChange'
import PhoneNumberFormat from 'sr/helpers/functions/PhoneNumberFormat'
import { uploadFile } from 'sr/utils/api/uploadFile'
import { SuccessMessage } from 'sr/helpers/functions/SuccessMessage'
import { getFiles } from 'sr/utils/api/getFiles'
import { getFile } from 'sr/utils/api/getFile'
import { LoaderFullScreen } from 'sr/layout/common/LoaderFullScreen'
import ContentWithLoader from 'sr/layout/common/ContentLoader'
import ContentLoader from 'react-content-loader'
export const Account = () => {
    const [showModal, setShowModal] = useState(false)
    const [activeComponent, setActiveComponent] = useState<any>({});
    const [profileImg, setprofileImg] = useState(null)
    const [userId, setUserid] = useState()
    const [loaders, setLoaders] = useState(true)
    const {logout} = useAuth()
    const [formData, setFormData] = useState<any>({});
    const HandleModel = (data) => {
        setShowModal(data)
}
const {loader:loader, getData:fetchData, data:data} = useGet()
useEffect(()=> {
const {user_id} = getUserDetails()
setUserid(user_id)
},[])
const childRef = useRef(null);
const fetchDetails  = async (id)=> {
    const params = new URLSearchParams()
      if (userId) params.append('id', id)
        await fetchData(()=> getIndividual(params))
    }
const HandleChange = async () => {
    let isValidData: boolean;
    if (childRef.current && childRef.current.validateData) {
      isValidData = await childRef.current.validateData();
    }
    if (childRef.current && childRef.current.submitForm){
      childRef.current.submitForm()
    }    

    if(isValidData) {   
            const result = await updateIndividual({id:userId, ...formData})
            if(result?.success=== true) {
                fetchDetails(result?.data?.id)
                setShowModal(false)
            }
  
    }  
  }
useEffect(()=> {
if(userId) {
  fetchProfileImg(userId)
    fetchDetails(userId)
}
},[userId])
const HandleData = (data:any) => { 
      setFormData(data)
  }
const AllModels = [
    {
        label: 'NameChange',
        componentName: NameChange,
        headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.NAME.BUTTON.TITLE" defaultMessage="Change Name" />
      },
    {
      label: 'EmailChange',
      componentName: EmailChange,
      headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_EMAIL.BUTTON" defaultMessage="Change email" />
    },
    {
        label: 'PasswordChange',
        componentName: PasswordChange,
        headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PASSWORD.BUTTON" defaultMessage="Change password" />
      },
    {
        label:'ChangePhone',
        componentName:ChangePhone,
        headertitle:<FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PHONENO.TITLE" defaultMessage="Change Phone Number" />
    }
    ]

const openModel = (index: any) => {  
    const activeComp = AllModels.filter(function (e, i) {
      return i == index
    });      
    console.log(activeComp[0])
    setActiveComponent(activeComp[0]);

    setShowModal(true)
  };
  const HandleImage = async (e:any)=> {
    setLoaders(true)
    const file: any = e.target.files[0]
    const formData = new FormData()
    formData.append('individual_id', userId);
    formData.append('profile_img', file);
    const result = await uploadFile(formData);
    if(result?.success===true) {
  setLoaders(false)
      if(profileImg) {
      SuccessMessage("API.SUCCESS.PROFILE.IMG.UPDATE")
      } else {
        SuccessMessage("API.SUCCESS.PROFILE.IMG.UPLOAD")
      }
      fetchProfileImg(userId)
    }
    else {
      setLoaders(false)
    }
}
const fetchProfileImg = async (individual_id)=> {
  setLoaders(true)
  const params = {
    individual_id:individual_id
  }
const profileImg = await getFiles(params)
if(profileImg?.success===true) {
const filterfile = profileImg?.data?.filter((item)=> item?.file?.fieldname==='profile_img')
if(filterfile && filterfile[0]?.file?.key) {
  const fileimg = await getFile(filterfile[0]?.file?.key);
  if(fileimg) {
  const newImageUrl = URL.createObjectURL(fileimg);
  setprofileImg(newImageUrl)
  setLoaders(false)
  }
  else {
    setLoaders(false)
  }
  
}
else {
  setLoaders(false)
}
}
}
  return (
    <>
   {loaders && <LoaderFullScreen />}
    <div className='flex justify-center border-b border-neutral-200 pb-3 mb-3'>
     
    <div className="radius-50 container mx-auto my-auto flex justify-center w-[200px]">
        <label htmlFor='img-input'>
        <img alt="Profile" className="rounded-full" src={profileImg ? profileImg : toAbsoluteUrl('/media/icons/displayProfilePlaceholder.png')} />
        <input onChange={(e)=> HandleImage(e)} type='file' id='img-input' name='img' accept='image/*' className='hidden'></input>
        </label>
        </div>
    </div>
    <ContentWithLoader loading={loader} content={<ContentLoader 
    speed={2}
    width={900}
    height={500}
    viewBox="0 0 900 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="-590" y="17" rx="3" ry="3" width="88" height="6" /> 
    <rect x="-161" y="4" rx="3" ry="3" width="52" height="9" /> 
    <rect x="-607" y="61" rx="3" ry="3" width="410" height="9" /> 
    <rect x="-821" y="118" rx="3" ry="3" width="380" height="9" /> 
    <rect x="-647" y="90" rx="3" ry="3" width="178" height="6" /> 
    <circle cx="-219" cy="13" r="20" /> 
    <rect x="-564" y="-18" rx="3" ry="3" width="88" height="6" /> 
    <rect x="-266" y="202" rx="3" ry="3" width="131" height="22" /> 
    <rect x="-580" y="-34" rx="0" ry="0" width="4" height="40" /> 
    <rect x="-524" y="22" rx="0" ry="0" width="157" height="3" /> 
    <rect x="-460" y="73" rx="0" ry="0" width="15" height="46" /> 
    <rect x="-541" y="79" rx="0" ry="0" width="161" height="44" /> 
    <rect x="-530" y="40" rx="0" ry="0" width="165" height="55" /> 
    <rect x="-488" y="92" rx="0" ry="0" width="200" height="63" /> 
    <rect x="554" y="46" rx="0" ry="0" width="40" height="12" /> 
    <rect x="260" y="70" rx="0" ry="0" width="2" height="1" /> 
    <rect x="-715" y="125" rx="0" ry="0" width="557" height="60" /> 
    <rect x="-531" y="97" rx="0" ry="0" width="476" height="30" /> 
    <rect x="-594" y="182" rx="0" ry="0" width="373" height="128" /> 
    <rect x="311" y="153" rx="0" ry="0" width="0" height="21" /> 
    <rect x="-563" y="123" rx="0" ry="0" width="84" height="2" /> 
    <rect x="-637" y="124" rx="0" ry="0" width="172" height="4" /> 
    <rect x="-566" y="133" rx="0" ry="0" width="141" height="12" /> 
    <rect x="-571" y="120" rx="0" ry="0" width="60" height="5" /> 
    <rect x="24" y="147" rx="0" ry="0" width="2" height="12" /> 
    <rect x="-598" y="191" rx="0" ry="0" width="132" height="11" /> 
    <rect x="-558" y="139" rx="0" ry="0" width="186" height="7" /> 
    <rect x="-647" y="187" rx="0" ry="0" width="498" height="30" /> 
    <rect x="-415" y="158" rx="0" ry="0" width="72" height="8" /> 
    <rect x="-635" y="159" rx="0" ry="0" width="481" height="32" /> 
    <rect x="124" y="187" rx="0" ry="0" width="2" height="26" /> 
    <rect x="-238" y="170" rx="0" ry="0" width="67" height="23" /> 
    <rect x="23" y="11" rx="0" ry="0" width="900" height="61" /> 
    <rect x="25" y="88" rx="0" ry="0" width="900" height="61" /> 
    <rect x="27" y="166" rx="0" ry="0" width="900" height="61" />
  </ContentLoader>}>
     <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className=''><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.NAME.TITLE" defaultMessage="Name" /></h2>
            <div>{data?.first_name} {data?.last_name}</div>
        </div>
        <div onClick={()=> openModel(0)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.NAME.BUTTON.TITLE" defaultMessage="Change Name" /></div>
    </div>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className=''><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.EMAIL.TITLE" defaultMessage="Email" /></h2>
            <div>{data?.email}</div>
        </div>
        <div onClick={()=> openModel(1)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_EMAIL.BUTTON" defaultMessage="Change email" /></div>
    </div>
    
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className=''><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.PASSWORD.TITLE" defaultMessage="Password" /></h2>
            <div>••••••••</div>
        </div>
        <div onClick={() => openModel(2)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PASSWORD.BUTTON" defaultMessage="Change password" /></div>
    </div>
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className=''><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.PHONENO.TITLE" defaultMessage="Phone number" /></h2>
            <div>{PhoneNumberFormat(data?.mobile_number)}</div>
        </div>
        <div onClick={()=> openModel(3)} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.CHANGE_PHONENO.TITLE" defaultMessage="Change Phone Number" /></div>
    </div>
    {/* <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className=''><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.AUTHENTICATION.TITLE" defaultMessage="Third party authentication" /></h2>
            <div><FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.AUTHENTICATION.TEXT" defaultMessage="No third-party applications have access to your account" />
    </div>
        </div>
    </div> */}
    <div className='flex justify-between items-center mb-3 pb-3 border-b border-neutral-200'>
        <div>
            <h2 className=''>{data?.email}</h2>
        </div>
        <div onClick={()=> logout()} className="text-[#0078ae] hover:text-[#0078ae] hover:border-2 hover:border-[#C7D2F6] hover:bg-[#EEF1FE] py-2 rounded-lg  text-base px-3 cursor-pointer border border-neutral-200">
        <FormattedMessage id="INDIVIDUAL.ACCOUNT.SETTINGS.SIGN_OUT.BUTTON" defaultMessage="Sign out" /></div>
    </div>
    </ContentWithLoader>
    <ModelWrapperWithButton Save={HandleChange} showModal = {showModal} onCheckModel = {HandleModel} headertitle = {activeComponent.headertitle}>
    {<activeComponent.componentName HandleData={HandleData} ref={childRef} HandleModel = {HandleModel}/>}
    </ModelWrapperWithButton>
    </>
  )
}
