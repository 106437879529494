import { FormattedMessage } from "react-intl";
const SidebarPreItems = [
  {
    link: "/job-post/add-basic-details",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="w-6 h-6"
      >
        <path d="M13 4.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V11H4.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H11v6.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V13h6.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H13V4.5z"></path>
      </svg>
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.SIDEBAR.MENU.POST.A.JOB"
        defaultMessage="Post a Job"
      />
    ),
  },
  {
    link: "/jobs-listing",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
        className="w-6 h-6"
      >
        <path
          fill-rule="evenodd"
          d="M10 3C7 3 6 6 6 6H2.5a.5.5 0 00-.5.5V9h16V6.5a.5.5 0 00-.5-.5H14s-1-3-4-3zm2.5 3h-5s1-1.5 2.5-1.5S12.5 6 12.5 6z"
          clip-rule="evenodd"
        ></path>
        <path d="M8 11H2v5.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V11h-6c0 1-1 2-2 2s-2-1-2-2z"></path>
      </svg>
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.SIDEBAR.MENU.JOBS"
        defaultMessage="Jobs"
      />
    ),
  },
  {
    link: "/work-order",
    icon: (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 112.83"
      className="ml-1 w-5 h-5 fill-current text-white"
    >
      <path
        fillRule="evenodd"
        d="M103.3,34.19l8.23,3.52a2.15,2.15,0,0,1,1.13,2.82l-2,4.56L98.53,39.88l2-4.56a2.15,2.15,0,0,1,2.82-1.13ZM8.88,7.88h8.19V2.73a2.74,2.74,0,0,1,5.47,0V7.88h12V2.73a2.73,2.73,0,1,1,5.46,0V7.88H52V2.73a2.73,2.73,0,0,1,5.46,0V7.88h12V2.73a2.73,2.73,0,0,1,5.46,0V7.88h9.27a8.91,8.91,0,0,1,8.88,8.88V28.54a12.27,12.27,0,0,0-1.76,2.9l-2,4.56A10,10,0,0,0,89,37.16a11.24,11.24,0,0,0-.58,1.15l-.6,1.4V16.76a3.6,3.6,0,0,0-3.58-3.58H75v5.15a2.73,2.73,0,0,1-5.46,0V13.18h-12v5.15a2.73,2.73,0,0,1-5.46,0V13.18H40v5.15a2.73,2.73,0,1,1-5.46,0V13.18h-12v5.15a2.74,2.74,0,0,1-5.47,0V13.18H8.88A3.58,3.58,0,0,0,5.3,16.76v92a3.6,3.6,0,0,0,3.58,3.59H59.16l.56,5.29H8.88A8.89,8.89,0,0,1,0,108.77v-92A8.91,8.91,0,0,1,8.88,7.88ZM20.34,94.35a2.65,2.65,0,0,1,0-5.3H66.72l-2.27,5.3Zm0-17.48a2.65,2.65,0,0,1,0-5.3H72.78a2.52,2.52,0,0,1,1.27.35l-2.12,5Zm0-17.48a2.65,2.65,0,0,1,0-5.3H72.78a2.65,2.65,0,0,1,0,5.3Zm0-17.48a2.65,2.65,0,0,1,0-5.3H72.78a2.65,2.65,0,0,1,0,5.3ZM81,114.6l-6.19,5c-4.85,3.92-4.36,5.06-5-.88l-1-9.34h0L97.54,42.18l12.18,5.22L81,114.6Zm-10.09-4.31,8,3.42L74.82,117c-3.19,2.58-2.87,3.32-3.28-.57l-.66-6.14Z"
      />
    </svg>
    
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.SIDEBAR.MENU.WORKORDER"
        defaultMessage="Work Order"
      />
    ),
  }
  // {
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       focusable="false"
  //       role="img"
  //       fill="currentColor"
  //       viewBox="0 0 20 20"
  //       aria-hidden="true"
  //       className="w-6 h-6"
  //     >
  //       <path d="M4.058 8.36A14.008 14.008 0 013 3.5a.487.487 0 01.492-.5h4.003c.276 0 .499.224.514.5a9.028 9.028 0 00.679 2.963.519.519 0 01-.107.567l-2.34 2.341a12.504 12.504 0 004.391 4.39l2.341-2.34a.519.519 0 01.567-.107c.94.391 1.943.62 2.96.678.276.015.5.238.5.514v4.002a.487.487 0 01-.5.492A14.015 14.015 0 014.058 8.36z"></path>
  //     </svg>
  //   ),
  //   text: (
  //     <FormattedMessage
  //       id="RECRUITER.SIDEBAR.MENU.PHONECALLS"
  //       defaultMessage="Phone Calls"
  //     />
  //   ),
  // },

  // {
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       focusable="false"
  //       role="img"
  //       fill="currentColor"
  //       viewBox="0 0 20 20"
  //       aria-hidden="true"
  //       className="w-6 h-6"
  //     >
  //       <path
  //         fill-rule="evenodd"
  //         d="M9 9c1.934 0 3.5-1.566 3.5-3.5S10.934 2 9 2a3.499 3.499 0 00-3.5 3.5C5.5 7.434 7.066 9 9 9zm0 1.75c-2.336 0-7 1.172-7 3.5v1.25a.5.5 0 00.5.5h6.92a4.685 4.685 0 01-.9-2.77c0-.909.257-1.757.703-2.477A7.418 7.418 0 009 10.75zm6.952 4.136c.292-.483.46-1.05.46-1.656a3.2 3.2 0 00-3.196-3.202 3.2 3.2 0 00-3.196 3.202 3.2 3.2 0 004.878 2.723l1.897 1.9a.5.5 0 00.706 0l.354-.353a.501.501 0 000-.708l-1.903-1.906zm-1.046-1.656a1.69 1.69 0 11-3.38 0 1.69 1.69 0 013.38 0z"
  //         clip-rule="evenodd"
  //       ></path>
  //     </svg>
  //   ),
  //   text: (
  //     <FormattedMessage
  //       id="RECRUITER.SIDEBAR.MENU.SEARCHCVS"
  //       defaultMessage="Search CVs"
  //     />
  //   ),
  // },
];
const SidebarPostItems = [
  {
    link: "/candidates",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
    
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.SIDEBAR.MENU.CANDIDATES"
        defaultMessage="Candidates"
      />
    ),
  },
  {
    link: "/contractor",
    icon: (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      role="img"
      fill="currentColor"
      viewBox="0 0 20 20"
      aria-hidden="true"
      className="w-6 h-6"
    >
      <path
        fill-rule="evenodd"
        d="M8.865 5.438a4.321 4.321 0 00-.525 2.768 2.434 2.434 0 11.525-2.768zM9.944 8.49a2.822 2.822 0 011.78-3.583l.016-.005A2.808 2.808 0 119.944 8.49zm2.415 3.305a6 6 0 01.35.005h.269c2.396.114 5.237 1.277 5.237 2.705v.996a.5.5 0 01-.5.499H7.483a.5.5 0 01-.5-.5v-.992c0-.146.03-.29.086-.43.142-.345.46-.718 1.032-1.084.77-.491 1.902-.897 3.331-1.12.313-.043.624-.07.927-.08zm-2.77-1.126a8.429 8.429 0 00-2.938-.563c-2.135 0-4.867 1.048-4.867 2.355v.793a.5.5 0 00.5.5h3.307c.252-.823.893-1.507 1.703-2.024.646-.413 1.442-.752 2.353-1.006a4.565 4.565 0 01-.058-.055z"
        clip-rule="evenodd"
      ></path>
    </svg>
    
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.SIDEBAR.MENU.CONTRACTOR"
        defaultMessage="Contractor"
      />
    ),
  },
  {
    link: "/supervisor",
    icon: (
<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className="w-6 h-6 fill-current text-white"
    >
      <path d="M32 4C15.4 4 4 15.4 4 32s11.4 28 28 28 28-11.4 28-28S48.6 4 32 4zm0 50c-12.15 0-22-9.85-22-22S19.85 10 32 10s22 9.85 22 22-9.85 22-22 22z" />
      <path d="M32 16c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 16c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zM32 44c-7.3 0-14 3.7-14 6v4h28v-4c0-2.3-6.7-6-14-6z" />
    </svg>
    
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.SIDEBAR.MENU.SUPERVISOR"
        defaultMessage="supervisor"
      />
    ),
  },
  {
    link: "/manage-customer",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
    </svg>
    
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.SIDEBAR.MENU.MANAGECUSTOMER"
        defaultMessage="Manage Customer"
      />
    ),
  },
];

const ModelItems = [
  {
    icon: (
      <svg
        aria-hidden="true"
        height="14"
        role="presentation"
        viewBox="0 0 16 14"
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 0.5H1.5C0.583333 0.5 0 1.08333 0 2V12C0 12.9167 0.583333 13.5 1.5 13.5H14.5C15.4167 13.5 16 12.9166 16 11.9999V2C16 1.08333 15.4167 0.5 14.5 0.5ZM2 4.5H10V7.5H2V4.5ZM2 9.5H10V11.5H2C2 11.5 2 11.4583 2 11V9.5ZM14 11.5H12V4.5L14 4.49992V11C14 11.4583 14 11.5 14 11.5Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    text: (
      <FormattedMessage
        id="RECRUITER.TOPBAR.MENU.COMPANYPAGE"
        defaultMessage="Company Page"
      />
    ),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="w-5 h-5"
      >
        <path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path>
      </svg>
    ),
    text:  <FormattedMessage
    id="RECRUITER.TOPBAR.MENU.USERS"
    defaultMessage="Users"
  />,
  },
  {
    icon: (
      <svg
        className="w-5 h-5"
        aria-hidden="true"
        height="24"
        role="presentation"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 8.5V20.5C23 20.8 22.8 21 22.5 21H4.5C4.2 21 4 20.8 4 20.5V19H20.5C20.8 19 21 18.8 21 18.5V8H22.5C22.8 8 23 8.3 23 8.5Z"
          fill="currentColor"
        ></path>
        <path
          d="M15 4H1.4C1.2 4 1 4.1 1 4.4V16.7C1 16.9 1.2 17.1 1.4 17.1H18.6C18.8 17.1 19 16.9 19 16.7V4.4C19 4.2 18.8 4 18.6 4H15ZM2.7 8.8C4.3 8.5 5.6 7.2 5.9 5.6H14.2C14.5 7.2 15.8 8.5 17.4 8.8V12.2C15.8 12.5 14.5 13.8 14.2 15.4H5.8C5.5 13.8 4.2 12.5 2.6 12.2L2.7 8.8ZM17.4 8C16.2 7.7 15.3 6.8 15.1 5.6H17C17.2 5.6 17.4 5.8 17.4 6V8ZM5 5.6C4.7 6.7 3.8 7.6 2.7 7.9V6C2.7 5.8 2.9 5.6 3.1 5.6H5ZM2.7 15V13.1C3.8 13.4 4.7 14.3 5 15.4H3.1C2.8 15.4 2.7 15.2 2.7 15ZM15 15.4C15.3 14.3 16.2 13.4 17.3 13.1V15C17.3 15.2 17.1 15.4 16.9 15.4H15Z"
          fill="currentColor"
        ></path>
        <path
          d="M10 7.19995C8.20001 7.19995 6.70001 8.69995 6.70001 10.5C6.70001 12.3 8.20001 13.8 10 13.8C11.8 13.8 13.3 12.3 13.3 10.5C13.3 8.69995 11.8 7.19995 10 7.19995ZM10 12.9C8.70001 12.9 7.60001 11.8 7.60001 10.5C7.60001 9.19995 8.70001 8.09995 10 8.09995C11.3 8.09995 12.4 9.19995 12.4 10.5C12.4 11.8 11.3 12.9 10 12.9Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    text: <FormattedMessage
    id="RECRUITER.TOPBAR.MENU.BILLING.AND.INVOICES"
    defaultMessage="Billing and Invoices"
  />,
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
        className="w-6 h-6"
      >
        <path
          fill-rule="evenodd"
          d="M9 9c1.934 0 3.5-1.566 3.5-3.5S10.934 2 9 2a3.499 3.499 0 00-3.5 3.5C5.5 7.434 7.066 9 9 9zm0 1.75c-2.336 0-7 1.172-7 3.5v1.25a.5.5 0 00.5.5h6.92a4.685 4.685 0 01-.9-2.77c0-.909.257-1.757.703-2.477A7.418 7.418 0 009 10.75zm6.952 4.136c.292-.483.46-1.05.46-1.656a3.2 3.2 0 00-3.196-3.202 3.2 3.2 0 00-3.196 3.202 3.2 3.2 0 004.878 2.723l1.897 1.9a.5.5 0 00.706 0l.354-.353a.501.501 0 000-.708l-1.903-1.906zm-1.046-1.656a1.69 1.69 0 11-3.38 0 1.69 1.69 0 013.38 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
    ),
    text: "Subscription",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
        className="w-6 h-6"
      >
        <path
          fill-rule="evenodd"
          d="M9 9c1.934 0 3.5-1.566 3.5-3.5S10.934 2 9 2a3.499 3.499 0 00-3.5 3.5C5.5 7.434 7.066 9 9 9zm0 1.75c-2.336 0-7 1.172-7 3.5v1.25a.5.5 0 00.5.5h6.92a4.685 4.685 0 01-.9-2.77c0-.909.257-1.757.703-2.477A7.418 7.418 0 009 10.75zm6.952 4.136c.292-.483.46-1.05.46-1.656a3.2 3.2 0 00-3.196-3.202 3.2 3.2 0 00-3.196 3.202 3.2 3.2 0 004.878 2.723l1.897 1.9a.5.5 0 00.706 0l.354-.353a.501.501 0 000-.708l-1.903-1.906zm-1.046-1.656a1.69 1.69 0 11-3.38 0 1.69 1.69 0 013.38 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
    ),
    text: "Contact Us",
  },
];

const ModelBottomItem = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
        className="w-5 h-5"
      >
        <path
          fillRule="evenodd"
          d="M8.269 2.448A.5.5 0 018.766 2h2.468a.5.5 0 01.497.448l.194 1.836a6.395 6.395 0 012.322 1.275l1.785-.765a.528.528 0 01.654.208L17.9 6.998a.473.473 0 01-.137.642l-1.59 1.086a5.735 5.735 0 010 2.548l1.59 1.086a.473.473 0 01.137.642l-1.213 1.996a.528.528 0 01-.654.208l-1.785-.765a6.396 6.396 0 01-2.322 1.275l-.194 1.836a.5.5 0 01-.497.448H8.766a.5.5 0 01-.497-.448l-.194-1.836a6.397 6.397 0 01-2.322-1.275l-1.785.765a.528.528 0 01-.655-.208L2.1 13.002a.473.473 0 01.138-.642l1.589-1.085a5.734 5.734 0 010-2.55L2.238 7.64a.473.473 0 01-.137-.642l1.213-1.996a.528.528 0 01.654-.208l1.785.765a6.395 6.395 0 012.322-1.275l.194-1.836zm1.73 11.043c2.03 0 3.675-1.563 3.675-3.49 0-1.929-1.645-3.492-3.674-3.492-2.03 0-3.675 1.563-3.675 3.491 0 1.928 1.645 3.49 3.675 3.49z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    text: <FormattedMessage
    id="RECRUITER.TOPBAR.MENU.ACCOUNT.SETTING"
    defaultMessage="Account Settings"
  />,
    link: "/company/settings",
  },
];

const CompanySettingData = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="w-5 h-5"
      >
        <path d="M12 12c2.486 0 4.5-2.014 4.5-4.5S14.486 3 12 3a4.499 4.499 0 00-4.5 4.5C7.5 9.986 9.514 12 12 12zm0 2.25c-3.004 0-9 1.508-9 4.5v1.75a.5.5 0 00.5.5h17a.5.5 0 00.5-.5v-1.75c0-2.992-5.996-4.5-9-4.5z"></path>
      </svg>
    ),
    smalltext: <FormattedMessage
    id="RECRUITER.ACCOUNT.SETTINGS.MENU.ACCOUNT.TEXT"
    defaultMessage="Contact information and password"
  />,
    text: <FormattedMessage
    id="RECRUITER.ACCOUNT.SETTINGS.MENU.ACCOUNT.TITLE"
    defaultMessage="Account Settings"
  />,
  },
  {
    icon: (
      <svg
        className="w-5 h-5"
        aria-hidden="true"
        height="14"
        role="presentation"
        viewBox="0 0 16 14"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 0.5H1.5C0.583333 0.5 0 1.08333 0 2V12C0 12.9167 0.583333 13.5 1.5 13.5H14.5C15.4167 13.5 16 12.9166 16 11.9999V2C16 1.08333 15.4167 0.5 14.5 0.5ZM2 4.5H10V7.5H2V4.5ZM2 9.5H10V11.5H2C2 11.5 2 11.4583 2 11V9.5ZM14 11.5H12V4.5L14 4.49992V11C14 11.4583 14 11.5 14 11.5Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    smalltext: <FormattedMessage
    id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.TEXT"
    defaultMessage="Company name and details"
  />,
    text: <FormattedMessage
    id="RECRUITER.ACCOUNT.SETTINGS.MENU.COMPANY.TITLE"
    defaultMessage="Company Settings"
  />,
  },
  { icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
</svg>

      , 
      smalltext:<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.PAYMENT.TEXT" defaultMessage="Payment Setting Detail" />,
      text: <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.PAYMENT.TITLE" defaultMessage="Payment Setting" />
  },
  { icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>  
  
        , 
        smalltext:<FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.BALANCE.TEXT" defaultMessage="Account Balance Detail" />,
        text: <FormattedMessage id="INDIVIDUAL.SETTINGS.MENU.BALANCE.TITLE" defaultMessage="Account Balance" />
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
</svg>

      ),
      smalltext: <FormattedMessage
      id="RECRUITER.ACCOUNT.SETTINGS.MENU.TRANSACTION.TEXT"
      defaultMessage="Transaction History Details"
    />,
      text: <FormattedMessage
      id="RECRUITER.ACCOUNT.SETTINGS.MENU.TRANSACTION.TITLE"
      defaultMessage="Transaction History"
    />,
    },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
</svg>

    ),
    smalltext: <FormattedMessage
    id="RECRUITER.ACCOUNT.SETTINGS.MENU.ADDITIONAL.TEXT"
    defaultMessage="Manage your additional information"
  />,
    text: <FormattedMessage
    id="RECRUITER.ACCOUNT.SETTINGS.MENU.ADDITIONAL.TITLE"
    defaultMessage="Additional Info"
  />,
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
    </svg>    

    ),
    smalltext: <FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.BRANCH.TEXT" defaultMessage="Manage your Branch" />,
    text: <FormattedMessage id="RECRUITER.ACCOUNT.SETTINGS.MENU.BRANCH.TITLE" defaultMessage="Branch" />,
  },
  // {
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       strokeWidth={1.5}
  //       stroke="currentColor"
  //       className="w-5 h-5"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
  //       />
  //     </svg>
  //   ),
  //   smalltext: <FormattedMessage
  //   id="RECRUITER.ACCOUNT.SETTINGS.MENU.DEVICE.TEXT"
  //   defaultMessage="Manage your active devices and settings"
  // />,
  //   text: <FormattedMessage
  //   id="RECRUITER.ACCOUNT.SETTINGS.MENU.DEVICE.TITLE"
  //   defaultMessage="Device Management"
  // />,
  // },
];

export {
  SidebarPreItems,
  ModelItems,
  ModelBottomItem,
  CompanySettingData,
  SidebarPostItems,
};
