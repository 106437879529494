import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { KTSVG } from 'sr/helpers';

export function ScrollTop() {
  const { pathname } = useLocation();
  const isFirstRun = useRef(true);

  const pluginsReinitialization = () => {
    setTimeout(() => {
      // StickyComponent.reInitialization()
      setTimeout(() => {
        // ToggleComponent.reinitialization()
        // DrawerComponent.reinitialization()
      }, 70);
    }, 140);
  };

  const scrollTop = () => {
    window.scrollTo(0, 0); // ✅ FIX: Scroll to the top
  };

  const updateHeaderSticky = () => {
    const stickyHeader = document.body.querySelectorAll(`[data-kt-sticky-name="header"]`);
    if (stickyHeader && stickyHeader.length > 0) {
      // const sticky = StickyComponent.getInstance(stickyHeader[0] as HTMLElement)
      // if (sticky) {
      //   sticky.update()
      // }
    }
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      pluginsReinitialization();
    }

    updateHeaderSticky();

    setTimeout(() => {
      scrollTop(); // ✅ FIX: Call scrollTop here
    }, 0);
  }, [pathname]);

  return (
    <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true'>
      <KTSVG path='/media/icons/duotune/arrows/arr066.svg' />
    </div>
  );
}
