import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
export const Pageination = ({page, totalpage, ...props}) => {
    const pageNumber = []
    for(let i = 1; i<= totalpage; i++) {
        pageNumber.push(i)
    }

  return (
    <>
<nav className='w-full flex flex-wrap justify-center items-center lg:space-x-8'>
    <p className='font-bold text-sm text-[#717b9e]'>
      <FormattedMessage 
      id='PAGEINATION.TITLE'
      defaultMessage='Page {activePage} of {allPage}'
      values={{activePage:page, allPage:totalpage}}
      />
    </p>
  <ul className="items-center inline-flex lg:space-x-2 text-base h-10 justify-between w-full lg:w-fit">
    <li onClick={() => props.onPageChange(page-1)}>
      <Link to="#" className="flex items-center justify-center px-4 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-[20px] hover:bg-gray-100 hover:text-gray-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
<FormattedMessage 
id='PAGEINATION.PREVIOUS.BUTTON'
defaultMessage='Previous'
/>
</Link>
    </li>
  {  pageNumber.map((data, index)=> {
    if (data === page ) {
   return ( <li key={index} onClick={() => props.onPageChange(data)}>
      <Link  to='#' className=" lg:flex hidden items-center justify-center h-8 w-8 leading-tight text-[#121224] border rounded-full border-[#121224] hover:bg-gray-100 hover:text-gray-700">{data}</Link>
    </li> ) }
    else {return(
      index >9 ? null :
         <li key={index} onClick={() => props.onPageChange(data)}>
            <Link to='#' className="lg:flex hidden items-center justify-center h-8 w-8 leading-tight text-[#121224] hover:bg-gray-100 hover:text-gray-700">{data}</Link>
          </li> 
         
  )}
   }) }
    <li onClick={() => props.onPageChange(page+1)}>
      <Link to="#" className="flex items-center justify-center px-4 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-[20px] hover:bg-gray-100 hover:text-gray-700">
      <FormattedMessage 
id='PAGEINATION.NEXT.BUTTON'
defaultMessage='Next'
/>
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>
</Link>
    </li>
  </ul>
</nav>
</>
  )
}
