import React, {useEffect, useState} from 'react'
import { jobTypes } from './Jsonalldata'
import { CheckBox } from 'sr/helpers/ui-components/company/CheckBox'
import CheckBoxHandle from 'sr/helpers/functions/CheckBoxHandle'
import { FormattedMessage } from 'react-intl'
export const JobTypeChange = (props) => {
    const [jobtype, setJobType] = useState<any[]>([]);
    useEffect(()=> {
        setJobType(props?.data?.job_type)
    },[props?.data])

    useEffect(()=> {
     const data = {
      job_type:jobtype
     }
     props.HandleDataProps(data)
    },[jobtype])
  return (
    <>
    <p className='pt-8 text-base font-satoshiBold'><FormattedMessage id="RECRUITER.POSTJOB.ADDJOBDETAILS.INPUT.JOBTYPE.TITLE" defaultMessage="Job type" /></p>
<div className="flex flex-wrap space-x-4">
{jobTypes.map((data, index)=> (
<CheckBox
value={data.value}
onChange={(e) => CheckBoxHandle(e, jobtype, setJobType)}
checked = {jobtype.includes(data.value)} 
label={data.label}
/>
))}
   </div>
   </>
  )
}
