import React from "react";
import { HandleApiError } from "sr/helpers/functions/HandleApiError";
import { SuccessMessage } from "sr/helpers/functions/SuccessMessage";
import ApiResponse from "sr/models/ApiResponse";
import { post, put } from "sr/utils/axios";

export const updateAvailability = async (putData) => {
    try {
  return await put<ApiResponse<[]>>('/cleaner/availability', putData).then((res)=> {
    if(res?.success===true){
        SuccessMessage('API.SUCCESS.AVAILABILITY.UPDATE')
       return res     

    } 
    else {
        return null
    }

} 
    )
}
catch(e:any){
    HandleApiError(e)
}
};
